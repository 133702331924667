import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {

	public servicesEndpointRoot: string = null;
	public alternateServicesEndpointRoot: string = null;
	public homepageUrl: string = null;
	public baseCampClientID: string = null;

	constructor(private http: HttpClient) {
	}

	public from(environment: any) {
		this.servicesEndpointRoot = environment.servicesEndpointRoot;
		this.alternateServicesEndpointRoot = environment.alternateServicesEndpointRoot;
		this.homepageUrl = environment.homepageUrl;
		this.baseCampClientID = environment.baseCampClientID;
	}

	public async load(path: string): Promise<void> {
		console.group(`ConfigService init from ${path}`);
		const cfg = await this.http.get<any>(path).toPromise();
		this.servicesEndpointRoot = cfg.servicesEndpointRoot;
		this.alternateServicesEndpointRoot = cfg.alternateServicesEndpointRoot;
		this.homepageUrl = cfg.homepageUrl;
		this.baseCampClientID = cfg.baseCampClientID;
		console.groupEnd();
	}
}
