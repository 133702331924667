import {Injectable} from '@angular/core';
import {NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import * as _moment from 'moment';

// Vedi https://github.com/jvandemo/generator-angular2-library/issues/221 gli venisse un colpo
const moment = _moment;

@Injectable()
export class ItNgbDateParserFormatter extends NgbDateParserFormatter {

	/**
	 * Crea un NgbDate partendo da una stringa formato ISO
	 * @param dateStr stringa con data (e ora) formato ISO (2023-01-31T22:45:00.000)
	 */
	public static fromDateString(dateStr: string): NgbDate {
		if (dateStr) {
			const dateTimeTokens = dateStr.split('T');
			if (dateTimeTokens.length === 2) {
				const datePartTokens = dateTimeTokens[0].split('-');
				if (datePartTokens.length === 3) {
					return new NgbDate(Number(datePartTokens[0]), Number(datePartTokens[1]), Number(datePartTokens[2]));
				}
			}
		}
		const now = new Date();
		return new NgbDate(now.getFullYear(), now.getMonth() + 1, now.getDate());
	}

	/**
	 * Converte un NgbDateStruc in Date
	 * @param ngbDate NgbDateStruct da convertire
	 */
	public static toDate(ngbDate: NgbDateStruct): Date {
		return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
	}

	/**
	 * Converte un Date in  NgbDateStruc
	 * @param date Date da convertire
	 */
	public static fromDate(date: Date): NgbDate {
		return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
	}

	/**
	 * Converte un NgbDateStruct in un moment
	 * @param ngbDate NgbDateStruct da convertire
	 */
	public static toMoment(ngbDate: NgbDateStruct): _moment.Moment {
		const date = this.toDate(ngbDate);
		return moment(date);
	}

	/**
	 * Converte un moment in un NgbDate
	 * @param m moment da convertire
	 */
	public static fromMoment(m: _moment.Moment): NgbDate {
		return new NgbDate(m.year(), m.month(), m.date());
	}

	/**
	 * Converte un numero che rappresenta una data a 8 cifre (YYYYMMGG) in un NgbDate
	 * @param dateNr Numero che rappresenta una data a 8 cifre (YYYYMMGG)
	 */
	public static fromDateNumber(dateNr: number): NgbDate {
		if (dateNr) {
			const dateNrString = String(dateNr);
			const year = +dateNrString.substr(0, 4);
			const month = +dateNrString.substr(4, 2);
			const day = +dateNrString.substr(6, 2);
			return new NgbDate(year, month, day);
		}
		return null;
	}

	public static timeFromDateString(dateStr: string): NgbTimeStruct {
		if (dateStr) {
			const dateTimeTokens = dateStr.split('T');
			if (dateTimeTokens.length === 2) {
				const datePartTokens = dateTimeTokens[1].split(':');
				if (datePartTokens.length === 3) {
					return {
						hour: Number(datePartTokens[0]),
						minute: Number(datePartTokens[1]),
						second: Number(datePartTokens[2])
					};
				}
			}
		}
		return {hour: 0, minute: 0, second: 0}
	}

	public static toDateNr(date: NgbDate): number {
		// Ci devono essere giorno / mese e anno
		if (!(date && date.month && date.day && date.year)) {
			return null;
		}
		return date.year * 10000 + date.month * 100 + date.day;
	}

	/**
	 * Converte un NgbDate ed eventuale NgbTimeStruct in data/ora formato ISO
	 * @param date NgbDateStruct che rappresenta la data
	 * @param time NgbTimeStruct facoltativa che rappresenta l'orario
	 */
	public static toDateString(date: NgbDateStruct, time?: NgbTimeStruct): string {
		// Ci devono essere giorno / mese e anno
		if (!(date && date.month && date.day && date.year)) {
			return null;
		}
		const month = `${date.month < 10 ? '0' : ''}${date.month}`;
		const day = `${date.day < 10 ? '0' : ''}${date.day}`;
		if (time) {
			const hour = `${time.hour < 10 ? '0' : ''}${time.hour}`;
			const minute = `${time.minute < 10 ? '0' : ''}${time.minute}`;
			const second = `${time.second < 10 ? '0' : ''}${time.second}`;
			return `${date.year}-${month}-${day}T${hour}:${minute}:${second}`;
		} else
			return `${date.year}-${month}-${day}T00:00:00`;
	}

	/**
	 * Converte un Date in una stringa ISO conservando il timezone
	 * @param date Date da convertire
	 */
	public static dateToISOStringWithTimezone = (date: Date) => {
		const tzOffset = -date.getTimezoneOffset();
		const diff = tzOffset >= 0 ? '+' : '-';
		const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
		return date.getFullYear() +
			'-' + pad(date.getMonth() + 1) +
			'-' + pad(date.getDate()) +
			'T' + pad(date.getHours()) +
			':' + pad(date.getMinutes()) +
			':' + pad(date.getSeconds()) +
			diff + pad(tzOffset / 60) +
			':' + pad(tzOffset % 60);
	};

	format(date: NgbDateStruct): string {
		if (date) {
			const m = moment([date.year, date.month - 1, date.day]);
			return m.format('DD/MM/YYYY');
		} else {
			return '';
		}
	}

	parse(value: string): NgbDateStruct {
		if (value) {
			value = value.trim();
			const tokens: string[] = value.split('/');	// [g]g/[m]m/aaaa
			if (tokens.length === 3) {
				const day = parseInt(tokens[0].trim(), 10);
				const month = parseInt(tokens[1].trim(), 10);
				const year = parseInt(tokens[2].trim(), 10);
				if (day > 0 && day < 32 && month > 0 && month < 13 && year > 1900) {
					return {
						day,
						month,
						year
					};
				}
			}
		}

		return null;
	}

}
