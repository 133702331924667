export const environment = {
	production: true,
	i18nFiles: '/assets/i18n/',
    servicesEndpointRoot: 'https://api-staging.naar.com/',
    
	website: 'auto',
	googleMapsApiKey: 'AIzaSyBSNG0UvbqrSdk0JPejA3_K-eDXTCbpRv4',
    googleAnalyticsMeasurementId: 'G-JM0096DD0F',
	b2bIntranetUrl: 'https://intranet-staging.naar.com/in/dashboard',

	b2bIntranetPwdRecover: 'https://itb2b-staging.naar.com/auth/recovery',
	b2bIntranetUsrSignIn: 'https://itb2b-staging.naar.com/auth/recovery',
	b2bIntranetAdvUrl: {
		it: 'https://itb2b-staging.naar.com/agency/dashboard',
		fr: 'https://frb2b-staging.naar.com/agency/dashboard',
		de: 'https://deb2b-staging.naar.com/agency/dashboard',
		nl: 'https://nlb2b-staging.naar.com/agency/dashboard',
	},
	b2bIntranetAdvTripList: {
		it: 'https://b2b-staging.naar.com/agency/orders/advanced',
		fr: 'https://b2b-staging.naar.com/agency/orders/advanced',
		de: 'https://b2b-staging.naar.com/agency/orders/advanced',
		nl: 'https://b2b-staging.naar.com/agency/orders/advanced'
	},
	DINAMIC_COOKIES_ARTICLE_ID: 182068
};
