export const WEBLABELS: any = {
	/**
	 * Content of path intranet
	 */
	intranet: {
		/**
		 * Content of path intranet.ENUMS
		 */
		ENUMS: {
			/**
			 * Content of path intranet.ENUMS.EImageType
			 */
			EImageType: {
				/**
				 * Fotografia
				 */
				Photo: {
					id: 1,
					label: "intranet.ENUMS.EImageType.Photo"
				},

				/**
				 * Vettoriale
				 */
				Vector: {
					id: 2,
					label: "intranet.ENUMS.EImageType.Vector"
				},

				/**
				 * Tutte
				 */
				All: {
					id: 5857,
					label: "intranet.ENUMS.EImageType.All"
				},

				/**
				 * Illustrazione
				 */
				Illustration: {
					id: 5858,
					label: "intranet.ENUMS.EImageType.Illustration"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ELingua
			 */
			ELingua: {
				/**
				 * Tedesco
				 */
				Deutsch: {
					id: 3,
					label: "intranet.ENUMS.ELingua.Deutsch"
				},

				/**
				 * Olandese
				 */
				Dutch: {
					id: 4,
					label: "intranet.ENUMS.ELingua.Dutch"
				},

				/**
				 * Inglese
				 */
				English: {
					id: 5,
					label: "intranet.ENUMS.ELingua.English"
				},

				/**
				 * Francese
				 */
				Francais: {
					id: 6,
					label: "intranet.ENUMS.ELingua.Francais"
				},

				/**
				 * Italiano
				 */
				Italiano: {
					id: 7,
					label: "intranet.ENUMS.ELingua.Italiano"
				},

				/**
				 * Portoghese
				 */
				Portuguese: {
					id: 8,
					label: "intranet.ENUMS.ELingua.Portuguese"
				},

				/**
				 * Spagnolo
				 */
				Spanish: {
					id: 9,
					label: "intranet.ENUMS.ELingua.Spanish"
				},

				/**
				 * Non definita
				 */
				undefined: {
					id: 10,
					label: "intranet.ENUMS.ELingua.undefined"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EListinoPrezzi
			 */
			EListinoPrezzi: {
				/**
				 * Default
				 */
				Default: {
					id: 11,
					label: "intranet.ENUMS.EListinoPrezzi.Default"
				},

				/**
				 * Pacchetto
				 */
				Pacchetto: {
					id: 12,
					label: "intranet.ENUMS.EListinoPrezzi.Pacchetto"
				},

				/**
				 * Prezzi netti
				 */
				PrezziNetti: {
					id: 13,
					label: "intranet.ENUMS.EListinoPrezzi.PrezziNetti"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EMacrotipologia
			 */
			EMacrotipologia: {
				/**
				 * Solo i supplementi
				 */
				SoloSupplementi: {
					id: 14,
					label: "intranet.ENUMS.EMacrotipologia.SoloSupplementi"
				},

				/**
				 * Tutte le tipologie che non sono supplementi
				 */
				TuttoTranneSupplementi: {
					id: 15,
					label: "intranet.ENUMS.EMacrotipologia.TuttoTranneSupplementi"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EMezzoPrenotazione
			 */
			EMezzoPrenotazione: {
				/**
				 * Biglietti Aerei
				 */
				BigliettiAerei: {
					id: 16,
					label: "intranet.ENUMS.EMezzoPrenotazione.BigliettiAerei"
				},

				/**
				 * Cellulare
				 */
				Cellulare: {
					id: 17,
					label: "intranet.ENUMS.EMezzoPrenotazione.Cellulare"
				},

				/**
				 * Email Prenotazioni
				 */
				EmailPrenotazioni: {
					id: 18,
					label: "intranet.ENUMS.EMezzoPrenotazione.EmailPrenotazioni"
				},

				/**
				 * Email Promozioni
				 */
				EmailPromozioni: {
					id: 19,
					label: "intranet.ENUMS.EMezzoPrenotazione.EmailPromozioni"
				},

				/**
				 * Fax Prenotazioni
				 */
				FaxPrenotazioni: {
					id: 20,
					label: "intranet.ENUMS.EMezzoPrenotazione.FaxPrenotazioni"
				},

				/**
				 * Fax Promozioni
				 */
				FaxPromozioni: {
					id: 21,
					label: "intranet.ENUMS.EMezzoPrenotazione.FaxPromozioni"
				},

				/**
				 * Invio Doc Contabili
				 */
				InvioDocContabili: {
					id: 22,
					label: "intranet.ENUMS.EMezzoPrenotazione.InvioDocContabili"
				},

				/**
				 * Telefono
				 */
				Telefono: {
					id: 23,
					label: "intranet.ENUMS.EMezzoPrenotazione.Telefono"
				},

				/**
				 * Terminale Aereo
				 */
				TerminaleAereo: {
					id: 24,
					label: "intranet.ENUMS.EMezzoPrenotazione.TerminaleAereo"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EModalitaNonMappati
			 */
			EModalitaNonMappati: {
				/**
				 * Non attivi
				 */
				NonAttivi: {
					id: 25,
					label: "intranet.ENUMS.EModalitaNonMappati.NonAttivi"
				},

				/**
				 * Sempre
				 */
				Sempre: {
					id: 26,
					label: "intranet.ENUMS.EModalitaNonMappati.Sempre"
				},

				/**
				 * Su richiesta, se presenti
				 */
				SuRichiesta: {
					id: 27,
					label: "intranet.ENUMS.EModalitaNonMappati.SuRichiesta"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EObbligatorieta
			 */
			EObbligatorieta: {
				/**
				 * Facoltativo
				 */
				Facoltativo: {
					id: 28,
					label: "intranet.ENUMS.EObbligatorieta.Facoltativo"
				},

				/**
				 * Obbligatorio
				 */
				Obbligatorio: {
					id: 29,
					label: "intranet.ENUMS.EObbligatorieta.Obbligatorio"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EOperatore
			 */
			EOperatore: {
				/**
				 * Aviomar
				 */
				Aviomar: {
					id: 30,
					label: "intranet.ENUMS.EOperatore.Aviomar"
				},

				/**
				 * Indefinito
				 */
				Indefinito: {
					id: 31,
					label: "intranet.ENUMS.EOperatore.Indefinito"
				},

				/**
				 * Naar Tour Operator
				 */
				Naar: {
					id: 32,
					label: "intranet.ENUMS.EOperatore.Naar"
				},

				/**
				 * Naar Benelux
				 */
				NaarBenelux: {
					id: 33,
					label: "intranet.ENUMS.EOperatore.NaarBenelux"
				},

				/**
				 * Naar France
				 */
				NaarFrance: {
					id: 34,
					label: "intranet.ENUMS.EOperatore.NaarFrance"
				},

				/**
				 * Naar Germany
				 */
				NaarGermany: {
					id: 35,
					label: "intranet.ENUMS.EOperatore.NaarGermany"
				},

				/**
				 *  
				 */
				undefined: {
					id: 6181,
					label: "intranet.ENUMS.EOperatore.undefined"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EOperatoreDisuguaglianza
			 */
			EOperatoreDisuguaglianza: {
				/**
				 * Esattamente
				 */
				Esattamente: {
					id: 36,
					label: "intranet.ENUMS.EOperatoreDisuguaglianza.Esattamente"
				},

				/**
				 * Massimo
				 */
				Massimo: {
					id: 37,
					label: "intranet.ENUMS.EOperatoreDisuguaglianza.Massimo"
				},

				/**
				 * Minimo
				 */
				Minimo: {
					id: 38,
					label: "intranet.ENUMS.EOperatoreDisuguaglianza.Minimo"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EPeriodoGiorniGratis
			 */
			EPeriodoGiorniGratis: {
				/**
				 * Il giorno meno costoso
				 */
				GiornoMenoCostoso: {
					id: 39,
					label: "intranet.ENUMS.EPeriodoGiorniGratis.GiornoMenoCostoso"
				},

				/**
				 * Il giorno più costoso
				 */
				GiornoPiuCostoso: {
					id: 40,
					label: "intranet.ENUMS.EPeriodoGiorniGratis.GiornoPiuCostoso"
				},

				/**
				 * La prima notte
				 */
				PrimaNotte: {
					id: 41,
					label: "intranet.ENUMS.EPeriodoGiorniGratis.PrimaNotte"
				},

				/**
				 * La prima settimana (max 7gg)
				 */
				PrimaSettimana: {
					id: 42,
					label: "intranet.ENUMS.EPeriodoGiorniGratis.PrimaSettimana"
				},

				/**
				 * La seconda settimana (max 7gg)
				 */
				SecondaSettimana: {
					id: 43,
					label: "intranet.ENUMS.EPeriodoGiorniGratis.SecondaSettimana"
				},

				/**
				 * La seconda settimana e successive
				 */
				SecondaSettimanaESuccessive: {
					id: 44,
					label: "intranet.ENUMS.EPeriodoGiorniGratis.SecondaSettimanaESuccessive"
				},

				/**
				 * La settimana meno costosa
				 */
				SettimanaMenoCostosa: {
					id: 45,
					label: "intranet.ENUMS.EPeriodoGiorniGratis.SettimanaMenoCostosa"
				},

				/**
				 * L'ultima notte
				 */
				UltimaNotte: {
					id: 46,
					label: "intranet.ENUMS.EPeriodoGiorniGratis.UltimaNotte"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EPosizioneLink
			 */
			EPosizioneLink: {
				/**
				 * Banner Bottom
				 */
				BannerBottom: {
					id: 47,
					label: "intranet.ENUMS.EPosizioneLink.BannerBottom"
				},

				/**
				 * Banner Left
				 */
				BannerLeft: {
					id: 48,
					label: "intranet.ENUMS.EPosizioneLink.BannerLeft"
				},

				/**
				 * Banner Right
				 */
				BannerRight: {
					id: 49,
					label: "intranet.ENUMS.EPosizioneLink.BannerRight"
				},

				/**
				 * Banner Top
				 */
				BannerTop: {
					id: 50,
					label: "intranet.ENUMS.EPosizioneLink.BannerTop"
				},

				/**
				 * Box News
				 */
				BoxNews: {
					id: 51,
					label: "intranet.ENUMS.EPosizioneLink.BoxNews"
				},

				/**
				 * Default
				 */
				Default: {
					id: 52,
					label: "intranet.ENUMS.EPosizioneLink.Default"
				},

				/**
				 * Menù Navigazione
				 */
				MenuNavigazione: {
					id: 53,
					label: "intranet.ENUMS.EPosizioneLink.MenuNavigazione"
				},

				/**
				 * Menu Secondario
				 */
				MenuSecondario: {
					id: 54,
					label: "intranet.ENUMS.EPosizioneLink.MenuSecondario"
				},

				/**
				 * Footer
				 */
				FooterLinks: {
					id: 6078,
					label: "intranet.ENUMS.EPosizioneLink.FooterLinks"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EPrenotaEntro
			 */
			EPrenotaEntro: {
				/**
				 * Prima di
				 */
				Giorni: {
					id: 55,
					label: "intranet.ENUMS.EPrenotaEntro.Giorni"
				},

				/**
				 * Nel periodo
				 */
				Periodo: {
					id: 56,
					label: "intranet.ENUMS.EPrenotaEntro.Periodo"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EPubblicazioneLuogo
			 */
			EPubblicazioneLuogo: {
				/**
				 * Non pubblicato
				 */
				NonPubblicato: {
					id: 57,
					label: "intranet.ENUMS.EPubblicazioneLuogo.NonPubblicato"
				},

				/**
				 * Principale
				 */
				Principale: {
					id: 58,
					label: "intranet.ENUMS.EPubblicazioneLuogo.Principale"
				},

				/**
				 * Secondario
				 */
				Secondario: {
					id: 59,
					label: "intranet.ENUMS.EPubblicazioneLuogo.Secondario"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ERegolaCostiBambino
			 */
			ERegolaCostiBambino: {
				/**
				 * Fino ad un bambino
				 */
				Fino1Bambino: {
					id: 60,
					label: "intranet.ENUMS.ERegolaCostiBambino.Fino1Bambino"
				},

				/**
				 * Fino ad un infant
				 */
				Fino1Infant: {
					id: 61,
					label: "intranet.ENUMS.ERegolaCostiBambino.Fino1Infant"
				},

				/**
				 * Fino a 2 bambini
				 */
				Fino2Bambini: {
					id: 62,
					label: "intranet.ENUMS.ERegolaCostiBambino.Fino2Bambini"
				},

				/**
				 * Fino a 2 infant
				 */
				Fino2Infant: {
					id: 63,
					label: "intranet.ENUMS.ERegolaCostiBambino.Fino2Infant"
				},

				/**
				 * Fino a 3 bambini
				 */
				Fino3Bambini: {
					id: 64,
					label: "intranet.ENUMS.ERegolaCostiBambino.Fino3Bambini"
				},

				/**
				 * Fino a 4 bambini
				 */
				Fino4Bambini: {
					id: 65,
					label: "intranet.ENUMS.ERegolaCostiBambino.Fino4Bambini"
				},

				/**
				 * Fino a 5 bambini
				 */
				Fino5Bambini: {
					id: 66,
					label: "intranet.ENUMS.ERegolaCostiBambino.Fino5Bambini"
				},

				/**
				 * Primo bambino
				 */
				PrimoBambino: {
					id: 67,
					label: "intranet.ENUMS.ERegolaCostiBambino.PrimoBambino"
				},

				/**
				 * Quarto bambino
				 */
				QuartoBambino: {
					id: 68,
					label: "intranet.ENUMS.ERegolaCostiBambino.QuartoBambino"
				},

				/**
				 * Quinto bambino
				 */
				QuintoBambino: {
					id: 69,
					label: "intranet.ENUMS.ERegolaCostiBambino.QuintoBambino"
				},

				/**
				 * Secondo bambino
				 */
				SecondoBambino: {
					id: 70,
					label: "intranet.ENUMS.ERegolaCostiBambino.SecondoBambino"
				},

				/**
				 * Terzo bambino
				 */
				TerzoBambino: {
					id: 71,
					label: "intranet.ENUMS.ERegolaCostiBambino.TerzoBambino"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ERegolaInserimentoCosti
			 */
			ERegolaInserimentoCosti: {
				/**
				 * DoppiaPerPaxSupplementoSingolaGlobaleRiduzioneTerzoQuartoLettoPerc
				 */
				DoppiaPerPaxSupplementoSingolaGlobaleRiduzioneTerzoQuartoLettoPerc: {
					id: 72,
					label: "intranet.ENUMS.ERegolaInserimentoCosti.DoppiaPerPaxSupplementoSingolaGlobaleRiduzioneTerzoQuartoLettoPerc"
				},

				/**
				 * DoppiaPerPaxSupplementoSingolaPercRiduzioneTerzoQuartoLettoPerc
				 */
				DoppiaPerPaxSupplementoSingolaPercRiduzioneTerzoQuartoLettoPerc: {
					id: 73,
					label: "intranet.ENUMS.ERegolaInserimentoCosti.DoppiaPerPaxSupplementoSingolaPercRiduzioneTerzoQuartoLettoPerc"
				},

				/**
				 * Doppia per pax, supplemento su doppia per singola, tripla e quadrupla per pax
				 */
				DoppiaPerPaxSupplementoSingolaPerTriplaQuadruplaPerPax: {
					id: 74,
					label: "intranet.ENUMS.ERegolaInserimentoCosti.DoppiaPerPaxSupplementoSingolaPerTriplaQuadruplaPerPax"
				},

				/**
				 * DoppiaPerPaxSupplementoSingolaRiduzioneTerzoQuartoLetto
				 */
				DoppiaPerPaxSupplementoSingolaRiduzioneTerzoQuartoLetto: {
					id: 75,
					label: "intranet.ENUMS.ERegolaInserimentoCosti.DoppiaPerPaxSupplementoSingolaRiduzioneTerzoQuartoLetto"
				},

				/**
				 * Doppia per pax, supplemento singola per camera, riduzione tripla e quadrupla per pax
				 */
				DoppiaPerPaxSupplementoSingolaRiduzioneTriplaQuadruplaPerPax: {
					id: 76,
					label: "intranet.ENUMS.ERegolaInserimentoCosti.DoppiaPerPaxSupplementoSingolaRiduzioneTriplaQuadruplaPerPax"
				},

				/**
				 * DoppiaPerPaxSupplementoSingolaTriplaQuadruplaGlobale
				 */
				DoppiaPerPaxSupplementoSingolaTriplaQuadruplaGlobale: {
					id: 77,
					label: "intranet.ENUMS.ERegolaInserimentoCosti.DoppiaPerPaxSupplementoSingolaTriplaQuadruplaGlobale"
				},

				/**
				 * DoppiaPerPaxSupplementoSingolaTriplaQuadruplaPerPax
				 */
				DoppiaPerPaxSupplementoSingolaTriplaQuadruplaPerPax: {
					id: 78,
					label: "intranet.ENUMS.ERegolaInserimentoCosti.DoppiaPerPaxSupplementoSingolaTriplaQuadruplaPerPax"
				},

				/**
				 * Doppia, tripla e quadrupla per pax, supplemento singola per camera
				 */
				DoppiaTriplaQuadruplaPerPaxSupplementoSingolaPerPax: {
					id: 79,
					label: "intranet.ENUMS.ERegolaInserimentoCosti.DoppiaTriplaQuadruplaPerPaxSupplementoSingolaPerPax"
				},

				/**
				 * Nessuna
				 */
				Nessuna: {
					id: 80,
					label: "intranet.ENUMS.ERegolaInserimentoCosti.Nessuna"
				},

				/**
				 * Singola e doppia per camera, supplemento tripla e quadrupla sulla doppia per pax
				 */
				SingolaDoppiaPerCameraSupplementoTriplaQuadruplaPerPax: {
					id: 81,
					label: "intranet.ENUMS.ERegolaInserimentoCosti.SingolaDoppiaPerCameraSupplementoTriplaQuadruplaPerPax"
				},

				/**
				 * SingolaDoppiaPerPaxSupplementoTriplaQuadruplaGlobale
				 */
				SingolaDoppiaPerPaxSupplementoTriplaQuadruplaGlobale: {
					id: 82,
					label: "intranet.ENUMS.ERegolaInserimentoCosti.SingolaDoppiaPerPaxSupplementoTriplaQuadruplaGlobale"
				},

				/**
				 * Singola, Doppia, Tripla e Quadrupla per pax
				 */
				SingolaDoppiaTriplaQuadruplaPerPax: {
					id: 83,
					label: "intranet.ENUMS.ERegolaInserimentoCosti.SingolaDoppiaTriplaQuadruplaPerPax"
				},

				/**
				 * Supplemento su tipologia globale
				 */
				SupplementoSuTipologiaGlobale: {
					id: 84,
					label: "intranet.ENUMS.ERegolaInserimentoCosti.SupplementoSuTipologiaGlobale"
				},

				/**
				 * Supplemento su tipologia per pax
				 */
				SupplementoSuTipologiaPerPax: {
					id: 85,
					label: "intranet.ENUMS.ERegolaInserimentoCosti.SupplementoSuTipologiaPerPax"
				},

				/**
				 * Supplemento su trattamento base per pax
				 */
				SupplementoSuTrattamentoBasePerPax: {
					id: 86,
					label: "intranet.ENUMS.ERegolaInserimentoCosti.SupplementoSuTrattamentoBasePerPax"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ESortOrder
			 */
			ESortOrder: {
				/**
				 * Più recenti
				 */
				Latest: {
					id: 87,
					label: "intranet.ENUMS.ESortOrder.Latest"
				},

				/**
				 * Più popolari
				 */
				Popular: {
					id: 88,
					label: "intranet.ENUMS.ESortOrder.Popular"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EStatoPubblicazioneServizio
			 */
			EStatoPubblicazioneServizio: {
				/**
				 * In lavorazione
				 */
				InLavorazione: {
					id: 89,
					label: "intranet.ENUMS.EStatoPubblicazioneServizio.InLavorazione"
				},

				/**
				 * Non pubblicare
				 */
				NonPubblicare: {
					id: 90,
					label: "intranet.ENUMS.EStatoPubblicazioneServizio.NonPubblicare"
				},

				/**
				 * Pubblica solo per utenti intranet
				 */
				PubblicaIntranet: {
					id: 91,
					label: "intranet.ENUMS.EStatoPubblicazioneServizio.PubblicaIntranet"
				},

				/**
				 * Pubblica su web
				 */
				PubblicaWeb: {
					id: 92,
					label: "intranet.ENUMS.EStatoPubblicazioneServizio.PubblicaWeb"
				},

				/**
				 * Pubblica su web come preferito
				 */
				PubblicaWebPreferito: {
					id: 93,
					label: "intranet.ENUMS.EStatoPubblicazioneServizio.PubblicaWebPreferito"
				},

				/**
				 * Visibile pubblicamente
				 */
				VisibilePubblicamente: {
					id: 94,
					label: "intranet.ENUMS.EStatoPubblicazioneServizio.VisibilePubblicamente"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EStatoUtilizzoServizio
			 */
			EStatoUtilizzoServizio: {
				/**
				 * Attivo
				 */
				Attivo: {
					id: 95,
					label: "intranet.ENUMS.EStatoUtilizzoServizio.Attivo"
				},

				/**
				 * Obsoleto
				 */
				Obsoleto: {
					id: 96,
					label: "intranet.ENUMS.EStatoUtilizzoServizio.Obsoleto"
				},

				/**
				 * Occasionale
				 */
				Occasionale: {
					id: 97,
					label: "intranet.ENUMS.EStatoUtilizzoServizio.Occasionale"
				},

				/**
				 * In lavorazione
				 */
				InLavorazione: {
					id: 6145,
					label: "intranet.ENUMS.EStatoUtilizzoServizio.InLavorazione"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EStatusFlag
			 */
			EStatusFlag: {
				/**
				 * Bozza
				 */
				Bozza: {
					id: 98,
					label: "intranet.ENUMS.EStatusFlag.Bozza"
				},

				/**
				 * Pronto
				 */
				Pronto: {
					id: 99,
					label: "intranet.ENUMS.EStatusFlag.Pronto"
				},

				/**
				 * Tutti gli stati
				 */
				undefined: {
					id: 100,
					label: "intranet.ENUMS.EStatusFlag.undefined"
				},

				/**
				 * Pubblicato solo per utenti intranet
				 */
				UtentiIntranet: {
					id: 101,
					label: "intranet.ENUMS.EStatusFlag.UtentiIntranet"
				},

				/**
				 * Pubblicato per tutti
				 */
				UtentiTutti: {
					id: 102,
					label: "intranet.ENUMS.EStatusFlag.UtentiTutti"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ESupplementoRiduzione
			 */
			ESupplementoRiduzione: {
				/**
				 * Da pagare in loco
				 */
				DaPagareInLoco: {
					id: 103,
					label: "intranet.ENUMS.ESupplementoRiduzione.DaPagareInLoco"
				},

				/**
				 * Gratis da prenotare
				 */
				GratisDaPrenotare: {
					id: 104,
					label: "intranet.ENUMS.ESupplementoRiduzione.GratisDaPrenotare"
				},

				/**
				 * Nessuno
				 */
				Nessuno: {
					id: 105,
					label: "intranet.ENUMS.ESupplementoRiduzione.Nessuno"
				},

				/**
				 * Riduzione
				 */
				Riduzione: {
					id: 106,
					label: "intranet.ENUMS.ESupplementoRiduzione.Riduzione"
				},

				/**
				 * Supplemento
				 */
				Supplemento: {
					id: 107,
					label: "intranet.ENUMS.ESupplementoRiduzione.Supplemento"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETicketsType
			 */
			ETicketsType: {
				/**
				 * Amadeus
				 */
				Amadeus: {
					id: 108,
					label: "intranet.ENUMS.ETicketsType.Amadeus"
				},

				/**
				 * Meridiano
				 */
				Meridiano: {
					id: 109,
					label: "intranet.ENUMS.ETicketsType.Meridiano"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoAcconto
			 */
			ETipoAcconto: {
				/**
				 * Acconto
				 */
				Acconto: {
					id: 110,
					label: "intranet.ENUMS.ETipoAcconto.Acconto"
				},

				/**
				 * AltriVoli
				 */
				AltriVoli: {
					id: 111,
					label: "intranet.ENUMS.ETipoAcconto.AltriVoli"
				},

				/**
				 * Hotel
				 */
				Hotel: {
					id: 112,
					label: "intranet.ENUMS.ETipoAcconto.Hotel"
				},

				/**
				 * TariffaIP
				 */
				TariffaIP: {
					id: 113,
					label: "intranet.ENUMS.ETipoAcconto.TariffaIP"
				},

				/**
				 * TariffaTO
				 */
				TariffaTO: {
					id: 114,
					label: "intranet.ENUMS.ETipoAcconto.TariffaTO"
				},

				/**
				 * Tour
				 */
				Tour: {
					id: 115,
					label: "intranet.ENUMS.ETipoAcconto.Tour"
				},

				/**
				 * Tutto
				 */
				Tutto: {
					id: 116,
					label: "intranet.ENUMS.ETipoAcconto.Tutto"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoAccordo
			 */
			ETipoAccordo: {
				/**
				 * Agenzie
				 */
				Agenzie: {
					id: 117,
					label: "intranet.ENUMS.ETipoAccordo.Agenzie"
				},

				/**
				 * Biglietteria
				 */
				Biglietteria: {
					id: 118,
					label: "intranet.ENUMS.ETipoAccordo.Biglietteria"
				},

				/**
				 * Fornitori
				 */
				Fornitori: {
					id: 119,
					label: "intranet.ENUMS.ETipoAccordo.Fornitori"
				},

				/**
				 * -- Scaduto--
				 */
				Scaduto: {
					id: 120,
					label: "intranet.ENUMS.ETipoAccordo.Scaduto"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoAnticipoFornitore
			 */
			ETipoAnticipoFornitore: {
				/**
				 * Importo Fisso
				 */
				ImportoFisso: {
					id: 121,
					label: "intranet.ENUMS.ETipoAnticipoFornitore.ImportoFisso"
				},

				/**
				 * Notti
				 */
				Notti: {
					id: 122,
					label: "intranet.ENUMS.ETipoAnticipoFornitore.Notti"
				},

				/**
				 * Percentuale
				 */
				Percentuale: {
					id: 123,
					label: "intranet.ENUMS.ETipoAnticipoFornitore.Percentuale"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoApplicazione
			 */
			ETipoApplicazione: {
				/**
				 * Applica sconti / supplementi senza mostrare il valore
				 */
				ApplicaENascondiPrezzo: {
					id: 124,
					label: "intranet.ENUMS.ETipoApplicazione.ApplicaENascondiPrezzo"
				},

				/**
				 * Blocca la vendita
				 */
				BloccaVendita: {
					id: 125,
					label: "intranet.ENUMS.ETipoApplicazione.BloccaVendita"
				},

				/**
				 * Forza 'su richiesta'
				 */
				ForzaSuRichiesta: {
					id: 126,
					label: "intranet.ENUMS.ETipoApplicazione.ForzaSuRichiesta"
				},

				/**
				 * Modifica quote base
				 */
				ModificaQuoteBase: {
					id: 127,
					label: "intranet.ENUMS.ETipoApplicazione.ModificaQuoteBase"
				},

				/**
				 * Applica sconti / supplementi
				 */
				Normale: {
					id: 128,
					label: "intranet.ENUMS.ETipoApplicazione.Normale"
				},

				/**
				 * Visualizza label
				 */
				VisualizzaLabel: {
					id: 129,
					label: "intranet.ENUMS.ETipoApplicazione.VisualizzaLabel"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoCommissione
			 */
			ETipoCommissione: {
				/**
				 * Contratti Al Netto / Estero
				 */
				ContrattiAlNetto: {
					id: 130,
					label: "intranet.ENUMS.ETipoCommissione.ContrattiAlNetto"
				},

				/**
				 * Sconto commerciale
				 */
				ScontoCommerciale: {
					id: 131,
					label: "intranet.ENUMS.ETipoCommissione.ScontoCommerciale"
				},

				/**
				 * Su imponibile
				 */
				SuImponibile: {
					id: 132,
					label: "intranet.ENUMS.ETipoCommissione.SuImponibile"
				},

				/**
				 * Su lordo
				 */
				SuLordo: {
					id: 133,
					label: "intranet.ENUMS.ETipoCommissione.SuLordo"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoContatto
			 */
			ETipoContatto: {
				/**
				 * Addetto
				 */
				Addetto: {
					id: 134,
					label: "intranet.ENUMS.ETipoContatto.Addetto"
				},

				/**
				 * Agenzia
				 */
				Agenzia: {
					id: 135,
					label: "intranet.ENUMS.ETipoContatto.Agenzia"
				},

				/**
				 * Agenzie Chiuse
				 */
				AgenzieChiuse: {
					id: 136,
					label: "intranet.ENUMS.ETipoContatto.AgenzieChiuse"
				},

				/**
				 * Appartamenti
				 */
				Appartamenti: {
					id: 137,
					label: "intranet.ENUMS.ETipoContatto.Appartamenti"
				},

				/**
				 * Cliente contabilità
				 */
				ClienteContabilita: {
					id: 138,
					label: "intranet.ENUMS.ETipoContatto.ClienteContabilita"
				},

				/**
				 * Cliente Diretto
				 */
				ClienteDiretto: {
					id: 139,
					label: "intranet.ENUMS.ETipoContatto.ClienteDiretto"
				},

				/**
				 * Compagnie Aeree
				 */
				CompagnieAeree: {
					id: 140,
					label: "intranet.ENUMS.ETipoContatto.CompagnieAeree"
				},

				/**
				 * Contratti Agenti
				 */
				ContrattiAgenti: {
					id: 141,
					label: "intranet.ENUMS.ETipoContatto.ContrattiAgenti"
				},

				/**
				 * Corrieri
				 */
				Corrieri: {
					id: 142,
					label: "intranet.ENUMS.ETipoContatto.Corrieri"
				},

				/**
				 * Fornitore
				 */
				Fornitore: {
					id: 143,
					label: "intranet.ENUMS.ETipoContatto.Fornitore"
				},

				/**
				 * Fornitore Contabilita
				 */
				FornitoreContabilita: {
					id: 144,
					label: "intranet.ENUMS.ETipoContatto.FornitoreContabilita"
				},

				/**
				 * Fornitore Indiretto
				 */
				FornitoreIndiretto: {
					id: 145,
					label: "intranet.ENUMS.ETipoContatto.FornitoreIndiretto"
				},

				/**
				 * Giornali
				 */
				Giornali: {
					id: 146,
					label: "intranet.ENUMS.ETipoContatto.Giornali"
				},

				/**
				 * Gruppi Agenzie
				 */
				GruppiAgenzie: {
					id: 147,
					label: "intranet.ENUMS.ETipoContatto.GruppiAgenzie"
				},

				/**
				 * Hotel
				 */
				Hotel: {
					id: 148,
					label: "intranet.ENUMS.ETipoContatto.Hotel"
				},

				/**
				 * Produttore
				 */
				Produttore: {
					id: 149,
					label: "intranet.ENUMS.ETipoContatto.Produttore"
				},

				/**
				 * Punto pickup
				 */
				PuntoPickup: {
					id: 150,
					label: "intranet.ENUMS.ETipoContatto.PuntoPickup"
				},

				/**
				 * Sottogruppi Agenzie
				 */
				SottogruppiAgenzie: {
					id: 151,
					label: "intranet.ENUMS.ETipoContatto.SottogruppiAgenzie"
				},

				/**
				 * Tour Operator
				 */
				TourOperator: {
					id: 152,
					label: "intranet.ENUMS.ETipoContatto.TourOperator"
				},

				/**
				 * Ufficio Fornitore
				 */
				UfficioFornitore: {
					id: 153,
					label: "intranet.ENUMS.ETipoContatto.UfficioFornitore"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoDatoContatto
			 */
			ETipoDatoContatto: {
				/**
				 * Accesso Beta Test
				 */
				AccessoBetaTest: {
					id: 154,
					label: "intranet.ENUMS.ETipoDatoContatto.AccessoBetaTest"
				},

				/**
				 * Citta
				 */
				Citta: {
					id: 155,
					label: "intranet.ENUMS.ETipoDatoContatto.Citta"
				},

				/**
				 * Cod Aviomar
				 */
				CodAviomar: {
					id: 156,
					label: "intranet.ENUMS.ETipoDatoContatto.CodAviomar"
				},

				/**
				 * Codice AdHoc
				 */
				CodiceAdHoc: {
					id: 157,
					label: "intranet.ENUMS.ETipoDatoContatto.CodiceAdHoc"
				},

				/**
				 * Codice Fiscale
				 */
				CodiceFiscale: {
					id: 158,
					label: "intranet.ENUMS.ETipoDatoContatto.CodiceFiscale"
				},

				/**
				 * Codice IATA
				 */
				CodiceIATA: {
					id: 159,
					label: "intranet.ENUMS.ETipoDatoContatto.CodiceIATA"
				},

				/**
				 * Codice Priorita Telefonica
				 */
				CodicePrioritaTelefonica: {
					id: 160,
					label: "intranet.ENUMS.ETipoDatoContatto.CodicePrioritaTelefonica"
				},

				/**
				 * Codice Univoco Agenzia FE
				 */
				CodiceUnivocoAgenziaFE: {
					id: 161,
					label: "intranet.ENUMS.ETipoDatoContatto.CodiceUnivocoAgenziaFE"
				},

				/**
				 * Contratto Agenzia
				 */
				ContrattoAgenzia: {
					id: 162,
					label: "intranet.ENUMS.ETipoDatoContatto.ContrattoAgenzia"
				},

				/**
				 * FE senza PEC ne Codice
				 */
				FEsenzaPECneCodice: {
					id: 163,
					label: "intranet.ENUMS.ETipoDatoContatto.FEsenzaPECneCodice"
				},

				/**
				 * Indirizzo PEC
				 */
				IndirizzoPEC: {
					id: 164,
					label: "intranet.ENUMS.ETipoDatoContatto.IndirizzoPEC"
				},

				/**
				 * Indirizzo Pec Agenzia FE
				 */
				IndirizzoPecAgenziaFE: {
					id: 165,
					label: "intranet.ENUMS.ETipoDatoContatto.IndirizzoPecAgenziaFE"
				},

				/**
				 * Nota Commerciale Agenzia
				 */
				NotaCommercialeAgenzia: {
					id: 166,
					label: "intranet.ENUMS.ETipoDatoContatto.NotaCommercialeAgenzia"
				},

				/**
				 * Note Offerta
				 */
				NoteOfferta: {
					id: 167,
					label: "intranet.ENUMS.ETipoDatoContatto.NoteOfferta"
				},

				/**
				 * Partita IVA
				 */
				PartitaIVA: {
					id: 168,
					label: "intranet.ENUMS.ETipoDatoContatto.PartitaIVA"
				},

				/**
				 * Password Ticket Stats
				 */
				PasswordTicketStats: {
					id: 169,
					label: "intranet.ENUMS.ETipoDatoContatto.PasswordTicketStats"
				},

				/**
				 * PCC
				 */
				PCC: {
					id: 170,
					label: "intranet.ENUMS.ETipoDatoContatto.PCC"
				},

				/**
				 * Posizione Agenzia
				 */
				PosizioneAgenzia: {
					id: 171,
					label: "intranet.ENUMS.ETipoDatoContatto.PosizioneAgenzia"
				},

				/**
				 * Provincia
				 */
				Provincia: {
					id: 172,
					label: "intranet.ENUMS.ETipoDatoContatto.Provincia"
				},

				/**
				 * Provincia Agenzie
				 */
				ProvinciaAgenzie: {
					id: 173,
					label: "intranet.ENUMS.ETipoDatoContatto.ProvinciaAgenzie"
				},

				/**
				 * Regione
				 */
				Regione: {
					id: 174,
					label: "intranet.ENUMS.ETipoDatoContatto.Regione"
				},

				/**
				 * Responsabile
				 */
				Responsabile: {
					id: 175,
					label: "intranet.ENUMS.ETipoDatoContatto.Responsabile"
				},

				/**
				 * Sesso
				 */
				Sesso: {
					id: 176,
					label: "intranet.ENUMS.ETipoDatoContatto.Sesso"
				},

				/**
				 * Sito Web
				 */
				SitoWeb: {
					id: 177,
					label: "intranet.ENUMS.ETipoDatoContatto.SitoWeb"
				},

				/**
				 * Sso Token
				 */
				SsoToken: {
					id: 178,
					label: "intranet.ENUMS.ETipoDatoContatto.SsoToken"
				},

				/**
				 * Url Logo
				 */
				UrlLogo: {
					id: 179,
					label: "intranet.ENUMS.ETipoDatoContatto.UrlLogo"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoDocumento
			 */
			ETipoDocumento: {
				/**
				 * Assicurazioni
				 */
				Assicurazioni: {
					id: 180,
					label: "intranet.ENUMS.ETipoDocumento.Assicurazioni"
				},

				/**
				 * Autofattura
				 */
				Autofattura: {
					id: 181,
					label: "intranet.ENUMS.ETipoDocumento.Autofattura"
				},

				/**
				 * Autofattura 2018
				 */
				AutofatturaBis: {
					id: 182,
					label: "intranet.ENUMS.ETipoDocumento.AutofatturaBis"
				},

				/**
				 * Biglietteria
				 */
				Biglietteria: {
					id: 183,
					label: "intranet.ENUMS.ETipoDocumento.Biglietteria"
				},

				/**
				 * Conferma
				 */
				Conferma: {
					id: 184,
					label: "intranet.ENUMS.ETipoDocumento.Conferma"
				},

				/**
				 * Conferma cliente
				 */
				ConfermaCliente: {
					id: 185,
					label: "intranet.ENUMS.ETipoDocumento.ConfermaCliente"
				},

				/**
				 * Corrispondenza
				 */
				Corrispondenza: {
					id: 186,
					label: "intranet.ENUMS.ETipoDocumento.Corrispondenza"
				},

				/**
				 * Documenti di viaggio
				 */
				DocumentiDiViaggio: {
					id: 187,
					label: "intranet.ENUMS.ETipoDocumento.DocumentiDiViaggio"
				},

				/**
				 * Documento allegato
				 */
				DocumentoAllegato: {
					id: 188,
					label: "intranet.ENUMS.ETipoDocumento.DocumentoAllegato"
				},

				/**
				 * Documento Online
				 */
				DocumentoContabile: {
					id: 189,
					label: "intranet.ENUMS.ETipoDocumento.DocumentoContabile"
				},

				/**
				 * Estratto Conto
				 */
				EstrattoConto: {
					id: 190,
					label: "intranet.ENUMS.ETipoDocumento.EstrattoConto"
				},

				/**
				 * Estratto conto conferma servizi
				 */
				EstrattoContoConfermaServizi: {
					id: 191,
					label: "intranet.ENUMS.ETipoDocumento.EstrattoContoConfermaServizi"
				},

				/**
				 * Fattura
				 */
				Fattura: {
					id: 192,
					label: "intranet.ENUMS.ETipoDocumento.Fattura"
				},

				/**
				 * Fattura acquisto
				 */
				FatturaAcquisto: {
					id: 193,
					label: "intranet.ENUMS.ETipoDocumento.FatturaAcquisto"
				},

				/**
				 * Fattura di acquisto ordinaria
				 */
				FatturaAcquistoOrdinaria: {
					id: 194,
					label: "intranet.ENUMS.ETipoDocumento.FatturaAcquistoOrdinaria"
				},

				/**
				 * Fattura ordinaria
				 */
				FatturaOrdinaria: {
					id: 195,
					label: "intranet.ENUMS.ETipoDocumento.FatturaOrdinaria"
				},

				/**
				 * Generico
				 */
				Generico: {
					id: 196,
					label: "intranet.ENUMS.ETipoDocumento.Generico"
				},

				/**
				 * Mail conferma cliente finale
				 */
				MailConfermaClienteFinale: {
					id: 197,
					label: "intranet.ENUMS.ETipoDocumento.MailConfermaClienteFinale"
				},

				/**
				 * Mail prenotazione cliente finale
				 */
				MailPrenotazioneClienteFinale: {
					id: 198,
					label: "intranet.ENUMS.ETipoDocumento.MailPrenotazioneClienteFinale"
				},

				/**
				 * Miniguida
				 */
				Miniguida: {
					id: 199,
					label: "intranet.ENUMS.ETipoDocumento.Miniguida"
				},

				/**
				 * Multi documento
				 */
				MultiDocumento: {
					id: 200,
					label: "intranet.ENUMS.ETipoDocumento.MultiDocumento"
				},

				/**
				 * Nota Credito
				 */
				NotaCredito: {
					id: 201,
					label: "intranet.ENUMS.ETipoDocumento.NotaCredito"
				},

				/**
				 * Nota di credito acquisto
				 */
				NotaCreditoAcquisto: {
					id: 202,
					label: "intranet.ENUMS.ETipoDocumento.NotaCreditoAcquisto"
				},

				/**
				 * Nota di credito acquisto ordinaria
				 */
				NotaCreditoAcquistoOrdinaria: {
					id: 203,
					label: "intranet.ENUMS.ETipoDocumento.NotaCreditoAcquistoOrdinaria"
				},

				/**
				 * Nota di credito ordinaria
				 */
				NotaCreditoOrdinaria: {
					id: 204,
					label: "intranet.ENUMS.ETipoDocumento.NotaCreditoOrdinaria"
				},

				/**
				 * Nota di debito
				 */
				NotaDiDebito: {
					id: 205,
					label: "intranet.ENUMS.ETipoDocumento.NotaDiDebito"
				},

				/**
				 * Nota di debito ordinaria
				 */
				NotaDiDebitoOrdinaria: {
					id: 206,
					label: "intranet.ENUMS.ETipoDocumento.NotaDiDebitoOrdinaria"
				},

				/**
				 * Offerta
				 */
				Offerta: {
					id: 207,
					label: "intranet.ENUMS.ETipoDocumento.Offerta"
				},

				/**
				 * Prenotazione
				 */
				Prenotazione: {
					id: 208,
					label: "intranet.ENUMS.ETipoDocumento.Prenotazione"
				},

				/**
				 * Prenotazione host-to-host
				 */
				PrenotazioneH2H: {
					id: 209,
					label: "intranet.ENUMS.ETipoDocumento.PrenotazioneH2H"
				},

				/**
				 * Preventivo cliente
				 */
				PreventivoCliente: {
					id: 210,
					label: "intranet.ENUMS.ETipoDocumento.PreventivoCliente"
				},

				/**
				 * Preventivo cliente traducibile
				 */
				PreventivoClienteTraducibile: {
					id: 211,
					label: "intranet.ENUMS.ETipoDocumento.PreventivoClienteTraducibile"
				},

				/**
				 * Programma di viaggio
				 */
				ProgrammaViaggio: {
					id: 212,
					label: "intranet.ENUMS.ETipoDocumento.ProgrammaViaggio"
				},

				/**
				 * Programma di Viaggio 2022
				 */
				ProgrammaViaggio2022: {
					id: 213,
					label: "intranet.ENUMS.ETipoDocumento.ProgrammaViaggio2022"
				},

				/**
				 * Programma di viaggio traducibile
				 */
				ProgrammaViaggioTraducibile: {
					id: 214,
					label: "intranet.ENUMS.ETipoDocumento.ProgrammaViaggioTraducibile"
				},

				/**
				 * Rapporto Contabile
				 */
				RapportoContabile: {
					id: 215,
					label: "intranet.ENUMS.ETipoDocumento.RapportoContabile"
				},

				/**
				 * Riepilogo prenotazioni
				 */
				RiepilogoPrenotazioni: {
					id: 216,
					label: "intranet.ENUMS.ETipoDocumento.RiepilogoPrenotazioni"
				},

				/**
				 * Riepilogo prenotazioni accordo
				 */
				RiepilogoPrenotazioniAccordo: {
					id: 217,
					label: "intranet.ENUMS.ETipoDocumento.RiepilogoPrenotazioniAccordo"
				},

				/**
				 * Riepilogo prenotazioni NU
				 */
				RiepilogoPrenotazioniNU: {
					id: 218,
					label: "intranet.ENUMS.ETipoDocumento.RiepilogoPrenotazioniNU"
				},

				/**
				 * Risposta per adv
				 */
				RispostaPerAdv: {
					id: 219,
					label: "intranet.ENUMS.ETipoDocumento.RispostaPerAdv"
				},

				/**
				 * Risposta prenotazione
				 */
				RispostaPrenotazione: {
					id: 220,
					label: "intranet.ENUMS.ETipoDocumento.RispostaPrenotazione"
				},

				/**
				 * Storno autofattura
				 */
				StornoAutofattura: {
					id: 221,
					label: "intranet.ENUMS.ETipoDocumento.StornoAutofattura"
				},

				/**
				 * Voucher
				 */
				Voucher: {
					id: 222,
					label: "intranet.ENUMS.ETipoDocumento.Voucher"
				},

				/**
				 * Voucher turistico
				 */
				VoucherTuristico: {
					id: 223,
					label: "intranet.ENUMS.ETipoDocumento.VoucherTuristico"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoEmissioneVoucher
			 */
			ETipoEmissioneVoucher: {
				/**
				 * No
				 */
				Nessuna: {
					id: 224,
					label: "intranet.ENUMS.ETipoEmissioneVoucher.Nessuna"
				},

				/**
				 * Un voucher per offerta
				 */
				UnVoucherAOfferta: {
					id: 225,
					label: "intranet.ENUMS.ETipoEmissioneVoucher.UnVoucherAOfferta"
				},

				/**
				 * Un voucher a servizio
				 */
				UnVoucherAServizio: {
					id: 226,
					label: "intranet.ENUMS.ETipoEmissioneVoucher.UnVoucherAServizio"
				},

				/**
				 * Voucher del fornitore
				 */
				VoucherFornitore: {
					id: 227,
					label: "intranet.ENUMS.ETipoEmissioneVoucher.VoucherFornitore"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoEstrattoConto
			 */
			ETipoEstrattoConto: {
				/**
				 * Fine mese
				 */
				FineMese: {
					id: 228,
					label: "intranet.ENUMS.ETipoEstrattoConto.FineMese"
				},

				/**
				 * Travelfactoring
				 */
				Travelfactoring: {
					id: 229,
					label: "intranet.ENUMS.ETipoEstrattoConto.Travelfactoring"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoLink
			 */
			ETipoLink: {
				/**
				 * Contenitore di articoli, luoghi, servizi
				 */
				Contenitore: {
					id: 230,
					label: "intranet.ENUMS.ETipoLink.Contenitore"
				},

				/**
				 * Router Link
				 */
				RouterLink: {
					id: 231,
					label: "intranet.ENUMS.ETipoLink.RouterLink"
				},

				/**
				 * Url
				 */
				Url: {
					id: 232,
					label: "intranet.ENUMS.ETipoLink.Url"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoListaEmissioni
			 */
			ETipoListaEmissioni: {
				/**
				 * Richiesti da autorizzare
				 */
				DaAutorizzare: {
					id: 233,
					label: "intranet.ENUMS.ETipoListaEmissioni.DaAutorizzare"
				},

				/**
				 * Autorizzati da emettere
				 */
				DaEmettere: {
					id: 234,
					label: "intranet.ENUMS.ETipoListaEmissioni.DaEmettere"
				},

				/**
				 * Emessi
				 */
				Emessi: {
					id: 235,
					label: "intranet.ENUMS.ETipoListaEmissioni.Emessi"
				},

				/**
				 * Emissioni richieste
				 */
				NonPresiInCarico: {
					id: 236,
					label: "intranet.ENUMS.ETipoListaEmissioni.NonPresiInCarico"
				},

				/**
				 * Tutti
				 */
				Tutti: {
					id: 237,
					label: "intranet.ENUMS.ETipoListaEmissioni.Tutti"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoLuogo
			 */
			ETipoLuogo: {
				/**
				 * Aeroporto
				 */
				Aeroporto: {
					id: 238,
					label: "intranet.ENUMS.ETipoLuogo.Aeroporto"
				},

				/**
				 * Area
				 */
				Area: {
					id: 239,
					label: "intranet.ENUMS.ETipoLuogo.Area"
				},

				/**
				 * Città
				 */
				Citta: {
					id: 240,
					label: "intranet.ENUMS.ETipoLuogo.Citta"
				},

				/**
				 * Continente
				 */
				Continente: {
					id: 241,
					label: "intranet.ENUMS.ETipoLuogo.Continente"
				},

				/**
				 * Destinazione
				 */
				Destinazione: {
					id: 242,
					label: "intranet.ENUMS.ETipoLuogo.Destinazione"
				},

				/**
				 * Gruppi di città
				 */
				GruppiDiCitta: {
					id: 243,
					label: "intranet.ENUMS.ETipoLuogo.GruppiDiCitta"
				},

				/**
				 * Idee di viaggio
				 */
				IdeeDiViaggio: {
					id: 244,
					label: "intranet.ENUMS.ETipoLuogo.IdeeDiViaggio"
				},

				/**
				 * Paese
				 */
				Paese: {
					id: 245,
					label: "intranet.ENUMS.ETipoLuogo.Paese"
				},

				/**
				 * Pagina Tci
				 */
				PaginaTci: {
					id: 246,
					label: "intranet.ENUMS.ETipoLuogo.PaginaTci"
				},

				/**
				 * Pagina Web
				 */
				PaginaWeb: {
					id: 247,
					label: "intranet.ENUMS.ETipoLuogo.PaginaWeb"
				},

				/**
				 * Punto di interesse
				 */
				PuntoDiInteresse: {
					id: 248,
					label: "intranet.ENUMS.ETipoLuogo.PuntoDiInteresse"
				},

				/**
				 * Punto di noleggio
				 */
				PuntoNoleggio: {
					id: 249,
					label: "intranet.ENUMS.ETipoLuogo.PuntoNoleggio"
				},

				/**
				 * Quartiere / Località
				 */
				Quartiere: {
					id: 250,
					label: "intranet.ENUMS.ETipoLuogo.Quartiere"
				},

				/**
				 * Regione
				 */
				Regione: {
					id: 251,
					label: "intranet.ENUMS.ETipoLuogo.Regione"
				},

				/**
				 * Stato
				 */
				Stato: {
					id: 252,
					label: "intranet.ENUMS.ETipoLuogo.Stato"
				},

				/**
				 * Subzona aerea
				 */
				SubzonaAerea: {
					id: 253,
					label: "intranet.ENUMS.ETipoLuogo.SubzonaAerea"
				},

				/**
				 * Zona
				 */
				Zona: {
					id: 254,
					label: "intranet.ENUMS.ETipoLuogo.Zona"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoLuogoGiata
			 */
			ETipoLuogoGiata: {
				/**
				 * Città
				 */
				Citta: {
					id: 255,
					label: "intranet.ENUMS.ETipoLuogoGiata.Citta"
				},

				/**
				 * Destinazione
				 */
				Destinazione: {
					id: 256,
					label: "intranet.ENUMS.ETipoLuogoGiata.Destinazione"
				},

				/**
				 * Paese
				 */
				Paese: {
					id: 257,
					label: "intranet.ENUMS.ETipoLuogoGiata.Paese"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoLuogoRicercaPrezzi
			 */
			ETipoLuogoRicercaPrezzi: {
				/**
				 * Fornitore
				 */
				LuogoFornitore: {
					id: 258,
					label: "intranet.ENUMS.ETipoLuogoRicercaPrezzi.LuogoFornitore"
				},

				/**
				 * Naar
				 */
				LuogoNaar: {
					id: 259,
					label: "intranet.ENUMS.ETipoLuogoRicercaPrezzi.LuogoNaar"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoOperazioneIncasso
			 */
			ETipoOperazioneIncasso: {
				/**
				 * Acconto
				 */
				Acconto: {
					id: 260,
					label: "intranet.ENUMS.ETipoOperazioneIncasso.Acconto"
				},

				/**
				 * Cessione
				 */
				Cessione: {
					id: 261,
					label: "intranet.ENUMS.ETipoOperazioneIncasso.Cessione"
				},

				/**
				 * Saldo
				 */
				Saldo: {
					id: 262,
					label: "intranet.ENUMS.ETipoOperazioneIncasso.Saldo"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoPagamentoIncasso
			 */
			ETipoPagamentoIncasso: {
				/**
				 * Assegno
				 */
				Assegno: {
					id: 263,
					label: "intranet.ENUMS.ETipoPagamentoIncasso.Assegno"
				},

				/**
				 * Bonifico
				 */
				Bonifico: {
					id: 264,
					label: "intranet.ENUMS.ETipoPagamentoIncasso.Bonifico"
				},

				/**
				 * Compensazione
				 */
				Compensazione: {
					id: 265,
					label: "intranet.ENUMS.ETipoPagamentoIncasso.Compensazione"
				},

				/**
				 * Contanti
				 */
				Contanti: {
					id: 266,
					label: "intranet.ENUMS.ETipoPagamentoIncasso.Contanti"
				},

				/**
				 * Factoring
				 */
				Factoring: {
					id: 267,
					label: "intranet.ENUMS.ETipoPagamentoIncasso.Factoring"
				},

				/**
				 * PayPal
				 */
				PAYPAL: {
					id: 268,
					label: "intranet.ENUMS.ETipoPagamentoIncasso.PAYPAL"
				},

				/**
				 * POS
				 */
				POS: {
					id: 269,
					label: "intranet.ENUMS.ETipoPagamentoIncasso.POS"
				},

				/**
				 * RID
				 */
				RID: {
					id: 270,
					label: "intranet.ENUMS.ETipoPagamentoIncasso.RID"
				},

				/**
				 * TravelFactoring
				 */
				TravelFactoring: {
					id: 271,
					label: "intranet.ENUMS.ETipoPagamentoIncasso.TravelFactoring"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoRelazioneContatto
			 */
			ETipoRelazioneContatto: {
				/**
				 * Competa A
				 */
				CompetaA: {
					id: 272,
					label: "intranet.ENUMS.ETipoRelazioneContatto.CompetaA"
				},

				/**
				 * Dipendente Di
				 */
				DipendenteDi: {
					id: 273,
					label: "intranet.ENUMS.ETipoRelazioneContatto.DipendenteDi"
				},

				/**
				 * Membro Del Gruppo
				 */
				MembroDelGruppo: {
					id: 274,
					label: "intranet.ENUMS.ETipoRelazioneContatto.MembroDelGruppo"
				},

				/**
				 * Membro Del Sottogruppo
				 */
				MembroDelSottogruppo: {
					id: 275,
					label: "intranet.ENUMS.ETipoRelazioneContatto.MembroDelSottogruppo"
				},

				/**
				 * Ufficio Di
				 */
				UfficioDi: {
					id: 276,
					label: "intranet.ENUMS.ETipoRelazioneContatto.UfficioDi"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoRicercaArticolo
			 */
			ETipoRicercaArticolo: {
				/**
				 * Note Compagnia Aerea
				 */
				NoteCompagniaAerea: {
					id: 277,
					label: "intranet.ENUMS.ETipoRicercaArticolo.NoteCompagniaAerea"
				},

				/**
				 * Note Documento
				 */
				NoteDocumento: {
					id: 278,
					label: "intranet.ENUMS.ETipoRicercaArticolo.NoteDocumento"
				},

				/**
				 * Note servizio
				 */
				NoteServizio: {
					id: 279,
					label: "intranet.ENUMS.ETipoRicercaArticolo.NoteServizio"
				},

				/**
				 * Standard
				 */
				Standard: {
					id: 280,
					label: "intranet.ENUMS.ETipoRicercaArticolo.Standard"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoRicercaLuogo
			 */
			ETipoRicercaLuogo: {
				/**
				 * Non cercare
				 */
				NoRicerca: {
					id: 281,
					label: "intranet.ENUMS.ETipoRicercaLuogo.NoRicerca"
				},

				/**
				 * Solo tour
				 */
				SoloTour: {
					id: 282,
					label: "intranet.ENUMS.ETipoRicercaLuogo.SoloTour"
				},

				/**
				 * Tutto
				 */
				Tutto: {
					id: 283,
					label: "intranet.ENUMS.ETipoRicercaLuogo.Tutto"
				},

				/**
				 * Tutto tranne gli hotel
				 */
				TuttoSalvoHotel: {
					id: 284,
					label: "intranet.ENUMS.ETipoRicercaLuogo.TuttoSalvoHotel"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoServizio
			 */
			ETipoServizio: {
				/**
				 * Hotel
				 */
				Hotel: {
					id: 285,
					label: "intranet.ENUMS.ETipoServizio.Hotel"
				},

				/**
				 * Itinerari consigliati
				 */
				ItinerariConsigliati: {
					id: 286,
					label: "intranet.ENUMS.ETipoServizio.ItinerariConsigliati"
				},

				/**
				 * Kit viaggio
				 */
				KitViaggio: {
					id: 287,
					label: "intranet.ENUMS.ETipoServizio.KitViaggio"
				},

				/**
				 * Kit viaggio OLD
				 */
				KitViaggioOLD: {
					id: 288,
					label: "intranet.ENUMS.ETipoServizio.KitViaggioOLD"
				},

				/**
				 * Nessuno
				 */
				Nessuno: {
					id: 289,
					label: "intranet.ENUMS.ETipoServizio.Nessuno"
				},

				/**
				 * Noleggi auto
				 */
				NoleggiAuto: {
					id: 290,
					label: "intranet.ENUMS.ETipoServizio.NoleggiAuto"
				},

				/**
				 * Pacchetti
				 */
				Pacchetti: {
					id: 291,
					label: "intranet.ENUMS.ETipoServizio.Pacchetti"
				},

				/**
				 * Pacchetti catalogo
				 */
				PacchettiCatalogo: {
					id: 292,
					label: "intranet.ENUMS.ETipoServizio.PacchettiCatalogo"
				},

				/**
				 * Pacchetti dinamici
				 */
				PacchettiDinamici: {
					id: 293,
					label: "intranet.ENUMS.ETipoServizio.PacchettiDinamici"
				},

				/**
				 * Selfdrive
				 */
				SelfDrive: {
					id: 294,
					label: "intranet.ENUMS.ETipoServizio.SelfDrive"
				},

				/**
				 * Tour e crociere
				 */
				TourECrociere: {
					id: 295,
					label: "intranet.ENUMS.ETipoServizio.TourECrociere"
				},

				/**
				 * Trasferimenti e visite
				 */
				TrasferimentiEVisite: {
					id: 296,
					label: "intranet.ENUMS.ETipoServizio.TrasferimentiEVisite"
				},

				/**
				 * 
				 */
				undefined: {
					id: 297,
					label: "intranet.ENUMS.ETipoServizio.undefined"
				},

				/**
				 * Voli
				 */
				Voli: {
					id: 298,
					label: "intranet.ENUMS.ETipoServizio.Voli"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoServizioFlag
			 */
			ETipoServizioFlag: {
				/**
				 * Crociere
				 */
				Crociere: {
					id: 299,
					label: "intranet.ENUMS.ETipoServizioFlag.Crociere"
				},

				/**
				 * Hotel
				 */
				Hotel: {
					id: 300,
					label: "intranet.ENUMS.ETipoServizioFlag.Hotel"
				},

				/**
				 * Noleggio
				 */
				Noleggi: {
					id: 301,
					label: "intranet.ENUMS.ETipoServizioFlag.Noleggi"
				},

				/**
				 * Pacchetti
				 */
				Pacchetti: {
					id: 302,
					label: "intranet.ENUMS.ETipoServizioFlag.Pacchetti"
				},

				/**
				 * Selfdrive
				 */
				SelfDrive: {
					id: 303,
					label: "intranet.ENUMS.ETipoServizioFlag.SelfDrive"
				},

				/**
				 * Tour
				 */
				Tour: {
					id: 304,
					label: "intranet.ENUMS.ETipoServizioFlag.Tour"
				},

				/**
				 * Trasferimenti
				 */
				Trasferimenti: {
					id: 305,
					label: "intranet.ENUMS.ETipoServizioFlag.Trasferimenti"
				},

				/**
				 * Qualsiasi tipo di servizio
				 */
				undefined: {
					id: 306,
					label: "intranet.ENUMS.ETipoServizioFlag.undefined"
				},

				/**
				 * Visite
				 */
				Visite: {
					id: 307,
					label: "intranet.ENUMS.ETipoServizioFlag.Visite"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoSupplemento
			 */
			ETipoSupplemento: {
				/**
				 * Appartamento
				 */
				Appartamento: {
					id: 308,
					label: "intranet.ENUMS.ETipoSupplemento.Appartamento"
				},

				/**
				 * Camera
				 */
				Camera: {
					id: 309,
					label: "intranet.ENUMS.ETipoSupplemento.Camera"
				},

				/**
				 * N/D
				 */
				Ignoto: {
					id: 310,
					label: "intranet.ENUMS.ETipoSupplemento.Ignoto"
				},

				/**
				 * Letto aggiunto
				 */
				LettoAggiunto: {
					id: 311,
					label: "intranet.ENUMS.ETipoSupplemento.LettoAggiunto"
				},

				/**
				 * Offerta / Riduzione
				 */
				OfferteRid: {
					id: 312,
					label: "intranet.ENUMS.ETipoSupplemento.OfferteRid"
				},

				/**
				 * Pasto
				 */
				Pasti: {
					id: 313,
					label: "intranet.ENUMS.ETipoSupplemento.Pasti"
				},

				/**
				 * Trasferimento
				 */
				Trasferimenti: {
					id: 314,
					label: "intranet.ENUMS.ETipoSupplemento.Trasferimenti"
				},

				/**
				 * Visita
				 */
				Visite: {
					id: 315,
					label: "intranet.ENUMS.ETipoSupplemento.Visite"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoTabellaCostiCommissioni
			 */
			ETipoTabellaCostiCommissioni: {
				/**
				 * Last minute
				 */
				LastMinute: {
					id: 316,
					label: "intranet.ENUMS.ETipoTabellaCostiCommissioni.LastMinute"
				},

				/**
				 * Prenota Prima
				 */
				PrenotaPrima: {
					id: 317,
					label: "intranet.ENUMS.ETipoTabellaCostiCommissioni.PrenotaPrima"
				},

				/**
				 * Standard
				 */
				Standard: {
					id: 318,
					label: "intranet.ENUMS.ETipoTabellaCostiCommissioni.Standard"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoTicketBiglietteria
			 */
			ETipoTicketBiglietteria: {
				/**
				 * IP
				 */
				IP: {
					id: 319,
					label: "intranet.ENUMS.ETipoTicketBiglietteria.IP"
				},

				/**
				 * TO
				 */
				TO: {
					id: 320,
					label: "intranet.ENUMS.ETipoTicketBiglietteria.TO"
				},

				/**
				 * Tutto
				 */
				Tutto: {
					id: 321,
					label: "intranet.ENUMS.ETipoTicketBiglietteria.Tutto"
				},

				/**
				 * ENUMS.ETipoTicketBiglietteria.2
				 */
				_2: {
					id: 6571,
					label: "intranet.ENUMS.ETipoTicketBiglietteria.2"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoTrasporto
			 */
			ETipoTrasporto: {
				/**
				 * Aereo
				 */
				Aereo: {
					id: 322,
					label: "intranet.ENUMS.ETipoTrasporto.Aereo"
				},

				/**
				 * Auto
				 */
				Auto: {
					id: 323,
					label: "intranet.ENUMS.ETipoTrasporto.Auto"
				},

				/**
				 * Indefinito
				 */
				Indefinito: {
					id: 324,
					label: "intranet.ENUMS.ETipoTrasporto.Indefinito"
				},

				/**
				 * Traghetto
				 */
				Traghetto: {
					id: 325,
					label: "intranet.ENUMS.ETipoTrasporto.Traghetto"
				},

				/**
				 * Treno
				 */
				Treno: {
					id: 326,
					label: "intranet.ENUMS.ETipoTrasporto.Treno"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoUtente
			 */
			ETipoUtente: {
				/**
				 * Agenzia
				 */
				Agenzia: {
					id: 327,
					label: "intranet.ENUMS.ETipoUtente.Agenzia"
				},

				/**
				 * Intranet
				 */
				Intranet: {
					id: 328,
					label: "intranet.ENUMS.ETipoUtente.Intranet"
				},

				/**
				 * Privato
				 */
				Privato: {
					id: 329,
					label: "intranet.ENUMS.ETipoUtente.Privato"
				},

				/**
				 * Test per certificazioni
				 */
				TestCertificazioni: {
					id: 330,
					label: "intranet.ENUMS.ETipoUtente.TestCertificazioni"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETitolo
			 */
			ETitolo: {
				/**
				 * Bambina
				 */
				Bambina: {
					id: 331,
					label: "intranet.ENUMS.ETitolo.Bambina"
				},

				/**
				 * Bambino
				 */
				Bambino: {
					id: 332,
					label: "intranet.ENUMS.ETitolo.Bambino"
				},

				/**
				 * Dottor
				 */
				Dottor: {
					id: 333,
					label: "intranet.ENUMS.ETitolo.Dottor"
				},

				/**
				 * Dottssa
				 */
				Dottssa: {
					id: 334,
					label: "intranet.ENUMS.ETitolo.Dottssa"
				},

				/**
				 * Sas
				 */
				Sas: {
					id: 335,
					label: "intranet.ENUMS.ETitolo.Sas"
				},

				/**
				 * Sconosciuto
				 */
				Sconosciuto: {
					id: 336,
					label: "intranet.ENUMS.ETitolo.Sconosciuto"
				},

				/**
				 * Signor
				 */
				Signor: {
					id: 337,
					label: "intranet.ENUMS.ETitolo.Signor"
				},

				/**
				 * Signora
				 */
				Signora: {
					id: 338,
					label: "intranet.ENUMS.ETitolo.Signora"
				},

				/**
				 * Snc
				 */
				Snc: {
					id: 339,
					label: "intranet.ENUMS.ETitolo.Snc"
				},

				/**
				 * SpA
				 */
				SpA: {
					id: 340,
					label: "intranet.ENUMS.ETitolo.SpA"
				},

				/**
				 * Srl
				 */
				Srl: {
					id: 341,
					label: "intranet.ENUMS.ETitolo.Srl"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ApplicationHandledFilter
			 */
			ApplicationHandledFilter: {
				/**
				 * Gestiti
				 */
				Handled: {
					id: 5758,
					label: "intranet.ENUMS.ApplicationHandledFilter.Handled"
				},

				/**
				 * All
				 */
				None: {
					id: 5759,
					label: "intranet.ENUMS.ApplicationHandledFilter.None"
				},

				/**
				 * Non gestiti
				 */
				Unhandled: {
					id: 5760,
					label: "intranet.ENUMS.ApplicationHandledFilter.Unhandled"
				},

			},

			/**
			 * Content of path intranet.ENUMS.DebugErrorsFilter
			 */
			DebugErrorsFilter: {
				/**
				 * Debug
				 */
				DebugOnly: {
					id: 5761,
					label: "intranet.ENUMS.DebugErrorsFilter.DebugOnly"
				},

				/**
				 * All
				 */
				None: {
					id: 5762,
					label: "intranet.ENUMS.DebugErrorsFilter.None"
				},

				/**
				 * Prod
				 */
				ProdOnly: {
					id: 5763,
					label: "intranet.ENUMS.DebugErrorsFilter.ProdOnly"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EAbbinamentoSettimana
			 */
			EAbbinamentoSettimana: {
				/**
				 * alla settimana precedente
				 */
				SettimanaPrecedente: {
					id: 5764,
					label: "intranet.ENUMS.EAbbinamentoSettimana.SettimanaPrecedente"
				},

				/**
				 * alla Settimana precedente e/o successiva
				 */
				SettimanaPrimaODopo: {
					id: 5765,
					label: "intranet.ENUMS.EAbbinamentoSettimana.SettimanaPrimaODopo"
				},

				/**
				 * alla settimana successiva
				 */
				SettimanaSuccessiva: {
					id: 5766,
					label: "intranet.ENUMS.EAbbinamentoSettimana.SettimanaSuccessiva"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EApplSettAbbinata
			 */
			EApplSettAbbinata: {
				/**
				 * La settimana abbinata a quella che inizia il {gg}
				 */
				SettimanaAbbinata: {
					id: 5767,
					label: "intranet.ENUMS.EApplSettAbbinata.SettimanaAbbinata"
				},

				/**
				 * La settimana che inizia il {gg}
				 */
				SettimanaIndicata: {
					id: 5768,
					label: "intranet.ENUMS.EApplSettAbbinata.SettimanaIndicata"
				},

				/**
				 * Tutto il periodo
				 */
				Tutto: {
					id: 5769,
					label: "intranet.ENUMS.EApplSettAbbinata.Tutto"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EAutofillFlags
			 */
			EAutofillFlags: {
				/**
				 * Sempre
				 */
				ExcludeFlightSearch: {
					id: 5770,
					label: "intranet.ENUMS.EAutofillFlags.ExcludeFlightSearch"
				},

				/**
				 * Include hotel vicini all'aeroporto
				 */
				IncludeAirportHotels: {
					id: 5771,
					label: "intranet.ENUMS.EAutofillFlags.IncludeAirportHotels"
				},

				/**
				 * Sempre
				 */
				IncludeInstantPayHotels: {
					id: 5772,
					label: "intranet.ENUMS.EAutofillFlags.IncludeInstantPayHotels"
				},

				/**
				 * Sempre
				 */
				IncludeNotRefundableHotels: {
					id: 5773,
					label: "intranet.ENUMS.EAutofillFlags.IncludeNotRefundableHotels"
				},

				/**
				 * Include hotel su richiesta
				 */
				IncludeOnRequestHotels: {
					id: 5774,
					label: "intranet.ENUMS.EAutofillFlags.IncludeOnRequestHotels"
				},

				/**
				 * Include hotel su richiesta o vicini all'aeroporto
				 */
				IncludeOnRequestHotelsOrIncludeAirportHotels: {
					id: 5775,
					label: "intranet.ENUMS.EAutofillFlags.IncludeOnRequestHotelsOrIncludeAirportHotels"
				},

				/**
				 * Sempre
				 */
				None: {
					id: 5776,
					label: "intranet.ENUMS.EAutofillFlags.None"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ECategoriaArticolo
			 */
			ECategoriaArticolo: {
				/**
				 * Elementi Articoli
				 */
				ElementiArticoli: {
					id: 5777,
					label: "intranet.ENUMS.ECategoriaArticolo.ElementiArticoli"
				},

				/**
				 * Miniguida
				 */
				Miniguida: {
					id: 5778,
					label: "intranet.ENUMS.ECategoriaArticolo.Miniguida"
				},

				/**
				 * Obsoleto
				 */
				Obsoleto: {
					id: 5779,
					label: "intranet.ENUMS.ECategoriaArticolo.Obsoleto"
				},

				/**
				 * Offerte
				 */
				Offerte: {
					id: 5780,
					label: "intranet.ENUMS.ECategoriaArticolo.Offerte"
				},

				/**
				 * Pre Produzione
				 */
				PreProduzione: {
					id: 5781,
					label: "intranet.ENUMS.ECategoriaArticolo.PreProduzione"
				},

				/**
				 * Produzione
				 */
				Produzione: {
					id: 5782,
					label: "intranet.ENUMS.ECategoriaArticolo.Produzione"
				},

				/**
				 * Testi Catalogo
				 */
				TestiCatalogo: {
					id: 5783,
					label: "intranet.ENUMS.ECategoriaArticolo.TestiCatalogo"
				},

				/**
				 * Testi Stampe
				 */
				TestiStampe: {
					id: 5784,
					label: "intranet.ENUMS.ECategoriaArticolo.TestiStampe"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ECentroDiCosto
			 */
			ECentroDiCosto: {
				/**
				 * Aliviaggi
				 */
				Aliviaggi: {
					id: 5785,
					label: "intranet.ENUMS.ECentroDiCosto.Aliviaggi"
				},

				/**
				 * Anyway
				 */
				Anyway: {
					id: 5786,
					label: "intranet.ENUMS.ECentroDiCosto.Anyway"
				},

				/**
				 * Aviomar
				 */
				Aviomar: {
					id: 5787,
					label: "intranet.ENUMS.ECentroDiCosto.Aviomar"
				},

				/**
				 * DriveOut
				 */
				DriveOut: {
					id: 5788,
					label: "intranet.ENUMS.ECentroDiCosto.DriveOut"
				},

				/**
				 * Naar
				 */
				Naar: {
					id: 5789,
					label: "intranet.ENUMS.ECentroDiCosto.Naar"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EContabilizzazione
			 */
			EContabilizzazione: {
				/**
				 * Cee
				 */
				Cee: {
					id: 5790,
					label: "intranet.ENUMS.EContabilizzazione.Cee"
				},

				/**
				 * Extra Cee
				 */
				ExtraCee: {
					id: 5791,
					label: "intranet.ENUMS.EContabilizzazione.ExtraCee"
				},

				/**
				 * Indefinito
				 */
				INDEFINITO: {
					id: 5792,
					label: "intranet.ENUMS.EContabilizzazione.INDEFINITO"
				},

				/**
				 * Italia
				 */
				Italia: {
					id: 5793,
					label: "intranet.ENUMS.EContabilizzazione.Italia"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EDatoClienteMancante
			 */
			EDatoClienteMancante: {
				/**
				 * Codice fiscale leader
				 */
				CodiceFiscale: {
					id: 5794,
					label: "intranet.ENUMS.EDatoClienteMancante.CodiceFiscale"
				},

				/**
				 * Email contatto
				 */
				Email: {
					id: 5795,
					label: "intranet.ENUMS.EDatoClienteMancante.Email"
				},

				/**
				 * Cellulare cliente
				 */
				NumeroCellulare: {
					id: 5796,
					label: "intranet.ENUMS.EDatoClienteMancante.NumeroCellulare"
				},

				/**
				 * Numero di passaporto
				 */
				NumeroPassaporto: {
					id: 5797,
					label: "intranet.ENUMS.EDatoClienteMancante.NumeroPassaporto"
				},

				/**
				 * Paese rilascio passaporto
				 */
				PaeseRilascioPassaporto: {
					id: 5798,
					label: "intranet.ENUMS.EDatoClienteMancante.PaeseRilascioPassaporto"
				},

				/**
				 * Scadenza passaporto
				 */
				ScadenzaPassaporto: {
					id: 5799,
					label: "intranet.ENUMS.EDatoClienteMancante.ScadenzaPassaporto"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EEmailAddressType
			 */
			EEmailAddressType: {
				/**
				 * Bcc
				 */
				Bcc: {
					id: 5800,
					label: "intranet.ENUMS.EEmailAddressType.Bcc"
				},

				/**
				 * Cc
				 */
				Cc: {
					id: 5801,
					label: "intranet.ENUMS.EEmailAddressType.Cc"
				},

				/**
				 * To
				 */
				To: {
					id: 5802,
					label: "intranet.ENUMS.EEmailAddressType.To"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EEntrataUscita
			 */
			EEntrataUscita: {
				/**
				 * Entrata
				 */
				Entrata: {
					id: 5803,
					label: "intranet.ENUMS.EEntrataUscita.Entrata"
				},

				/**
				 * Entrata e uscita
				 */
				InOut: {
					id: 5804,
					label: "intranet.ENUMS.EEntrataUscita.InOut"
				},

				/**
				 * Uscita
				 */
				Uscita: {
					id: 5805,
					label: "intranet.ENUMS.EEntrataUscita.Uscita"
				},

			},

			/**
			 * Content of path intranet.ENUMS.eExportSet
			 */
			eExportSet: {
				/**
				 * Tutto
				 */
				All: {
					id: 5806,
					label: "intranet.ENUMS.eExportSet.All"
				},

				/**
				 * Pagina corrente
				 */
				CurrentPage: {
					id: 5807,
					label: "intranet.ENUMS.eExportSet.CurrentPage"
				},

				/**
				 * Dati filtrati
				 */
				Filtered: {
					id: 5808,
					label: "intranet.ENUMS.eExportSet.Filtered"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EFiltroScadenzaAccordi
			 */
			EFiltroScadenzaAccordi: {
				/**
				 * Non scaduti
				 */
				NonScaduti: {
					id: 5809,
					label: "intranet.ENUMS.EFiltroScadenzaAccordi.NonScaduti"
				},

				/**
				 * Qualsiasi
				 */
				Qualsiasi: {
					id: 5810,
					label: "intranet.ENUMS.EFiltroScadenzaAccordi.Qualsiasi"
				},

				/**
				 * Scaduti
				 */
				Scaduti: {
					id: 5811,
					label: "intranet.ENUMS.EFiltroScadenzaAccordi.Scaduti"
				},

				/**
				 * Validi adesso
				 */
				ValidiAdesso: {
					id: 5812,
					label: "intranet.ENUMS.EFiltroScadenzaAccordi.ValidiAdesso"
				},

				/**
				 * Validi in futuro
				 */
				ValidiInFuturo: {
					id: 5813,
					label: "intranet.ENUMS.EFiltroScadenzaAccordi.ValidiInFuturo"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EGiornoSettimana
			 */
			EGiornoSettimana: {
				/**
				 * Dom
				 */
				Domenica: {
					id: 5814,
					label: "intranet.ENUMS.EGiornoSettimana.Domenica"
				},

				/**
				 * Gio
				 */
				Giovedi: {
					id: 5815,
					label: "intranet.ENUMS.EGiornoSettimana.Giovedi"
				},

				/**
				 * Lun
				 */
				Lunedi: {
					id: 5816,
					label: "intranet.ENUMS.EGiornoSettimana.Lunedi"
				},

				/**
				 * Mar
				 */
				Martedi: {
					id: 5817,
					label: "intranet.ENUMS.EGiornoSettimana.Martedi"
				},

				/**
				 * Mer
				 */
				Mercoledi: {
					id: 5818,
					label: "intranet.ENUMS.EGiornoSettimana.Mercoledi"
				},

				/**
				 * Sab
				 */
				Sabato: {
					id: 5819,
					label: "intranet.ENUMS.EGiornoSettimana.Sabato"
				},

				/**
				 * Ven
				 */
				Venerdi: {
					id: 5820,
					label: "intranet.ENUMS.EGiornoSettimana.Venerdi"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EGiornoSettimanaAbbr
			 */
			EGiornoSettimanaAbbr: {
				/**
				 * D
				 */
				Domenica: {
					id: 5821,
					label: "intranet.ENUMS.EGiornoSettimanaAbbr.Domenica"
				},

				/**
				 * G
				 */
				Giovedi: {
					id: 5822,
					label: "intranet.ENUMS.EGiornoSettimanaAbbr.Giovedi"
				},

				/**
				 * L
				 */
				Lunedi: {
					id: 5823,
					label: "intranet.ENUMS.EGiornoSettimanaAbbr.Lunedi"
				},

				/**
				 * M
				 */
				Martedi: {
					id: 5824,
					label: "intranet.ENUMS.EGiornoSettimanaAbbr.Martedi"
				},

				/**
				 * m
				 */
				Mercoledi: {
					id: 5825,
					label: "intranet.ENUMS.EGiornoSettimanaAbbr.Mercoledi"
				},

				/**
				 * S
				 */
				Sabato: {
					id: 5826,
					label: "intranet.ENUMS.EGiornoSettimanaAbbr.Sabato"
				},

				/**
				 * V
				 */
				Venerdi: {
					id: 5827,
					label: "intranet.ENUMS.EGiornoSettimanaAbbr.Venerdi"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EGruppoApplicazione
			 */
			EGruppoApplicazione: {
				/**
				 * Gli 'adulti' con età oltre {eta2} ann{eta2, plural, =0{i} one{o} other{i}}
				 */
				OltreEta2: {
					id: 5828,
					label: "intranet.ENUMS.EGruppoApplicazione.OltreEta2"
				},

				/**
				 * Gli 'infant' con meno di {eta1} ann{eta1, plural, =0{i} one{o} other{i}}
				 */
				SottoEta1: {
					id: 5829,
					label: "intranet.ENUMS.EGruppoApplicazione.SottoEta1"
				},

				/**
				 * I 'bambini' con età compresa tra {eta1} e {eta2} anni
				 */
				TraEta1e2: {
					id: 5830,
					label: "intranet.ENUMS.EGruppoApplicazione.TraEta1e2"
				},

				/**
				 * Tutti i passeggeri
				 */
				Tutti: {
					id: 5831,
					label: "intranet.ENUMS.EGruppoApplicazione.Tutti"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EH2HTipoLocation
			 */
			EH2HTipoLocation: {
				/**
				 * Aeroporto
				 */
				Aeroporto: {
					id: 5832,
					label: "intranet.ENUMS.EH2HTipoLocation.Aeroporto"
				},

				/**
				 * Indefinita
				 */
				Indefinita: {
					id: 5833,
					label: "intranet.ENUMS.EH2HTipoLocation.Indefinita"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EImageCategory
			 */
			EImageCategory: {
				/**
				 * Animali
				 */
				Animals: {
					id: 5834,
					label: "intranet.ENUMS.EImageCategory.Animals"
				},

				/**
				 * Sfondi
				 */
				Backgrounds: {
					id: 5835,
					label: "intranet.ENUMS.EImageCategory.Backgrounds"
				},

				/**
				 * Edifici
				 */
				Buildings: {
					id: 5836,
					label: "intranet.ENUMS.EImageCategory.Buildings"
				},

				/**
				 * Affari
				 */
				Business: {
					id: 5837,
					label: "intranet.ENUMS.EImageCategory.Business"
				},

				/**
				 * Computer
				 */
				Computer: {
					id: 5838,
					label: "intranet.ENUMS.EImageCategory.Computer"
				},

				/**
				 * Educazione
				 */
				Education: {
					id: 5839,
					label: "intranet.ENUMS.EImageCategory.Education"
				},

				/**
				 * Moda
				 */
				Fashion: {
					id: 5840,
					label: "intranet.ENUMS.EImageCategory.Fashion"
				},

				/**
				 * Sentimenti
				 */
				Feelings: {
					id: 5841,
					label: "intranet.ENUMS.EImageCategory.Feelings"
				},

				/**
				 * Cibo
				 */
				Food: {
					id: 5842,
					label: "intranet.ENUMS.EImageCategory.Food"
				},

				/**
				 * Salute
				 */
				Health: {
					id: 5843,
					label: "intranet.ENUMS.EImageCategory.Health"
				},

				/**
				 * Industria
				 */
				Industry: {
					id: 5844,
					label: "intranet.ENUMS.EImageCategory.Industry"
				},

				/**
				 * Musica
				 */
				Music: {
					id: 5845,
					label: "intranet.ENUMS.EImageCategory.Music"
				},

				/**
				 * Natura
				 */
				Nature: {
					id: 5846,
					label: "intranet.ENUMS.EImageCategory.Nature"
				},

				/**
				 * Persone
				 */
				People: {
					id: 5847,
					label: "intranet.ENUMS.EImageCategory.People"
				},

				/**
				 * Luoghi
				 */
				Places: {
					id: 5848,
					label: "intranet.ENUMS.EImageCategory.Places"
				},

				/**
				 * Religione
				 */
				Religion: {
					id: 5849,
					label: "intranet.ENUMS.EImageCategory.Religion"
				},

				/**
				 * Scienza
				 */
				Science: {
					id: 5850,
					label: "intranet.ENUMS.EImageCategory.Science"
				},

				/**
				 * Sport
				 */
				Sports: {
					id: 5851,
					label: "intranet.ENUMS.EImageCategory.Sports"
				},

				/**
				 * Trasporti
				 */
				Transportation: {
					id: 5852,
					label: "intranet.ENUMS.EImageCategory.Transportation"
				},

				/**
				 * Viaggi
				 */
				Travel: {
					id: 5853,
					label: "intranet.ENUMS.EImageCategory.Travel"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EImageOrientation
			 */
			EImageOrientation: {
				/**
				 * Qualsiasi
				 */
				All: {
					id: 5854,
					label: "intranet.ENUMS.EImageOrientation.All"
				},

				/**
				 * Orizzontale
				 */
				Horizontal: {
					id: 5855,
					label: "intranet.ENUMS.EImageOrientation.Horizontal"
				},

				/**
				 * Verticale
				 */
				Vertical: {
					id: 5856,
					label: "intranet.ENUMS.EImageOrientation.Vertical"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EWebTranslatableTable
			 */
			EWebTranslatableTable: {
				/**
				 * Dati servizi
				 */
				DATI_AGG_SERVIZI: {
					id: 6013,
					label: "intranet.ENUMS.EWebTranslatableTable.DATI_AGG_SERVIZI"
				},

				/**
				 * Luoghi
				 */
				LUOGHI: {
					id: 6014,
					label: "intranet.ENUMS.EWebTranslatableTable.LUOGHI"
				},

				/**
				 * Servizi
				 */
				SERVIZI: {
					id: 6015,
					label: "intranet.ENUMS.EWebTranslatableTable.SERVIZI"
				},

				/**
				 * Tipi servizi
				 */
				SERVIZI_TIPI: {
					id: 6016,
					label: "intranet.ENUMS.EWebTranslatableTable.SERVIZI_TIPI"
				},

				/**
				 * Trattamenti servizi
				 */
				SERV_TRATTAMENTI: {
					id: 6017,
					label: "intranet.ENUMS.EWebTranslatableTable.SERV_TRATTAMENTI"
				},

				/**
				 * Status voli
				 */
				STATUS_VOLI: {
					id: 6018,
					label: "intranet.ENUMS.EWebTranslatableTable.STATUS_VOLI"
				},

				/**
				 * Tipi dati aggiuntivi
				 */
				TIPI_DATI_AGGIUNTIVI: {
					id: 6019,
					label: "intranet.ENUMS.EWebTranslatableTable.TIPI_DATI_AGGIUNTIVI"
				},

				/**
				 * Tipologie standard servizi
				 */
				TIPOLOGIE_STANDARD_SERVIZI: {
					id: 6198,
					label: "intranet.ENUMS.EWebTranslatableTable.TIPOLOGIE_STANDARD_SERVIZI"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoEntita
			 */
			ETipoEntita: {
				/**
				 * Luogo
				 */
				Luogo: {
					id: 6071,
					label: "intranet.ENUMS.ETipoEntita.Luogo"
				},

				/**
				 * Servizio
				 */
				Servizio: {
					id: 6072,
					label: "intranet.ENUMS.ETipoEntita.Servizio"
				},

				/**
				 * Articolo
				 */
				Articolo: {
					id: 6073,
					label: "intranet.ENUMS.ETipoEntita.Articolo"
				},

				/**
				 * Contatto
				 */
				Contatto: {
					id: 6074,
					label: "intranet.ENUMS.ETipoEntita.Contatto"
				},

				/**
				 * Widget servizio
				 */
				WidgetServizioCard: {
					id: 6075,
					label: "intranet.ENUMS.ETipoEntita.WidgetServizioCard"
				},

				/**
				 * Immagine
				 */
				Immagine: {
					id: 6076,
					label: "intranet.ENUMS.ETipoEntita.Immagine"
				},

				/**
				 * Widget offerta
				 */
				WidgetOffertaCard: {
					id: 6077,
					label: "intranet.ENUMS.ETipoEntita.WidgetOffertaCard"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETipoViaggio
			 */
			ETipoViaggio: {
				/**
				 * Indefinito
				 */
				Indefinito: {
					id: 6153,
					label: "intranet.ENUMS.ETipoViaggio.Indefinito"
				},

				/**
				 * VIP
				 */
				Vip: {
					id: 6154,
					label: "intranet.ENUMS.ETipoViaggio.Vip"
				},

				/**
				 * Gruppo
				 */
				Gruppo: {
					id: 6155,
					label: "intranet.ENUMS.ETipoViaggio.Gruppo"
				},

				/**
				 * TCI
				 */
				Tci: {
					id: 6156,
					label: "intranet.ENUMS.ETipoViaggio.Tci"
				},

				/**
				 * Adv
				 */
				Adv: {
					id: 6157,
					label: "intranet.ENUMS.ETipoViaggio.Adv"
				},

				/**
				 * Easy Top
				 */
				EasyTop: {
					id: 6158,
					label: "intranet.ENUMS.ETipoViaggio.EasyTop"
				},

				/**
				 * Pacchetto
				 */
				Pacchetto: {
					id: 6159,
					label: "intranet.ENUMS.ETipoViaggio.Pacchetto"
				},

			},

			/**
			 * Content of path intranet.ENUMS.EPriceRuleTripType
			 */
			EPriceRuleTripType: {
				/**
				 * Tutti
				 */
				All: {
					id: 6299,
					label: "intranet.ENUMS.EPriceRuleTripType.All"
				},

				/**
				 * Pacchetti
				 */
				Packages: {
					id: 6300,
					label: "intranet.ENUMS.EPriceRuleTripType.Packages"
				},

				/**
				 * Su misura
				 */
				TailorMade: {
					id: 6301,
					label: "intranet.ENUMS.EPriceRuleTripType.TailorMade"
				},

			},

			/**
			 * Content of path intranet.ENUMS.ETuningParamType
			 */
			ETuningParamType: {
				/**
				 * String
				 */
				String: {
					id: 6526,
					label: "intranet.ENUMS.ETuningParamType.String"
				},

				/**
				 * Data e ora
				 */
				DateTime: {
					id: 6527,
					label: "intranet.ENUMS.ETuningParamType.DateTime"
				},

				/**
				 * Numero
				 */
				Number: {
					id: 6528,
					label: "intranet.ENUMS.ETuningParamType.Number"
				},

				/**
				 * Lingua
				 */
				Language: {
					id: 6529,
					label: "intranet.ENUMS.ETuningParamType.Language"
				},

				/**
				 * Booleano
				 */
				Bool: {
					id: 6530,
					label: "intranet.ENUMS.ETuningParamType.Bool"
				},

			},

		},

		/**
		 * Content of path intranet.ERRORS
		 */
		ERRORS: {
			/**
			 * Abbinamento non eliminabile per conferme presenti
			 */
			ABBINAMENTO_NON_ELIMINABILE_PER_CONFERME: {
				id: 342,
				label: "intranet.ERRORS.ABBINAMENTO_NON_ELIMINABILE_PER_CONFERME"
			},

			/**
			 * Abbinamento non modificabile
			 */
			ABBINAMENTO_NON_MODIFICABILE: {
				id: 343,
				label: "intranet.ERRORS.ABBINAMENTO_NON_MODIFICABILE"
			},

			/**
			 * Abbinamento non trovato
			 */
			ABBINAMENTO_NON_TROVATO: {
				id: 344,
				label: "intranet.ERRORS.ABBINAMENTO_NON_TROVATO"
			},

			/**
			 * Accordo H2H non assegnabile direttamente
			 */
			ACCORDO_H2H_NON_ASSEGNABILE_DIRETTAMENTE: {
				id: 345,
				label: "intranet.ERRORS.ACCORDO_H2H_NON_ASSEGNABILE_DIRETTAMENTE"
			},

			/**
			 * Accordo non trovato
			 */
			ACCORDO_NON_TROVATO: {
				id: 346,
				label: "intranet.ERRORS.ACCORDO_NON_TROVATO"
			},

			/**
			 * AAccordo senza tabelle di costo valide
			 */
			ACCORDO_SENZA_TABELLE_COSTO_VALIDE: {
				id: 347,
				label: "intranet.ERRORS.ACCORDO_SENZA_TABELLE_COSTO_VALIDE"
			},

			/**
			 * agenzia mancante
			 */
			AGENZIA_MANCANTE: {
				id: 348,
				label: "intranet.ERRORS.AGENZIA_MANCANTE"
			},

			/**
			 * agenzia priva di accordo commerciale
			 */
			AGENZIA_PRIVA_DI_ACCORDO_COMMERCIALE: {
				id: 349,
				label: "intranet.ERRORS.AGENZIA_PRIVA_DI_ACCORDO_COMMERCIALE"
			},

			/**
			 * allegato non trovato
			 */
			ALLEGATO_NON_TROVATO: {
				id: 350,
				label: "intranet.ERRORS.ALLEGATO_NON_TROVATO"
			},

			/**
			 * articolo con riferimenti circolari
			 */
			ARTICOLO_CON_RIFERIMENTI_CIRCOLARI: {
				id: 351,
				label: "intranet.ERRORS.ARTICOLO_CON_RIFERIMENTI_CIRCOLARI"
			},

			/**
			 * articolo non trovato
			 */
			ARTICOLO_NON_TROVATO: {
				id: 352,
				label: "intranet.ERRORS.ARTICOLO_NON_TROVATO"
			},

			/**
			 * cliente non eliminabile per conferme
			 */
			CLIENTE_NON_ELIMINABILE_PER_CONFERME: {
				id: 353,
				label: "intranet.ERRORS.CLIENTE_NON_ELIMINABILE_PER_CONFERME"
			},

			/**
			 * cliente non eliminabile per riferimento
			 */
			CLIENTE_NON_ELIMINABILE_PER_RIFERIMENTO: {
				id: 354,
				label: "intranet.ERRORS.CLIENTE_NON_ELIMINABILE_PER_RIFERIMENTO"
			},

			/**
			 * cliente non modificabile
			 */
			CLIENTE_NON_MODIFICABILE: {
				id: 355,
				label: "intranet.ERRORS.CLIENTE_NON_MODIFICABILE"
			},

			/**
			 * cliente non trovato
			 */
			CLIENTE_NON_TROVATO: {
				id: 356,
				label: "intranet.ERRORS.CLIENTE_NON_TROVATO"
			},

			/**
			 * contatto id inesistente
			 */
			CONTATTO_ID_INESISTENTE: {
				id: 357,
				label: "intranet.ERRORS.CONTATTO_ID_INESISTENTE"
			},

			/**
			 * costo non trovato
			 */
			COSTO_NON_TROVATO: {
				id: 358,
				label: "intranet.ERRORS.COSTO_NON_TROVATO"
			},

			/**
			 * data fine periodo ricerca indeterminata
			 */
			DATA_FINE_PERIODO_RICERCA_INDETERMINATA: {
				id: 359,
				label: "intranet.ERRORS.DATA_FINE_PERIODO_RICERCA_INDETERMINATA"
			},

			/**
			 * data inizio periodo ricerca indeterminata
			 */
			DATA_INIZIO_PERIODO_RICERCA_INDETERMINATA: {
				id: 360,
				label: "intranet.ERRORS.DATA_INIZIO_PERIODO_RICERCA_INDETERMINATA"
			},

			/**
			 * date ricerca incompatibili
			 */
			DATE_RICERCA_INCOMPATIBILI: {
				id: 361,
				label: "intranet.ERRORS.DATE_RICERCA_INCOMPATIBILI"
			},

			/**
			 * documento non eliminabile associato a riga confermata
			 */
			DOCUMENTO_NON_ELIMINABILE_ASSOCIATO_A_RIGA_CONFERMATA: {
				id: 362,
				label: "intranet.ERRORS.DOCUMENTO_NON_ELIMINABILE_ASSOCIATO_A_RIGA_CONFERMATA"
			},

			/**
			 * documento non trovato
			 */
			DOCUMENTO_NON_TROVATO: {
				id: 363,
				label: "intranet.ERRORS.DOCUMENTO_NON_TROVATO"
			},

			/**
			 * documento offerta non trovato
			 */
			DOCUMENTO_OFFERTA_NON_TROVATO: {
				id: 364,
				label: "intranet.ERRORS.DOCUMENTO_OFFERTA_NON_TROVATO"
			},

			/**
			 * errore creazione itinerario
			 */
			ERRORE_CREAZIONE_ITINERARIO: {
				id: 365,
				label: "intranet.ERRORS.ERRORE_CREAZIONE_ITINERARIO"
			},

			/**
			 * fascia prezzo servizio non trovata
			 */
			FASCIA_PREZZO_SERVIZIO_NON_TROVATA: {
				id: 366,
				label: "intranet.ERRORS.FASCIA_PREZZO_SERVIZIO_NON_TROVATA"
			},

			/**
			 * fascia servizio non trovata
			 */
			FASCIA_SERVIZIO_NON_TROVATA: {
				id: 367,
				label: "intranet.ERRORS.FASCIA_SERVIZIO_NON_TROVATA"
			},

			/**
			 * giorno mancante
			 */
			GIORNO_MANCANTE: {
				id: 368,
				label: "intranet.ERRORS.GIORNO_MANCANTE"
			},

			/**
			 * giorno non appartenente ad itinerario
			 */
			GIORNO_NON_APPARTENENTE_AD_ITINERARIO: {
				id: 369,
				label: "intranet.ERRORS.GIORNO_NON_APPARTENENTE_AD_ITINERARIO"
			},

			/**
			 * giorno non valido
			 */
			GIORNO_NON_VALIDO: {
				id: 370,
				label: "intranet.ERRORS.GIORNO_NON_VALIDO"
			},

			/**
			 * impossibile eliminare servizi su una conferma
			 */
			IMPOSSIBILE_ELIMINARE_SERVIZI_SU_UNA_CONFERMA: {
				id: 371,
				label: "intranet.ERRORS.IMPOSSIBILE_ELIMINARE_SERVIZI_SU_UNA_CONFERMA"
			},

			/**
			 * impossibile inserire amtp senza amtw
			 */
			IMPOSSIBILE_INSERIRE_AMTP_SENZA_AMTW: {
				id: 372,
				label: "intranet.ERRORS.IMPOSSIBILE_INSERIRE_AMTP_SENZA_AMTW"
			},

			/**
			 * impossibile rinunciare a servizio
			 */
			IMPOSSIBILE_RINUNCIARE_A_SERVIZIO: {
				id: 373,
				label: "intranet.ERRORS.IMPOSSIBILE_RINUNCIARE_A_SERVIZIO"
			},

			/**
			 * impossibile ripristinare
			 */
			IMPOSSIBILE_RIPRISTINARE: {
				id: 374,
				label: "intranet.ERRORS.IMPOSSIBILE_RIPRISTINARE"
			},

			/**
			 * impossibile sostituire servizio
			 */
			IMPOSSIBILE_SOSTITUIRE_SERVIZIO: {
				id: 375,
				label: "intranet.ERRORS.IMPOSSIBILE_SOSTITUIRE_SERVIZIO"
			},

			/**
			 * itinerario non trovato
			 */
			ITINERARIO_NON_TROVATO: {
				id: 376,
				label: "intranet.ERRORS.ITINERARIO_NON_TROVATO"
			},

			/**
			 * itinerario servizio non associato a tipologie
			 */
			ITINERARIO_SERVIZIO_NON_ASSOCIATO_A_TIPOLOGIE: {
				id: 377,
				label: "intranet.ERRORS.ITINERARIO_SERVIZIO_NON_ASSOCIATO_A_TIPOLOGIE"
			},

			/**
			 * itinerario tipologia non trovato
			 */
			ITINERARIO_TIPOLOGIA_NON_TROVATO: {
				id: 378,
				label: "intranet.ERRORS.ITINERARIO_TIPOLOGIA_NON_TROVATO"
			},

			/**
			 * itinerario tour associato tipologia non trovato
			 */
			ITINERARIO_TOUR_ASSOCIATO_TIPOLOGIA_NON_TROVATO: {
				id: 379,
				label: "intranet.ERRORS.ITINERARIO_TOUR_ASSOCIATO_TIPOLOGIA_NON_TROVATO"
			},

			/**
			 * itinerario tour non trovato
			 */
			ITINERARIO_TOUR_NON_TROVATO: {
				id: 380,
				label: "intranet.ERRORS.ITINERARIO_TOUR_NON_TROVATO"
			},

			/**
			 * luogo non trovato
			 */
			LUOGO_NON_TROVATO: {
				id: 381,
				label: "intranet.ERRORS.LUOGO_NON_TROVATO"
			},

			/**
			 * luogo ricerca prezzi servizi indeterminato
			 */
			LUOGO_RICERCA_PREZZI_SERVIZI_INDETERMINATO: {
				id: 382,
				label: "intranet.ERRORS.LUOGO_RICERCA_PREZZI_SERVIZI_INDETERMINATO"
			},

			/**
			 * nota di sistema non modificabile
			 */
			NOTA_DI_SISTEMA_NON_MODIFICABILE: {
				id: 383,
				label: "intranet.ERRORS.NOTA_DI_SISTEMA_NON_MODIFICABILE"
			},

			/**
			 * nota non eliminabile legata ad accordo
			 */
			NOTA_NON_ELIMINABILE_LEGATA_AD_ACCORDO: {
				id: 384,
				label: "intranet.ERRORS.NOTA_NON_ELIMINABILE_LEGATA_AD_ACCORDO"
			},

			/**
			 * nota non trovata
			 */
			NOTA_NON_TROVATA: {
				id: 385,
				label: "intranet.ERRORS.NOTA_NON_TROVATA"
			},

			/**
			 * notti mancanti
			 */
			NOTTI_MANCANTI: {
				id: 386,
				label: "intranet.ERRORS.NOTTI_MANCANTI"
			},

			/**
			 * notti non valide
			 */
			NOTTI_NON_VALIDE: {
				id: 387,
				label: "intranet.ERRORS.NOTTI_NON_VALIDE"
			},

			/**
			 * offerta non confermata to
			 */
			OFFERTA_NON_CONFERMATA_TO: {
				id: 388,
				label: "intranet.ERRORS.OFFERTA_NON_CONFERMATA_TO"
			},

			/**
			 * offerta non trovata
			 */
			OFFERTA_NON_TROVATA: {
				id: 389,
				label: "intranet.ERRORS.OFFERTA_NON_TROVATA"
			},

			/**
			 * passeggeri indeterminati
			 */
			PASSEGGERI_INDETERMINATI: {
				id: 390,
				label: "intranet.ERRORS.PASSEGGERI_INDETERMINATI"
			},

			/**
			 * polizza non cumulabile con amtw
			 */
			POLIZZA_NON_CUMULABILE_CON_AMTW: {
				id: 391,
				label: "intranet.ERRORS.POLIZZA_NON_CUMULABILE_CON_AMTW"
			},

			/**
			 * regola vendita associata a costo non trovata
			 */
			REGOLA_VENDITA_ASSOCIATA_A_COSTO_NON_TROVATA: {
				id: 392,
				label: "intranet.ERRORS.REGOLA_VENDITA_ASSOCIATA_A_COSTO_NON_TROVATA"
			},

			/**
			 * ricerca hotel zero notti
			 */
			RICERCA_HOTEL_ZERO_NOTTI: {
				id: 393,
				label: "intranet.ERRORS.RICERCA_HOTEL_ZERO_NOTTI"
			},

			/**
			 * ricerca non trovata
			 */
			RICERCA_NON_TROVATA: {
				id: 394,
				label: "intranet.ERRORS.RICERCA_NON_TROVATA"
			},

			/**
			 * ricerca prezzi nel passato
			 */
			RICERCA_PREZZI_NEL_PASSATO: {
				id: 395,
				label: "intranet.ERRORS.RICERCA_PREZZI_NEL_PASSATO"
			},

			/**
			 * riga offerta non trovata
			 */
			RIGA_OFFERTA_NON_TROVATA: {
				id: 396,
				label: "intranet.ERRORS.RIGA_OFFERTA_NON_TROVATA"
			},

			/**
			 * riga servizio hotel richiede notti
			 */
			RIGA_SERVIZIO_HOTEL_RICHIEDE_NOTTI: {
				id: 397,
				label: "intranet.ERRORS.RIGA_SERVIZIO_HOTEL_RICHIEDE_NOTTI"
			},

			/**
			 * riga servizio non eliminabile
			 */
			RIGA_SERVIZIO_NON_ELIMINABILE: {
				id: 398,
				label: "intranet.ERRORS.RIGA_SERVIZIO_NON_ELIMINABILE"
			},

			/**
			 * riga servizio non modificabile
			 */
			RIGA_SERVIZIO_NON_MODIFICABILE: {
				id: 399,
				label: "intranet.ERRORS.RIGA_SERVIZIO_NON_MODIFICABILE"
			},

			/**
			 * servizio codice non trovato
			 */
			SERVIZIO_CODICE_NON_TROVATO: {
				id: 400,
				label: "intranet.ERRORS.SERVIZIO_CODICE_NON_TROVATO"
			},

			/**
			 * servizio non inseribile su pratica confermata
			 */
			SERVIZIO_NON_INSERIBILE_SU_PRATICA_CONFERMATA: {
				id: 401,
				label: "intranet.ERRORS.SERVIZIO_NON_INSERIBILE_SU_PRATICA_CONFERMATA"
			},

			/**
			 * servizio non modificabile
			 */
			SERVIZIO_NON_MODIFICABILE: {
				id: 402,
				label: "intranet.ERRORS.SERVIZIO_NON_MODIFICABILE"
			},

			/**
			 * servizio non trovato
			 */
			SERVIZIO_NON_TROVATO: {
				id: 403,
				label: "intranet.ERRORS.SERVIZIO_NON_TROVATO"
			},

			/**
			 * servizio privo di itinerari
			 */
			SERVIZIO_PRIVO_DI_ITINERARI: {
				id: 404,
				label: "intranet.ERRORS.SERVIZIO_PRIVO_DI_ITINERARI"
			},

			/**
			 * servizio utilizzabile solo da min max
			 */
			SERVIZIO_UTILIZZABILE_SOLO_DA_MIN_MAX: {
				id: 405,
				label: "intranet.ERRORS.SERVIZIO_UTILIZZABILE_SOLO_DA_MIN_MAX"
			},

			/**
			 * tabella costi commissioni non trovata
			 */
			TABELLA_COSTI_COMMISSIONI_NON_TROVATA: {
				id: 406,
				label: "intranet.ERRORS.TABELLA_COSTI_COMMISSIONI_NON_TROVATA"
			},

			/**
			 * tappa non eliminabile associata a servizio
			 */
			TAPPA_NON_ELIMINABILE_ASSOCIATA_A_SERVIZIO: {
				id: 407,
				label: "intranet.ERRORS.TAPPA_NON_ELIMINABILE_ASSOCIATA_A_SERVIZIO"
			},

			/**
			 * tappa non eliminabile per servizio confermato
			 */
			TAPPA_NON_ELIMINABILE_PER_SERVIZIO_CONFERMATO: {
				id: 408,
				label: "intranet.ERRORS.TAPPA_NON_ELIMINABILE_PER_SERVIZIO_CONFERMATO"
			},

			/**
			 * tappa non modificabile per incompatibilita servizio confermato
			 */
			TAPPA_NON_MODIFICABILE_PER_INCOMPATIBILITA_SERVIZIO_CONFERMATO: {
				id: 409,
				label: "intranet.ERRORS.TAPPA_NON_MODIFICABILE_PER_INCOMPATIBILITA_SERVIZIO_CONFERMATO"
			},

			/**
			 * tappa tour non modificabile
			 */
			TAPPA_TOUR_NON_MODIFICABILE: {
				id: 410,
				label: "intranet.ERRORS.TAPPA_TOUR_NON_MODIFICABILE"
			},

			/**
			 * tariffa non piu disponibile
			 */
			TARIFFA_NON_PIU_DISPONIBILE: {
				id: 411,
				label: "intranet.ERRORS.TARIFFA_NON_PIU_DISPONIBILE"
			},

			/**
			 * tipologia non trovata
			 */
			TIPOLOGIA_NON_TROVATA: {
				id: 412,
				label: "intranet.ERRORS.TIPOLOGIA_NON_TROVATA"
			},

			/**
			 * tipologia senza itinerario
			 */
			TIPOLOGIA_SENZA_ITINERARIO: {
				id: 413,
				label: "intranet.ERRORS.TIPOLOGIA_SENZA_ITINERARIO"
			},

			/**
			 * utente id inesistente
			 */
			UTENTE_ID_INESISTENTE: {
				id: 414,
				label: "intranet.ERRORS.UTENTE_ID_INESISTENTE"
			},

			/**
			 * valuta accordo non trovata
			 */
			VALUTA_ACCORDO_NON_TROVATA: {
				id: 415,
				label: "intranet.ERRORS.VALUTA_ACCORDO_NON_TROVATA"
			},

			/**
			 * volo non trovato
			 */
			VOLO_NON_TROVATO: {
				id: 416,
				label: "intranet.ERRORS.VOLO_NON_TROVATO"
			},

			/**
			 * voucher non trovato
			 */
			VOUCHER_NON_TROVATO: {
				id: 417,
				label: "intranet.ERRORS.VOUCHER_NON_TROVATO"
			},

			/**
			 * wcys gia inviata
			 */
			WCYS_GIA_INVIATA: {
				id: 418,
				label: "intranet.ERRORS.WCYS_GIA_INVIATA"
			},

			/**
			 * ERRORS.undefined
			 */
			undefined: {
				id: 6360,
				label: "intranet.ERRORS.undefined"
			},

			/**
			 * ERRORS.IMPOSSIBILE_ELIMINARE_ITINERARIO_ASSOCIATO_TIPOLOGIA
			 */
			IMPOSSIBILE_ELIMINARE_ITINERARIO_ASSOCIATO_TIPOLOGIA: {
				id: 6395,
				label: "intranet.ERRORS.IMPOSSIBILE_ELIMINARE_ITINERARIO_ASSOCIATO_TIPOLOGIA"
			},

			/**
			 * ERRORS.VALIDITA_NON_TROVATA
			 */
			VALIDITA_NON_TROVATA: {
				id: 6404,
				label: "intranet.ERRORS.VALIDITA_NON_TROVATA"
			},

			/**
			 * ERRORS.IMPOSSIBILE_ELIMINARE_VALIDITA
			 */
			IMPOSSIBILE_ELIMINARE_VALIDITA: {
				id: 6409,
				label: "intranet.ERRORS.IMPOSSIBILE_ELIMINARE_VALIDITA"
			},

			/**
			 * ERRORS.IMPOSSIBILE_ELIMINARE_TIPOLOGIA_USATA_IN_OFFERTE
			 */
			IMPOSSIBILE_ELIMINARE_TIPOLOGIA_USATA_IN_OFFERTE: {
				id: 6413,
				label: "intranet.ERRORS.IMPOSSIBILE_ELIMINARE_TIPOLOGIA_USATA_IN_OFFERTE"
			},

			/**
			 * ERRORS.IMPOSSIBILE_ELIMINARE_TIPOLOGIA_USATA_IN_OFFERTA
			 */
			IMPOSSIBILE_ELIMINARE_TIPOLOGIA_USATA_IN_OFFERTA: {
				id: 6420,
				label: "intranet.ERRORS.IMPOSSIBILE_ELIMINARE_TIPOLOGIA_USATA_IN_OFFERTA"
			},

			/**
			 * ERRORS.IMPOSSIBILE_ELIMINARE_REGOLA_SERVIZIO_CON_COSTI
			 */
			IMPOSSIBILE_ELIMINARE_REGOLA_SERVIZIO_CON_COSTI: {
				id: 6485,
				label: "intranet.ERRORS.IMPOSSIBILE_ELIMINARE_REGOLA_SERVIZIO_CON_COSTI"
			},

			/**
			 * ERRORS.CODICE_RECUPERO_PASSWORD_NON_VALIDO
			 */
			CODICE_RECUPERO_PASSWORD_NON_VALIDO: {
				id: 6487,
				label: "intranet.ERRORS.CODICE_RECUPERO_PASSWORD_NON_VALIDO"
			},

			/**
			 * ERRORS.IMPOSSIBILE_ELIMINARE_ITINERARIO_ASSOCIATO_TIPOLOGIE
			 */
			IMPOSSIBILE_ELIMINARE_ITINERARIO_ASSOCIATO_TIPOLOGIE: {
				id: 6519,
				label: "intranet.ERRORS.IMPOSSIBILE_ELIMINARE_ITINERARIO_ASSOCIATO_TIPOLOGIE"
			},

			/**
			 * ERRORS.CODICE_GIATA_SERVIZIO_GIA_ASSOCIATO
			 */
			CODICE_GIATA_SERVIZIO_GIA_ASSOCIATO: {
				id: 6520,
				label: "intranet.ERRORS.CODICE_GIATA_SERVIZIO_GIA_ASSOCIATO"
			},

			/**
			 * ERRORS.IMPOSSIBILE_ELIMINARE_REGOLA_VENDITA_CON_COSTI
			 */
			IMPOSSIBILE_ELIMINARE_REGOLA_VENDITA_CON_COSTI: {
				id: 6573,
				label: "intranet.ERRORS.IMPOSSIBILE_ELIMINARE_REGOLA_VENDITA_CON_COSTI"
			},

		},

		/**
		 * Content of path intranet.IMMAGINI
		 */
		IMMAGINI: {
			/**
			 * A
			 */
			ALTEZZA_ABBREVIATA: {
				id: 419,
				label: "intranet.IMMAGINI.ALTEZZA-ABBREVIATA"
			},

			/**
			 * Articolo
			 */
			ARTICOLO: {
				id: 420,
				label: "intranet.IMMAGINI.ARTICOLO"
			},

			/**
			 * Carica nuove immagini
			 */
			CARICA: {
				id: 421,
				label: "intranet.IMMAGINI.CARICA"
			},

			/**
			 * Carica immagini
			 */
			CARICA_IMMAGINI: {
				id: 422,
				label: "intranet.IMMAGINI.CARICA-IMMAGINI"
			},

			/**
			 * Categoria
			 */
			CATEGORIA: {
				id: 423,
				label: "intranet.IMMAGINI.CATEGORIA"
			},

			/**
			 * Data/Ora
			 */
			DATA: {
				id: 424,
				label: "intranet.IMMAGINI.DATA"
			},

			/**
			 * Didascalia
			 */
			DIDASCALIA: {
				id: 425,
				label: "intranet.IMMAGINI.DIDASCALIA"
			},

			/**
			 * Size
			 */
			DIMENSIONI: {
				id: 426,
				label: "intranet.IMMAGINI.DIMENSIONI"
			},

			/**
			 * Dimensioni minime
			 */
			DIMENSIONI_MINIME: {
				id: 427,
				label: "intranet.IMMAGINI.DIMENSIONI-MINIME"
			},

			/**
			 * Elimina immagini
			 */
			ELIMINA_IMMAGINI: {
				id: 428,
				label: "intranet.IMMAGINI.ELIMINA-IMMAGINI"
			},

			/**
			 * Gestione immagini
			 */
			GESTIONE_IMMAGINI: {
				id: 429,
				label: "intranet.IMMAGINI.GESTIONE-IMMAGINI"
			},

			/**
			 * Id
			 */
			ID: {
				id: 430,
				label: "intranet.IMMAGINI.ID"
			},

			/**
			 * Content of path intranet.IMMAGINI.IMG-FLAGS
			 */
			IMG_FLAGS: {
				/**
				 * MNG
				 */
				MNG: {
					id: 431,
					label: "intranet.IMMAGINI.IMG-FLAGS.MNG"
				},

				/**
				 * PDV
				 */
				PDV: {
					id: 432,
					label: "intranet.IMMAGINI.IMG-FLAGS.PDV"
				},

				/**
				 * PREV
				 */
				PREV: {
					id: 433,
					label: "intranet.IMMAGINI.IMG-FLAGS.PREV"
				},

				/**
				 * WEB
				 */
				WEB: {
					id: 434,
					label: "intranet.IMMAGINI.IMG-FLAGS.WEB"
				},

			},

			/**
			 * Immagini sicure per ogni età
			 */
			IMMAGINI_SICURE: {
				id: 435,
				label: "intranet.IMMAGINI.IMMAGINI-SICURE"
			},

			/**
			 * L
			 */
			LARGHEZZA_ABBREVIATA: {
				id: 436,
				label: "intranet.IMMAGINI.LARGHEZZA-ABBREVIATA"
			},

			/**
			 * Lingua
			 */
			LINGUA: {
				id: 437,
				label: "intranet.IMMAGINI.LINGUA"
			},

			/**
			 * Luogo
			 */
			LUOGO: {
				id: 438,
				label: "intranet.IMMAGINI.LUOGO"
			},

			/**
			 * Miniguida
			 */
			MINIGUIDA: {
				id: 439,
				label: "intranet.IMMAGINI.MINIGUIDA"
			},

			/**
			 * Ordinamento
			 */
			ORDINAMENTO: {
				id: 440,
				label: "intranet.IMMAGINI.ORDINAMENTO"
			},

			/**
			 * Ordine
			 */
			ORDINE: {
				id: 441,
				label: "intranet.IMMAGINI.ORDINE"
			},

			/**
			 * Orientamento
			 */
			ORIENTAMENTO: {
				id: 442,
				label: "intranet.IMMAGINI.ORIENTAMENTO"
			},

			/**
			 * Paese
			 */
			PAESE: {
				id: 443,
				label: "intranet.IMMAGINI.PAESE"
			},

			/**
			 * Preventivo
			 */
			PREVENTIVO: {
				id: 444,
				label: "intranet.IMMAGINI.PREVENTIVO"
			},

			/**
			 * Programma
			 */
			PROGRAMMA: {
				id: 445,
				label: "intranet.IMMAGINI.PROGRAMMA"
			},

			/**
			 * Regione
			 */
			REGIONE: {
				id: 446,
				label: "intranet.IMMAGINI.REGIONE"
			},

			/**
			 * Ricerca su Pixabay
			 */
			RICERCA_SU_PIXABAY: {
				id: 447,
				label: "intranet.IMMAGINI.RICERCA-SU-PIXABAY"
			},

			/**
			 * Scelte dall'editore
			 */
			SCELTE_EDITORE: {
				id: 448,
				label: "intranet.IMMAGINI.SCELTE-EDITORE"
			},

			/**
			 * Seleziona immagini
			 */
			SELEZIONA_IMMAGINI: {
				id: 449,
				label: "intranet.IMMAGINI.SELEZIONA-IMMAGINI"
			},

			/**
			 * Servizio
			 */
			SERVIZIO: {
				id: 450,
				label: "intranet.IMMAGINI.SERVIZIO"
			},

			/**
			 * Sito Web
			 */
			SITOWEB: {
				id: 451,
				label: "intranet.IMMAGINI.SITOWEB"
			},

			/**
			 * Soggetto
			 */
			SOGGETTO: {
				id: 452,
				label: "intranet.IMMAGINI.SOGGETTO"
			},

			/**
			 * minimo 2 caratteri
			 */
			SOGGETTO_HELP: {
				id: 453,
				label: "intranet.IMMAGINI.SOGGETTO-HELP"
			},

			/**
			 * Solo luogo
			 */
			SOLO_LUOGO: {
				id: 454,
				label: "intranet.IMMAGINI.SOLO-LUOGO"
			},

			/**
			 * Tipo immagine
			 */
			TIPO_DI_IMMAGINE: {
				id: 455,
				label: "intranet.IMMAGINI.TIPO-DI-IMMAGINE"
			},

			/**
			 * Elimina Immagine
			 */
			ELIMINA_IMMAGINE: {
				id: 6138,
				label: "intranet.IMMAGINI.ELIMINA-IMMAGINE"
			},

			/**
			 * Ripristina Immagine
			 */
			RIPRISTINA_IMMAGINE: {
				id: 6139,
				label: "intranet.IMMAGINI.RIPRISTINA-IMMAGINE"
			},

			/**
			 * Immagine Eliminata
			 */
			IMMAGINE_ELIMINATA: {
				id: 6140,
				label: "intranet.IMMAGINI.IMMAGINE-ELIMINATA"
			},

			/**
			 * Immagine Ripristinata
			 */
			IMMAGINE_RIPRISTINATA: {
				id: 6141,
				label: "intranet.IMMAGINI.IMMAGINE-RIPRISTINATA"
			},

			/**
			 * IMMAGINI.IMMAGINI-ELIMINATE
			 */
			IMMAGINI_ELIMINATE: {
				id: 6359,
				label: "intranet.IMMAGINI.IMMAGINI-ELIMINATE"
			},

		},

		/**
		 * Content of path intranet.LANGUAGES
		 */
		LANGUAGES: {
			/**
			 * Tedesco
			 */
			DE: {
				id: 456,
				label: "intranet.LANGUAGES.DE"
			},

			/**
			 * Inglese
			 */
			EN: {
				id: 457,
				label: "intranet.LANGUAGES.EN"
			},

			/**
			 * Spagnolo
			 */
			ES: {
				id: 458,
				label: "intranet.LANGUAGES.ES"
			},

			/**
			 * Francese
			 */
			FR: {
				id: 459,
				label: "intranet.LANGUAGES.FR"
			},

			/**
			 * Italiano
			 */
			IT: {
				id: 460,
				label: "intranet.LANGUAGES.IT"
			},

			/**
			 * Olandese
			 */
			NL: {
				id: 461,
				label: "intranet.LANGUAGES.NL"
			},

			/**
			 * Debug language
			 */
			ZU: {
				id: 462,
				label: "intranet.LANGUAGES.ZU"
			},

		},

		/**
		 * Content of path intranet.MENU
		 */
		MENU: {
			/**
			 * Content of path intranet.MENU.ADMIN
			 */
			ADMIN: {
				/**
				 * Assicurazioni
				 */
				ASSICURAZIONI: {
					id: 463,
					label: "intranet.MENU.ADMIN.ASSICURAZIONI"
				},

				/**
				 * Code servizi
				 */
				CODE_SERVIZI: {
					id: 464,
					label: "intranet.MENU.ADMIN.CODE-SERVIZI"
				},

				/**
				 * Email di sistema
				 */
				EMAIL_DI_SISTEMA: {
					id: 465,
					label: "intranet.MENU.ADMIN.EMAIL-DI-SISTEMA"
				},

				/**
				 * Email forwarder
				 */
				EMAIL_FORWARDER: {
					id: 466,
					label: "intranet.MENU.ADMIN.EMAIL-FORWARDER"
				},

				/**
				 * Errori
				 */
				ERRORI: {
					id: 467,
					label: "intranet.MENU.ADMIN.ERRORI"
				},

				/**
				 * Fornitori H2H
				 */
				FORNITORI_H2H: {
					id: 468,
					label: "intranet.MENU.ADMIN.FORNITORI-H2H"
				},

				/**
				 * Gestione cache
				 */
				GESTIONE_CACHE: {
					id: 469,
					label: "intranet.MENU.ADMIN.GESTIONE-CACHE"
				},

				/**
				 * Log Attività
				 */
				LOG_ATTIVITA: {
					id: 470,
					label: "intranet.MENU.ADMIN.LOG-ATTIVITA"
				},

				/**
				 * Luoghi Fornitori H2H
				 */
				LUOGHI_H2H: {
					id: 471,
					label: "intranet.MENU.ADMIN.LUOGHI-H2H"
				},

				/**
				 * Admin
				 */
				MENU: {
					id: 472,
					label: "intranet.MENU.ADMIN.MENU"
				},

				/**
				 * Ruoli e permessi
				 */
				RUOLI_E_PERMESSI: {
					id: 473,
					label: "intranet.MENU.ADMIN.RUOLI-E-PERMESSI"
				},

				/**
				 * Servizi Fornitori H2H
				 */
				SERVIZI_FORNITORE: {
					id: 474,
					label: "intranet.MENU.ADMIN.SERVIZI-FORNITORE"
				},

				/**
				 * Servizi Naar H2H
				 */
				SERVIZI_H2H: {
					id: 475,
					label: "intranet.MENU.ADMIN.SERVIZI-H2H"
				},

				/**
				 * Sessioni Utente
				 */
				SESSIONI_UTENTE: {
					id: 476,
					label: "intranet.MENU.ADMIN.SESSIONI-UTENTE"
				},

				/**
				 * Utenti
				 */
				UTENTI: {
					id: 477,
					label: "intranet.MENU.ADMIN.UTENTI"
				},

				/**
				 * Termini di pagamento
				 */
				PAYMENT_TERMS: {
					id: 6136,
					label: "intranet.MENU.ADMIN.PAYMENT-TERMS"
				},

				/**
				 * Termini di pagamento
				 */
				TERMINI_PAGAMENTO: {
					id: 6186,
					label: "intranet.MENU.ADMIN.TERMINI-PAGAMENTO"
				},

				/**
				 * Pricing
				 */
				PRICING: {
					id: 6241,
					label: "intranet.MENU.ADMIN.PRICING"
				},

				/**
				 * Tuning AI
				 */
				AI_TUNING: {
					id: 6366,
					label: "intranet.MENU.ADMIN.AI-TUNING"
				},

				/**
				 * MENU.ADMIN.ACTIVITY-LOGS
				 */
				ACTIVITY_LOGS: {
					id: 6560,
					label: "intranet.MENU.ADMIN.ACTIVITY-LOGS"
				},

			},

			/**
			 * Content of path intranet.MENU.ANAGRAFICHE
			 */
			ANAGRAFICHE: {
				/**
				 * Accordi
				 */
				ACCORDI: {
					id: 478,
					label: "intranet.MENU.ANAGRAFICHE.ACCORDI"
				},

				/**
				 * Aeroporti
				 */
				AEROPORTI: {
					id: 479,
					label: "intranet.MENU.ANAGRAFICHE.AEROPORTI"
				},

				/**
				 * Compagnie aeree
				 */
				COMPAGNIE_AEREE: {
					id: 480,
					label: "intranet.MENU.ANAGRAFICHE.COMPAGNIE-AEREE"
				},

				/**
				 * Contatti
				 */
				CONTATTI: {
					id: 481,
					label: "intranet.MENU.ANAGRAFICHE.CONTATTI"
				},

				/**
				 * Luoghi
				 */
				LUOGHI: {
					id: 482,
					label: "intranet.MENU.ANAGRAFICHE.LUOGHI"
				},

				/**
				 * Anagrafiche
				 */
				MENU: {
					id: 483,
					label: "intranet.MENU.ANAGRAFICHE.MENU"
				},

			},

			/**
			 * Content of path intranet.MENU.BIGLIETTERIA
			 */
			BIGLIETTERIA: {
				/**
				 * Amadeus Cryptic
				 */
				AMADEUS_CRYPTIC: {
					id: 484,
					label: "intranet.MENU.BIGLIETTERIA.AMADEUS-CRYPTIC"
				},

				/**
				 * Emissioni
				 */
				EMISSIONI: {
					id: 485,
					label: "intranet.MENU.BIGLIETTERIA.EMISSIONI"
				},

				/**
				 * Biglietteria
				 */
				MENU: {
					id: 486,
					label: "intranet.MENU.BIGLIETTERIA.MENU"
				},

			},

			/**
			 * Content of path intranet.MENU.CONTABILITA
			 */
			CONTABILITA: {
				/**
				 * Cambi Valute
				 */
				CAMBI_VALUTE: {
					id: 487,
					label: "intranet.MENU.CONTABILITA.CAMBI-VALUTE"
				},

				/**
				 * Ciclo Passivo
				 */
				CICLO_PASSIVO: {
					id: 488,
					label: "intranet.MENU.CONTABILITA.CICLO-PASSIVO"
				},

				/**
				 * Crea Fatture
				 */
				CREA_FATTURE: {
					id: 489,
					label: "intranet.MENU.CONTABILITA.CREA-FATTURE"
				},

				/**
				 * Estratto conto
				 */
				ESTRATTO_CONTO: {
					id: 490,
					label: "intranet.MENU.CONTABILITA.ESTRATTO-CONTO"
				},

				/**
				 * Fatture
				 */
				FATTURE: {
					id: 491,
					label: "intranet.MENU.CONTABILITA.FATTURE"
				},

				/**
				 * Import Global Hertz
				 */
				IMPORT_GLOBAL_HERTZ: {
					id: 492,
					label: "intranet.MENU.CONTABILITA.IMPORT-GLOBAL-HERTZ"
				},

				/**
				 * Import Tickets
				 */
				IMPORT_MERIDIANO: {
					id: 493,
					label: "intranet.MENU.CONTABILITA.IMPORT-MERIDIANO"
				},

				/**
				 * Invio Fatture
				 */
				INVIO_FATTURE: {
					id: 494,
					label: "intranet.MENU.CONTABILITA.INVIO-FATTURE"
				},

				/**
				 * Contabilità
				 */
				MENU: {
					id: 495,
					label: "intranet.MENU.CONTABILITA.MENU"
				},

				/**
				 * Pagamenti
				 */
				PAGAMENTI: {
					id: 496,
					label: "intranet.MENU.CONTABILITA.PAGAMENTI"
				},

				/**
				 * Progressivi
				 */
				PROGRESSIVI: {
					id: 497,
					label: "intranet.MENU.CONTABILITA.PROGRESSIVI"
				},

				/**
				 * Report anticipi forn.
				 */
				REPORT_ANTICIPI: {
					id: 498,
					label: "intranet.MENU.CONTABILITA.REPORT-ANTICIPI"
				},

				/**
				 * Ricerca Viaggiatori
				 */
				RICERCA_VIAGGIATORI: {
					id: 499,
					label: "intranet.MENU.CONTABILITA.RICERCA-VIAGGIATORI"
				},

				/**
				 * Saldi
				 */
				SALDI: {
					id: 500,
					label: "intranet.MENU.CONTABILITA.SALDI"
				},

				/**
				 * Scadenziario
				 */
				SCADENZIARIO: {
					id: 501,
					label: "intranet.MENU.CONTABILITA.SCADENZIARIO"
				},

				/**
				 * Travelfactoring Accrediti
				 */
				TRAVELFACTORING_ACCREDITI: {
					id: 502,
					label: "intranet.MENU.CONTABILITA.TRAVELFACTORING-ACCREDITI"
				},

			},

			/**
			 * Dashboard
			 */
			DASHBOARD: {
				id: 503,
				label: "intranet.MENU.DASHBOARD"
			},

			/**
			 * Content of path intranet.MENU.INTRANET-OFFERTE
			 */
			INTRANET_OFFERTE: {
				/**
				 * Crea un viaggio
				 */
				CREA_UN_VIAGGIO: {
					id: 504,
					label: "intranet.MENU.INTRANET-OFFERTE.CREA-UN-VIAGGIO"
				},

				/**
				 * I tuoi viaggi
				 */
				I_TUOI_VIAGGI: {
					id: 505,
					label: "intranet.MENU.INTRANET-OFFERTE.I-TUOI-VIAGGI"
				},

				/**
				 * Ricerca Viaggi
				 */
				RICERCA_VIAGGI: {
					id: 506,
					label: "intranet.MENU.INTRANET-OFFERTE.RICERCA-VIAGGI"
				},

				/**
				 * Viaggi
				 */
				TITLE: {
					id: 507,
					label: "intranet.MENU.INTRANET-OFFERTE.TITLE"
				},

			},

			/**
			 * 
			 */
			OFFERTE: {
				id: 508,
				label: "intranet.MENU.OFFERTE"
			},

			/**
			 * Vecchia intranet
			 */
			OLD_INTRANET: {
				id: 509,
				label: "intranet.MENU.OLD-INTRANET"
			},

			/**
			 * Content of path intranet.MENU.PROGRAMMAZIONE
			 */
			PROGRAMMAZIONE: {
				/**
				 * Anticipi fornitore
				 */
				ANTICIPI_FORNITORE: {
					id: 510,
					label: "intranet.MENU.PROGRAMMAZIONE.ANTICIPI-FORNITORE"
				},

				/**
				 * Articoli
				 */
				ARTICOLI: {
					id: 511,
					label: "intranet.MENU.PROGRAMMAZIONE.ARTICOLI"
				},

				/**
				 * Check prezzi
				 */
				CHECK_PREZZI: {
					id: 512,
					label: "intranet.MENU.PROGRAMMAZIONE.CHECK-PREZZI"
				},

				/**
				 * Cms
				 */
				CMS: {
					id: 513,
					label: "intranet.MENU.PROGRAMMAZIONE.CMS"
				},

				/**
				 * Dati aggiuntivi
				 */
				DATI_AGGIUNTIVI: {
					id: 514,
					label: "intranet.MENU.PROGRAMMAZIONE.DATI-AGGIUNTIVI"
				},

				/**
				 * Documenti allegati
				 */
				DOCUMENTI_ALLEGATI: {
					id: 515,
					label: "intranet.MENU.PROGRAMMAZIONE.DOCUMENTI-ALLEGATI"
				},

				/**
				 * Frasi
				 */
				FRASI: {
					id: 516,
					label: "intranet.MENU.PROGRAMMAZIONE.FRASI"
				},

				/**
				 * Immagini
				 */
				IMMAGINI: {
					id: 517,
					label: "intranet.MENU.PROGRAMMAZIONE.IMMAGINI"
				},

				/**
				 * Informazioni interne
				 */
				INFORMAZIONI_INTERNE: {
					id: 518,
					label: "intranet.MENU.PROGRAMMAZIONE.INFORMAZIONI-INTERNE"
				},

				/**
				 * Mappe
				 */
				MAPPE: {
					id: 519,
					label: "intranet.MENU.PROGRAMMAZIONE.MAPPE"
				},

				/**
				 * Margini servizi
				 */
				MARGINI_SERVIZI: {
					id: 520,
					label: "intranet.MENU.PROGRAMMAZIONE.MARGINI-SERVIZI"
				},

				/**
				 * Programmazione
				 */
				MENU: {
					id: 521,
					label: "intranet.MENU.PROGRAMMAZIONE.MENU"
				},

				/**
				 * Sconti
				 */
				SCONTI: {
					id: 522,
					label: "intranet.MENU.PROGRAMMAZIONE.SCONTI"
				},

				/**
				 * Servizi
				 */
				SERVIZI: {
					id: 523,
					label: "intranet.MENU.PROGRAMMAZIONE.SERVIZI"
				},

				/**
				 * Template
				 */
				TEMPLATE: {
					id: 524,
					label: "intranet.MENU.PROGRAMMAZIONE.TEMPLATE"
				},

				/**
				 * Articoli (da frasi)
				 */
				TESTI: {
					id: 525,
					label: "intranet.MENU.PROGRAMMAZIONE.TESTI"
				},

				/**
				 * Tipo Dati aggiuntivi
				 */
				TIPODATI_AGGIUNTIVI: {
					id: 526,
					label: "intranet.MENU.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI"
				},

			},

			/**
			 * Content of path intranet.MENU.STATISTICHE
			 */
			STATISTICHE: {
				/**
				 * Gestione Statistiche
				 */
				GESTIONE_STATISTICHE: {
					id: 527,
					label: "intranet.MENU.STATISTICHE.GESTIONE-STATISTICHE"
				},

				/**
				 * Statistiche
				 */
				MENU: {
					id: 528,
					label: "intranet.MENU.STATISTICHE.MENU"
				},

				/**
				 * Viaggi con dati incompleti
				 */
				OFFERTE_INCOMPLETE: {
					id: 529,
					label: "intranet.MENU.STATISTICHE.OFFERTE-INCOMPLETE"
				},

				/**
				 * Preventivi
				 */
				PREVENTIVI: {
					id: 530,
					label: "intranet.MENU.STATISTICHE.PREVENTIVI"
				},

				/**
				 * Preventivi venditori
				 */
				PREVENTIVI_VENDITORI: {
					id: 531,
					label: "intranet.MENU.STATISTICHE.PREVENTIVI-VENDITORI"
				},

				/**
				 * Reports
				 */
				REPORTS: {
					id: 532,
					label: "intranet.MENU.STATISTICHE.REPORTS"
				},

				/**
				 * Situazione Vendite
				 */
				SITUAZIONE_VENDITE: {
					id: 533,
					label: "intranet.MENU.STATISTICHE.SITUAZIONE-VENDITE"
				},

				/**
				 * Statistiche
				 */
				STATISTICHE: {
					id: 534,
					label: "intranet.MENU.STATISTICHE.STATISTICHE"
				},

				/**
				 * Vendite
				 */
				VENDITE: {
					id: 535,
					label: "intranet.MENU.STATISTICHE.VENDITE"
				},

				/**
				 * Vendite e preventivi
				 */
				VENDITE_PREVENTIVI: {
					id: 536,
					label: "intranet.MENU.STATISTICHE.VENDITE-PREVENTIVI"
				},

			},

			/**
			 * Content of path intranet.MENU.TRADUZIONI
			 */
			TRADUZIONI: {
				/**
				 * Articoli
				 */
				ARTICOLI: {
					id: 537,
					label: "intranet.MENU.TRADUZIONI.ARTICOLI"
				},

				/**
				 * Frasi
				 */
				FRASI: {
					id: 538,
					label: "intranet.MENU.TRADUZIONI.FRASI"
				},

				/**
				 * Luoghi
				 */
				LUOGHI: {
					id: 539,
					label: "intranet.MENU.TRADUZIONI.LUOGHI"
				},

				/**
				 * Traduzioni
				 */
				MENU: {
					id: 540,
					label: "intranet.MENU.TRADUZIONI.MENU"
				},

				/**
				 * Dati Prodotto
				 */
				SERVIZI: {
					id: 541,
					label: "intranet.MENU.TRADUZIONI.SERVIZI"
				},

				/**
				 * Labels
				 */
				LABELS: {
					id: 5864,
					label: "intranet.MENU.TRADUZIONI.LABELS"
				},

			},

			/**
			 * Content of path intranet.MENU.CMS
			 */
			CMS: {
				/**
				 * CMS
				 */
				MENU: {
					id: 6040,
					label: "intranet.MENU.CMS.MENU"
				},

				/**
				 * B2C
				 */
				B2C: {
					id: 6041,
					label: "intranet.MENU.CMS.B2C"
				},

			},

		},

		/**
		 * Content of path intranet.NAVBAR
		 */
		NAVBAR: {
			/**
			 * Content of path intranet.NAVBAR.CACHE
			 */
			CACHE: {
				/**
				 * Accounting
				 */
				ACCOUNTING: {
					id: 542,
					label: "intranet.NAVBAR.CACHE.ACCOUNTING"
				},

				/**
				 * Ricarica l'elenco utenti / permessi di accesso
				 */
				ACCOUNTING_TITLE: {
					id: 543,
					label: "intranet.NAVBAR.CACHE.ACCOUNTING-TITLE"
				},

				/**
				 * Articoli
				 */
				ARTICOLI: {
					id: 544,
					label: "intranet.NAVBAR.CACHE.ARTICOLI"
				},

				/**
				 * Ricarica tutti gli articoli
				 */
				ARTICOLI_TITLE: {
					id: 545,
					label: "intranet.NAVBAR.CACHE.ARTICOLI-TITLE"
				},

				/**
				 * Svuota cache
				 */
				CACHE: {
					id: 546,
					label: "intranet.NAVBAR.CACHE.CACHE"
				},

				/**
				 * Contatti
				 */
				CONTATTI: {
					id: 547,
					label: "intranet.NAVBAR.CACHE.CONTATTI"
				},

				/**
				 * Ricarica l'elenco dei contatti
				 */
				CONTATTI_TITLE: {
					id: 548,
					label: "intranet.NAVBAR.CACHE.CONTATTI-TITLE"
				},

				/**
				 * Risultati di ricerca
				 */
				RICERCA: {
					id: 549,
					label: "intranet.NAVBAR.CACHE.RICERCA"
				},

				/**
				 * Annulla la cache dei risultati delle ricerche di disponbilità
				 */
				RICERCA_TITLE: {
					id: 550,
					label: "intranet.NAVBAR.CACHE.RICERCA-TITLE"
				},

				/**
				 * Cache completa
				 */
				TUTTO: {
					id: 551,
					label: "intranet.NAVBAR.CACHE.TUTTO"
				},

				/**
				 * Svuota completamente la cache del sito e dei servizi.
				 * ATTENZIONE: da usare con moderazione!
				 * Il sito non risponde per alcuni secondi dopo aver usato questa funzione
				 */
				TUTTO_TITLE: {
					id: 552,
					label: "intranet.NAVBAR.CACHE.TUTTO-TITLE"
				},

			},

			/**
			 * ID offerta + Invio
			 */
			IDOFFERTA: {
				id: 553,
				label: "intranet.NAVBAR.IDOFFERTA"
			},

			/**
			 * Impostazioni
			 */
			IMPOSTAZIONI: {
				id: 554,
				label: "intranet.NAVBAR.IMPOSTAZIONI"
			},

			/**
			 * Scrivi almeno 3 caratteri e premi invio per cercare.
			 */
			INVIO_PER_CERCARE: {
				id: 555,
				label: "intranet.NAVBAR.INVIO-PER-CERCARE"
			},

			/**
			 * Logout
			 */
			LOGOUT: {
				id: 556,
				label: "intranet.NAVBAR.LOGOUT"
			},

			/**
			 * Inserire almeno 3 caratteri
			 */
			MINIMO_3_CARATTERI: {
				id: 557,
				label: "intranet.NAVBAR.MINIMO-3-CARATTERI"
			},

			/**
			 * Nessun risultato per {term}
			 */
			NESSUN_RISULTATO: {
				id: 558,
				label: "intranet.NAVBAR.NESSUN-RISULTATO"
			},

			/**
			 * Luoghi, servizi, ecc...
			 */
			SEARCH_PLACEHOLDER: {
				id: 559,
				label: "intranet.NAVBAR.SEARCH-PLACEHOLDER"
			},

		},

		/**
		 * Content of path intranet.NR-DROPDOWN
		 */
		NR_DROPDOWN: {
			/**
			 * Aggiungi elemento
			 */
			ADD_TAG: {
				id: 560,
				label: "intranet.NR-DROPDOWN.ADD-TAG"
			},

			/**
			 * Cancella
			 */
			CLEAR_ALL: {
				id: 561,
				label: "intranet.NR-DROPDOWN.CLEAR-ALL"
			},

			/**
			 * Ricerca...
			 */
			LOADING: {
				id: 562,
				label: "intranet.NR-DROPDOWN.LOADING"
			},

			/**
			 * Nessun elemento trovato
			 */
			NOT_FOUND: {
				id: 563,
				label: "intranet.NR-DROPDOWN.NOT-FOUND"
			},

			/**
			 * Scrivi per cercare
			 */
			TYPE_TO_SEARCH: {
				id: 564,
				label: "intranet.NR-DROPDOWN.TYPE-TO-SEARCH"
			},

		},

		/**
		 * Content of path intranet.OFFERTE
		 */
		OFFERTE: {
			/**
			 * Content of path intranet.OFFERTE.DOCUMENTI
			 */
			DOCUMENTI: {
				/**
				 * Aggiungi
				 */
				AGGIUNGI: {
					id: 565,
					label: "intranet.OFFERTE.DOCUMENTI.AGGIUNGI"
				},

				/**
				 * Almeno uno
				 */
				ALMENO_UNO: {
					id: 566,
					label: "intranet.OFFERTE.DOCUMENTI.ALMENO-UNO"
				},

				/**
				 * Altro luogo
				 */
				ALTRO_LUOGO: {
					id: 567,
					label: "intranet.OFFERTE.DOCUMENTI.ALTRO-LUOGO"
				},

				/**
				 * Appunti
				 */
				APPUNTI: {
					id: 568,
					label: "intranet.OFFERTE.DOCUMENTI.APPUNTI"
				},

				/**
				 * Cerca frase per contenuto, titolo, ID
				 */
				CERCA_FRASE: {
					id: 569,
					label: "intranet.OFFERTE.DOCUMENTI.CERCA-FRASE"
				},

				/**
				 * Copia valore di default
				 */
				COPIA_DEFAULT: {
					id: 570,
					label: "intranet.OFFERTE.DOCUMENTI.COPIA-DEFAULT"
				},

				/**
				 * Corrispondenza
				 */
				CORRIPONDENZA: {
					id: 571,
					label: "intranet.OFFERTE.DOCUMENTI.CORRIPONDENZA"
				},

				/**
				 * Elimina frase
				 */
				ELIMINA_FRASE: {
					id: 572,
					label: "intranet.OFFERTE.DOCUMENTI.ELIMINA-FRASE"
				},

				/**
				 * Filtra per tag
				 */
				FILTRO_TAG: {
					id: 573,
					label: "intranet.OFFERTE.DOCUMENTI.FILTRO-TAG"
				},

				/**
				 * Frase
				 */
				FRASE: {
					id: 574,
					label: "intranet.OFFERTE.DOCUMENTI.FRASE"
				},

				/**
				 * Frase collegata all'accordo
				 */
				FRASE_COLLEGATA_ACCORDO: {
					id: 575,
					label: "intranet.OFFERTE.DOCUMENTI.FRASE-COLLEGATA-ACCORDO"
				},

				/**
				 * Frase collegata al contatto
				 */
				FRASE_COLLEGATA_CONTATTO: {
					id: 576,
					label: "intranet.OFFERTE.DOCUMENTI.FRASE-COLLEGATA-CONTATTO"
				},

				/**
				 * Frase collegata al servizio
				 */
				FRASE_COLLEGATA_SERVIZIO: {
					id: 577,
					label: "intranet.OFFERTE.DOCUMENTI.FRASE-COLLEGATA-SERVIZIO"
				},

				/**
				 * Generiche
				 */
				FRASI_GENERICHE: {
					id: 578,
					label: "intranet.OFFERTE.DOCUMENTI.FRASI-GENERICHE"
				},

				/**
				 * Luogo
				 */
				FRASI_LUOGO: {
					id: 579,
					label: "intranet.OFFERTE.DOCUMENTI.FRASI-LUOGO"
				},

				/**
				 * Servizi
				 */
				FRASI_SERVIZIO: {
					id: 580,
					label: "intranet.OFFERTE.DOCUMENTI.FRASI-SERVIZIO"
				},

				/**
				 * Content of path intranet.OFFERTE.DOCUMENTI.HELP
				 */
				HELP: {
					/**
					 * Descrizione del servizio di una riga di offerta
					 */
					DESCRIZIONERIGAOFFERTA: {
						id: 581,
						label: "intranet.OFFERTE.DOCUMENTI.HELP.DESCRIZIONERIGAOFFERTA"
					},

					/**
					 * Area di destinazione del viaggio
					 */
					DESTINAZIONE: {
						id: 582,
						label: "intranet.OFFERTE.DOCUMENTI.HELP.DESTINAZIONE"
					},

					/**
					 * Luogo e nome di un servizio
					 */
					SERVIZIOOFFERTA: {
						id: 583,
						label: "intranet.OFFERTE.DOCUMENTI.HELP.SERVIZIOOFFERTA"
					},

					/**
					 * Nome, indirizzo e recapiti degli uffici di un corrispondente locale
					 */
					UFFICIOLOCALE: {
						id: 584,
						label: "intranet.OFFERTE.DOCUMENTI.HELP.UFFICIOLOCALE"
					},

				},

				/**
				 * ID
				 */
				ID: {
					id: 585,
					label: "intranet.OFFERTE.DOCUMENTI.ID"
				},

				/**
				 * Incolla {n} frasi
				 */
				INCOLLA_TUTTI: {
					id: 586,
					label: "intranet.OFFERTE.DOCUMENTI.INCOLLA-TUTTI"
				},

				/**
				 * Modifica variabili frase
				 */
				MODIFICA_VARIABILI_FRASE: {
					id: 587,
					label: "intranet.OFFERTE.DOCUMENTI.MODIFICA-VARIABILI-FRASE"
				},

				/**
				 * Sposta avanti di una frase
				 */
				SPOSTA_AVANTI: {
					id: 588,
					label: "intranet.OFFERTE.DOCUMENTI.SPOSTA-AVANTI"
				},

				/**
				 * Sposta indietro di una frase
				 */
				SPOSTA_INDIETRO: {
					id: 589,
					label: "intranet.OFFERTE.DOCUMENTI.SPOSTA-INDIETRO"
				},

				/**
				 * Sposta nella sezione precedente
				 */
				SPOSTA_SEZIONE_PRECEDENTE: {
					id: 590,
					label: "intranet.OFFERTE.DOCUMENTI.SPOSTA-SEZIONE-PRECEDENTE"
				},

				/**
				 * Sposta nella sezione successiva
				 */
				SPOSTA_SEZIONE_SUCCESSIVA: {
					id: 591,
					label: "intranet.OFFERTE.DOCUMENTI.SPOSTA-SEZIONE-SUCCESSIVA"
				},

				/**
				 * Svuota appunti
				 */
				SVUOTA_APPUNTI: {
					id: 592,
					label: "intranet.OFFERTE.DOCUMENTI.SVUOTA-APPUNTI"
				},

				/**
				 * Terminatore
				 */
				TERMINATORE: {
					id: 593,
					label: "intranet.OFFERTE.DOCUMENTI.TERMINATORE"
				},

				/**
				 * Tutti
				 */
				TUTTI: {
					id: 594,
					label: "intranet.OFFERTE.DOCUMENTI.TUTTI"
				},

				/**
				 * Valore personalizzato per {variabile}
				 */
				VALORE_PERSONALIZZATO_VARIABILE: {
					id: 595,
					label: "intranet.OFFERTE.DOCUMENTI.VALORE-PERSONALIZZATO-VARIABILE"
				},

			},

		},

		/**
		 * Content of path intranet.OPZIONI
		 */
		OPZIONI: {
			/**
			 * Attiva fuorisede
			 */
			ATTIVA_FUORISEDE: {
				id: 596,
				label: "intranet.OPZIONI.ATTIVA-FUORISEDE"
			},

			/**
			 * Email per fuorisede
			 */
			EMAIL_FUORISEDE: {
				id: 597,
				label: "intranet.OPZIONI.EMAIL-FUORISEDE"
			},

			/**
			 * Inserire un indirizzo email valido
			 */
			EMAIL_FUORISEDE_OBBLIGATORIA: {
				id: 598,
				label: "intranet.OPZIONI.EMAIL-FUORISEDE-OBBLIGATORIA"
			},

			/**
			 * Fuorisede
			 */
			FUORISEDE: {
				id: 599,
				label: "intranet.OPZIONI.FUORISEDE"
			},

			/**
			 * Lingua nuova intranet
			 */
			LINGUA: {
				id: 600,
				label: "intranet.OPZIONI.LINGUA"
			},

			/**
			 * Content of path intranet.OPZIONI.LINGUE
			 */
			LINGUE: {
				/**
				 * Tedesco
				 */
				de: {
					id: 601,
					label: "intranet.OPZIONI.LINGUE.de"
				},

				/**
				 * Inglese
				 */
				en: {
					id: 602,
					label: "intranet.OPZIONI.LINGUE.en"
				},

				/**
				 * Spagnolo
				 */
				es: {
					id: 603,
					label: "intranet.OPZIONI.LINGUE.es"
				},

				/**
				 * Francese
				 */
				fr: {
					id: 604,
					label: "intranet.OPZIONI.LINGUE.fr"
				},

				/**
				 * Italiano
				 */
				it: {
					id: 605,
					label: "intranet.OPZIONI.LINGUE.it"
				},

				/**
				 * OPZIONI.LINGUE.zu
				 */
				zu: {
					id: 6486,
					label: "intranet.OPZIONI.LINGUE.zu"
				},

			},

			/**
			 * Opzioni salvate
			 */
			OPZIONI_SALVATE: {
				id: 606,
				label: "intranet.OPZIONI.OPZIONI-SALVATE"
			},

			/**
			 * Opzioni di visualizzazione
			 */
			OPZIONI_VISUALIZZAZIONE: {
				id: 607,
				label: "intranet.OPZIONI.OPZIONI-VISUALIZZAZIONE"
			},

			/**
			 * Prova
			 */
			PROVA: {
				id: 608,
				label: "intranet.OPZIONI.PROVA"
			},

			/**
			 * Tema
			 */
			TEMA: {
				id: 609,
				label: "intranet.OPZIONI.TEMA"
			},

			/**
			 * Tema intranet
			 */
			TEMA_INTRANET: {
				id: 610,
				label: "intranet.OPZIONI.TEMA-INTRANET"
			},

			/**
			 * Tema area agenzie
			 */
			TEMA_SITO: {
				id: 611,
				label: "intranet.OPZIONI.TEMA-SITO"
			},

			/**
			 * Content of path intranet.OPZIONI.TEMI-SITO
			 */
			TEMI_SITO: {
				/**
				 * Colori per non rovinarsi la vista
				 */
				DARK: {
					id: 612,
					label: "intranet.OPZIONI.TEMI-SITO.DARK"
				},

				/**
				 * Naar standard
				 */
				DEFAULT: {
					id: 613,
					label: "intranet.OPZIONI.TEMI-SITO.DEFAULT"
				},

				/**
				 * Colori copiati da Booking.com
				 */
				MARCO: {
					id: 614,
					label: "intranet.OPZIONI.TEMI-SITO.MARCO"
				},

			},

			/**
			 * Impostazioni utente
			 */
			TITOLO: {
				id: 615,
				label: "intranet.OPZIONI.TITOLO"
			},

		},

		/**
		 * Content of path intranet.PROGRAMMAZIONE
		 */
		PROGRAMMAZIONE: {
			/**
			 * Content of path intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE
			 */
			ANTICIPI_FORNITORE: {
				/**
				 * Content of path intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR
				 */
				EDITOR: {
					/**
					 * Accordo
					 */
					ACCORDO: {
						id: 616,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.ACCORDO"
					},

					/**
					 * Al
					 */
					AL: {
						id: 617,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.AL"
					},

					/**
					 * La regola di anticipo {nome} è utilizzata in {n} righe di offerta. Eliminandola la regola verrà calcolata con i criteri rimanenti. Procedere?
					 */
					CONFERMA_ELIMINA_KO: {
						id: 618,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.CONFERMA-ELIMINA-KO"
					},

					/**
					 * Confermi l'eliminazionde della regola di anticipo {nome}?
					 */
					CONFERMA_ELIMINA_OK: {
						id: 619,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.CONFERMA-ELIMINA-OK"
					},

					/**
					 * Dal
					 */
					DAL: {
						id: 620,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.DAL"
					},

					/**
					 * Dalla conferma
					 */
					DALLA_CONFERMA: {
						id: 621,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.DALLA-CONFERMA"
					},

					/**
					 * giorni
					 */
					GIORNI: {
						id: 622,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.GIORNI"
					},

					/**
					 * Luogo
					 */
					LUOGO: {
						id: 623,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.LUOGO"
					},

					/**
					 * Moltiplica per num. pax
					 */
					MOLTIPLICA_PER_PAX: {
						id: 624,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.MOLTIPLICA-PER-PAX"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 625,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.NOME"
					},

					/**
					 * Il nome è obbligatorio
					 */
					NOME_OBBLIGATORIO: {
						id: 626,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.NOME-OBBLIGATORIO"
					},

					/**
					 * Note
					 */
					NOTE: {
						id: 627,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.NOTE"
					},

					/**
					 * Notti
					 */
					NOTTI: {
						id: 628,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.NOTTI"
					},

					/**
					 * Nuova regola anticipo
					 */
					NUOVA_REGOLA: {
						id: 629,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.NUOVA-REGOLA"
					},

					/**
					 * Ordine
					 */
					ORDINE: {
						id: 630,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.ORDINE"
					},

					/**
					 * Inserire un numero
					 */
					ORDINE_HELP: {
						id: 631,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.ORDINE-HELP"
					},

					/**
					 * Pagare entro
					 */
					PAGARE_ENTRO: {
						id: 632,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.PAGARE-ENTRO"
					},

					/**
					 * 0 - 999
					 */
					PAGARE_ENTRO_HELP: {
						id: 633,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.PAGARE-ENTRO-HELP"
					},

					/**
					 * Prima dell'ìnizio del servizio
					 */
					PRIMA_INIZIO_SERVIZIO: {
						id: 634,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.PRIMA-INIZIO-SERVIZIO"
					},

					/**
					 * Servizio
					 */
					SERVIZIO: {
						id: 635,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.SERVIZIO"
					},

					/**
					 * Tipo anticipo
					 */
					TIPO_ANTICIPO: {
						id: 636,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.TIPO-ANTICIPO"
					},

					/**
					 * Tipo servizio
					 */
					TIPO_SERVIZIO: {
						id: 637,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.TIPO-SERVIZIO"
					},

					/**
					 * -- Tutti --
					 */
					TUTTI: {
						id: 638,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.TUTTI"
					},

					/**
					 * Valore
					 */
					VALORE: {
						id: 639,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.VALORE"
					},

					/**
					 * Immettere un valore valido
					 */
					VALORE_HELP: {
						id: 640,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.EDITOR.VALORE-HELP"
					},

				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.LISTA
				 */
				LISTA: {
					/**
					 * Accordo
					 */
					ACCORDO: {
						id: 641,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.LISTA.ACCORDO"
					},

					/**
					 * Al {data}
					 */
					AL_DATA: {
						id: 642,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.LISTA.AL-DATA"
					},

					/**
					 * Dal {data}
					 */
					DAL_DATA: {
						id: 643,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.LISTA.DAL-DATA"
					},

					/**
					 * ID
					 */
					ID: {
						id: 644,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.LISTA.ID"
					},

					/**
					 * Luogo
					 */
					LUOGO: {
						id: 645,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.LISTA.LUOGO"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 646,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.LISTA.NOME"
					},

					/**
					 * Nome o ID
					 */
					NOME_O_ID: {
						id: 647,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.LISTA.NOME_O_ID"
					},

					/**
					 * Non associato
					 */
					NON_ASSOCIATO: {
						id: 648,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.LISTA.NON-ASSOCIATO"
					},

					/**
					 * Note
					 */
					NOTE: {
						id: 649,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.LISTA.NOTE"
					},

					/**
					 * Ordine
					 */
					ORDINE: {
						id: 650,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.LISTA.ORDINE"
					},

					/**
					 * Periodo
					 */
					PERIODO: {
						id: 651,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.LISTA.PERIODO"
					},

					/**
					 * Servizio
					 */
					SERVIZIO: {
						id: 652,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.LISTA.SERVIZIO"
					},

					/**
					 * Tipo servizio
					 */
					TIPO_SERVIZIO: {
						id: 653,
						label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.LISTA.TIPO-SERVIZIO"
					},

				},

				/**
				 * -- Nuovo --
				 */
				NUOVO: {
					id: 654,
					label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.NUOVO"
				},

				/**
				 * Ricerca
				 */
				RICERCA: {
					id: 655,
					label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.RICERCA"
				},

				/**
				 * Pagamenti anticipati al fornitore
				 */
				TITOLO: {
					id: 656,
					label: "intranet.PROGRAMMAZIONE.ANTICIPI-FORNITORE.TITOLO"
				},

			},

			/**
			 * Content of path intranet.PROGRAMMAZIONE.ARTICOLI
			 */
			ARTICOLI: {
				/**
				 * Accordo
				 */
				ACCORDO: {
					id: 657,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ACCORDO"
				},

				/**
				 * Aggiorna/modifica allegato
				 */
				AGGIORNA_ALLEGATO: {
					id: 658,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.AGGIORNA-ALLEGATO"
				},

				/**
				 * Aggiungi allegato
				 */
				AGGIUNGI_ALLEGATO: {
					id: 659,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.AGGIUNGI-ALLEGATO"
				},

				/**
				 * Allegati
				 */
				ALLEGATI: {
					id: 660,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ALLEGATI"
				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.ARTICOLI.ANTEPRIMA-TESTO
				 */
				ANTEPRIMA_TESTO: {
					/**
					 * Aggiorna
					 */
					BTN_REFRESH: {
						id: 661,
						label: "intranet.PROGRAMMAZIONE.ARTICOLI.ANTEPRIMA-TESTO.BTN-REFRESH"
					},

					/**
					 * Anteprima del testo
					 */
					TITLE: {
						id: 662,
						label: "intranet.PROGRAMMAZIONE.ARTICOLI.ANTEPRIMA-TESTO.TITLE"
					},

				},

				/**
				 * Articoli
				 */
				ARTICOLI: {
					id: 663,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ARTICOLI"
				},

				/**
				 * Articolo eliminato
				 */
				ARTICOLO_ELIMINATO: {
					id: 664,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ARTICOLO-ELIMINATO"
				},

				/**
				 * Articolo salvato
				 */
				ARTICOLO_SALVATO: {
					id: 665,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ARTICOLO-SALVATO"
				},

				/**
				 * Cambia
				 */
				CAMBIA_COLLEGAMENTO: {
					id: 666,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.CAMBIA-COLLEGAMENTO"
				},

				/**
				 * Cambia articolo padre
				 */
				CAMBIA_PADRE: {
					id: 667,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.CAMBIA-PADRE"
				},

				/**
				 * Carica
				 */
				CARICA: {
					id: 668,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.CARICA"
				},

				/**
				 * Catalogo
				 */
				CATALOGO: {
					id: 669,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.CATALOGO"
				},

				/**
				 * Categoria
				 */
				CATEGORIA: {
					id: 670,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.CATEGORIA"
				},

				/**
				 * Collegamento
				 */
				COLLEGAMENTO: {
					id: 671,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.COLLEGAMENTO"
				},

				/**
				 * Comp. aerea
				 */
				COMPAGNIA_AEREA: {
					id: 672,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.COMPAGNIA-AEREA"
				},

				/**
				 * Confermato
				 */
				CONFERMATO: {
					id: 673,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.CONFERMATO"
				},

				/**
				 * Copia articolo {n}
				 */
				COPIA_ARTICOLO_N: {
					id: 674,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.COPIA-ARTICOLO-N"
				},

				/**
				 * Data
				 */
				DATA: {
					id: 675,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.DATA"
				},

				/**
				 * Descrizione
				 */
				DESCRIZIONE: {
					id: 676,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.DESCRIZIONE"
				},

				/**
				 * Edit html
				 */
				EDIT_HTML: {
					id: 677,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.EDIT-HTML"
				},

				/**
				 * Vuoi eliminare l'articolo ID={id}?
				 */
				ELIMINAZIONE_ARTICOLO: {
					id: 678,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ELIMINAZIONE-ARTICOLO"
				},

				/**
				 * Errore nella lettura dell'articolo
				 */
				ERRORE_LETTURA: {
					id: 679,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ERRORE-LETTURA"
				},

				/**
				 * Estratto conto
				 */
				ESTRATTO_CONTO: {
					id: 680,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ESTRATTO-CONTO"
				},

				/**
				 * File caricato
				 */
				FILE_CARICATO: {
					id: 681,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.FILE-CARICATO"
				},

				/**
				 * Gerarchia
				 */
				GERARCHIA: {
					id: 682,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.GERARCHIA"
				},

				/**
				 * Giorno
				 */
				GIORNO: {
					id: 683,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.GIORNO"
				},

				/**
				 * Giorno: 0-255
				 */
				GIORNO_HELP: {
					id: 684,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.GIORNO-HELP"
				},

				/**
				 * Icona
				 */
				ICONA: {
					id: 685,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ICONA"
				},

				/**
				 * ID
				 */
				ID: {
					id: 686,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ID"
				},

				/**
				 * Imposta dati per...
				 */
				IMPOSTA_DATI: {
					id: 687,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.IMPOSTA-DATI"
				},

				/**
				 * Imposta padre
				 */
				IMPOSTA_PADRE: {
					id: 688,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.IMPOSTA-PADRE"
				},

				/**
				 * Pubblica in
				 */
				INTESTAZIONE: {
					id: 689,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.INTESTAZIONE"
				},

				/**
				 * Lingua
				 */
				LINGUA: {
					id: 690,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.LINGUA"
				},

				/**
				 * Link
				 */
				LINK: {
					id: 691,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.LINK"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 692,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.LUOGO"
				},

				/**
				 * Miniguida
				 */
				MINIGUIDA: {
					id: 693,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.MINIGUIDA"
				},

				/**
				 * {n} articol{n, plural, =0{i} one{o} other{i}} figlio
				 */
				N_FIGLI: {
					id: 694,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.N-FIGLI"
				},

				/**
				 * {n} articol{n, plural, =0{i} one{o} other{i}} padre
				 */
				N_PADRI: {
					id: 695,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.N-PADRI"
				},

				/**
				 * -- Nessuna --
				 */
				NESSUNA: {
					id: 696,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.NESSUNA"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 697,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.NOME"
				},

				/**
				 * Nome / ID
				 */
				NOME_ID: {
					id: 698,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.NOME-ID"
				},

				/**
				 * Non associato
				 */
				NON_ASSOCIATO: {
					id: 699,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.NON-ASSOCIATO"
				},

				/**
				 * -- Non impostata --
				 */
				NON_IMPOSTATA: {
					id: 700,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.NON-IMPOSTATA"
				},

				/**
				 * -- Non impostato --
				 */
				NON_IMPOSTATO: {
					id: 701,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.NON-IMPOSTATO"
				},

				/**
				 * Nuovo articolo
				 */
				NUOVO_ARTICOLO: {
					id: 702,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.NUOVO-ARTICOLO"
				},

				/**
				 * Nuovo articolo {n}
				 */
				NUOVO_ARTICOLO_N: {
					id: 703,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.NUOVO-ARTICOLO-N"
				},

				/**
				 * Prev. Adv
				 */
				OFFERTA: {
					id: 704,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.OFFERTA"
				},

				/**
				 * Ordine
				 */
				ORDINE: {
					id: 705,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ORDINE"
				},

				/**
				 * Ord.glob.
				 */
				ORDINE_GLOBALE: {
					id: 706,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ORDINE-GLOBALE"
				},

				/**
				 * Ordine: 0-255
				 */
				ORDINE_HELP: {
					id: 707,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ORDINE-HELP"
				},

				/**
				 * Visualizza padre come link
				 */
				PADRE_COME_LINK: {
					id: 708,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.PADRE-COME-LINK"
				},

				/**
				 * Posizione
				 */
				POSIZIONE: {
					id: 709,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.POSIZIONE"
				},

				/**
				 * Prev. cliente
				 */
				PREVENTIVO: {
					id: 710,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.PREVENTIVO"
				},

				/**
				 * Anteprima
				 */
				PREVIEW: {
					id: 711,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.PREVIEW"
				},

				/**
				 * Priorità
				 */
				PRIORITA: {
					id: 712,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.PRIORITA"
				},

				/**
				 * Priodità: 0-255
				 */
				PRIORITA_HELP: {
					id: 713,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.PRIORITA-HELP"
				},

				/**
				 * Programma
				 */
				PROGRAMMA: {
					id: 714,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.PROGRAMMA"
				},

				/**
				 * Pubblica al
				 */
				PUBBLICA_AL: {
					id: 715,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.PUBBLICA-AL"
				},

				/**
				 * Pubblica dal
				 */
				PUBBLICA_DAL: {
					id: 716,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.PUBBLICA-DAL"
				},

				/**
				 * Pubblica come link nei documenti
				 */
				PUBBLICA_LINK_DOCS: {
					id: 717,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.PUBBLICA-LINK-DOCS"
				},

				/**
				 * Questo articolo
				 */
				QUESTO_ARTICOLO: {
					id: 718,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.QUESTO-ARTICOLO"
				},

				/**
				 * Ricerca articoli
				 */
				RICERCA: {
					id: 719,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.RICERCA"
				},

				/**
				 * Rimuovi allegato
				 */
				RIMUOVI_ALLEGATO: {
					id: 720,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.RIMUOVI-ALLEGATO"
				},

				/**
				 * Rimuovi
				 */
				RIMUOVI_COLLEGAMENTO: {
					id: 721,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.RIMUOVI-COLLEGAMENTO"
				},

				/**
				 * Rimuovi riferimento ad articolo padre
				 */
				RIMUOVI_RIFERIMENTO_PADRE: {
					id: 722,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.RIMUOVI-RIFERIMENTO-PADRE"
				},

				/**
				 * Scegli
				 */
				SCEGLI: {
					id: 723,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.SCEGLI"
				},

				/**
				 * Scegli file da allegare
				 */
				SCEGLI_ALLEGATO: {
					id: 724,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.SCEGLI-ALLEGATO"
				},

				/**
				 * Scegli un articolo
				 */
				SCEGLI_ARTICOLO: {
					id: 725,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.SCEGLI-ARTICOLO"
				},

				/**
				 * Scegli l'articolo padre
				 */
				SCEGLI_ARTICOLO_PADRE: {
					id: 726,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.SCEGLI-ARTICOLO-PADRE"
				},

				/**
				 * Scegli...
				 */
				SCEGLI_FILE: {
					id: 727,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.SCEGLI-FILE"
				},

				/**
				 * Scegli link da associare
				 */
				SCEGLI_LINK: {
					id: 728,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.SCEGLI-LINK"
				},

				/**
				 * Scegliere un allegato
				 */
				SCEGLIERE_ALLEGATO: {
					id: 729,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.SCEGLIERE-ALLEGATO"
				},

				/**
				 * [img:12345]
				 */
				SELECT: {
					id: 730,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.SELECT"
				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.ARTICOLI.SELEZIONA-IMMAGINI
				 */
				SELEZIONA_IMMAGINI: {
					/**
					 * Inserisci Token
					 */
					BTN_INSERISCI: {
						id: 731,
						label: "intranet.PROGRAMMAZIONE.ARTICOLI.SELEZIONA-IMMAGINI.BTN-INSERISCI"
					},

					/**
					 * Verrà salvato il token [img:{selectedImageId}]
					 */
					LBL_SAVE_TOKEN: {
						id: 732,
						label: "intranet.PROGRAMMAZIONE.ARTICOLI.SELEZIONA-IMMAGINI.LBL-SAVE-TOKEN"
					},

					/**
					 * Selezione Immagine
					 */
					TITLE: {
						id: 733,
						label: "intranet.PROGRAMMAZIONE.ARTICOLI.SELEZIONA-IMMAGINI.TITLE"
					},

				},

				/**
				 * Servizio
				 */
				SERVIZIO: {
					id: 734,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.SERVIZIO"
				},

				/**
				 * Sunto
				 */
				SUNTO: {
					id: 735,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.SUNTO"
				},

				/**
				 * Sunto / Codice
				 */
				SUNTO_CODICE: {
					id: 736,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.SUNTO-CODICE"
				},

				/**
				 * Testo
				 */
				TESTO: {
					id: 737,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.TESTO"
				},

				/**
				 * Documento
				 */
				TIPO_DOCUMENTO: {
					id: 738,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.TIPO-DOCUMENTO"
				},

				/**
				 * Tipo ricerca
				 */
				TIPO_RICERCA: {
					id: 739,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.TIPO-RICERCA"
				},

				/**
				 * Tipo
				 */
				TIPO_SERVIZIO: {
					id: 740,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.TIPO-SERVIZIO"
				},

				/**
				 * Titolo
				 */
				TITOLO: {
					id: 741,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.TITOLO"
				},

				/**
				 * Titolo / ID
				 */
				TITOLO_ID: {
					id: 742,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.TITOLO-ID"
				},

				/**
				 * Titolo originale
				 */
				TITOLO_ORIGINALE: {
					id: 743,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.TITOLO-ORIGINALE"
				},

				/**
				 * Trad. da conf.
				 */
				TRAD_DA_CONF: {
					id: 744,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.TRAD-DA-CONF"
				},

				/**
				 * Traduci
				 */
				TRADUCI: {
					id: 745,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.TRADUCI"
				},

				/**
				 * Traduzione completata
				 */
				TRADUZIONE_COMPLETATA: {
					id: 746,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.TRADUZIONE-COMPLETATA"
				},

				/**
				 * -- Tutte le categorie --
				 */
				TUTTE_LE_CATEGORIE: {
					id: 747,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.TUTTE-LE-CATEGORIE"
				},

				/**
				 * -- Tutte le intestazioni --
				 */
				TUTTE_LE_INTESTAZIONI: {
					id: 748,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.TUTTE-LE-INTESTAZIONI"
				},

				/**
				 * Ultimo aggiornamento
				 */
				ULTIMO_AGGIORNAMENTO: {
					id: 749,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ULTIMO-AGGIORNAMENTO"
				},

				/**
				 * Vedi
				 */
				VEDI: {
					id: 750,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.VEDI"
				},

				/**
				 * Voucher
				 */
				VOUCHER: {
					id: 751,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.VOUCHER"
				},

				/**
				 * Web
				 */
				WEB: {
					id: 752,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.WEB"
				},

				/**
				 * Regole di pubblicazione
				 */
				PUBBLICAZIONI: {
					id: 5968,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.PUBBLICAZIONI"
				},

				/**
				 * Sito
				 */
				SITO: {
					id: 5969,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.SITO"
				},

				/**
				 * Pubblica
				 */
				PUBBLICA: {
					id: 5970,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.PUBBLICA"
				},

				/**
				 * Sovrascrivi il markup
				 */
				MARKUP_OVERRIDE: {
					id: 5971,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.MARKUP-OVERRIDE"
				},

				/**
				 * Operatore
				 */
				OPERATORE: {
					id: 5987,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.OPERATORE"
				},

				/**
				 * Sei sicuro di voler ripristinare l'articolo {id}?
				 */
				RIPRISTINO_ARTICOLO: {
					id: 6088,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.RIPRISTINO-ARTICOLO"
				},

				/**
				 * Operatore
				 */
				OPEARTORE: {
					id: 6182,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.OPEARTORE"
				},

				/**
				 * Articolo ripristinato
				 */
				ARTICOLO_RIPRISTINATO: {
					id: 6209,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ARTICOLO-RIPRISTINATO"
				},

				/**
				 * Solo attivi
				 */
				ONLY_ACTIVE: {
					id: 6213,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.ONLY-ACTIVE"
				},

				/**
				 * Generato da AI
				 */
				GENERATO_AI: {
					id: 6554,
					label: "intranet.PROGRAMMAZIONE.ARTICOLI.GENERATO-AI"
				},

			},

			/**
			 * Content of path intranet.PROGRAMMAZIONE.CHECK-PREZZI
			 */
			CHECK_PREZZI: {
				/**
				 * Adulti
				 */
				ADULTI: {
					id: 753,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.ADULTI"
				},

				/**
				 * Bambini
				 */
				BAMBINI: {
					id: 754,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.BAMBINI"
				},

				/**
				 * Camera
				 */
				CAMERA: {
					id: 755,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.CAMERA"
				},

				/**
				 * Data
				 */
				DATA: {
					id: 756,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.DATA"
				},

				/**
				 * Età bambino
				 */
				ETA_BAMBINO: {
					id: 757,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.ETA-BAMBINO"
				},

				/**
				 * Fornitore
				 */
				FORNITORE: {
					id: 758,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.FORNITORE"
				},

				/**
				 * ID
				 */
				ID: {
					id: 759,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.ID"
				},

				/**
				 * Impersona utente
				 */
				IMPERSONA_UTENTE: {
					id: 760,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.IMPERSONA-UTENTE"
				},

				/**
				 * Listino
				 */
				LISTINO: {
					id: 761,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.LISTINO"
				},

				/**
				 * Luogo fornitore dest.
				 */
				LUOGO_DEST_FORNITORE: {
					id: 762,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.LUOGO-DEST-FORNITORE"
				},

				/**
				 * Luogo Naar dest.
				 */
				LUOGO_DEST_NAAR: {
					id: 763,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.LUOGO-DEST-NAAR"
				},

				/**
				 * Luogo fornitore
				 */
				LUOGO_FORNITORE: {
					id: 764,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.LUOGO-FORNITORE"
				},

				/**
				 * Luogo Naar
				 */
				LUOGO_NAAR: {
					id: 765,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.LUOGO-NAAR"
				},

				/**
				 * Nessun risultato utile
				 */
				NO_RESULT: {
					id: 766,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.NO-RESULT"
				},

				/**
				 * Notti
				 */
				NOTTI: {
					id: 767,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.NOTTI"
				},

				/**
				 * 
				 */
				NRF: {
					id: 768,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.NRF"
				},

				/**
				 * Ora Dropoff
				 */
				ORA_DROPOFF: {
					id: 769,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.ORA-DROPOFF"
				},

				/**
				 * Ora Pickup
				 */
				ORA_PICKUP: {
					id: 770,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.ORA-PICKUP"
				},

				/**
				 * Pax ID
				 */
				PAX_ID: {
					id: 771,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.PAX-ID"
				},

				/**
				 * 
				 */
				PI: {
					id: 772,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.PI"
				},

				/**
				 * Req
				 */
				REQ: {
					id: 773,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.REQ"
				},

				/**
				 * Res
				 */
				RES: {
					id: 774,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.RES"
				},

				/**
				 * Senior
				 */
				SENIORS: {
					id: 775,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.SENIORS"
				},

				/**
				 * Servizio singolo
				 */
				SERVIZIO_SINGOLO: {
					id: 776,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.SERVIZIO-SINGOLO"
				},

				/**
				 * Specificare il luogo fornitore
				 */
				SPECIFICA_LUOGO_FORNITORE: {
					id: 777,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.SPECIFICA-LUOGO-FORNITORE"
				},

				/**
				 * Specificare il luogo Naar
				 */
				SPECIFICA_LUOGO_NAAR: {
					id: 778,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.SPECIFICA-LUOGO-NAAR"
				},

				/**
				 * Tipo luogo
				 */
				TIPO_LUOGO: {
					id: 779,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.TIPO-LUOGO"
				},

				/**
				 * Tipo servizio
				 */
				TIPO_SERVIZIO: {
					id: 780,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.TIPO-SERVIZIO"
				},

				/**
				 * Check prezzi
				 */
				TITOLO: {
					id: 781,
					label: "intranet.PROGRAMMAZIONE.CHECK-PREZZI.TITOLO"
				},

			},

			/**
			 * Content of path intranet.PROGRAMMAZIONE.CMS
			 */
			CMS: {
				/**
				 * Aggiungi articolo
				 */
				AGGIUNGI_ARTICOLO: {
					id: 782,
					label: "intranet.PROGRAMMAZIONE.CMS.AGGIUNGI-ARTICOLO"
				},

				/**
				 * Aggiungi link in questa sezione
				 */
				AGGIUNGI_LINK: {
					id: 783,
					label: "intranet.PROGRAMMAZIONE.CMS.AGGIUNGI-LINK"
				},

				/**
				 * Associazione al link {nome}
				 */
				ASSOCIA_ARTICOLO: {
					id: 784,
					label: "intranet.PROGRAMMAZIONE.CMS.ASSOCIA-ARTICOLO"
				},

				/**
				 * Eliminare il link {nome}?
				 */
				CONFERMA_ELIMINAZIONE_LINK: {
					id: 785,
					label: "intranet.PROGRAMMAZIONE.CMS.CONFERMA-ELIMINAZIONE-LINK"
				},

				/**
				 * Contenuto
				 */
				CONTENUTO: {
					id: 786,
					label: "intranet.PROGRAMMAZIONE.CMS.CONTENUTO"
				},

				/**
				 * Contenuto aggiunto
				 */
				CONTENUTO_AGGIUNTO: {
					id: 787,
					label: "intranet.PROGRAMMAZIONE.CMS.CONTENUTO-AGGIUNTO"
				},

				/**
				 * Contenuto eliminato
				 */
				CONTENUTO_ELIMINATO: {
					id: 788,
					label: "intranet.PROGRAMMAZIONE.CMS.CONTENUTO-ELIMINATO"
				},

				/**
				 * Il contenuto è stato salvato
				 */
				CONTENUTO_SALVATO: {
					id: 789,
					label: "intranet.PROGRAMMAZIONE.CMS.CONTENUTO-SALVATO"
				},

				/**
				 * Nuovo collegamento
				 */
				CREA_LINK: {
					id: 790,
					label: "intranet.PROGRAMMAZIONE.CMS.CREA-LINK"
				},

				/**
				 * Icona
				 */
				ICONA: {
					id: 791,
					label: "intranet.PROGRAMMAZIONE.CMS.ICONA"
				},

				/**
				 * Link
				 */
				LINK: {
					id: 792,
					label: "intranet.PROGRAMMAZIONE.CMS.LINK"
				},

				/**
				 * Link salvato
				 */
				LINK_CREATO: {
					id: 793,
					label: "intranet.PROGRAMMAZIONE.CMS.LINK-CREATO"
				},

				/**
				 * Link eliminato
				 */
				LINK_ELIMINATO: {
					id: 794,
					label: "intranet.PROGRAMMAZIONE.CMS.LINK-ELIMINATO"
				},

				/**
				 * Inserire un link valido
				 */
				LINK_HELP: {
					id: 795,
					label: "intranet.PROGRAMMAZIONE.CMS.LINK-HELP"
				},

				/**
				 * Link salvato
				 */
				LINK_SALVATO: {
					id: 796,
					label: "intranet.PROGRAMMAZIONE.CMS.LINK-SALVATO"
				},

				/**
				 * Modifica collegamento
				 */
				MODIFICA_LINK: {
					id: 797,
					label: "intranet.PROGRAMMAZIONE.CMS.MODIFICA-LINK"
				},

				/**
				 * Modifica ordinamento
				 */
				MODIFICA_ORDINAMENTO: {
					id: 798,
					label: "intranet.PROGRAMMAZIONE.CMS.MODIFICA-ORDINAMENTO"
				},

				/**
				 * Modifica l'ordine degli articoli
				 */
				MODIFICA_ORDINE_ARTICOLI: {
					id: 799,
					label: "intranet.PROGRAMMAZIONE.CMS.MODIFICA-ORDINE-ARTICOLI"
				},

				/**
				 * Inserire il titolo del link
				 */
				NOME_HELP: {
					id: 800,
					label: "intranet.PROGRAMMAZIONE.CMS.NOME-HELP"
				},

				/**
				 * Ordinamento impostato
				 */
				ORDINAMENTO_IMPOSTATO: {
					id: 801,
					label: "intranet.PROGRAMMAZIONE.CMS.ORDINAMENTO-IMPOSTATO"
				},

				/**
				 * Rimuovere il contenuto {nome}?
				 */
				RIMUOVERE_IL_CONTENUTO: {
					id: 802,
					label: "intranet.PROGRAMMAZIONE.CMS.RIMUOVERE-IL-CONTENUTO"
				},

				/**
				 * Tipo
				 */
				TIPO_LINK: {
					id: 803,
					label: "intranet.PROGRAMMAZIONE.CMS.TIPO-LINK"
				},

				/**
				 * Cms
				 */
				TITLE: {
					id: 804,
					label: "intranet.PROGRAMMAZIONE.CMS.TITLE"
				},

				/**
				 * Url
				 */
				URL: {
					id: 805,
					label: "intranet.PROGRAMMAZIONE.CMS.URL"
				},

				/**
				 * Inserire una url valida
				 */
				URL_HELP: {
					id: 806,
					label: "intranet.PROGRAMMAZIONE.CMS.URL-HELP"
				},

			},

			/**
			 * Content of path intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI
			 */
			DATI_AGGIUNTIVI: {
				/**
				 * Copia dato agg. {n}
				 */
				COPIA_DATI_AGGIUNTIVO_N: {
					id: 807,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.COPIA-DATI-AGGIUNTIVO-N"
				},

				/**
				 * Dati aggiuntivi
				 */
				DATI_AGGIUNTIVI: {
					id: 808,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.DATI-AGGIUNTIVI"
				},

				/**
				 * Dato aggiuntivo eliminato
				 */
				DATO_ELIMINATO: {
					id: 809,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.DATO-ELIMINATO"
				},

				/**
				 * Dato aggiuntivo salvato
				 */
				DATO_SALVATO: {
					id: 810,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.DATO-SALVATO"
				},

				/**
				 * Vuoi eliminare il dato {nome}?
				 */
				ELIMINAZIONE_DATO: {
					id: 811,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.ELIMINAZIONE-DATO"
				},

				/**
				 * Errore nel leggere i dati
				 */
				ERRORE_LETTURA: {
					id: 812,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.ERRORE-LETTURA"
				},

				/**
				 * ID
				 */
				ID: {
					id: 813,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.ID"
				},

				/**
				 * Immagine
				 */
				IMMAGINE: {
					id: 814,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.IMMAGINE"
				},

				/**
				 * ID Immagine
				 */
				IMMAGINE_ID: {
					id: 815,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.IMMAGINE-ID"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 816,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.LUOGO"
				},

				/**
				 * Codice
				 */
				MEMO: {
					id: 817,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.MEMO"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 818,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.NOME"
				},

				/**
				 * Tedesco
				 */
				NOME_DE: {
					id: 819,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.NOME-DE"
				},

				/**
				 * Inglese
				 */
				NOME_EN: {
					id: 820,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.NOME-EN"
				},

				/**
				 * Spagnolo
				 */
				NOME_ES: {
					id: 821,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.NOME-ES"
				},

				/**
				 * Francese
				 */
				NOME_FR: {
					id: 822,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.NOME-FR"
				},

				/**
				 * Nome/ID
				 */
				NOME_ID: {
					id: 823,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.NOME-ID"
				},

				/**
				 * Nuova dato {n}
				 */
				NUOVO_DATO_N: {
					id: 824,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.NUOVO-DATO-N"
				},

				/**
				 * Valore richiesto
				 */
				OBBLIGATORIO: {
					id: 825,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.OBBLIGATORIO"
				},

				/**
				 * Ordine
				 */
				ORDINE: {
					id: 826,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.ORDINE"
				},

				/**
				 * Priorità
				 */
				PRIORITA: {
					id: 827,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.PRIORITA"
				},

				/**
				 * Qualsiasi luogo
				 */
				QUALSIASI_LUOGO: {
					id: 828,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.QUALSIASI-LUOGO"
				},

				/**
				 * Qualsiasi tipo
				 */
				QUALSIASI_TIPO: {
					id: 829,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.QUALSIASI-TIPO"
				},

				/**
				 * Ricerca dati aggiuntivi
				 */
				RICERCA: {
					id: 830,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.RICERCA"
				},

				/**
				 * Consenti dal/al
				 */
				TEMPORIZZATO: {
					id: 831,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.TEMPORIZZATO"
				},

				/**
				 * Tipo
				 */
				TIPO: {
					id: 832,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.TIPO"
				},

				/**
				 * Tipo servizio
				 */
				TIPO_SERVIZIO: {
					id: 833,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.TIPO-SERVIZIO"
				},

				/**
				 * Variabili
				 */
				VARIABILI: {
					id: 834,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.VARIABILI"
				},

				/**
				 * PROGRAMMAZIONE.DATI-AGGIUNTIVI.DATI-AGGIUNTIVO
				 */
				DATI_AGGIUNTIVO: {
					id: 6415,
					label: "intranet.PROGRAMMAZIONE.DATI-AGGIUNTIVI.DATI-AGGIUNTIVO"
				},

			},

			/**
			 * Content of path intranet.PROGRAMMAZIONE.FRASI
			 */
			FRASI: {
				/**
				 * ID
				 */
				ARTICOLO_ID: {
					id: 835,
					label: "intranet.PROGRAMMAZIONE.FRASI.ARTICOLO-ID"
				},

				/**
				 * Titilo articolo
				 */
				ARTICOLO_TITOLO: {
					id: 836,
					label: "intranet.PROGRAMMAZIONE.FRASI.ARTICOLO-TITOLO"
				},

				/**
				 * Contatto
				 */
				CONTATTO: {
					id: 837,
					label: "intranet.PROGRAMMAZIONE.FRASI.CONTATTO"
				},

				/**
				 * Copia frase {n}
				 */
				COPIA_FRASE_N: {
					id: 838,
					label: "intranet.PROGRAMMAZIONE.FRASI.COPIA-FRASE-N"
				},

				/**
				 * Corrispondenza tag
				 */
				CORRISPONDENZA_TAG: {
					id: 839,
					label: "intranet.PROGRAMMAZIONE.FRASI.CORRISPONDENZA-TAG"
				},

				/**
				 * Tutti
				 */
				CORRISPONDENZA_TAG_TUTTI: {
					id: 840,
					label: "intranet.PROGRAMMAZIONE.FRASI.CORRISPONDENZA-TAG-TUTTI"
				},

				/**
				 * Almeno uno
				 */
				CORRISPONDENZA_TAG_UNO: {
					id: 841,
					label: "intranet.PROGRAMMAZIONE.FRASI.CORRISPONDENZA-TAG-UNO"
				},

				/**
				 * Def. FIT
				 */
				DEF_FIT: {
					id: 842,
					label: "intranet.PROGRAMMAZIONE.FRASI.DEF-FIT"
				},

				/**
				 * Default FIT
				 */
				DEF_FIT_2: {
					id: 843,
					label: "intranet.PROGRAMMAZIONE.FRASI.DEF-FIT-2"
				},

				/**
				 * Mostra utilizzi
				 */
				DOVE_USATA: {
					id: 844,
					label: "intranet.PROGRAMMAZIONE.FRASI.DOVE-USATA"
				},

				/**
				 * Vuoi eliminare la frase ID={id}?
				 */
				ELIMINAZIONE_FRASE: {
					id: 845,
					label: "intranet.PROGRAMMAZIONE.FRASI.ELIMINAZIONE-FRASE"
				},

				/**
				 * Errore nella lettura della frase
				 */
				ERRORE_LETTURA: {
					id: 846,
					label: "intranet.PROGRAMMAZIONE.FRASI.ERRORE-LETTURA"
				},

				/**
				 * FIT
				 */
				FIT: {
					id: 847,
					label: "intranet.PROGRAMMAZIONE.FRASI.FIT"
				},

				/**
				 * Usa per FIT
				 */
				FIT_2: {
					id: 848,
					label: "intranet.PROGRAMMAZIONE.FRASI.FIT-2"
				},

				/**
				 * Frase
				 */
				FRASE: {
					id: 849,
					label: "intranet.PROGRAMMAZIONE.FRASI.FRASE"
				},

				/**
				 * Frase eliminata
				 */
				FRASE_ELIMINATA: {
					id: 850,
					label: "intranet.PROGRAMMAZIONE.FRASI.FRASE-ELIMINATA"
				},

				/**
				 * Frase salvata
				 */
				FRASE_SALVATA: {
					id: 851,
					label: "intranet.PROGRAMMAZIONE.FRASI.FRASE-SALVATA"
				},

				/**
				 * Frasi
				 */
				FRASI: {
					id: 852,
					label: "intranet.PROGRAMMAZIONE.FRASI.FRASI"
				},

				/**
				 * Cerca frasi generiche
				 */
				FRASI_GENERICHE: {
					id: 853,
					label: "intranet.PROGRAMMAZIONE.FRASI.FRASI-GENERICHE"
				},

				/**
				 * Cerca frasi per luoghi e servizi
				 */
				FRASI_LUOGHI_SERVIZI: {
					id: 854,
					label: "intranet.PROGRAMMAZIONE.FRASI.FRASI-LUOGHI-SERVIZI"
				},

				/**
				 * ID
				 */
				ID: {
					id: 855,
					label: "intranet.PROGRAMMAZIONE.FRASI.ID"
				},

				/**
				 * Parametri automatici: @luogo: Luogo associato all'articolo, @servizio: Servizio associato all'articolo, @luogotappa: In un itinerario è la concatenazione di tutti i luoghi da cui si passa nel giorno in cui si inserisce la frase. I nom
				 */
				INFO_TEXT: {
					id: 856,
					label: "intranet.PROGRAMMAZIONE.FRASI.INFO-TEXT"
				},

				/**
				 * Inserisci di default nella sezione
				 */
				IS_DEFAULT: {
					id: 857,
					label: "intranet.PROGRAMMAZIONE.FRASI.IS-DEFAULT"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 858,
					label: "intranet.PROGRAMMAZIONE.FRASI.LUOGO"
				},

				/**
				 * Luogo dest.
				 */
				LUOGO_DEST: {
					id: 859,
					label: "intranet.PROGRAMMAZIONE.FRASI.LUOGO-DEST"
				},

				/**
				 * Nuova frase
				 */
				NUOVA_FRASE: {
					id: 860,
					label: "intranet.PROGRAMMAZIONE.FRASI.NUOVA-FRASE"
				},

				/**
				 * Nuova frase {n}
				 */
				NUOVA_FRASE_N: {
					id: 861,
					label: "intranet.PROGRAMMAZIONE.FRASI.NUOVA-FRASE-N"
				},

				/**
				 * Paese
				 */
				PAESE: {
					id: 862,
					label: "intranet.PROGRAMMAZIONE.FRASI.PAESE"
				},

				/**
				 * Pasti
				 */
				PASTI: {
					id: 863,
					label: "intranet.PROGRAMMAZIONE.FRASI.PASTI"
				},

				/**
				 * Cena (D)
				 */
				PASTI_CENA: {
					id: 864,
					label: "intranet.PROGRAMMAZIONE.FRASI.PASTI-CENA"
				},

				/**
				 * Colazione (B)
				 */
				PASTI_COLAZIONE: {
					id: 865,
					label: "intranet.PROGRAMMAZIONE.FRASI.PASTI-COLAZIONE"
				},

				/**
				 * Nessuno
				 */
				PASTI_NESSUNO: {
					id: 866,
					label: "intranet.PROGRAMMAZIONE.FRASI.PASTI-NESSUNO"
				},

				/**
				 * Pranzo (L)
				 */
				PASTI_PRANZO: {
					id: 867,
					label: "intranet.PROGRAMMAZIONE.FRASI.PASTI-PRANZO"
				},

				/**
				 * Pranzo al sacco (BL)
				 */
				PASTI_PRANZO_SACCO: {
					id: 868,
					label: "intranet.PROGRAMMAZIONE.FRASI.PASTI-PRANZO-SACCO"
				},

				/**
				 * al
				 */
				PUBBLICA_AL: {
					id: 869,
					label: "intranet.PROGRAMMAZIONE.FRASI.PUBBLICA-AL"
				},

				/**
				 * Pubblica dal
				 */
				PUBBLICA_DAL: {
					id: 870,
					label: "intranet.PROGRAMMAZIONE.FRASI.PUBBLICA-DAL"
				},

				/**
				 * Qualsiasi contatto
				 */
				QUALSIASI_CONTATTO: {
					id: 871,
					label: "intranet.PROGRAMMAZIONE.FRASI.QUALSIASI-CONTATTO"
				},

				/**
				 * Qualsiasi luogo
				 */
				QUALSIASI_LUOGO: {
					id: 872,
					label: "intranet.PROGRAMMAZIONE.FRASI.QUALSIASI-LUOGO"
				},

				/**
				 * Qualsiasi luogo di destinazione
				 */
				QUALSIASI_LUOGO_DEST: {
					id: 873,
					label: "intranet.PROGRAMMAZIONE.FRASI.QUALSIASI-LUOGO-DEST"
				},

				/**
				 * Qualsiasi paese
				 */
				QUALSIASI_PAESE: {
					id: 874,
					label: "intranet.PROGRAMMAZIONE.FRASI.QUALSIASI-PAESE"
				},

				/**
				 * Qualsiasi servizio
				 */
				QUALSIASI_SERVIZIO: {
					id: 875,
					label: "intranet.PROGRAMMAZIONE.FRASI.QUALSIASI-SERVIZIO"
				},

				/**
				 * Tutti i tipi di servizio
				 */
				QUALSIASI_TIPO_SERVIZIO: {
					id: 876,
					label: "intranet.PROGRAMMAZIONE.FRASI.QUALSIASI-TIPO-SERVIZIO"
				},

				/**
				 * Qualunque sezione
				 */
				QUALUNQUE_SEZIONE: {
					id: 877,
					label: "intranet.PROGRAMMAZIONE.FRASI.QUALUNQUE-SEZIONE"
				},

				/**
				 * Ricerca frasi
				 */
				RICERCA: {
					id: 878,
					label: "intranet.PROGRAMMAZIONE.FRASI.RICERCA"
				},

				/**
				 * Servizio
				 */
				SERVIZIO: {
					id: 879,
					label: "intranet.PROGRAMMAZIONE.FRASI.SERVIZIO"
				},

				/**
				 * Sezione
				 */
				SEZIONE: {
					id: 880,
					label: "intranet.PROGRAMMAZIONE.FRASI.SEZIONE"
				},

				/**
				 * Ordine
				 */
				SORT_ORDER: {
					id: 881,
					label: "intranet.PROGRAMMAZIONE.FRASI.SORT-ORDER"
				},

				/**
				 * Tag
				 */
				TAG: {
					id: 882,
					label: "intranet.PROGRAMMAZIONE.FRASI.TAG"
				},

				/**
				 * Tipo servizio
				 */
				TIPO_SERVIZIO: {
					id: 883,
					label: "intranet.PROGRAMMAZIONE.FRASI.TIPO-SERVIZIO"
				},

				/**
				 * Titolo
				 */
				TITOLO: {
					id: 884,
					label: "intranet.PROGRAMMAZIONE.FRASI.TITOLO"
				},

				/**
				 * Frase / Titolo / ID
				 */
				TITOLO_ID: {
					id: 885,
					label: "intranet.PROGRAMMAZIONE.FRASI.TITOLO-ID"
				},

				/**
				 * Aggiungi traduzione
				 */
				TRADUCI: {
					id: 886,
					label: "intranet.PROGRAMMAZIONE.FRASI.TRADUCI"
				},

				/**
				 * Traduzione completata
				 */
				TRADUZIONE_COMPLETATA: {
					id: 887,
					label: "intranet.PROGRAMMAZIONE.FRASI.TRADUZIONE-COMPLETATA"
				},

				/**
				 * Tedesco
				 */
				TRADUZIONE_LINGUA_Deutsch: {
					id: 888,
					label: "intranet.PROGRAMMAZIONE.FRASI.TRADUZIONE-LINGUA-Deutsch"
				},

				/**
				 * Inglese
				 */
				TRADUZIONE_LINGUA_ENGLISH: {
					id: 889,
					label: "intranet.PROGRAMMAZIONE.FRASI.TRADUZIONE-LINGUA-ENGLISH"
				},

				/**
				 * Francese
				 */
				TRADUZIONE_LINGUA_Francais: {
					id: 890,
					label: "intranet.PROGRAMMAZIONE.FRASI.TRADUZIONE-LINGUA-Francais"
				},

				/**
				 * Utilizzi
				 */
				UTILIZZI: {
					id: 891,
					label: "intranet.PROGRAMMAZIONE.FRASI.UTILIZZI"
				},

				/**
				 * Visib.
				 */
				VISIBILITA: {
					id: 892,
					label: "intranet.PROGRAMMAZIONE.FRASI.VISIBILITA"
				},

				/**
				 * Visibile
				 */
				VISIBILITA_2: {
					id: 893,
					label: "intranet.PROGRAMMAZIONE.FRASI.VISIBILITA-2"
				},

				/**
				 * Preventivo
				 */
				VISIBILITA_PREVENTIVO: {
					id: 894,
					label: "intranet.PROGRAMMAZIONE.FRASI.VISIBILITA-PREVENTIVO"
				},

				/**
				 * Sempre
				 */
				VISIBILITA_SEMPRE: {
					id: 895,
					label: "intranet.PROGRAMMAZIONE.FRASI.VISIBILITA-SEMPRE"
				},

				/**
				 * Prog. Viaggio
				 */
				VISIBILITA_VIAGGIO: {
					id: 896,
					label: "intranet.PROGRAMMAZIONE.FRASI.VISIBILITA-VIAGGIO"
				},

				/**
				 * Web
				 */
				VISIBILITA_WEB: {
					id: 897,
					label: "intranet.PROGRAMMAZIONE.FRASI.VISIBILITA-WEB"
				},

				/**
				 * Vuoi ripristinare la frase ID={id}?
				 */
				RIPRISTINO_FRASE: {
					id: 6216,
					label: "intranet.PROGRAMMAZIONE.FRASI.RIPRISTINO-FRASE"
				},

				/**
				 * Frase ripristinata
				 */
				FRASE_RIPRISTINATA: {
					id: 6217,
					label: "intranet.PROGRAMMAZIONE.FRASI.FRASE-RIPRISTINATA"
				},

			},

			/**
			 * Content of path intranet.PROGRAMMAZIONE.IMMAGINI
			 */
			IMMAGINI: {
				/**
				 * A
				 */
				ALTEZZA_ABBREVIATA: {
					id: 898,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.ALTEZZA-ABBREVIATA"
				},

				/**
				 * Articolo
				 */
				ARTICOLO: {
					id: 899,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.ARTICOLO"
				},

				/**
				 * Carica nuove immagini
				 */
				CARICA: {
					id: 900,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.CARICA"
				},

				/**
				 * Carica immagini
				 */
				CARICA_IMMAGINI: {
					id: 901,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.CARICA-IMMAGINI"
				},

				/**
				 * Categoria
				 */
				CATEGORIA: {
					id: 902,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.CATEGORIA"
				},

				/**
				 * Data
				 */
				DATA: {
					id: 903,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.DATA"
				},

				/**
				 * Didascalia
				 */
				DIDASCALIA: {
					id: 904,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.DIDASCALIA"
				},

				/**
				 * Dimensioni
				 */
				DIMENSIONI: {
					id: 905,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.DIMENSIONI"
				},

				/**
				 * Dimensioni minime
				 */
				DIMENSIONI_MINIME: {
					id: 906,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.DIMENSIONI-MINIME"
				},

				/**
				 * Vuoi duplicare l'immagione ID={id}?
				 */
				DUPLICARE_IMMAGINE: {
					id: 907,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.DUPLICARE-IMMAGINE"
				},

				/**
				 * Gestione immagini
				 */
				GESTIONE_IMMAGINI: {
					id: 908,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.GESTIONE-IMMAGINI"
				},

				/**
				 * ID
				 */
				ID: {
					id: 909,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.ID"
				},

				/**
				 * Immagine duplicata
				 */
				IMMAGINE_DUPLICATA: {
					id: 910,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.IMMAGINE-DUPLICATA"
				},

				/**
				 * Immagine: {title}
				 */
				IMMAGINE_TITOLO: {
					id: 911,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.IMMAGINE-TITOLO"
				},

				/**
				 * Immagini sicure per ogni età
				 */
				IMMAGINI_SICURE: {
					id: 912,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.IMMAGINI-SICURE"
				},

				/**
				 * Importa
				 */
				IMPORTA: {
					id: 913,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.IMPORTA"
				},

				/**
				 * Importazione di {n} immagin{n, plural, =0{i} one{e} other{i}}
				 */
				IMPORTA_IMMAGINI: {
					id: 914,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.IMPORTA-IMMAGINI"
				},

				/**
				 * Importare {n} immagin{n, plural, =0{i} one{e} other{i}} da Pixabay?
				 */
				IMPORTA_IMMAGINI_CONFERMA: {
					id: 915,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.IMPORTA-IMMAGINI-CONFERMA"
				},

				/**
				 * L
				 */
				LARGHEZZA_ABBREVIATA: {
					id: 916,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.LARGHEZZA-ABBREVIATA"
				},

				/**
				 * Lingua
				 */
				LINGUA: {
					id: 917,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.LINGUA"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 918,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.LUOGO"
				},

				/**
				 * Miniguida
				 */
				MINIGUIDA: {
					id: 919,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.MINIGUIDA"
				},

				/**
				 * Ordinamento
				 */
				ORDINAMENTO: {
					id: 920,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.ORDINAMENTO"
				},

				/**
				 * Ordine
				 */
				ORDINE: {
					id: 921,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.ORDINE"
				},

				/**
				 * Orientamento
				 */
				ORIENTAMENTO: {
					id: 922,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.ORIENTAMENTO"
				},

				/**
				 * Paese
				 */
				PAESE: {
					id: 923,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.PAESE"
				},

				/**
				 * Preventivo
				 */
				PREVENTIVO: {
					id: 924,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.PREVENTIVO"
				},

				/**
				 * Programma
				 */
				PROGRAMMA: {
					id: 925,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.PROGRAMMA"
				},

				/**
				 * 
				 */
				PRPOGRAMMA: {
					id: 926,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.PRPOGRAMMA"
				},

				/**
				 * Regione
				 */
				REGIONE: {
					id: 927,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.REGIONE"
				},

				/**
				 * Ricerca su Pixabay
				 */
				RICERCA_SU_PIXABAY: {
					id: 928,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.RICERCA-SU-PIXABAY"
				},

				/**
				 * Scelte dall'editore
				 */
				SCELTE_EDITORE: {
					id: 929,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.SCELTE-EDITORE"
				},

				/**
				 * Seleziona immagini
				 */
				SELEZIONA_IMMAGINI: {
					id: 930,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.SELEZIONA-IMMAGINI"
				},

				/**
				 * Servizio
				 */
				SERVIZIO: {
					id: 931,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.SERVIZIO"
				},

				/**
				 * Sito Web
				 */
				SITOWEB: {
					id: 932,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.SITOWEB"
				},

				/**
				 * Soggetto
				 */
				SOGGETTO: {
					id: 933,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.SOGGETTO"
				},

				/**
				 * minimo 2 caratteri
				 */
				SOGGETTO_HELP: {
					id: 934,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.SOGGETTO-HELP"
				},

				/**
				 * Solo luoghi
				 */
				SOLO_LUOGO: {
					id: 935,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.SOLO-LUOGO"
				},

				/**
				 * Tipo immagine
				 */
				TIPO_DI_IMMAGINE: {
					id: 936,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.TIPO-DI-IMMAGINE"
				},

				/**
				 * Immagini
				 */
				TITLE: {
					id: 937,
					label: "intranet.PROGRAMMAZIONE.IMMAGINI.TITLE"
				},

			},

			/**
			 * Content of path intranet.PROGRAMMAZIONE.MARGINI-SERVIZI
			 */
			MARGINI_SERVIZI: {
				/**
				 * Content of path intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR
				 */
				EDITOR: {
					/**
					 * Accordo
					 */
					ACCORDO: {
						id: 938,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.ACCORDO"
					},

					/**
					 * Servizio al
					 */
					AL: {
						id: 939,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.AL"
					},

					/**
					 * Confermi l'eliminazionde della regola id={id}?
					 */
					CONFERMA_ELIMINA: {
						id: 940,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.CONFERMA-ELIMINA"
					},

					/**
					 * Servizio dal
					 */
					DAL: {
						id: 941,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.DAL"
					},

					/**
					 * Delta markup
					 */
					DELTA_MARKUP: {
						id: 942,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.DELTA-MARKUP"
					},

					/**
					 * -5 .. +5
					 */
					DELTA_MARKUP_HELP: {
						id: 943,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.DELTA-MARKUP-HELP"
					},

					/**
					 * Luogo
					 */
					LUOGO: {
						id: 944,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.LUOGO"
					},

					/**
					 * Note
					 */
					NOTE: {
						id: 945,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.NOTE"
					},

					/**
					 * Nuova regola modifica markup
					 */
					NUOVA_REGOLA: {
						id: 946,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.NUOVA-REGOLA"
					},

					/**
					 * Ordine
					 */
					ORDINE: {
						id: 947,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.ORDINE"
					},

					/**
					 * Inserire un numero
					 */
					ORDINE_HELP: {
						id: 948,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.ORDINE-HELP"
					},

					/**
					 * Proporzionale
					 */
					PROPORZIONALE: {
						id: 949,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.PROPORZIONALE"
					},

					/**
					 * Ricerca al
					 */
					RICERCA_AL: {
						id: 950,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.RICERCA-AL"
					},

					/**
					 * Ricerca dal
					 */
					RICERCA_DAL: {
						id: 951,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.RICERCA-DAL"
					},

					/**
					 * Servizio
					 */
					SERVIZIO: {
						id: 952,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.SERVIZIO"
					},

					/**
					 * Tipo servizio
					 */
					TIPO_SERVIZIO: {
						id: 953,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.TIPO-SERVIZIO"
					},

					/**
					 * -- Tutti --
					 */
					TUTTI: {
						id: 954,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.EDITOR.TUTTI"
					},

				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.LISTA
				 */
				LISTA: {
					/**
					 * Accordo
					 */
					ACCORDO: {
						id: 955,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.LISTA.ACCORDO"
					},

					/**
					 * Delta %
					 */
					DELTA: {
						id: 956,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.LISTA.DELTA"
					},

					/**
					 * ID
					 */
					ID: {
						id: 957,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.LISTA.ID"
					},

					/**
					 * Luogo
					 */
					LUOGO: {
						id: 958,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.LISTA.LUOGO"
					},

					/**
					 * Non associato
					 */
					NON_ASSOCIATO: {
						id: 959,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.LISTA.NON-ASSOCIATO"
					},

					/**
					 * Ordine
					 */
					ORDINE: {
						id: 960,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.LISTA.ORDINE"
					},

					/**
					 * Servizio
					 */
					SERVIZIO: {
						id: 961,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.LISTA.SERVIZIO"
					},

					/**
					 * Tipo servizio
					 */
					TIPO_SERVIZIO: {
						id: 962,
						label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.LISTA.TIPO-SERVIZIO"
					},

				},

				/**
				 * Nuova regola
				 */
				NUOVA_REGOLA: {
					id: 963,
					label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.NUOVA-REGOLA"
				},

				/**
				 * Ricerca
				 */
				RICERCA: {
					id: 964,
					label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.RICERCA"
				},

				/**
				 * Margini serivzi
				 */
				TITOLO: {
					id: 965,
					label: "intranet.PROGRAMMAZIONE.MARGINI-SERVIZI.TITOLO"
				},

			},

			/**
			 * Content of path intranet.PROGRAMMAZIONE.SERVIZI
			 */
			SERVIZI: {
				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.ACCORDI
				 */
				ACCORDI: {
					/**
					 * L'accordo {nome} ID={id} è già presente nell'elenco!
					 */
					ACCORDO_GIA_PRESENTE: {
						id: 966,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ACCORDI.ACCORDO-GIA-PRESENTE"
					},

					/**
					 * Accordo scaduto
					 */
					ACCORDO_SCADUTO: {
						id: 967,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ACCORDI.ACCORDO-SCADUTO"
					},

					/**
					 * Aggiungi accordo
					 */
					AGGIUNGI_ACCCORDO: {
						id: 968,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ACCORDI.AGGIUNGI-ACCCORDO"
					},

					/**
					 * Id
					 */
					ID: {
						id: 969,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ACCORDI.ID"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 970,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ACCORDI.NOME"
					},

					/**
					 * Dati modificati. Ricordati di salvare!
					 */
					RICORDATI_DI_SALVARE: {
						id: 971,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ACCORDI.RICORDATI-DI-SALVARE"
					},

				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.ARTICOLI
				 */
				ARTICOLI: {
					/**
					 * Al
					 */
					AL: {
						id: 972,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ARTICOLI.AL"
					},

					/**
					 * Dal
					 */
					DAL: {
						id: 973,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ARTICOLI.DAL"
					},

					/**
					 * Giorno
					 */
					GIORNO: {
						id: 974,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ARTICOLI.GIORNO"
					},

					/**
					 * Modifica articolo
					 */
					MODIFICA_ARTICOLO: {
						id: 975,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ARTICOLI.MODIFICA-ARTICOLO"
					},

					/**
					 * Ordine
					 */
					ORDINE: {
						id: 976,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ARTICOLI.ORDINE"
					},

					/**
					 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.ARTICOLI.PUBBLICA
					 */
					PUBBLICA: {
						/**
						 * E/C
						 */
						ESTRATTO_CONTO: {
							id: 977,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ARTICOLI.PUBBLICA.ESTRATTO-CONTO"
						},

						/**
						 * Offerta
						 */
						OFFERTA: {
							id: 978,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ARTICOLI.PUBBLICA.OFFERTA"
						},

						/**
						 * Preventivo
						 */
						PREVENTIVO: {
							id: 979,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ARTICOLI.PUBBLICA.PREVENTIVO"
						},

						/**
						 * Programma
						 */
						PROGRAMMA: {
							id: 980,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ARTICOLI.PUBBLICA.PROGRAMMA"
						},

						/**
						 * Voucher
						 */
						VOUCHER: {
							id: 981,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ARTICOLI.PUBBLICA.VOUCHER"
						},

						/**
						 * Web
						 */
						WEB: {
							id: 982,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ARTICOLI.PUBBLICA.WEB"
						},

					},

				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI
				 */
				ASSOCIAZIONI: {
					/**
					 * Al
					 */
					AL: {
						id: 983,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.AL"
					},

					/**
					 * Associata a
					 */
					ASSOCIA_A: {
						id: 984,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.ASSOCIA-A"
					},

					/**
					 * Associa regola vendita aggiuntiva
					 */
					ASSOCIA_REGOLA_VENDITA_AGGIUNTIVA: {
						id: 985,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.ASSOCIA-REGOLA-VENDITA-AGGIUNTIVA"
					},

					/**
					 * Associa regola vendita per penali
					 */
					ASSOCIA_REGOLA_VENDITA_PENALE: {
						id: 986,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.ASSOCIA-REGOLA-VENDITA-PENALE"
					},

					/**
					 * Associa tipologia a supplemento
					 */
					ASSOCIA_TIPOLOGIA_A_SUPPLEMENTO: {
						id: 987,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.ASSOCIA-TIPOLOGIA-A-SUPPLEMENTO"
					},

					/**
					 * Associazione ID={id}
					 */
					ASSOCIAZIONE_ID: {
						id: 988,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.ASSOCIAZIONE-ID"
					},

					/**
					 * Eliminate {n} associazion{n, plural, =0{i} one{e} other{i}}
					 */
					ASSOCIAZIONI_ELIMINATE: {
						id: 989,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.ASSOCIAZIONI-ELIMINATE"
					},

					/**
					 * Bedding
					 */
					BEDDING: {
						id: 990,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.BEDDING"
					},

					/**
					 * Cod/Pri/Cum
					 */
					CODICE_PRIORITA_CUMULABILITA: {
						id: 991,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.CODICE-PRIORITA-CUMULABILITA"
					},

					/**
					 * Comm. ADV {n}%
					 */
					COMMISSIONI_ADV_PERC: {
						id: 992,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.COMMISSIONI-ADV-PERC"
					},

					/**
					 * Comm. TO {n}%
					 */
					COMMISSIONI_TO_PERC: {
						id: 993,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.COMMISSIONI-TO-PERC"
					},

					/**
					 * Confermi di voler eliminare l'associazione ID={id}?
					 */
					CONFERMA_ELIMINAZIONE: {
						id: 994,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.CONFERMA-ELIMINAZIONE"
					},

					/**
					 * Dal
					 */
					DAL: {
						id: 995,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.DAL"
					},

					/**
					 * Data non valida
					 */
					DATA_NON_VALIDA: {
						id: 996,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.DATA-NON-VALIDA"
					},

					/**
					 * Confermi di voler eliminare {n} associazion{n, plural, =0{i} one{e} other{i}}?
					 */
					ELIMINA_MULTI_CONFERMA: {
						id: 997,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.ELIMINA-MULTI-CONFERMA"
					},

					/**
					 * Esclusioni
					 */
					ESCLUSIONI: {
						id: 998,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.ESCLUSIONI"
					},

					/**
					 * Età max {n}
					 */
					ETA_MAX: {
						id: 999,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.ETA-MAX"
					},

					/**
					 * Età min {n}
					 */
					ETA_MIN: {
						id: 1000,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.ETA-MIN"
					},

					/**
					 * Generiche
					 */
					GENERICHE: {
						id: 1001,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.GENERICHE"
					},

					/**
					 * Gruppi di tipologie
					 */
					GRUPPI_DI_TIPOLOGIE: {
						id: 1002,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.GRUPPI-DI-TIPOLOGIE"
					},

					/**
					 * Grp.tipologie
					 */
					GRUPPO_TIPOLOGIE: {
						id: 1003,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.GRUPPO-TIPOLOGIE"
					},

					/**
					 * ID
					 */
					ID: {
						id: 1004,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.ID"
					},

					/**
					 * Min. Nt. Per. {n}
					 */
					MINIMO_NOTTI_PERIODO: {
						id: 1005,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.MINIMO-NOTTI-PERIODO"
					},

					/**
					 * Nuova associazione
					 */
					NUOVA_ASSOCIAZIONE: {
						id: 1006,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.NUOVA-ASSOCIAZIONE"
					},

					/**
					 * Offerta
					 */
					OFFERTA: {
						id: 1007,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA"
					},

					/**
					 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE
					 */
					OFFERTA_COMPONIBILE: {
						/**
						 * Validi: da 0 a 100
						 */
						_0_100: {
							id: 1008,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.0-100"
						},

						/**
						 * in abbinamento
						 */
						ABBINAMENTO_SETTIMANA: {
							id: 1009,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ABBINAMENTO-SETTIMANA"
						},

						/**
						 * Accetta multipli interi del periodo
						 */
						ACCETTA_MULTIPLI: {
							id: 1010,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ACCETTA-MULTIPLI"
						},

						/**
						 * Aggiungi vincolo
						 */
						AGGIUNGI_VINCOLO: {
							id: 1011,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.AGGIUNGI-VINCOLO"
						},

						/**
						 * Operazione
						 */
						ALGORITMO: {
							id: 1012,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ALGORITMO"
						},

						/**
						 * Scegliere un'operazione
						 */
						ALGORITMO_HELP: {
							id: 1013,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ALGORITMO-HELP"
						},

						/**
						 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ALGORNTIMI
						 */
						ALGORNTIMI: {
							/**
							 * Giorni gratis
							 */
							G: {
								id: 1014,
								label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ALGORNTIMI.G"
							},

							/**
							 * Sconto in percentuale
							 */
							SP: {
								id: 1015,
								label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ALGORNTIMI.SP"
							},

							/**
							 * Sconto in valore
							 */
							SV: {
								id: 1016,
								label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ALGORNTIMI.SV"
							},

						},

						/**
						 * Nel gruppo applica a
						 */
						APPLICA_A_GRUPPO: {
							id: 1017,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.APPLICA-A-GRUPPO"
						},

						/**
						 * Applica al numero massimo di pax
						 */
						APPLICA_A_MAX_PAX: {
							id: 1018,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.APPLICA-A-MAX-PAX"
						},

						/**
						 * Applica a partire da
						 */
						APPLICA_A_PARTIRE_DA: {
							id: 1019,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.APPLICA-A-PARTIRE-DA"
						},

						/**
						 * Settimana abbinata
						 */
						APPLICA_A_SETTIMANA: {
							id: 1020,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.APPLICA-A-SETTIMANA"
						},

						/**
						 * Se c'è un minimo notti/giorni applica sui multipli interi del periodo
						 */
						APPLICA_MULTIPLI_PERIODO: {
							id: 1021,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.APPLICA-MULTIPLI-PERIODO"
						},

						/**
						 * Se l'opzione è attiva e c'è p.es. un minimo 7 giorni con un soggiorno di 14 lo sconto sarà doppio. Altrimenti verrà applicato una sola volta per tutto il soggiorno. Utilizzabile sono su prima/ultima notte e giorno più/meno costoso.
						 */
						APPLICA_MULTIPLI_PERIODO_GIORNI_HELP: {
							id: 1022,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.APPLICA-MULTIPLI-PERIODO-GIORNI-HELP"
						},

						/**
						 * Se l'opzione è attiva e c'è p.es. un minimo 7 giorni con un soggiorno di 14 lo sconto sarà doppio. Altrimenti verrà applicato una sola volta per tutto il soggiorno.
						 */
						APPLICA_MULTIPLI_PERIODO_HELP: {
							id: 1023,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.APPLICA-MULTIPLI-PERIODO-HELP"
						},

						/**
						 * Applica solo alle quote bambino
						 */
						APPLICA_SOLO_QUOTE_BAMBINO: {
							id: 1024,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.APPLICA-SOLO-QUOTE-BAMBINO"
						},

						/**
						 * Applica sulla quota della mezza doppia
						 */
						APPLICA_SU_MEZZA_DOPPIA: {
							id: 1025,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.APPLICA-SU-MEZZA-DOPPIA"
						},

						/**
						 * Se l'opzione è attiva il prezzo verrà calcolato sulla metà dell'importo della camera doppia a parità di periodo / trattamento / bedding / tabella costo / regole
						 */
						APPLICA_SU_MEZZA_DOPPIA_HELP: {
							id: 1026,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.APPLICA-SU-MEZZA-DOPPIA-HELP"
						},

						/**
						 * Associazione per offerta / azione
						 */
						ASSOCIAZIONE_OFFERTA: {
							id: 1027,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ASSOCIAZIONE-OFFERTA"
						},

						/**
						 * Associazione regola vendita per penale
						 */
						ASSOCIAZIONE_PENALE: {
							id: 1028,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ASSOCIAZIONE-PENALE"
						},

						/**
						 * Associazione regola vendita aggiuntiva
						 */
						ASSOCIAZIONE_REGOLA: {
							id: 1029,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ASSOCIAZIONE-REGOLA"
						},

						/**
						 * Associazione supplemento
						 */
						ASSOCIAZIONE_SUPPLEMENTO: {
							id: 1030,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ASSOCIAZIONE-SUPPLEMENTO"
						},

						/**
						 * Azione
						 */
						AZIONE: {
							id: 1031,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.AZIONE"
						},

						/**
						 * Check-in
						 */
						CHECK_IN: {
							id: 1032,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.CHECK-IN"
						},

						/**
						 * Il codice è obbligatorio: lettera maiuscola, tranne 'T'
						 */
						CODCIE_OBBLIGATORIO: {
							id: 1033,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.CODCIE-OBBLIGATORIO"
						},

						/**
						 * Codice
						 */
						CODICE: {
							id: 1034,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.CODICE"
						},

						/**
						 * Gruppo
						 */
						COMPOSIZIONE_GRUPPO: {
							id: 1035,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.COMPOSIZIONE-GRUPPO"
						},

						/**
						 * con età compresa tra {eta1} e {eta2} anni
						 */
						CON_ETA_TRA_E: {
							id: 1036,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.CON-ETA-TRA-E"
						},

						/**
						 * con meno di
						 */
						CON_MENO_DI: {
							id: 1037,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.CON-MENO-DI"
						},

						/**
						 * con più di
						 */
						CON_PIU_DI: {
							id: 1038,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.CON-PIU-DI"
						},

						/**
						 * Converti in
						 */
						CONVERTI: {
							id: 1039,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.CONVERTI"
						},

						/**
						 * Cumulabilità
						 */
						CUMULABILITA: {
							id: 1040,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.CUMULABILITA"
						},

						/**
						 * Data non valida
						 */
						DATA_NON_VALIDA: {
							id: 1041,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.DATA-NON-VALIDA"
						},

						/**
						 * Data prenotazione
						 */
						DATA_PRENOTAZIONE: {
							id: 1042,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.DATA-PRENOTAZIONE"
						},

						/**
						 * Disabilitata
						 */
						DISABILITATA: {
							id: 1043,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.DISABILITATA"
						},

						/**
						 * Durata
						 */
						DURATA: {
							id: 1044,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.DURATA"
						},

						/**
						 * Età infant
						 */
						ETA_1: {
							id: 1045,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ETA-1"
						},

						/**
						 * Età adulto
						 */
						ETA_2: {
							id: 1046,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ETA-2"
						},

						/**
						 * Giorni
						 */
						GIORNI: {
							id: 1047,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.GIORNI"
						},

						/**
						 * Attivare questa opzione per conteggiare anche quei giorni di soggiorno che ricadono nei periodi esclusi. Se l'opzione non è selezionata un soggiorno di 14 giorni di cui 7 ricadano in un periodo escluso verrà considerato come se fosse di 7.
						 */
						IGNORA_PERIODI_ESCLUSI_HELP: {
							id: 1048,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.IGNORA-PERIODI-ESCLUSI-HELP"
						},

						/**
						 * Il soggiorno deve essere
						 */
						IL_SOGGIORNO_DEVE_ESSERE: {
							id: 1049,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.IL-SOGGIORNO-DEVE-ESSERE"
						},

						/**
						 * Ignora periodi esclusi
						 */
						INGORA_PERIODI_ESCLUSI: {
							id: 1050,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.INGORA-PERIODI-ESCLUSI"
						},

						/**
						 * Label
						 */
						LABEL: {
							id: 1051,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.LABEL"
						},

						/**
						 * La label descrittiva è obbligatoria
						 */
						LABEL_OBBLIGATORIA: {
							id: 1052,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.LABEL-OBBLIGATORIA"
						},

						/**
						 * Lasciare a 0 per calcolarlo in base allo sconto applicato al costo
						 */
						LASCIARE_0_PER_AUTO: {
							id: 1053,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.LASCIARE-0-PER-AUTO"
						},

						/**
						 * Limita a max
						 */
						LIMITA_A_MAX: {
							id: 1054,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.LIMITA-A-MAX"
						},

						/**
						 * Moltiplica la quota scontata della mezza doppia per il numero di pax
						 */
						MOLIPLICA_MEZZA_DOPPIA_PER_PAX: {
							id: 1055,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.MOLIPLICA-MEZZA-DOPPIA-PER-PAX"
						},

						/**
						 * Note interne
						 */
						NOTE_INTERNE: {
							id: 1056,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.NOTE-INTERNE"
						},

						/**
						 * Impostare un ordine numerico
						 */
						ODINE_OBBLIGATORIO: {
							id: 1057,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ODINE-OBBLIGATORIO"
						},

						/**
						 * Ordine
						 */
						ORDINE: {
							id: 1058,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.ORDINE"
						},

						/**
						 * pax
						 */
						PASSEGGERI_ABBREVIATO: {
							id: 1059,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.PASSEGGERI-ABBREVIATO"
						},

						/**
						 * pax
						 */
						PAX: {
							id: 1060,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.PAX"
						},

						/**
						 * Per giorno
						 */
						PER_GIORNO: {
							id: 1061,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.PER-GIORNO"
						},

						/**
						 * Per persona
						 */
						PER_PERSONA: {
							id: 1062,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.PER-PERSONA"
						},

						/**
						 * Percentuale
						 */
						PERCENTUALE: {
							id: 1063,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.PERCENTUALE"
						},

						/**
						 * Periodi esclusi
						 */
						PERIODI_ESCLUSI: {
							id: 1064,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.PERIODI-ESCLUSI"
						},

						/**
						 * Controlla il formato dei periodi esclusi
						 */
						PERIODI_ESCLUSI_ERRORE: {
							id: 1065,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.PERIODI-ESCLUSI-ERRORE"
						},

						/**
						 * gg/mm[/aaaa]-gg/mm[/aaaa][,altro periodo, ...]
						 */
						PERIODI_ESCLUSI_HELP: {
							id: 1066,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.PERIODI-ESCLUSI-HELP"
						},

						/**
						 * Sulle quote bambino applica in proporzione alla quota adulto
						 */
						QUOTA_BAMBINI_PROPORZIONALE: {
							id: 1067,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.QUOTA-BAMBINI-PROPORZIONALE"
						},

						/**
						 * Se l'opzione non è attiva il bambino viene scontato come l'adulto. Se l'opzione è attiva e p.es. il bambino ha una quota scontata al 50% lo sconto dovuto all'offerta verrà dimezzato.
						 */
						QUOTA_BAMBINI_PROPORZIONALE_HELP: {
							id: 1068,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.QUOTA-BAMBINI-PROPORZIONALE-HELP"
						},

						/**
						 * quote
						 */
						QUOTE: {
							id: 1069,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.QUOTE"
						},

						/**
						 * Quote bambino
						 */
						QUOTE_BAMBINO: {
							id: 1070,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.QUOTE-BAMBINO"
						},

						/**
						 * Se l'opzione è attiva tutte quote a cui applicare lo sconto verranno ricostruite in base alle tariffe della camera doppia
						 */
						RICOSTRUISCI_SU_MEZZA_DOPPIA_HELP: {
							id: 1071,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.RICOSTRUISCI-SU-MEZZA-DOPPIA-HELP"
						},

						/**
						 * Sconta
						 */
						SCONTA: {
							id: 1072,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.SCONTA"
						},

						/**
						 * del
						 */
						SCONTA_DEL_X_PERCENTO: {
							id: 1073,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.SCONTA-DEL-X-PERCENTO"
						},

						/**
						 * Sconto sul costo
						 */
						SCONTO_COSTO: {
							id: 1074,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.SCONTO-COSTO"
						},

						/**
						 * Sconto sul prezzo
						 */
						SCONTO_PREZZO: {
							id: 1075,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.SCONTO-PREZZO"
						},

						/**
						 * Settimana
						 */
						SETTIMANA: {
							id: 1076,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.SETTIMANA"
						},

						/**
						 * Valore non valido
						 */
						VALORE_NON_VALIDO: {
							id: 1077,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.VALORE-NON-VALIDO"
						},

						/**
						 * Valori validi: da 0 a 255
						 */
						VALORI_VALIDI_0_255: {
							id: 1078,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.VALORI-VALIDI-0-255"
						},

						/**
						 * Valori validi: da 1 a 255
						 */
						VALORI_VALIDI_1_255: {
							id: 1079,
							label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.VALORI-VALIDI-1-255"
						},

						/**
						 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.VINCOLI
						 */
						VINCOLI: {
							/**
							 * Abbinamento settimana
							 */
							AS: {
								id: 1080,
								label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.VINCOLI.AS"
							},

							/**
							 * Composizione gruppo
							 */
							CG: {
								id: 1081,
								label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.VINCOLI.CG"
							},

							/**
							 * Data checkin
							 */
							DP: {
								id: 1082,
								label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.VINCOLI.DP"
							},

							/**
							 * Durata soggiorno
							 */
							DS: {
								id: 1083,
								label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.VINCOLI.DS"
							},

							/**
							 * Giorni entrata/uscita
							 */
							EU: {
								id: 1084,
								label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.VINCOLI.EU"
							},

							/**
							 * Data prenotazione
							 */
							PP: {
								id: 1085,
								label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.VINCOLI.PP"
							},

							/**
							 * Presenza quote bambino
							 */
							QB: {
								id: 1086,
								label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTA-COMPONIBILE.VINCOLI.QB"
							},

						},

					},

					/**
					 * Offerte / Azioni
					 */
					OFFERTE_AZIONI: {
						id: 1087,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.OFFERTE-AZIONI"
					},

					/**
					 * Prenota entro il {d}
					 */
					PRENOTA_ENTRO_DATA: {
						id: 1088,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.PRENOTA-ENTRO-DATA"
					},

					/**
					 * Prenota entro {n} gg
					 */
					PRENOTA_ENTRO_GIORNI: {
						id: 1089,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.PRENOTA-ENTRO-GIORNI"
					},

					/**
					 * Regola vendita
					 */
					REGOLA_VENDITA: {
						id: 1090,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.REGOLA-VENDITA"
					},

					/**
					 * Regole vendita aggiuntive
					 */
					REGOLE_VENDITA_AGGIUNTIVE: {
						id: 1091,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.REGOLE-VENDITA-AGGIUNTIVE"
					},

					/**
					 * Regole vendita penali
					 */
					REGOLE_VENDITA_PENALI: {
						id: 1092,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.REGOLE-VENDITA-PENALI"
					},

					/**
					 * -- Selezionare un supplemento --
					 */
					SELEZIONARE_UN_SUPPLEMEMNTO: {
						id: 1093,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.SELEZIONARE-UN-SUPPLEMEMNTO"
					},

					/**
					 * Specificare una regola vendita
					 */
					SPECIFICARE_UNA_REGOLA_VENDITA: {
						id: 1094,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.SPECIFICARE-UNA-REGOLA-VENDITA"
					},

					/**
					 * Supplementi
					 */
					SUPPLEMENTI: {
						id: 1095,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.SUPPLEMENTI"
					},

					/**
					 * Supplemento
					 */
					SUPPLEMENTO: {
						id: 1096,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.SUPPLEMENTO"
					},

					/**
					 * Specificare un supplemento da associare
					 */
					SUPPLEMENTO_NECESSARIO: {
						id: 1097,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.SUPPLEMENTO-NECESSARIO"
					},

					/**
					 * Tabella costi/commissioni
					 */
					TABELLA_COSTI_COMMISSIONI: {
						id: 1098,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.TABELLA-COSTI-COMMISSIONI"
					},

					/**
					 * Tipo offerta/azione
					 */
					TIPO_OFFERTA_AZIONE: {
						id: 1099,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.TIPO-OFFERTA-AZIONE"
					},

					/**
					 * Tipo tipologia
					 */
					TIPO_TIPOLOGIA: {
						id: 1100,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.TIPO-TIPOLOGIA"
					},

					/**
					 * Tipologia
					 */
					TIPOLOGIA: {
						id: 1101,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.TIPOLOGIA"
					},

					/**
					 * Tipologia o categoria
					 */
					TIPOLOGIA_O_CATEGORIA: {
						id: 1102,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.TIPOLOGIA-O-CATEGORIA"
					},

					/**
					 * Tipologia specifica
					 */
					TIPOLOGIA_SPECIFICA: {
						id: 1103,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.TIPOLOGIA-SPECIFICA"
					},

					/**
					 * Trattamento
					 */
					TRATTAMENTO: {
						id: 1104,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.TRATTAMENTO"
					},

					/**
					 * Tutte le regole
					 */
					TUTTE_LE_REGOLE_VENDITA: {
						id: 1105,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.TUTTE-LE-REGOLE-VENDITA"
					},

					/**
					 * Tutte le tabelle
					 */
					TUTTE_LE_TABELLE_COSTI_COMMISSIONI: {
						id: 1106,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.TUTTE-LE-TABELLE-COSTI-COMMISSIONI"
					},

					/**
					 * Tutte le tipologie
					 */
					TUTTE_LE_TIPOLOGIE: {
						id: 1107,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.TUTTE-LE-TIPOLOGIE"
					},

					/**
					 * Tutti i bedding
					 */
					TUTTI_I_BEDDING: {
						id: 1108,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.TUTTI-I-BEDDING"
					},

					/**
					 * Tutti i gruppi
					 */
					TUTTI_I_GRUPPI_DI_TIPOLOGIE: {
						id: 1109,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.TUTTI-I-GRUPPI-DI-TIPOLOGIE"
					},

					/**
					 * Tutti i supplementi
					 */
					TUTTI_I_SUPPLEMENTI: {
						id: 1110,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.TUTTI-I-SUPPLEMENTI"
					},

					/**
					 * Tutti i tipi
					 */
					TUTTI_I_TIPI_DI_TIPOLOGIA: {
						id: 1111,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.TUTTI-I-TIPI-DI-TIPOLOGIA"
					},

					/**
					 * Tutti i trattamenti
					 */
					TUTTI_I_TRATTAMENTI: {
						id: 1112,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.TUTTI-I-TRATTAMENTI"
					},

					/**
					 * Sempre
					 */
					VALIDA_SEMPRE: {
						id: 1113,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.VALIDA-SEMPRE"
					},

					/**
					 * Validità
					 */
					VALIDITA: {
						id: 1114,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.VALIDITA"
					},

					/**
					 * Vincoli
					 */
					VINCOLI: {
						id: 1115,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ASSOCIAZIONI.VINCOLI"
					},

				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.CLONE
				 */
				CLONE: {
					/**
					 * Accordi
					 */
					ACCORDI: {
						id: 1116,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.ACCORDI"
					},

					/**
					 * Associazioni
					 */
					ASSOCIAZIONI: {
						id: 1117,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.ASSOCIAZIONI"
					},

					/**
					 * Dati aggiuntivi
					 */
					ATTRIBUTI: {
						id: 1118,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.ATTRIBUTI"
					},

					/**
					 * Costi
					 */
					COSTI: {
						id: 1119,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.COSTI"
					},

					/**
					 * Duplica servizio
					 */
					DUPLICA_SERVIZIO: {
						id: 1120,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.DUPLICA SERVIZIO"
					},

					/**
					 * Confermi di voler duplicare il servizio ID={id} {nome}?
					 */
					DUPLICA_CONFERMA: {
						id: 1121,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.DUPLICA-CONFERMA"
					},

					/**
					 * Conferma duplicazione
					 */
					DUPLICA_CONFERMA_TITOLO: {
						id: 1122,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.DUPLICA-CONFERMA-TITOLO"
					},

					/**
					 * Elementi da duplicare
					 */
					ELEMENTI_DA_DUPLICARE: {
						id: 1123,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.ELEMENTI-DA-DUPLICARE"
					},

					/**
					 * Immagini
					 */
					IMMAGINI: {
						id: 1124,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.IMMAGINI"
					},

					/**
					 * Itinerari
					 */
					ITINERARI: {
						id: 1125,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.ITINERARI"
					},

					/**
					 * Mutiluogo
					 */
					MULTI_LUOGO: {
						id: 1126,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.MULTI-LUOGO"
					},

					/**
					 * Partenze
					 */
					PARTENZE: {
						id: 1127,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.PARTENZE"
					},

					/**
					 * Regole servizio
					 */
					REGOLE_SERVIZIO: {
						id: 1128,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.REGOLE-SERVIZIO"
					},

					/**
					 * Regole vendita
					 */
					REGOLE_VENDITA: {
						id: 1129,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.REGOLE-VENDITA"
					},

					/**
					 * Servizio duplicato
					 */
					SERVIZIO_DUPLICATO: {
						id: 1130,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.SERVIZIO-DUPLICATO"
					},

					/**
					 * Tipologie
					 */
					TIPOLOGIE: {
						id: 1131,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.TIPOLOGIE"
					},

					/**
					 * Validità
					 */
					VALIDITA: {
						id: 1132,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.CLONE.VALIDITA"
					},

				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.COSTI
				 */
				COSTI: {
					/**
					 * Accordo scaduto
					 */
					ACCORDO_SCADUTO: {
						id: 1133,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.ACCORDO-SCADUTO"
					},

					/**
					 * Cambia
					 */
					AGGIONRA_MARGINE: {
						id: 1134,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.AGGIONRA-MARGINE"
					},

					/**
					 * Al
					 */
					AL: {
						id: 1135,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.AL"
					},

					/**
					 * Anni
					 */
					ANNI: {
						id: 1136,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.ANNI"
					},

					/**
					 * -- Associa a nessuno --
					 */
					ASSOCIA_A_NESSUNO: {
						id: 1137,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.ASSOCIA-A-NESSUNO"
					},

					/**
					 * Cambia associazioni
					 */
					CAMBIA_ASSOCIAZIONI: {
						id: 1138,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.CAMBIA-ASSOCIAZIONI"
					},

					/**
					 * Cambia associazioni per {n} cost{n, plural, =0{i} one{o} other{i}}
					 */
					CAMBIA_ASSOCIAZIONI_PER_N_COSTI: {
						id: 1139,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.CAMBIA-ASSOCIAZIONI-PER-N-COSTI"
					},

					/**
					 * Cambia valore
					 */
					CAMBIA_VALORE: {
						id: 1140,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.CAMBIA-VALORE"
					},

					/**
					 * Cod. prenotazione
					 */
					CODICE_PRENOTAZIONE: {
						id: 1141,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.CODICE-PRENOTAZIONE"
					},

					/**
					 * Cod.Pren.
					 */
					CODICE_PRENOTAZIONE_ABBR: {
						id: 1142,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.CODICE-PRENOTAZIONE-ABBR"
					},

					/**
					 * Confermi di voler cambiare i dati di {n} cost{n, plural, =0{i} one{o} other{i}}?
					 */
					CONFERMA_AGGIORNAMENTO_N_DATI: {
						id: 1143,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.CONFERMA-AGGIORNAMENTO-N-DATI"
					},

					/**
					 * Confermi di voler correggere {n} duplicat{n, plural, =0{i} one{o} other{i}} di costo? I doppioni indicati in rosso verranno eliminati, il costo inserito nella casella verrà salvato.
					 */
					CONFERMA_CORREZIONE_N_DUPLICATI: {
						id: 1144,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.CONFERMA-CORREZIONE-N-DUPLICATI"
					},

					/**
					 * Sei sicuro di voler eliminare {n} cost{n, plural, =0{i} one{o} other{i}} selezionat{n, plural, =0{i} one{o} other{i}}?
					 */
					CONFERMA_ELIMINAZIONE_COSTI: {
						id: 1145,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.CONFERMA-ELIMINAZIONE-COSTI"
					},

					/**
					 * Correggi {n} duplicat{n, plural, =0{i} one{o} other{i}}
					 */
					CORREGGI_N_DUPLICATI: {
						id: 1146,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.CORREGGI-N-DUPLICATI"
					},

					/**
					 * Costi
					 */
					COSTI: {
						id: 1147,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.COSTI"
					},

					/**
					 * {n} cost{n, plural, =0{i} one{o} other{i}} aggiornat{n, plural, =0{i} one{o} other{i}}
					 */
					COSTI_AGGIORNATI: {
						id: 1148,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.COSTI-AGGIORNATI"
					},

					/**
					 * Attenzione: sono presenti {n} duplicat{n, plural, =0{i} one{o} other{i}} di costo
					 */
					COSTI_DUPLICATI: {
						id: 1149,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.COSTI-DUPLICATI"
					},

					/**
					 * {n} cost{n, plural, =0{i} one{o} other{i}} duplicat{n, plural, =0{i} one{o} other{i}}
					 */
					COSTI_DUPLICATI_LABEL: {
						id: 1150,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.COSTI-DUPLICATI-LABEL"
					},

					/**
					 * {n} cost{n, plural, =0{i} one{o} other{i}} eliminat{n, plural, =0{i} one{o} other{i}}
					 */
					COSTI_ELIMINATI: {
						id: 1151,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.COSTI-ELIMINATI"
					},

					/**
					 * Costi inseriti
					 */
					COSTI_INSERITI: {
						id: 1152,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.COSTI-INSERITI"
					},

					/**
					 * Dal
					 */
					DAL: {
						id: 1153,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.DAL"
					},

					/**
					 * Età child
					 */
					ETA_CHILD: {
						id: 1154,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.ETA-CHILD"
					},

					/**
					 * Inserimento
					 */
					INSERIMENTO: {
						id: 1155,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.INSERIMENTO"
					},

					/**
					 * Inserire almeno un costo
					 */
					INSERIRE_ALMENO_UN_COSTO: {
						id: 1156,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.INSERIRE-ALMENO-UN-COSTO"
					},

					/**
					 * Inserire un numero
					 */
					INSERIRE_UN_NUMERO: {
						id: 1157,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.INSERIRE-UN-NUMERO"
					},

					/**
					 * Inserisci costi
					 */
					INSERISCI_COSTI: {
						id: 1158,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.INSERISCI-COSTI"
					},

					/**
					 * Margine
					 */
					MARGINE: {
						id: 1159,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.MARGINE"
					},

					/**
					 * Max {eta} ann{eta, plural, =0{i} one{o} other{i}}
					 */
					MAX_ANNI: {
						id: 1160,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.MAX-ANNI"
					},

					/**
					 * {n} duplicat{n, plural, =0{i} one{o} other{i}}
					 */
					N_DUPLICATI: {
						id: 1161,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.N-DUPLICATI"
					},

					/**
					 * {n} cost{n, plural, =0{i} one{o} other{i}} selezionat{n, plural, =0{i} one{o} other{i}}
					 */
					N_SELEZIONATI: {
						id: 1162,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.N-SELEZIONATI"
					},

					/**
					 * -- Nessuno --
					 */
					NESSUN_TRATTAMENTO: {
						id: 1163,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.NESSUN-TRATTAMENTO"
					},

					/**
					 * -- Nessuna --
					 */
					NESSUNA_REGOLA_SERVIZIO: {
						id: 1164,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.NESSUNA-REGOLA-SERVIZIO"
					},

					/**
					 * -- Nessuna --
					 */
					NESSUNA_REGOLA_VENDITA: {
						id: 1165,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.NESSUNA-REGOLA-VENDITA"
					},

					/**
					 * Niente
					 */
					NIENTE: {
						id: 1166,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.NIENTE"
					},

					/**
					 * -- Non cambiare --
					 */
					NON_CAMBIARE: {
						id: 1167,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.NON-CAMBIARE"
					},

					/**
					 * per pax
					 */
					PER_PAX: {
						id: 1168,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.PER-PAX"
					},

					/**
					 * Periodo
					 */
					PERIODO: {
						id: 1169,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.PERIODO"
					},

					/**
					 * Predisponi associazione
					 */
					PREDISPONI_ASSOCIAZIONE: {
						id: 1170,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.PREDISPONI-ASSOCIAZIONE"
					},

					/**
					 * Predisponi inserimento
					 */
					PREDISPONI_INSERIMENTO: {
						id: 1171,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.PREDISPONI-INSERIMENTO"
					},

					/**
					 * Prezzi
					 */
					PREZZI: {
						id: 1172,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.PREZZI"
					},

					/**
					 * Prezzi indicativi
					 */
					PREZZI_INDICATIVI: {
						id: 1173,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.PREZZI-INDICATIVI"
					},

					/**
					 * Prezzi ricalcolati
					 */
					PREZZI_RICALCOLATI: {
						id: 1174,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.PREZZI-RICALCOLATI"
					},

					/**
					 * Regola costi bambino
					 */
					REGOLA_COSTI_BAMBINO: {
						id: 1175,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.REGOLA-COSTI-BAMBINO"
					},

					/**
					 * Regola inserimento
					 */
					REGOLA_INSERIMENTO: {
						id: 1176,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.REGOLA-INSERIMENTO"
					},

					/**
					 * Regola servizio
					 */
					REGOLA_SERVIZIO: {
						id: 1177,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.REGOLA-SERVIZIO"
					},

					/**
					 * Regola vendita
					 */
					REGOLA_VENDITA: {
						id: 1178,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.REGOLA-VENDITA"
					},

					/**
					 * Ricalcola
					 */
					RICALCOLA_PREZZI: {
						id: 1179,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.RICALCOLA-PREZZI"
					},

					/**
					 * riduzione su doppia
					 */
					RIDUZIONE_SU_DOPPIA: {
						id: 1180,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.RIDUZIONE-SU-DOPPIA"
					},

					/**
					 * Sconto %
					 */
					SCONTO_CHILD_PERCENTUALE: {
						id: 1181,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.SCONTO-CHILD-PERCENTUALE"
					},

					/**
					 * Seleziona
					 */
					SELEZIONA: {
						id: 1182,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.SELEZIONA"
					},

					/**
					 * Verranno selezionate solo le validità che ricadono interamente nel periodo scelto
					 */
					SELEZIONA_HELP: {
						id: 1183,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.SELEZIONA-HELP"
					},

					/**
					 * Seleziona un periodo
					 */
					SELEZIONA_UN_PERIODO: {
						id: 1184,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.SELEZIONA-UN-PERIODO"
					},

					/**
					 * Selezionare almeno una delle due date dal / al
					 */
					SELEZIONARE_ALMENO_UNA_DATA: {
						id: 1185,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.SELEZIONARE-ALMENO-UNA-DATA"
					},

					/**
					 * Selezionare almeno una tabella costi commissioni
					 */
					SELEZIONARE_ALMENO_UNA_TABELLA: {
						id: 1186,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.SELEZIONARE-ALMENO-UNA-TABELLA"
					},

					/**
					 * Selezionare almeno una tipologia
					 */
					SELEZIONARE_ALMENO_UNA_TIPOLOGIA: {
						id: 1187,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.SELEZIONARE-ALMENO-UNA-TIPOLOGIA"
					},

					/**
					 * Selezionare almeno una validità
					 */
					SELEZIONARE_ALMENO_UNA_VALIDITA: {
						id: 1188,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.SELEZIONARE-ALMENO-UNA-VALIDITA"
					},

					/**
					 * Selezionare un trattamento
					 */
					SELEZIONARE_UN_TRATTAMENTO: {
						id: 1189,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.SELEZIONARE-UN-TRATTAMENTO"
					},

					/**
					 * Selezionare una tipologia
					 */
					SELEZIONARE_UNA_TIPOLOGIA: {
						id: 1190,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.SELEZIONARE-UNA-TIPOLOGIA"
					},

					/**
					 * supplemento su doppia
					 */
					SUPPLEMENTO_SU_DOPPIA: {
						id: 1191,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.SUPPLEMENTO-SU-DOPPIA"
					},

					/**
					 * Tabella costi commissioni ID={id}
					 */
					TABELLA_ID: {
						id: 1192,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.TABELLA-ID"
					},

					/**
					 * Tabelle costi commissioni
					 */
					TABELLE_COSTI_COMMISSIONI: {
						id: 1193,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.TABELLE-COSTI-COMMISSIONI"
					},

					/**
					 * Hai selezionato tabelle costo con value diverse tra loro: {valute}
					 */
					TABELLE_COSTI_VALUTE_MULTIPLE: {
						id: 1194,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.TABELLE-COSTI-VALUTE-MULTIPLE"
					},

					/**
					 * Tipologia base
					 */
					TIPOLOGIA_BASE: {
						id: 1195,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.TIPOLOGIA-BASE"
					},

					/**
					 * Tipologia ID={id}
					 */
					TIPOLOGIA_ID: {
						id: 1196,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.TIPOLOGIA-ID"
					},

					/**
					 * Tipologie
					 */
					TIPOLOGIE: {
						id: 1197,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.TIPOLOGIE"
					},

					/**
					 * Trattamento
					 */
					TRATTAMENTO: {
						id: 1198,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.TRATTAMENTO"
					},

					/**
					 * Trattamento base
					 */
					TRATTAMENTO_BASE: {
						id: 1199,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.TRATTAMENTO-BASE"
					},

					/**
					 * Tutti i bambini
					 */
					TUTTI_I_BAMBINI: {
						id: 1200,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.TUTTI-I-BAMBINI"
					},

					/**
					 * tutti
					 */
					TUTTI_I_GIORNI: {
						id: 1201,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.TUTTI-I-GIORNI"
					},

					/**
					 * Tutto
					 */
					TUTTO: {
						id: 1202,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.TUTTO"
					},

					/**
					 * Validità
					 */
					VALIDITA: {
						id: 1203,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.VALIDITA"
					},

					/**
					 * Verifica partenze
					 */
					VERIFICA_PARTENZE: {
						id: 1204,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.VERIFICA-PARTENZE"
					},

					/**
					 * Visualizza
					 */
					VISUALIZZA: {
						id: 1205,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.COSTI.VISUALIZZA"
					},

				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.DATI-AGGIUNTIVI
				 */
				DATI_AGGIUNTIVI: {
					/**
					 * Al
					 */
					AL: {
						id: 1206,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.DATI-AGGIUNTIVI.AL"
					},

					/**
					 * Dal
					 */
					DAL: {
						id: 1207,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.DATI-AGGIUNTIVI.DAL"
					},

					/**
					 * Dati modificati, ricordati di salvare
					 */
					DATI_MODIFICATI_RICORDATI_DI_SALVARE: {
						id: 1208,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.DATI-AGGIUNTIVI.DATI-MODIFICATI-RICORDATI-DI-SALVARE"
					},

					/**
					 * -- Valore non impostato --
					 */
					VALORE_NON_IMPOSTATO: {
						id: 1209,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.DATI-AGGIUNTIVI.VALORE-NON-IMPOSTATO"
					},

				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.EDITOR
				 */
				EDITOR: {
					/**
					 * Accordi
					 */
					ACCORDI: {
						id: 1210,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.ACCORDI"
					},

					/**
					 * Articoli
					 */
					ARTICOLI: {
						id: 1211,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.ARTICOLI"
					},

					/**
					 * Associazioni
					 */
					ASSOCIAZIONI: {
						id: 1212,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.ASSOCIAZIONI"
					},

					/**
					 * Codice
					 */
					CODICE: {
						id: 1213,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.CODICE"
					},

					/**
					 * Codice Giata
					 */
					CODICE_GIATA: {
						id: 1214,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.CODICE-GIATA"
					},

					/**
					 * Consigliato
					 */
					CONSIGLIATO: {
						id: 1215,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.CONSIGLIATO"
					},

					/**
					 * Costi
					 */
					COSTI: {
						id: 1216,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.COSTI"
					},

					/**
					 * Dati
					 */
					DATI: {
						id: 1217,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.DATI"
					},

					/**
					 * Dati aggiuntivi
					 */
					DATI_AGGIUNTIVI: {
						id: 1218,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.DATI-AGGIUNTIVI"
					},

					/**
					 * Eliminare il servizio {svc} ID={id}?
					 */
					ELIMINA_CONFERMA: {
						id: 1219,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.ELIMINA-CONFERMA"
					},

					/**
					 * Intestazione voucher
					 */
					INTESTAZIONE_VOUCHER: {
						id: 1220,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.INTESTAZIONE-VOUCHER"
					},

					/**
					 * Itinerari
					 */
					ITINERARI: {
						id: 1221,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.ITINERARI"
					},

					/**
					 * Luoghi
					 */
					LUOGHI: {
						id: 1222,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.LUOGHI"
					},

					/**
					 * Luogo
					 */
					LUOGO: {
						id: 1223,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.LUOGO"
					},

					/**
					 * Il luogo è obbligatorio
					 */
					LUOGO_OBBLIGATORIO: {
						id: 1224,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.LUOGO-OBBLIGATORIO"
					},

					/**
					 * Markup
					 */
					MARKUP: {
						id: 1225,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.MARKUP"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 1226,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.NOME"
					},

					/**
					 * Nome inglese
					 */
					NOME_INGLESE: {
						id: 1227,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.NOME-INGLESE"
					},

					/**
					 * Il nome italiano è obbligatorio
					 */
					NOME_OBBLIGATORIO: {
						id: 1228,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.NOME-OBBLIGATORIO"
					},

					/**
					 * Nome fornitore
					 */
					NOME_ORIGINALE: {
						id: 1229,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.NOME-ORIGINALE"
					},

					/**
					 * Note
					 */
					NOTE: {
						id: 1230,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.NOTE"
					},

					/**
					 * Nuovo servizio
					 */
					NUOVO_SERVIZIO: {
						id: 1231,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.NUOVO-SERVIZIO"
					},

					/**
					 * Vai alle traduzioni
					 */
					OPEN_TRANSLATION: {
						id: 1232,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.OPEN-TRANSLATION"
					},

					/**
					 * Ordine
					 */
					ORDINE: {
						id: 1233,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.ORDINE"
					},

					/**
					 * Partenze
					 */
					PARTENZE: {
						id: 1234,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.PARTENZE"
					},

					/**
					 * Penale default
					 */
					PENALE_DEFAULT: {
						id: 1235,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.PENALE-DEFAULT"
					},

					/**
					 * Prezzo
					 */
					PREZZO: {
						id: 1236,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.PREZZO"
					},

					/**
					 * Pubblicazione Default
					 */
					PUBBLICAZIONE_NAAR: {
						id: 1237,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.PUBBLICAZIONE-NAAR"
					},

					/**
					 * Pubblicazione B2C
					 */
					PUBBLICAZIONE_NAAR_B2C: {
						id: 1238,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.PUBBLICAZIONE-NAAR-B2C"
					},

					/**
					 * Pubblicazione sito Naar FRA
					 */
					PUBBLICAZIONE_NAAR_FRANCE: {
						id: 1239,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.PUBBLICAZIONE-NAAR-FRANCE"
					},

					/**
					 * Regole pacchetto
					 */
					REGOLE_PACCHETTO_SERVIZIO: {
						id: 1240,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.REGOLE-PACCHETTO-SERVIZIO"
					},

					/**
					 * Regole servizio
					 */
					REGOLE_SERVIZIO: {
						id: 1241,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.REGOLE-SERVIZIO"
					},

					/**
					 * Regole vendita
					 */
					REGOLE_VENDITA: {
						id: 1242,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.REGOLE-VENDITA"
					},

					/**
					 * Ripristinare il servizio {svc} ID={id}?
					 */
					RIPRISTINA_CONFERMA: {
						id: 1243,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.RIPRISTINA-CONFERMA"
					},

					/**
					 * Servizio eliminato
					 */
					SERVIZIO_ELIMINATO: {
						id: 1244,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.SERVIZIO-ELIMINATO"
					},

					/**
					 * Stato
					 */
					STATO: {
						id: 1245,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.STATO"
					},

					/**
					 * Tipo
					 */
					TIPO: {
						id: 1246,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.TIPO"
					},

					/**
					 * Tipologie
					 */
					TIPOLOGIE: {
						id: 1247,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.TIPOLOGIE"
					},

					/**
					 * Validità
					 */
					VALIDITA: {
						id: 1248,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EDITOR.VALIDITA"
					},

				},

				/**
				 * Errore di lettura del servizio
				 */
				ERRORE_LETTURA: {
					id: 1249,
					label: "intranet.PROGRAMMAZIONE.SERVIZI.ERRORE-LETTURA"
				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.EXPORT
				 */
				EXPORT: {
					/**
					 * Servizi
					 */
					NOME_FOGLIO: {
						id: 1250,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EXPORT.NOME-FOGLIO"
					},

					/**
					 * Pubblicazione Naar ITA
					 */
					PUBBLICAZIONE_1: {
						id: 1251,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EXPORT.PUBBLICAZIONE-1"
					},

					/**
					 * Pubblicazione Naar FRA
					 */
					PUBBLICAZIONE_2: {
						id: 1252,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EXPORT.PUBBLICAZIONE-2"
					},

					/**
					 * Pubblicazione Naar
					 */
					PUBBLICAZIONE_3: {
						id: 1253,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.EXPORT.PUBBLICAZIONE-3"
					},

				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI
				 */
				ITINERARI: {
					/**
					 * Aggiungi alternativa
					 */
					AGGIUNGI_ALTERNATIVA: {
						id: 1254,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.AGGIUNGI-ALTERNATIVA"
					},

					/**
					 * Aggiungi tappa
					 */
					AGGIUNGI_TAPPA: {
						id: 1255,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.AGGIUNGI-TAPPA"
					},

					/**
					 * Alternative
					 */
					ALTERNATIVE: {
						id: 1256,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.ALTERNATIVE"
					},

					/**
					 * Altro
					 */
					ALTRO_SERVIZIO: {
						id: 1257,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.ALTRO-SERVIZIO"
					},

					/**
					 * Consenti cambio hotel
					 */
					CAMBIO_HOTEL: {
						id: 1258,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.CAMBIO-HOTEL"
					},

					/**
					 * Codice
					 */
					CODICE: {
						id: 1259,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.CODICE"
					},

					/**
					 * Confermi l'eliminazione dell'itinerario {nome} ID={id}?
					 */
					CONFERMA_ELIMINA_ITINERARIO: {
						id: 1260,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.CONFERMA-ELIMINA-ITINERARIO"
					},

					/**
					 * Dettaglio servizi tappa
					 */
					DETTAGLI_SERVIZI_TAPPA: {
						id: 1261,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.DETTAGLI-SERVIZI-TAPPA"
					},

					/**
					 * Giorni / Notti
					 */
					DURATA: {
						id: 1262,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.DURATA"
					},

					/**
					 * Durata come tappa
					 */
					DURATA_COME_TAPPA: {
						id: 1263,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.DURATA-COME-TAPPA"
					},

					/**
					 * Giorno
					 */
					GIORNO: {
						id: 1264,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.GIORNO"
					},

					/**
					 * Giorno {gg}
					 */
					GIORNO_N: {
						id: 1265,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.GIORNO-N"
					},

					/**
					 * -- Hotel non associato --
					 */
					HOTEL_NON_ASSOCIATO: {
						id: 1266,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.HOTEL-NON-ASSOCIATO"
					},

					/**
					 * ID
					 */
					ID: {
						id: 1267,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.ID"
					},

					/**
					 * Itinerario con durata superiore a {gg} giorni
					 */
					ITINERARIO_CON_DURATA_ECCESSIVA: {
						id: 1268,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.ITINERARIO-CON-DURATA-ECCESSIVA"
					},

					/**
					 * Luogo
					 */
					LUOGO: {
						id: 1269,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.LUOGO"
					},

					/**
					 * Mantieni fine tappa
					 */
					MANTIENI_FINE_TAPPA: {
						id: 1270,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.MANTIENI-FINE-TAPPA"
					},

					/**
					 * Mantieni inizio tappa
					 */
					MANTIENI_INIZIO_TAPPA: {
						id: 1271,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.MANTIENI-INIZIO-TAPPA"
					},

					/**
					 * No ricerca voli
					 */
					NO_RICERCA_VOLI: {
						id: 1272,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.NO-RICERCA-VOLI"
					},

					/**
					 * Nome Interno
					 */
					NOME: {
						id: 1273,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.NOME"
					},

					/**
					 * Nuova
					 */
					NUOVA_TAPPA: {
						id: 1274,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.NUOVA-TAPPA"
					},

					/**
					 * Nuovo itinerario
					 */
					NUOVO_ITINERAIO: {
						id: 1275,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.NUOVO-ITINERAIO"
					},

					/**
					 * Questo servizio
					 */
					QUESTO_SERVIZIO: {
						id: 1276,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.QUESTO-SERVIZIO"
					},

					/**
					 * Scegli il servizio per la tappa
					 */
					SCEGLI_SERVIZIO: {
						id: 1277,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.SCEGLI-SERVIZIO"
					},

					/**
					 * Scegli la tipologia del servizio selezionato
					 */
					SCEGLI_TIPOLOGIA: {
						id: 1278,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.SCEGLI-TIPOLOGIA"
					},

					/**
					 * Scegli tipologia pacchetto
					 */
					SCEGLI_TIPOLOGIA_PACCHETTO: {
						id: 1279,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.SCEGLI-TIPOLOGIA-PACCHETTO"
					},

					/**
					 * Scelta multipla
					 */
					SCELTA_MULTIPLA: {
						id: 1280,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.SCELTA-MULTIPLA"
					},

					/**
					 * Servizi alternativi
					 */
					SERVIZI_ALTERNATIVI: {
						id: 1281,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.SERVIZI-ALTERNATIVI"
					},

					/**
					 * Servizi della tappa
					 */
					SERVIZI_TAPPA: {
						id: 1282,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.SERVIZI-TAPPA"
					},

					/**
					 * Servizio singolo
					 */
					SERVIZIO_SINGOLO: {
						id: 1283,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.SERVIZIO-SINGOLO"
					},

					/**
					 * Tappa con durata superiore a {gg} giorni
					 */
					TAPPA_CON_DURATA_ECCESSIVA: {
						id: 1284,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.TAPPA-CON-DURATA-ECCESSIVA"
					},

					/**
					 * Tappe
					 */
					TAPPE: {
						id: 1285,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.ITINERARI.TAPPE"
					},

				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.LISTA
				 */
				LISTA: {
					/**
					 * Contatto
					 */
					CONTATTO: {
						id: 1286,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.CONTATTO"
					},

					/**
					 * Fornitore
					 */
					FORNITORE: {
						id: 1287,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.FORNITORE"
					},

					/**
					 * Cod.Giata
					 */
					GIATA: {
						id: 1288,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.GIATA"
					},

					/**
					 * ID
					 */
					ID: {
						id: 1289,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.ID"
					},

					/**
					 * Luogo
					 */
					LUOGO: {
						id: 1290,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.LUOGO"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 1291,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.NOME"
					},

					/**
					 * Nome / ID
					 */
					NOME_O_ID: {
						id: 1292,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.NOME_O_ID"
					},

					/**
					 * Paese
					 */
					PAESE: {
						id: 1293,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.PAESE"
					},

					/**
					 * Pubblica
					 */
					PUBBLICAZIONE: {
						id: 1294,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.PUBBLICAZIONE"
					},

					/**
					 * Regione
					 */
					REGIONE: {
						id: 1295,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.REGIONE"
					},

					/**
					 * Status
					 */
					STATUS: {
						id: 1296,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.STATUS"
					},

					/**
					 * Tipo
					 */
					TIPO: {
						id: 1297,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.TIPO"
					},

					/**
					 * Nome in lingua
					 */
					NOME_LINGUA: {
						id: 5997,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.NOME-LINGUA"
					},

					/**
					 * Lingua
					 */
					LINGUA: {
						id: 5998,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.LINGUA"
					},

					/**
					 * Pubblicati nel mercato 
					 */
					SOLAMENTE_PUBBLICATI: {
						id: 6196,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.SOLAMENTE-PUBBLICATI"
					},

					/**
					 * Ultimo aggiornamento
					 */
					ULTIMO_AGGIORNAMENTO: {
						id: 6220,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LISTA.ULTIMO-AGGIORNAMENTO"
					},

				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.LUOGHI
				 */
				LUOGHI: {
					/**
					 * Aggiungi luogo
					 */
					AGGIUNGI_LUOGO: {
						id: 1298,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LUOGHI.AGGIUNGI-LUOGO"
					},

					/**
					 * ID
					 */
					ID: {
						id: 1299,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LUOGHI.ID"
					},

					/**
					 * Luogo
					 */
					LUOGO: {
						id: 1300,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LUOGHI.LUOGO"
					},

					/**
					 * Il luogo {nome} ID={id} è già presente nell'elenco!
					 */
					LUOGO_GIA_PRESENTE: {
						id: 1301,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LUOGHI.LUOGO-GIA-PRESENTE"
					},

					/**
					 * Paese
					 */
					PAESE: {
						id: 1302,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LUOGHI.PAESE"
					},

					/**
					 * Regione
					 */
					REGIONE: {
						id: 1303,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LUOGHI.REGIONE"
					},

					/**
					 * Dati modificati. Ricordati di salvare!
					 */
					RICORDATI_DI_SALVARE: {
						id: 1304,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.LUOGHI.RICORDATI-DI-SALVARE"
					},

				},

				/**
				 * Nuovo servizio {n}
				 */
				NUOVO_SERVIZIO: {
					id: 1305,
					label: "intranet.PROGRAMMAZIONE.SERVIZI.NUOVO-SERVIZIO"
				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE
				 */
				PARTENZE: {
					/**
					 * Aggiungi
					 */
					AGGIUNGI: {
						id: 1306,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.AGGIUNGI"
					},

					/**
					 * Al
					 */
					AL: {
						id: 1307,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.AL"
					},

					/**
					 * Dal
					 */
					DAL: {
						id: 1308,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.DAL"
					},

					/**
					 * Elimina
					 */
					ELIMINA: {
						id: 1309,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.ELIMINA"
					},

					/**
					 * Garantita
					 */
					GARANTITA: {
						id: 1310,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.GARANTITA"
					},

					/**
					 * giorni
					 */
					GIORNI: {
						id: 1311,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.GIORNI"
					},

					/**
					 * Imposta 'Garantita'
					 */
					IMPOSTA_GARANTITA: {
						id: 1312,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IMPOSTA-GARANTITA"
					},

					/**
					 * Imposta 'In francese'
					 */
					IMPOSTA_IN_FRANCESE: {
						id: 1313,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IMPOSTA-IN-FRANCESE"
					},

					/**
					 * Imposta 'In inglese'
					 */
					IMPOSTA_IN_INGLESE: {
						id: 1314,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IMPOSTA-IN-INGLESE"
					},

					/**
					 * Imposta 'In italiano'
					 */
					IMPOSTA_IN_ITALIANO: {
						id: 1315,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IMPOSTA-IN-ITALIANO"
					},

					/**
					 * Imposta 'In spagnolo'
					 */
					IMPOSTA_IN_SPAGNOLO: {
						id: 1316,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IMPOSTA-IN-SPAGNOLO"
					},

					/**
					 * Imposta 'In tedesco'
					 */
					IMPOSTA_IN_TEDESCO: {
						id: 1317,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IMPOSTA-IN-TEDESCO"
					},

					/**
					 * Imposta non 'Garantita'
					 */
					IMPOSTA_NON_GARANTITA: {
						id: 1318,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IMPOSTA-NON-GARANTITA"
					},

					/**
					 * Imposta non 'In francese'
					 */
					IMPOSTA_NON_IN_FRANCESE: {
						id: 1319,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IMPOSTA-NON-IN-FRANCESE"
					},

					/**
					 * Imposta non 'In inglese'
					 */
					IMPOSTA_NON_IN_INGLESE: {
						id: 1320,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IMPOSTA-NON-IN-INGLESE"
					},

					/**
					 * Imposta non 'In italiano'
					 */
					IMPOSTA_NON_IN_ITALIANO: {
						id: 1321,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IMPOSTA-NON-IN-ITALIANO"
					},

					/**
					 * Imposta non 'In spagnolo'
					 */
					IMPOSTA_NON_IN_SPAGNOLO: {
						id: 1322,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IMPOSTA-NON-IN-SPAGNOLO"
					},

					/**
					 * Imposta non 'In tedesco'
					 */
					IMPOSTA_NON_IN_TEDESCO: {
						id: 1323,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IMPOSTA-NON-IN-TEDESCO"
					},

					/**
					 * Imposta non 'Su richiesta'
					 */
					IMPOSTA_NON_SU_RICHIESTA: {
						id: 1324,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IMPOSTA-NON-SU-RICHIESTA"
					},

					/**
					 * Imposta 'Su richiesta'
					 */
					IMPOSTA_SU_RICHIESTA: {
						id: 1325,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IMPOSTA-SU-RICHIESTA"
					},

					/**
					 * In francese
					 */
					IN_FRANCESE: {
						id: 1326,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-FRANCESE"
					},

					/**
					 * In inglese
					 */
					IN_INGLESE: {
						id: 1327,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-INGLESE"
					},

					/**
					 * In italiano
					 */
					IN_ITALIANO: {
						id: 1328,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-ITALIANO"
					},

					/**
					 * In spagnolo
					 */
					IN_SPAGNOLO: {
						id: 1329,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-SPAGNOLO"
					},

					/**
					 * In tedesco
					 */
					IN_TEDESCO: {
						id: 1330,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-TEDESCO"
					},

					/**
					 * Inserire una data
					 */
					INDICARE_UNA_DATA: {
						id: 1331,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.INDICARE-UNA-DATA"
					},

					/**
					 * Mese
					 */
					MESE: {
						id: 1332,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.MESE"
					},

					/**
					 * {n} selezionat{n, plural, =0{e} one{a} other{e}}
					 */
					N_SELEZIONATE: {
						id: 1333,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.N-SELEZIONATE"
					},

					/**
					 * Ogni
					 */
					OGNI: {
						id: 1334,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.OGNI"
					},

					/**
					 * Partenze
					 */
					PARTENZE: {
						id: 1335,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.PARTENZE"
					},

					/**
					 * Partenze create
					 */
					PARTENZE_CREATE: {
						id: 1336,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.PARTENZE-CREATE"
					},

					/**
					 * Partenze eliminate
					 */
					PARTENZE_ELIMINATE: {
						id: 1337,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.PARTENZE-ELIMINATE"
					},

					/**
					 * Partenze salvate
					 */
					PARTENZE_SALVATE: {
						id: 1338,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.PARTENZE-SALVATE"
					},

					/**
					 * Su richiesta
					 */
					SU_RICHIESTA: {
						id: 1339,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.SU-RICHIESTA"
					},

					/**
					 * In olandese
					 */
					IN_OLANDESE: {
						id: 6110,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-OLANDESE"
					},

				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-PACCHETTO
				 */
				REGOLE_PACCHETTO: {
					/**
					 * Confermi di voler eliminare {n} regol{n, plural, =0{e} one{a} other{e}} pacchetto?
					 */
					ELIMINA_MULTI_CONFERMA: {
						id: 1340,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-PACCHETTO.ELIMINA-MULTI-CONFERMA"
					},

					/**
					 * ID
					 */
					ID: {
						id: 1341,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-PACCHETTO.ID"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 1342,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-PACCHETTO.NOME"
					},

					/**
					 * Nuova regola pacchetto
					 */
					NUOVA_REGOLA_PACCHETTO: {
						id: 1343,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-PACCHETTO.NUOVA-REGOLA-PACCHETTO"
					},

					/**
					 * Partenza al
					 */
					PARTENZA_AL: {
						id: 1344,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-PACCHETTO.PARTENZA-AL"
					},

					/**
					 * Partenza dal
					 */
					PARTENZA_DAL: {
						id: 1345,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-PACCHETTO.PARTENZA-DAL"
					},

					/**
					 * Partenza dal-al
					 */
					PARTENZA_DAL_AL: {
						id: 1346,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-PACCHETTO.PARTENZA-DAL-AL"
					},

					/**
					 * Prenotazione al
					 */
					PRENOTAZIONE_AL: {
						id: 1347,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-PACCHETTO.PRENOTAZIONE-AL"
					},

					/**
					 * Prenotazione dal
					 */
					PRENOTAZIONE_DAL: {
						id: 1348,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-PACCHETTO.PRENOTAZIONE-DAL"
					},

					/**
					 * Prenotabile dal-al
					 */
					PRENOTAZIONE_DAL_AL: {
						id: 1349,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-PACCHETTO.PRENOTAZIONE-DAL-AL"
					},

					/**
					 * Sconto blocca prezzo
					 */
					SCONTO_BLOCCA_PREZZO: {
						id: 1350,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-PACCHETTO.SCONTO-BLOCCA-PREZZO"
					},

					/**
					 * Sconto quote
					 */
					SCONTO_QUOTE: {
						id: 1351,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-PACCHETTO.SCONTO-QUOTE"
					},

					/**
					 * Sconto servizio
					 */
					SCONTO_SERVIZIO: {
						id: 1352,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-PACCHETTO.SCONTO-SERVIZIO"
					},

				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO
				 */
				REGOLE_SERVIZIO: {
					/**
					 * Ad
					 */
					AD: {
						id: 1353,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.AD"
					},

					/**
					 * Camera
					 */
					CAMERA: {
						id: 1354,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.CAMERA"
					},

					/**
					 * Ch
					 */
					CH: {
						id: 1355,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.CH"
					},

					/**
					 * Costo per giorno
					 */
					COSTO_PER_GIORNO: {
						id: 1356,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.COSTO-PER-GIORNO"
					},

					/**
					 * Costo per persona
					 */
					COSTO_PER_PERSONA: {
						id: 1357,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.COSTO-PER-PERSONA"
					},

					/**
					 * Descrizione
					 */
					DESCRIZIONE: {
						id: 1358,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.DESCRIZIONE"
					},

					/**
					 * Confermi di voler eliminare {n} regol{n, plural, =0{e} one{a} other{e}} servizio?
					 */
					ELIMINA_MULTI_CONFERMA: {
						id: 1359,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.ELIMINA-MULTI-CONFERMA"
					},

					/**
					 * Vuoi eliminare la regola {desc} (ID={id})?
					 */
					ELIMINA_REGOLA_CONFERMA: {
						id: 1360,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.ELIMINA-REGOLA-CONFERMA"
					},

					/**
					 * Età child gratis
					 */
					ETA_CHILD_GRATIS: {
						id: 1361,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.ETA-CHILD-GRATIS"
					},

					/**
					 * Età child gratis deve essere un numero tra 0 e 255
					 */
					ETA_CHILD_GRATIS_NON_VALIDA: {
						id: 1362,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.ETA-CHILD-GRATIS-NON-VALIDA"
					},

					/**
					 * EtaInf
					 */
					ETA_INF: {
						id: 1363,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.ETA-INF"
					},

					/**
					 * Età infant
					 */
					ETA_INFANT: {
						id: 1364,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.ETA-INFANT"
					},

					/**
					 * Età infant deve essere un numero tra 0 e 255
					 */
					ETA_INFANT_NON_VALIDA: {
						id: 1365,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.ETA-INFANT-NON-VALIDA"
					},

					/**
					 * FreeCh
					 */
					FREE_CH: {
						id: 1366,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.FREE-CH"
					},

					/**
					 * FreeSale
					 */
					FREE_SALE: {
						id: 1367,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.FREE-SALE"
					},

					/**
					 * Freesale giorni
					 */
					FREESALE_GIORNI: {
						id: 1368,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.FREESALE-GIORNI"
					},

					/**
					 * Freesale giorni deve essere un numero tra 0 e 255
					 */
					FREESALE_GIORNI_NON_VALIDO: {
						id: 1369,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.FREESALE-GIORNI-NON-VALIDO"
					},

					/**
					 * Globale
					 */
					GLOBALE: {
						id: 1370,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.GLOBALE"
					},

					/**
					 * ID
					 */
					ID: {
						id: 1371,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.ID"
					},

					/**
					 * Max adulti
					 */
					MAX_ADULTI: {
						id: 1372,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.MAX-ADULTI"
					},

					/**
					 * Max adulti deve essere un numero tra 1 e 255
					 */
					MAX_ADULTI_NON_VALIDO: {
						id: 1373,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.MAX-ADULTI-NON-VALIDO"
					},

					/**
					 * Max child camera
					 */
					MAX_CHILD_CAM: {
						id: 1374,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.MAX-CHILD-CAM"
					},

					/**
					 * Max child camera deve essere un numero tra 0 e 255
					 */
					MAX_CHILD_CAMERA_NON_VALIDO: {
						id: 1375,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.MAX-CHILD-CAMERA-NON-VALIDO"
					},

					/**
					 * Max pax
					 */
					MAX_PAX: {
						id: 1376,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.MAX-PAX"
					},

					/**
					 * Max pax camera
					 */
					MAX_PAX_CAMERA: {
						id: 1377,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.MAX-PAX-CAMERA"
					},

					/**
					 * Max pax camera deve essere un numero tra 1 e 255
					 */
					MAX_PAX_CAMERA_NON_VALIDO: {
						id: 1378,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.MAX-PAX-CAMERA-NON-VALIDO"
					},

					/**
					 * Max pax deve essere un numero tra 1 e 255
					 */
					MAX_PAX_OBBLIGATORIO: {
						id: 1379,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.MAX-PAX-OBBLIGATORIO"
					},

					/**
					 * Min pax
					 */
					MIN_PAX: {
						id: 1380,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.MIN-PAX"
					},

					/**
					 * Min pax deve essere un numero tra 0 e 255
					 */
					MIN_PAX_OBBLIGATORIO: {
						id: 1381,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.MIN-PAX-OBBLIGATORIO"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 1382,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.NOME"
					},

					/**
					 * Nuova regola servizio
					 */
					NUOVA_REGOLA_SERVIZIO: {
						id: 1383,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.NUOVA-REGOLA-SERVIZIO"
					},

					/**
					 * Pax
					 */
					PAX: {
						id: 1384,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.PAX"
					},

					/**
					 * PerGG
					 */
					PER_GG: {
						id: 1385,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.PER-GG"
					},

					/**
					 * PerPers
					 */
					PER_PERS: {
						id: 1386,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.PER-PERS"
					},

					/**
					 * Eliminate {n} regol{n, plural, =0{e} one{a} other{e}} servizio
					 */
					REGOLE_ELIMINATE: {
						id: 1387,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-SERVIZIO.REGOLE-ELIMINATE"
					},

				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA
				 */
				REGOLE_VENDITA: {
					/**
					 * Al
					 */
					AL: {
						id: 1388,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.AL"
					},

					/**
					 * Articolo testo penali
					 */
					ARTICOLO_PENALE: {
						id: 1389,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.ARTICOLO-PENALE"
					},

					/**
					 * Comm ADV
					 */
					COMM_ADV: {
						id: 1390,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.COMM-ADV"
					},

					/**
					 * Comm TO
					 */
					COMM_TO: {
						id: 1391,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.COMM-TO"
					},

					/**
					 * Dal
					 */
					DAL: {
						id: 1392,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.DAL"
					},

					/**
					 * Data non valida
					 */
					DATA_NON_VALIDA: {
						id: 1393,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.DATA-NON-VALIDA"
					},

					/**
					 * Der. su richiesta
					 */
					DEREG_SU_RICHIESTA: {
						id: 1394,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.DEREG-SU-RICHIESTA"
					},

					/**
					 * Deregolamenta commissione ADV
					 */
					DEREGOLAMENTA_COMMISSIONE_ADV: {
						id: 1395,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.DEREGOLAMENTA-COMMISSIONE-ADV"
					},

					/**
					 * La commissione ADV deve essere un numero tra 0 e 100
					 */
					DEREGOLAMENTA_COMMISSIONE_ADV_NON_VALIDA: {
						id: 1396,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.DEREGOLAMENTA-COMMISSIONE-ADV-NON-VALIDA"
					},

					/**
					 * Deregolamenta commissione TO
					 */
					DEREGOLAMENTA_COMMISSIONE_TO: {
						id: 1397,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.DEREGOLAMENTA-COMMISSIONE-TO"
					},

					/**
					 * La commissione TO deve essere un numero tra 0 e 100
					 */
					DEREGOLAMENTA_COMMISSIONE_TO_NON_VALIDA: {
						id: 1398,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.DEREGOLAMENTA-COMMISSIONE-TO-NON-VALIDA"
					},

					/**
					 * Deregolamenta 'su richiesta'
					 */
					DEREGOLAMENTA_SU_RICHIESTA: {
						id: 1399,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.DEREGOLAMENTA-SU-RICHIESTA"
					},

					/**
					 * Descrizione
					 */
					DESCRIZIONE: {
						id: 1400,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.DESCRIZIONE"
					},

					/**
					 * Confermi di voler eliminare {n} regol{n, plural, =0{e} one{a} other{e}} vendita?
					 */
					ELIMINA_MULTI_CONFERMA: {
						id: 1401,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.ELIMINA-MULTI-CONFERMA"
					},

					/**
					 * Vuoi eliminare la regola {nome} - {desc} (ID={id})?
					 */
					ELIMINA_REGOLA_CONFERMA: {
						id: 1402,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.ELIMINA-REGOLA-CONFERMA"
					},

					/**
					 * Età max
					 */
					ETA_MAX: {
						id: 1403,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.ETA-MAX"
					},

					/**
					 * Età min
					 */
					ETA_MIN: {
						id: 1404,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.ETA-MIN"
					},

					/**
					 * Out
					 */
					GG_FINE: {
						id: 1405,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.GG-FINE"
					},

					/**
					 * In
					 */
					GG_INIZIO: {
						id: 1406,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.GG-INIZIO"
					},

					/**
					 * Giorni entrata
					 */
					GIORNI_ENTRATA: {
						id: 1407,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.GIORNI-ENTRATA"
					},

					/**
					 * Giorni prima
					 */
					GIORNI_PRIMA: {
						id: 1408,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.GIORNI-PRIMA"
					},

					/**
					 * Giorni uscita
					 */
					GIORNI_USCITA: {
						id: 1409,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.GIORNI-USCITA"
					},

					/**
					 * ID
					 */
					ID: {
						id: 1410,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.ID"
					},

					/**
					 * Massimo notti
					 */
					MASSIMO_NOTTI: {
						id: 1411,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.MASSIMO-NOTTI"
					},

					/**
					 * 0 - 100
					 */
					MASSIMO_NOTTI_NON_VALIDO: {
						id: 1412,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.MASSIMO-NOTTI-NON-VALIDO"
					},

					/**
					 * Max età pax
					 */
					MAX_ETA_PAX: {
						id: 1413,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.MAX-ETA-PAX"
					},

					/**
					 * 0 - 100
					 */
					MAX_ETA_PAX_NON_VALIDA: {
						id: 1414,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.MAX-ETA-PAX-NON-VALIDA"
					},

					/**
					 * Min età pax
					 */
					MIN_ETA_PAX: {
						id: 1415,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.MIN-ETA-PAX"
					},

					/**
					 * 0 - 100
					 */
					MIN_ETA_PAX_NON_VALIDA: {
						id: 1416,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.MIN-ETA-PAX-NON-VALIDA"
					},

					/**
					 * Min nt. periodo
					 */
					MIN_NOTTI_PERIODO: {
						id: 1417,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.MIN-NOTTI-PERIODO"
					},

					/**
					 * Minimo notti
					 */
					MINIMO_NOTTI: {
						id: 1418,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.MINIMO-NOTTI"
					},

					/**
					 * 0 - 100
					 */
					MINIMO_NOTTI_NON_VALIDO: {
						id: 1419,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.MINIMO-NOTTI-NON-VALIDO"
					},

					/**
					 * Minimo notti periodo
					 */
					MINIMO_NOTTI_PERIODO: {
						id: 1420,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.MINIMO-NOTTI-PERIODO"
					},

					/**
					 * 0 - 255
					 */
					MINIMO_NOTTI_PERIODO_NON_VALIDO: {
						id: 1421,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.MINIMO-NOTTI-PERIODO-NON-VALIDO"
					},

					/**
					 * -- Nessuna --
					 */
					NESSUNA: {
						id: 1422,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.NESSUNA"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 1423,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.NOME"
					},

					/**
					 * Notti
					 */
					NOTTI: {
						id: 1424,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.NOTTI"
					},

					/**
					 * Notti
					 */
					NOTTI_PENALE: {
						id: 1425,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.NOTTI-PENALE"
					},

					/**
					 * Numero notti
					 */
					NUMERO_NOTTI: {
						id: 1426,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.NUMERO-NOTTI"
					},

					/**
					 * 0 - 100
					 */
					NUMERO_NOTTI_NON_VALIDO: {
						id: 1427,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.NUMERO-NOTTI-NON-VALIDO"
					},

					/**
					 * Nuova regola vendita
					 */
					NUOVA_REGOLA_VENDITA: {
						id: 1428,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.NUOVA-REGOLA-VENDITA"
					},

					/**
					 * Penale
					 */
					PENALE: {
						id: 1429,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.PENALE"
					},

					/**
					 * Penali
					 */
					PENALI: {
						id: 1430,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.PENALI"
					},

					/**
					 * Penale
					 */
					PERCENTUALE_PENALE: {
						id: 1431,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.PERCENTUALE-PENALE"
					},

					/**
					 * Prenota entro data
					 */
					PRENOTA_ENTRO_DATA: {
						id: 1432,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.PRENOTA-ENTRO-DATA"
					},

					/**
					 * Prenota entro giorni
					 */
					PRENOTA_ENTRO_GIORNI: {
						id: 1433,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.PRENOTA-ENTRO-GIORNI"
					},

					/**
					 * 0-100
					 */
					PRENOTA_ENTRO_GIORNI_NON_VALIDO: {
						id: 1434,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.PRENOTA-ENTRO-GIORNI-NON-VALIDO"
					},

					/**
					 * Prenota entro il {data}
					 */
					PRENOTA_ENTRO_IL_DATA: {
						id: 1435,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.PRENOTA-ENTRO-IL-DATA"
					},

					/**
					 * Prenota entro il {n} giorn{n, plural, =0{i} one{o} other{i}}
					 */
					PRENOTA_ENTRO_IL_GIORNI: {
						id: 1436,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.PRENOTA-ENTRO-IL-GIORNI"
					},

					/**
					 * Eliminate {n} regol{n, plural, =0{e} one{a} other{e}} vendita
					 */
					REGOLE_ELIMINATE: {
						id: 1437,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.REGOLE-VENDITA.REGOLE-ELIMINATE"
					},

				},

				/**
				 * Ricerca
				 */
				RICERCA: {
					id: 1438,
					label: "intranet.PROGRAMMAZIONE.SERVIZI.RICERCA"
				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE
				 */
				TIPOLOGIE: {
					/**
					 * Alternativa
					 */
					ALTERNATIVA: {
						id: 1439,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.ALTERNATIVA"
					},

					/**
					 * Codice
					 */
					CODICE: {
						id: 1440,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.CODICE"
					},

					/**
					 * Contatto fine
					 */
					CONTATTO_FINE: {
						id: 1441,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.CONTATTO-FINE"
					},

					/**
					 * Contatto inizio
					 */
					CONTATTO_INIZIO: {
						id: 1442,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.CONTATTO-INIZIO"
					},

					/**
					 * Costo per giorno
					 */
					COSTO_PER_GIORNO: {
						id: 1443,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.COSTO-PER-GIORNO"
					},

					/**
					 * Costo per persona
					 */
					COSTO_PER_PERSONA: {
						id: 1444,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.COSTO-PER-PERSONA"
					},

					/**
					 * Confermi di voler eliminare la tipologia {nome} (ID={id})?
					 */
					ELIMINA_CONFERMA: {
						id: 1445,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.ELIMINA-CONFERMA"
					},

					/**
					 * Confermi di voler eliminare {n} tipologi{n, plural, =0{e} one{a} other{e}}?
					 */
					ELIMINA_MULTI_CONFERMA: {
						id: 1446,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.ELIMINA-MULTI-CONFERMA"
					},

					/**
					 * tipologie_{ts}.xlsx
					 */
					EXPORT_FILE_NAME: {
						id: 1447,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.EXPORT-FILE-NAME"
					},

					/**
					 * Tipologie
					 */
					EXPORT_SHEET_NAME: {
						id: 1448,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.EXPORT-SHEET-NAME"
					},

					/**
					 * Facoltativo
					 */
					FACOLTATIVO: {
						id: 1449,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.FACOLTATIVO"
					},

					/**
					 * Gruppo {n}
					 */
					GRUPPO_ALTERNATIVA: {
						id: 1450,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.GRUPPO-ALTERNATIVA"
					},

					/**
					 * ID
					 */
					ID: {
						id: 1451,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.ID"
					},

					/**
					 * Intestazione voucher
					 */
					INTESTAZIONE_VOUCHER: {
						id: 1452,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.INTESTAZIONE-VOUCHER"
					},

					/**
					 * Itinerario
					 */
					ITINERARIO: {
						id: 1453,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.ITINERARIO"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 1454,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.NOME"
					},

					/**
					 * Nome fornitore
					 */
					NOME_VOUCHER: {
						id: 1455,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.NOME-VOUCHER"
					},

					/**
					 * N/D
					 */
					NON_SPECIFICATO: {
						id: 1456,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.NON-SPECIFICATO"
					},

					/**
					 * Note
					 */
					NOTE: {
						id: 1457,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.NOTE"
					},

					/**
					 * Note voucher
					 */
					NOTE_VOUCHER: {
						id: 1458,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.NOTE-VOUCHER"
					},

					/**
					 * Nuova tipologia
					 */
					NUOVA_TIPOLOGIA: {
						id: 1459,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.NUOVA-TIPOLOGIA"
					},

					/**
					 * Obbligatorio
					 */
					OBBLIGATORIO: {
						id: 1460,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.OBBLIGATORIO"
					},

					/**
					 * Ora fine
					 */
					ORA_FINE: {
						id: 1461,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.ORA-FINE"
					},

					/**
					 * Ora inizio
					 */
					ORA_INIZIO: {
						id: 1462,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.ORA-INIZIO"
					},

					/**
					 * Ordine
					 */
					ORDINE: {
						id: 1463,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.ORDINE"
					},

					/**
					 * Punto fine
					 */
					PUNTO_FINE: {
						id: 1464,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.PUNTO-FINE"
					},

					/**
					 * Punto inizio
					 */
					PUNTO_INIZIO: {
						id: 1465,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.PUNTO-INIZIO"
					},

					/**
					 * Regola pacchetto
					 */
					REGOLA_PACCHETTO: {
						id: 1466,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.REGOLA-PACCHETTO"
					},

					/**
					 * Confermi di voler ripristinare la tipologia {nome} (ID={id})?
					 */
					RIPRISTINA_CONFERMA: {
						id: 1467,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.RIPRISTINA-CONFERMA"
					},

					/**
					 * Su richiesta
					 */
					SU_RICHIESTA: {
						id: 1468,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.SU-RICHIESTA"
					},

					/**
					 * Suppl./Riduz.
					 */
					SUPPL_RID: {
						id: 1469,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.SUPPL_RID"
					},

					/**
					 * Supplemento in %
					 */
					SUPPLEMENTO_PERCENTUALE: {
						id: 1470,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.SUPPLEMENTO-PERCENTUALE"
					},

					/**
					 * Tipo
					 */
					TIPO: {
						id: 1471,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.TIPO"
					},

					/**
					 * Tipologia eliminata
					 */
					TIPOLOGIA_ELIMINATA: {
						id: 1472,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.TIPOLOGIA-ELIMINATA"
					},

					/**
					 * Eliminate {n} tipologi{n, plural, =0{e} one{a} other{e}}
					 */
					TIPOLOGIE_ELIMINATE: {
						id: 1473,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.TIPOLOGIE-ELIMINATE"
					},

					/**
					 * Verifica partenze
					 */
					VERIFICA_PARTENZE: {
						id: 1474,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.VERIFICA-PARTENZE"
					},

					/**
					 * Tipo standard
					 */
					TIPOLOGIA_STANDARD: {
						id: 6111,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.TIPOLOGIA-STANDARD"
					},

					/**
					 * Parametri standard
					 */
					PARAMS_STANDARD: {
						id: 6117,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.PARAMS-STANDARD"
					},

					/**
					 * Nome o standard
					 */
					NOME_O_STANDARD: {
						id: 6187,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.NOME-O-STANDARD"
					},

					/**
					 * Crea tipologia standard
					 */
					CREA_STANDARD: {
						id: 6188,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.CREA-STANDARD"
					},

					/**
					 * Lingue associate
					 */
					LINGUE_ASSOCIATE: {
						id: 6212,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.LINGUE-ASSOCIATE"
					},

					/**
					 * Parametro presente in:
					 */
					PARAM_PRESENTE_IN: {
						id: 6215,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.PARAM-PRESENTE-IN"
					},

					/**
					 * Traduzione automatica
					 */
					TRADUZIONE_AUTOMATICA: {
						id: 6225,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.TRADUZIONE-AUTOMATICA"
					},

					/**
					 * Crea un parametro con @nome_param. Usa @startContact, @startPoint, @startTime, @endContact, @endPoint, @endTime per usare il valore del contatto, punto e orario di inizio e fine.
					 */
					PARAMS_HINT: {
						id: 6229,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.PARAMS-HINT"
					},

					/**
					 * La tipologia standard sarà disponibile per tutte le tipologie associate a servizi di tipo:
					 */
					TIPO_SERVIZIO_HINT: {
						id: 6230,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.TIPOLOGIE.TIPO-SERVIZIO-HINT"
					},

				},

				/**
				 * Servizi e costi
				 */
				TITOLO: {
					id: 1475,
					label: "intranet.PROGRAMMAZIONE.SERVIZI.TITOLO"
				},

				/**
				 * Content of path intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA
				 */
				VALIDITA: {
					/**
					 * Al
					 */
					AL: {
						id: 1476,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.AL"
					},

					/**
					 * Dal
					 */
					DAL: {
						id: 1477,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.DAL"
					},

					/**
					 * Data 'al' obbligaotria
					 */
					DATA_AL_OBBLIGATORIA: {
						id: 1478,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.DATA-AL-OBBLIGATORIA"
					},

					/**
					 * Data 'dal' obbligaotria
					 */
					DATA_DAL_OBBLIGATORIA: {
						id: 1479,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.DATA-DAL-OBBLIGATORIA"
					},

					/**
					 * Delta giorni
					 */
					DELTA_GIORNI: {
						id: 1480,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.DELTA-GIORNI"
					},

					/**
					 * Duplicare {n} validità?
					 */
					DUPLICA_CONFERMA: {
						id: 1481,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.DUPLICA-CONFERMA"
					},

					/**
					 * Duplicazione validità
					 */
					DUPLICAZIONE_VALIDITA: {
						id: 1482,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.DUPLICAZIONE-VALIDITA"
					},

					/**
					 * Eliminare la validità ID={id}?
					 */
					ELIMINA_CONFERMA: {
						id: 1483,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.ELIMINA-CONFERMA"
					},

					/**
					 * Giorni
					 */
					GIORNI: {
						id: 1484,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.GIORNI"
					},

					/**
					 * ID
					 */
					ID: {
						id: 1485,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.ID"
					},

					/**
					 * Incremento costi
					 */
					INCREMENTO_COSTI: {
						id: 1486,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.INCREMENTO-COSTI"
					},

					/**
					 * Note
					 */
					NOTE: {
						id: 1487,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.NOTE"
					},

					/**
					 * Nuova validità
					 */
					NUOVA_VALIDITA: {
						id: 1488,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.NUOVA-VALIDITA"
					},

					/**
					 * Over
					 */
					OVER: {
						id: 1489,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.OVER"
					},

					/**
					 * Over Adv %
					 */
					OVER_ADV: {
						id: 1490,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.OVER-ADV"
					},

					/**
					 * Valori validi: 0-255
					 */
					OVER_ADV_HELP: {
						id: 1491,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.OVER-ADV-HELP"
					},

					/**
					 * Prezzi indicativi
					 */
					PREZZI_INDICATIVI: {
						id: 1492,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.PREZZI-INDICATIVI"
					},

					/**
					 * tutti
					 */
					TUTTI_I_GIORNI: {
						id: 1493,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.TUTTI-I-GIORNI"
					},

					/**
					 * Validità
					 */
					VALIDITA: {
						id: 1494,
						label: "intranet.PROGRAMMAZIONE.SERVIZI.VALIDITA.VALIDITA"
					},

				},

				/**
				 * Sei sicuro di voler modificare lo stato di pubblicazione {site} del servizio {id} da "{old}" a "{new}"?
				 */
				CONFERMA_MODIFICA_PUBBLICAZIONE: {
					id: 6000,
					label: "intranet.PROGRAMMAZIONE.SERVIZI.CONFERMA-MODIFICA-PUBBLICAZIONE"
				},

			},

			/**
			 * Content of path intranet.PROGRAMMAZIONE.TEMPLATES
			 */
			TEMPLATES: {
				/**
				 * Vuoi eliminare il template ID={id}?
				 */
				ELIMINAZIONE_TEMPLATE: {
					id: 1495,
					label: "intranet.PROGRAMMAZIONE.TEMPLATES.ELIMINAZIONE-TEMPLATE"
				},

				/**
				 * Errore nella lettura del template
				 */
				ERRORE_LETTURA: {
					id: 1496,
					label: "intranet.PROGRAMMAZIONE.TEMPLATES.ERRORE-LETTURA"
				},

				/**
				 * ID
				 */
				ID: {
					id: 1497,
					label: "intranet.PROGRAMMAZIONE.TEMPLATES.ID"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 1498,
					label: "intranet.PROGRAMMAZIONE.TEMPLATES.NOME"
				},

				/**
				 * Nome / ID
				 */
				NOME_ID: {
					id: 1499,
					label: "intranet.PROGRAMMAZIONE.TEMPLATES.NOME-ID"
				},

				/**
				 * Nuovo template
				 */
				NUOVO_TEMPLATE: {
					id: 1500,
					label: "intranet.PROGRAMMAZIONE.TEMPLATES.NUOVO-TEMPLATE"
				},

				/**
				 * Nuovo template {n}
				 */
				NUOVO_TEMPLATE_N: {
					id: 1501,
					label: "intranet.PROGRAMMAZIONE.TEMPLATES.NUOVO-TEMPLATE-N"
				},

				/**
				 * Ricerca template
				 */
				RICERCA: {
					id: 1502,
					label: "intranet.PROGRAMMAZIONE.TEMPLATES.RICERCA"
				},

				/**
				 * Template salvato
				 */
				TEMPLATE_SALVATO: {
					id: 1503,
					label: "intranet.PROGRAMMAZIONE.TEMPLATES.TEMPLATE-SALVATO"
				},

				/**
				 * Templates
				 */
				TEMPLATES: {
					id: 1504,
					label: "intranet.PROGRAMMAZIONE.TEMPLATES.TEMPLATES"
				},

				/**
				 * Tipo Servizio
				 */
				TIPOSERVIZIO: {
					id: 1505,
					label: "intranet.PROGRAMMAZIONE.TEMPLATES.TIPOSERVIZIO"
				},

			},

			/**
			 * Content of path intranet.PROGRAMMAZIONE.TESTI
			 */
			TESTI: {
				/**
				 * Contatto
				 */
				CONTATTO: {
					id: 1506,
					label: "intranet.PROGRAMMAZIONE.TESTI.CONTATTO"
				},

				/**
				 * Copia testo {n}
				 */
				COPIA_TESTO_N: {
					id: 1507,
					label: "intranet.PROGRAMMAZIONE.TESTI.COPIA-TESTO-N"
				},

				/**
				 * Solo testi normali
				 */
				CORRISPONDENZA_NORMALI: {
					id: 1508,
					label: "intranet.PROGRAMMAZIONE.TESTI.CORRISPONDENZA-NORMALI"
				},

				/**
				 * Corrispondenza tag
				 */
				CORRISPONDENZA_TAG: {
					id: 1509,
					label: "intranet.PROGRAMMAZIONE.TESTI.CORRISPONDENZA-TAG"
				},

				/**
				 * Solo testi template
				 */
				CORRISPONDENZA_TEMPLATE: {
					id: 1510,
					label: "intranet.PROGRAMMAZIONE.TESTI.CORRISPONDENZA-TEMPLATE"
				},

				/**
				 * Tutti
				 */
				CORRISPONDENZA_TUTTI: {
					id: 1511,
					label: "intranet.PROGRAMMAZIONE.TESTI.CORRISPONDENZA-TUTTI"
				},

				/**
				 * Vuoi duplicare il testo ID={id}?
				 */
				DUPLICARE_TESTO: {
					id: 1512,
					label: "intranet.PROGRAMMAZIONE.TESTI.DUPLICARE-TESTO"
				},

				/**
				 * Vuoi eliminare il testo ID={id}?
				 */
				ELIMINAZIONE_TESTO: {
					id: 1513,
					label: "intranet.PROGRAMMAZIONE.TESTI.ELIMINAZIONE-TESTO"
				},

				/**
				 * Errore nella lettura del testo
				 */
				ERRORE_LETTURA: {
					id: 1514,
					label: "intranet.PROGRAMMAZIONE.TESTI.ERRORE-LETTURA"
				},

				/**
				 * ID
				 */
				ID: {
					id: 1515,
					label: "intranet.PROGRAMMAZIONE.TESTI.ID"
				},

				/**
				 * Inserisci di default nella sezione
				 */
				IS_DEFAULT: {
					id: 1516,
					label: "intranet.PROGRAMMAZIONE.TESTI.IS-DEFAULT"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 1517,
					label: "intranet.PROGRAMMAZIONE.TESTI.LUOGO"
				},

				/**
				 * Selezionare un servizio
				 */
				NO_SERVICE_NO_EDITOR: {
					id: 1518,
					label: "intranet.PROGRAMMAZIONE.TESTI.NO-SERVICE-NO-EDITOR"
				},

				/**
				 * Nuovo testo
				 */
				NUOVO_TESTO: {
					id: 1519,
					label: "intranet.PROGRAMMAZIONE.TESTI.NUOVO-TESTO"
				},

				/**
				 * Nuovo testo {n}
				 */
				NUOVO_TESTO_N: {
					id: 1520,
					label: "intranet.PROGRAMMAZIONE.TESTI.NUOVO-TESTO-N"
				},

				/**
				 * Ordine
				 */
				ORDINE: {
					id: 1521,
					label: "intranet.PROGRAMMAZIONE.TESTI.ORDINE"
				},

				/**
				 * Paese
				 */
				PAESE: {
					id: 1522,
					label: "intranet.PROGRAMMAZIONE.TESTI.PAESE"
				},

				/**
				 * al
				 */
				PARTENZA_AL: {
					id: 1523,
					label: "intranet.PROGRAMMAZIONE.TESTI.PARTENZA-AL"
				},

				/**
				 * Partenza dal
				 */
				PARTENZA_DAL: {
					id: 1524,
					label: "intranet.PROGRAMMAZIONE.TESTI.PARTENZA-DAL"
				},

				/**
				 * al
				 */
				PUBBLICA_AL: {
					id: 1525,
					label: "intranet.PROGRAMMAZIONE.TESTI.PUBBLICA-AL"
				},

				/**
				 * Pubblica dal
				 */
				PUBBLICA_DAL: {
					id: 1526,
					label: "intranet.PROGRAMMAZIONE.TESTI.PUBBLICA-DAL"
				},

				/**
				 * Pubblica in
				 */
				PUBBLICA_IN: {
					id: 1527,
					label: "intranet.PROGRAMMAZIONE.TESTI.PUBBLICA-IN"
				},

				/**
				 * Estrato conto
				 */
				PUBBLICA_IN_ESTRATTO_CONTO: {
					id: 1528,
					label: "intranet.PROGRAMMAZIONE.TESTI.PUBBLICA-IN-ESTRATTO-CONTO"
				},

				/**
				 * Offerta
				 */
				PUBBLICA_IN_OFFERTA: {
					id: 1529,
					label: "intranet.PROGRAMMAZIONE.TESTI.PUBBLICA-IN-OFFERTA"
				},

				/**
				 * Prev. cliente
				 */
				PUBBLICA_IN_PREVENTIVO: {
					id: 1530,
					label: "intranet.PROGRAMMAZIONE.TESTI.PUBBLICA-IN-PREVENTIVO"
				},

				/**
				 * Programma
				 */
				PUBBLICA_IN_PROGRAMMA: {
					id: 1531,
					label: "intranet.PROGRAMMAZIONE.TESTI.PUBBLICA-IN-PROGRAMMA"
				},

				/**
				 * Template
				 */
				PUBBLICA_IN_TEMPLATE: {
					id: 1532,
					label: "intranet.PROGRAMMAZIONE.TESTI.PUBBLICA-IN-TEMPLATE"
				},

				/**
				 * Voucher
				 */
				PUBBLICA_IN_VOUCER: {
					id: 1533,
					label: "intranet.PROGRAMMAZIONE.TESTI.PUBBLICA-IN-VOUCER"
				},

				/**
				 * Web
				 */
				PUBBLICA_IN_WEB: {
					id: 1534,
					label: "intranet.PROGRAMMAZIONE.TESTI.PUBBLICA-IN-WEB"
				},

				/**
				 * Qualsiasi contatto
				 */
				QUALSIASI_CONTATTO: {
					id: 1535,
					label: "intranet.PROGRAMMAZIONE.TESTI.QUALSIASI-CONTATTO"
				},

				/**
				 * Qualsiasi luogo
				 */
				QUALSIASI_LUOGO: {
					id: 1536,
					label: "intranet.PROGRAMMAZIONE.TESTI.QUALSIASI-LUOGO"
				},

				/**
				 * Qualsiasi luogo di destinazione
				 */
				QUALSIASI_LUOGO_DEST: {
					id: 1537,
					label: "intranet.PROGRAMMAZIONE.TESTI.QUALSIASI-LUOGO-DEST"
				},

				/**
				 * Qualsiasi paese
				 */
				QUALSIASI_PAESE: {
					id: 1538,
					label: "intranet.PROGRAMMAZIONE.TESTI.QUALSIASI-PAESE"
				},

				/**
				 * Qualsiasi servizio
				 */
				QUALSIASI_SERVIZIO: {
					id: 1539,
					label: "intranet.PROGRAMMAZIONE.TESTI.QUALSIASI-SERVIZIO"
				},

				/**
				 * Tutti i tipi di servizio
				 */
				QUALSIASI_TIPO_SERVIZIO: {
					id: 1540,
					label: "intranet.PROGRAMMAZIONE.TESTI.QUALSIASI-TIPO-SERVIZIO"
				},

				/**
				 * Qualunque sezione
				 */
				QUALUNQUE_SEZIONE: {
					id: 1541,
					label: "intranet.PROGRAMMAZIONE.TESTI.QUALUNQUE-SEZIONE"
				},

				/**
				 * Ricerca testo
				 */
				RICERCA: {
					id: 1542,
					label: "intranet.PROGRAMMAZIONE.TESTI.RICERCA"
				},

				/**
				 * Servizio
				 */
				SERVIZIO: {
					id: 1543,
					label: "intranet.PROGRAMMAZIONE.TESTI.SERVIZIO"
				},

				/**
				 * Ordine
				 */
				SORT_ORDER: {
					id: 1544,
					label: "intranet.PROGRAMMAZIONE.TESTI.SORT-ORDER"
				},

				/**
				 * Status
				 */
				STATUS: {
					id: 1545,
					label: "intranet.PROGRAMMAZIONE.TESTI.STATUS"
				},

				/**
				 * Tag
				 */
				TAG: {
					id: 1546,
					label: "intranet.PROGRAMMAZIONE.TESTI.TAG"
				},

				/**
				 * Template
				 */
				TEMPLATE: {
					id: 1547,
					label: "intranet.PROGRAMMAZIONE.TESTI.TEMPLATE"
				},

				/**
				 * Testi
				 */
				TESTI: {
					id: 1548,
					label: "intranet.PROGRAMMAZIONE.TESTI.TESTI"
				},

				/**
				 * Cerca testi per luoghi e servizi
				 */
				TESTI_LUOGHI_SERVIZI: {
					id: 1549,
					label: "intranet.PROGRAMMAZIONE.TESTI.TESTI-LUOGHI-SERVIZI"
				},

				/**
				 * Testo duplicato
				 */
				TESTO_DUPLICATO: {
					id: 1550,
					label: "intranet.PROGRAMMAZIONE.TESTI.TESTO-DUPLICATO"
				},

				/**
				 * Testo eliminato
				 */
				TESTO_ELIMINATO: {
					id: 1551,
					label: "intranet.PROGRAMMAZIONE.TESTI.TESTO-ELIMINATO"
				},

				/**
				 * Cerca testi generici
				 */
				TESTO_GENERICO: {
					id: 1552,
					label: "intranet.PROGRAMMAZIONE.TESTI.TESTO-GENERICO"
				},

				/**
				 * Testo salvato
				 */
				TESTO_SALVATO: {
					id: 1553,
					label: "intranet.PROGRAMMAZIONE.TESTI.TESTO-SALVATO"
				},

				/**
				 * Testo: {title}
				 */
				TESTO_TITOLO: {
					id: 1554,
					label: "intranet.PROGRAMMAZIONE.TESTI.TESTO-TITOLO"
				},

				/**
				 * tipo
				 */
				TIPO: {
					id: 1555,
					label: "intranet.PROGRAMMAZIONE.TESTI.TIPO"
				},

				/**
				 * Tipo servizio
				 */
				TIPO_SERVIZIO: {
					id: 1556,
					label: "intranet.PROGRAMMAZIONE.TESTI.TIPO-SERVIZIO"
				},

				/**
				 * Titolo Interno
				 */
				TITOLO: {
					id: 1557,
					label: "intranet.PROGRAMMAZIONE.TESTI.TITOLO"
				},

				/**
				 * Titolo Interno / ID
				 */
				TITOLO_ID: {
					id: 1558,
					label: "intranet.PROGRAMMAZIONE.TESTI.TITOLO-ID"
				},

				/**
				 * Traduzione completata
				 */
				TRADUZIONE_COMPLETATA: {
					id: 1559,
					label: "intranet.PROGRAMMAZIONE.TESTI.TRADUZIONE-COMPLETATA"
				},

				/**
				 * Tedesco
				 */
				TRADUZIONE_LINGUA_Deutsch: {
					id: 1560,
					label: "intranet.PROGRAMMAZIONE.TESTI.TRADUZIONE-LINGUA-Deutsch"
				},

				/**
				 * Inglese
				 */
				TRADUZIONE_LINGUA_ENGLISH: {
					id: 1561,
					label: "intranet.PROGRAMMAZIONE.TESTI.TRADUZIONE-LINGUA-ENGLISH"
				},

				/**
				 * Francese
				 */
				TRADUZIONE_LINGUA_Francais: {
					id: 1562,
					label: "intranet.PROGRAMMAZIONE.TESTI.TRADUZIONE-LINGUA-Francais"
				},

				/**
				 * Tutti gli stati
				 */
				TUTTI_STATUS: {
					id: 1563,
					label: "intranet.PROGRAMMAZIONE.TESTI.TUTTI-STATUS"
				},

				/**
				 * Nessun template
				 */
				TUTTI_TEMPLATE: {
					id: 1564,
					label: "intranet.PROGRAMMAZIONE.TESTI.TUTTI-TEMPLATE"
				},

				/**
				 * PROGRAMMAZIONE.TESTI.TESTO
				 */
				TESTO: {
					id: 6438,
					label: "intranet.PROGRAMMAZIONE.TESTI.TESTO"
				},

			},

			/**
			 * Content of path intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI
			 */
			TIPODATI_AGGIUNTIVI: {
				/**
				 * Copia dato agg. {n}
				 */
				COPIA_DATI_AGGIUNTIVO_N: {
					id: 1565,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.COPIA-DATI-AGGIUNTIVO-N"
				},

				/**
				 * Dato aggiuntivo eliminato
				 */
				DATO_ELIMINATO: {
					id: 1566,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.DATO-ELIMINATO"
				},

				/**
				 * Dato aggiuntivo salvato
				 */
				DATO_SALVATO: {
					id: 1567,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.DATO-SALVATO"
				},

				/**
				 * Vuoi eliminare il dato {nome}?
				 */
				ELIMINAZIONE_DATO: {
					id: 1568,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.ELIMINAZIONE-DATO"
				},

				/**
				 * Errore nel leggere i dati
				 */
				ERRORE_LETTURA: {
					id: 1569,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.ERRORE-LETTURA"
				},

				/**
				 * ID
				 */
				ID: {
					id: 1570,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.ID"
				},

				/**
				 * Mostra Desc
				 */
				MOSTRA_DESC: {
					id: 1571,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.MOSTRA_DESC"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 1572,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.NOME"
				},

				/**
				 * Tedesco
				 */
				NOME_DE: {
					id: 1573,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.NOME-DE"
				},

				/**
				 * Inglese
				 */
				NOME_EN: {
					id: 1574,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.NOME-EN"
				},

				/**
				 * Spagnolo
				 */
				NOME_ES: {
					id: 1575,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.NOME-ES"
				},

				/**
				 * Francese
				 */
				NOME_FR: {
					id: 1576,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.NOME-FR"
				},

				/**
				 * Nome/ID
				 */
				NOME_ID: {
					id: 1577,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.NOME-ID"
				},

				/**
				 * Nuova dato {n}
				 */
				NUOVO_DATO_N: {
					id: 1578,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.NUOVO-DATO-N"
				},

				/**
				 * Ordine
				 */
				ORDINE: {
					id: 1579,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.ORDINE"
				},

				/**
				 * Ricerca tipo dati aggiuntivi
				 */
				RICERCA: {
					id: 1580,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.RICERCA"
				},

				/**
				 * Ricerca
				 */
				RICERCA_LABEL: {
					id: 1581,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.RICERCA_LABEL"
				},

				/**
				 * Tipo servizio
				 */
				SERVIZIO: {
					id: 1582,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.SERVIZIO"
				},

				/**
				 * Standard
				 */
				STANDARD: {
					id: 1583,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.STANDARD"
				},

				/**
				 * Tipo
				 */
				TIPO: {
					id: 1584,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.TIPO"
				},

				/**
				 * Tipo Dati aggiuntivi
				 */
				TIPODATI_AGGIUNTIVI: {
					id: 1585,
					label: "intranet.PROGRAMMAZIONE.TIPODATI-AGGIUNTIVI.TIPODATI-AGGIUNTIVI"
				},

			},

			/**
			 * Content of path intranet.PROGRAMMAZIONE.PUBBLICAZIONI
			 */
			PUBBLICAZIONI: {
				/**
				 * Regole di pubblicazione
				 */
				TITLE: {
					id: 5979,
					label: "intranet.PROGRAMMAZIONE.PUBBLICAZIONI.TITLE"
				},

				/**
				 * Sito
				 */
				SITO: {
					id: 5980,
					label: "intranet.PROGRAMMAZIONE.PUBBLICAZIONI.SITO"
				},

				/**
				 * Pubblica
				 */
				PUBBLICA: {
					id: 5981,
					label: "intranet.PROGRAMMAZIONE.PUBBLICAZIONI.PUBBLICA"
				},

				/**
				 * Pubblica dal
				 */
				PUBBLICA_DAL: {
					id: 5982,
					label: "intranet.PROGRAMMAZIONE.PUBBLICAZIONI.PUBBLICA-DAL"
				},

				/**
				 * Pubblica al
				 */
				PUBBLICA_AL: {
					id: 5983,
					label: "intranet.PROGRAMMAZIONE.PUBBLICAZIONI.PUBBLICA-AL"
				},

				/**
				 * Sovrascrivi il markup
				 */
				MARKUP_OVERRIDE: {
					id: 5984,
					label: "intranet.PROGRAMMAZIONE.PUBBLICAZIONI.MARKUP-OVERRIDE"
				},

			},

		},

		/**
		 * Content of path intranet.SEARCH
		 */
		SEARCH: {
			/**
			 * Accordi
			 */
			ACCORDI: {
				id: 1586,
				label: "intranet.SEARCH.ACCORDI"
			},

			/**
			 * Articoli
			 */
			ARTICOLI: {
				id: 1587,
				label: "intranet.SEARCH.ARTICOLI"
			},

			/**
			 * Altri risultati con {term}
			 */
			CERCA_ALTRO: {
				id: 1588,
				label: "intranet.SEARCH.CERCA-ALTRO"
			},

			/**
			 * Contatti
			 */
			CONTATTI: {
				id: 1589,
				label: "intranet.SEARCH.CONTATTI"
			},

			/**
			 * Luoghi
			 */
			LUOGHI: {
				id: 1590,
				label: "intranet.SEARCH.LUOGHI"
			},

			/**
			 * Servizi
			 */
			SERVIZI: {
				id: 1591,
				label: "intranet.SEARCH.SERVIZI"
			},

		},

		/**
		 * Content of path intranet.STATISTICHE
		 */
		STATISTICHE: {
			/**
			 * Content of path intranet.STATISTICHE.MANAGE-STATS
			 */
			MANAGE_STATS: {
				/**
				 * Alias
				 */
				ALIAS: {
					id: 1592,
					label: "intranet.STATISTICHE.MANAGE-STATS.ALIAS"
				},

				/**
				 * Copia statistica {n}
				 */
				COPIA_STAT_N: {
					id: 1593,
					label: "intranet.STATISTICHE.MANAGE-STATS.COPIA-STAT-N"
				},

				/**
				 * Vuoi eliminare la statistica?
				 */
				ELIMINAZIONE_STAT: {
					id: 1594,
					label: "intranet.STATISTICHE.MANAGE-STATS.ELIMINAZIONE-STAT"
				},

				/**
				 * Errore nel leggere la statistica
				 */
				ERRORE_LETTURA: {
					id: 1595,
					label: "intranet.STATISTICHE.MANAGE-STATS.ERRORE-LETTURA"
				},

				/**
				 * ID
				 */
				ID: {
					id: 1596,
					label: "intranet.STATISTICHE.MANAGE-STATS.ID"
				},

				/**
				 * Gestione statistiche
				 */
				MANAGE_STATS: {
					id: 1597,
					label: "intranet.STATISTICHE.MANAGE-STATS.MANAGE-STATS"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 1598,
					label: "intranet.STATISTICHE.MANAGE-STATS.NOME"
				},

				/**
				 * Il nome è obbligatorio
				 */
				NOME_OBBLIGATORIO: {
					id: 1599,
					label: "intranet.STATISTICHE.MANAGE-STATS.NOME-OBBLIGATORIO"
				},

				/**
				 * Nuova statistica {n}
				 */
				NUOVA_STAT_N: {
					id: 1600,
					label: "intranet.STATISTICHE.MANAGE-STATS.NUOVA-STAT-N"
				},

				/**
				 * Query
				 */
				QUERY: {
					id: 1601,
					label: "intranet.STATISTICHE.MANAGE-STATS.QUERY"
				},

				/**
				 * Statistica eliminata
				 */
				STAT_ELIMINATA: {
					id: 1602,
					label: "intranet.STATISTICHE.MANAGE-STATS.STAT-ELIMINATA"
				},

				/**
				 * La statistica è stata salvata correttamente
				 */
				STATISTICA_SALVATA: {
					id: 1603,
					label: "intranet.STATISTICHE.MANAGE-STATS.STATISTICA-SALVATA"
				},

			},

			/**
			 * Mostra
			 */
			MOSTRA: {
				id: 1604,
				label: "intranet.STATISTICHE.MOSTRA"
			},

			/**
			 * Content of path intranet.STATISTICHE.OFFERTE-INCOMPLETE
			 */
			OFFERTE_INCOMPLETE: {
				/**
				 * Agenzia
				 */
				AGENZIA: {
					id: 1605,
					label: "intranet.STATISTICHE.OFFERTE-INCOMPLETE.AGENZIA"
				},

				/**
				 * Giorni partenza
				 */
				GIORNI_PARTENZA: {
					id: 1606,
					label: "intranet.STATISTICHE.OFFERTE-INCOMPLETE.GIORNI-PARTENZA"
				},

				/**
				 * ID offerta
				 */
				ID_OFFERTA: {
					id: 1607,
					label: "intranet.STATISTICHE.OFFERTE-INCOMPLETE.ID-OFFERTA"
				},

				/**
				 * Invia a...
				 */
				INVIA_A: {
					id: 1608,
					label: "intranet.STATISTICHE.OFFERTE-INCOMPLETE.INVIA-A"
				},

				/**
				 * Agenzia
				 */
				INVIA_A_AGENZIA: {
					id: 1609,
					label: "intranet.STATISTICHE.OFFERTE-INCOMPLETE.INVIA-A-AGENZIA"
				},

				/**
				 * Utente
				 */
				INVIA_A_UTENTE: {
					id: 1610,
					label: "intranet.STATISTICHE.OFFERTE-INCOMPLETE.INVIA-A-UTENTE"
				},

				/**
				 * Utente conferma
				 */
				INVIA_A_UTENTE_CONFERMA: {
					id: 1611,
					label: "intranet.STATISTICHE.OFFERTE-INCOMPLETE.INVIA-A-UTENTE-CONFERMA"
				},

				/**
				 * Utente preventivo
				 */
				INVIA_A_UTENTE_PREVENTIVO: {
					id: 1612,
					label: "intranet.STATISTICHE.OFFERTE-INCOMPLETE.INVIA-A-UTENTE-PREVENTIVO"
				},

				/**
				 * Partenza
				 */
				PARTENZA: {
					id: 1613,
					label: "intranet.STATISTICHE.OFFERTE-INCOMPLETE.PARTENZA"
				},

				/**
				 * Problemi clienti
				 */
				PROBLEMI_CLIENTI: {
					id: 1614,
					label: "intranet.STATISTICHE.OFFERTE-INCOMPLETE.PROBLEMI-CLIENTI"
				},

				/**
				 * Problemi offerta
				 */
				PROBLEMI_OFFERTA: {
					id: 1615,
					label: "intranet.STATISTICHE.OFFERTE-INCOMPLETE.PROBLEMI-OFFERTA"
				},

				/**
				 * Dati incompleti
				 */
				TITLE: {
					id: 1616,
					label: "intranet.STATISTICHE.OFFERTE-INCOMPLETE.TITLE"
				},

				/**
				 * Utente
				 */
				UTENTE: {
					id: 1617,
					label: "intranet.STATISTICHE.OFFERTE-INCOMPLETE.UTENTE"
				},

				/**
				 * Ut. Conferma
				 */
				UTENTE_CONFERMA: {
					id: 1618,
					label: "intranet.STATISTICHE.OFFERTE-INCOMPLETE.UTENTE-CONFERMA"
				},

				/**
				 * Ut. Preventivo
				 */
				UTENTE_PREVENTIVO: {
					id: 1619,
					label: "intranet.STATISTICHE.OFFERTE-INCOMPLETE.UTENTE-PREVENTIVO"
				},

			},

			/**
			 * Parametri
			 */
			PARAMETRI: {
				id: 1620,
				label: "intranet.STATISTICHE.PARAMETRI"
			},

			/**
			 * Date: è possibile inserirle anche nel formato gg/mm/aaaa, senza usare apici
			 */
			PARAMETRI_HINT: {
				id: 1621,
				label: "intranet.STATISTICHE.PARAMETRI-HINT"
			},

			/**
			 * Content of path intranet.STATISTICHE.PREVENTIVI
			 */
			PREVENTIVI: {
				/**
				 * Voucher
				 */
				ACCVOUCHER: {
					id: 1622,
					label: "intranet.STATISTICHE.PREVENTIVI.ACCVOUCHER"
				},

				/**
				 * Agenzia
				 */
				AGENZIA: {
					id: 1623,
					label: "intranet.STATISTICHE.PREVENTIVI.AGENZIA"
				},

				/**
				 * BlcPrz
				 */
				BLCPRZ: {
					id: 1624,
					label: "intranet.STATISTICHE.PREVENTIVI.BLCPRZ"
				},

				/**
				 * Cancellazione Adv
				 */
				CANCELLAZIONE_ADV: {
					id: 1625,
					label: "intranet.STATISTICHE.PREVENTIVI.CANCELLAZIONE-ADV"
				},

				/**
				 * Cat. ADV
				 */
				CATEGORIA_ADV: {
					id: 1626,
					label: "intranet.STATISTICHE.PREVENTIVI.CATEGORIA-ADV"
				},

				/**
				 * Città
				 */
				CITTA: {
					id: 1627,
					label: "intranet.STATISTICHE.PREVENTIVI.CITTA"
				},

				/**
				 * Commissione agenzia
				 */
				COMMISSIONE_AGENZIA: {
					id: 1628,
					label: "intranet.STATISTICHE.PREVENTIVI.COMMISSIONE-AGENZIA"
				},

				/**
				 * Conferma
				 */
				CONFERMA: {
					id: 1629,
					label: "intranet.STATISTICHE.PREVENTIVI.CONFERMA"
				},

				/**
				 * Conferma al
				 */
				CONFERMA_AL: {
					id: 1630,
					label: "intranet.STATISTICHE.PREVENTIVI.CONFERMA-AL"
				},

				/**
				 * Conferma dal
				 */
				CONFERMA_DAL: {
					id: 1631,
					label: "intranet.STATISTICHE.PREVENTIVI.CONFERMA-DAL"
				},

				/**
				 * Conferma
				 */
				CONFERMA_VENDITA: {
					id: 1632,
					label: "intranet.STATISTICHE.PREVENTIVI.CONFERMA-VENDITA"
				},

				/**
				 * Creazione
				 */
				CREAZIONE: {
					id: 1633,
					label: "intranet.STATISTICHE.PREVENTIVI.CREAZIONE"
				},

				/**
				 * Creazione al
				 */
				CREAZIONE_AL: {
					id: 1634,
					label: "intranet.STATISTICHE.PREVENTIVI.CREAZIONE-AL"
				},

				/**
				 * Creazione dal
				 */
				CREAZIONE_DAL: {
					id: 1635,
					label: "intranet.STATISTICHE.PREVENTIVI.CREAZIONE-DAL"
				},

				/**
				 * Descrizione
				 */
				DESCRIZIONE: {
					id: 1636,
					label: "intranet.STATISTICHE.PREVENTIVI.DESCRIZIONE"
				},

				/**
				 * Destinazione
				 */
				DESTINAZIONE: {
					id: 1637,
					label: "intranet.STATISTICHE.PREVENTIVI.DESTINAZIONE"
				},

				/**
				 * Diritti agenzia
				 */
				DIRITTI_AGENZIA: {
					id: 1638,
					label: "intranet.STATISTICHE.PREVENTIVI.DIRITTI-AGENZIA"
				},

				/**
				 * Gest. conf.
				 */
				GEST_CONFERMA: {
					id: 1639,
					label: "intranet.STATISTICHE.PREVENTIVI.GEST-CONFERMA"
				},

				/**
				 * Gest. prev.
				 */
				GEST_PREVENTIVO: {
					id: 1640,
					label: "intranet.STATISTICHE.PREVENTIVI.GEST-PREVENTIVO"
				},

				/**
				 * Gruppo
				 */
				GRUPPO: {
					id: 1641,
					label: "intranet.STATISTICHE.PREVENTIVI.GRUPPO"
				},

				/**
				 * ID
				 */
				ID: {
					id: 1642,
					label: "intranet.STATISTICHE.PREVENTIVI.ID"
				},

				/**
				 * ID offerta
				 */
				ID_OFFERTA: {
					id: 1643,
					label: "intranet.STATISTICHE.PREVENTIVI.ID-OFFERTA"
				},

				/**
				 * Incentivato
				 */
				INCENTIVATO: {
					id: 1644,
					label: "intranet.STATISTICHE.PREVENTIVI.INCENTIVATO"
				},

				/**
				 * Info. agenzia
				 */
				INFO_AGENZIA: {
					id: 1645,
					label: "intranet.STATISTICHE.PREVENTIVI.INFO-AGENZIA"
				},

				/**
				 * Info Ancillary
				 */
				INFO_ANCILLARY: {
					id: 1646,
					label: "intranet.STATISTICHE.PREVENTIVI.INFO-ANCILLARY"
				},

				/**
				 * Info Costi
				 */
				INFO_COSTI: {
					id: 1647,
					label: "intranet.STATISTICHE.PREVENTIVI.INFO-COSTI"
				},

				/**
				 * Info viaggio
				 */
				INFO_VIAGGIO: {
					id: 1648,
					label: "intranet.STATISTICHE.PREVENTIVI.INFO-VIAGGIO"
				},

				/**
				 * Int 100K
				 */
				INT100: {
					id: 1649,
					label: "intranet.STATISTICHE.PREVENTIVI.INT100"
				},

				/**
				 * Int 300K
				 */
				INT300: {
					id: 1650,
					label: "intranet.STATISTICHE.PREVENTIVI.INT300"
				},

				/**
				 * Int 500K
				 */
				INT500: {
					id: 1651,
					label: "intranet.STATISTICHE.PREVENTIVI.INT500"
				},

				/**
				 * Int 1000K
				 */
				INT1000: {
					id: 1652,
					label: "intranet.STATISTICHE.PREVENTIVI.INT1000"
				},

				/**
				 * Int. comm.
				 */
				INTCOMM: {
					id: 1653,
					label: "intranet.STATISTICHE.PREVENTIVI.INTCOMM"
				},

				/**
				 * Mail ADV
				 */
				MAIL_ADV: {
					id: 1654,
					label: "intranet.STATISTICHE.PREVENTIVI.MAIL-ADV"
				},

				/**
				 * Marg. netto
				 */
				MARGINE_NETTO: {
					id: 1655,
					label: "intranet.STATISTICHE.PREVENTIVI.MARGINE-NETTO"
				},

				/**
				 * Network
				 */
				NETWORK: {
					id: 1656,
					label: "intranet.STATISTICHE.PREVENTIVI.NETWORK"
				},

				/**
				 * Pag
				 */
				PAG: {
					id: 1657,
					label: "intranet.STATISTICHE.PREVENTIVI.PAG"
				},

				/**
				 * Partenze
				 */
				PARTENZA: {
					id: 1658,
					label: "intranet.STATISTICHE.PREVENTIVI.PARTENZA"
				},

				/**
				 * Partenza al
				 */
				PARTENZA_AL: {
					id: 1659,
					label: "intranet.STATISTICHE.PREVENTIVI.PARTENZA-AL"
				},

				/**
				 * Partenza dal
				 */
				PARTENZA_DAL: {
					id: 1660,
					label: "intranet.STATISTICHE.PREVENTIVI.PARTENZA-DAL"
				},

				/**
				 * N. Pax
				 */
				PAX: {
					id: 1661,
					label: "intranet.STATISTICHE.PREVENTIVI.PAX"
				},

				/**
				 * % commissione
				 */
				PERC_COMMISSIONE: {
					id: 1662,
					label: "intranet.STATISTICHE.PREVENTIVI.PERC-COMMISSIONE"
				},

				/**
				 * Preventivi
				 */
				PREVENTIVI: {
					id: 1663,
					label: "intranet.STATISTICHE.PREVENTIVI.PREVENTIVI"
				},

				/**
				 * Provincia
				 */
				PROVINCIA: {
					id: 1664,
					label: "intranet.STATISTICHE.PREVENTIVI.PROVINCIA"
				},

				/**
				 * QtApPr
				 */
				QTAPPR: {
					id: 1665,
					label: "intranet.STATISTICHE.PREVENTIVI.QTAPPR"
				},

				/**
				 * Realizzo
				 */
				REALIZZO: {
					id: 1666,
					label: "intranet.STATISTICHE.PREVENTIVI.REALIZZO"
				},

				/**
				 * Regione
				 */
				REGIONE: {
					id: 1667,
					label: "intranet.STATISTICHE.PREVENTIVI.REGIONE"
				},

				/**
				 * Tipo prev.
				 */
				TIPO_PREVENTIVO: {
					id: 1668,
					label: "intranet.STATISTICHE.PREVENTIVI.TIPO-PREVENTIVO"
				},

				/**
				 * Tipo
				 */
				TIPO_V: {
					id: 1669,
					label: "intranet.STATISTICHE.PREVENTIVI.TIPO-V"
				},

				/**
				 * Totale
				 */
				TOTALE: {
					id: 1670,
					label: "intranet.STATISTICHE.PREVENTIVI.TOTALE"
				},

				/**
				 * Vendite
				 */
				VENDITE: {
					id: 1671,
					label: "intranet.STATISTICHE.PREVENTIVI.VENDITE"
				},

				/**
				 * WCYS
				 */
				WCYS: {
					id: 1672,
					label: "intranet.STATISTICHE.PREVENTIVI.WCYS"
				},

				/**
				 * WCYS Plus
				 */
				WCYS_PLUS: {
					id: 1673,
					label: "intranet.STATISTICHE.PREVENTIVI.WCYS-PLUS"
				},

			},

			/**
			 * Content of path intranet.STATISTICHE.REPORTS
			 */
			REPORTS: {
				/**
				 * Cancella dati
				 */
				CANCELLA_DATI: {
					id: 1674,
					label: "intranet.STATISTICHE.REPORTS.CANCELLA-DATI"
				},

				/**
				 * Carica dati
				 */
				CARICA_DATI: {
					id: 1675,
					label: "intranet.STATISTICHE.REPORTS.CARICA-DATI"
				},

				/**
				 * Vuoi eliminare i valori salvati?
				 */
				ELIMINAZIONE_VALORI_SALVATI: {
					id: 1676,
					label: "intranet.STATISTICHE.REPORTS.ELIMINAZIONE-VALORI-SALVATI"
				},

				/**
				 * Genera report
				 */
				GENERA_REPORT: {
					id: 1677,
					label: "intranet.STATISTICHE.REPORTS.GENERA-REPORT"
				},

				/**
				 * ID
				 */
				ID: {
					id: 1678,
					label: "intranet.STATISTICHE.REPORTS.ID"
				},

				/**
				 * Invio automatico
				 */
				INVIO_AUTO: {
					id: 1679,
					label: "intranet.STATISTICHE.REPORTS.INVIO-AUTO"
				},

				/**
				 * Nessun dato salvato. Premere 'Salva dati' per salvare i dati in lista...
				 */
				NO_DATI_SALVATI: {
					id: 1680,
					label: "intranet.STATISTICHE.REPORTS.NO-DATI-SALVATI"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 1681,
					label: "intranet.STATISTICHE.REPORTS.NOME"
				},

				/**
				 * Nome report
				 */
				NOME_REPORT: {
					id: 1682,
					label: "intranet.STATISTICHE.REPORTS.NOME-REPORT"
				},

				/**
				 * Orario invio
				 */
				ORARIO_INVIO: {
					id: 1683,
					label: "intranet.STATISTICHE.REPORTS.ORARIO-INVIO"
				},

				/**
				 * Periodicità
				 */
				PERIODICITA: {
					id: 1684,
					label: "intranet.STATISTICHE.REPORTS.PERIODICITA"
				},

				/**
				 * Reports
				 */
				REPORTS: {
					id: 1685,
					label: "intranet.STATISTICHE.REPORTS.REPORTS"
				},

				/**
				 * Salva dati
				 */
				SALVA_DATI: {
					id: 1686,
					label: "intranet.STATISTICHE.REPORTS.SALVA-DATI"
				},

				/**
				 * Carica valori salvati
				 */
				TITOLI_CARICA_VALORI: {
					id: 1687,
					label: "intranet.STATISTICHE.REPORTS.TITOLI-CARICA-VALORI"
				},

				/**
				 * Salvataggio valori
				 */
				TITOLI_SALVA_VALORI: {
					id: 1688,
					label: "intranet.STATISTICHE.REPORTS.TITOLI-SALVA-VALORI"
				},

				/**
				 * Ultimo invio
				 */
				ULTIMO_INVIO: {
					id: 1689,
					label: "intranet.STATISTICHE.REPORTS.ULTIMO-INVIO"
				},

				/**
				 * Nome
				 */
				VALORI_NOME: {
					id: 1690,
					label: "intranet.STATISTICHE.REPORTS.VALORI-NOME"
				},

				/**
				 * Vedi ultima email
				 */
				VEDI_ULTIMA_MAIL: {
					id: 1691,
					label: "intranet.STATISTICHE.REPORTS.VEDI-ULTIMA-MAIL"
				},

			},

			/**
			 * Statistica
			 */
			STATISTICA: {
				id: 1692,
				label: "intranet.STATISTICHE.STATISTICA"
			},

			/**
			 * Statistiche
			 */
			STATISTICHE: {
				id: 1693,
				label: "intranet.STATISTICHE.STATISTICHE"
			},

			/**
			 * Content of path intranet.STATISTICHE.VENDITE
			 */
			VENDITE: {
				/**
				 * al
				 */
				AL: {
					id: 1694,
					label: "intranet.STATISTICHE.VENDITE.AL"
				},

				/**
				 * Anno prec.
				 */
				ANNO_PRECEDENTE: {
					id: 1695,
					label: "intranet.STATISTICHE.VENDITE.ANNO-PRECEDENTE"
				},

				/**
				 * CAP
				 */
				CAP: {
					id: 1696,
					label: "intranet.STATISTICHE.VENDITE.CAP"
				},

				/**
				 * Categoria
				 */
				CATEGORIA: {
					id: 1697,
					label: "intranet.STATISTICHE.VENDITE.CATEGORIA"
				},

				/**
				 * Città
				 */
				CITTA: {
					id: 1698,
					label: "intranet.STATISTICHE.VENDITE.CITTA"
				},

				/**
				 * Conferma
				 */
				CONFERMA_VENDITA: {
					id: 1699,
					label: "intranet.STATISTICHE.VENDITE.CONFERMA-VENDITA"
				},

				/**
				 * Creazione
				 */
				CREAZIONE_VENDITA: {
					id: 1700,
					label: "intranet.STATISTICHE.VENDITE.CREAZIONE-VENDITA"
				},

				/**
				 * dal
				 */
				DAL: {
					id: 1701,
					label: "intranet.STATISTICHE.VENDITE.DAL"
				},

				/**
				 * Descrizione
				 */
				DESCRIZIONE_VENDITA: {
					id: 1702,
					label: "intranet.STATISTICHE.VENDITE.DESCRIZIONE-VENDITA"
				},

				/**
				 * Gruppo
				 */
				GRUPPO: {
					id: 1703,
					label: "intranet.STATISTICHE.VENDITE.GRUPPO"
				},

				/**
				 * ID
				 */
				ID: {
					id: 1704,
					label: "intranet.STATISTICHE.VENDITE.ID"
				},

				/**
				 * Impiegato
				 */
				IMPIEGATO: {
					id: 1705,
					label: "intranet.STATISTICHE.VENDITE.IMPIEGATO"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 1706,
					label: "intranet.STATISTICHE.VENDITE.LUOGO"
				},

				/**
				 * Media
				 */
				MEDIA: {
					id: 1707,
					label: "intranet.STATISTICHE.VENDITE.MEDIA"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 1708,
					label: "intranet.STATISTICHE.VENDITE.NOME"
				},

				/**
				 * % N
				 */
				NPAXVENDUTE: {
					id: 1709,
					label: "intranet.STATISTICHE.VENDITE.NPAXVENDUTE"
				},

				/**
				 * N. Prev
				 */
				NPREVENTIVI: {
					id: 1710,
					label: "intranet.STATISTICHE.VENDITE.NPREVENTIVI"
				},

				/**
				 * N. Pax vend
				 */
				NVENDUTE: {
					id: 1711,
					label: "intranet.STATISTICHE.VENDITE.NVENDUTE"
				},

				/**
				 * Operatore
				 */
				OPERATORE: {
					id: 1712,
					label: "intranet.STATISTICHE.VENDITE.OPERATORE"
				},

				/**
				 * Partenza
				 */
				PARTENZA: {
					id: 1713,
					label: "intranet.STATISTICHE.VENDITE.PARTENZA"
				},

				/**
				 * Partenza al
				 */
				PARTENZA_AL: {
					id: 1714,
					label: "intranet.STATISTICHE.VENDITE.PARTENZA-AL"
				},

				/**
				 * Partenza dal
				 */
				PARTENZA_DAL: {
					id: 1715,
					label: "intranet.STATISTICHE.VENDITE.PARTENZA-DAL"
				},

				/**
				 * Partenze
				 */
				PARTENZE: {
					id: 1716,
					label: "intranet.STATISTICHE.VENDITE.PARTENZE"
				},

				/**
				 * prev/vend
				 */
				PREV_VEND_PAX: {
					id: 1717,
					label: "intranet.STATISTICHE.VENDITE.PREV-VEND-PAX"
				},

				/**
				 * Preventivi
				 */
				PREVENTIVI: {
					id: 1718,
					label: "intranet.STATISTICHE.VENDITE.PREVENTIVI"
				},

				/**
				 * Preventivi al
				 */
				PREVENTIVI_AL: {
					id: 1719,
					label: "intranet.STATISTICHE.VENDITE.PREVENTIVI-AL"
				},

				/**
				 * Preventivi dal
				 */
				PREVENTIVI_DAL: {
					id: 1720,
					label: "intranet.STATISTICHE.VENDITE.PREVENTIVI-DAL"
				},

				/**
				 * Provincia
				 */
				PROVINCIA: {
					id: 1721,
					label: "intranet.STATISTICHE.VENDITE.PROVINCIA"
				},

				/**
				 * Regione
				 */
				REGIONE: {
					id: 1722,
					label: "intranet.STATISTICHE.VENDITE.REGIONE"
				},

				/**
				 * Totale
				 */
				TOTALE: {
					id: 1723,
					label: "intranet.STATISTICHE.VENDITE.TOTALE"
				},

				/**
				 * Totale numero preventivi
				 */
				TOTALE_N_PREVENTIVI: {
					id: 1724,
					label: "intranet.STATISTICHE.VENDITE.TOTALE-N-PREVENTIVI"
				},

				/**
				 * Totale numero vendite
				 */
				TOTALE_N_VENDITE: {
					id: 1725,
					label: "intranet.STATISTICHE.VENDITE.TOTALE-N-VENDITE"
				},

				/**
				 * Totale preventivi
				 */
				TOTALE_PREVENTIVI: {
					id: 1726,
					label: "intranet.STATISTICHE.VENDITE.TOTALE-PREVENTIVI"
				},

				/**
				 * Totale vendite
				 */
				TOTALE_VENDITE: {
					id: 1727,
					label: "intranet.STATISTICHE.VENDITE.TOTALE-VENDITE"
				},

				/**
				 * Ultimo Prev./Conf.
				 */
				ULTIMO_PREV_CONF: {
					id: 1728,
					label: "intranet.STATISTICHE.VENDITE.ULTIMO-PREV-CONF"
				},

				/**
				 * Utente
				 */
				UTENTE: {
					id: 1729,
					label: "intranet.STATISTICHE.VENDITE.UTENTE"
				},

				/**
				 * Vendite
				 */
				VENDITE: {
					id: 1730,
					label: "intranet.STATISTICHE.VENDITE.VENDITE"
				},

				/**
				 * % Lit.
				 */
				VENDITE_PC: {
					id: 1731,
					label: "intranet.STATISTICHE.VENDITE.VENDITE-PC"
				},

				/**
				 * Vendite e preventivi
				 */
				VENDITE_PREVENTIVI: {
					id: 1732,
					label: "intranet.STATISTICHE.VENDITE.VENDITE-PREVENTIVI"
				},

			},

			/**
			 * Content of path intranet.STATISTICHE.VENDITE-MESE
			 */
			VENDITE_MESE: {
				/**
				 * Partenza fino al
				 */
				DATA_LIMITE: {
					id: 1733,
					label: "intranet.STATISTICHE.VENDITE-MESE.DATA-LIMITE"
				},

				/**
				 * Data obbligatoria
				 */
				DATA_LIMITE_OBBLIGATORIA: {
					id: 1734,
					label: "intranet.STATISTICHE.VENDITE-MESE.DATA-LIMITE-OBBLIGATORIA"
				},

				/**
				 * Tour Operator
				 */
				OPERATORE: {
					id: 1735,
					label: "intranet.STATISTICHE.VENDITE-MESE.OPERATORE"
				},

				/**
				 * Percentuale partito
				 */
				PERCENTUALE_PARTITO: {
					id: 1736,
					label: "intranet.STATISTICHE.VENDITE-MESE.PERCENTUALE-PARTITO"
				},

				/**
				 * Percentuale preventivi
				 */
				PERCENTUALE_PREVENTIVI: {
					id: 1737,
					label: "intranet.STATISTICHE.VENDITE-MESE.PERCENTUALE-PREVENTIVI"
				},

				/**
				 * Percentuale vendite
				 */
				PERCENTUALE_VENDITE: {
					id: 1738,
					label: "intranet.STATISTICHE.VENDITE-MESE.PERCENTUALE-VENDITE"
				},

				/**
				 * Vendite per mese
				 */
				VENDITE_MESE: {
					id: 1739,
					label: "intranet.STATISTICHE.VENDITE-MESE.VENDITE-MESE"
				},

			},

			/**
			 * STATISTICHE.REPORT.REPORTS
			 */
			REPORT: {
				id: 6552,
				label: "intranet.STATISTICHE.REPORT.REPORTS"
			},

		},

		/**
		 * Content of path intranet.TRADUZIONI
		 */
		TRADUZIONI: {
			/**
			 * Content of path intranet.TRADUZIONI.ARTICOLI
			 */
			ARTICOLI: {
				/**
				 * Aggiungi traduzione
				 */
				ADD_TRANS: {
					id: 1740,
					label: "intranet.TRADUZIONI.ARTICOLI.ADD-TRANS"
				},

				/**
				 * Scegli una lingua e premi il tasto aggiungi
				 */
				ADD_TRANS_INFO: {
					id: 1741,
					label: "intranet.TRADUZIONI.ARTICOLI.ADD-TRANS-INFO"
				},

				/**
				 * Traduzione
				 */
				ARTICOLI: {
					id: 1742,
					label: "intranet.TRADUZIONI.ARTICOLI.ARTICOLI"
				},

				/**
				 * Tradotti by AI
				 */
				AUTOMATICI: {
					id: 1743,
					label: "intranet.TRADUZIONI.ARTICOLI.AUTOMATICI"
				},

				/**
				 * ID
				 */
				ID: {
					id: 1744,
					label: "intranet.TRADUZIONI.ARTICOLI.ID"
				},

				/**
				 * Lingua
				 */
				LINGUA: {
					id: 1745,
					label: "intranet.TRADUZIONI.ARTICOLI.LINGUA"
				},

				/**
				 * Lingue disponibili
				 */
				LINGUE: {
					id: 1746,
					label: "intranet.TRADUZIONI.ARTICOLI.LINGUE"
				},

				/**
				 * Nessuna traduzione disponibile
				 */
				NO_TRANS_INFO: {
					id: 1747,
					label: "intranet.TRADUZIONI.ARTICOLI.NO-TRANS-INFO"
				},

				/**
				 * Non confermati
				 */
				NON_CONFERMATI: {
					id: 1748,
					label: "intranet.TRADUZIONI.ARTICOLI.NON-CONFERMATI"
				},

				/**
				 * Non tradotti in
				 */
				NON_TRADOTTI_IN: {
					id: 1749,
					label: "intranet.TRADUZIONI.ARTICOLI.NON-TRADOTTI-IN"
				},

				/**
				 * Parole
				 */
				NUMERO_PAROLE: {
					id: 1750,
					label: "intranet.TRADUZIONI.ARTICOLI.NUMERO-PAROLE"
				},

				/**
				 * Ricerca articoli
				 */
				RICERCA: {
					id: 1751,
					label: "intranet.TRADUZIONI.ARTICOLI.RICERCA"
				},

				/**
				 * Titolo
				 */
				TITOLO: {
					id: 1752,
					label: "intranet.TRADUZIONI.ARTICOLI.TITOLO"
				},

				/**
				 * Titolo / ID
				 */
				TITOLO_ID: {
					id: 1753,
					label: "intranet.TRADUZIONI.ARTICOLI.TITOLO-ID"
				},

				/**
				 * Confermata
				 */
				TRAD_CONFERMATA: {
					id: 1754,
					label: "intranet.TRADUZIONI.ARTICOLI.TRAD-CONFERMATA"
				},

				/**
				 * Segna una traduzione come confermata se credi che vada bene!
				 */
				TRAD_CONFERMATA_HELP: {
					id: 1755,
					label: "intranet.TRADUZIONI.ARTICOLI.TRAD-CONFERMATA-HELP"
				},

				/**
				 * Traduzione automatica in {l}
				 */
				TRADUZIONE_AUTOMATICA: {
					id: 1756,
					label: "intranet.TRADUZIONI.ARTICOLI.TRADUZIONE-AUTOMATICA"
				},

				/**
				 * Ultimo aggiornamento lingua
				 */
				ULTIMO_AGG_LINGUA: {
					id: 1757,
					label: "intranet.TRADUZIONI.ARTICOLI.ULTIMO-AGG-LINGUA"
				},

				/**
				 * Ultimo aggiornamento
				 */
				ULTIMO_AGGIORNAMENTO: {
					id: 1758,
					label: "intranet.TRADUZIONI.ARTICOLI.ULTIMO-AGGIORNAMENTO"
				},

				/**
				 * Da tradurre in
				 */
				DA_TRADURRE_IN: {
					id: 6020,
					label: "intranet.TRADUZIONI.ARTICOLI.DA-TRADURRE-IN"
				},

			},

			/**
			 * Content of path intranet.TRADUZIONI.FRASI
			 */
			FRASI: {
				/**
				 * Aggiungi traduzione
				 */
				ADD_TRANS: {
					id: 1759,
					label: "intranet.TRADUZIONI.FRASI.ADD-TRANS"
				},

				/**
				 * Scegli una lingua e premi il tasto aggiungi
				 */
				ADD_TRANS_INFO: {
					id: 1760,
					label: "intranet.TRADUZIONI.FRASI.ADD-TRANS-INFO"
				},

				/**
				 * Tradotte by AI
				 */
				AUTOMATICI: {
					id: 1761,
					label: "intranet.TRADUZIONI.FRASI.AUTOMATICI"
				},

				/**
				 * Traduzione
				 */
				FRASI: {
					id: 1762,
					label: "intranet.TRADUZIONI.FRASI.FRASI"
				},

				/**
				 * ID
				 */
				ID: {
					id: 1763,
					label: "intranet.TRADUZIONI.FRASI.ID"
				},

				/**
				 * Lingua
				 */
				LINGUA: {
					id: 1764,
					label: "intranet.TRADUZIONI.FRASI.LINGUA"
				},

				/**
				 * Lingue disponibili
				 */
				LINGUE: {
					id: 1765,
					label: "intranet.TRADUZIONI.FRASI.LINGUE"
				},

				/**
				 * Nessuna traduzione disponibile
				 */
				NO_TRANS_INFO: {
					id: 1766,
					label: "intranet.TRADUZIONI.FRASI.NO-TRANS-INFO"
				},

				/**
				 * Non confermate
				 */
				NON_CONFERMATI: {
					id: 1767,
					label: "intranet.TRADUZIONI.FRASI.NON-CONFERMATI"
				},

				/**
				 * Non tradotte in
				 */
				NON_TRADOTTI_IN: {
					id: 1768,
					label: "intranet.TRADUZIONI.FRASI.NON-TRADOTTI-IN"
				},

				/**
				 * Parole
				 */
				NUMERO_PAROLE: {
					id: 1769,
					label: "intranet.TRADUZIONI.FRASI.NUMERO-PAROLE"
				},

				/**
				 * Ricerca frasi
				 */
				RICERCA: {
					id: 1770,
					label: "intranet.TRADUZIONI.FRASI.RICERCA"
				},

				/**
				 * Titolo
				 */
				TITOLO: {
					id: 1771,
					label: "intranet.TRADUZIONI.FRASI.TITOLO"
				},

				/**
				 * Titolo / ID
				 */
				TITOLO_ID: {
					id: 1772,
					label: "intranet.TRADUZIONI.FRASI.TITOLO-ID"
				},

				/**
				 * Confermata
				 */
				TRAD_CONFERMATA: {
					id: 1773,
					label: "intranet.TRADUZIONI.FRASI.TRAD-CONFERMATA"
				},

				/**
				 * Segna una traduzione come confermata se credi che vada bene!
				 */
				TRAD_CONFERMATA_HELP: {
					id: 1774,
					label: "intranet.TRADUZIONI.FRASI.TRAD-CONFERMATA-HELP"
				},

				/**
				 * Traduzione automatica in {l}
				 */
				TRADUZIONE_AUTOMATICA: {
					id: 1775,
					label: "intranet.TRADUZIONI.FRASI.TRADUZIONE-AUTOMATICA"
				},

				/**
				 * Ultimo aggiornamento lingua
				 */
				ULTIMO_AGG_LINGUA: {
					id: 1776,
					label: "intranet.TRADUZIONI.FRASI.ULTIMO-AGG-LINGUA"
				},

				/**
				 * Ultimo aggiornamento
				 */
				ULTIMO_AGGIORNAMENTO: {
					id: 1777,
					label: "intranet.TRADUZIONI.FRASI.ULTIMO-AGGIORNAMENTO"
				},

				/**
				 * Da tradurre in
				 */
				DA_TRADURRE_IN: {
					id: 6021,
					label: "intranet.TRADUZIONI.FRASI.DA-TRADURRE-IN"
				},

			},

			/**
			 * Content of path intranet.TRADUZIONI.LUOGHI
			 */
			LUOGHI: {
				/**
				 * Aggiungi traduzione
				 */
				ADD_TRANS: {
					id: 1778,
					label: "intranet.TRADUZIONI.LUOGHI.ADD-TRANS"
				},

				/**
				 * Scegli una lingua e premi il tasto aggiungi
				 */
				ADD_TRANS_INFO: {
					id: 1779,
					label: "intranet.TRADUZIONI.LUOGHI.ADD-TRANS-INFO"
				},

				/**
				 * ID
				 */
				ID: {
					id: 1780,
					label: "intranet.TRADUZIONI.LUOGHI.ID"
				},

				/**
				 * Lingue
				 */
				LINGUE: {
					id: 1781,
					label: "intranet.TRADUZIONI.LUOGHI.LINGUE"
				},

				/**
				 * Luoghi
				 */
				LUOGHI: {
					id: 1782,
					label: "intranet.TRADUZIONI.LUOGHI.LUOGHI"
				},

				/**
				 * Luogo salvato correttamente
				 */
				LUOGO_SALVATO: {
					id: 1783,
					label: "intranet.TRADUZIONI.LUOGHI.LUOGO-SALVATO"
				},

				/**
				 * Nessuna traduzione disponibile
				 */
				NO_TRANS_INFO: {
					id: 1784,
					label: "intranet.TRADUZIONI.LUOGHI.NO-TRANS-INFO"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 1785,
					label: "intranet.TRADUZIONI.LUOGHI.NOME"
				},

				/**
				 * Francese
				 */
				NOME_FRANCESE: {
					id: 1786,
					label: "intranet.TRADUZIONI.LUOGHI.NOME-FRANCESE"
				},

				/**
				 * Inglese
				 */
				NOME_INGLESE: {
					id: 1787,
					label: "intranet.TRADUZIONI.LUOGHI.NOME-INGLESE"
				},

				/**
				 * Olandese
				 */
				NOME_OLANDESE: {
					id: 1788,
					label: "intranet.TRADUZIONI.LUOGHI.NOME-OLANDESE"
				},

				/**
				 * Tedesco
				 */
				NOME_TEDESCO: {
					id: 1789,
					label: "intranet.TRADUZIONI.LUOGHI.NOME-TEDESCO"
				},

				/**
				 * Parole
				 */
				NUMERO_PAROLE: {
					id: 1790,
					label: "intranet.TRADUZIONI.LUOGHI.NUMERO-PAROLE"
				},

				/**
				 * Ricerca luoghi
				 */
				RICERCA: {
					id: 1791,
					label: "intranet.TRADUZIONI.LUOGHI.RICERCA"
				},

				/**
				 * Confermata
				 */
				TRAD_CONFERMATA: {
					id: 1792,
					label: "intranet.TRADUZIONI.LUOGHI.TRAD-CONFERMATA"
				},

				/**
				 * Segna una traduzione come confermata se credi che vada bene!
				 */
				TRAD_CONFERMATA_HELP: {
					id: 1793,
					label: "intranet.TRADUZIONI.LUOGHI.TRAD-CONFERMATA-HELP"
				},

				/**
				 * Traduzione automatica in {l}
				 */
				TRADUZIONE_AUTOMATICA: {
					id: 1794,
					label: "intranet.TRADUZIONI.LUOGHI.TRADUZIONE-AUTOMATICA"
				},

				/**
				 * Ultimo aggiornamento
				 */
				ULTIMO_AGGIORNAMENTO: {
					id: 1795,
					label: "intranet.TRADUZIONI.LUOGHI.ULTIMO-AGGIORNAMENTO"
				},

			},

			/**
			 * Content of path intranet.TRADUZIONI.SERVIZI
			 */
			SERVIZI: {
				/**
				 * ID
				 */
				ID: {
					id: 1796,
					label: "intranet.TRADUZIONI.SERVIZI.ID"
				},

				/**
				 * Lingue
				 */
				LINGUE: {
					id: 1797,
					label: "intranet.TRADUZIONI.SERVIZI.LINGUE"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 1798,
					label: "intranet.TRADUZIONI.SERVIZI.NOME"
				},

				/**
				 * Nome Francese
				 */
				NOME_FRANCESE: {
					id: 1799,
					label: "intranet.TRADUZIONI.SERVIZI.NOME-FRANCESE"
				},

				/**
				 * Nome inglese
				 */
				NOME_INGLESE: {
					id: 1800,
					label: "intranet.TRADUZIONI.SERVIZI.NOME-INGLESE"
				},

				/**
				 * Nome Olandese
				 */
				NOME_OLANDESE: {
					id: 1801,
					label: "intranet.TRADUZIONI.SERVIZI.NOME-OLANDESE"
				},

				/**
				 * Nome Tedesco
				 */
				NOME_TEDESCO: {
					id: 1802,
					label: "intranet.TRADUZIONI.SERVIZI.NOME-TEDESCO"
				},

				/**
				 * Dati Prodotto
				 */
				RICERCA: {
					id: 1803,
					label: "intranet.TRADUZIONI.SERVIZI.RICERCA"
				},

				/**
				 * Servizi
				 */
				SERVIZI: {
					id: 1804,
					label: "intranet.TRADUZIONI.SERVIZI.SERVIZI"
				},

				/**
				 * Ultimo aggiornamento lingua
				 */
				ULTIMO_AGG_LINGUA: {
					id: 1805,
					label: "intranet.TRADUZIONI.SERVIZI.ULTIMO-AGG-LINGUA"
				},

				/**
				 * Ultimo aggiornamento
				 */
				ULTIMO_AGGIORNAMENTO: {
					id: 1806,
					label: "intranet.TRADUZIONI.SERVIZI.ULTIMO-AGGIORNAMENTO"
				},

				/**
				 * Tabella
				 */
				TABELLA: {
					id: 6003,
					label: "intranet.TRADUZIONI.SERVIZI.TABELLA"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 6004,
					label: "intranet.TRADUZIONI.SERVIZI.LUOGO"
				},

				/**
				 * Testo
				 */
				TESTO: {
					id: 6005,
					label: "intranet.TRADUZIONI.SERVIZI.TESTO"
				},

				/**
				 * Paese
				 */
				PAESE: {
					id: 6006,
					label: "intranet.TRADUZIONI.SERVIZI.PAESE"
				},

				/**
				 * Lingua
				 */
				LINGUA: {
					id: 6007,
					label: "intranet.TRADUZIONI.SERVIZI.LINGUA"
				},

				/**
				 * Categoria
				 */
				CATEGORIA: {
					id: 6008,
					label: "intranet.TRADUZIONI.SERVIZI.CATEGORIA"
				},

				/**
				 * Tradotto
				 */
				TRADOTTO: {
					id: 6009,
					label: "intranet.TRADUZIONI.SERVIZI.TRADOTTO"
				},

				/**
				 * Nome tradotto
				 */
				NOME_LINGUA: {
					id: 6010,
					label: "intranet.TRADUZIONI.SERVIZI.NOME-LINGUA"
				},

				/**
				 * Info
				 */
				INFO: {
					id: 6011,
					label: "intranet.TRADUZIONI.SERVIZI.INFO"
				},

				/**
				 * Ultimo aggiornamento traduzione
				 */
				ULTIMO_AGGIORNAMENTO_LINGUA: {
					id: 6012,
					label: "intranet.TRADUZIONI.SERVIZI.ULTIMO-AGGIORNAMENTO-LINGUA"
				},

				/**
				 * Da tradurre
				 */
				DA_TRADURRE: {
					id: 6118,
					label: "intranet.TRADUZIONI.SERVIZI.DA-TRADURRE"
				},

				/**
				 * TRADUZIONI.SERVIZI.SERVIZIO-SALVATO
				 */
				SERVIZIO_SALVATO: {
					id: 6339,
					label: "intranet.TRADUZIONI.SERVIZI.SERVIZIO-SALVATO"
				},

			},

			/**
			 * Content of path intranet.TRADUZIONI.LABELS
			 */
			LABELS: {
				/**
				 * Id
				 */
				ID: {
					id: 5859,
					label: "intranet.TRADUZIONI.LABELS.ID"
				},

				/**
				 * Sito
				 */
				WEBSITE: {
					id: 5860,
					label: "intranet.TRADUZIONI.LABELS.WEBSITE"
				},

				/**
				 * Label
				 */
				LABEL: {
					id: 5861,
					label: "intranet.TRADUZIONI.LABELS.LABEL"
				},

				/**
				 * Lingue
				 */
				LINGUE: {
					id: 5862,
					label: "intranet.TRADUZIONI.LABELS.LINGUE"
				},

				/**
				 * Ultimo aggiornamento
				 */
				ULTIMO_AGGIORNAMENTO: {
					id: 5863,
					label: "intranet.TRADUZIONI.LABELS.ULTIMO-AGGIORNAMENTO"
				},

				/**
				 * Non confermate
				 */
				NOTCONFIRMED: {
					id: 5865,
					label: "intranet.TRADUZIONI.LABELS.NOTCONFIRMED"
				},

				/**
				 * Ricerca label
				 */
				RICERCA: {
					id: 5867,
					label: "intranet.TRADUZIONI.LABELS.RICERCA"
				},

				/**
				 * Nuova label
				 */
				NUOVA: {
					id: 5868,
					label: "intranet.TRADUZIONI.LABELS.NUOVA"
				},

				/**
				 * Vuoi eliminare la label {titolo}?
				 */
				ELIMINAZIONE: {
					id: 5873,
					label: "intranet.TRADUZIONI.LABELS.ELIMINAZIONE"
				},

				/**
				 * Label salvata
				 */
				SAVED: {
					id: 5874,
					label: "intranet.TRADUZIONI.LABELS.SAVED"
				},

				/**
				 * Labels
				 */
				LABELS: {
					id: 5875,
					label: "intranet.TRADUZIONI.LABELS.LABELS"
				},

				/**
				 * Copia
				 */
				COPIA: {
					id: 5876,
					label: "intranet.TRADUZIONI.LABELS.COPIA"
				},

				/**
				 * Label non trovata
				 */
				NOTFOUND: {
					id: 5882,
					label: "intranet.TRADUZIONI.LABELS.NOTFOUND"
				},

				/**
				 * Label eliminata
				 */
				ELIMINATO: {
					id: 5883,
					label: "intranet.TRADUZIONI.LABELS.ELIMINATO"
				},

				/**
				 * Traduzione automatica
				 */
				TRADUZIONE_AUTOMATICA: {
					id: 5892,
					label: "intranet.TRADUZIONI.LABELS.TRADUZIONE-AUTOMATICA"
				},

				/**
				 * Segna una traduzione come confermata se credi che vada bene!
				 */
				TRAD_CONFERMATA_HELP: {
					id: 5894,
					label: "intranet.TRADUZIONI.LABELS.TRAD-CONFERMATA-HELP"
				},

				/**
				 * Confermata
				 */
				TRAD_CONFERMATA: {
					id: 5895,
					label: "intranet.TRADUZIONI.LABELS.TRAD-CONFERMATA"
				},

				/**
				 * Parole
				 */
				NUMERO_PAROLE: {
					id: 5896,
					label: "intranet.TRADUZIONI.LABELS.NUMERO-PAROLE"
				},

				/**
				 * Aggiungi traduzione
				 */
				ADD_TRANS: {
					id: 5897,
					label: "intranet.TRADUZIONI.LABELS.ADD-TRANS"
				},

				/**
				 * Id
				 */
				TITOLO_ID: {
					id: 5898,
					label: "intranet.TRADUZIONI.LABELS.TITOLO-ID"
				},

				/**
				 * Non tradotti in
				 */
				NON_TRADOTTI_IN: {
					id: 5899,
					label: "intranet.TRADUZIONI.LABELS.NON-TRADOTTI-IN"
				},

				/**
				 * Da tradurre in
				 */
				DA_TRADURRE_IN: {
					id: 6022,
					label: "intranet.TRADUZIONI.LABELS.DA-TRADURRE-IN"
				},

				/**
				 * Testo obbligatorio
				 */
				undefined: {
					id: 6100,
					label: "intranet.TRADUZIONI.LABELS.undefined"
				},

				/**
				 * Ordine delle parentesi errato
				 */
				BRACES_ORDER: {
					id: 6101,
					label: "intranet.TRADUZIONI.LABELS.BRACES_ORDER"
				},

				/**
				 * Numero delle parentesi errato
				 */
				BRACES_NUM: {
					id: 6102,
					label: "intranet.TRADUZIONI.LABELS.BRACES_NUM"
				},

				/**
				 * Sintassi di pluralizzazione errata
				 */
				PLURALIZATION: {
					id: 6103,
					label: "intranet.TRADUZIONI.LABELS.PLURALIZATION"
				},

				/**
				 * Content of path intranet.TRADUZIONI.LABELS.WEBSITE-OBBLIGATORIO
				 */
				WEBSITE_OBBLIGATORIO: {
				},

			},

		},

		/**
		 * Ricerca accordi
		 */
		ACCORDI: {
			id: 4347,
			label: "intranet.ACCORDI.RICERCA_ACCORDI"
		},

		/**
		 * Content of path intranet.ADMIN
		 */
		ADMIN: {
			/**
			 * Content of path intranet.ADMIN.ASSICURAZIONI
			 */
			ASSICURAZIONI: {
				/**
				 * Abilitata
				 */
				ABILITATA: {
					id: 4348,
					label: "intranet.ADMIN.ASSICURAZIONI.ABILITATA"
				},

				/**
				 * Assicurazioni
				 */
				ASSICURAZIONI: {
					id: 4349,
					label: "intranet.ADMIN.ASSICURAZIONI.ASSICURAZIONI"
				},

				/**
				 * Codice
				 */
				CODICE: {
					id: 4350,
					label: "intranet.ADMIN.ASSICURAZIONI.CODICE"
				},

				/**
				 * Content of path intranet.ADMIN.ASSICURAZIONI.DETTAGLI
				 */
				DETTAGLI: {
					/**
					 * Data e ora
					 */
					DATA: {
						id: 4351,
						label: "intranet.ADMIN.ASSICURAZIONI.DETTAGLI.DATA"
					},

					/**
					 * Esito
					 */
					ESITO: {
						id: 4352,
						label: "intranet.ADMIN.ASSICURAZIONI.DETTAGLI.ESITO"
					},

					/**
					 * ID
					 */
					ID: {
						id: 4353,
						label: "intranet.ADMIN.ASSICURAZIONI.DETTAGLI.ID"
					},

					/**
					 * Note
					 */
					NOTE: {
						id: 4354,
						label: "intranet.ADMIN.ASSICURAZIONI.DETTAGLI.NOTE"
					},

					/**
					 * # Record
					 */
					RECORD: {
						id: 4355,
						label: "intranet.ADMIN.ASSICURAZIONI.DETTAGLI.RECORD"
					},

				},

				/**
				 * ID
				 */
				ID: {
					id: 4356,
					label: "intranet.ADMIN.ASSICURAZIONI.ID"
				},

				/**
				 * Invio alle ore
				 */
				INVIO_ALLE: {
					id: 4357,
					label: "intranet.ADMIN.ASSICURAZIONI.INVIO-ALLE"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 4358,
					label: "intranet.ADMIN.ASSICURAZIONI.NOME"
				},

				/**
				 * Ultimo invio
				 */
				ULTIMO_INVIO: {
					id: 4359,
					label: "intranet.ADMIN.ASSICURAZIONI.ULTIMO-INVIO"
				},

				/**
				 * Esito
				 */
				ULTIMO_INVIO_ESITO: {
					id: 4360,
					label: "intranet.ADMIN.ASSICURAZIONI.ULTIMO-INVIO-ESITO"
				},

				/**
				 * Note
				 */
				ULTIMO_INVIO_NOTE: {
					id: 4361,
					label: "intranet.ADMIN.ASSICURAZIONI.ULTIMO-INVIO-NOTE"
				},

				/**
				 * Ore
				 */
				ULTIMO_INVIO_ORE: {
					id: 4362,
					label: "intranet.ADMIN.ASSICURAZIONI.ULTIMO-INVIO-ORE"
				},

				/**
				 * # Record
				 */
				ULTIMO_INVIO_RECORD: {
					id: 4363,
					label: "intranet.ADMIN.ASSICURAZIONI.ULTIMO-INVIO-RECORD"
				},

			},

			/**
			 * Code serivzi
			 */
			CODE: {
				id: 4364,
				label: "intranet.ADMIN.CODE.TITLE"
			},

			/**
			 * Content of path intranet.ADMIN.EMAIL-DI-SISTEMA
			 */
			EMAIL_DI_SISTEMA: {
				/**
				 * Aggiungi nuovo testo
				 */
				AGGIUNGI_TESTO: {
					id: 4365,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.AGGIUNGI-TESTO"
				},

				/**
				 * Codice
				 */
				CODICE: {
					id: 4366,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.CODICE"
				},

				/**
				 * Codice email obbligatorio
				 */
				CODICE_OBBLIGATORIO: {
					id: 4367,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.CODICE-OBBLIGATORIO"
				},

				/**
				 * Copia email {n}
				 */
				COPIA_EMAIL_N: {
					id: 4368,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.COPIA-EMAIL-N"
				},

				/**
				 * Destinatari fissi
				 */
				DESTINATARI_FISSI: {
					id: 4369,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.DESTINATARI-FISSI"
				},

				/**
				 * Destinatario dinamico
				 */
				DESTINATARIO_DINAMICO: {
					id: 4370,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.DESTINATARIO-DINAMICO"
				},

				/**
				 * Sei sicuro di voler eliminare la mail {email}?
				 */
				ELIMINAZIONE_EMAIL: {
					id: 4371,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.ELIMINAZIONE-EMAIL"
				},

				/**
				 * Email
				 */
				EMAIL: {
					id: 4372,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.EMAIL"
				},

				/**
				 * Email di sistema
				 */
				EMAIL_DI_SISTEMA: {
					id: 4373,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.EMAIL-DI-SISTEMA"
				},

				/**
				 * Email eliminata correttamente
				 */
				EMAIL_ELIMINATA: {
					id: 4374,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.EMAIL-ELIMINATA"
				},

				/**
				 * Email salvata correttamente
				 */
				EMAIL_SALVATA: {
					id: 4375,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.EMAIL-SALVATA"
				},

				/**
				 * ID
				 */
				ID: {
					id: 4376,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.ID"
				},

				/**
				 * Mostra HTML
				 */
				MOSTRA_HTML: {
					id: 4377,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.MOSTRA-HTML"
				},

				/**
				 * Nessun testo presente. Premere il tasto "Aggiungi nuovo testo" per aggiungerne uno
				 */
				NO_TESTI: {
					id: 4378,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.NO-TESTI"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 4379,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.NOME"
				},

				/**
				 * Il nome è obbligatorio
				 */
				NOME_OBBLIGATORIO: {
					id: 4380,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.NOME-OBBLIGATORIO"
				},

				/**
				 * Nome visualizzato
				 */
				NOME_VISALIZZATO: {
					id: 4381,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.NOME-VISALIZZATO"
				},

				/**
				 * Nuova email {n}
				 */
				NUOVA_EMAIL_N: {
					id: 4382,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.NUOVA-EMAIL-N"
				},

				/**
				 * ID / Codice
				 */
				TERM: {
					id: 4383,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.TERM"
				},

				/**
				 * Testo
				 */
				TESTI_BODY: {
					id: 4384,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.TESTI-BODY"
				},

				/**
				 * Il testo della mail è obbligatorio
				 */
				TESTI_BODY_NON_VALIDO: {
					id: 4385,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.TESTI-BODY-NON-VALIDO"
				},

				/**
				 * Mittente
				 */
				TESTI_MITTENTE: {
					id: 4386,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.TESTI-MITTENTE"
				},

				/**
				 * Il mittente è obbligatorio e deve essere una mail valida
				 */
				TESTI_MITTENTE_NON_VALIDO: {
					id: 4387,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.TESTI-MITTENTE-NON-VALIDO"
				},

				/**
				 * Nome visualizzato mittente
				 */
				TESTI_NOME_MITTENTE: {
					id: 4388,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.TESTI-NOME-MITTENTE"
				},

				/**
				 * Il nome mittente è obbligatorio
				 */
				TESTI_NOME_MITTENTE_NON_VALIDO: {
					id: 4389,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.TESTI-NOME-MITTENTE-NON-VALIDO"
				},

				/**
				 * Oggetto
				 */
				TESTI_OGGETTO: {
					id: 4390,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.TESTI-OGGETTO"
				},

				/**
				 * L'oggetto della mail è obbligatorio
				 */
				TESTI_OGGETTO_NON_VALIDO: {
					id: 4391,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.TESTI-OGGETTO-NON-VALIDO"
				},

				/**
				 * Testo
				 */
				TESTO: {
					id: 4392,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.TESTO"
				},

				/**
				 * Testo dinamico
				 */
				TESTO_DINAMICO: {
					id: 4393,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.TESTO-DINAMICO"
				},

				/**
				 * Testo in HTML
				 */
				TESTO_HTML: {
					id: 4394,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.TESTO-HTML"
				},

				/**
				 * Tipo
				 */
				TIPO: {
					id: 4395,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.TIPO"
				},

				/**
				 * Traduzione automatica in {l}
				 */
				TRADUZIONE_AUTOMATICA: {
					id: 4396,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.TRADUZIONE-AUTOMATICA"
				},

				/**
				 * Operatore
				 */
				OPERATORE: {
					id: 6099,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.OPERATORE"
				},

				/**
				 * Sei sicuro di voler ripristinare la mail {email}?
				 */
				RIPRISTINO_EMAIL: {
					id: 6134,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.RIPRISTINO-EMAIL"
				},

				/**
				 * Email ripristinata correttamente
				 */
				EMAIL_RIPRISTINATA: {
					id: 6135,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.EMAIL-RIPRISTINATA"
				},

				/**
				 * Operatore obbligatorio
				 */
				OPERATORE_OBBLIGATORIO: {
					id: 6426,
					label: "intranet.ADMIN.EMAIL-DI-SISTEMA.OPERATORE-OBBLIGATORIO"
				},

			},

			/**
			 * Content of path intranet.ADMIN.EMAIL-FORWARDER
			 */
			EMAIL_FORWARDER: {
				/**
				 * ID Contatto
				 */
				CONTATTO_ID: {
					id: 4397,
					label: "intranet.ADMIN.EMAIL-FORWARDER.CONTATTO-ID"
				},

				/**
				 * Data
				 */
				DATA: {
					id: 4398,
					label: "intranet.ADMIN.EMAIL-FORWARDER.DATA"
				},

				/**
				 * Da
				 */
				FROM: {
					id: 4399,
					label: "intranet.ADMIN.EMAIL-FORWARDER.FROM"
				},

				/**
				 * ID
				 */
				OFFERTA_ID: {
					id: 4400,
					label: "intranet.ADMIN.EMAIL-FORWARDER.OFFERTA-ID"
				},

				/**
				 * Non trovata
				 */
				OFFERTA_NOT_FOUND: {
					id: 4401,
					label: "intranet.ADMIN.EMAIL-FORWARDER.OFFERTA-NOT-FOUND"
				},

				/**
				 * Prenotazione #
				 */
				RESERV_NUM: {
					id: 4402,
					label: "intranet.ADMIN.EMAIL-FORWARDER.RESERV-NUM"
				},

				/**
				 * Stato
				 */
				STATUS: {
					id: 4403,
					label: "intranet.ADMIN.EMAIL-FORWARDER.STATUS"
				},

				/**
				 * Oggetto
				 */
				SUBJECT: {
					id: 4404,
					label: "intranet.ADMIN.EMAIL-FORWARDER.SUBJECT"
				},

				/**
				 * Email Forwarder status
				 */
				TITLE: {
					id: 4405,
					label: "intranet.ADMIN.EMAIL-FORWARDER.TITLE"
				},

				/**
				 * A
				 */
				TO: {
					id: 4406,
					label: "intranet.ADMIN.EMAIL-FORWARDER.TO"
				},

			},

			/**
			 * Content of path intranet.ADMIN.ERRORI
			 */
			ERRORI: {
				/**
				 * Ambiente
				 */
				AMBIENTE: {
					id: 4407,
					label: "intranet.ADMIN.ERRORI.AMBIENTE"
				},

				/**
				 * Azione
				 */
				AZIONE: {
					id: 4408,
					label: "intranet.ADMIN.ERRORI.AZIONE"
				},

				/**
				 * Vuoi eliminare questo errore?
				 */
				CONFERMA_ELIMINA: {
					id: 4409,
					label: "intranet.ADMIN.ERRORI.CONFERMA-ELIMINA"
				},

				/**
				 * Vuoi eliminare {n} error{n, plural, =0{i} one{o} other{i}}?
				 */
				CONFERMA_ELIMINAZIONE_MULTIPLA: {
					id: 4410,
					label: "intranet.ADMIN.ERRORI.CONFERMA-ELIMINAZIONE-MULTIPLA"
				},

				/**
				 * Controller
				 */
				CONTROLLER: {
					id: 4411,
					label: "intranet.ADMIN.ERRORI.CONTROLLER"
				},

				/**
				 * Data
				 */
				DATA: {
					id: 4412,
					label: "intranet.ADMIN.ERRORI.DATA"
				},

				/**
				 * Errore eliminato
				 */
				ERRORE_ELIMINATO: {
					id: 4413,
					label: "intranet.ADMIN.ERRORI.ERRORE-ELIMINATO"
				},

				/**
				 * {n} error{n, plural, =0{i} one{o} other{i}} eliminat{n, plural, =0{i} one{o} other{i}}
				 */
				ERRORI_ELIMINATI: {
					id: 4414,
					label: "intranet.ADMIN.ERRORI.ERRORI-ELIMINATI"
				},

				/**
				 * Messaggio
				 */
				MESSAGGIO: {
					id: 4415,
					label: "intranet.ADMIN.ERRORI.MESSAGGIO"
				},

				/**
				 * Richiesta
				 */
				RICHIESTA: {
					id: 4416,
					label: "intranet.ADMIN.ERRORI.RICHIESTA"
				},

				/**
				 * Stack trace
				 */
				STACK_TRACE: {
					id: 4417,
					label: "intranet.ADMIN.ERRORI.STACK-TRACE"
				},

				/**
				 * Tipo
				 */
				TIPO: {
					id: 4418,
					label: "intranet.ADMIN.ERRORI.TIPO"
				},

				/**
				 * Errori
				 */
				TITLE: {
					id: 4419,
					label: "intranet.ADMIN.ERRORI.TITLE"
				},

				/**
				 * Utente
				 */
				UTENTE: {
					id: 4420,
					label: "intranet.ADMIN.ERRORI.UTENTE"
				},

				/**
				 * Data da
				 */
				DATA_FROM: {
					id: 6242,
					label: "intranet.ADMIN.ERRORI.DATA-FROM"
				},

				/**
				 * Data al
				 */
				DATA_TO: {
					id: 6243,
					label: "intranet.ADMIN.ERRORI.DATA-TO"
				},

			},

			/**
			 * Content of path intranet.ADMIN.SERVIZIFORNITORE
			 */
			SERVIZIFORNITORE: {
				/**
				 * Content of path intranet.ADMIN.SERVIZIFORNITORE.LIST
				 */
				LIST: {
					/**
					 * Abilitato
					 */
					ABILITATO: {
						id: 4421,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.ABILITATO"
					},

					/**
					 * Associato
					 */
					ASSOCIATO: {
						id: 4422,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.ASSOCIATO"
					},

					/**
					 * Codice GIATA
					 */
					COD_GIATA: {
						id: 4423,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.COD-GIATA"
					},

					/**
					 * Codice
					 */
					CODICE: {
						id: 4424,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.CODICE"
					},

					/**
					 * Conferma
					 */
					CONFERMA: {
						id: 4425,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.CONFERMA"
					},

					/**
					 * Confermato
					 */
					CONFERMATO: {
						id: 4426,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.CONFERMATO"
					},

					/**
					 * Crea Servizio H2H...
					 */
					CREA_H2H_SERV: {
						id: 4427,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.CREA-H2H-SERV"
					},

					/**
					 * Crea servizio Naar...
					 */
					CREATE_NAAR_SERV: {
						id: 4428,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.CREATE-NAAR-SERV"
					},

					/**
					 * Descrizione servizio fornitore
					 */
					DESCR_FORNITORE: {
						id: 4429,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.DESCR-FORNITORE"
					},

					/**
					 * Nascondi rating sotto al 60%
					 */
					EXC_LOW_RATING: {
						id: 4430,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.EXC-LOW-RATING"
					},

					/**
					 * Fornitore
					 */
					FORNITORE: {
						id: 4431,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.FORNITORE"
					},

					/**
					 * Giata
					 */
					GIATAID: {
						id: 4432,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.GIATAID"
					},

					/**
					 * Hotel
					 */
					HOTEL: {
						id: 4433,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.HOTEL"
					},

					/**
					 * ID
					 */
					ID: {
						id: 4434,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.ID"
					},

					/**
					 * includi i luoghi vicini
					 */
					INCLUDE_NEAR: {
						id: 4435,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.INCLUDE-NEAR"
					},

					/**
					 * Indirizzo fornitore
					 */
					IND_FORNITORE: {
						id: 4436,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.IND-FORNITORE"
					},

					/**
					 * Indirizzo Naar
					 */
					IND_NAAR: {
						id: 4437,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.IND-NAAR"
					},

					/**
					 * Cerca anche nei luoghi vicini
					 */
					LUOGHI_VICINI: {
						id: 4438,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.LUOGHI-VICINI"
					},

					/**
					 * Luogo Naar
					 */
					LUOGO: {
						id: 4439,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.LUOGO"
					},

					/**
					 * Luogo fornitore
					 */
					LUOGOFORNITORE: {
						id: 4440,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.LUOGOFORNITORE"
					},

					/**
					 * Luogo Naar
					 */
					LUOGONAAR: {
						id: 4441,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.LUOGONAAR"
					},

					/**
					 * Mappato
					 */
					MAPPATO: {
						id: 4442,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.MAPPATO"
					},

					/**
					 * Mapping
					 */
					MAPPING: {
						id: 4443,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.MAPPING"
					},

					/**
					 * Nel luogo indicato nella colonna 'Luogo Naar'
					 */
					NAAR_COL: {
						id: 4444,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.NAAR-COL"
					},

					/**
					 * Noleggi
					 */
					NOLEGGI: {
						id: 4445,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.NOLEGGI"
					},

					/**
					 * Nome / codice servizio fornitore
					 */
					NOME_SERV: {
						id: 4446,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.NOME-SERV"
					},

					/**
					 * Non associato
					 */
					NONASSOCIATO: {
						id: 4447,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.NONASSOCIATO"
					},

					/**
					 * Non conf.
					 */
					NONCONFERMATO: {
						id: 4448,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.NONCONFERMATO"
					},

					/**
					 * Non mappato
					 */
					NONMAPPATO: {
						id: 4449,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.NONMAPPATO"
					},

					/**
					 * [Nuovo]
					 */
					NUOVO: {
						id: 4450,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.NUOVO"
					},

					/**
					 * Apri
					 */
					OPEN: {
						id: 4451,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.OPEN"
					},

					/**
					 * Qualsiasi
					 */
					QUALSIASI: {
						id: 4452,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.QUALSIASI"
					},

					/**
					 * Cerca in
					 */
					SEARCH: {
						id: 4453,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.SEARCH.IN"
					},

					/**
					 * Cerca servizi H2H da associare
					 */
					SEARCH_H2H: {
						id: 4454,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.SEARCH-H2H"
					},

					/**
					 * Servizio H2H
					 */
					SERV_H2H: {
						id: 4455,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.SERV-H2H"
					},

					/**
					 * Servizio Naar
					 */
					SERV_NAAR: {
						id: 4456,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.SERV-NAAR"
					},

					/**
					 * Servizio H2H
					 */
					SERVIZIOH2H: {
						id: 4457,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.SERVIZIOH2H"
					},

					/**
					 * Associated H2H mappato
					 */
					SERVIZIOH2HMAPPATO: {
						id: 4458,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.SERVIZIOH2HMAPPATO"
					},

					/**
					 * Fornitore
					 */
					SUPPLIER: {
						id: 4459,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.SUPPLIER"
					},

					/**
					 * Tipo
					 */
					TIPO: {
						id: 4460,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.TIPO"
					},

					/**
					 * Servizi Fornitori H2H
					 */
					TITLE: {
						id: 4461,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.TITLE"
					},

					/**
					 * Trasferimenti
					 */
					TRASFERIMENTI: {
						id: 4462,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.TRASFERIMENTI"
					},

					/**
					 * Tipologie...
					 */
					TYPES: {
						id: 4463,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.TYPES"
					},

					/**
					 * Ultimo agg.
					 */
					ULTIMOAGG: {
						id: 4464,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.ULTIMOAGG"
					},

					/**
					 * Servizio Naar
					 */
					SERVIZIONAAR: {
						id: 6112,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.SERVIZIONAAR"
					},

					/**
					 * Status
					 */
					STATUS: {
						id: 6116,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.STATUS"
					},

					/**
					 * Apri H2H Service
					 */
					OPEN_H2H: {
						id: 6119,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.OPEN-H2H"
					},

					/**
					 * Crea servizio H2H
					 */
					CREA_H2H: {
						id: 6133,
						label: "intranet.ADMIN.SERVIZIFORNITORE.LIST.CREA-H2H"
					},

				},

				/**
				 * Content of path intranet.ADMIN.SERVIZIFORNITORE.TIPOLOGIE
				 */
				TIPOLOGIE: {
					/**
					 * ANN
					 */
					ANN: {
						id: 4465,
						label: "intranet.ADMIN.SERVIZIFORNITORE.TIPOLOGIE.ANN"
					},

					/**
					 * Codice
					 */
					CODICE: {
						id: 4466,
						label: "intranet.ADMIN.SERVIZIFORNITORE.TIPOLOGIE.CODICE"
					},

					/**
					 * CONF
					 */
					CONF: {
						id: 4467,
						label: "intranet.ADMIN.SERVIZIFORNITORE.TIPOLOGIE.CONF"
					},

					/**
					 * Conf
					 */
					CONFERMA: {
						id: 4468,
						label: "intranet.ADMIN.SERVIZIFORNITORE.TIPOLOGIE.CONFERMA"
					},

					/**
					 * ID
					 */
					ID: {
						id: 4469,
						label: "intranet.ADMIN.SERVIZIFORNITORE.TIPOLOGIE.ID"
					},

					/**
					 * Macro tipologia
					 */
					MACRO_TIPOLOGIA: {
						id: 4470,
						label: "intranet.ADMIN.SERVIZIFORNITORE.TIPOLOGIE.MACRO-TIPOLOGIA"
					},

					/**
					 * Mappato su
					 */
					MAPPED_ON: {
						id: 4471,
						label: "intranet.ADMIN.SERVIZIFORNITORE.TIPOLOGIE.MAPPED-ON"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 4472,
						label: "intranet.ADMIN.SERVIZIFORNITORE.TIPOLOGIE.NOME"
					},

					/**
					 * Tipologia non mappata
					 */
					NON_MAPPATA: {
						id: 4473,
						label: "intranet.ADMIN.SERVIZIFORNITORE.TIPOLOGIE.NON-MAPPATA"
					},

					/**
					 * Regola vendita
					 */
					REG_VENDITA: {
						id: 4474,
						label: "intranet.ADMIN.SERVIZIFORNITORE.TIPOLOGIE.REG-VENDITA"
					},

					/**
					 * Tipologia Naar
					 */
					TIP_NAAR: {
						id: 4475,
						label: "intranet.ADMIN.SERVIZIFORNITORE.TIPOLOGIE.TIP-NAAR"
					},

					/**
					 * Tipologie di
					 */
					TITLE: {
						id: 4476,
						label: "intranet.ADMIN.SERVIZIFORNITORE.TIPOLOGIE.TITLE"
					},

					/**
					 * Ultimo agg.
					 */
					ULTIMOAGG: {
						id: 4477,
						label: "intranet.ADMIN.SERVIZIFORNITORE.TIPOLOGIE.ULTIMOAGG"
					},

				},

				/**
				 * Servizi fornitore
				 */
				TITLE: {
					id: 4478,
					label: "intranet.ADMIN.SERVIZIFORNITORE.TITLE"
				},

			},

			/**
			 * Content of path intranet.ADMIN.SERVIZIH2H
			 */
			SERVIZIH2H: {
				/**
				 * Content of path intranet.ADMIN.SERVIZIH2H.DESCRIZIONI
				 */
				DESCRIZIONI: {
					/**
					 * Abilitata
					 */
					ABILITATA: {
						id: 4479,
						label: "intranet.ADMIN.SERVIZIH2H.DESCRIZIONI.ABILITATA"
					},

					/**
					 * Aggiornata
					 */
					AGGIORNATA: {
						id: 4480,
						label: "intranet.ADMIN.SERVIZIH2H.DESCRIZIONI.AGGIORNATA"
					},

					/**
					 * Descrizione
					 */
					DESCRIZIONE: {
						id: 4481,
						label: "intranet.ADMIN.SERVIZIH2H.DESCRIZIONI.DESCRIZIONE"
					},

					/**
					 * Fornitore
					 */
					FORNITORE: {
						id: 4482,
						label: "intranet.ADMIN.SERVIZIH2H.DESCRIZIONI.FORNITORE"
					},

					/**
					 * ID
					 */
					ID: {
						id: 4483,
						label: "intranet.ADMIN.SERVIZIH2H.DESCRIZIONI.ID"
					},

					/**
					 * Immagine
					 */
					IMMAGINE: {
						id: 4484,
						label: "intranet.ADMIN.SERVIZIH2H.DESCRIZIONI.IMMAGINE"
					},

					/**
					 * Lingua
					 */
					LINGUA: {
						id: 4485,
						label: "intranet.ADMIN.SERVIZIH2H.DESCRIZIONI.LINGUA"
					},

					/**
					 * Ordine
					 */
					ORDINE: {
						id: 4486,
						label: "intranet.ADMIN.SERVIZIH2H.DESCRIZIONI.ORDINE"
					},

					/**
					 * Testo
					 */
					TESTO: {
						id: 4487,
						label: "intranet.ADMIN.SERVIZIH2H.DESCRIZIONI.TESTO"
					},

				},

				/**
				 * Content of path intranet.ADMIN.SERVIZIH2H.EDITOR
				 */
				EDITOR: {
					/**
					 * CAP
					 */
					CAP: {
						id: 4488,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.CAP"
					},

					/**
					 * Codice Giata
					 */
					CODICEGIATA: {
						id: 4489,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.CODICEGIATA"
					},

					/**
					 * Confermi l'eliminazionde del servizio h2h id={id}?
					 */
					CONFERMA_ELIMINA: {
						id: 4490,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.CONFERMA-ELIMINA"
					},

					/**
					 * Consigliato
					 */
					CONSIGLIATO: {
						id: 4491,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.CONSIGLIATO"
					},

					/**
					 * Cover Url
					 */
					COVERURL: {
						id: 4492,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.COVERURL"
					},

					/**
					 * Crea servizio
					 */
					CREA_SERVIZIO: {
						id: 4493,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.CREA-SERVIZIO"
					},

					/**
					 * Crea servizio Naar
					 */
					CREATE_NAAR_SERVICE_CHECK: {
						id: 4494,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.CREATE-NAAR-SERVICE-CHECK"
					},

					/**
					 * Dati
					 */
					DATI: {
						id: 4495,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.DATI"
					},

					/**
					 * Descrizioni
					 */
					DESCRIZIONI: {
						id: 4496,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.DESCRIZIONI"
					},

					/**
					 * Disabilitato
					 */
					DISABILITATO: {
						id: 4497,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.DISABILITATO"
					},

					/**
					 * Email
					 */
					EMAIL: {
						id: 4498,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.EMAIL"
					},

					/**
					 * Immagini
					 */
					IMMAGINI: {
						id: 4499,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.IMMAGINI"
					},

					/**
					 * Indirizzo
					 */
					INDIRIZZO: {
						id: 4500,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.INDIRIZZO"
					},

					/**
					 * Luogo
					 */
					LUOGO: {
						id: 4501,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.LUOGO"
					},

					/**
					 * Mappa
					 */
					MAPPA: {
						id: 4502,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.MAPPA"
					},

					/**
					 * Dati per servizio Naar
					 */
					NAAR_SERV_TITLE: {
						id: 4503,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.NAAR-SERV-TITLE"
					},

					/**
					 * -- Nuovo servizio H2H --
					 */
					NEW_TITLE: {
						id: 4504,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.NEW-TITLE"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 4505,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.NOME"
					},

					/**
					 * Ordine
					 */
					ORDINE: {
						id: 4506,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.ORDINE"
					},

					/**
					 * Pubblicazione
					 */
					PUBBLICAZIONE: {
						id: 4507,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.PUBBLICAZIONE"
					},

					/**
					 * Servizi fornitore associati
					 */
					SERV_FORNITORE: {
						id: 4508,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.SERV-FORNITORE"
					},

					/**
					 * Servizio eliminato
					 */
					SERVIZIO_ELIMINATO: {
						id: 4509,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.SERVIZIO-ELIMINATO"
					},

					/**
					 * Servizio Naar
					 */
					SERVIZIONAAR: {
						id: 4510,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.SERVIZIONAAR"
					},

					/**
					 * Stato
					 */
					STATUS: {
						id: 4511,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.STATUS"
					},

					/**
					 * Stelle
					 */
					STELLE: {
						id: 4512,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.STELLE"
					},

					/**
					 * Telefono
					 */
					TELEFONO: {
						id: 4513,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.TELEFONO"
					},

					/**
					 * Tipo
					 */
					TIPO: {
						id: 4514,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.TIPO"
					},

					/**
					 * Website
					 */
					WEBSITE: {
						id: 4515,
						label: "intranet.ADMIN.SERVIZIH2H.EDITOR.WEBSITE"
					},

				},

				/**
				 * Eliminare il servizio {name} ID={id}?
				 */
				ELIMINA_SERVIZIO: {
					id: 4516,
					label: "intranet.ADMIN.SERVIZIH2H.ELIMINA-SERVIZIO"
				},

				/**
				 * Content of path intranet.ADMIN.SERVIZIH2H.IMMAGINI
				 */
				IMMAGINI: {
					/**
					 * Abilitata
					 */
					ABILITATA: {
						id: 4517,
						label: "intranet.ADMIN.SERVIZIH2H.IMMAGINI.ABILITATA"
					},

					/**
					 * Aggiornata
					 */
					AGGIORNATA: {
						id: 4518,
						label: "intranet.ADMIN.SERVIZIH2H.IMMAGINI.AGGIORNATA"
					},

					/**
					 * Descrizione
					 */
					DESCRIZIONE: {
						id: 4519,
						label: "intranet.ADMIN.SERVIZIH2H.IMMAGINI.DESCRIZIONE"
					},

					/**
					 * Fornitore
					 */
					FORNITORE: {
						id: 4520,
						label: "intranet.ADMIN.SERVIZIH2H.IMMAGINI.FORNITORE"
					},

					/**
					 * ID
					 */
					ID: {
						id: 4521,
						label: "intranet.ADMIN.SERVIZIH2H.IMMAGINI.ID"
					},

					/**
					 * Immagine
					 */
					IMMAGINE: {
						id: 4522,
						label: "intranet.ADMIN.SERVIZIH2H.IMMAGINI.IMMAGINE"
					},

					/**
					 * Ordine
					 */
					ORDINE: {
						id: 4523,
						label: "intranet.ADMIN.SERVIZIH2H.IMMAGINI.ORDINE"
					},

					/**
					 * Ordine Naar
					 */
					ORDINE_NAAR: {
						id: 4524,
						label: "intranet.ADMIN.SERVIZIH2H.IMMAGINI.ORDINE_NAAR"
					},

				},

				/**
				 * Content of path intranet.ADMIN.SERVIZIH2H.LIST
				 */
				LIST: {
					/**
					 * Abilitato
					 */
					ABILITATO: {
						id: 4525,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.ABILITATO"
					},

					/**
					 * Abilitazione
					 */
					ABILITAZIONE: {
						id: 4526,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.ABILITAZIONE"
					},

					/**
					 * Annulla unione
					 */
					ANNULLA_MERGE: {
						id: 4527,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.ANNULLA-MERGE"
					},

					/**
					 * Apri servizio
					 */
					APRI_SERVIZIO: {
						id: 4528,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.APRI-SERVIZIO"
					},

					/**
					 * Associato
					 */
					ASSOCIATO: {
						id: 4529,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.ASSOCIATO"
					},

					/**
					 * Scegliere un servizio fornitore da associare a
					 */
					CHOOSESVC_TITLE: {
						id: 4530,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.CHOOSESVC-TITLE"
					},

					/**
					 * Città Giata
					 */
					CITTA_GIATA: {
						id: 4531,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.CITTA-GIATA"
					},

					/**
					 * Codice fornitore
					 */
					COD_FORNITORE: {
						id: 4532,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.COD-FORNITORE"
					},

					/**
					 * Codice GIATA
					 */
					COD_GIATA: {
						id: 4533,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.COD-GIATA"
					},

					/**
					 * Codice
					 */
					CODICE: {
						id: 4534,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.CODICE"
					},

					/**
					 * Codice
					 */
					CODICE_FORNITORE: {
						id: 4535,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.CODICE-FORNITORE"
					},

					/**
					 * Completo
					 */
					COMPLETO: {
						id: 4536,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.COMPLETO"
					},

					/**
					 * Conferma
					 */
					CONFERMA: {
						id: 4537,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.CONFERMA"
					},

					/**
					 * Conferma tutti
					 */
					CONFERMA_TUTTI: {
						id: 4538,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.CONFERMA-TUTTI"
					},

					/**
					 * Confermato
					 */
					CONFERMATO: {
						id: 4539,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.CONFERMATO"
					},

					/**
					 * Impostare {ns} ({id}) su {l} servizi H2H?
					 */
					CONFIRM_ALL_MAPPING: {
						id: 4540,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.CONFIRM-ALL-MAPPING"
					},

					/**
					 * Confermare il codice Giata {g} per tutti i servizi H2H presenti?
					 */
					CONFIRM_MULTI: {
						id: 4541,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.CONFIRM-MULTI"
					},

					/**
					 * Rimuovere il mapping dall'ID {id}?
					 */
					CONFIRM_REMOVE_MAPPING: {
						id: 4542,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.CONFIRM-REMOVE-MAPPING"
					},

					/**
					 * per il servizio {n}?
					 */
					CONFIRM_SINGLE_2: {
						id: 4543,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.CONFIRM-SINGLE-2"
					},

					/**
					 * Confermare il codice Giata {id}
					 */
					CONFIRM_SINGLE_ADD: {
						id: 4544,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.CONFIRM-SINGLE-ADD"
					},

					/**
					 * Rimuovere il codice Giata
					 */
					CONFIRM_SINGLE_REMOVE: {
						id: 4545,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.CONFIRM-SINGLE-REMOVE"
					},

					/**
					 * Crea servizio Naar...
					 */
					CREA_SER_NAAR: {
						id: 4546,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.CREA-SER-NAAR"
					},

					/**
					 * Disabilitato
					 */
					DISABILITATO: {
						id: 4547,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.DISABILITATO"
					},

					/**
					 * Fornitore
					 */
					FORNITORE: {
						id: 4548,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.FORNITORE"
					},

					/**
					 * Giata ID
					 */
					GIATA_ID: {
						id: 4549,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.GIATA-ID"
					},

					/**
					 * Codici GIATA per
					 */
					GIATA_MODAL_TITLE: {
						id: 4550,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.GIATA-MODAL-TITLE"
					},

					/**
					 * Giata
					 */
					GIATAID: {
						id: 4551,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.GIATAID"
					},

					/**
					 * H2H ID
					 */
					H2HID: {
						id: 4552,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.H2HID"
					},

					/**
					 * Nome H2H
					 */
					H2HNAME: {
						id: 4553,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.H2HNAME"
					},

					/**
					 * Nascondi rating sotto al 60%
					 */
					HIDE_LOWER_RATING: {
						id: 4554,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.HIDE-LOWER-RATING"
					},

					/**
					 * Hotel
					 */
					HOTEL: {
						id: 4555,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.HOTEL"
					},

					/**
					 * ID
					 */
					ID: {
						id: 4556,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.ID"
					},

					/**
					 * Imposta su tutti
					 */
					IMPOSTA_TUTTI: {
						id: 4557,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.IMPOSTA-TUTTI"
					},

					/**
					 * Indirizzo H2H
					 */
					INDIRIZZO_H2H: {
						id: 4558,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.INDIRIZZO-H2H"
					},

					/**
					 * Indirizzo Fornitore
					 */
					INDIRIZZOFORNITORE: {
						id: 4559,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.INDIRIZZOFORNITORE"
					},

					/**
					 * Includi luoghi vicini
					 */
					LUOGHI_VICINI: {
						id: 4560,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.LUOGHI-VICINI"
					},

					/**
					 * Luogo Naar
					 */
					LUOGO: {
						id: 4561,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.LUOGO"
					},

					/**
					 * Luogo fornitore
					 */
					LUOGO_FORN: {
						id: 4562,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.LUOGO-FORN"
					},

					/**
					 * Luogo fornitore
					 */
					LUOGO_FORNITORE: {
						id: 4563,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.LUOGO-FORNITORE"
					},

					/**
					 * Luogo Naar
					 */
					LUOGONAAR: {
						id: 4564,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.LUOGONAAR"
					},

					/**
					 * Mappato
					 */
					MAPPATO: {
						id: 4565,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.MAPPATO"
					},

					/**
					 * Mapping
					 */
					MAPPING: {
						id: 4566,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.MAPPING"
					},

					/**
					 * Noleggi
					 */
					NOLEGGI: {
						id: 4567,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.NOLEGGI"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 4568,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.NOME"
					},

					/**
					 * Nome servizio
					 */
					NOME_SERV: {
						id: 4569,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.NOME-SERV"
					},

					/**
					 * Non associato
					 */
					NON_ASSOCIATO: {
						id: 4570,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.NON-ASSOCIATO"
					},

					/**
					 * Non completo
					 */
					NON_COMPLETO: {
						id: 4571,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.NON-COMPLETO"
					},

					/**
					 * Non confermato
					 */
					NON_CONFERMATO: {
						id: 4572,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.NON-CONFERMATO"
					},

					/**
					 * Non mappato
					 */
					NON_MAPPATO: {
						id: 4573,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.NON-MAPPATO"
					},

					/**
					 * Paese Giata
					 */
					PAESE_GIATA: {
						id: 4574,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.PAESE-GIATA"
					},

					/**
					 * Qualsiasi
					 */
					QUALSIASI: {
						id: 4575,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.QUALSIASI"
					},

					/**
					 * Servizio Mappato
					 */
					SERVIZIO_MAPPATO: {
						id: 4576,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.SERVIZIO-MAPPATO"
					},

					/**
					 * Servizio H2H
					 */
					SERVIZIOH2H: {
						id: 4577,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.SERVIZIOH2H"
					},

					/**
					 * Servizio Naar
					 */
					SERVIZIONAAR: {
						id: 4578,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.SERVIZIONAAR"
					},

					/**
					 * Fornitore
					 */
					SUPPLIER: {
						id: 4579,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.SUPPLIER"
					},

					/**
					 * Tipo
					 */
					TIPO: {
						id: 4580,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.TIPO"
					},

					/**
					 * Trasferimenti
					 */
					TRASFERIMENTI: {
						id: 4581,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.TRASFERIMENTI"
					},

					/**
					 * Ultimo Aggiornamento
					 */
					ULTIMOAGG: {
						id: 4582,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.ULTIMOAGG"
					},

					/**
					 * Unisci a questa riga
					 */
					UNISCI_END: {
						id: 4583,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.UNISCI-END"
					},

					/**
					 * Unisci
					 */
					UNISCI_START: {
						id: 4584,
						label: "intranet.ADMIN.SERVIZIH2H.LIST.UNISCI-START"
					},

				},

				/**
				 * Content of path intranet.ADMIN.SERVIZIH2H.MAPPA
				 */
				MAPPA: {
					/**
					 * Visualizza su mappa
					 */
					CENTRAMAPPA: {
						id: 4585,
						label: "intranet.ADMIN.SERVIZIH2H.MAPPA.CENTRAMAPPA"
					},

					/**
					 * Crea mappa
					 */
					CREATEMAP: {
						id: 4586,
						label: "intranet.ADMIN.SERVIZIH2H.MAPPA.CREATEMAP"
					},

					/**
					 * Indirizzo
					 */
					INDIRIZZO: {
						id: 4587,
						label: "intranet.ADMIN.SERVIZIH2H.MAPPA.INDIRIZZO"
					},

					/**
					 * Latitudine
					 */
					LATITUDINE: {
						id: 4588,
						label: "intranet.ADMIN.SERVIZIH2H.MAPPA.LATITUDINE"
					},

					/**
					 * Localizza
					 */
					LOCALIZZA: {
						id: 4589,
						label: "intranet.ADMIN.SERVIZIH2H.MAPPA.LOCALIZZA"
					},

					/**
					 * Longitudine
					 */
					LONGITUDINE: {
						id: 4590,
						label: "intranet.ADMIN.SERVIZIH2H.MAPPA.LONGITUDINE"
					},

					/**
					 * Sposta pin al centro della mappa
					 */
					MOVEPIN: {
						id: 4591,
						label: "intranet.ADMIN.SERVIZIH2H.MAPPA.MOVEPIN"
					},

					/**
					 * Il servizio non ha una mappa
					 */
					NOMAP: {
						id: 4592,
						label: "intranet.ADMIN.SERVIZIH2H.MAPPA.NOMAP"
					},

					/**
					 * Zoom
					 */
					ZOOM: {
						id: 4593,
						label: "intranet.ADMIN.SERVIZIH2H.MAPPA.ZOOM"
					},

				},

				/**
				 * Content of path intranet.ADMIN.SERVIZIH2H.MODAL
				 */
				MODAL: {
					/**
					 * Cap
					 */
					CAP: {
						id: 4594,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.CAP"
					},

					/**
					 * Cap non valido
					 */
					CAP_NON_VALIDO: {
						id: 4595,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.CAP-NON-VALIDO"
					},

					/**
					 * Categorie
					 */
					CATEGORIE: {
						id: 4596,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.CATEGORIE"
					},

					/**
					 * Cerca Indirizzo
					 */
					CERCAINDIRIZZO: {
						id: 4597,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.CERCAINDIRIZZO"
					},

					/**
					 * Classificazione
					 */
					CLASSIFICAZIONI: {
						id: 4598,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.CLASSIFICAZIONI"
					},

					/**
					 * Codice giata
					 */
					CODICEGIATA: {
						id: 4599,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.CODICEGIATA"
					},

					/**
					 * Consigliato
					 */
					CONSIGLIATO: {
						id: 4600,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.CONSIGLIATO"
					},

					/**
					 * Codice giata non valido
					 */
					GIATAID_NON_VALIDO: {
						id: 4601,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.GIATAID-NON-VALIDO"
					},

					/**
					 * Indirizzo
					 */
					INDIRIZZO: {
						id: 4602,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.INDIRIZZO"
					},

					/**
					 * Indirizzo non valido
					 */
					INDIRIZZO_NON_VALIDO: {
						id: 4603,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.INDIRIZZO-NON-VALIDO"
					},

					/**
					 * Lat.
					 */
					LAT: {
						id: 4604,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.LAT"
					},

					/**
					 * Long.
					 */
					LNG: {
						id: 4605,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.LNG"
					},

					/**
					 * Localizza
					 */
					LOCALIZZA: {
						id: 4606,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.LOCALIZZA"
					},

					/**
					 * Luogo
					 */
					LUOGO: {
						id: 4607,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.LUOGO"
					},

					/**
					 * Il luogo è obbligatorio
					 */
					LUOGO_OBBLIGATORIO: {
						id: 4608,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.LUOGO-OBBLIGATORIO"
					},

					/**
					 * Modifica descrizione
					 */
					MODIFICA_DESCRIZIONE: {
						id: 4609,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.MODIFICA-DESCRIZIONE"
					},

					/**
					 * Modifica immagine
					 */
					MODIFICA_IMMAGINE: {
						id: 4610,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.MODIFICA-IMMAGINE"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 4611,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.NOME"
					},

					/**
					 * Nome non valido
					 */
					NOME_NON_VALIDO: {
						id: 4612,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.NOME-NON-VALIDO"
					},

					/**
					 * Il nome è obbligatorio
					 */
					NOME_OBBLIGATORIO: {
						id: 4613,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.NOME-OBBLIGATORIO"
					},

					/**
					 * Nome oringinale
					 */
					NOMEINGLESE: {
						id: 4614,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.NOMEINGLESE"
					},

					/**
					 * Nome originale non valido
					 */
					NOMEINGLESE_NON_VALIDO: {
						id: 4615,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.NOMEINGLESE-NON-VALIDO"
					},

					/**
					 * Crea servizio
					 */
					NUOVO_SERVIZIO: {
						id: 4616,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.NUOVO-SERVIZIO"
					},

					/**
					 * Ordine
					 */
					ORDINE: {
						id: 4617,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.ORDINE"
					},

					/**
					 * Ordine non valido
					 */
					ORDINE_NON_VALIDO: {
						id: 4618,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.ORDINE-NON-VALIDO"
					},

					/**
					 * Penali
					 */
					PENALI: {
						id: 4619,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.PENALI"
					},

					/**
					 * Posizione
					 */
					POSIZIONI: {
						id: 4620,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.POSIZIONI"
					},

					/**
					 * Pubblicazione
					 */
					PUBBLICAZIONE: {
						id: 4621,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.PUBBLICAZIONE"
					},

					/**
					 * Stato
					 */
					STATO: {
						id: 4622,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.STATO"
					},

					/**
					 * Stelle
					 */
					STELLE: {
						id: 4623,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.STELLE"
					},

					/**
					 * Telefono
					 */
					TELEFONO: {
						id: 4624,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.TELEFONO"
					},

					/**
					 * Telefono non valido
					 */
					TELEFONO_NON_VALIDO: {
						id: 4625,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.TELEFONO-NON-VALIDO"
					},

					/**
					 * Zoom
					 */
					ZOOM: {
						id: 4626,
						label: "intranet.ADMIN.SERVIZIH2H.MODAL.ZOOM"
					},

				},

				/**
				 * Servizio eliminato
				 */
				SERVIZIO_ELIMINATO: {
					id: 4627,
					label: "intranet.ADMIN.SERVIZIH2H.SERVIZIO-ELIMINATO"
				},

				/**
				 * Content of path intranet.ADMIN.SERVIZIH2H.SUPPLIERS
				 */
				SUPPLIERS: {
					/**
					 * Abilitato
					 */
					ABILITATO: {
						id: 4628,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.ABILITATO"
					},

					/**
					 * Associa servizi fornitore...
					 */
					ASSOCIA: {
						id: 4629,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.ASSOCIA"
					},

					/**
					 * Codice
					 */
					CODICE: {
						id: 4630,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.CODICE"
					},

					/**
					 * Confermato
					 */
					CONFERMATO: {
						id: 4631,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.CONFERMATO"
					},

					/**
					 * Descrizione servizio fornitore
					 */
					DESCR: {
						id: 4632,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.DESCR"
					},

					/**
					 * Escludi non confermati sotto il 60% di ranking
					 */
					EXCLUDE_LOW_RATING: {
						id: 4633,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.EXCLUDE-LOW-RATING"
					},

					/**
					 * Fornitore
					 */
					FORNITORE: {
						id: 4634,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.FORNITORE"
					},

					/**
					 * Giata
					 */
					GIATAID: {
						id: 4635,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.GIATAID"
					},

					/**
					 * Id
					 */
					ID: {
						id: 4636,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.ID"
					},

					/**
					 * Indirizzo fornitore
					 */
					IND_FORNITORE: {
						id: 4637,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.IND-FORNITORE"
					},

					/**
					 * Indirizzo Naar
					 */
					IND_NAAR: {
						id: 4638,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.IND-NAAR"
					},

					/**
					 * Luogo fornitore
					 */
					LUOGO_FORNITORE: {
						id: 4639,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.LUOGO-FORNITORE"
					},

					/**
					 * Luogo Naar
					 */
					LUOGONAAR: {
						id: 4640,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.LUOGONAAR"
					},

					/**
					 * Ultimo agg.
					 */
					ULTIMO_AGG: {
						id: 4641,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.ULTIMO-AGG"
					},

					/**
					 * Sganciare la riga ID={id}?
					 */
					UNLINK_TEXT: {
						id: 4642,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.UNLINK-TEXT"
					},

					/**
					 * Richiesta conferma
					 */
					UNLINK_TITLE: {
						id: 4643,
						label: "intranet.ADMIN.SERVIZIH2H.SUPPLIERS.UNLINK-TITLE"
					},

				},

				/**
				 * Servizi H2H
				 */
				TITLE: {
					id: 4644,
					label: "intranet.ADMIN.SERVIZIH2H.TITLE"
				},

			},

			/**
			 * Content of path intranet.ADMIN.UTENTI
			 */
			UTENTI: {
				/**
				 * Agenzia
				 */
				AGENZIA: {
					id: 4645,
					label: "intranet.ADMIN.UTENTI.AGENZIA"
				},

				/**
				 * Chiave duplicata
				 */
				CHIAVE_DUPLICATA: {
					id: 4646,
					label: "intranet.ADMIN.UTENTI.CHIAVE-DUPLICATA"
				},

				/**
				 * Chiave obbligatoria
				 */
				CHIAVE_OBBLIGATORIA: {
					id: 4647,
					label: "intranet.ADMIN.UTENTI.CHIAVE-OBBLIGATORIA"
				},

				/**
				 * Sei sicuro di voler eliminare {n} utent{n, plural, =0{i} one{e} other{i}}?
				 */
				CONFERMA_ELIMINA_MULTI: {
					id: 4648,
					label: "intranet.ADMIN.UTENTI.CONFERMA-ELIMINA-MULTI"
				},

				/**
				 * Confermi di voler impersonare {nome}?
				 */
				CONFERMA_IMPERSONA: {
					id: 4649,
					label: "intranet.ADMIN.UTENTI.CONFERMA-IMPERSONA"
				},

				/**
				 * Data reg
				 */
				DATA_REGISTRAZIONE: {
					id: 4650,
					label: "intranet.ADMIN.UTENTI.DATA-REGISTRAZIONE"
				},

				/**
				 * Content of path intranet.ADMIN.UTENTI.DATI
				 */
				DATI: {
					/**
					 * Abilitato CRS
					 */
					ABILITA_CRS: {
						id: 4651,
						label: "intranet.ADMIN.UTENTI.DATI.ABILITA-CRS"
					},

					/**
					 * Abilitato
					 */
					ABILITATO: {
						id: 4652,
						label: "intranet.ADMIN.UTENTI.DATI.ABILITATO"
					},

					/**
					 * Agenzia
					 */
					AGENZIA: {
						id: 4653,
						label: "intranet.ADMIN.UTENTI.DATI.AGENZIA"
					},

					/**
					 * Cognome
					 */
					COGNOME: {
						id: 4654,
						label: "intranet.ADMIN.UTENTI.DATI.COGNOME"
					},

					/**
					 * Sei sicuro di voler eliminare l'utente {nome} (ID={id})?
					 */
					CONFERMA_ELIMINA: {
						id: 4655,
						label: "intranet.ADMIN.UTENTI.DATI.CONFERMA-ELIMINA"
					},

					/**
					 * Contatto utente obbligatorio
					 */
					CONTATTO_UTENTE_OBBLIGATORIO: {
						id: 4656,
						label: "intranet.ADMIN.UTENTI.DATI.CONTATTO-UTENTE-OBBLIGATORIO"
					},

					/**
					 * Dati
					 */
					DATI: {
						id: 4657,
						label: "intranet.ADMIN.UTENTI.DATI.DATI"
					},

					/**
					 * Email
					 */
					EMAIL: {
						id: 4658,
						label: "intranet.ADMIN.UTENTI.DATI.EMAIL"
					},

					/**
					 * Email non valida
					 */
					EMAIL_NON_VALIDA: {
						id: 4659,
						label: "intranet.ADMIN.UTENTI.DATI.EMAIL-NON-VALIDA"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 4660,
						label: "intranet.ADMIN.UTENTI.DATI.NOME"
					},

					/**
					 * Il nome è obbligatorio
					 */
					NOME_OBBLIGATORIO: {
						id: 4661,
						label: "intranet.ADMIN.UTENTI.DATI.NOME-OBBLIGATORIO"
					},

					/**
					 * Inibisci prenotazione
					 */
					NPR: {
						id: 4662,
						label: "intranet.ADMIN.UTENTI.DATI.NPR"
					},

					/**
					 * Nuovo utente
					 */
					NUOVO_UTENTE: {
						id: 4663,
						label: "intranet.ADMIN.UTENTI.DATI.NUOVO-UTENTE"
					},

					/**
					 * Operatore
					 */
					OPERATORE: {
						id: 4664,
						label: "intranet.ADMIN.UTENTI.DATI.OPERATORE"
					},

					/**
					 * Ruoli
					 */
					RUOLI: {
						id: 4665,
						label: "intranet.ADMIN.UTENTI.DATI.RUOLI"
					},

					/**
					 * Team
					 */
					TEAM: {
						id: 4666,
						label: "intranet.ADMIN.UTENTI.DATI.TEAM"
					},

					/**
					 * Telefono
					 */
					TELEFONO: {
						id: 4667,
						label: "intranet.ADMIN.UTENTI.DATI.TELEFONO"
					},

					/**
					 * Tipo di utente
					 */
					TIPO: {
						id: 4668,
						label: "intranet.ADMIN.UTENTI.DATI.TIPO"
					},

					/**
					 * Contatto utente
					 */
					UTENTE_FINALE: {
						id: 4669,
						label: "intranet.ADMIN.UTENTI.DATI.UTENTE-FINALE"
					},

					/**
					 * Utente padre
					 */
					UTENTE_PADRE: {
						id: 4670,
						label: "intranet.ADMIN.UTENTI.DATI.UTENTE-PADRE"
					},

					/**
					 * Utente secondario
					 */
					UTENTE_SECONDARIO: {
						id: 4671,
						label: "intranet.ADMIN.UTENTI.DATI.UTENTE-SECONDARIO"
					},

					/**
					 * Validatà (giorni)
					 */
					FASTLOGIN_VALIDITY: {
						id: 6472,
						label: "intranet.ADMIN.UTENTI.DATI.FASTLOGIN-VALIDITY"
					},

					/**
					 * Abilita Fast Login
					 */
					FASTLOGIN_ENABLE: {
						id: 6473,
						label: "intranet.ADMIN.UTENTI.DATI.FASTLOGIN-ENABLE"
					},

					/**
					 * Data ultima abilitazione
					 */
					FASTLOGIN_ENABLED_DATE: {
						id: 6474,
						label: "intranet.ADMIN.UTENTI.DATI.FASTLOGIN-ENABLED-DATE"
					},

					/**
					 * Fast login abilitato
					 */
					FASTLOGIN_ENABLED: {
						id: 6475,
						label: "intranet.ADMIN.UTENTI.DATI.FASTLOGIN-ENABLED"
					},

					/**
					 * Token di accesso
					 */
					FASTLOGIN_TOKEN: {
						id: 6476,
						label: "intranet.ADMIN.UTENTI.DATI.FASTLOGIN-TOKEN"
					},

					/**
					 * Questo è il link per effettuare il fast login
					 */
					FASTLOGIN_TOKEN_INFO: {
						id: 6477,
						label: "intranet.ADMIN.UTENTI.DATI.FASTLOGIN-TOKEN-INFO"
					},

					/**
					 * Disabilita Fast Login
					 */
					FASTLOGIN_DISABLE: {
						id: 6478,
						label: "intranet.ADMIN.UTENTI.DATI.FASTLOGIN-DISABLE"
					},

					/**
					 * Sei sicuro di voler abilitare la funzione di Fast Login per l'utente selezionato?
					 */
					FASTLOGIN_CONFIRM: {
						id: 6479,
						label: "intranet.ADMIN.UTENTI.DATI.FASTLOGIN-CONFIRM"
					},

					/**
					 * TOKEN SCADUTO O NON VALIDO
					 */
					FASTLOGIN_TOKEN_NOTVALID: {
						id: 6480,
						label: "intranet.ADMIN.UTENTI.DATI.FASTLOGIN-TOKEN-NOTVALID"
					},

					/**
					 * giorn{n, plural, =0{i} one{o} other{i}}
					 */
					FASTLOGIN_VALIDITY_DAYS: {
						id: 6481,
						label: "intranet.ADMIN.UTENTI.DATI.FASTLOGIN-VALIDITY-DAYS"
					},

					/**
					 * Sei sicuro di voler disabilitare il fast login per l'utente selezionato?
					 * Questo implica che il link di fast login non sarà più utilizzabile!
					 */
					FASTLOGIN_DISAB_CONFIRM: {
						id: 6482,
						label: "intranet.ADMIN.UTENTI.DATI.FASTLOGIN-DISAB-CONFIRM"
					},

					/**
					 * Login via email abilitato
					 */
					EMAIL_LOGIN_ENABLED: {
						id: 6605,
						label: "intranet.ADMIN.UTENTI.DATI.EMAIL-LOGIN-ENABLED"
					},

				},

				/**
				 * Email
				 */
				EMAIL: {
					id: 4672,
					label: "intranet.ADMIN.UTENTI.EMAIL"
				},

				/**
				 * ID
				 */
				ID: {
					id: 4673,
					label: "intranet.ADMIN.UTENTI.ID"
				},

				/**
				 * Impersona
				 */
				IMPERSONA: {
					id: 4674,
					label: "intranet.ADMIN.UTENTI.IMPERSONA"
				},

				/**
				 * Inserisci le tue credenziali per accedere come {nome}
				 */
				ISTRUZIONI_IMPERSONA_HELP_POST: {
					id: 4675,
					label: "intranet.ADMIN.UTENTI.ISTRUZIONI-IMPERSONA-HELP-POST"
				},

				/**
				 * Apri questo link in una finestra anonima:
				 */
				ISTRUZIONI_IMPERSONA_HELP_PRE: {
					id: 4676,
					label: "intranet.ADMIN.UTENTI.ISTRUZIONI-IMPERSONA-HELP-PRE"
				},

				/**
				 * Istruzioni per impersonare un altro utente
				 */
				ISTRUZIONI_IMPERSONA_TITOLO: {
					id: 4677,
					label: "intranet.ADMIN.UTENTI.ISTRUZIONI-IMPERSONA-TITOLO"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 4678,
					label: "intranet.ADMIN.UTENTI.NOME"
				},

				/**
				 * Nome, email o ID
				 */
				NOME_ID: {
					id: 4679,
					label: "intranet.ADMIN.UTENTI.NOME-ID"
				},

				/**
				 * Il nome è obbligatorio
				 */
				NOME_OBBLIGATORIO: {
					id: 4680,
					label: "intranet.ADMIN.UTENTI.NOME-OBBLIGATORIO"
				},

				/**
				 * Nuovo Ruolo
				 */
				NUOVO_RUOLO: {
					id: 4681,
					label: "intranet.ADMIN.UTENTI.NUOVO-RUOLO"
				},

				/**
				 * Nuovo Team
				 */
				NUOVO_TEAM: {
					id: 4682,
					label: "intranet.ADMIN.UTENTI.NUOVO-TEAM"
				},

				/**
				 * Nuovo utente {n}
				 */
				NUOVO_UTENTE: {
					id: 4683,
					label: "intranet.ADMIN.UTENTI.NUOVO-UTENTE"
				},

				/**
				 * Invia richiesta di recupero password
				 */
				RESET_PASSWORD: {
					id: 4684,
					label: "intranet.ADMIN.UTENTI.RESET-PASSWORD"
				},

				/**
				 * Ruoli
				 */
				RUOLI: {
					id: 4685,
					label: "intranet.ADMIN.UTENTI.RUOLI"
				},

				/**
				 * Teams
				 */
				TEAMS: {
					id: 4686,
					label: "intranet.ADMIN.UTENTI.TEAMS"
				},

				/**
				 * Tipo
				 */
				TIPO: {
					id: 4687,
					label: "intranet.ADMIN.UTENTI.TIPO"
				},

				/**
				 * Utenti, ruoli, teams
				 */
				TITOLO: {
					id: 4688,
					label: "intranet.ADMIN.UTENTI.TITOLO"
				},

				/**
				 * Utenti
				 */
				UTENTI: {
					id: 4689,
					label: "intranet.ADMIN.UTENTI.UTENTI"
				},

				/**
				 * Visibilità
				 */
				VISIBILITA: {
					id: 4690,
					label: "intranet.ADMIN.UTENTI.VISIBILITA"
				},

				/**
				 * Ultima richiesta
				 */
				ULTIMA_RICHIESTA: {
					id: 5961,
					label: "intranet.ADMIN.UTENTI.ULTIMA-RICHIESTA"
				},

				/**
				 * Sei sicuro di voler eliminare l'utente {nome} (ID={id})?
				 */
				CONFERMA_RIPRISTINO: {
					id: 6211,
					label: "intranet.ADMIN.UTENTI.CONFERMA-RIPRISTINO"
				},

				/**
				 * Fast Login
				 */
				FAST_LOGIN_ENABLED: {
					id: 6603,
					label: "intranet.ADMIN.UTENTI.FAST-LOGIN-ENABLED"
				},

				/**
				 * Email Login
				 */
				EMAIL_LOGIN_ENABLED: {
					id: 6604,
					label: "intranet.ADMIN.UTENTI.EMAIL-LOGIN-ENABLED"
				},

			},

			/**
			 * Content of path intranet.ADMIN.TERMINI-PAGAMENTO
			 */
			TERMINI_PAGAMENTO: {
				/**
				 * Nome o Id
				 */
				NOME_ID: {
					id: 6184,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.NOME-ID"
				},

				/**
				 * Id
				 */
				ID: {
					id: 6185,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.ID"
				},

				/**
				 * Nome italiano
				 */
				NOME: {
					id: 6189,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.NOME"
				},

				/**
				 * Nome inglese
				 */
				NOME_EN: {
					id: 6190,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.NOME-EN"
				},

				/**
				 * Nome tedesco
				 */
				NOME_DE: {
					id: 6191,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.NOME-DE"
				},

				/**
				 * Nome francese
				 */
				NOME_FR: {
					id: 6192,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.NOME-FR"
				},

				/**
				 * Nome olandese
				 */
				NOME_NL: {
					id: 6193,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.NOME-NL"
				},

				/**
				 * Memo
				 */
				MEMO: {
					id: 6194,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.MEMO"
				},

				/**
				 * Visibile
				 */
				VISIBILE: {
					id: 6195,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.VISIBILE"
				},

				/**
				 * Il nome è obbligatorio
				 */
				NOME_OBBLIGATORIO: {
					id: 6199,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.NOME-OBBLIGATORIO"
				},

				/**
				 * Il nome inglese è obbligatorio
				 */
				NOME_EN_OBBLIGATORIO: {
					id: 6200,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.NOME-EN-OBBLIGATORIO"
				},

				/**
				 * Il memo non può avere più di 5 caratteri
				 */
				MEMO_MASSIMO_5_CARATTERI: {
					id: 6201,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.MEMO-MASSIMO-5-CARATTERI"
				},

				/**
				 * Termini di pagamento
				 */
				TERMINI_PAGAMENTO: {
					id: 6202,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.TERMINI-PAGAMENTO"
				},

				/**
				 * Nuovo termine di pagamento
				 */
				NUOVO_TERMINE_PAGAMENTO_N: {
					id: 6203,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.NUOVO-TERMINE-PAGAMENTO-N"
				},

				/**
				 * Ripristinare termine di pagamento
				 */
				RIPRISTINO: {
					id: 6204,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.RIPRISTINO"
				},

				/**
				 * Termine di pagamento ripristinato
				 */
				RIPRISTINATO: {
					id: 6205,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.RIPRISTINATO"
				},

				/**
				 * Eliminare termine di pagamento
				 */
				ELIMINAZIONE: {
					id: 6206,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.ELIMINAZIONE"
				},

				/**
				 * Termine di pagamento eliminato
				 */
				ELIMINATO: {
					id: 6207,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.ELIMINATO"
				},

				/**
				 * Termine di pagamento salvato
				 */
				SALVATO: {
					id: 6208,
					label: "intranet.ADMIN.TERMINI-PAGAMENTO.SALVATO"
				},

			},

			/**
			 * Content of path intranet.ADMIN.PRICING
			 */
			PRICING: {
				/**
				 * ID
				 */
				ID: {
					id: 6260,
					label: "intranet.ADMIN.PRICING.ID"
				},

				/**
				 * Creazione dal
				 */
				DAL: {
					id: 6261,
					label: "intranet.ADMIN.PRICING.DAL"
				},

				/**
				 * Creazione al
				 */
				AL: {
					id: 6262,
					label: "intranet.ADMIN.PRICING.AL"
				},

				/**
				 * Partenza dal
				 */
				PART_DAL: {
					id: 6263,
					label: "intranet.ADMIN.PRICING.PART-DAL"
				},

				/**
				 * Partenza al
				 */
				PART_AL: {
					id: 6264,
					label: "intranet.ADMIN.PRICING.PART-AL"
				},

				/**
				 * Tipo servizio
				 */
				TIPO_SERVIZIO: {
					id: 6265,
					label: "intranet.ADMIN.PRICING.TIPO-SERVIZIO"
				},

				/**
				 * Tipo viaggio
				 */
				TIPO_VIAGGIO: {
					id: 6266,
					label: "intranet.ADMIN.PRICING.TIPO-VIAGGIO"
				},

				/**
				 * ID luogo
				 */
				LUOGO_ID: {
					id: 6267,
					label: "intranet.ADMIN.PRICING.LUOGO-ID"
				},

				/**
				 * Margine delta
				 */
				DELTA_MARGIN: {
					id: 6268,
					label: "intranet.ADMIN.PRICING.DELTA-MARGIN"
				},

				/**
				 * Priorità
				 */
				PRIORITA: {
					id: 6269,
					label: "intranet.ADMIN.PRICING.PRIORITA"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 6292,
					label: "intranet.ADMIN.PRICING.LUOGO"
				},

				/**
				 * Dati pricing
				 */
				DATI: {
					id: 6293,
					label: "intranet.ADMIN.PRICING.DATI"
				},

				/**
				 * Regole pricing
				 */
				RULES: {
					id: 6294,
					label: "intranet.ADMIN.PRICING.RULES"
				},

				/**
				 * Nuova regola
				 */
				NUOVA_REGOLA: {
					id: 6295,
					label: "intranet.ADMIN.PRICING.NUOVA-REGOLA"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 6296,
					label: "intranet.ADMIN.PRICING.NOME"
				},

				/**
				 * Operatore
				 */
				OPERATORE: {
					id: 6297,
					label: "intranet.ADMIN.PRICING.OPERATORE"
				},

				/**
				 * Ricerca pricing
				 */
				RICERCA: {
					id: 6298,
					label: "intranet.ADMIN.PRICING.RICERCA"
				},

				/**
				 * Nuovo pricing {n}
				 */
				NUOVO_N: {
					id: 6303,
					label: "intranet.ADMIN.PRICING.NUOVO-N"
				},

				/**
				 * Copia pricing {n}
				 */
				COPIA_N: {
					id: 6304,
					label: "intranet.ADMIN.PRICING.COPIA-N"
				},

				/**
				 * Pricing salvato
				 */
				SALVATO: {
					id: 6308,
					label: "intranet.ADMIN.PRICING.SALVATO"
				},

				/**
				 * Sei sicuro di voler eliminare il pricing ID={id}?
				 */
				ELIMINAZIONE: {
					id: 6309,
					label: "intranet.ADMIN.PRICING.ELIMINAZIONE"
				},

				/**
				 * Sei sicuro di voler eliminare la regola di pricing ID={id}?
				 */
				ELIMINAZIONE_REGOLA: {
					id: 6310,
					label: "intranet.ADMIN.PRICING.ELIMINAZIONE-REGOLA"
				},

				/**
				 * Pricing eliminato
				 */
				ELIMINATO: {
					id: 6312,
					label: "intranet.ADMIN.PRICING.ELIMINATO"
				},

				/**
				 * Pricing
				 */
				TITOLO: {
					id: 6377,
					label: "intranet.ADMIN.PRICING.TITOLO"
				},

			},

			/**
			 * Content of path intranet.ADMIN.AI-TUNING
			 */
			AI_TUNING: {
				/**
				 * Nome
				 */
				NOME: {
					id: 6402,
					label: "intranet.ADMIN.AI-TUNING.NOME"
				},

				/**
				 * Id
				 */
				ID: {
					id: 6403,
					label: "intranet.ADMIN.AI-TUNING.ID"
				},

				/**
				 * Modello
				 */
				MODELLO: {
					id: 6406,
					label: "intranet.ADMIN.AI-TUNING.MODELLO"
				},

				/**
				 * Messaggio
				 */
				MESSAGGIO: {
					id: 6407,
					label: "intranet.ADMIN.AI-TUNING.MESSAGGIO"
				},

				/**
				 * Parametri
				 */
				PARAMS: {
					id: 6496,
					label: "intranet.ADMIN.AI-TUNING.PARAMS"
				},

				/**
				 * Nome
				 */
				PARAM_NAME: {
					id: 6497,
					label: "intranet.ADMIN.AI-TUNING.PARAM-NAME"
				},

				/**
				 * Tipo
				 */
				PARAM_TYPE: {
					id: 6498,
					label: "intranet.ADMIN.AI-TUNING.PARAM-TYPE"
				},

				/**
				 * Label
				 */
				PARAM_LABEL: {
					id: 6499,
					label: "intranet.ADMIN.AI-TUNING.PARAM-LABEL"
				},

				/**
				 * Test
				 */
				TEST: {
					id: 6500,
					label: "intranet.ADMIN.AI-TUNING.TEST"
				},

				/**
				 * Tuning salvato
				 */
				SALVATO: {
					id: 6501,
					label: "intranet.ADMIN.AI-TUNING.SALVATO"
				},

				/**
				 * Tuning AI
				 */
				TITOLO: {
					id: 6502,
					label: "intranet.ADMIN.AI-TUNING.TITOLO"
				},

				/**
				 * Messaggio utente
				 */
				MESSAGGIO_UTENTE: {
					id: 6503,
					label: "intranet.ADMIN.AI-TUNING.MESSAGGIO-UTENTE"
				},

				/**
				 * Risposta assistente
				 */
				RISPOSTA_ASSISTENTE: {
					id: 6504,
					label: "intranet.ADMIN.AI-TUNING.RISPOSTA-ASSISTENTE"
				},

				/**
				 * Formatta risposta
				 */
				FORMATTA_RISPOSTA: {
					id: 6505,
					label: "intranet.ADMIN.AI-TUNING.FORMATTA-RISPOSTA"
				},

				/**
				 * Ricerca tuning
				 */
				RICERCA: {
					id: 6506,
					label: "intranet.ADMIN.AI-TUNING.RICERCA"
				},

				/**
				 * Nuovo tuning
				 */
				NUOVO_N: {
					id: 6507,
					label: "intranet.ADMIN.AI-TUNING.NUOVO-N"
				},

				/**
				 * Istruzioni sistema
				 */
				ISTRUZIONI_SISTEMA: {
					id: 6508,
					label: "intranet.ADMIN.AI-TUNING.ISTRUZIONI-SISTEMA"
				},

				/**
				 * Testing
				 */
				TESTING: {
					id: 6509,
					label: "intranet.ADMIN.AI-TUNING.TESTING"
				},

				/**
				 * Sei sicuro di voler eliminare il tuning ID={id}?
				 */
				ELIMINAZIONE: {
					id: 6510,
					label: "intranet.ADMIN.AI-TUNING.ELIMINAZIONE"
				},

				/**
				 * Tuning eliminato
				 */
				ELIMINATO: {
					id: 6511,
					label: "intranet.ADMIN.AI-TUNING.ELIMINATO"
				},

			},

		},

		/**
		 * Content of path intranet.ANAGRAFICHE
		 */
		ANAGRAFICHE: {
			/**
			 * Content of path intranet.ANAGRAFICHE.ACCORDI
			 */
			ACCORDI: {
				/**
				 * Eliminati {nr} accord{nr, plural, =0{i} one{o} other{i}}
				 */
				ACCORDI_ELIMINATI: {
					id: 4691,
					label: "intranet.ANAGRAFICHE.ACCORDI.ACCORDI-ELIMINATI"
				},

				/**
				 * Accordo deregolamentato
				 */
				ACCORDO_DEREGOLAMENTATO: {
					id: 4692,
					label: "intranet.ANAGRAFICHE.ACCORDI.ACCORDO-DEREGOLAMENTATO"
				},

				/**
				 * Accordo eliminato
				 */
				ACCORDO_ELIMINATO: {
					id: 4693,
					label: "intranet.ANAGRAFICHE.ACCORDI.ACCORDO-ELIMINATO"
				},

				/**
				 * Accordo Host-2-Host
				 */
				ACCORDO_HOST_2_HOST: {
					id: 4694,
					label: "intranet.ANAGRAFICHE.ACCORDI.ACCORDO-HOST-2-HOST"
				},

				/**
				 * Accordo ripristinato
				 */
				ACCORDO_RIPRISTINATO: {
					id: 4695,
					label: "intranet.ANAGRAFICHE.ACCORDI.ACCORDO-RIPRISTINATO"
				},

				/**
				 * Accordo salvato
				 */
				ACCORDO_SALVATO: {
					id: 4696,
					label: "intranet.ANAGRAFICHE.ACCORDI.ACCORDO-SALVATO"
				},

				/**
				 * Al
				 */
				AL: {
					id: 4697,
					label: "intranet.ANAGRAFICHE.ACCORDI.AL"
				},

				/**
				 * Altri contraenti
				 */
				ALTRI_CONTRAENTI: {
					id: 4698,
					label: "intranet.ANAGRAFICHE.ACCORDI.ALTRI-CONTRAENTI"
				},

				/**
				 * Centro di costo
				 */
				CENTRO_DI_COSTO: {
					id: 4699,
					label: "intranet.ANAGRAFICHE.ACCORDI.CENTRO-DI-COSTO"
				},

				/**
				 * Codice contabile
				 */
				CODICE_CONTABILE: {
					id: 4700,
					label: "intranet.ANAGRAFICHE.ACCORDI.CODICE-CONTABILE"
				},

				/**
				 * Contabilizza
				 */
				CONTABILIZZA: {
					id: 4701,
					label: "intranet.ANAGRAFICHE.ACCORDI.CONTABILIZZA"
				},

				/**
				 * Contatto
				 */
				CONTATTO: {
					id: 4702,
					label: "intranet.ANAGRAFICHE.ACCORDI.CONTATTO"
				},

				/**
				 * Contatto supporto
				 */
				CONTATTO_SUPPORTO: {
					id: 4703,
					label: "intranet.ANAGRAFICHE.ACCORDI.CONTATTO-SUPPORTO"
				},

				/**
				 * Contraente
				 */
				CONTRAENTE: {
					id: 4704,
					label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTE"
				},

				/**
				 * Content of path intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI
				 */
				CONTRAENTI: {
					/**
					 * Acconto nozze %
					 */
					ACCONTO_NOZZE_PERC: {
						id: 4705,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.ACCONTO-NOZZE-PERC"
					},

					/**
					 * Acconto %
					 */
					ACCONTO_PERC: {
						id: 4706,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.ACCONTO-PERC"
					},

					/**
					 * Aggiornato
					 */
					AGGIORNATO: {
						id: 4707,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.AGGIORNATO"
					},

					/**
					 * Banca pagamenti
					 */
					BANCA: {
						id: 4708,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.BANCA"
					},

					/**
					 * Contatto
					 */
					CONTATTO: {
						id: 4709,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.CONTATTO"
					},

					/**
					 * Il contatto contraente è obbligatorio
					 */
					CONTATTO_OBBLIGATORIO: {
						id: 4710,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.CONTATTO-OBBLIGATORIO"
					},

					/**
					 * Contraente eliminato
					 */
					CONTRAENTE_ELIMINATO: {
						id: 4711,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.CONTRAENTE-ELIMINATO"
					},

					/**
					 * Contraente salvato
					 */
					CONTRAENTE_SALVATO: {
						id: 4712,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.CONTRAENTE-SALVATO"
					},

					/**
					 * Eliminat{nr, plural, =0{i} one{e} other{i}} {nr} contraent{nr, plural, =0{i} one{e} other{i}}
					 */
					CONTRAENTI_ELIMINATI: {
						id: 4713,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.CONTRAENTI-ELIMINATI"
					},

					/**
					 * Corrente
					 */
					CORRENTE: {
						id: 4714,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.CORRENTE"
					},

					/**
					 * Credito
					 */
					CREDITO: {
						id: 4715,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.CREDITO"
					},

					/**
					 * Data
					 */
					DATA: {
						id: 4716,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.DATA"
					},

					/**
					 * Deregolamenta commissioni
					 */
					DEREGOLAMENTA_COMMISSIONI: {
						id: 4717,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.DEREGOLAMENTA-COMMISSIONI"
					},

					/**
					 * Eliminare l'accordo contatto ID={id}?
					 */
					ELIMINARE: {
						id: 4718,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.ELIMINARE"
					},

					/**
					 * Eliminare {selected} contraent{selected, plural, =0{i} one{e} other{i}}?
					 */
					ELIMINARE_MULTI: {
						id: 4719,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.ELIMINARE-MULTI"
					},

					/**
					 * ID
					 */
					ID: {
						id: 4720,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.ID"
					},

					/**
					 * No anticipo biglietti
					 */
					NO_ANTICIPO_BIGLIETTI: {
						id: 4721,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.NO-ANTICIPO-BIGLIETTI"
					},

					/**
					 * Non richiedere pagamento quote apertura pratica/blocca prezzo al 100%
					 */
					NO_ANTICIPO_QUOTE: {
						id: 4722,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.NO-ANTICIPO-QUOTE"
					},

					/**
					 * Nuovo accordo / contatto
					 */
					NUOVO_CONTRAENTE: {
						id: 4723,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.NUOVO-CONTRAENTE"
					},

					/**
					 * Operatore
					 */
					OPERATORE: {
						id: 4724,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.OPERATORE"
					},

					/**
					 * Pagamento biglietteria
					 */
					PAGAMENTO_BIGLIETTERIA: {
						id: 4725,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.PAGAMENTO-BIGLIETTERIA"
					},

					/**
					 * Pagamento viaggi
					 */
					PAGAMENTO_VIAGGI: {
						id: 4726,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.PAGAMENTO-VIAGGI"
					},

					/**
					 * Abilita viaggi netti
					 */
					ABILITA_VIAGGI_NETTI: {
						id: 6236,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.ABILITA-VIAGGI-NETTI"
					},

					/**
					 * Pricing
					 */
					PRICING: {
						id: 6237,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.PRICING"
					},

					/**
					 * Markup TEDS
					 */
					MARKUP_TEDS: {
						id: 6238,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.MARKUP-TEDS"
					},

					/**
					 * Markup viaggi netti
					 */
					MARKUP_VIAGGI_NETTI: {
						id: 6239,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.MARKUP-VIAGGI-NETTI"
					},

					/**
					 * Viaggi netti
					 */
					VIAGGI_NETTI: {
						id: 6240,
						label: "intranet.ANAGRAFICHE.ACCORDI.CONTRAENTI.VIAGGI-NETTI"
					},

				},

				/**
				 * Conversione prezzi netti
				 */
				CONVERSIONE_NETTO: {
					id: 4727,
					label: "intranet.ANAGRAFICHE.ACCORDI.CONVERSIONE-NETTO"
				},

				/**
				 * Conversione prezzi pacchetto
				 */
				CONVERSIONE_PACCHETTO: {
					id: 4728,
					label: "intranet.ANAGRAFICHE.ACCORDI.CONVERSIONE-PACCHETTO"
				},

				/**
				 * Conv. cst / prz
				 */
				CONVERSIONE_PC: {
					id: 4729,
					label: "intranet.ANAGRAFICHE.ACCORDI.CONVERSIONE-PC"
				},

				/**
				 * Copia accordo {n}
				 */
				COPIA_ACCORDO_N: {
					id: 4730,
					label: "intranet.ANAGRAFICHE.ACCORDI.COPIA-ACCORDO-N"
				},

				/**
				 * Dal
				 */
				DAL: {
					id: 4731,
					label: "intranet.ANAGRAFICHE.ACCORDI.DAL"
				},

				/**
				 * Dati
				 */
				DATI: {
					id: 4732,
					label: "intranet.ANAGRAFICHE.ACCORDI.DATI"
				},

				/**
				 * Content of path intranet.ANAGRAFICHE.ACCORDI.DATI-AGGIUNTIVI
				 */
				DATI_AGGIUNTIVI: {
					/**
					 * Banca
					 */
					BANCA: {
						id: 4733,
						label: "intranet.ANAGRAFICHE.ACCORDI.DATI-AGGIUNTIVI.BANCA"
					},

					/**
					 * Eliminat{nr, plural, =0{i} one{e} other{i}} {nr} dat{nr, plural, =0{i} one{o} other{i}} aggiuntiv{nr, plural, =0{i} one{o} other{i}}
					 */
					DATI_AGGIUNTIVI_ELIMINATI: {
						id: 4734,
						label: "intranet.ANAGRAFICHE.ACCORDI.DATI-AGGIUNTIVI.DATI-AGGIUNTIVI-ELIMINATI"
					},

					/**
					 * Dato aggiuntivo eliminato
					 */
					DATO_AGGIUNTIVO_ELIMINATO: {
						id: 4735,
						label: "intranet.ANAGRAFICHE.ACCORDI.DATI-AGGIUNTIVI.DATO-AGGIUNTIVO-ELIMINATO"
					},

					/**
					 * Dato aggiuntivo salvato
					 */
					DATO_AGGIUNTIVO_SALVATO: {
						id: 4736,
						label: "intranet.ANAGRAFICHE.ACCORDI.DATI-AGGIUNTIVI.DATO-AGGIUNTIVO-SALVATO"
					},

					/**
					 * Eliminare il dato aggiuntivo id {id}?
					 */
					ELIMINARE: {
						id: 4737,
						label: "intranet.ANAGRAFICHE.ACCORDI.DATI-AGGIUNTIVI.ELIMINARE"
					},

					/**
					 * Eliminare {selected} dat{selected, plural, =0{i} one{o} other{i}} aggiuntiv{selected, plural, =0{i} one{o} other{i}}?
					 */
					ELIMINARE_MULTI: {
						id: 4738,
						label: "intranet.ANAGRAFICHE.ACCORDI.DATI-AGGIUNTIVI.ELIMINARE-MULTI"
					},

					/**
					 * Iban
					 */
					IBAN: {
						id: 4739,
						label: "intranet.ANAGRAFICHE.ACCORDI.DATI-AGGIUNTIVI.IBAN"
					},

					/**
					 * ID
					 */
					ID: {
						id: 4740,
						label: "intranet.ANAGRAFICHE.ACCORDI.DATI-AGGIUNTIVI.ID"
					},

					/**
					 * Nuovo dato aggiuntivo
					 */
					NUOVO_DATO: {
						id: 4741,
						label: "intranet.ANAGRAFICHE.ACCORDI.DATI-AGGIUNTIVI.NUOVO-DATO"
					},

					/**
					 * Swift
					 */
					SWIFT: {
						id: 4742,
						label: "intranet.ANAGRAFICHE.ACCORDI.DATI-AGGIUNTIVI.SWIFT"
					},

				},

				/**
				 * Dati bancari
				 */
				DATI_BANCARI: {
					id: 4743,
					label: "intranet.ANAGRAFICHE.ACCORDI.DATI-BANCARI"
				},

				/**
				 * Comm. biglietteria dereg.
				 */
				DEREGOLAMENTE_COMMISSIONI_BIGLIETTERIA: {
					id: 4744,
					label: "intranet.ANAGRAFICHE.ACCORDI.DEREGOLAMENTE-COMMISSIONI-BIGLIETTERIA"
				},

				/**
				 * Eliminare {nr} accord{nr, plural, =0{i} one{o} other{i}}?
				 */
				ELIMINA_ACCORDI: {
					id: 4745,
					label: "intranet.ANAGRAFICHE.ACCORDI.ELIMINA-ACCORDI"
				},

				/**
				 * Eliminare l'accordo {name} ID={id}?
				 */
				ELIMINA_ACCORDO: {
					id: 4746,
					label: "intranet.ANAGRAFICHE.ACCORDI.ELIMINA-ACCORDO"
				},

				/**
				 * Emetti voucher
				 */
				EMETTI_VOUCHER: {
					id: 4747,
					label: "intranet.ANAGRAFICHE.ACCORDI.EMETTI-VOUCHER"
				},

				/**
				 * Errore nel leggere l'accordo
				 */
				ERRORE_LETTURA: {
					id: 4748,
					label: "intranet.ANAGRAFICHE.ACCORDI.ERRORE-LETTURA"
				},

				/**
				 * Fine contratto
				 */
				FINE: {
					id: 4749,
					label: "intranet.ANAGRAFICHE.ACCORDI.FINE"
				},

				/**
				 * Fornitore
				 */
				FORNITORE: {
					id: 4750,
					label: "intranet.ANAGRAFICHE.ACCORDI.FORNITORE"
				},

				/**
				 * ID
				 */
				ID: {
					id: 4751,
					label: "intranet.ANAGRAFICHE.ACCORDI.ID"
				},

				/**
				 * Inizio contratto
				 */
				INIZIO: {
					id: 4752,
					label: "intranet.ANAGRAFICHE.ACCORDI.INIZIO"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 4753,
					label: "intranet.ANAGRAFICHE.ACCORDI.LUOGO"
				},

				/**
				 * Margine default
				 */
				MARGINE_DEFAULT: {
					id: 4754,
					label: "intranet.ANAGRAFICHE.ACCORDI.MARGINE-DEFAULT"
				},

				/**
				 * Margine netto
				 */
				MARGINE_NETTO: {
					id: 4755,
					label: "intranet.ANAGRAFICHE.ACCORDI.MARGINE-NETTO"
				},

				/**
				 * Margine pacchetto
				 */
				MARGINE_PACCHETTO: {
					id: 4756,
					label: "intranet.ANAGRAFICHE.ACCORDI.MARGINE-PACCHETTO"
				},

				/**
				 * Codice
				 */
				MEMO: {
					id: 4757,
					label: "intranet.ANAGRAFICHE.ACCORDI.MEMO"
				},

				/**
				 * Mittenti reservations
				 */
				MITTENTI_RESERVATIONS: {
					id: 4758,
					label: "intranet.ANAGRAFICHE.ACCORDI.MITTENTI-RESERVATIONS"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 4759,
					label: "intranet.ANAGRAFICHE.ACCORDI.NOME"
				},

				/**
				 * Nome / ID
				 */
				NOME_ID: {
					id: 4760,
					label: "intranet.ANAGRAFICHE.ACCORDI.NOME-ID"
				},

				/**
				 * Il nome dell'accordo è obbligatorio
				 */
				NOME_OBBLIGATORIO: {
					id: 4761,
					label: "intranet.ANAGRAFICHE.ACCORDI.NOME-OBBLIGATORIO"
				},

				/**
				 * Nota voucher
				 */
				NOTA_VOUCHER: {
					id: 4762,
					label: "intranet.ANAGRAFICHE.ACCORDI.NOTA-VOUCHER"
				},

				/**
				 * -- Nuovo accordo --
				 */
				NUOVO_ACCORDO: {
					id: 4763,
					label: "intranet.ANAGRAFICHE.ACCORDI.NUOVO-ACCORDO"
				},

				/**
				 * Nuovo accordo {n}
				 */
				NUOVO_ACCORDO_N: {
					id: 4764,
					label: "intranet.ANAGRAFICHE.ACCORDI.NUOVO-ACCORDO-N"
				},

				/**
				 * Operatore
				 */
				OPERATORE: {
					id: 4765,
					label: "intranet.ANAGRAFICHE.ACCORDI.OPERATORE"
				},

				/**
				 * Prenotazione automatica
				 */
				PRENOTAZIONE_AUTOMATICA: {
					id: 4766,
					label: "intranet.ANAGRAFICHE.ACCORDI.PRENOTAZIONE-AUTOMATICA"
				},

				/**
				 * Prezzi lordi
				 */
				PREZZI_LORDI: {
					id: 4767,
					label: "intranet.ANAGRAFICHE.ACCORDI.PREZZI-LORDI"
				},

				/**
				 * Ricerca accordi
				 */
				RICERCA_ACCORDI: {
					id: 4768,
					label: "intranet.ANAGRAFICHE.ACCORDI.RICERCA-ACCORDI"
				},

				/**
				 * Ripristinare l'accordo {name} ID={id}?
				 */
				RIPRISTINA_ACCORDO: {
					id: 4769,
					label: "intranet.ANAGRAFICHE.ACCORDI.RIPRISTINA-ACCORDO"
				},

				/**
				 * Scadenza
				 */
				SCADENZA: {
					id: 4770,
					label: "intranet.ANAGRAFICHE.ACCORDI.SCADENZA"
				},

				/**
				 * Content of path intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI
				 */
				TABELLE_COSTI_COMMISSIONI: {
					/**
					 * Al
					 */
					AL: {
						id: 4771,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.AL"
					},

					/**
					 * Codice Aviomar
					 */
					CODICE_AVIOMAR: {
						id: 4772,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.CODICE-AVIOMAR"
					},

					/**
					 * Dal
					 */
					DAL: {
						id: 4773,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.DAL"
					},

					/**
					 * Data 'al' obbligatoria
					 */
					DATA_AL_OBBLIGATORIA: {
						id: 4774,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.DATA-AL-OBBLIGATORIA"
					},

					/**
					 * Data 'dal' obbligatoria
					 */
					DATA_DAL_OBBLIGATORIA: {
						id: 4775,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.DATA-DAL-OBBLIGATORIA"
					},

					/**
					 * Descrizione web
					 */
					DESCRIZIONE_WEB: {
						id: 4776,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.DESCRIZIONE-WEB"
					},

					/**
					 * Eliminare la tabella costi commissioni id {id}?
					 */
					ELIMINARE: {
						id: 4777,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.ELIMINARE"
					},

					/**
					 * Eliminare {selected} tabell{selected, plural, =0{e} one{a} other{e}} costi commissioni?
					 */
					ELIMINARE_MULTI: {
						id: 4778,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.ELIMINARE-MULTI"
					},

					/**
					 * Esclusioni
					 */
					ESCLUSIONI: {
						id: 4779,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.ESCLUSIONI"
					},

					/**
					 * Fisso
					 */
					FISSO: {
						id: 4780,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.FISSO"
					},

					/**
					 * ID
					 */
					ID: {
						id: 4781,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.ID"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 4782,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.NOME"
					},

					/**
					 * Il nome è obbligatorio
					 */
					NOME_OBBLIGATORIO: {
						id: 4783,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.NOME-OBBLIGATORIO"
					},

					/**
					 * Nome originale
					 */
					NOME_ORIGINALE: {
						id: 4784,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.NOME-ORIGINALE"
					},

					/**
					 * -- Non indicato --
					 */
					NON_INDICATO: {
						id: 4785,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.NON-INDICATO"
					},

					/**
					 * Non rimborsabile
					 */
					NON_RIMBORSABILE: {
						id: 4786,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.NON-RIMBORSABILE"
					},

					/**
					 * Nuova tabella costi commissioni
					 */
					NUOVA_TABELLA: {
						id: 4787,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.NUOVA-TABELLA"
					},

					/**
					 * Ordine
					 */
					ORDINE: {
						id: 4788,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.ORDINE"
					},

					/**
					 * Paga subito
					 */
					PAGA_SUBITO: {
						id: 4789,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.PAGA-SUBITO"
					},

					/**
					 * Percentuale
					 */
					PERCENTUALE: {
						id: 4790,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.PERCENTUALE"
					},

					/**
					 * Fornitori: commissione.Agenzie: commissione web
					 */
					PERCENTUALE_HELP: {
						id: 4791,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.PERCENTUALE-HELP"
					},

					/**
					 * Percentuale è obbligatoria
					 */
					PERCENTUALE_OBBLIGATORIA: {
						id: 4792,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.PERCENTUALE-OBBLIGATORIA"
					},

					/**
					 * Percentuale pacchetti
					 */
					PERCENTUALE_PACCHETTI: {
						id: 4793,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.PERCENTUALE-PACCHETTI"
					},

					/**
					 * Fornitori: non usato.Agenzie: commissione su pacchetti
					 */
					PERCENTUALE_PACCHETTI_HELP: {
						id: 4794,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.PERCENTUALE-PACCHETTI-HELP"
					},

					/**
					 * La percentuale pacchetti è obbligatoria
					 */
					PERCENTUALE_PACCHETTI_OBBLIGATORIA: {
						id: 4795,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.PERCENTUALE-PACCHETTI-OBBLIGATORIA"
					},

					/**
					 * Percentuale 2
					 */
					PERCENTUALE2: {
						id: 4796,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.PERCENTUALE2"
					},

					/**
					 * Fornitori: non usato.Agenzie: commissione non web
					 */
					PERCENTUALE2_HELP: {
						id: 4797,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.PERCENTUALE2-HELP"
					},

					/**
					 * Percentuale 2 è obbligatoria
					 */
					PERCENTUALE2_OBBLIGATORIA: {
						id: 4798,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.PERCENTUALE2-OBBLIGATORIA"
					},

					/**
					 * Permuta bambini
					 */
					PERMUTA_BAMBINI: {
						id: 4799,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.PERMUTA-BAMBINI"
					},

					/**
					 * Prenota dopo data
					 */
					PRENOTA_DOPO: {
						id: 4800,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.PRENOTA-DOPO"
					},

					/**
					 * Prenota entro data
					 */
					PRENOTA_ENTRO: {
						id: 4801,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.PRENOTA-ENTRO"
					},

					/**
					 * Prenota entro giorni
					 */
					PRENOTA_ENTRO_GIORNI: {
						id: 4802,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.PRENOTA-ENTRO-GIORNI"
					},

					/**
					 * Regola inserimento costi
					 */
					REGOLA_INSERIMENTO_COSTI: {
						id: 4803,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.REGOLA-INSERIMENTO-COSTI"
					},

					/**
					 * Content of path intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.REGOLE-COSTI
					 */
					REGOLE_COSTI: {
						/**
						 * Nessuna
						 */
						_0: {
							id: 4804,
							label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.REGOLE-COSTI.0"
						},

						/**
						 * Singola Doppia Tripla Quadrupla per pax
						 */
						_1: {
							id: 4805,
							label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.REGOLE-COSTI.1"
						},

						/**
						 * Singola Doppia per camera - Supplemento tripla e quadrupla p.p.
						 */
						_2: {
							id: 4806,
							label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.REGOLE-COSTI.2"
						},

						/**
						 * Doppia per persona -  Supplemento singola - Riduzione tripla e quadrupla p.p.
						 */
						_3: {
							id: 4807,
							label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.REGOLE-COSTI.3"
						},

						/**
						 * Doppia Tripla Quad per persona - Supplemento singola p.p.
						 */
						_4: {
							id: 4808,
							label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.REGOLE-COSTI.4"
						},

						/**
						 * Doppia per persona - Supplemento singola %, tripla p.p.,quadrupla p.p.
						 */
						_5: {
							id: 4809,
							label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.REGOLE-COSTI.5"
						},

						/**
						 * Doppia per persona - Supplemento singola, tripla e quadrupla p.p.
						 */
						_6: {
							id: 4810,
							label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.REGOLE-COSTI.6"
						},

						/**
						 * Doppia per persona - Supplemento singola, % rid. 3° letto, % rid. 4° letto
						 */
						_7: {
							id: 4811,
							label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.REGOLE-COSTI.7"
						},

						/**
						 * Doppia per persona - Supplemento singola %, % rid. 3° letto, % rid. 4° letto
						 */
						_8: {
							id: 4812,
							label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.REGOLE-COSTI.8"
						},

						/**
						 * Supplemento su tipologia base a camera
						 */
						_9: {
							id: 4813,
							label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.REGOLE-COSTI.9"
						},

						/**
						 * Supplemento su tipologia base a persona
						 */
						_10: {
							id: 4814,
							label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.REGOLE-COSTI.10"
						},

						/**
						 * Doppia per persona - Supplemento singola, rid. 3° letto, rid. 4° letto
						 */
						_11: {
							id: 4815,
							label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.REGOLE-COSTI.11"
						},

						/**
						 * Supplemento su trattamento base a persona
						 */
						_12: {
							id: 4816,
							label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.REGOLE-COSTI.12"
						},

					},

					/**
					 * Solo viaggi di nozze
					 */
					SOLO_NOZZE: {
						id: 4817,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.SOLO-NOZZE"
					},

					/**
					 * Tabella costi commissioni eliminata
					 */
					TABELLA_ELIMINATA: {
						id: 4818,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.TABELLA-ELIMINATA"
					},

					/**
					 * Tabella costi commissioni salvata
					 */
					TABELLA_SALVATA: {
						id: 4819,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.TABELLA-SALVATA"
					},

					/**
					 * Eliminat{nr, plural, =0{e} one{a} other{e}} {nr} tabell{nr, plural, =0{e} one{a} other{e}} costi commissioni
					 */
					TABELLE_ELIMINATE: {
						id: 4820,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.TABELLE-ELIMINATE"
					},

					/**
					 * Categoria
					 */
					TIPO: {
						id: 4821,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.TIPO"
					},

					/**
					 * Tipo
					 */
					TIPO_COMMISSIONE: {
						id: 4822,
						label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTI-COMMISSIONI.TIPO-COMMISSIONE"
					},

				},

				/**
				 * Tabelle costi commissioni
				 */
				TABELLE_COSTICOMMISSIONI: {
					id: 4823,
					label: "intranet.ANAGRAFICHE.ACCORDI.TABELLE-COSTICOMMISSIONI"
				},

				/**
				 * Termini pagamento
				 */
				TERMINI_PAGAMENTO: {
					id: 4824,
					label: "intranet.ANAGRAFICHE.ACCORDI.TERMINI-PAGAMENTO"
				},

				/**
				 * Tipo
				 */
				TIPO: {
					id: 4825,
					label: "intranet.ANAGRAFICHE.ACCORDI.TIPO"
				},

				/**
				 * Accordi
				 */
				TITOLO: {
					id: 4826,
					label: "intranet.ANAGRAFICHE.ACCORDI.TITOLO"
				},

				/**
				 * Valuta
				 */
				VALUTA: {
					id: 4827,
					label: "intranet.ANAGRAFICHE.ACCORDI.VALUTA"
				},

				/**
				 * Accordo Host-2-Host senza fornitore!
				 */
				H2H_SENZA_FORNITORE: {
					id: 6327,
					label: "intranet.ANAGRAFICHE.ACCORDI.H2H-SENZA-FORNITORE"
				},

			},

			/**
			 * Content of path intranet.ANAGRAFICHE.AEROPORTI
			 */
			AEROPORTI: {
				/**
				 * Aeroporti
				 */
				AEROPORTI: {
					id: 4828,
					label: "intranet.ANAGRAFICHE.AEROPORTI.AEROPORTI"
				},

				/**
				 * Aeroporto
				 */
				AEROPORTO: {
					id: 4829,
					label: "intranet.ANAGRAFICHE.AEROPORTI.AEROPORTO"
				},

				/**
				 * Aeroporto eliminato
				 */
				AEROPORTO_ELIMINATO: {
					id: 4830,
					label: "intranet.ANAGRAFICHE.AEROPORTI.AEROPORTO-ELIMINATO"
				},

				/**
				 * Aeroporto non valido
				 */
				AEROPORTO_NON_VALIDO: {
					id: 4831,
					label: "intranet.ANAGRAFICHE.AEROPORTI.AEROPORTO-NON-VALIDO"
				},

				/**
				 * Aeroporto salvato
				 */
				AEROPORTO_SALVATO: {
					id: 4832,
					label: "intranet.ANAGRAFICHE.AEROPORTI.AEROPORTO-SALVATO"
				},

				/**
				 * Check-In domestico (minuti)
				 */
				CHECK_IN_DOMESTICO: {
					id: 4833,
					label: "intranet.ANAGRAFICHE.AEROPORTI.CHECK-IN-DOMESTICO"
				},

				/**
				 * Check-In domestico (minuti) non valido
				 */
				CHECK_IN_DOMESTICO_NON_VALIDO: {
					id: 4834,
					label: "intranet.ANAGRAFICHE.AEROPORTI.CHECK-IN-DOMESTICO-NON-VALIDO"
				},

				/**
				 * Check-In internazionale (minuti)
				 */
				CHECK_IN_INTERNAZIONALE: {
					id: 4835,
					label: "intranet.ANAGRAFICHE.AEROPORTI.CHECK-IN-INTERNAZIONALE"
				},

				/**
				 * Check-In internazionale (minuti) non valido
				 */
				CHECK_IN_INTERNAZIONALE_NON_VALIDO: {
					id: 4836,
					label: "intranet.ANAGRAFICHE.AEROPORTI.CHECK-IN-INTERNAZIONALE-NON-VALIDO"
				},

				/**
				 * Cod. IATA
				 */
				CODICE: {
					id: 4837,
					label: "intranet.ANAGRAFICHE.AEROPORTI.CODICE"
				},

				/**
				 * Cod. IATA città
				 */
				CODICE_AREA: {
					id: 4838,
					label: "intranet.ANAGRAFICHE.AEROPORTI.CODICE-AREA"
				},

				/**
				 * Cod. IATA città non valido
				 */
				CODICE_AREA_NON_VALIDO: {
					id: 4839,
					label: "intranet.ANAGRAFICHE.AEROPORTI.CODICE-AREA-NON-VALIDO"
				},

				/**
				 * Cod. IATA non valido. Deve essere diverso da uno già esistente
				 */
				CODICE_NON_UNICO: {
					id: 4840,
					label: "intranet.ANAGRAFICHE.AEROPORTI.CODICE-NON-UNICO"
				},

				/**
				 * 
				 */
				CODICE_NON_VALIDO: {
					id: 4841,
					label: "intranet.ANAGRAFICHE.AEROPORTI.CODICE-NON-VALIDO"
				},

				/**
				 * Cod. IATA obbligatorio
				 */
				CODICE_OBBLIGATORIO: {
					id: 4842,
					label: "intranet.ANAGRAFICHE.AEROPORTI.CODICE-OBBLIGATORIO"
				},

				/**
				 * Sei sicuto di voler procedere con la cancellazione dell'aeroporto?
				 */
				CONFERMA_ELIMINA: {
					id: 4843,
					label: "intranet.ANAGRAFICHE.AEROPORTI.CONFERMA-ELIMINA"
				},

				/**
				 * Copia aeroporto {n}
				 */
				COPIA_AEROPORTO_N: {
					id: 4844,
					label: "intranet.ANAGRAFICHE.AEROPORTI.COPIA-AEROPORTO-N"
				},

				/**
				 * Dati aeroporto
				 */
				DATI: {
					id: 4845,
					label: "intranet.ANAGRAFICHE.AEROPORTI.DATI"
				},

				/**
				 * Vuoi eliminare l'aeroporto {iata} - {name}?
				 */
				ELIMINAZIONE_AEROPORTO: {
					id: 4846,
					label: "intranet.ANAGRAFICHE.AEROPORTI.ELIMINAZIONE-AEROPORTO"
				},

				/**
				 * Errore nel leggere l'aeroporto
				 */
				ERRORE_LETTURA: {
					id: 4847,
					label: "intranet.ANAGRAFICHE.AEROPORTI.ERRORE-LETTURA"
				},

				/**
				 * ID
				 */
				ID: {
					id: 4848,
					label: "intranet.ANAGRAFICHE.AEROPORTI.ID"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 4849,
					label: "intranet.ANAGRAFICHE.AEROPORTI.LUOGO"
				},

				/**
				 * Luogo non valido
				 */
				LUOGO_NON_VALIDO: {
					id: 4850,
					label: "intranet.ANAGRAFICHE.AEROPORTI.LUOGO-NON-VALIDO"
				},

				/**
				 * Mappa
				 */
				MAPPA: {
					id: 4851,
					label: "intranet.ANAGRAFICHE.AEROPORTI.MAPPA"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 4852,
					label: "intranet.ANAGRAFICHE.AEROPORTI.NOME"
				},

				/**
				 * Nome italiano
				 */
				NOME_ITALIANO: {
					id: 4853,
					label: "intranet.ANAGRAFICHE.AEROPORTI.NOME-ITALIANO"
				},

				/**
				 * Nome italiano non valido
				 */
				NOME_ITALIANO_NON_VALIDO: {
					id: 4854,
					label: "intranet.ANAGRAFICHE.AEROPORTI.NOME-ITALIANO-NON-VALIDO"
				},

				/**
				 * 
				 */
				NOME_NON_VALIDO: {
					id: 4855,
					label: "intranet.ANAGRAFICHE.AEROPORTI.NOME-NON-VALIDO"
				},

				/**
				 * Nome obbligatorio
				 */
				NOME_OBBLIGATORIO: {
					id: 4856,
					label: "intranet.ANAGRAFICHE.AEROPORTI.NOME-OBBLIGATORIO"
				},

				/**
				 * Nuovo aeroporto
				 */
				NUOVO_AEROPORTO: {
					id: 4857,
					label: "intranet.ANAGRAFICHE.AEROPORTI.NUOVO-AEROPORTO"
				},

				/**
				 * Nuova aeroporto {n}
				 */
				NUOVO_AEROPORTO_N: {
					id: 4858,
					label: "intranet.ANAGRAFICHE.AEROPORTI.NUOVO-AEROPORTO-N"
				},

				/**
				 * Paese
				 */
				PAESE: {
					id: 4859,
					label: "intranet.ANAGRAFICHE.AEROPORTI.PAESE"
				},

				/**
				 * Paese non valido
				 */
				PAESE_NON_VALIDO: {
					id: 4860,
					label: "intranet.ANAGRAFICHE.AEROPORTI.PAESE-NON-VALIDO"
				},

				/**
				 * Aerop. principale
				 */
				PRINCIPALE: {
					id: 4861,
					label: "intranet.ANAGRAFICHE.AEROPORTI.PRINCIPALE"
				},

				/**
				 * Ricerca aeroporti
				 */
				RICERCA: {
					id: 4862,
					label: "intranet.ANAGRAFICHE.AEROPORTI.RICERCA"
				},

			},

			/**
			 * Content of path intranet.ANAGRAFICHE.COMPAGNIE
			 */
			COMPAGNIE: {
				/**
				 * Cod. BSP
				 */
				COD_BSP: {
					id: 4863,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.COD-BSP"
				},

				/**
				 * Codice
				 */
				CODICE: {
					id: 4864,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.CODICE"
				},

				/**
				 * Codice compagnia non valido. Deve essere diverso da uno già esistente
				 */
				CODICE_NON_UNICO: {
					id: 4865,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.CODICE-NON-UNICO"
				},

				/**
				 * Codice compagnia obbligatorio
				 */
				CODICE_OBBLIGATORIO: {
					id: 4866,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.CODICE-OBBLIGATORIO"
				},

				/**
				 * Commissioni
				 */
				COMM: {
					id: 4867,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.COMM"
				},

				/**
				 * Comm. nazionali
				 */
				COMM_NAZ: {
					id: 4868,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.COMM-NAZ"
				},

				/**
				 * Compagnia
				 */
				COMPAGNIA: {
					id: 4869,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.COMPAGNIA"
				},

				/**
				 * Compagnia aerea eliminata
				 */
				COMPAGNIA_ELIMINATA: {
					id: 4870,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.COMPAGNIA-ELIMINATA"
				},

				/**
				 * Compagnia aerea salvata
				 */
				COMPAGNIA_SALVATA: {
					id: 4871,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.COMPAGNIA-SALVATA"
				},

				/**
				 * Compagnie
				 */
				COMPAGNIE: {
					id: 4872,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.COMPAGNIE"
				},

				/**
				 * Sei sicuto di voler procedere con la cancellazione della compagnia?
				 */
				CONFERMA_ELIMINA: {
					id: 4873,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.CONFERMA-ELIMINA"
				},

				/**
				 * Copia compagnia {n}
				 */
				COPIA_COMPAGNIA_N: {
					id: 4874,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.COPIA-COMPAGNIA-N"
				},

				/**
				 * Dati compagnia
				 */
				DATI: {
					id: 4875,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.DATI"
				},

				/**
				 * Vuoi eliminare la compagnia aerea {code} - {name}?
				 */
				ELIMINAZIONE_COMPAGNIA: {
					id: 4876,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.ELIMINAZIONE-COMPAGNIA"
				},

				/**
				 * Errore nel leggere la compagnia aerea
				 */
				ERRORE_LETTURA: {
					id: 4877,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.ERRORE-LETTURA"
				},

				/**
				 * ID
				 */
				ID: {
					id: 4878,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.ID"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 4879,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.NOME"
				},

				/**
				 * Il nome è obbligatorio
				 */
				NOME_OBBLIGATORIO: {
					id: 4880,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.NOME-OBBLIGATORIO"
				},

				/**
				 * Nuova Compagnia
				 */
				NUOVA_COMPAGNIA: {
					id: 4881,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.NUOVA-COMPAGNIA"
				},

				/**
				 * Nuova compagnia {n}
				 */
				NUOVA_COMPAGNIA_N: {
					id: 4882,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.NUOVA-COMPAGNIA-N"
				},

				/**
				 * Rank
				 */
				RANK: {
					id: 4883,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.RANK"
				},

				/**
				 * Classificazione
				 */
				TANK: {
					id: 4884,
					label: "intranet.ANAGRAFICHE.COMPAGNIE.TANK"
				},

			},

			/**
			 * Content of path intranet.ANAGRAFICHE.CONTATTI
			 */
			CONTATTI: {
				/**
				 * Accordi
				 */
				ACCORDI: {
					id: 4885,
					label: "intranet.ANAGRAFICHE.CONTATTI.ACCORDI"
				},

				/**
				 * Confermi l'eliminazione dell'accordo aggiuntivo?
				 */
				ACCORDI_AGG_CONFERMA_ELIMINA: {
					id: 4886,
					label: "intranet.ANAGRAFICHE.CONTATTI.ACCORDI-AGG-CONFERMA-ELIMINA"
				},

				/**
				 * Accordi aggiuntivi
				 */
				ACCORDI_AGGIUNTIVI: {
					id: 4887,
					label: "intranet.ANAGRAFICHE.CONTATTI.ACCORDI-AGGIUNTIVI"
				},

				/**
				 * Accordi diretti
				 */
				ACCORDI_DIRETTI: {
					id: 4888,
					label: "intranet.ANAGRAFICHE.CONTATTI.ACCORDI-DIRETTI"
				},

				/**
				 * Accordo
				 */
				ACCORDO: {
					id: 4889,
					label: "intranet.ANAGRAFICHE.CONTATTI.ACCORDO"
				},

				/**
				 * Al
				 */
				AL: {
					id: 4890,
					label: "intranet.ANAGRAFICHE.CONTATTI.AL"
				},

				/**
				 * Attivo
				 */
				ATTIVO: {
					id: 4891,
					label: "intranet.ANAGRAFICHE.CONTATTI.ATTIVO"
				},

				/**
				 * Banca pagamenti
				 */
				BANCA: {
					id: 4892,
					label: "intranet.ANAGRAFICHE.CONTATTI.BANCA"
				},

				/**
				 * CAP
				 */
				CAP: {
					id: 4893,
					label: "intranet.ANAGRAFICHE.CONTATTI.CAP"
				},

				/**
				 * CAP fatt.
				 */
				CAPFATT: {
					id: 4894,
					label: "intranet.ANAGRAFICHE.CONTATTI.CAPFATT"
				},

				/**
				 * Categoria
				 */
				CATEGORIA: {
					id: 4895,
					label: "intranet.ANAGRAFICHE.CONTATTI.CATEGORIA"
				},

				/**
				 * Visualizza su mappa
				 */
				CENTRAMAPPA: {
					id: 4896,
					label: "intranet.ANAGRAFICHE.CONTATTI.CENTRAMAPPA"
				},

				/**
				 * Città
				 */
				CITTA: {
					id: 4897,
					label: "intranet.ANAGRAFICHE.CONTATTI.CITTA"
				},

				/**
				 * Città fatt.
				 */
				CITTAFATT: {
					id: 4898,
					label: "intranet.ANAGRAFICHE.CONTATTI.CITTAFATT"
				},

				/**
				 * Civico
				 */
				CIVICO: {
					id: 4899,
					label: "intranet.ANAGRAFICHE.CONTATTI.CIVICO"
				},

				/**
				 * Civico fatt.
				 */
				CIVICOFATT: {
					id: 4900,
					label: "intranet.ANAGRAFICHE.CONTATTI.CIVICOFATT"
				},

				/**
				 * Cognome
				 */
				COGNOME: {
					id: 4901,
					label: "intranet.ANAGRAFICHE.CONTATTI.COGNOME"
				},

				/**
				 * Deregolamenta commissioni biglietteria
				 */
				COMMISSIONIDEREGOLAMENTATE: {
					id: 4902,
					label: "intranet.ANAGRAFICHE.CONTATTI.COMMISSIONIDEREGOLAMENTATE"
				},

				/**
				 * Sei sicuto di voler procedere con la cancellazione del contatto {nome}?
				 */
				CONFERMA_ELIMINA: {
					id: 4903,
					label: "intranet.ANAGRAFICHE.CONTATTI.CONFERMA-ELIMINA"
				},

				/**
				 * Contatto
				 */
				CONTATTO: {
					id: 4904,
					label: "intranet.ANAGRAFICHE.CONTATTI.CONTATTO"
				},

				/**
				 * Contatto padre
				 */
				CONTATTO_PADRE: {
					id: 4905,
					label: "intranet.ANAGRAFICHE.CONTATTI.CONTATTO-PADRE"
				},

				/**
				 * Selezionare un contatto padre
				 */
				CONTATTO_PADRE_OBBLIGATORIO: {
					id: 4906,
					label: "intranet.ANAGRAFICHE.CONTATTI.CONTATTO-PADRE-OBBLIGATORIO"
				},

				/**
				 * Corrente
				 */
				CORRENTE: {
					id: 4907,
					label: "intranet.ANAGRAFICHE.CONTATTI.CORRENTE"
				},

				/**
				 * Crea mappa
				 */
				CREATEMAP: {
					id: 4908,
					label: "intranet.ANAGRAFICHE.CONTATTI.CREATEMAP"
				},

				/**
				 * Credito
				 */
				CREDITO: {
					id: 4909,
					label: "intranet.ANAGRAFICHE.CONTATTI.CREDITO"
				},

				/**
				 * Dal
				 */
				DAL: {
					id: 4910,
					label: "intranet.ANAGRAFICHE.CONTATTI.DAL"
				},

				/**
				 * Data
				 */
				DATA: {
					id: 4911,
					label: "intranet.ANAGRAFICHE.CONTATTI.DATA"
				},

				/**
				 * Dati
				 */
				DATI: {
					id: 4912,
					label: "intranet.ANAGRAFICHE.CONTATTI.DATI"
				},

				/**
				 * Dati agg.
				 */
				DATI_AGG: {
					id: 4913,
					label: "intranet.ANAGRAFICHE.CONTATTI.DATI-AGG"
				},

				/**
				 * Modifica utente
				 */
				EDIT_UTENTE_TITLE: {
					id: 4914,
					label: "intranet.ANAGRAFICHE.CONTATTI.EDIT-UTENTE-TITLE"
				},

				/**
				 * Modifica accordo
				 */
				EDITACCORDO: {
					id: 4915,
					label: "intranet.ANAGRAFICHE.CONTATTI.EDITACCORDO"
				},

				/**
				 * Linguaggio
				 */
				EDITOR_LINGUA: {
					id: 4916,
					label: "intranet.ANAGRAFICHE.CONTATTI.EDITOR-LINGUA"
				},

				/**
				 * Titolo
				 */
				EDITOR_TITOLO: {
					id: 4917,
					label: "intranet.ANAGRAFICHE.CONTATTI.EDITOR-TITOLO"
				},

				/**
				 * Email
				 */
				EMAIL: {
					id: 4918,
					label: "intranet.ANAGRAFICHE.CONTATTI.EMAIL"
				},

				/**
				 * Indirizzo email non valido
				 */
				EMAIL_NON_VALIDO: {
					id: 4919,
					label: "intranet.ANAGRAFICHE.CONTATTI.EMAIL-NON-VALIDO"
				},

				/**
				 * Fax
				 */
				FAX: {
					id: 4920,
					label: "intranet.ANAGRAFICHE.CONTATTI.FAX"
				},

				/**
				 * Numero di fax non valido
				 */
				FAX_NON_VALIDO: {
					id: 4921,
					label: "intranet.ANAGRAFICHE.CONTATTI.FAX-NON-VALIDO"
				},

				/**
				 * ID
				 */
				ID: {
					id: 4922,
					label: "intranet.ANAGRAFICHE.CONTATTI.ID"
				},

				/**
				 * Indirizzo
				 */
				INDIRIZZO: {
					id: 4923,
					label: "intranet.ANAGRAFICHE.CONTATTI.INDIRIZZO"
				},

				/**
				 * Indirizzo fatt.
				 */
				INDIRIZZOFATT: {
					id: 4924,
					label: "intranet.ANAGRAFICHE.CONTATTI.INDIRIZZOFATT"
				},

				/**
				 * Latitudine
				 */
				LATITUDINE: {
					id: 4925,
					label: "intranet.ANAGRAFICHE.CONTATTI.LATITUDINE"
				},

				/**
				 * Content of path intranet.ANAGRAFICHE.CONTATTI.LIST
				 */
				LIST: {
					/**
					 * Accordo comm.
					 */
					ACCORDO_COMMERCIALE: {
						id: 4926,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.ACCORDO-COMMERCIALE"
					},

					/**
					 * CAP
					 */
					CAP: {
						id: 4927,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.CAP"
					},

					/**
					 * Categoria
					 */
					CATEGORIA: {
						id: 4928,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.CATEGORIA"
					},

					/**
					 * Città
					 */
					CITTA: {
						id: 4929,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.CITTA"
					},

					/**
					 * Cognome
					 */
					COGNOME: {
						id: 4930,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.COGNOME"
					},

					/**
					 * Email
					 */
					EMAIL: {
						id: 4931,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.EMAIL"
					},

					/**
					 * ID
					 */
					ID: {
						id: 4932,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.ID"
					},

					/**
					 * Indirizzo
					 */
					INDIRIZZO: {
						id: 4933,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.INDIRIZZO"
					},

					/**
					 * Mod.pag.
					 */
					MODALITA_PAGAMENTO: {
						id: 4934,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.MODALITA-PAGAMENTO"
					},

					/**
					 * Mod.pag. biglietteria
					 */
					MODALITA_PAGAMENTO_BIGLIETTERIA: {
						id: 4935,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.MODALITA-PAGAMENTO-BIGLIETTERIA"
					},

					/**
					 * Network
					 */
					NETWORK: {
						id: 4936,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.NETWORK"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 4937,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.NOME"
					},

					/**
					 * Nome colloquilale
					 */
					NOME_COLLOQUIALE: {
						id: 4938,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.NOME-COLLOQUIALE"
					},

					/**
					 * Nome o ID
					 */
					NOME_ID: {
						id: 4939,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.NOME-ID"
					},

					/**
					 * Paese
					 */
					PAESE: {
						id: 4940,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.PAESE"
					},

					/**
					 * Partita IVA
					 */
					PARTITA_IVA: {
						id: 4941,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.PARTITA-IVA"
					},

					/**
					 * Promotore
					 */
					PROMOTORE: {
						id: 4942,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.PROMOTORE"
					},

					/**
					 * Provincia
					 */
					PROVINCIA: {
						id: 4943,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.PROVINCIA"
					},

					/**
					 * Ragione sociale
					 */
					RAGIONE_SOCIALE: {
						id: 4944,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.RAGIONE-SOCIALE"
					},

					/**
					 * Regione
					 */
					REGIONE: {
						id: 4945,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.REGIONE"
					},

					/**
					 * Telefono
					 */
					TELEFONO: {
						id: 4946,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.TELEFONO"
					},

					/**
					 * Tipo contatto
					 */
					TIPO: {
						id: 4947,
						label: "intranet.ANAGRAFICHE.CONTATTI.LIST.TIPO"
					},

				},

				/**
				 * Impossibile localizzare le coordinate
				 */
				LOCALIZATIONKO: {
					id: 4948,
					label: "intranet.ANAGRAFICHE.CONTATTI.LOCALIZATIONKO"
				},

				/**
				 * Coordinate trovate: LAT: {lat} - LON: {lon}
				 */
				LOCALIZATIONOK: {
					id: 4949,
					label: "intranet.ANAGRAFICHE.CONTATTI.LOCALIZATIONOK"
				},

				/**
				 * Localizza
				 */
				LOCALIZZA: {
					id: 4950,
					label: "intranet.ANAGRAFICHE.CONTATTI.LOCALIZZA"
				},

				/**
				 * Longitudine
				 */
				LONGITUDINE: {
					id: 4951,
					label: "intranet.ANAGRAFICHE.CONTATTI.LONGITUDINE"
				},

				/**
				 * Ruolo
				 */
				MANSIONE: {
					id: 4952,
					label: "intranet.ANAGRAFICHE.CONTATTI.MANSIONE"
				},

				/**
				 * Mappa
				 */
				MAPPA: {
					id: 4953,
					label: "intranet.ANAGRAFICHE.CONTATTI.MAPPA"
				},

				/**
				 * Markup hotel netti
				 */
				MARKUP: {
					id: 4954,
					label: "intranet.ANAGRAFICHE.CONTATTI.MARKUP"
				},

				/**
				 * Sposta pin al centro della mappa
				 */
				MOVEPIN: {
					id: 4955,
					label: "intranet.ANAGRAFICHE.CONTATTI.MOVEPIN"
				},

				/**
				 * Non richiedere acconto biglietti
				 */
				NOANTICIPOBIGL: {
					id: 4956,
					label: "intranet.ANAGRAFICHE.CONTATTI.NOANTICIPOBIGL"
				},

				/**
				 * Non richiedere pagamento quote apertura pratica/blocca prezzo al 100%
				 */
				NOANTICIPOQUOT: {
					id: 4957,
					label: "intranet.ANAGRAFICHE.CONTATTI.NOANTICIPOQUOT"
				},

				/**
				 * Il contatto non ha una mappa
				 */
				NOMAP: {
					id: 4958,
					label: "intranet.ANAGRAFICHE.CONTATTI.NOMAP"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 4959,
					label: "intranet.ANAGRAFICHE.CONTATTI.NOME"
				},

				/**
				 * Note
				 */
				NOTE: {
					id: 4960,
					label: "intranet.ANAGRAFICHE.CONTATTI.NOTE"
				},

				/**
				 * Nuovo contatto {n}
				 */
				NUOVO_CONTATTO: {
					id: 4961,
					label: "intranet.ANAGRAFICHE.CONTATTI.NUOVO-CONTATTO"
				},

				/**
				 * Nuovo utente
				 */
				NUOVO_UTENTE_TITLE: {
					id: 4962,
					label: "intranet.ANAGRAFICHE.CONTATTI.NUOVO-UTENTE-TITLE"
				},

				/**
				 * Nuovo accordo
				 */
				NUOVOACCORDO: {
					id: 4963,
					label: "intranet.ANAGRAFICHE.CONTATTI.NUOVOACCORDO"
				},

				/**
				 * Operatore
				 */
				OPERATORE: {
					id: 4964,
					label: "intranet.ANAGRAFICHE.CONTATTI.OPERATORE"
				},

				/**
				 * Pagamento viaggi
				 */
				PAGAMENTO: {
					id: 4965,
					label: "intranet.ANAGRAFICHE.CONTATTI.PAGAMENTO"
				},

				/**
				 * Pagamento biglietteria
				 */
				PAGBIGLIETTERIA: {
					id: 4966,
					label: "intranet.ANAGRAFICHE.CONTATTI.PAGBIGLIETTERIA"
				},

				/**
				 * Acconto (%)
				 */
				PERCACCONTO: {
					id: 4967,
					label: "intranet.ANAGRAFICHE.CONTATTI.PERCACCONTO"
				},

				/**
				 * Acconto nozze (%)
				 */
				PERCACCONTONOZZE: {
					id: 4968,
					label: "intranet.ANAGRAFICHE.CONTATTI.PERCACCONTONOZZE"
				},

				/**
				 * Può prenotare voli
				 */
				PRENOTAREVOLI: {
					id: 4969,
					label: "intranet.ANAGRAFICHE.CONTATTI.PRENOTAREVOLI"
				},

				/**
				 * Priorità
				 */
				PRIORITA: {
					id: 4970,
					label: "intranet.ANAGRAFICHE.CONTATTI.PRIORITA"
				},

				/**
				 * Recapiti
				 */
				RECAPITI: {
					id: 4971,
					label: "intranet.ANAGRAFICHE.CONTATTI.RECAPITI"
				},

				/**
				 * Recapito
				 */
				RECAPITO: {
					id: 4972,
					label: "intranet.ANAGRAFICHE.CONTATTI.RECAPITO"
				},

				/**
				 * Recapito obbligatorio
				 */
				RECAPITO_NON_VALIDO: {
					id: 4973,
					label: "intranet.ANAGRAFICHE.CONTATTI.RECAPITO-NON-VALIDO"
				},

				/**
				 * Relazioni
				 */
				RELAZIONI: {
					id: 4974,
					label: "intranet.ANAGRAFICHE.CONTATTI.RELAZIONI"
				},

				/**
				 * Relazioni figlio
				 */
				RELAZIONI_FIGLIO: {
					id: 4975,
					label: "intranet.ANAGRAFICHE.CONTATTI.RELAZIONI-FIGLIO"
				},

				/**
				 * Telefono
				 */
				TELEFONO: {
					id: 4976,
					label: "intranet.ANAGRAFICHE.CONTATTI.TELEFONO"
				},

				/**
				 * Numero di telefono non valido
				 */
				TELEFONO_NON_VALIDO: {
					id: 4977,
					label: "intranet.ANAGRAFICHE.CONTATTI.TELEFONO-NON-VALIDO"
				},

				/**
				 * Testo
				 */
				TESTO: {
					id: 4978,
					label: "intranet.ANAGRAFICHE.CONTATTI.TESTO"
				},

				/**
				 * Valore obbligatorio
				 */
				TESTO_NON_VALIDO: {
					id: 4979,
					label: "intranet.ANAGRAFICHE.CONTATTI.TESTO-NON-VALIDO"
				},

				/**
				 * Tipo
				 */
				TIPO: {
					id: 4980,
					label: "intranet.ANAGRAFICHE.CONTATTI.TIPO"
				},

				/**
				 * Tipo contatto obbligatorio
				 */
				TIPO_CONTATTO_NON_VALIDO: {
					id: 4981,
					label: "intranet.ANAGRAFICHE.CONTATTI.TIPO-CONTATTO-NON-VALIDO"
				},

				/**
				 * Contatti
				 */
				TITOLO: {
					id: 4982,
					label: "intranet.ANAGRAFICHE.CONTATTI.TITOLO"
				},

				/**
				 * Confermi l'eliminazione dell'utente?
				 */
				UTENTE_CONFERMA_ELIMINA: {
					id: 4983,
					label: "intranet.ANAGRAFICHE.CONTATTI.UTENTE-CONFERMA-ELIMINA"
				},

				/**
				 * Utenti
				 */
				UTENTI: {
					id: 4984,
					label: "intranet.ANAGRAFICHE.CONTATTI.UTENTI"
				},

				/**
				 * Zoom
				 */
				ZOOM: {
					id: 4985,
					label: "intranet.ANAGRAFICHE.CONTATTI.ZOOM"
				},

				/**
				 * Logo
				 */
				LOGO: {
					id: 6142,
					label: "intranet.ANAGRAFICHE.CONTATTI.LOGO"
				},

				/**
				 * Carica nuovo logo
				 */
				CARICA_NUOVO_LOGO: {
					id: 6143,
					label: "intranet.ANAGRAFICHE.CONTATTI.CARICA-NUOVO-LOGO"
				},

				/**
				 * Lettura logo fallita
				 */
				LOGO_ERRORE_LETTURA: {
					id: 6176,
					label: "intranet.ANAGRAFICHE.CONTATTI.LOGO-ERRORE-LETTURA"
				},

				/**
				 * Immagine troppo grossa, inserire un'immagine larga massimo 400px
				 */
				LOGO_MAX_PIX: {
					id: 6177,
					label: "intranet.ANAGRAFICHE.CONTATTI.LOGO-MAX-PIX"
				},

				/**
				 * Confermi il ripristino di questo contatto?
				 */
				CONFERMA_RIPRISTINO: {
					id: 6210,
					label: "intranet.ANAGRAFICHE.CONTATTI.CONFERMA-RIPRISTINO"
				},

			},

			/**
			 * Content of path intranet.ANAGRAFICHE.FORNITORIH2H
			 */
			FORNITORIH2H: {
				/**
				 * Attivo
				 */
				ATTIVO: {
					id: 4986,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.ATTIVO"
				},

				/**
				 * Agg. automatico
				 */
				AUTOREFRESH: {
					id: 4987,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.AUTOREFRESH"
				},

				/**
				 * Book
				 */
				BOOK: {
					id: 4988,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.BOOK"
				},

				/**
				 * Crociere
				 */
				CROCIERE: {
					id: 4989,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.CROCIERE"
				},

				/**
				 * Durata ric.
				 */
				DURATARIC: {
					id: 4990,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.DURATARIC"
				},

				/**
				 * Hotel
				 */
				HOTEL: {
					id: 4991,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.HOTEL"
				},

				/**
				 * Noleggi
				 */
				NOLEGGI: {
					id: 4992,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.NOLEGGI"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 4993,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.NOME"
				},

				/**
				 * Ric
				 */
				RIC: {
					id: 4994,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.RIC"
				},

				/**
				 * Ric. attive
				 */
				RICATTIVE: {
					id: 4995,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.RICATTIVE"
				},

				/**
				 * Ric. fault
				 */
				RICFAULT: {
					id: 4996,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.RICFAULT"
				},

				/**
				 * Vedi solo attivi
				 */
				SHOWONLYACTIVE: {
					id: 4997,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.SHOWONLYACTIVE"
				},

				/**
				 * Fornitori H2H
				 */
				TITLE: {
					id: 4998,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.TITLE"
				},

				/**
				 * ogni {n} secondi
				 */
				TRA: {
					id: 4999,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.TRA"
				},

				/**
				 * Ultimi {n} secondi
				 */
				ULT10MIN: {
					id: 5000,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.ULT10MIN"
				},

				/**
				 * Ultima ricerca
				 */
				ULTIMARICERCA: {
					id: 5001,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.ULTIMARICERCA"
				},

				/**
				 * Ultimo fault
				 */
				ULTIMOFAULT: {
					id: 5002,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.ULTIMOFAULT"
				},

				/**
				 * Visite
				 */
				VISITE: {
					id: 5003,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.VISITE"
				},

				/**
				 * Voli
				 */
				VOLI: {
					id: 5004,
					label: "intranet.ANAGRAFICHE.FORNITORIH2H.VOLI"
				},

			},

			/**
			 * Content of path intranet.ANAGRAFICHE.LUOGHI
			 */
			LUOGHI: {
				/**
				 * Confermi l'eliminazione dell'accordo aggiuntivo?
				 */
				ACCORDI_AGG_CONFERMA_ELIMINA: {
					id: 5005,
					label: "intranet.ANAGRAFICHE.LUOGHI.ACCORDI-AGG-CONFERMA-ELIMINA"
				},

				/**
				 * Accordo
				 */
				ACCORDO: {
					id: 5006,
					label: "intranet.ANAGRAFICHE.LUOGHI.ACCORDO"
				},

				/**
				 * Indirizzo
				 */
				ADDRESS: {
					id: 5007,
					label: "intranet.ANAGRAFICHE.LUOGHI.ADDRESS"
				},

				/**
				 * Al
				 */
				AL: {
					id: 5008,
					label: "intranet.ANAGRAFICHE.LUOGHI.AL"
				},

				/**
				 * Allot.
				 */
				ALLOT: {
					id: 5009,
					label: "intranet.ANAGRAFICHE.LUOGHI.ALLOT"
				},

				/**
				 * Art.Data
				 */
				ART_DATA: {
					id: 5010,
					label: "intranet.ANAGRAFICHE.LUOGHI.ART_DATA"
				},

				/**
				 * Luogo Astoi
				 */
				ASTOI_ID: {
					id: 5011,
					label: "intranet.ANAGRAFICHE.LUOGHI.ASTOI-ID"
				},

				/**
				 * Attivo
				 */
				ATTIVO: {
					id: 5012,
					label: "intranet.ANAGRAFICHE.LUOGHI.ATTIVO"
				},

				/**
				 * Autocompletamento
				 */
				AUTOFILL: {
					id: 5013,
					label: "intranet.ANAGRAFICHE.LUOGHI.AUTOFILL"
				},

				/**
				 * Banca pagamenti
				 */
				BANCA: {
					id: 5014,
					label: "intranet.ANAGRAFICHE.LUOGHI.BANCA"
				},

				/**
				 * Non usare dati di cache in ricerca prezzi
				 */
				CACHE_BYPASS: {
					id: 5015,
					label: "intranet.ANAGRAFICHE.LUOGHI.CACHE-BYPASS"
				},

				/**
				 * Capoluogo di provincia (ITA)
				 */
				CAPOLUOGO: {
					id: 5016,
					label: "intranet.ANAGRAFICHE.LUOGHI.CAPOLUOGO"
				},

				/**
				 * CEE (solo paesi)
				 */
				CEE: {
					id: 5017,
					label: "intranet.ANAGRAFICHE.LUOGHI.CEE"
				},

				/**
				 * Visualizza su mappa
				 */
				CENTRAMAPPA: {
					id: 5018,
					label: "intranet.ANAGRAFICHE.LUOGHI.CENTRAMAPPA"
				},

				/**
				 * IATA / Codice
				 */
				CODICE_IATA: {
					id: 5019,
					label: "intranet.ANAGRAFICHE.LUOGHI.CODICE-IATA"
				},

				/**
				 * IATA / Codice non valido
				 */
				CODICE_IATA_NON_VALIDO: {
					id: 5020,
					label: "intranet.ANAGRAFICHE.LUOGHI.CODICE-IATA-NON-VALIDO"
				},

				/**
				 * Deregolamenta commissioni biglietteria
				 */
				COMMISSIONIDEREGOLAMENTATE: {
					id: 5021,
					label: "intranet.ANAGRAFICHE.LUOGHI.COMMISSIONIDEREGOLAMENTATE"
				},

				/**
				 * Sei sicuto di voler procedere con la cancellazione del luogo?
				 */
				CONFERMA_ELIMINA: {
					id: 5022,
					label: "intranet.ANAGRAFICHE.LUOGHI.CONFERMA-ELIMINA"
				},

				/**
				 * Contatto
				 */
				CONTATTO: {
					id: 5023,
					label: "intranet.ANAGRAFICHE.LUOGHI.CONTATTO"
				},

				/**
				 * Contatto padre
				 */
				CONTATTO_PADRE: {
					id: 5024,
					label: "intranet.ANAGRAFICHE.LUOGHI.CONTATTO-PADRE"
				},

				/**
				 * Contiene
				 */
				CONTIENE: {
					id: 5025,
					label: "intranet.ANAGRAFICHE.LUOGHI.CONTIENE"
				},

				/**
				 * Corrente
				 */
				CORRENTE: {
					id: 5026,
					label: "intranet.ANAGRAFICHE.LUOGHI.CORRENTE"
				},

				/**
				 * Crea mappa
				 */
				CREATEMAP: {
					id: 5027,
					label: "intranet.ANAGRAFICHE.LUOGHI.CREATEMAP"
				},

				/**
				 * Credito
				 */
				CREDITO: {
					id: 5028,
					label: "intranet.ANAGRAFICHE.LUOGHI.CREDITO"
				},

				/**
				 * Dal
				 */
				DAL: {
					id: 5029,
					label: "intranet.ANAGRAFICHE.LUOGHI.DAL"
				},

				/**
				 * Data
				 */
				DATA: {
					id: 5030,
					label: "intranet.ANAGRAFICHE.LUOGHI.DATA"
				},

				/**
				 * Dati
				 */
				DATI: {
					id: 5031,
					label: "intranet.ANAGRAFICHE.LUOGHI.DATI"
				},

				/**
				 * Dati agg.
				 */
				DATI_AGG: {
					id: 5032,
					label: "intranet.ANAGRAFICHE.LUOGHI.DATI_AGG"
				},

				/**
				 * Modifica accordo
				 */
				EDITACCORDO: {
					id: 5033,
					label: "intranet.ANAGRAFICHE.LUOGHI.EDITACCORDO"
				},

				/**
				 * Errore di lettura luogo
				 */
				ERRORE_LETTURA: {
					id: 5034,
					label: "intranet.ANAGRAFICHE.LUOGHI.ERRORE-LETTURA"
				},

				/**
				 * Forn.H2h
				 */
				FORN_H2H: {
					id: 5035,
					label: "intranet.ANAGRAFICHE.LUOGHI.FORN_H2H"
				},

				/**
				 * Codice GIATA non valido
				 */
				GIATA_CODE_NON_VALIDO: {
					id: 5036,
					label: "intranet.ANAGRAFICHE.LUOGHI.GIATA-CODE-NON-VALIDO"
				},

				/**
				 * ID
				 */
				ID: {
					id: 5037,
					label: "intranet.ANAGRAFICHE.LUOGHI.ID"
				},

				/**
				 * Immagini
				 */
				IMMAGINI: {
					id: 5038,
					label: "intranet.ANAGRAFICHE.LUOGHI.IMMAGINI"
				},

				/**
				 * Lat
				 */
				LAT: {
					id: 5039,
					label: "intranet.ANAGRAFICHE.LUOGHI.LAT"
				},

				/**
				 * Latitudine
				 */
				LATITUDINE: {
					id: 5040,
					label: "intranet.ANAGRAFICHE.LUOGHI.LATITUDINE"
				},

				/**
				 * Content of path intranet.ANAGRAFICHE.LUOGHI.LIST
				 */
				LIST: {
					/**
					 * IATA
					 */
					IATA: {
						id: 5041,
						label: "intranet.ANAGRAFICHE.LUOGHI.LIST.IATA"
					},

					/**
					 * Iata / Codice
					 */
					IATA_COD: {
						id: 5042,
						label: "intranet.ANAGRAFICHE.LUOGHI.LIST.IATA_COD"
					},

					/**
					 * ID
					 */
					ID: {
						id: 5043,
						label: "intranet.ANAGRAFICHE.LUOGHI.LIST.ID"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 5044,
						label: "intranet.ANAGRAFICHE.LUOGHI.LIST.NOME"
					},

					/**
					 * Paese
					 */
					PAESE: {
						id: 5045,
						label: "intranet.ANAGRAFICHE.LUOGHI.LIST.PAESE"
					},

					/**
					 * Regione
					 */
					REGIONE: {
						id: 5046,
						label: "intranet.ANAGRAFICHE.LUOGHI.LIST.REGIONE"
					},

					/**
					 * Tipo
					 */
					TIPO: {
						id: 5047,
						label: "intranet.ANAGRAFICHE.LUOGHI.LIST.TIPO"
					},

				},

				/**
				 * Impossibile localizzare le coordinate
				 */
				LOCALIZATIONKO: {
					id: 5048,
					label: "intranet.ANAGRAFICHE.LUOGHI.LOCALIZATIONKO"
				},

				/**
				 * Coordinate trovate:
				 */
				LOCALIZATIONOK: {
					id: 5049,
					label: "intranet.ANAGRAFICHE.LUOGHI.LOCALIZATIONOK"
				},

				/**
				 * Localizza
				 */
				LOCALIZZA: {
					id: 5050,
					label: "intranet.ANAGRAFICHE.LUOGHI.LOCALIZZA"
				},

				/**
				 * Lon
				 */
				LON: {
					id: 5051,
					label: "intranet.ANAGRAFICHE.LUOGHI.LON"
				},

				/**
				 * Longitudine
				 */
				LONGITUDINE: {
					id: 5052,
					label: "intranet.ANAGRAFICHE.LUOGHI.LONGITUDINE"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 5053,
					label: "intranet.ANAGRAFICHE.LUOGHI.LUOGO"
				},

				/**
				 * Luogo obbligatorio
				 */
				LUOGO_OBBLIGATORIO: {
					id: 5054,
					label: "intranet.ANAGRAFICHE.LUOGHI.LUOGO-OBBLIGATORIO"
				},

				/**
				 * Mappa
				 */
				MAPPA: {
					id: 5055,
					label: "intranet.ANAGRAFICHE.LUOGHI.MAPPA"
				},

				/**
				 * Sposta pin al centro della mappa
				 */
				MOVEPIN: {
					id: 5056,
					label: "intranet.ANAGRAFICHE.LUOGHI.MOVEPIN"
				},

				/**
				 * Nascondi servizi non rimborsabili
				 */
				NASCONDINRF: {
					id: 5057,
					label: "intranet.ANAGRAFICHE.LUOGHI.NASCONDINRF"
				},

				/**
				 * Non richiedere acconto biglietti
				 */
				NOANTICIPOBIGL: {
					id: 5058,
					label: "intranet.ANAGRAFICHE.LUOGHI.NOANTICIPOBIGL"
				},

				/**
				 * Non richiedere pagamento quote apertura pratica/blocca prezzo al 100%
				 */
				NOANTICIPOQUOT: {
					id: 5059,
					label: "intranet.ANAGRAFICHE.LUOGHI.NOANTICIPOQUOT"
				},

				/**
				 * Il contatto non ha una mappa
				 */
				NOMAP: {
					id: 5060,
					label: "intranet.ANAGRAFICHE.LUOGHI.NOMAP"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 5061,
					label: "intranet.ANAGRAFICHE.LUOGHI.NOME"
				},

				/**
				 * Nome inglese
				 */
				NOME_INGLESE: {
					id: 5062,
					label: "intranet.ANAGRAFICHE.LUOGHI.NOME-INGLESE"
				},

				/**
				 * Nome obbligatorio
				 */
				NOME_NON_VALIDO: {
					id: 5063,
					label: "intranet.ANAGRAFICHE.LUOGHI.NOME-NON-VALIDO"
				},

				/**
				 * Nome obbligatorio
				 */
				NOME_OBBLIGATORIO: {
					id: 5064,
					label: "intranet.ANAGRAFICHE.LUOGHI.NOME-OBBLIGATORIO"
				},

				/**
				 * Nome originale
				 */
				NOME_ORIG: {
					id: 5065,
					label: "intranet.ANAGRAFICHE.LUOGHI.NOME-ORIG"
				},

				/**
				 * Nome originale non valido
				 */
				NOME_ORIG_NON_VALIDO: {
					id: 5066,
					label: "intranet.ANAGRAFICHE.LUOGHI.NOME-ORIG-NON-VALIDO"
				},

				/**
				 * Servizi non mappati
				 */
				NON_MAPPATI: {
					id: 5067,
					label: "intranet.ANAGRAFICHE.LUOGHI.NON-MAPPATI"
				},

				/**
				 * Nuovo luogo
				 */
				NUOVO_LUOGO: {
					id: 5068,
					label: "intranet.ANAGRAFICHE.LUOGHI.NUOVO-LUOGO"
				},

				/**
				 * Nuovo accordo
				 */
				NUOVOACCORDO: {
					id: 5069,
					label: "intranet.ANAGRAFICHE.LUOGHI.NUOVOACCORDO"
				},

				/**
				 * Apri traduzioni
				 */
				OPEN_TRANSLATION: {
					id: 5070,
					label: "intranet.ANAGRAFICHE.LUOGHI.OPEN-TRANSLATION"
				},

				/**
				 * Operatore
				 */
				OPERATORE: {
					id: 5071,
					label: "intranet.ANAGRAFICHE.LUOGHI.OPERATORE"
				},

				/**
				 * Ordine
				 */
				ORDINE: {
					id: 5072,
					label: "intranet.ANAGRAFICHE.LUOGHI.ORDINE"
				},

				/**
				 * Ordine non valido
				 */
				ORDINE_NON_VALIDO: {
					id: 5073,
					label: "intranet.ANAGRAFICHE.LUOGHI.ORDINE-NON-VALIDO"
				},

				/**
				 * Pagamento viaggi
				 */
				PAGAMENTO: {
					id: 5074,
					label: "intranet.ANAGRAFICHE.LUOGHI.PAGAMENTO"
				},

				/**
				 * Pagamento biglietteria
				 */
				PAGBIGLIETTERIA: {
					id: 5075,
					label: "intranet.ANAGRAFICHE.LUOGHI.PAGBIGLIETTERIA"
				},

				/**
				 * Fa parte di
				 */
				PARTE_DI: {
					id: 5076,
					label: "intranet.ANAGRAFICHE.LUOGHI.PARTE-DI"
				},

				/**
				 * Partenza
				 */
				PARTENZA: {
					id: 5077,
					label: "intranet.ANAGRAFICHE.LUOGHI.PARTENZA"
				},

				/**
				 * Acconto
				 */
				PERCACCONTO: {
					id: 5078,
					label: "intranet.ANAGRAFICHE.LUOGHI.PERCACCONTO"
				},

				/**
				 * Acconto nozze
				 */
				PERCACCONTONOZZE: {
					id: 5079,
					label: "intranet.ANAGRAFICHE.LUOGHI.PERCACCONTONOZZE"
				},

				/**
				 * Popolarità
				 */
				POPOLARITA: {
					id: 5080,
					label: "intranet.ANAGRAFICHE.LUOGHI.POPOLARITA"
				},

				/**
				 * Popolarità non valida
				 */
				POPOLARITA_NON_VALIDO: {
					id: 5081,
					label: "intranet.ANAGRAFICHE.LUOGHI.POPOLARITA-NON-VALIDO"
				},

				/**
				 * Pref.
				 */
				PREF: {
					id: 5082,
					label: "intranet.ANAGRAFICHE.LUOGHI.PREF"
				},

				/**
				 * Priorità
				 */
				PRIORITA: {
					id: 5083,
					label: "intranet.ANAGRAFICHE.LUOGHI.PRIORITA"
				},

				/**
				 * Provincia
				 */
				PROVINCIA: {
					id: 5084,
					label: "intranet.ANAGRAFICHE.LUOGHI.PROVINCIA"
				},

				/**
				 * Provincia non valida
				 */
				PROVINCIA_NON_VALIDO: {
					id: 5085,
					label: "intranet.ANAGRAFICHE.LUOGHI.PROVINCIA-NON-VALIDO"
				},

				/**
				 * Recapito
				 */
				RECAPITO: {
					id: 5086,
					label: "intranet.ANAGRAFICHE.LUOGHI.RECAPITO"
				},

				/**
				 * Relazioni
				 */
				RELAZIONI: {
					id: 5087,
					label: "intranet.ANAGRAFICHE.LUOGHI.RELAZIONI"
				},

				/**
				 * Servizi
				 */
				SERVIZI: {
					id: 5088,
					label: "intranet.ANAGRAFICHE.LUOGHI.SERVIZI"
				},

				/**
				 * Servizo
				 */
				SERVIZIO: {
					id: 5089,
					label: "intranet.ANAGRAFICHE.LUOGHI.SERVIZIO"
				},

				/**
				 * Sito
				 */
				SITO_B2C: {
					id: 5090,
					label: "intranet.ANAGRAFICHE.LUOGHI.SITO-B2C"
				},

				/**
				 * Sito Naar
				 */
				SITO_NAAR: {
					id: 5091,
					label: "intranet.ANAGRAFICHE.LUOGHI.SITO-NAAR"
				},

				/**
				 * Sito Naar France
				 */
				SITO_NAAR_FR: {
					id: 5092,
					label: "intranet.ANAGRAFICHE.LUOGHI.SITO-NAAR-FR"
				},

				/**
				 * Testo
				 */
				TESTO: {
					id: 5093,
					label: "intranet.ANAGRAFICHE.LUOGHI.TESTO"
				},

				/**
				 * Tipo
				 */
				TIPO: {
					id: 5094,
					label: "intranet.ANAGRAFICHE.LUOGHI.TIPO"
				},

				/**
				 * Codice GIATA
				 */
				TIPO_GIATA: {
					id: 5095,
					label: "intranet.ANAGRAFICHE.LUOGHI.TIPO-GIATA"
				},

				/**
				 * Tipo ricerca
				 */
				TIPO_RICERCA: {
					id: 5096,
					label: "intranet.ANAGRAFICHE.LUOGHI.TIPO-RICERCA"
				},

				/**
				 * Luoghi
				 */
				TITOLO: {
					id: 5097,
					label: "intranet.ANAGRAFICHE.LUOGHI.TITOLO"
				},

				/**
				 * Utl.prezzo
				 */
				ULT_PREZZO: {
					id: 5098,
					label: "intranet.ANAGRAFICHE.LUOGHI.ULT_PREZZO"
				},

				/**
				 * Confermi l'eliminazione dell'utente?
				 */
				UTENTE_CONFERMA_ELIMINA: {
					id: 5099,
					label: "intranet.ANAGRAFICHE.LUOGHI.UTENTE-CONFERMA-ELIMINA"
				},

				/**
				 * Luoghi vicini
				 */
				VICINI: {
					id: 5100,
					label: "intranet.ANAGRAFICHE.LUOGHI.VICINI"
				},

				/**
				 * Web
				 */
				WEB: {
					id: 5101,
					label: "intranet.ANAGRAFICHE.LUOGHI.WEB"
				},

				/**
				 * Zoom
				 */
				ZOOM: {
					id: 5102,
					label: "intranet.ANAGRAFICHE.LUOGHI.ZOOM"
				},

			},

			/**
			 * Content of path intranet.ANAGRAFICHE.LUOGHIH2H
			 */
			LUOGHIH2H: {
				/**
				 * Abilitato
				 */
				ABILITATO: {
					id: 5103,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.ABILITATO"
				},

				/**
				 * Abilitazione
				 */
				ABILITAZIONE: {
					id: 5104,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.ABILITAZIONE"
				},

				/**
				 * Accordo
				 */
				ACCORDO: {
					id: 5105,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.ACCORDO"
				},

				/**
				 * Accordo associato per valuta e markup specifici (Obbligatorio per: EXPEDIA, GTA)
				 */
				ACCORDO_EDITOR: {
					id: 5106,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.ACCORDO-EDITOR"
				},

				/**
				 * Ultimo Agg.
				 */
				AGGIORNATO: {
					id: 5107,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.AGGIORNATO"
				},

				/**
				 * Codice
				 */
				CODICE: {
					id: 5108,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.CODICE"
				},

				/**
				 * Codice paese
				 */
				CODPAESE: {
					id: 5109,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.CODPAESE"
				},

				/**
				 * Conferma
				 */
				CONFERMA: {
					id: 5110,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.CONFERMA"
				},

				/**
				 * Confermato
				 */
				CONFERMATO: {
					id: 5111,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.CONFERMATO"
				},

				/**
				 * Da confermare
				 */
				DACONFERMARE: {
					id: 5112,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.DACONFERMARE"
				},

				/**
				 * Disabilitato
				 */
				DISABILITATO: {
					id: 5113,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.DISABILITATO"
				},

				/**
				 * Fornitore
				 */
				FORNITORE: {
					id: 5114,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.FORNITORE"
				},

				/**
				 * ID
				 */
				ID: {
					id: 5115,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.ID"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 5116,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.LUOGO"
				},

				/**
				 * Luogo Naar
				 */
				LUOGO_NAAR: {
					id: 5117,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.LUOGO-NAAR"
				},

				/**
				 * Luogo Fornitore
				 */
				LUOGOFORN: {
					id: 5118,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.LUOGOFORN"
				},

				/**
				 * Nome / Codice Luogo fornitore
				 */
				LUOGOFORNITORE: {
					id: 5119,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.LUOGOFORNITORE"
				},

				/**
				 * Luogo Naar
				 */
				LUOGONAAR: {
					id: 5120,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.LUOGONAAR"
				},

				/**
				 * Mappato
				 */
				MAPPATO: {
					id: 5121,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.MAPPATO"
				},

				/**
				 * Mapping
				 */
				MAPPING: {
					id: 5122,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.MAPPING"
				},

				/**
				 * Non mappato
				 */
				NONMAPPATO: {
					id: 5123,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.NONMAPPATO"
				},

				/**
				 * Paese
				 */
				PAESE: {
					id: 5124,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.PAESE"
				},

				/**
				 * Pri. articoli
				 */
				PRI_ARTICOLI: {
					id: 5125,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.PRI-ARTICOLI"
				},

				/**
				 * Pri. articoli non valida
				 */
				PRI_ARTICOLI_NON_VALIDO: {
					id: 5126,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.PRI-ARTICOLI-NON-VALIDO"
				},

				/**
				 * Pri. immagini
				 */
				PRI_IMMAGINI: {
					id: 5127,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.PRI-IMMAGINI"
				},

				/**
				 * Pri. immagini non valida
				 */
				PRI_IMMAGINI_NON_VALIDO: {
					id: 5128,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.PRI-IMMAGINI-NON-VALIDO"
				},

				/**
				 * Pri. info
				 */
				PRI_INFO: {
					id: 5129,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.PRI-INFO"
				},

				/**
				 * Pri. info non valida
				 */
				PRI_INFO_NON_VALIDO: {
					id: 5130,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.PRI-INFO-NON-VALIDO"
				},

				/**
				 * Priorità
				 */
				PRIORITA: {
					id: 5131,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.PRIORITA"
				},

				/**
				 * Priorità non valida
				 */
				PRIORITA_NON_VALIDO: {
					id: 5132,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.PRIORITA-NON-VALIDO"
				},

				/**
				 * Qualsiasi
				 */
				QUALSIASI: {
					id: 5133,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.QUALSIASI"
				},

				/**
				 * Tipo location
				 */
				TIPO_LOCATION: {
					id: 5134,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.TIPO-LOCATION"
				},

				/**
				 * Tipo Luogo
				 */
				TIPOLUOGO: {
					id: 5135,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.TIPOLUOGO"
				},

				/**
				 * Gestione Luoghi H2H
				 */
				TITOLO: {
					id: 5136,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.TITOLO"
				},

				/**
				 * Status
				 */
				STATUS: {
					id: 6132,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.STATUS"
				},

				/**
				 * ANAGRAFICHE.LUOGHIH2H.ELIMINAZIONE-LUOGOH2H
				 */
				ELIMINAZIONE_LUOGOH2H: {
					id: 6353,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.ELIMINAZIONE-LUOGOH2H"
				},

				/**
				 * ANAGRAFICHE.LUOGHIH2H.LUOGOH2H-ELIMINATO
				 */
				LUOGOH2H_ELIMINATO: {
					id: 6410,
					label: "intranet.ANAGRAFICHE.LUOGHIH2H.LUOGOH2H-ELIMINATO"
				},

			},

			/**
			 * Content of path intranet.ANAGRAFICHE.OPERATORI
			 */
			OPERATORI: {
				/**
				 * Content of path intranet.ANAGRAFICHE.OPERATORI.LISTA
				 */
				LISTA: {
					/**
					 * Contatto
					 */
					CONTATTO: {
						id: 5137,
						label: "intranet.ANAGRAFICHE.OPERATORI.LISTA.CONTATTO"
					},

					/**
					 * ID
					 */
					ID: {
						id: 5138,
						label: "intranet.ANAGRAFICHE.OPERATORI.LISTA.ID"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 5139,
						label: "intranet.ANAGRAFICHE.OPERATORI.LISTA.NOME"
					},

					/**
					 * Nome visualizzato
					 */
					NOME_VISUALIZZATO: {
						id: 5140,
						label: "intranet.ANAGRAFICHE.OPERATORI.LISTA.NOME-VISUALIZZATO"
					},

					/**
					 * Nome / ID
					 */
					NOME_O_ID: {
						id: 5141,
						label: "intranet.ANAGRAFICHE.OPERATORI.LISTA.NOME_O_ID"
					},

					/**
					 * Valuta
					 */
					VALUTA: {
						id: 5142,
						label: "intranet.ANAGRAFICHE.OPERATORI.LISTA.VALUTA"
					},

				},

				/**
				 * Operatori
				 */
				OPERATORI: {
					id: 5143,
					label: "intranet.ANAGRAFICHE.OPERATORI.OPERATORI"
				},

				/**
				 * Ricerca operatori
				 */
				RICERCA_OPERATORI: {
					id: 5144,
					label: "intranet.ANAGRAFICHE.OPERATORI.RICERCA-OPERATORI"
				},

			},

			/**
			 * Immagini
			 */
			SERVIZI: {
				id: 6091,
				label: "intranet.ANAGRAFICHE.SERVIZI.IMMAGINI"
			},

		},

		/**
		 * Content of path intranet.AUTH
		 */
		AUTH: {
			/**
			 * Content of path intranet.AUTH.LOGIN
			 */
			LOGIN: {
				/**
				 * Accedi
				 */
				ACCEDI: {
					id: 5145,
					label: "intranet.AUTH.LOGIN.ACCEDI"
				},

				/**
				 * Accesso non riuscito
				 */
				ACCESSO_NON_RIUSCITO: {
					id: 5146,
					label: "intranet.AUTH.LOGIN.ACCESSO-NON-RIUSCITO"
				},

				/**
				 * Login
				 */
				LOGIN: {
					id: 5147,
					label: "intranet.AUTH.LOGIN.LOGIN"
				},

				/**
				 * Nome utente e/o password errati
				 */
				NOME_O_PASSWORD_ERRATI: {
					id: 5148,
					label: "intranet.AUTH.LOGIN.NOME-O-PASSWORD-ERRATI"
				},

				/**
				 * Password
				 */
				PASSWORD: {
					id: 5149,
					label: "intranet.AUTH.LOGIN.PASSWORD"
				},

				/**
				 * Compilare la password
				 */
				PASSWORD_OBBLIGATORIA: {
					id: 5150,
					label: "intranet.AUTH.LOGIN.PASSWORD-OBBLIGATORIA"
				},

				/**
				 * Accesso alla intranet
				 */
				TITLE: {
					id: 5152,
					label: "intranet.AUTH.LOGIN.TITLE"
				},

				/**
				 * Utente
				 */
				UTENTE: {
					id: 5153,
					label: "intranet.AUTH.LOGIN.UTENTE"
				},

				/**
				 * Compilare il campo utente (email)
				 */
				EMAIL_OBBLIGATORIA: {
					id: 5154,
					label: "intranet.AUTH.LOGIN.EMAIL-OBBLIGATORIA"
				},

				/**
				 * Reimposta password
				 */
				RECUPERO_PASSWORD: {
					id: 6027,
					label: "intranet.AUTH.LOGIN.RECUPERO-PASSWORD"
				},

				/**
				 * Se non riesci a ricordare la password usa il link seguente per reimpostarla.
				 */
				PASSWORD_RECOVER_MSG: {
					id: 6028,
					label: "intranet.AUTH.LOGIN.PASSWORD-RECOVER-MSG"
				},

				/**
				 * Inserisci la password
				 */
				PASSWORD_TIP: {
					id: 6029,
					label: "intranet.AUTH.LOGIN.PASSWORD-TIP.MSG"
				},

				/**
				 * Inserisci la tua email
				 */
				USER_TIP: {
					id: 6030,
					label: "intranet.AUTH.LOGIN.USER-TIP.MSG"
				},

				/**
				 * Richiedi reimposta password
				 */
				RICHIEDI_RECUPERO_PASSWORD: {
					id: 6033,
					label: "intranet.AUTH.LOGIN.RICHIEDI-RECUPERO-PASSWORD"
				},

				/**
				 * Inserisci la mail che usi per loggarti. Sarà l'indirizzo al quale verrà spedito un link che ti permetterà di ridefinire la password.
				 */
				RECUPERO_PASSWORD_MSG: {
					id: 6034,
					label: "intranet.AUTH.LOGIN.RECUPERO-PASSWORD-MSG"
				},

				/**
				 * Formato della mail non valido
				 */
				FORMATO_ERRATO: {
					id: 6035,
					label: "intranet.AUTH.LOGIN.FORMATO-ERRATO"
				},

				/**
				 * Utente (email)
				 */
				EMAIL: {
					id: 6036,
					label: "intranet.AUTH.LOGIN.EMAIL"
				},

				/**
				 * Content of path intranet.AUTH.LOGIN.UTENTE-OBBLIGATORIO
				 */
				UTENTE_OBBLIGATORIO: {
				},

			},

			/**
			 * Content of path intranet.AUTH.LOGOUT
			 */
			LOGOUT: {
				/**
				 * Disconnessione in corso
				 */
				DISCONNESSIONE_IN_CORSO: {
					id: 5155,
					label: "intranet.AUTH.LOGOUT.DISCONNESSIONE-IN-CORSO"
				},

				/**
				 * Logout
				 */
				LOGOUT: {
					id: 5156,
					label: "intranet.AUTH.LOGOUT.LOGOUT"
				},

			},

		},

		/**
		 * Content of path intranet.BIGLIETTERIA
		 */
		BIGLIETTERIA: {
			/**
			 * Content of path intranet.BIGLIETTERIA.EMISSIONI
			 */
			EMISSIONI: {
				/**
				 * Agenzia
				 */
				AGENZIA: {
					id: 5157,
					label: "intranet.BIGLIETTERIA.EMISSIONI.AGENZIA"
				},

				/**
				 * al
				 */
				AL: {
					id: 5158,
					label: "intranet.BIGLIETTERIA.EMISSIONI.AL"
				},

				/**
				 * Data emissione
				 */
				DATA_EMISSIONE: {
					id: 5159,
					label: "intranet.BIGLIETTERIA.EMISSIONI.DATA-EMISSIONE"
				},

				/**
				 * Data pagamento
				 */
				DATA_PAGAMENTO: {
					id: 5160,
					label: "intranet.BIGLIETTERIA.EMISSIONI.DATA-PAGAMENTO"
				},

				/**
				 * Data presa in carico
				 */
				DATA_PRESA_IN_CARICO: {
					id: 5161,
					label: "intranet.BIGLIETTERIA.EMISSIONI.DATA-PRESA-IN-CARICO"
				},

				/**
				 * Data richiesta
				 */
				DATA_RICHIESTA: {
					id: 5162,
					label: "intranet.BIGLIETTERIA.EMISSIONI.DATA-RICHIESTA"
				},

				/**
				 * Descrizione
				 */
				DESCRIZIONE: {
					id: 5163,
					label: "intranet.BIGLIETTERIA.EMISSIONI.DESCRIZIONE"
				},

				/**
				 * Content of path intranet.BIGLIETTERIA.EMISSIONI.DROPDOWN
				 */
				DROPDOWN: {
					/**
					 * Imposta come emesso
					 */
					EMESSO: {
						id: 5164,
						label: "intranet.BIGLIETTERIA.EMISSIONI.DROPDOWN.EMESSO"
					},

					/**
					 * Imposta Ok contabilità
					 */
					OK_CONTABILE: {
						id: 5165,
						label: "intranet.BIGLIETTERIA.EMISSIONI.DROPDOWN.OK-CONTABILE"
					},

					/**
					 * Segna come preso in carico
					 */
					PRESO_IN_CARICO: {
						id: 5166,
						label: "intranet.BIGLIETTERIA.EMISSIONI.DROPDOWN.PRESO-IN-CARICO"
					},

				},

				/**
				 * Emesso
				 */
				EMESSO: {
					id: 5167,
					label: "intranet.BIGLIETTERIA.EMISSIONI.EMESSO"
				},

				/**
				 * Emissioni
				 */
				EMISSIONI: {
					id: 5168,
					label: "intranet.BIGLIETTERIA.EMISSIONI.EMISSIONI"
				},

				/**
				 * ID Offerta
				 */
				ID_OFFERTA: {
					id: 5169,
					label: "intranet.BIGLIETTERIA.EMISSIONI.ID-OFFERTA"
				},

				/**
				 * Offerta
				 */
				OFFERTA: {
					id: 5170,
					label: "intranet.BIGLIETTERIA.EMISSIONI.OFFERTA"
				},

				/**
				 * Ok contab.
				 */
				OK_CONTAB: {
					id: 5171,
					label: "intranet.BIGLIETTERIA.EMISSIONI.OK-CONTAB"
				},

				/**
				 * Paga subito
				 */
				PAGA_SUBITO: {
					id: 5172,
					label: "intranet.BIGLIETTERIA.EMISSIONI.PAGA-SUBITO"
				},

				/**
				 * Pagamento
				 */
				PAGAMENTO: {
					id: 5173,
					label: "intranet.BIGLIETTERIA.EMISSIONI.PAGAMENTO"
				},

				/**
				 * Partenza
				 */
				PARTENZA: {
					id: 5174,
					label: "intranet.BIGLIETTERIA.EMISSIONI.PARTENZA"
				},

				/**
				 * Partenza dal
				 */
				PARTENZA_DAL: {
					id: 5175,
					label: "intranet.BIGLIETTERIA.EMISSIONI.PARTENZA-DAL"
				},

				/**
				 * PNR
				 */
				PNR: {
					id: 5176,
					label: "intranet.BIGLIETTERIA.EMISSIONI.PNR"
				},

				/**
				 * Scad. emiss. dal
				 */
				SCAD_EMISS_DAL: {
					id: 5177,
					label: "intranet.BIGLIETTERIA.EMISSIONI.SCAD-EMISS-DAL"
				},

				/**
				 * Scad. pag. dal
				 */
				SCAD_PAG_DAL: {
					id: 5178,
					label: "intranet.BIGLIETTERIA.EMISSIONI.SCAD-PAG-DAL"
				},

				/**
				 * Ut. conferma
				 */
				UT_CONFERMA: {
					id: 5179,
					label: "intranet.BIGLIETTERIA.EMISSIONI.UT-CONFERMA"
				},

				/**
				 * Operatore
				 */
				OPERATORE: {
					id: 6002,
					label: "intranet.BIGLIETTERIA.EMISSIONI.OPERATORE"
				},

			},

		},

		/**
		 * Content of path intranet.CALENDARIO
		 */
		CALENDARIO: {
			/**
			 * Content of path intranet.CALENDARIO.GIORNI_ABBREVIATI
			 */
			GIORNI_ABBREVIATI: {
				/**
				 * Do
				 */
				DOMENICA: {
					id: 5180,
					label: "intranet.CALENDARIO.GIORNI_ABBREVIATI.DOMENICA"
				},

				/**
				 * Gi
				 */
				GIOVEDI: {
					id: 5181,
					label: "intranet.CALENDARIO.GIORNI_ABBREVIATI.GIOVEDI"
				},

				/**
				 * Lu
				 */
				LUNEDI: {
					id: 5182,
					label: "intranet.CALENDARIO.GIORNI_ABBREVIATI.LUNEDI"
				},

				/**
				 * Ma
				 */
				MARTEDI: {
					id: 5183,
					label: "intranet.CALENDARIO.GIORNI_ABBREVIATI.MARTEDI"
				},

				/**
				 * Me
				 */
				MERCOLEDI: {
					id: 5184,
					label: "intranet.CALENDARIO.GIORNI_ABBREVIATI.MERCOLEDI"
				},

				/**
				 * Sa
				 */
				SABATO: {
					id: 5185,
					label: "intranet.CALENDARIO.GIORNI_ABBREVIATI.SABATO"
				},

				/**
				 * Ve
				 */
				VENERDI: {
					id: 5186,
					label: "intranet.CALENDARIO.GIORNI_ABBREVIATI.VENERDI"
				},

			},

			/**
			 * Content of path intranet.CALENDARIO.GIORNI_COMPLETI
			 */
			GIORNI_COMPLETI: {
				/**
				 * Domenica
				 */
				DOMENICA: {
					id: 5187,
					label: "intranet.CALENDARIO.GIORNI_COMPLETI.DOMENICA"
				},

				/**
				 * Giovedì
				 */
				GIOVEDI: {
					id: 5188,
					label: "intranet.CALENDARIO.GIORNI_COMPLETI.GIOVEDI"
				},

				/**
				 * Lunedì
				 */
				LUNEDI: {
					id: 5189,
					label: "intranet.CALENDARIO.GIORNI_COMPLETI.LUNEDI"
				},

				/**
				 * Martedì
				 */
				MARTEDI: {
					id: 5190,
					label: "intranet.CALENDARIO.GIORNI_COMPLETI.MARTEDI"
				},

				/**
				 * Mercoledì
				 */
				MERCOLEDI: {
					id: 5191,
					label: "intranet.CALENDARIO.GIORNI_COMPLETI.MERCOLEDI"
				},

				/**
				 * Sabato
				 */
				SABATO: {
					id: 5192,
					label: "intranet.CALENDARIO.GIORNI_COMPLETI.SABATO"
				},

				/**
				 * Venerdì
				 */
				VENERDI: {
					id: 5193,
					label: "intranet.CALENDARIO.GIORNI_COMPLETI.VENERDI"
				},

			},

			/**
			 * Content of path intranet.CALENDARIO.GIORNI_CORTI
			 */
			GIORNI_CORTI: {
				/**
				 * Dom
				 */
				DOMENICA: {
					id: 5194,
					label: "intranet.CALENDARIO.GIORNI_CORTI.DOMENICA"
				},

				/**
				 * Gio
				 */
				GIOVEDI: {
					id: 5195,
					label: "intranet.CALENDARIO.GIORNI_CORTI.GIOVEDI"
				},

				/**
				 * Lun
				 */
				LUNEDI: {
					id: 5196,
					label: "intranet.CALENDARIO.GIORNI_CORTI.LUNEDI"
				},

				/**
				 * Mar
				 */
				MARTEDI: {
					id: 5197,
					label: "intranet.CALENDARIO.GIORNI_CORTI.MARTEDI"
				},

				/**
				 * Mer
				 */
				MERCOLEDI: {
					id: 5198,
					label: "intranet.CALENDARIO.GIORNI_CORTI.MERCOLEDI"
				},

				/**
				 * Sab
				 */
				SABATO: {
					id: 5199,
					label: "intranet.CALENDARIO.GIORNI_CORTI.SABATO"
				},

				/**
				 * Ven
				 */
				VENERDI: {
					id: 5200,
					label: "intranet.CALENDARIO.GIORNI_CORTI.VENERDI"
				},

			},

			/**
			 * Content of path intranet.CALENDARIO.GIORNI_INIZIALE
			 */
			GIORNI_INIZIALE: {
				/**
				 * D
				 */
				DOMENICA: {
					id: 5201,
					label: "intranet.CALENDARIO.GIORNI_INIZIALE.DOMENICA"
				},

				/**
				 * G
				 */
				GIOVEDI: {
					id: 5202,
					label: "intranet.CALENDARIO.GIORNI_INIZIALE.GIOVEDI"
				},

				/**
				 * L
				 */
				LUNEDI: {
					id: 5203,
					label: "intranet.CALENDARIO.GIORNI_INIZIALE.LUNEDI"
				},

				/**
				 * M
				 */
				MARTEDI: {
					id: 5204,
					label: "intranet.CALENDARIO.GIORNI_INIZIALE.MARTEDI"
				},

				/**
				 * m
				 */
				MERCOLEDI: {
					id: 5205,
					label: "intranet.CALENDARIO.GIORNI_INIZIALE.MERCOLEDI"
				},

				/**
				 * S
				 */
				SABATO: {
					id: 5206,
					label: "intranet.CALENDARIO.GIORNI_INIZIALE.SABATO"
				},

				/**
				 * V
				 */
				VENERDI: {
					id: 5207,
					label: "intranet.CALENDARIO.GIORNI_INIZIALE.VENERDI"
				},

			},

			/**
			 * Content of path intranet.CALENDARIO.MESI_ABBREVIATI
			 */
			MESI_ABBREVIATI: {
				/**
				 * Ago
				 */
				AGOSTO: {
					id: 5208,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI.AGOSTO"
				},

				/**
				 * Apr
				 */
				APRILE: {
					id: 5209,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI.APRILE"
				},

				/**
				 * Dic
				 */
				DICEMBRE: {
					id: 5210,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI.DICEMBRE"
				},

				/**
				 * Feb
				 */
				FEBBRAIO: {
					id: 5211,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI.FEBBRAIO"
				},

				/**
				 * Gen
				 */
				GENNAIO: {
					id: 5212,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI.GENNAIO"
				},

				/**
				 * Giu
				 */
				GIUGNO: {
					id: 5213,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI.GIUGNO"
				},

				/**
				 * Lug
				 */
				LUGLIO: {
					id: 5214,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI.LUGLIO"
				},

				/**
				 * Mag
				 */
				MAGGIO: {
					id: 5215,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI.MAGGIO"
				},

				/**
				 * Mar
				 */
				MARZO: {
					id: 5216,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI.MARZO"
				},

				/**
				 * Nov
				 */
				NOVEMBRE: {
					id: 5217,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI.NOVEMBRE"
				},

				/**
				 * Ott
				 */
				OTTOBRE: {
					id: 5218,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI.OTTOBRE"
				},

				/**
				 * Set
				 */
				SETTEMBRE: {
					id: 5219,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI.SETTEMBRE"
				},

			},

			/**
			 * Content of path intranet.CALENDARIO.MESI_ABBREVIATI_ID
			 */
			MESI_ABBREVIATI_ID: {
				/**
				 * 
				 */
				_1: {
					id: 5220,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI_ID.1"
				},

				/**
				 * 
				 */
				_2: {
					id: 5221,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI_ID.2"
				},

				/**
				 * 
				 */
				_3: {
					id: 5222,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI_ID.3"
				},

				/**
				 * 
				 */
				_4: {
					id: 5223,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI_ID.4"
				},

				/**
				 * 
				 */
				_5: {
					id: 5224,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI_ID.5"
				},

				/**
				 * 
				 */
				_6: {
					id: 5225,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI_ID.6"
				},

				/**
				 * 
				 */
				_7: {
					id: 5226,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI_ID.7"
				},

				/**
				 * 
				 */
				_8: {
					id: 5227,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI_ID.8"
				},

				/**
				 * 
				 */
				_9: {
					id: 5228,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI_ID.9"
				},

				/**
				 * 
				 */
				_10: {
					id: 5229,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI_ID.10"
				},

				/**
				 * 
				 */
				_11: {
					id: 5230,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI_ID.11"
				},

				/**
				 * 
				 */
				_12: {
					id: 5231,
					label: "intranet.CALENDARIO.MESI_ABBREVIATI_ID.12"
				},

			},

			/**
			 * Content of path intranet.CALENDARIO.MESI_COMPLETI
			 */
			MESI_COMPLETI: {
				/**
				 * Agosto
				 */
				AGOSTO: {
					id: 5232,
					label: "intranet.CALENDARIO.MESI_COMPLETI.AGOSTO"
				},

				/**
				 * Aprile
				 */
				APRILE: {
					id: 5233,
					label: "intranet.CALENDARIO.MESI_COMPLETI.APRILE"
				},

				/**
				 * Dicembre
				 */
				DICEMBRE: {
					id: 5234,
					label: "intranet.CALENDARIO.MESI_COMPLETI.DICEMBRE"
				},

				/**
				 * Febbraio
				 */
				FEBBRAIO: {
					id: 5235,
					label: "intranet.CALENDARIO.MESI_COMPLETI.FEBBRAIO"
				},

				/**
				 * Gennaio
				 */
				GENNAIO: {
					id: 5236,
					label: "intranet.CALENDARIO.MESI_COMPLETI.GENNAIO"
				},

				/**
				 * Giugno
				 */
				GIUGNO: {
					id: 5237,
					label: "intranet.CALENDARIO.MESI_COMPLETI.GIUGNO"
				},

				/**
				 * Luglio
				 */
				LUGLIO: {
					id: 5238,
					label: "intranet.CALENDARIO.MESI_COMPLETI.LUGLIO"
				},

				/**
				 * Maggio
				 */
				MAGGIO: {
					id: 5239,
					label: "intranet.CALENDARIO.MESI_COMPLETI.MAGGIO"
				},

				/**
				 * Marzo
				 */
				MARZO: {
					id: 5240,
					label: "intranet.CALENDARIO.MESI_COMPLETI.MARZO"
				},

				/**
				 * Novembre
				 */
				NOVEMBRE: {
					id: 5241,
					label: "intranet.CALENDARIO.MESI_COMPLETI.NOVEMBRE"
				},

				/**
				 * Ottobre
				 */
				OTTOBRE: {
					id: 5242,
					label: "intranet.CALENDARIO.MESI_COMPLETI.OTTOBRE"
				},

				/**
				 * Settembre
				 */
				SETTEMBRE: {
					id: 5243,
					label: "intranet.CALENDARIO.MESI_COMPLETI.SETTEMBRE"
				},

			},

		},

		/**
		 * Content of path intranet.COMMON
		 */
		COMMON: {
			/**
			 * adulti
			 */
			ADULTI_GENERICO: {
				id: 5244,
				label: "intranet.COMMON.ADULTI-GENERICO"
			},

			/**
			 * Aggiungi
			 */
			AGGIUNGI: {
				id: 5245,
				label: "intranet.COMMON.AGGIUNGI"
			},

			/**
			 * Aiuto su{titolo}
			 */
			AIUTO_SU: {
				id: 5246,
				label: "intranet.COMMON.AIUTO-SU"
			},

			/**
			 * anni
			 */
			ANNI_GENERICO: {
				id: 5247,
				label: "intranet.COMMON.ANNI-GENERICO"
			},

			/**
			 * Annulla
			 */
			ANNULLA: {
				id: 5248,
				label: "intranet.COMMON.ANNULLA"
			},

			/**
			 * Annulla Luogo
			 */
			ANNULLA_LUOGO: {
				id: 5249,
				label: "intranet.COMMON.ANNULLA-LUOGO"
			},

			/**
			 * Anteprima
			 */
			ANTEPRIMA: {
				id: 5250,
				label: "intranet.COMMON.ANTEPRIMA"
			},

			/**
			 * Apri
			 */
			APRI: {
				id: 5251,
				label: "intranet.COMMON.APRI"
			},

			/**
			 * Apri tutti
			 */
			APRI_TUTTI: {
				id: 5252,
				label: "intranet.COMMON.APRI-TUTTI"
			},

			/**
			 * Associa
			 */
			ASSOCIA: {
				id: 5253,
				label: "intranet.COMMON.ASSOCIA"
			},

			/**
			 * Attendi...
			 */
			ATTENDI: {
				id: 5254,
				label: "intranet.COMMON.ATTENDI"
			},

			/**
			 * Attenzione
			 */
			ATTENZIONE: {
				id: 5255,
				label: "intranet.COMMON.ATTENZIONE"
			},

			/**
			 * Attenzione! Questo elemento è stato eliminato
			 */
			ATTENZIONE_ELIMINATO: {
				id: 5256,
				label: "intranet.COMMON.ATTENZIONE-ELIMINATO"
			},

			/**
			 * bambini
			 */
			BAMBINI_GENERICO: {
				id: 5257,
				label: "intranet.COMMON.BAMBINI-GENERICO"
			},

			/**
			 * Cambia
			 */
			CAMBIA: {
				id: 5258,
				label: "intranet.COMMON.CAMBIA"
			},

			/**
			 * Cancella
			 */
			CANCELLA: {
				id: 5259,
				label: "intranet.COMMON.CANCELLA"
			},

			/**
			 * Caricamento...
			 */
			CARICAMENTO: {
				id: 5260,
				label: "intranet.COMMON.CARICAMENTO"
			},

			/**
			 * Cerca
			 */
			CERCA: {
				id: 5261,
				label: "intranet.COMMON.CERCA"
			},

			/**
			 * Cestino
			 */
			CESTINO: {
				id: 5262,
				label: "intranet.COMMON.CESTINO"
			},

			/**
			 * Chiudi
			 */
			CHIUDI: {
				id: 5263,
				label: "intranet.COMMON.CHIUDI"
			},

			/**
			 * Conferma
			 */
			CONFERMA: {
				id: 5264,
				label: "intranet.COMMON.CONFERMA"
			},

			/**
			 * Conferma Luogo
			 */
			CONFERMA_LUOGO: {
				id: 5265,
				label: "intranet.COMMON.CONFERMA-LUOGO"
			},

			/**
			 * Copia
			 */
			COPIA: {
				id: 5266,
				label: "intranet.COMMON.COPIA"
			},

			/**
			 * Crea
			 */
			CREA: {
				id: 5267,
				label: "intranet.COMMON.CREA"
			},

			/**
			 * Dati copiati
			 */
			DATI_COPIATI: {
				id: 5268,
				label: "intranet.COMMON.DATI-COPIATI"
			},

			/**
			 * Dati eliminati
			 */
			DATI_ELIMINATI: {
				id: 5269,
				label: "intranet.COMMON.DATI-ELIMINATI"
			},

			/**
			 * Dati ripristinati
			 */
			DATI_RIPRISTINATI: {
				id: 5270,
				label: "intranet.COMMON.DATI-RIPRISTINATI"
			},

			/**
			 * Dati salvati
			 */
			DATI_SALVATI: {
				id: 5271,
				label: "intranet.COMMON.DATI-SALVATI"
			},

			/**
			 * -- Default --
			 */
			DEFAULT: {
				id: 5272,
				label: "intranet.COMMON.DEFAULT"
			},

			/**
			 * Deseleziona tutto
			 */
			DESELEZIONA_TUTTO: {
				id: 5273,
				label: "intranet.COMMON.DESELEZIONA-TUTTO"
			},

			/**
			 * Dettagli
			 */
			DETTAGLI: {
				id: 5274,
				label: "intranet.COMMON.DETTAGLI"
			},

			/**
			 * Duplica
			 */
			DUPLICA: {
				id: 5275,
				label: "intranet.COMMON.DUPLICA"
			},

			/**
			 * Content of path intranet.COMMON.DYNAMIC-FORM
			 */
			DYNAMIC_FORM: {
				/**
				 * Aggiungi
				 */
				AGGIUNGI_VALORE: {
					id: 5276,
					label: "intranet.COMMON.DYNAMIC-FORM.AGGIUNGI-VALORE"
				},

				/**
				 * a
				 */
				DATA_A: {
					id: 5277,
					label: "intranet.COMMON.DYNAMIC-FORM.DATA-A"
				},

				/**
				 * da
				 */
				DATA_DA: {
					id: 5278,
					label: "intranet.COMMON.DYNAMIC-FORM.DATA-DA"
				},

			},

			/**
			 * Elimina
			 */
			ELIMINA: {
				id: 5279,
				label: "intranet.COMMON.ELIMINA"
			},

			/**
			 * Errore
			 */
			ERRORE: {
				id: 5280,
				label: "intranet.COMMON.ERRORE"
			},

			/**
			 * Esporta
			 */
			ESPORTA: {
				id: 5281,
				label: "intranet.COMMON.ESPORTA"
			},

			/**
			 * {giorni} giorn{giorni, plural, =0{i} one{o} other{i}}
			 */
			GIORNI: {
				id: 5282,
				label: "intranet.COMMON.GIORNI"
			},

			/**
			 * giorni
			 */
			GIORNI_GENERICO: {
				id: 5283,
				label: "intranet.COMMON.GIORNI-GENERICO"
			},

			/**
			 * {n}° giorno
			 */
			GIORNO_N: {
				id: 5284,
				label: "intranet.COMMON.GIORNO-N"
			},

			/**
			 * Impossibile salvare
			 */
			IMPOSSIBILE_SALVARE: {
				id: 5285,
				label: "intranet.COMMON.IMPOSSIBILE-SALVARE"
			},

			/**
			 * Incolla
			 */
			INCOLLA: {
				id: 5286,
				label: "intranet.COMMON.INCOLLA"
			},

			/**
			 * infant
			 */
			INFANT_GENERICO: {
				id: 5287,
				label: "intranet.COMMON.INFANT-GENERICO"
			},

			/**
			 * Naar Intranet
			 */
			INTRANET_NAME: {
				id: 5288,
				label: "intranet.COMMON.INTRANET-NAME"
			},

			/**
			 * righe
			 */
			ITEMS: {
				id: 5289,
				label: "intranet.COMMON.ITEMS"
			},

			/**
			 * Lingua
			 */
			LINGUA: {
				id: 5290,
				label: "intranet.COMMON.LINGUA"
			},

			/**
			 * Lingua visualizzata
			 */
			LINGUA_VISUALIZZATA: {
				id: 5291,
				label: "intranet.COMMON.LINGUA-VISUALIZZATA"
			},

			/**
			 * Link diretto
			 */
			LINK_DIRETTO: {
				id: 5292,
				label: "intranet.COMMON.LINK-DIRETTO"
			},

			/**
			 * Modifica
			 */
			MODIFICA: {
				id: 5293,
				label: "intranet.COMMON.MODIFICA"
			},

			/**
			 * Modifiche annullate
			 */
			MODIFICHE_ANNULLATE: {
				id: 5294,
				label: "intranet.COMMON.MODIFICHE-ANNULLATE"
			},

			/**
			 * Mostra eliminati
			 */
			MOSTRA_ELIMINATI: {
				id: 5295,
				label: "intranet.COMMON.MOSTRA-ELIMINATI"
			},

			/**
			 * {qty} per pagina
			 */
			N_PER_PAGINA: {
				id: 5296,
				label: "intranet.COMMON.N-PER-PAGINA"
			},

			/**
			 * -- Nessuno --
			 */
			NESSUNO: {
				id: 5297,
				label: "intranet.COMMON.NESSUNO"
			},

			/**
			 * {notti} nott{notti, plural, =0{i} one{e} other{i}}
			 */
			NOTTI: {
				id: 5298,
				label: "intranet.COMMON.NOTTI"
			},

			/**
			 * notti
			 */
			NOTTI_GENERICO: {
				id: 5299,
				label: "intranet.COMMON.NOTTI-GENERICO"
			},

			/**
			 * Nuovo
			 */
			NUOVO: {
				id: 5300,
				label: "intranet.COMMON.NUOVO"
			},

			/**
			 * di
			 */
			OF: {
				id: 5301,
				label: "intranet.COMMON.OF"
			},

			/**
			 * Ok
			 */
			OK: {
				id: 5302,
				label: "intranet.COMMON.OK"
			},

			/**
			 * Operazione completata
			 */
			OPERAZIONE_COMPLETATA: {
				id: 5303,
				label: "intranet.COMMON.OPERAZIONE-COMPLETATA"
			},

			/**
			 * Pagina
			 */
			PAGINA: {
				id: 5304,
				label: "intranet.COMMON.PAGINA"
			},

			/**
			 * righe per pagina
			 */
			PER_PAGINA: {
				id: 5305,
				label: "intranet.COMMON.PER-PAGINA"
			},

			/**
			 * Pagina precedente
			 */
			PRECEDENTE_PAGINA: {
				id: 5306,
				label: "intranet.COMMON.PRECEDENTE-PAGINA"
			},

			/**
			 * Prima pagina
			 */
			PRIMA_PAGINA: {
				id: 5307,
				label: "intranet.COMMON.PRIMA-PAGINA"
			},

			/**
			 * Pagina successiva
			 */
			PROSSIMA_PAGINA: {
				id: 5308,
				label: "intranet.COMMON.PROSSIMA-PAGINA"
			},

			/**
			 * -- Qualsiasi --
			 */
			QUALSIASI: {
				id: 5309,
				label: "intranet.COMMON.QUALSIASI"
			},

			/**
			 * Reimposta
			 */
			REIMPOSTA: {
				id: 5310,
				label: "intranet.COMMON.REIMPOSTA"
			},

			/**
			 * Rimuovi
			 */
			RIMUOVI: {
				id: 5311,
				label: "intranet.COMMON.RIMUOVI"
			},

			/**
			 * Ripeti
			 */
			RIPETI: {
				id: 5312,
				label: "intranet.COMMON.RIPETI"
			},

			/**
			 * Ripristina
			 */
			RIPRISTINA: {
				id: 5313,
				label: "intranet.COMMON.RIPRISTINA"
			},

			/**
			 * Salva
			 */
			SALVA: {
				id: 5314,
				label: "intranet.COMMON.SALVA"
			},

			/**
			 * Salva e aggiungi
			 */
			SALVA_AGGIUNGI: {
				id: 5315,
				label: "intranet.COMMON.SALVA-AGGIUNGI"
			},

			/**
			 * Scarica Markdown
			 */
			SCARICA_MARKDOWN: {
				id: 5316,
				label: "intranet.COMMON.SCARICA-MARKDOWN"
			},

			/**
			 * Scarica PDF
			 */
			SCARICA_PDF: {
				id: 5317,
				label: "intranet.COMMON.SCARICA-PDF"
			},

			/**
			 * -- Scegli --
			 */
			SCEGLI: {
				id: 5318,
				label: "intranet.COMMON.SCEGLI"
			},

			/**
			 * Seleziona tutto
			 */
			SELEZIONA_TUTTO: {
				id: 5319,
				label: "intranet.COMMON.SELEZIONA-TUTTO"
			},

			/**
			 * {selected} selezionat{selected, plural, =0{e} one{a} other{e}}
			 */
			SELEZIONATE: {
				id: 5320,
				label: "intranet.COMMON.SELEZIONATE"
			},

			/**
			 * {selected} selezionat{selected, plural, =0{i} one{o} other{i}}
			 */
			SELEZIONATI: {
				id: 5321,
				label: "intranet.COMMON.SELEZIONATI"
			},

			/**
			 * Stampa
			 */
			STAMPA: {
				id: 5322,
				label: "intranet.COMMON.STAMPA"
			},

			/**
			 * Svuota
			 */
			SVUOTA: {
				id: 5323,
				label: "intranet.COMMON.SVUOTA"
			},

			/**
			 * Taglia
			 */
			TAGLIA: {
				id: 5324,
				label: "intranet.COMMON.TAGLIA"
			},

			/**
			 * Content of path intranet.COMMON.TEXTS-COMPOSER
			 */
			TEXTS_COMPOSER: {
				/**
				 * Sposta qui una frase...
				 */
				EMPTY_SECTION_ITEM: {
					id: 5325,
					label: "intranet.COMMON.TEXTS-COMPOSER.EMPTY-SECTION-ITEM"
				},

				/**
				 * Seleziona una sezione ed aggiungila...
				 */
				EMPTY_TEXT_ITEM: {
					id: 5326,
					label: "intranet.COMMON.TEXTS-COMPOSER.EMPTY-TEXT-ITEM"
				},

				/**
				 * Aggiungi sezione
				 */
				SELEZIONA_SEZIONE_ADD: {
					id: 5327,
					label: "intranet.COMMON.TEXTS-COMPOSER.SELEZIONA-SEZIONE-ADD"
				},

				/**
				 * Trascina la frase per spostarla...
				 */
				SPOSTA_FRASE: {
					id: 5328,
					label: "intranet.COMMON.TEXTS-COMPOSER.SPOSTA-FRASE"
				},

				/**
				 * Content of path intranet.COMMON.TEXTS-COMPOSER.TOOLBOX
				 */
				TOOLBOX: {
					/**
					 * Aggiungi frase
					 */
					AGGIUNGI_FRASE: {
						id: 5329,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.AGGIUNGI-FRASE"
					},

					/**
					 * Generiche
					 */
					AGGIUNGI_FRASE_GENERICHE: {
						id: 5330,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.AGGIUNGI-FRASE-GENERICHE"
					},

					/**
					 * Luoghi
					 */
					AGGIUNGI_FRASE_LUOGHI: {
						id: 5331,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.AGGIUNGI-FRASE-LUOGHI"
					},

					/**
					 * Crea nuova
					 */
					AGGIUNGI_FRASE_NUOVA: {
						id: 5332,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.AGGIUNGI-FRASE-NUOVA"
					},

					/**
					 * Servizi
					 */
					AGGIUNGI_FRASE_SERVIZI: {
						id: 5333,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.AGGIUNGI-FRASE-SERVIZI"
					},

					/**
					 * Aggiungi frase alla sezione
					 */
					AGGIUNGI_FRASE_SEZIONE: {
						id: 5334,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.AGGIUNGI-FRASE-SEZIONE"
					},

					/**
					 * Apri la frase seleziona...
					 */
					APRI_FRASE: {
						id: 5335,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.APRI-FRASE"
					},

					/**
					 * Articolo
					 */
					ARTICOLO: {
						id: 5336,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.ARTICOLO"
					},

					/**
					 * Copia frasi dal giorno precedente
					 */
					COPIA_FRASI_G_PREC: {
						id: 5337,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.COPIA-FRASI-G-PREC"
					},

					/**
					 * Copia frasi dal giorno successivo
					 */
					COPIA_FRASI_G_SUCC: {
						id: 5338,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.COPIA-FRASI-G-SUCC"
					},

					/**
					 * Modifica le variabili della frase...
					 */
					EDIT_VARIABILI_FRASE: {
						id: 5339,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.EDIT-VARIABILI-FRASE"
					},

					/**
					 * Modifica le variabili
					 */
					EDITA_VARIABILI: {
						id: 5340,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.EDITA-VARIABILI"
					},

					/**
					 * Elimina frase
					 */
					ELIMINA_FRASE: {
						id: 5341,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.ELIMINA-FRASE"
					},

					/**
					 * Elimina sezione
					 */
					ELIMINA_SEZIONE: {
						id: 5342,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.ELIMINA-SEZIONE"
					},

					/**
					 * Frase
					 */
					FRASE: {
						id: 5343,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.FRASE"
					},

					/**
					 * Incolla
					 */
					INCOLLA: {
						id: 5344,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.INCOLLA"
					},

					/**
					 * No
					 */
					NO_TERMINATORE: {
						id: 5345,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.NO-TERMINATORE"
					},

					/**
					 * Risultati di ricerca per
					 */
					SEARCH_KEYS_LOCATIONS_SERVICES: {
						id: 5346,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.SEARCH-KEYS-LOCATIONS-SERVICES"
					},

					/**
					 * Sposta avanti di una frase
					 */
					SPOSTA_AVANTI: {
						id: 5347,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.SPOSTA-AVANTI"
					},

					/**
					 * Sposta indietro di una frase
					 */
					SPOSTA_INDIETRO: {
						id: 5348,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.SPOSTA-INDIETRO"
					},

					/**
					 * Sposta nella sezione precedente
					 */
					SPOSTA_SEZIONE_PRECEDENTE: {
						id: 5349,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.SPOSTA-SEZIONE-PRECEDENTE"
					},

					/**
					 * Sposta nella sezione successiva
					 */
					SPOSTA_SEZIONE_SUCCESSIVA: {
						id: 5350,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.SPOSTA-SEZIONE-SUCCESSIVA"
					},

					/**
					 * Terminatore
					 */
					TERMINATORE: {
						id: 5351,
						label: "intranet.COMMON.TEXTS-COMPOSER.TOOLBOX.TERMINATORE"
					},

				},

			},

			/**
			 * Traduci
			 */
			TRADUCI: {
				id: 5352,
				label: "intranet.COMMON.TRADUCI"
			},

			/**
			 * Traduzione in corso...
			 */
			TRADUZIONE_IN_CORSO: {
				id: 5353,
				label: "intranet.COMMON.TRADUZIONE-IN-CORSO"
			},

			/**
			 * Ultima pagina
			 */
			ULTIMA_PAGINA: {
				id: 5354,
				label: "intranet.COMMON.ULTIMA-PAGINA"
			},

			/**
			 * Verifica i dati inseriti
			 */
			VERIFICA_DATI: {
				id: 5355,
				label: "intranet.COMMON.VERIFICA-DATI"
			},

			/**
			 * Visualizza
			 */
			VISUALIZZA: {
				id: 5356,
				label: "intranet.COMMON.VISUALIZZA"
			},

			/**
			 * Visualizzat{shown, plural, =0{i} one{o} other{i}} {shown} di {total}
			 */
			VISUALIZZATI: {
				id: 5357,
				label: "intranet.COMMON.VISUALIZZATI"
			},

			/**
			 * Visualizzat{shown, plural, =0{i} one{o} other{i}} {start}-{end} di {total}
			 */
			VISUALIZZATI_RANGE: {
				id: 5358,
				label: "intranet.COMMON.VISUALIZZATI-RANGE"
			},

			/**
			 * Non definito
			 */
			UNDEFINED: {
				id: 5999,
				label: "intranet.COMMON.UNDEFINED"
			},

			/**
			 * Seleziona il file
			 */
			IMPORTA: {
				id: 6086,
				label: "intranet.COMMON.IMPORTA"
			},

			/**
			 * Seleziona un file da importare
			 */
			SCEGLI_FILE: {
				id: 6087,
				label: "intranet.COMMON.SCEGLI-FILE"
			},

			/**
			 * No
			 */
			NO: {
				id: 6113,
				label: "intranet.COMMON.NO"
			},

			/**
			 * Sì
			 */
			SI: {
				id: 6114,
				label: "intranet.COMMON.SI"
			},

			/**
			 * File importato correttamente
			 */
			FILE_CARICATO: {
				id: 6130,
				label: "intranet.COMMON.FILE-CARICATO"
			},

			/**
			 * Nessun risultato...
			 */
			NESSUN_RISULTATO: {
				id: 6197,
				label: "intranet.COMMON.NESSUN-RISULTATO"
			},

			/**
			 * Salva ed esci
			 */
			SALVA_ESCI: {
				id: 6228,
				label: "intranet.COMMON.SALVA-ESCI"
			},

			/**
			 * Oggi
			 */
			OGGI: {
				id: 6233,
				label: "intranet.COMMON.OGGI"
			},

			/**
			 * Ieri
			 */
			IERI: {
				id: 6234,
				label: "intranet.COMMON.IERI"
			},

			/**
			 * Domani
			 */
			DOMANI: {
				id: 6235,
				label: "intranet.COMMON.DOMANI"
			},

			/**
			 * Content of path intranet.COMMON.FORM
			 */
			FORM: {
				/**
				 * Campi obbligatori mancanti
				 */
				REQUIRED_FIELDS: {
					id: 6305,
					label: "intranet.COMMON.FORM.REQUIRED-FIELDS"
				},

				/**
				 * Form invalido
				 */
				INVALID: {
					id: 6307,
					label: "intranet.COMMON.FORM.INVALID"
				},

			},

			/**
			 * COMMON.CONFERMA-SERVIZIO
			 */
			CONFERMA_SERVIZIO: {
				id: 6367,
				label: "intranet.COMMON.CONFERMA-SERVIZIO"
			},

			/**
			 * COMMON.IMAGE-DUPLICATA
			 */
			IMAGE_DUPLICATA: {
				id: 6408,
				label: "intranet.COMMON.IMAGE-DUPLICATA"
			},

			/**
			 * COMMON.SUCCESS
			 */
			SUCCESS: {
				id: 6414,
				label: "intranet.COMMON.SUCCESS"
			},

			/**
			 * COMMON.RESTORE
			 */
			RESTORE: {
				id: 6439,
				label: "intranet.COMMON.RESTORE"
			},

			/**
			 * COMMON.PAGAMENTO-IMMEDIATO
			 */
			PAGAMENTO_IMMEDIATO: {
				id: 6633,
				label: "intranet.COMMON.PAGAMENTO-IMMEDIATO"
			},

			/**
			 * COMMON.NOME-GRUPPO-TUTTI
			 */
			NOME_GRUPPO_TUTTI: {
				id: 6634,
				label: "intranet.COMMON.NOME-GRUPPO-TUTTI"
			},

			/**
			 * COMMON.TARIFFA-TO
			 */
			TARIFFA_TO: {
				id: 6635,
				label: "intranet.COMMON.TARIFFA-TO"
			},

			/**
			 * COMMON.NON-RIMBORSABILE
			 */
			NON_RIMBORSABILE: {
				id: 6636,
				label: "intranet.COMMON.NON-RIMBORSABILE"
			},

			/**
			 * COMMON.DETTAGLIO-NOLEGGIO-FORMAT
			 */
			DETTAGLIO_NOLEGGIO_FORMAT: {
				id: 6637,
				label: "intranet.COMMON.DETTAGLIO-NOLEGGIO-FORMAT"
			},

			/**
			 * COMMON.PERSONE-GENERICO
			 */
			PERSONE_GENERICO: {
				id: 6638,
				label: "intranet.COMMON.PERSONE-GENERICO"
			},

			/**
			 * COMMON.PARTENZA-TRATTA-VOLO
			 */
			PARTENZA_TRATTA_VOLO: {
				id: 6639,
				label: "intranet.COMMON.PARTENZA-TRATTA-VOLO"
			},

			/**
			 * COMMON.TARIFFA-AEREA
			 */
			TARIFFA_AEREA: {
				id: 6640,
				label: "intranet.COMMON.TARIFFA-AEREA"
			},

			/**
			 * COMMON.PENALE-SE-ANNULLATA-ENTRO
			 */
			PENALE_SE_ANNULLATA_ENTRO: {
				id: 6641,
				label: "intranet.COMMON.PENALE-SE-ANNULLATA-ENTRO"
			},

			/**
			 * COMMON.EMISSIONE-ENTRO-IL
			 */
			EMISSIONE_ENTRO_IL: {
				id: 6642,
				label: "intranet.COMMON.EMISSIONE-ENTRO-IL"
			},

			/**
			 * COMMON.VOLI-EMISSIONE
			 */
			VOLI_EMISSIONE: {
				id: 6643,
				label: "intranet.COMMON.VOLI-EMISSIONE"
			},

			/**
			 * COMMON.VOLI-OPZIONE-AUTOCANCELLAZIONE
			 */
			VOLI_OPZIONE_AUTOCANCELLAZIONE: {
				id: 6644,
				label: "intranet.COMMON.VOLI-OPZIONE-AUTOCANCELLAZIONE"
			},

			/**
			 * COMMON.TASSE-TARIFFA-AEREA
			 */
			TASSE_TARIFFA_AEREA: {
				id: 6645,
				label: "intranet.COMMON.TASSE-TARIFFA-AEREA"
			},

			/**
			 * COMMON.VOLI-BAGAGLIO-NON-INCLUSO
			 */
			VOLI_BAGAGLIO_NON_INCLUSO: {
				id: 6646,
				label: "intranet.COMMON.VOLI-BAGAGLIO-NON-INCLUSO"
			},

			/**
			 * COMMON.VOLI-TARIFFA-NDC
			 */
			VOLI_TARIFFA_NDC: {
				id: 6647,
				label: "intranet.COMMON.VOLI-TARIFFA-NDC"
			},

			/**
			 * COMMON.VOLI-TARIFFA-IP
			 */
			VOLI_TARIFFA_IP: {
				id: 6648,
				label: "intranet.COMMON.VOLI-TARIFFA-IP"
			},

			/**
			 * COMMON.VOLI-TASSE-TO
			 */
			VOLI_TASSE_TO: {
				id: 6649,
				label: "intranet.COMMON.VOLI-TASSE-TO"
			},

			/**
			 * COMMON.VOLI-TARIFFA-TO
			 */
			VOLI_TARIFFA_TO: {
				id: 6650,
				label: "intranet.COMMON.VOLI-TARIFFA-TO"
			},

			/**
			 * COMMON.VOLI-TASSE-IP
			 */
			VOLI_TASSE_IP: {
				id: 6651,
				label: "intranet.COMMON.VOLI-TASSE-IP"
			},

			/**
			 * COMMON.VOLI-TASSE-NDC
			 */
			VOLI_TASSE_NDC: {
				id: 6652,
				label: "intranet.COMMON.VOLI-TASSE-NDC"
			},

			/**
			 * COMMON.VOLI-PAGAMENTO-IMMEDIATO
			 */
			VOLI_PAGAMENTO_IMMEDIATO: {
				id: 6653,
				label: "intranet.COMMON.VOLI-PAGAMENTO-IMMEDIATO"
			},

			/**
			 * COMMON.VOLI-SEGMENTO-PARTENZA
			 */
			VOLI_SEGMENTO_PARTENZA: {
				id: 6654,
				label: "intranet.COMMON.VOLI-SEGMENTO-PARTENZA"
			},

			/**
			 * COMMON.VOLI-NOT-REF
			 */
			VOLI_NOT_REF: {
				id: 6655,
				label: "intranet.COMMON.VOLI-NOT-REF"
			},

			/**
			 * COMMON.VOLI-ECONOMY
			 */
			VOLI_ECONOMY: {
				id: 6656,
				label: "intranet.COMMON.VOLI-ECONOMY"
			},

			/**
			 * COMMON.VOLI-ITINERARIO
			 */
			VOLI_ITINERARIO: {
				id: 6657,
				label: "intranet.COMMON.VOLI-ITINERARIO"
			},

			/**
			 * COMMON.VOLI-FIRST
			 */
			VOLI_FIRST: {
				id: 6658,
				label: "intranet.COMMON.VOLI-FIRST"
			},

			/**
			 * COMMON.VOLI-BUSINESS
			 */
			VOLI_BUSINESS: {
				id: 6659,
				label: "intranet.COMMON.VOLI-BUSINESS"
			},

			/**
			 * COMMON.VOLI-TASSE-MODIFICA
			 */
			VOLI_TASSE_MODIFICA: {
				id: 6660,
				label: "intranet.COMMON.VOLI-TASSE-MODIFICA"
			},

			/**
			 * COMMON.VOLI-TARIFFA-MODIFICA
			 */
			VOLI_TARIFFA_MODIFICA: {
				id: 6661,
				label: "intranet.COMMON.VOLI-TARIFFA-MODIFICA"
			},

			/**
			 * COMMON.VOLI-NOPNR-TO-TARIFFA-MODIFICA
			 */
			VOLI_NOPNR_TO_TARIFFA_MODIFICA: {
				id: 6662,
				label: "intranet.COMMON.VOLI-NOPNR-TO-TARIFFA-MODIFICA"
			},

			/**
			 * COMMON.VOLI-SOLUZIONE
			 */
			VOLI_SOLUZIONE: {
				id: 6663,
				label: "intranet.COMMON.VOLI-SOLUZIONE"
			},

			/**
			 * COMMON.VOLI-NOPNR-IP-TARIFFA-MODIFICA
			 */
			VOLI_NOPNR_IP_TARIFFA_MODIFICA: {
				id: 6664,
				label: "intranet.COMMON.VOLI-NOPNR-IP-TARIFFA-MODIFICA"
			},

		},

		/**
		 * Content of path intranet.CONTABILITA
		 */
		CONTABILITA: {
			/**
			 * Content of path intranet.CONTABILITA.CICLO-PASSIVO
			 */
			CICLO_PASSIVO: {
				/**
				 * Content of path intranet.CONTABILITA.CICLO-PASSIVO.EDITOR
				 */
				EDITOR: {
					/**
					 * Annullata ADV
					 */
					ANNULLATA_ADV: {
						id: 5359,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.ANNULLATA-ADV"
					},

					/**
					 * Annullata TO
					 */
					ANNULLATA_TO: {
						id: 5360,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.ANNULLATA-TO"
					},

					/**
					 * Banca
					 */
					BANCA: {
						id: 5361,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.BANCA"
					},

					/**
					 * Banca forn.
					 */
					BANCA_FORNITORE: {
						id: 5362,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.BANCA-FORNITORE"
					},

					/**
					 * Cambio
					 */
					CAMBIO: {
						id: 5363,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.CAMBIO"
					},

					/**
					 * Clienti
					 */
					CLIENTI: {
						id: 5364,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.CLIENTI"
					},

					/**
					 * Commissioni
					 */
					COMMISSIONI_BANCARIE: {
						id: 5365,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.COMMISSIONI-BANCARIE"
					},

					/**
					 * Conferma
					 */
					CONFERMA: {
						id: 5366,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.CONFERMA"
					},

					/**
					 * Confermi di voler eliminare il pagamento ID={id}?
					 */
					CONFERMA_ELIMINA: {
						id: 5367,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.CONFERMA-ELIMINA"
					},

					/**
					 * Data
					 */
					DATA: {
						id: 5368,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.DATA"
					},

					/**
					 * Email utente conferma
					 */
					EMAIL_UTENTE_CONFEMRA: {
						id: 5369,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.EMAIL-UTENTE-CONFEMRA"
					},

					/**
					 * Fornitore
					 */
					FORNITORE: {
						id: 5370,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.FORNITORE"
					},

					/**
					 * Fornitore richiesto
					 */
					FORNITORE_RICHIESTO: {
						id: 5371,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.FORNITORE-RICHIESTO"
					},

					/**
					 * Importo
					 */
					IMPORTO: {
						id: 5372,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.IMPORTO"
					},

					/**
					 * Note
					 */
					NOTE: {
						id: 5373,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.NOTE"
					},

					/**
					 * Nuovo pagamento
					 */
					NUOVO_PAGAMENTO: {
						id: 5374,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.NUOVO-PAGAMENTO"
					},

					/**
					 * Offerta
					 */
					OFFERTA: {
						id: 5375,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.OFFERTA"
					},

					/**
					 * Offerte
					 */
					OFFERTE: {
						id: 5376,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.OFFERTE"
					},

					/**
					 * Operatore
					 */
					OPERATORE: {
						id: 5377,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.OPERATORE"
					},

					/**
					 * Pagare
					 */
					PAGARE: {
						id: 5378,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.PAGARE"
					},

					/**
					 * Partenza
					 */
					PARTENZA: {
						id: 5379,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.PARTENZA"
					},

					/**
					 * Utente conferma
					 */
					UTENTE_CONFEMRA: {
						id: 5380,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.UTENTE-CONFEMRA"
					},

					/**
					 * Valuta
					 */
					VALUTA: {
						id: 5381,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.VALUTA"
					},

					/**
					 * Valuta obbligatoria
					 */
					VALUTA_OBBLIGATORIA: {
						id: 5382,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.EDITOR.VALUTA-OBBLIGATORIA"
					},

				},

				/**
				 * Content of path intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI
				 */
				LISTA_PAGAMENTI: {
					/**
					 * Banca
					 */
					BANCA: {
						id: 5383,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.BANCA"
					},

					/**
					 * Cambio
					 */
					CAMBIO: {
						id: 5384,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.CAMBIO"
					},

					/**
					 * Costo
					 */
					COSTO: {
						id: 5385,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.COSTO"
					},

					/**
					 * Data al
					 */
					DATA_AL: {
						id: 5386,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.DATA-AL"
					},

					/**
					 * Data dal
					 */
					DATA_DAL: {
						id: 5387,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.DATA-DAL"
					},

					/**
					 * Data eff.
					 */
					DATA_EFFETTUAZIONE: {
						id: 5388,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.DATA-EFFETTUAZIONE"
					},

					/**
					 * Euro
					 */
					EURO: {
						id: 5389,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.EURO"
					},

					/**
					 * Fornitore
					 */
					FORNITORE: {
						id: 5390,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.FORNITORE"
					},

					/**
					 * ID Fornitore
					 */
					FORNITORE_ID: {
						id: 5391,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.FORNITORE-ID"
					},

					/**
					 * ID
					 */
					ID: {
						id: 5392,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.ID"
					},

					/**
					 * Importo
					 */
					IMPORTO: {
						id: 5393,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.IMPORTO"
					},

					/**
					 * Note
					 */
					NOTE: {
						id: 5394,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.NOTE"
					},

					/**
					 * N.ro prenotazione
					 */
					NUMERO_PRENOTAZIONE: {
						id: 5395,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.NUMERO-PRENOTAZIONE"
					},

					/**
					 * Nuovo pagamento {nr}
					 */
					NUOVO_PAGAMENTO_NR: {
						id: 5396,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.NUOVO-PAGAMENTO-NR"
					},

					/**
					 * Offerta ID
					 */
					OFFERTA_ID: {
						id: 5397,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.OFFERTA-ID"
					},

					/**
					 * Operatore
					 */
					OPERATORE: {
						id: 5398,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.OPERATORE"
					},

					/**
					 * Pagamento ID
					 */
					PAGAMENTO_ID: {
						id: 5399,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.PAGAMENTO-ID"
					},

					/**
					 * Totali
					 */
					TOTALI: {
						id: 5400,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.TOTALI"
					},

					/**
					 * Valuta
					 */
					VALUTA: {
						id: 5401,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-PAGAMENTI.VALUTA"
					},

				},

				/**
				 * Content of path intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI
				 */
				LISTA_SERVIZI: {
					/**
					 * Accordo
					 */
					ACCORDO: {
						id: 5402,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.ACCORDO"
					},

					/**
					 * Accordo o fornitore mancanti
					 */
					ACCORDO_O_FORNITORE_MANCANTI: {
						id: 5403,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.ACCORDO-O-FORNITORE-MANCANTI"
					},

					/**
					 * Addeb
					 */
					ADDEBITATO: {
						id: 5404,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.ADDEBITATO"
					},

					/**
					 * Annullata ADV
					 */
					ANNULLATA_ADV: {
						id: 5405,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.ANNULLATA-ADV"
					},

					/**
					 * Annullata TO
					 */
					ANNULLATA_TO: {
						id: 5406,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.ANNULLATA-TO"
					},

					/**
					 * Attenzione: il fornitore del pagamento è diverso dal fornitore dell'accordo del servizio
					 */
					ATTENZIONE_FORNITORE_PAGAMENTO_DIVERSO_DA_SELEZIONATO: {
						id: 5407,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.ATTENZIONE-FORNITORE-PAGAMENTO-DIVERSO-DA-SELEZIONATO"
					},

					/**
					 * Attenzione: i servizi selezionati hanno differenti fornitori
					 */
					ATTENZIONE_PAGAMENTI_CON_FORNITORI_DIVERSI: {
						id: 5408,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.ATTENZIONE-PAGAMENTI-CON-FORNITORI-DIVERSI"
					},

					/**
					 * CEE
					 */
					CEE: {
						id: 5409,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.CEE"
					},

					/**
					 * Clienti
					 */
					CLIENTI: {
						id: 5410,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.CLIENTI"
					},

					/**
					 * Confermi di voler impostare i pagamenti su {n} righe?
					 */
					CONFERMA_IMPOSTAZIONE_PAGAMENTI: {
						id: 5411,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.CONFERMA-IMPOSTAZIONE-PAGAMENTI"
					},

					/**
					 * Il pagamento impostato è in {v}. Sono presenti servizi da pagare in {o}.Confermi di voler impostare i pagamenti su {n} righe?
					 */
					CONFERMA_IMPOSTAZIONE_PAGAMENTI_MULTI_VALUTA: {
						id: 5412,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.CONFERMA-IMPOSTAZIONE-PAGAMENTI-MULTI-VALUTA"
					},

					/**
					 * Fornitore
					 */
					FORNITORE: {
						id: 5413,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.FORNITORE"
					},

					/**
					 * Fornitore pagamento diverso dal fornitore dell'accordo
					 */
					FORNTIORE_PAGAMENTO_DIFFERENTE: {
						id: 5414,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.FORNTIORE-PAGAMENTO-DIFFERENTE"
					},

					/**
					 * I servizi selezionati non diverse valute: {v}
					 */
					I_SERVIZI_SELEZIONATI_HANNO_MOLTEPLICI_VALUTE: {
						id: 5415,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.I-SERVIZI-SELEZIONATI-HANNO-MOLTEPLICI-VALUTE"
					},

					/**
					 * I servizi selezionati non hanno la valuta
					 */
					I_SERVIZI_SELEZIONATI_NON_HANNO_VALUTA: {
						id: 5416,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.I-SERVIZI-SELEZIONATI-NON-HANNO-VALUTA"
					},

					/**
					 * ID Pag
					 */
					ID_PAGAMENTO: {
						id: 5417,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.ID-PAGAMENTO"
					},

					/**
					 * ID pagamento errato
					 */
					ID_PAGAMENTO_ERRATO: {
						id: 5418,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.ID-PAGAMENTO-ERRATO"
					},

					/**
					 * Il pagamento esiste
					 */
					IL_PAGAMENTO_ESISTE: {
						id: 5419,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.IL-PAGAMENTO-ESISTE"
					},

					/**
					 * Imposta
					 */
					IMPOSTA: {
						id: 5420,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.IMPOSTA"
					},

					/**
					 * Luogo
					 */
					LUOGO: {
						id: 5421,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.LUOGO"
					},

					/**
					 * Nome / cognome pax
					 */
					NOME_PAX: {
						id: 5422,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.NOME-PAX"
					},

					/**
					 * N.ro prenotazione
					 */
					NUMERO_PRENOTAZIONE: {
						id: 5423,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.NUMERO-PRENOTAZIONE"
					},

					/**
					 * Offerta ID
					 */
					OFFERTA_ID: {
						id: 5424,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.OFFERTA-ID"
					},

					/**
					 * Operatore
					 */
					OPERATORE: {
						id: 5425,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.OPERATORE"
					},

					/**
					 * Paese
					 */
					PAESE: {
						id: 5426,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.PAESE"
					},

					/**
					 * ID pagamento
					 */
					PAGAMENTO_ID: {
						id: 5427,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.PAGAMENTO-ID"
					},

					/**
					 * Pagamento mancante
					 */
					PAGAMENTO_MANCANTE: {
						id: 5428,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.PAGAMENTO-MANCANTE"
					},

					/**
					 * Pagamento ID={id} non trovato
					 */
					PAGAMENTO_NON_TROVATO: {
						id: 5429,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.PAGAMENTO-NON-TROVATO"
					},

					/**
					 * Pagare
					 */
					PAGARE: {
						id: 5430,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.PAGARE"
					},

					/**
					 * Pagato
					 */
					PAGATO: {
						id: 5431,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.PAGATO"
					},

					/**
					 * Partenza
					 */
					PARTENZA: {
						id: 5432,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.PARTENZA"
					},

					/**
					 * Partenza al
					 */
					PARTENZA_AL: {
						id: 5433,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.PARTENZA-AL"
					},

					/**
					 * Inizio servizio dal
					 */
					INIZIO_SERVIZIO_DAL: {
						id: 5434,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.INIZIO-SERVIZIO-DAL"
					},

					/**
					 * Prezzo
					 */
					PREZZO: {
						id: 5435,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.PREZZO"
					},

					/**
					 * Selezionare un fornitore, un accordo oppure un'offerta
					 */
					SELEZIONARE_FORNITORE_O_ACCORDO: {
						id: 5436,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.SELEZIONARE-FORNITORE-O-ACCORDO"
					},

					/**
					 * Servizi e pagamento hanno valute diverse: Servizi {s} / Pagamento {p}
					 */
					SERVIZI_CON_VALUTA_DIVERSA: {
						id: 5437,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.SERVIZI-CON-VALUTA-DIVERSA"
					},

					/**
					 * Servizio
					 */
					SERVIZIO: {
						id: 5438,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.SERVIZIO"
					},

					/**
					 * Valuta
					 */
					VALUTA: {
						id: 5439,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.VALUTA"
					},

					/**
					 * Valuta pagamento diversa: {v}
					 */
					VALUTA_PAGAMENTO_DIVERSA: {
						id: 5440,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.VALUTA-PAGAMENTO-DIVERSA"
					},

					/**
					 * Verif
					 */
					VERIFICATO: {
						id: 5441,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.VERIFICATO"
					},

					/**
					 * Inizio servizio al
					 */
					INIZIO_SERVIZIO_AL: {
						id: 6231,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.INIZIO-SERVIZIO-AL"
					},

					/**
					 * Partenza dal
					 */
					PARTENZA_DAL: {
						id: 6232,
						label: "intranet.CONTABILITA.CICLO-PASSIVO.LISTA-SERVIZI.PARTENZA-DAL"
					},

				},

				/**
				 * Pagamenti
				 */
				PAGAMENTI: {
					id: 5442,
					label: "intranet.CONTABILITA.CICLO-PASSIVO.PAGAMENTI"
				},

				/**
				 * Ricerca pagamenti
				 */
				RICERCA_PAGAMENTI: {
					id: 5443,
					label: "intranet.CONTABILITA.CICLO-PASSIVO.RICERCA-PAGAMENTI"
				},

				/**
				 * Servizi da pagare
				 */
				SERVIZI_DA_PAGARE: {
					id: 5444,
					label: "intranet.CONTABILITA.CICLO-PASSIVO.SERVIZI-DA-PAGARE"
				},

				/**
				 * Ciclo passivo
				 */
				TITOLO: {
					id: 5445,
					label: "intranet.CONTABILITA.CICLO-PASSIVO.TITOLO"
				},

			},

			/**
			 * Content of path intranet.CONTABILITA.ESTRATTI-CONTO
			 */
			ESTRATTI_CONTO: {
				/**
				 * Acconto
				 */
				ACCONTO: {
					id: 5446,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.ACCONTO"
				},

				/**
				 * Agenzia
				 */
				AGENZIA: {
					id: 5447,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.AGENZIA"
				},

				/**
				 * Agenzia obbligatoria
				 */
				AGENZIA_OBBLIGATORIA: {
					id: 5448,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.AGENZIA-OBBLIGATORIA"
				},

				/**
				 * Al
				 */
				AL: {
					id: 5449,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.AL"
				},

				/**
				 * Banca
				 */
				BANCA: {
					id: 5450,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.BANCA"
				},

				/**
				 * La banca è obbligatoria
				 */
				BANCA_OBBLIGATORIA: {
					id: 5451,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.BANCA-OBBLIGATORIA"
				},

				/**
				 * Cessione
				 */
				CESSIONE: {
					id: 5452,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.CESSIONE"
				},

				/**
				 * Commissione
				 */
				COMMISSIONE: {
					id: 5453,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.COMMISSIONE"
				},

				/**
				 * Compensazione
				 */
				COMPENSAZIONE: {
					id: 5454,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.COMPENSAZIONE"
				},

				/**
				 * Crea Invio
				 */
				CREA_INVIO: {
					id: 5455,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.CREA-INVIO"
				},

				/**
				 * Da pagare
				 */
				DA_PAGARE: {
					id: 5456,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.DA-PAGARE"
				},

				/**
				 * Dal
				 */
				DAL: {
					id: 5457,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.DAL"
				},

				/**
				 * Data invio
				 */
				DATA_INVIO: {
					id: 5458,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.DATA-INVIO"
				},

				/**
				 * Data rif.
				 */
				DATA_RIFERIMENTO: {
					id: 5459,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.DATA-RIFERIMENTO"
				},

				/**
				 * La data di riferimento è obbligatoria
				 */
				DATA_RIFERIMENTO_OBBLIGATORIA: {
					id: 5460,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.DATA-RIFERIMENTO-OBBLIGATORIA"
				},

				/**
				 * Dati
				 */
				DATI: {
					id: 5461,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.DATI"
				},

				/**
				 * Descrizione
				 */
				DESCRIZIONE: {
					id: 5462,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.DESCRIZIONE"
				},

				/**
				 * Destinatario
				 */
				DESTINATARIO: {
					id: 5463,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.DESTINATARIO"
				},

				/**
				 * Il destinatario è obbligatorio
				 */
				DESTINATARIO_OBBLIGATORIO: {
					id: 5464,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.DESTINATARIO-OBBLIGATORIO"
				},

				/**
				 * Eliminare l'estratto conto {name} ID={id}?
				 */
				ELIMINA_ESTRATTO_CONTO: {
					id: 5465,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.ELIMINA-ESTRATTO-CONTO"
				},

				/**
				 * Errore nel leggere l'estratto conto
				 */
				ERRORE_LETTURA: {
					id: 5466,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.ERRORE-LETTURA"
				},

				/**
				 * Estratto conto creato
				 */
				ESTRATTO_CONTO_CREATO: {
					id: 5467,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.ESTRATTO-CONTO-CREATO"
				},

				/**
				 * Estratto conto eliminato
				 */
				ESTRATTO_CONTO_ELIMINATO: {
					id: 5468,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.ESTRATTO-CONTO-ELIMINATO"
				},

				/**
				 * Estratto conto rapido
				 */
				ESTRATTO_RAPIDO: {
					id: 5469,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.ESTRATTO-RAPIDO"
				},

				/**
				 * Gruppo
				 */
				GRUPPO: {
					id: 5470,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.GRUPPO"
				},

				/**
				 * ID
				 */
				ID: {
					id: 5471,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.ID"
				},

				/**
				 * ID Agenzia
				 */
				ID_AGENZIA: {
					id: 5472,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.ID-AGENZIA"
				},

				/**
				 * Importo
				 */
				IMPORTO: {
					id: 5473,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.IMPORTO"
				},

				/**
				 * Incassi
				 */
				INCASSI: {
					id: 5474,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.INCASSI"
				},

				/**
				 * {count} incass{count, plural, =0{i} one{o} other{i}}: sommati per offerta
				 */
				INCASSI_SELEZIONATI_ACCORPATI: {
					id: 5475,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.INCASSI-SELEZIONATI-ACCORPATI"
				},

				/**
				 * {count} incass{count, plural, =0{i} one{o} other{i}}: tutti i dettagli
				 */
				INCASSI_SELEZIONATI_SINGOLI: {
					id: 5476,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.INCASSI-SELEZIONATI-SINGOLI"
				},

				/**
				 * Indirizzo email
				 */
				INDIRIZZO_EMAIL: {
					id: 5477,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.INDIRIZZO-EMAIL"
				},

				/**
				 * Invia
				 */
				INVIA: {
					id: 5478,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.INVIA"
				},

				/**
				 * Invio
				 */
				INVIO: {
					id: 5479,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.INVIO"
				},

				/**
				 * Mittente
				 */
				MITTENTE: {
					id: 5480,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.MITTENTE"
				},

				/**
				 * Il mittente è obbligatorio
				 */
				MITTENTE_OBBLIGATORIO: {
					id: 5481,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.MITTENTE-OBBLIGATORIO"
				},

				/**
				 * Modalità pagamento corrente
				 */
				MODALITA_PAGAMENTO_CORRENTE: {
					id: 5482,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.MODALITA-PAGAMENTO-CORRENTE"
				},

				/**
				 * Netto
				 */
				NETTO: {
					id: 5483,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.NETTO"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 5484,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.NOME"
				},

				/**
				 * Nome visualizzato
				 */
				NOME_VISUALIZZATO: {
					id: 5485,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.NOME-VISUALIZZATO"
				},

				/**
				 * Note
				 */
				NOTE: {
					id: 5486,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.NOTE"
				},

				/**
				 * Nuovo estratto conto
				 */
				NUOVO_ESTRATTO_CONTO: {
					id: 5487,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.NUOVO-ESTRATTO-CONTO"
				},

				/**
				 * Offerta
				 */
				OFFERTA: {
					id: 5488,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.OFFERTA"
				},

				/**
				 * Oggetto
				 */
				OGGETTO: {
					id: 5489,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.OGGETTO"
				},

				/**
				 * L'oggetto della mail è obbligatorio
				 */
				OGGETTO_OBBLIGATORIO: {
					id: 5490,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.OGGETTO-OBBLIGATORIO"
				},

				/**
				 * Pagamento
				 */
				PAGAMENTO: {
					id: 5491,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.PAGAMENTO"
				},

				/**
				 * Partenza
				 */
				PARTENZA: {
					id: 5492,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.PARTENZA"
				},

				/**
				 * Partenza al
				 */
				PARTENZA_AL: {
					id: 5493,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.PARTENZA-AL"
				},

				/**
				 * Partenza al obbligatoria
				 */
				PARTENZA_AL_OBBLIGATORIA: {
					id: 5494,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.PARTENZA-AL-OBBLIGATORIA"
				},

				/**
				 * Partenza dal
				 */
				PARTENZA_DAL: {
					id: 5495,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.PARTENZA-DAL"
				},

				/**
				 * Partenza dal obbligatoria
				 */
				PARTENZA_DAL_OBBLIGATORIA: {
					id: 5496,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.PARTENZA-DAL-OBBLIGATORIA"
				},

				/**
				 * Partenze al
				 */
				PARTENZE_AL: {
					id: 5497,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.PARTENZE-AL"
				},

				/**
				 * Partenze dal
				 */
				PARTENZE_DAL: {
					id: 5498,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.PARTENZE-DAL"
				},

				/**
				 * Partita IVA
				 */
				PARTITA_IVA: {
					id: 5499,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.PARTITA-IVA"
				},

				/**
				 * Preview file Excel
				 */
				PREVIEW_EXCEL: {
					id: 5500,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.PREVIEW-EXCEL"
				},

				/**
				 * Ricerca
				 */
				RICERCA: {
					id: 5501,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.RICERCA"
				},

				/**
				 * Rif
				 */
				RIF: {
					id: 5502,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.RIF"
				},

				/**
				 * Saldo
				 */
				SALDO: {
					id: 5503,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.SALDO"
				},

				/**
				 * Scadenza
				 */
				SCADENZA: {
					id: 5504,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.SCADENZA"
				},

				/**
				 * Data di scadenza calcolata
				 */
				SCADENZA_CALCOLATA: {
					id: 5505,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.SCADENZA-CALCOLATA"
				},

				/**
				 * Subtotale
				 */
				SUBTOTALE: {
					id: 5506,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.SUBTOTALE"
				},

				/**
				 * Termine
				 */
				TERMINE: {
					id: 5507,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.TERMINE"
				},

				/**
				 * Testo
				 */
				TESTO: {
					id: 5508,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.TESTO"
				},

				/**
				 * Il testo è obbligatorio
				 */
				TESTO_OBBLIGATORIO: {
					id: 5509,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.TESTO-OBBLIGATORIO"
				},

				/**
				 * Tipo biglietteria
				 */
				TIPO_BIGLIETTERIA: {
					id: 5510,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.TIPO-BIGLIETTERIA"
				},

				/**
				 * Tipo
				 */
				TIPO_ESTRATTO_CONTO: {
					id: 5511,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.TIPO-ESTRATTO-CONTO"
				},

				/**
				 * Il tipo di estratto conto è obbligatorio
				 */
				TIPO_ESTRATTO_CONTO_OBBLIGATORIO: {
					id: 5512,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.TIPO-ESTRATTO-CONTO-OBBLIGATORIO"
				},

				/**
				 * Tipo operazione
				 */
				TIPO_OPERAZIONE: {
					id: 5513,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.TIPO-OPERAZIONE"
				},

				/**
				 * Tipo prepagamento
				 */
				TIPO_PREPAGAMENTO: {
					id: 5514,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.TIPO-PREPAGAMENTO"
				},

				/**
				 * Estratti conto
				 */
				TITOLO: {
					id: 5515,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.TITOLO"
				},

				/**
				 * Totale
				 */
				TOTALE: {
					id: 5516,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.TOTALE"
				},

				/**
				 * Totale selezionati
				 */
				TOTALE_SELEZIONATI: {
					id: 5517,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.TOTALE-SELEZIONATI"
				},

				/**
				 * Tutti gli incassi: sommati per offerta
				 */
				TUTTI_GLI_INCASSI_ACCORPATI: {
					id: 5518,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.TUTTI-GLI-INCASSI-ACCORPATI"
				},

				/**
				 * Tutti gli incassi: tutti i dettagli
				 */
				TUTTI_GLI_INCASSI_SINGOLI: {
					id: 5519,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.TUTTI-GLI-INCASSI-SINGOLI"
				},

				/**
				 * Versato
				 */
				VERSATO: {
					id: 5520,
					label: "intranet.CONTABILITA.ESTRATTI-CONTO.VERSATO"
				},

			},

			/**
			 * Content of path intranet.CONTABILITA.FATTURE
			 */
			FATTURE: {
				/**
				 * Content of path intranet.CONTABILITA.FATTURE.CREAZIONE
				 */
				CREAZIONE: {
					/**
					 * Agenzia
					 */
					AGENZIA: {
						id: 5521,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.AGENZIA"
					},

					/**
					 * Cancellazione
					 */
					CANCELLAZIONE: {
						id: 5522,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.CANCELLAZIONE"
					},

					/**
					 * Crea autofatture
					 */
					CREA_AUTOFATTURE: {
						id: 5523,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.CREA-AUTOFATTURE"
					},

					/**
					 * Crea autofatture 2018
					 */
					CREA_AUTOFATTURE_18: {
						id: 5524,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.CREA-AUTOFATTURE-18"
					},

					/**
					 * Crea fatture
					 */
					CREA_FATTURE: {
						id: 5525,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.CREA-FATTURE"
					},

					/**
					 * Sei sicuro di voler generare {n} document{n, plural, =0{i} one{o} other{i}} di tipo {t}?
					 */
					CREAZIONE_DOCUMENTI: {
						id: 5526,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.CREAZIONE-DOCUMENTI"
					},

					/**
					 * Documenti creati
					 */
					DOCUMENTI_CREATI: {
						id: 5527,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.DOCUMENTI-CREATI"
					},

					/**
					 * ID offerta
					 */
					ID_OFFERTA: {
						id: 5528,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.ID-OFFERTA"
					},

					/**
					 * Inserisci la data
					 */
					INSERISCI_DATA: {
						id: 5529,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.INSERISCI-DATA"
					},

					/**
					 * Netto
					 */
					NETTO: {
						id: 5530,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.NETTO"
					},

					/**
					 * Network
					 */
					NETWORK: {
						id: 5531,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.NETWORK"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 5532,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.NOME"
					},

					/**
					 * Partenza
					 */
					PARTENZA: {
						id: 5533,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.PARTENZA"
					},

					/**
					 * al
					 */
					PARTENZA_AL: {
						id: 5534,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.PARTENZA-AL"
					},

					/**
					 * Partenza dal
					 */
					PARTENZA_DAL: {
						id: 5535,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.PARTENZA-DAL"
					},

					/**
					 * Rif
					 */
					RIF: {
						id: 5536,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.RIF"
					},

					/**
					 * Solo cancellate
					 */
					SOLO_CANCELLATE: {
						id: 5537,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.SOLO-CANCELLATE"
					},

					/**
					 * Solo netto
					 */
					SOLO_NETTO: {
						id: 5538,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.SOLO-NETTO"
					},

					/**
					 * Totale
					 */
					TOTALE: {
						id: 5539,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.TOTALE"
					},

					/**
					 * Tot.Comm.
					 */
					TOTALE_COMM: {
						id: 5540,
						label: "intranet.CONTABILITA.FATTURE.CREAZIONE.TOTALE-COMM"
					},

				},

				/**
				 * Content of path intranet.CONTABILITA.FATTURE.EDITOR
				 */
				EDITOR: {
					/**
					 * Agenzia
					 */
					AGENZIA: {
						id: 5541,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.AGENZIA"
					},

					/**
					 * Specificare l'agenzia
					 */
					AGENZIA_RICHIESTA: {
						id: 5542,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.AGENZIA-RICHIESTA"
					},

					/**
					 * Causale
					 */
					CAUSALE: {
						id: 5543,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.CAUSALE"
					},

					/**
					 * 001 - Fattura extra Cee
					 */
					CAUSALE_001: {
						id: 5544,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.CAUSALE-001"
					},

					/**
					 * 004 - Autofattura
					 */
					CAUSALE_004: {
						id: 5545,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.CAUSALE-004"
					},

					/**
					 * 005 - Fatt.Comm.Comp aeree
					 */
					CAUSALE_005: {
						id: 5546,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.CAUSALE-005"
					},

					/**
					 * Fatt.Comm.Att.Fornitori
					 */
					CAUSALE_008: {
						id: 5547,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.CAUSALE-008"
					},

					/**
					 * 036 - Fattura Clienti 74ter
					 */
					CAUSALE_036: {
						id: 5548,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.CAUSALE-036"
					},

					/**
					 * 051 - Fattura Clienti
					 */
					CAUSALE_051: {
						id: 5549,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.CAUSALE-051"
					},

					/**
					 * Causale richiesta
					 */
					CAUSALE_RICHIESTA: {
						id: 5550,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.CAUSALE-RICHIESTA"
					},

					/**
					 * Codice fiscale
					 */
					CODICE_FISCALE: {
						id: 5551,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.CODICE-FISCALE"
					},

					/**
					 * Vuoi eliminare il documento {doc} numero {nr}?
					 */
					CONFERMA_ELIMINA: {
						id: 5552,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.CONFERMA-ELIMINA"
					},

					/**
					 * Contabilizzata
					 */
					CONTABILIZZATA: {
						id: 5553,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.CONTABILIZZATA"
					},

					/**
					 * Data
					 */
					DATA: {
						id: 5554,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.DATA"
					},

					/**
					 * Data partenza
					 */
					DATA_PARTENZA: {
						id: 5555,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.DATA-PARTENZA"
					},

					/**
					 * Data partenza non valida
					 */
					DATA_PARTENZA_NON_VALIDA: {
						id: 5556,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.DATA-PARTENZA-NON-VALIDA"
					},

					/**
					 * Specificare la data del documento
					 */
					DATA_RICHIESTA: {
						id: 5557,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.DATA-RICHIESTA"
					},

					/**
					 * Descrizione
					 */
					DESCRIZIONE: {
						id: 5558,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.DESCRIZIONE"
					},

					/**
					 * Indirizzo
					 */
					INDIRIZZO: {
						id: 5559,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.INDIRIZZO"
					},

					/**
					 * Intestata a
					 */
					INTESTAZIONE: {
						id: 5560,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.INTESTAZIONE"
					},

					/**
					 * N1 - Escluse ex art. 15
					 */
					NATURA_N1: {
						id: 5561,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.NATURA-N1"
					},

					/**
					 * N2 - Non soggette
					 */
					NATURA_N2: {
						id: 5562,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.NATURA-N2"
					},

					/**
					 * N3 - Non imponibili
					 */
					NATURA_N3: {
						id: 5563,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.NATURA-N3"
					},

					/**
					 * N4 - Esenti
					 */
					NATURA_N4: {
						id: 5564,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.NATURA-N4"
					},

					/**
					 * N5 - Regime del margine / IVA non esposta in fattura
					 */
					NATURA_N5: {
						id: 5565,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.NATURA-N5"
					},

					/**
					 * N6 - Inversione contabile
					 */
					NATURA_N6: {
						id: 5566,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.NATURA-N6"
					},

					/**
					 * N7 - IVA assolta in altro stato UE
					 */
					NATURA_N7: {
						id: 5567,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.NATURA-N7"
					},

					/**
					 * Note
					 */
					NOTE: {
						id: 5568,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.NOTE"
					},

					/**
					 * Numero
					 */
					NUMERO: {
						id: 5569,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.NUMERO"
					},

					/**
					 * Specificare il numero di documento
					 */
					NUMERO_RICHIESTO: {
						id: 5570,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.NUMERO-RICHIESTO"
					},

					/**
					 * Operatore
					 */
					OPERATORE: {
						id: 5571,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.OPERATORE"
					},

					/**
					 * Specificare il tour operator
					 */
					OPERATORE_RICHIESTO: {
						id: 5572,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.OPERATORE-RICHIESTO"
					},

					/**
					 * Pagamento
					 */
					PAGAMENTO: {
						id: 5573,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.PAGAMENTO"
					},

					/**
					 * Pagamento richiesto
					 */
					PAGAMENTO_RICHIESTO: {
						id: 5574,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.PAGAMENTO-RICHIESTO"
					},

					/**
					 * Partita IVA
					 */
					PARTITA_IVA: {
						id: 5575,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.PARTITA-IVA"
					},

					/**
					 * Content of path intranet.CONTABILITA.FATTURE.EDITOR.TABLE
					 */
					TABLE: {
						/**
						 * Cod. IVA
						 */
						COD_IVA: {
							id: 5576,
							label: "intranet.CONTABILITA.FATTURE.EDITOR.TABLE.COD-IVA"
						},

						/**
						 * Descrizione
						 */
						DESCRIZIONE: {
							id: 5577,
							label: "intranet.CONTABILITA.FATTURE.EDITOR.TABLE.DESCRIZIONE"
						},

						/**
						 * ID
						 */
						ID: {
							id: 5578,
							label: "intranet.CONTABILITA.FATTURE.EDITOR.TABLE.ID"
						},

						/**
						 * ID Off ag.
						 */
						ID_OFF: {
							id: 5579,
							label: "intranet.CONTABILITA.FATTURE.EDITOR.TABLE.ID-OFF"
						},

						/**
						 * Importo
						 */
						IMPORTO: {
							id: 5580,
							label: "intranet.CONTABILITA.FATTURE.EDITOR.TABLE.IMPORTO"
						},

						/**
						 * IVA
						 */
						IVA: {
							id: 5581,
							label: "intranet.CONTABILITA.FATTURE.EDITOR.TABLE.IVA"
						},

						/**
						 * Natura
						 */
						NATURA: {
							id: 5582,
							label: "intranet.CONTABILITA.FATTURE.EDITOR.TABLE.NATURA"
						},

					},

					/**
					 * Tipo documento
					 */
					TIPO_DOCUMENTO: {
						id: 5583,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.TIPO-DOCUMENTO"
					},

					/**
					 * Specificare il tipo di documento
					 */
					TIPO_DOCUMENTO_RICHIESTO: {
						id: 5584,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.TIPO-DOCUMENTO-RICHIESTO"
					},

					/**
					 * Crea correzione
					 */
					CREACORREZIONE: {
						id: 6218,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.CREACORREZIONE"
					},

					/**
					 * Calcola numero documento
					 */
					CALCOLANUMERODOC: {
						id: 6219,
						label: "intranet.CONTABILITA.FATTURE.EDITOR.CALCOLANUMERODOC"
					},

				},

				/**
				 * Fatture
				 */
				FATTURE: {
					id: 5585,
					label: "intranet.CONTABILITA.FATTURE.FATTURE"
				},

				/**
				 * Content of path intranet.CONTABILITA.FATTURE.LISTA
				 */
				LISTA: {
					/**
					 * Agenzia
					 */
					AGENZIA: {
						id: 5586,
						label: "intranet.CONTABILITA.FATTURE.LISTA.AGENZIA"
					},

					/**
					 * Anno documento
					 */
					ANNO_DOCUMENTO: {
						id: 5587,
						label: "intranet.CONTABILITA.FATTURE.LISTA.ANNO-DOCUMENTO"
					},

					/**
					 * Cliente
					 */
					CLIENTE: {
						id: 5588,
						label: "intranet.CONTABILITA.FATTURE.LISTA.CLIENTE"
					},

					/**
					 * Contatto
					 */
					CONTATTO: {
						id: 5589,
						label: "intranet.CONTABILITA.FATTURE.LISTA.CONTATTO"
					},

					/**
					 * Data
					 */
					DATA: {
						id: 5590,
						label: "intranet.CONTABILITA.FATTURE.LISTA.DATA"
					},

					/**
					 * Data emissione
					 */
					DATA_EMISSIONE: {
						id: 5591,
						label: "intranet.CONTABILITA.FATTURE.LISTA.DATA-EMISSIONE"
					},

					/**
					 * al
					 */
					DATA_EMISSIONE_AL: {
						id: 5592,
						label: "intranet.CONTABILITA.FATTURE.LISTA.DATA-EMISSIONE-AL"
					},

					/**
					 * Data emissione dal
					 */
					DATA_EMISSIONE_DAL: {
						id: 5593,
						label: "intranet.CONTABILITA.FATTURE.LISTA.DATA-EMISSIONE-DAL"
					},

					/**
					 * Data non valida
					 */
					DATA_NON_VALIDA: {
						id: 5594,
						label: "intranet.CONTABILITA.FATTURE.LISTA.DATA-NON-VALIDA"
					},

					/**
					 * Importo
					 */
					IMPORTO: {
						id: 5595,
						label: "intranet.CONTABILITA.FATTURE.LISTA.IMPORTO"
					},

					/**
					 * Intestazione
					 */
					INTESTAZIONE: {
						id: 5596,
						label: "intranet.CONTABILITA.FATTURE.LISTA.INTESTAZIONE"
					},

					/**
					 * Numero
					 */
					NUMERO: {
						id: 5597,
						label: "intranet.CONTABILITA.FATTURE.LISTA.NUMERO"
					},

					/**
					 * Numero documento
					 */
					NUMERO_DOCUMENTO: {
						id: 5598,
						label: "intranet.CONTABILITA.FATTURE.LISTA.NUMERO-DOCUMENTO"
					},

					/**
					 * Operatore
					 */
					OPERATORE: {
						id: 5599,
						label: "intranet.CONTABILITA.FATTURE.LISTA.OPERATORE"
					},

					/**
					 * Pagamento
					 */
					PAGAMENTO: {
						id: 5600,
						label: "intranet.CONTABILITA.FATTURE.LISTA.PAGAMENTO"
					},

					/**
					 * Partita IVA
					 */
					PARTITA_IVA: {
						id: 5601,
						label: "intranet.CONTABILITA.FATTURE.LISTA.PARTITA-IVA"
					},

					/**
					 * Tipo
					 */
					TIPO: {
						id: 5602,
						label: "intranet.CONTABILITA.FATTURE.LISTA.TIPO"
					},

					/**
					 * Tipo documento
					 */
					TIPO_DOCUMENTO: {
						id: 5603,
						label: "intranet.CONTABILITA.FATTURE.LISTA.TIPO-DOCUMENTO"
					},

				},

				/**
				 * Nuova fattura
				 */
				NUOVA_FATTURA: {
					id: 5604,
					label: "intranet.CONTABILITA.FATTURE.NUOVA-FATTURA"
				},

				/**
				 * Offerta
				 */
				OFFERTA_ID: {
					id: 5988,
					label: "intranet.CONTABILITA.FATTURE.OFFERTA-ID"
				},

				/**
				 * Crea fatture
				 */
				CREA_FATTURE: {
					id: 5996,
					label: "intranet.CONTABILITA.FATTURE.CREA-FATTURE"
				},

			},

			/**
			 * Content of path intranet.CONTABILITA.IMPORT-MERIDIANO
			 */
			IMPORT_MERIDIANO: {
				/**
				 * Alias
				 */
				ALIAS: {
					id: 5605,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.ALIAS"
				},

				/**
				 * C. Costo
				 */
				C_COSTO: {
					id: 5606,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.C_COSTO"
				},

				/**
				 * Carica
				 */
				CARICA: {
					id: 5607,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.CARICA"
				},

				/**
				 * CEE/EXTRA CEE
				 */
				CEE_EXTRA_CEE: {
					id: 5608,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.CEE_EXTRA_CEE"
				},

				/**
				 * Classe
				 */
				CLASSE: {
					id: 5609,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.CLASSE"
				},

				/**
				 * Codice V.
				 */
				CODICE_V: {
					id: 5610,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.CODICE_V"
				},

				/**
				 * Codice_V
				 */
				CODICEV: {
					id: 5611,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.CODICEV"
				},

				/**
				 * Commessa
				 */
				COMMESSA: {
					id: 5612,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.COMMESSA"
				},

				/**
				 * Copia record {n}
				 */
				COPIA_IM_N: {
					id: 5613,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.COPIA-IM-N"
				},

				/**
				 * 
				 */
				DATA_BOLLA: {
					id: 5614,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.DATA_BOLLA"
				},

				/**
				 * Data Emissione
				 */
				DATA_EMISSIONE: {
					id: 5615,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.DATA_EMISSIONE"
				},

				/**
				 * Vuoi eliminare il record?
				 */
				ELIMINAZIONE_IM: {
					id: 5616,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.ELIMINAZIONE-IM"
				},

				/**
				 * Emissione Al
				 */
				EMISSIONE_AL: {
					id: 5617,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.EMISSIONE_AL"
				},

				/**
				 * Emissione Dal
				 */
				EMISSIONE_DAL: {
					id: 5618,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.EMISSIONE_DAL"
				},

				/**
				 * Errore nel leggere i dati
				 */
				ERRORE_LETTURA: {
					id: 5619,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.ERRORE-LETTURA"
				},

				/**
				 * F. Pag
				 */
				F_PAG: {
					id: 5620,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.F_PAG"
				},

				/**
				 * File da importare:
				 */
				FILE_TO_IMPORT: {
					id: 5621,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.FILE_TO_IMPORT"
				},

				/**
				 * File di tipo excel richiesto
				 */
				FILE_TYPE_INFO: {
					id: 5622,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.FILE_TYPE_INFO"
				},

				/**
				 * ID
				 */
				ID: {
					id: 5623,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.ID"
				},

				/**
				 * ID / Numero Bolla
				 */
				ID_NUMERO_BOLLA: {
					id: 5624,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.ID_NUMERO_BOLLA"
				},

				/**
				 * ID Offerta
				 */
				ID_OFFERTA: {
					id: 5625,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.ID_OFFERTA"
				},

				/**
				 * ID_Offerta
				 */
				IDOFFERTA: {
					id: 5626,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.IDOFFERTA"
				},

				/**
				 * Record eliminato
				 */
				IM_ELIMINATO: {
					id: 5627,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.IM-ELIMINATO"
				},

				/**
				 * Il record è stato salvato correttamente
				 */
				IM_SALVATO: {
					id: 5628,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.IM-SALVATO"
				},

				/**
				 * Invia
				 */
				INVIA: {
					id: 5629,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.INVIA"
				},

				/**
				 * Inviato
				 */
				INVIATO: {
					id: 5630,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.INVIATO"
				},

				/**
				 * Iva
				 */
				IVA: {
					id: 5631,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.IVA"
				},

				/**
				 * Import Tickets
				 */
				MANAGE_IM: {
					id: 5632,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.MANAGE-IM"
				},

				/**
				 * Naz/Int
				 */
				NAZ_INT: {
					id: 5633,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.NAZ_INT"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 5634,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.NOME"
				},

				/**
				 * Il nome è obbligatorio
				 */
				NOME_OBBLIGATORIO: {
					id: 5635,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.NOME-OBBLIGATORIO"
				},

				/**
				 * Note
				 */
				NOTE: {
					id: 5636,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.NOTE"
				},

				/**
				 * Numero Bolla
				 */
				NUMERO_BOLLA: {
					id: 5637,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.NUMERO_BOLLA"
				},

				/**
				 * Nuova record {n}
				 */
				NUOVA_IM_N: {
					id: 5638,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.NUOVA-IM-N"
				},

				/**
				 * Offerta
				 */
				OFFERTA: {
					id: 5639,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.OFFERTA"
				},

				/**
				 * Partenza
				 */
				PARTENZA: {
					id: 5640,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.PARTENZA"
				},

				/**
				 * Partenza Pratica
				 */
				PARTENZA_PRATICA: {
					id: 5641,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.PARTENZA_PRATICA"
				},

				/**
				 * Passeggero
				 */
				PASSEGGERO: {
					id: 5642,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.PASSEGGERO"
				},

				/**
				 * Pax
				 */
				PAX: {
					id: 5643,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.PAX"
				},

				/**
				 * Routing
				 */
				ROUTING: {
					id: 5644,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.ROUTING"
				},

				/**
				 * Scegli file da importare
				 */
				SCEGLI_ALLEGATO: {
					id: 5645,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.SCEGLI-ALLEGATO"
				},

				/**
				 * Scegli...
				 */
				SCEGLI_FILE: {
					id: 5646,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.SCEGLI-FILE"
				},

				/**
				 * Scegliere un file
				 */
				SCEGLIERE_ALLEGATO: {
					id: 5647,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.SCEGLIERE-ALLEGATO"
				},

				/**
				 * Serie
				 */
				SERIE: {
					id: 5648,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.SERIE"
				},

				/**
				 * Tariffa Applicata
				 */
				TARIFFA_APPLICATA: {
					id: 5649,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.TARIFFA_APPLICATA"
				},

				/**
				 * Tariffa Ordinaria
				 */
				TARIFFA_ORDINARIA: {
					id: 5650,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.TARIFFA_ORDINARIA"
				},

				/**
				 * TAX
				 */
				TAX: {
					id: 5651,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.TAX"
				},

				/**
				 * Tipologia
				 */
				TICKET_TYPE: {
					id: 5652,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.TICKET_TYPE"
				},

				/**
				 * TKT
				 */
				TKT: {
					id: 5653,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.TKT"
				},

				/**
				 * Totale
				 */
				TOTALE: {
					id: 5654,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.TOTALE"
				},

				/**
				 * Tutte
				 */
				TUTTE: {
					id: 5655,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.TUTTE"
				},

				/**
				 * Vettore
				 */
				VETTORE: {
					id: 5656,
					label: "intranet.CONTABILITA.IMPORT-MERIDIANO.VETTORE"
				},

			},

			/**
			 * Content of path intranet.CONTABILITA.SALDI
			 */
			SALDI: {
				/**
				 * Content of path intranet.CONTABILITA.SALDI.EDITOR
				 */
				EDITOR: {
					/**
					 * Avere
					 */
					AVERE: {
						id: 5657,
						label: "intranet.CONTABILITA.SALDI.EDITOR.AVERE"
					},

					/**
					 * Banca
					 */
					BANCA: {
						id: 5658,
						label: "intranet.CONTABILITA.SALDI.EDITOR.BANCA"
					},

					/**
					 * Biglietteria
					 */
					BIGLIETTERIA: {
						id: 5659,
						label: "intranet.CONTABILITA.SALDI.EDITOR.BIGLIETTERIA"
					},

					/**
					 * Causale
					 */
					CAUSALE: {
						id: 5660,
						label: "intranet.CONTABILITA.SALDI.EDITOR.CAUSALE"
					},

					/**
					 * Cessione
					 */
					CESSIONE: {
						id: 5661,
						label: "intranet.CONTABILITA.SALDI.EDITOR.CESSIONE"
					},

					/**
					 * Contatto
					 */
					CONTATTO: {
						id: 5662,
						label: "intranet.CONTABILITA.SALDI.EDITOR.CONTATTO"
					},

					/**
					 * Il contatto è obbligatorio
					 */
					CONTATTO_OBBLIGATORIO: {
						id: 5663,
						label: "intranet.CONTABILITA.SALDI.EDITOR.CONTATTO-OBBLIGATORIO"
					},

					/**
					 * Dare
					 */
					DARE: {
						id: 5664,
						label: "intranet.CONTABILITA.SALDI.EDITOR.DARE"
					},

					/**
					 * Dare/Avere
					 */
					DARE_AVERE: {
						id: 5665,
						label: "intranet.CONTABILITA.SALDI.EDITOR.DARE-AVERE"
					},

					/**
					 * Data
					 */
					DATA: {
						id: 5666,
						label: "intranet.CONTABILITA.SALDI.EDITOR.DATA"
					},

					/**
					 * Descrizione
					 */
					DESCRIZIONE: {
						id: 5667,
						label: "intranet.CONTABILITA.SALDI.EDITOR.DESCRIZIONE"
					},

					/**
					 * ID
					 */
					ID: {
						id: 5668,
						label: "intranet.CONTABILITA.SALDI.EDITOR.ID"
					},

					/**
					 * Importo
					 */
					IMPORTO: {
						id: 5669,
						label: "intranet.CONTABILITA.SALDI.EDITOR.IMPORTO"
					},

					/**
					 * Incasso ID={id}
					 */
					INCASSO_ID: {
						id: 5670,
						label: "intranet.CONTABILITA.SALDI.EDITOR.INCASSO-ID"
					},

					/**
					 * Nuovo incasso
					 */
					NUOVO_INCASSO: {
						id: 5671,
						label: "intranet.CONTABILITA.SALDI.EDITOR.NUOVO-INCASSO"
					},

					/**
					 * Offerta
					 */
					OFFERTA: {
						id: 5672,
						label: "intranet.CONTABILITA.SALDI.EDITOR.OFFERTA"
					},

					/**
					 * Pagamento
					 */
					PAGAMENTO: {
						id: 5673,
						label: "intranet.CONTABILITA.SALDI.EDITOR.PAGAMENTO"
					},

					/**
					 * Prepagamento
					 */
					PREPAGAMENTO: {
						id: 5674,
						label: "intranet.CONTABILITA.SALDI.EDITOR.PREPAGAMENTO"
					},

					/**
					 * Riga
					 */
					RIGA: {
						id: 5675,
						label: "intranet.CONTABILITA.SALDI.EDITOR.RIGA"
					},

					/**
					 * Tipo operazione
					 */
					TIPO_OPERAZIONE: {
						id: 5676,
						label: "intranet.CONTABILITA.SALDI.EDITOR.TIPO-OPERAZIONE"
					},

				},

				/**
				 * Incassi
				 */
				INCASSI: {
					id: 5677,
					label: "intranet.CONTABILITA.SALDI.INCASSI"
				},

				/**
				 * Content of path intranet.CONTABILITA.SALDI.LISTA
				 */
				LISTA: {
					/**
					 * A
					 */
					A: {
						id: 5678,
						label: "intranet.CONTABILITA.SALDI.LISTA.A"
					},

					/**
					 * Avere
					 */
					AVERE: {
						id: 5679,
						label: "intranet.CONTABILITA.SALDI.LISTA.AVERE"
					},

					/**
					 * Banca
					 */
					BANCA: {
						id: 5680,
						label: "intranet.CONTABILITA.SALDI.LISTA.BANCA"
					},

					/**
					 * Biglietteria
					 */
					BIGLIETTERIA: {
						id: 5681,
						label: "intranet.CONTABILITA.SALDI.LISTA.BIGLIETTERIA"
					},

					/**
					 * Cessione
					 */
					CESSIONE: {
						id: 5682,
						label: "intranet.CONTABILITA.SALDI.LISTA.CESSIONE"
					},

					/**
					 * Confermi di voler ricalcolare gli incassi di {n} offert{n, plural, =0{e} one{a} other{e}}?
					 */
					CONFERMA_BATCH: {
						id: 5683,
						label: "intranet.CONTABILITA.SALDI.LISTA.CONFERMA-BATCH"
					},

					/**
					 * Sei sicuro di voler eliminare l'incasso ID={id}?
					 */
					CONFERMA_ELIMINA: {
						id: 5684,
						label: "intranet.CONTABILITA.SALDI.LISTA.CONFERMA-ELIMINA"
					},

					/**
					 * Sei sicuro di voler eliminare {n} incass{n, plural, =0{i} one{o} other{i}}?
					 */
					CONFERMA_ELIMINA_MULTI: {
						id: 5685,
						label: "intranet.CONTABILITA.SALDI.LISTA.CONFERMA-ELIMINA-MULTI"
					},

					/**
					 * Contatto
					 */
					CONTATTO: {
						id: 5686,
						label: "intranet.CONTABILITA.SALDI.LISTA.CONTATTO"
					},

					/**
					 * Crea
					 */
					CREA_INCASSI: {
						id: 5687,
						label: "intranet.CONTABILITA.SALDI.LISTA.CREA-INCASSI"
					},

					/**
					 * Crea accorpati
					 */
					CREA_INCASSI_ACCORPATI: {
						id: 5688,
						label: "intranet.CONTABILITA.SALDI.LISTA.CREA-INCASSI-ACCORPATI"
					},

					/**
					 * D
					 */
					D: {
						id: 5689,
						label: "intranet.CONTABILITA.SALDI.LISTA.D"
					},

					/**
					 * D/A
					 */
					D_A: {
						id: 5690,
						label: "intranet.CONTABILITA.SALDI.LISTA.D-A"
					},

					/**
					 * Dare
					 */
					DARE: {
						id: 5691,
						label: "intranet.CONTABILITA.SALDI.LISTA.DARE"
					},

					/**
					 * Dare/Avere
					 */
					DARE_AVERE: {
						id: 5692,
						label: "intranet.CONTABILITA.SALDI.LISTA.DARE-AVERE"
					},

					/**
					 * Descrizione
					 */
					DESCRIZIONE: {
						id: 5693,
						label: "intranet.CONTABILITA.SALDI.LISTA.DESCRIZIONE"
					},

					/**
					 * Descrizione offerta
					 */
					DESCRIZIONE_OFFERTA: {
						id: 5694,
						label: "intranet.CONTABILITA.SALDI.LISTA.DESCRIZIONE-OFFERTA"
					},

					/**
					 * ID
					 */
					ID: {
						id: 5695,
						label: "intranet.CONTABILITA.SALDI.LISTA.ID"
					},

					/**
					 * ID offerta
					 */
					ID_OFFERTA: {
						id: 5696,
						label: "intranet.CONTABILITA.SALDI.LISTA.ID-OFFERTA"
					},

					/**
					 * ID riga
					 */
					ID_RIGA: {
						id: 5697,
						label: "intranet.CONTABILITA.SALDI.LISTA.ID-RIGA"
					},

					/**
					 * Importo
					 */
					IMPORTO: {
						id: 5698,
						label: "intranet.CONTABILITA.SALDI.LISTA.IMPORTO"
					},

					/**
					 * Operatore
					 */
					OPERATORE: {
						id: 5699,
						label: "intranet.CONTABILITA.SALDI.LISTA.OPERATORE"
					},

					/**
					 * Operazione
					 */
					OPERAZIONE: {
						id: 5700,
						label: "intranet.CONTABILITA.SALDI.LISTA.OPERAZIONE"
					},

					/**
					 * Pagamento
					 */
					PAGAMENTO: {
						id: 5701,
						label: "intranet.CONTABILITA.SALDI.LISTA.PAGAMENTO"
					},

					/**
					 * Pag.incasso
					 */
					PAGAMENTO_INCASSO: {
						id: 5702,
						label: "intranet.CONTABILITA.SALDI.LISTA.PAGAMENTO-INCASSO"
					},

					/**
					 * Pagamento offerta
					 */
					PAGAMENTO_OFFERTA: {
						id: 5703,
						label: "intranet.CONTABILITA.SALDI.LISTA.PAGAMENTO-OFFERTA"
					},

					/**
					 * Partenza
					 */
					PARTENZA: {
						id: 5704,
						label: "intranet.CONTABILITA.SALDI.LISTA.PARTENZA"
					},

					/**
					 * al
					 */
					PARTENZA_AL: {
						id: 5705,
						label: "intranet.CONTABILITA.SALDI.LISTA.PARTENZA-AL"
					},

					/**
					 * Partenza dal
					 */
					PARTENZA_DAL: {
						id: 5706,
						label: "intranet.CONTABILITA.SALDI.LISTA.PARTENZA-DAL"
					},

					/**
					 * Partita IVA
					 */
					PARTITA_IVA: {
						id: 5707,
						label: "intranet.CONTABILITA.SALDI.LISTA.PARTITA-IVA"
					},

					/**
					 * Prepagamento
					 */
					PREPAGAMENTO: {
						id: 5708,
						label: "intranet.CONTABILITA.SALDI.LISTA.PREPAGAMENTO"
					},

					/**
					 * Promotore
					 */
					PROMOTORE: {
						id: 5709,
						label: "intranet.CONTABILITA.SALDI.LISTA.PROMOTORE"
					},

					/**
					 * Report scadenze
					 */
					REPORT_SCADENZE: {
						id: 5710,
						label: "intranet.CONTABILITA.SALDI.LISTA.REPORT-SCADENZE"
					},

					/**
					 * Ricalcola in blocco
					 */
					RICALCOLA_BATCH: {
						id: 5711,
						label: "intranet.CONTABILITA.SALDI.LISTA.RICALCOLA-BATCH"
					},

					/**
					 * Ricalcola in blocco dettagliato
					 */
					RICALCOLA_BATCH_DETTAGLIATO: {
						id: 5712,
						label: "intranet.CONTABILITA.SALDI.LISTA.RICALCOLA-BATCH-DETTAGLIATO"
					},

					/**
					 * Salda
					 */
					SALDA: {
						id: 5713,
						label: "intranet.CONTABILITA.SALDI.LISTA.SALDA"
					},

					/**
					 * Scaduto
					 */
					SALDA_SCADUTI: {
						id: 5714,
						label: "intranet.CONTABILITA.SALDI.LISTA.SALDA-SCADUTI"
					},

					/**
					 * Tutto
					 */
					SALDA_TUTTO: {
						id: 5715,
						label: "intranet.CONTABILITA.SALDI.LISTA.SALDA-TUTTO"
					},

					/**
					 * Scadenza
					 */
					SCADENZA: {
						id: 5716,
						label: "intranet.CONTABILITA.SALDI.LISTA.SCADENZA"
					},

					/**
					 * al
					 */
					SCADENZA_AL: {
						id: 5717,
						label: "intranet.CONTABILITA.SALDI.LISTA.SCADENZA-AL"
					},

					/**
					 * Scadenza dal
					 */
					SCADENZA_DAL: {
						id: 5718,
						label: "intranet.CONTABILITA.SALDI.LISTA.SCADENZA-DAL"
					},

					/**
					 * Scadenziario
					 */
					SCADENZIARIO: {
						id: 5719,
						label: "intranet.CONTABILITA.SALDI.LISTA.SCADENZIARIO"
					},

					/**
					 * Seleziona non scaduti
					 */
					SELEZIONA_NON_SCADUTI: {
						id: 5720,
						label: "intranet.CONTABILITA.SALDI.LISTA.SELEZIONA-NON-SCADUTI"
					},

					/**
					 * Seleziona scaduti
					 */
					SELEZIONA_SCADUTI: {
						id: 5721,
						label: "intranet.CONTABILITA.SALDI.LISTA.SELEZIONA-SCADUTI"
					},

					/**
					 * Totale avere
					 */
					TOTALE_AVERE: {
						id: 5722,
						label: "intranet.CONTABILITA.SALDI.LISTA.TOTALE-AVERE"
					},

					/**
					 * Totale avere scaduto
					 */
					TOTALE_AVERE_SCADUTO: {
						id: 5723,
						label: "intranet.CONTABILITA.SALDI.LISTA.TOTALE-AVERE-SCADUTO"
					},

					/**
					 * Totale dare
					 */
					TOTALE_DARE: {
						id: 5724,
						label: "intranet.CONTABILITA.SALDI.LISTA.TOTALE-DARE"
					},

					/**
					 * Totale sbilancio
					 */
					TOTALE_SBILANCIO: {
						id: 5725,
						label: "intranet.CONTABILITA.SALDI.LISTA.TOTALE-SBILANCIO"
					},

					/**
					 * Tutto
					 */
					TUTTO: {
						id: 5726,
						label: "intranet.CONTABILITA.SALDI.LISTA.TUTTO"
					},

				},

				/**
				 * Nuovo incasso {n}
				 */
				NUOVO_INCASSO: {
					id: 5727,
					label: "intranet.CONTABILITA.SALDI.NUOVO-INCASSO"
				},

				/**
				 * Incassi e saldi
				 */
				TITOLO: {
					id: 5728,
					label: "intranet.CONTABILITA.SALDI.TITOLO"
				},

			},

			/**
			 * Content of path intranet.CONTABILITA.VALUTE
			 */
			VALUTE: {
				/**
				 * Aggiorna quotazioni di cambio
				 */
				AGGIORNA_CAMBI: {
					id: 5729,
					label: "intranet.CONTABILITA.VALUTE.AGGIORNA-CAMBI"
				},

				/**
				 * Andamento cambio
				 */
				ANDAMENTO_CAMBIO: {
					id: 5730,
					label: "intranet.CONTABILITA.VALUTE.ANDAMENTO-CAMBIO"
				},

				/**
				 * Applicato
				 */
				APPLICATO: {
					id: 5731,
					label: "intranet.CONTABILITA.VALUTE.APPLICATO"
				},

				/**
				 * Impossibile impostare un cambio a 0
				 */
				CAMBIO_A_ZERO: {
					id: 5732,
					label: "intranet.CONTABILITA.VALUTE.CAMBIO-A-ZERO"
				},

				/**
				 * Caricati cambi aggiornati
				 */
				CARICATI_CAMBI_AGGIORNATI: {
					id: 5733,
					label: "intranet.CONTABILITA.VALUTE.CARICATI-CAMBI-AGGIORNATI"
				},

				/**
				 * Codice
				 */
				CODICE: {
					id: 5734,
					label: "intranet.CONTABILITA.VALUTE.CODICE"
				},

				/**
				 * Confermi il nuovo cambio per {code} ({name})?Valore corrente: {corrente} => {nuovoCorrente}Valore applicato: {applicato} => {nuovoApplicato}
				 */
				CONFERMA_NUOVO_CAMBIO: {
					id: 5735,
					label: "intranet.CONTABILITA.VALUTE.CONFERMA-NUOVO-CAMBIO"
				},

				/**
				 * Corrente
				 */
				CORRENTE: {
					id: 5736,
					label: "intranet.CONTABILITA.VALUTE.CORRENTE"
				},

				/**
				 * Inserisci nuovi valori
				 */
				INSERISCI_NUOVI_VALORI: {
					id: 5737,
					label: "intranet.CONTABILITA.VALUTE.INSERISCI-NUOVI-VALORI"
				},

				/**
				 * Nuovo valore applicato
				 */
				NUOVO_VALORE_APPLICATO: {
					id: 5738,
					label: "intranet.CONTABILITA.VALUTE.NUOVO-VALORE-APPLICATO"
				},

				/**
				 * Nuovo valore corrente
				 */
				NUOVO_VALORE_CORRENTE: {
					id: 5739,
					label: "intranet.CONTABILITA.VALUTE.NUOVO-VALORE-CORRENTE"
				},

				/**
				 * Ricerca
				 */
				RICERCA: {
					id: 5740,
					label: "intranet.CONTABILITA.VALUTE.RICERCA"
				},

				/**
				 * Storico Valuta
				 */
				STORICO_VALUTA: {
					id: 5741,
					label: "intranet.CONTABILITA.VALUTE.STORICO-VALUTA"
				},

				/**
				 * Valute
				 */
				TITOLO: {
					id: 5742,
					label: "intranet.CONTABILITA.VALUTE.TITOLO"
				},

				/**
				 * Ultimo agg.
				 */
				ULTIMO_AGGIORNAMENTO: {
					id: 5743,
					label: "intranet.CONTABILITA.VALUTE.ULTIMO-AGGIORNAMENTO"
				},

				/**
				 * Valore applicato
				 */
				VALORE_APPLICATO: {
					id: 5744,
					label: "intranet.CONTABILITA.VALUTE.VALORE-APPLICATO"
				},

				/**
				 * Valore corrente
				 */
				VALORE_CORRENTE: {
					id: 5745,
					label: "intranet.CONTABILITA.VALUTE.VALORE-CORRENTE"
				},

				/**
				 * Valuta
				 */
				VALUTA: {
					id: 5746,
					label: "intranet.CONTABILITA.VALUTE.VALUTA"
				},

				/**
				 * Vedi storico {code}
				 */
				VEDI_STORICO: {
					id: 5747,
					label: "intranet.CONTABILITA.VALUTE.VEDI-STORICO"
				},

			},

			/**
			 * Content of path intranet.CONTABILITA.SCADENZIARIO
			 */
			SCADENZIARIO: {
				/**
				 * Scadenziario
				 */
				TITOLO: {
					id: 6144,
					label: "intranet.CONTABILITA.SCADENZIARIO.TITOLO"
				},

				/**
				 * Content of path intranet.CONTABILITA.SCADENZIARIO.LISTA
				 */
				LISTA: {
					/**
					 * Agenzia
					 */
					AGENZIA: {
						id: 6146,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.AGENZIA"
					},

					/**
					 * Partita IVA
					 */
					PARTITA_IVA: {
						id: 6147,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.PARTITA-IVA"
					},

					/**
					 * Offerta
					 */
					OFFERTA: {
						id: 6148,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.OFFERTA"
					},

					/**
					 * Partenza dal
					 */
					PARTENZA_DAL: {
						id: 6149,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.PARTENZA-DAL"
					},

					/**
					 * al
					 */
					PARTENZA_AL: {
						id: 6150,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.PARTENZA-AL"
					},

					/**
					 * Destinazione
					 */
					DESTINAZIONE: {
						id: 6151,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.DESTINAZIONE"
					},

					/**
					 * Tipo
					 */
					TIPO: {
						id: 6152,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.TIPO"
					},

					/**
					 * Nozze
					 */
					NOZZE: {
						id: 6160,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.NOZZE"
					},

					/**
					 * Descrizione
					 */
					DESCRIZIONE: {
						id: 6161,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.DESCRIZIONE"
					},

					/**
					 * Partenza
					 */
					PARTENZA: {
						id: 6162,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.PARTENZA"
					},

					/**
					 * Conferma
					 */
					CONFERMA: {
						id: 6163,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.CONFERMA"
					},

					/**
					 * Conferma TO
					 */
					CONFERMA_TO: {
						id: 6164,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.CONFERMA-TO"
					},

					/**
					 * Operatore
					 */
					OPERATORE: {
						id: 6165,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.OPERATORE"
					},

					/**
					 * Città
					 */
					CITTA: {
						id: 6166,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.CITTA"
					},

					/**
					 * Totale
					 */
					TOTALE: {
						id: 6167,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.TOTALE"
					},

					/**
					 * Pag.
					 */
					PAG: {
						id: 6168,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.PAG"
					},

					/**
					 * Scadenze
					 */
					SCADENZE: {
						id: 6169,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.SCADENZE"
					},

					/**
					 * Saldato
					 */
					SALDATO: {
						id: 6170,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.SALDATO"
					},

					/**
					 * Saldare
					 */
					SALDARE: {
						id: 6171,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.SALDARE"
					},

					/**
					 * Status
					 */
					STATUS: {
						id: 6172,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.STATUS"
					},

					/**
					 * Clienti
					 */
					CLIENTI: {
						id: 6173,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.CLIENTI"
					},

					/**
					 * Pagamento
					 */
					PAGAMENTO: {
						id: 6174,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.PAGAMENTO"
					},

					/**
					 * Sono visualizzate le prime {max} righe delle {tot} che corrispondono ai criteri di ricerca. 
					 */
					WARNINGRIGHE: {
						id: 6178,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.WARNINGRIGHE"
					},

					/**
					 * TKT Parte
					 */
					TKT_PARTE: {
						id: 6179,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.TKT-PARTE"
					},

					/**
					 * TKT Tutto
					 */
					TKT_TUTTO: {
						id: 6180,
						label: "intranet.CONTABILITA.SCADENZIARIO.LISTA.TKT-TUTTO"
					},

				},

			},

		},

		/**
		 * Content of path intranet.DASHBOARD
		 */
		DASHBOARD: {
			/**
			 * File allegato
			 */
			FILE_ALLEGATO: {
				id: 5748,
				label: "intranet.DASHBOARD.FILE-ALLEGATO"
			},

			/**
			 * il
			 */
			IL: {
				id: 5749,
				label: "intranet.DASHBOARD.IL"
			},

			/**
			 * Dashboard
			 */
			TITOLO: {
				id: 5750,
				label: "intranet.DASHBOARD.TITOLO"
			},

			/**
			 * Ultimo aggiornamento
			 */
			ULTIMA_MODIFICA: {
				id: 5751,
				label: "intranet.DASHBOARD.ULTIMA-MODIFICA"
			},

			/**
			 * Content of path intranet.DASHBOARD.WIDGET
			 */
			WIDGET: {
				/**
				 * Content of path intranet.DASHBOARD.WIDGET.TITOLO
				 */
				TITOLO: {
					/**
					 * Admin
					 */
					Admin: {
						id: 5955,
						label: "intranet.DASHBOARD.WIDGET.TITOLO.Admin"
					},

					/**
					 * Statistiche
					 */
					Statistic: {
						id: 5959,
						label: "intranet.DASHBOARD.WIDGET.TITOLO.Statistic"
					},

					/**
					 * Basecamp
					 */
					Basecamp: {
						id: 5960,
						label: "intranet.DASHBOARD.WIDGET.TITOLO.Basecamp"
					},

					/**
					 * Traduzioni
					 */
					Translation: {
						id: 6226,
						label: "intranet.DASHBOARD.WIDGET.TITOLO.Translation"
					},

				},

				/**
				 * Accedi a BaseCamp
				 */
				LOGIN_BASECAMP: {
					id: 5956,
					label: "intranet.DASHBOARD.WIDGET.LOGIN-BASECAMP"
				},

				/**
				 * Widget non configurato
				 */
				NOT_CONFIGURED: {
					id: 5957,
					label: "intranet.DASHBOARD.WIDGET.NOT-CONFIGURED"
				},

				/**
				 * Content of path intranet.DASHBOARD.WIDGET.DESCRIPTION
				 */
				DESCRIPTION: {
					/**
					 * Errori
					 */
					Errors: {
						id: 5962,
						label: "intranet.DASHBOARD.WIDGET.DESCRIPTION.Errors"
					},

					/**
					 * Utenti loggati
					 */
					LoggedUsers: {
						id: 5963,
						label: "intranet.DASHBOARD.WIDGET.DESCRIPTION.LoggedUsers"
					},

				},

				/**
				 * Non ci sono messaggi
				 */
				NO_MESSAGES_BASECAMP: {
					id: 6107,
					label: "intranet.DASHBOARD.WIDGET.NO-MESSAGES-BASECAMP"
				},

				/**
				 * Logout
				 */
				LOGOUT_BASECAMP: {
					id: 6108,
					label: "intranet.DASHBOARD.WIDGET.LOGOUT-BASECAMP"
				},

				/**
				 * Salva per fare il logout!
				 */
				LOGOUT_BASECAMP_DISABLED: {
					id: 6109,
					label: "intranet.DASHBOARD.WIDGET.LOGOUT-BASECAMP-DISABLED"
				},

				/**
				 * Date: è possibile inserirle anche nel formato gg/mm/aaaa, senza usare apici.
				 * Usa "TODAY()" per indicare di usare la data corrente.
				 * Usa "TODAY(1)" o "TODAY(-7)" per indicare i giorni da aggiungere o togliere alla data corrente
				 */
				STATISTICHE_PARAMETRI_HINT: {
					id: 6183,
					label: "intranet.DASHBOARD.WIDGET.STATISTICHE-PARAMETRI-HINT"
				},

			},

			/**
			 * Content of path intranet.DASHBOARD.ANNULLA-MODIFICHE
			 */
			ANNULLA_MODIFICHE: {
				/**
				 * Sei sicuro di voler annullare le modifiche?
				 */
				MESSAGGIO: {
					id: 5972,
					label: "intranet.DASHBOARD.ANNULLA-MODIFICHE.MESSAGGIO"
				},

				/**
				 * Cancella
				 */
				CANCEL: {
					id: 5973,
					label: "intranet.DASHBOARD.ANNULLA-MODIFICHE.CANCEL"
				},

				/**
				 * Ok
				 */
				OK: {
					id: 5974,
					label: "intranet.DASHBOARD.ANNULLA-MODIFICHE.OK"
				},

			},

			/**
			 * Dashboard - Edit
			 */
			TITOLO_EDIT: {
				id: 6227,
				label: "intranet.DASHBOARD.TITOLO-EDIT"
			},

			/**
			 * DASHBOARD.TITOLO_
			 */
			TITOLO_: {
				id: 6513,
				label: "intranet.DASHBOARD.TITOLO_"
			},

			/**
			 * DASHBOARD.TITOLO2
			 */
			TITOLO2: {
				id: 6514,
				label: "intranet.DASHBOARD.TITOLO2"
			},

			/**
			 * DASHBOARD.TITOLO3
			 */
			TITOLO3: {
				id: 6515,
				label: "intranet.DASHBOARD.TITOLO3"
			},

		},

		/**
		 * Content of path intranet.ENTITY-OPENER
		 */
		ENTITY_OPENER: {
			/**
			 * Apri anagrafica luogo
			 */
			APRI_ANAGARFICA_LUOGO: {
				id: 5752,
				label: "intranet.ENTITY-OPENER.APRI-ANAGARFICA-LUOGO"
			},

			/**
			 * Apri anagrafica accordo
			 */
			APRI_ANAGRAFICA_ACCORDO: {
				id: 5753,
				label: "intranet.ENTITY-OPENER.APRI-ANAGRAFICA-ACCORDO"
			},

			/**
			 * Apri anagrafica contatto
			 */
			APRI_ANAGRAFICA_CONTATTO: {
				id: 5754,
				label: "intranet.ENTITY-OPENER.APRI-ANAGRAFICA-CONTATTO"
			},

			/**
			 * Apri anagrafica linea aerea
			 */
			APRI_ANAGRAFICA_LINEA_AEREA: {
				id: 5755,
				label: "intranet.ENTITY-OPENER.APRI-ANAGRAFICA-LINEA-AEREA"
			},

			/**
			 * Apri anagrafica servizio
			 */
			APRI_ANAGRAFICA_SERVIZIO: {
				id: 5756,
				label: "intranet.ENTITY-OPENER.APRI-ANAGRAFICA-SERVIZIO"
			},

			/**
			 * Apri anagrafica utenti
			 */
			APRI_ANAGRAFICA_UTENTI: {
				id: 5757,
				label: "intranet.ENTITY-OPENER.APRI-ANAGRAFICA-UTENTI"
			},

			/**
			 * Apri articolo
			 */
			APRI_ANAGRAFICA_ARTICOLO: {
				id: 6079,
				label: "intranet.ENTITY-OPENER.APRI-ANAGRAFICA-ARTICOLO"
			},

			/**
			 * Apri anagrafica servizio H2H
			 */
			APRI_ANAGRAFICA_SERVIZIO_H2H: {
				id: 6115,
				label: "intranet.ENTITY-OPENER.APRI-ANAGRAFICA-SERVIZIO-H2H"
			},

			/**
			 * ENTITY-OPENER.APRI-ANAGRAFICA-IMMAGINE
			 */
			APRI_ANAGRAFICA_IMMAGINE: {
				id: 6314,
				label: "intranet.ENTITY-OPENER.APRI-ANAGRAFICA-IMMAGINE"
			},

		},

		/**
		 * Content of path intranet.CMS
		 */
		CMS: {
			/**
			 * Content of path intranet.CMS.B2C
			 */
			B2C: {
				/**
				 * Content of path intranet.CMS.B2C.PAGELINK
				 */
				PAGELINK: {
					/**
					 * CMS
					 */
					PAGELINKS: {
						id: 6037,
						label: "intranet.CMS.B2C.PAGELINK.PAGELINKS"
					},

					/**
					 * Pagina {n}
					 */
					NUOVA_PAGELINK_N: {
						id: 6038,
						label: "intranet.CMS.B2C.PAGELINK.NUOVA-PAGELINK-N"
					},

					/**
					 * ID
					 */
					ID: {
						id: 6042,
						label: "intranet.CMS.B2C.PAGELINK.ID"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 6043,
						label: "intranet.CMS.B2C.PAGELINK.NOME"
					},

					/**
					 * Operatore
					 */
					OPERATORE: {
						id: 6044,
						label: "intranet.CMS.B2C.PAGELINK.OPERATORE"
					},

					/**
					 * Tipo
					 */
					TIPO: {
						id: 6045,
						label: "intranet.CMS.B2C.PAGELINK.TIPO"
					},

					/**
					 * Posizione
					 */
					POSIZIONE: {
						id: 6046,
						label: "intranet.CMS.B2C.PAGELINK.POSIZIONE"
					},

					/**
					 * URL
					 */
					URL: {
						id: 6047,
						label: "intranet.CMS.B2C.PAGELINK.URL"
					},

					/**
					 * Dati pagina
					 */
					DATI: {
						id: 6055,
						label: "intranet.CMS.B2C.PAGELINK.DATI"
					},

					/**
					 * Il nome della pagina è obbligatorio
					 */
					NOME_OBBLIGATORIO: {
						id: 6056,
						label: "intranet.CMS.B2C.PAGELINK.NOME-OBBLIGATORIO"
					},

					/**
					 * Nome inglese
					 */
					NOME_INGLESE: {
						id: 6057,
						label: "intranet.CMS.B2C.PAGELINK.NOME-INGLESE"
					},

					/**
					 * Tipo obbligatorio
					 */
					TIPO_OBBLIGATORIO: {
						id: 6058,
						label: "intranet.CMS.B2C.PAGELINK.TIPO-OBBLIGATORIO"
					},

					/**
					 * Operatore obbligatorio
					 */
					OPERATORE_OBBLIGATORIO: {
						id: 6059,
						label: "intranet.CMS.B2C.PAGELINK.OPERATORE-OBBLIGATORIO"
					},

					/**
					 * Ordine
					 */
					ORDINE: {
						id: 6060,
						label: "intranet.CMS.B2C.PAGELINK.ORDINE"
					},

					/**
					 * Ordine obbligatorio
					 */
					ORDINE_OBBLIGATORIO: {
						id: 6061,
						label: "intranet.CMS.B2C.PAGELINK.ORDINE-OBBLIGATORIO"
					},

					/**
					 * Luogo
					 */
					LUOGO: {
						id: 6062,
						label: "intranet.CMS.B2C.PAGELINK.LUOGO"
					},

					/**
					 * Servizio
					 */
					SERVIZIO: {
						id: 6063,
						label: "intranet.CMS.B2C.PAGELINK.SERVIZIO"
					},

					/**
					 * Pubblica dal
					 */
					PUBBLICA_DAL: {
						id: 6064,
						label: "intranet.CMS.B2C.PAGELINK.PUBBLICA-DAL"
					},

					/**
					 * Pubblica al
					 */
					PUBBLICA_AL: {
						id: 6065,
						label: "intranet.CMS.B2C.PAGELINK.PUBBLICA-AL"
					},

					/**
					 * Vuoi davvero eliminare il dato selezionato?
					 */
					ELIMINAZIONE: {
						id: 6069,
						label: "intranet.CMS.B2C.PAGELINK.ELIMINAZIONE"
					},

					/**
					 * Dato eliminato
					 */
					ELIMINATO: {
						id: 6070,
						label: "intranet.CMS.B2C.PAGELINK.ELIMINATO"
					},

					/**
					 * CMS.B2C.PAGELINK.SALVATO
					 */
					SALVATO: {
						id: 6575,
						label: "intranet.CMS.B2C.PAGELINK.SALVATO"
					},

				},

				/**
				 * Ricerca
				 */
				RICERCA: {
					id: 6039,
					label: "intranet.CMS.B2C.RICERCA"
				},

				/**
				 * Content of path intranet.CMS.B2C.PAGECONTENT
				 */
				PAGECONTENT: {
					/**
					 * Contenuto della pagina
					 */
					PAGECONTENT: {
						id: 6048,
						label: "intranet.CMS.B2C.PAGECONTENT.PAGECONTENT"
					},

					/**
					 * Tipo contenuto
					 */
					ENTITY_TYPE: {
						id: 6049,
						label: "intranet.CMS.B2C.PAGECONTENT.ENTITY-TYPE"
					},

					/**
					 * ID entità
					 */
					ENTITY_ID: {
						id: 6050,
						label: "intranet.CMS.B2C.PAGECONTENT.ENTITY-ID"
					},

					/**
					 * Ordine
					 */
					ORDINE: {
						id: 6051,
						label: "intranet.CMS.B2C.PAGECONTENT.ORDINE"
					},

					/**
					 * Icona
					 */
					FA_ICON: {
						id: 6052,
						label: "intranet.CMS.B2C.PAGECONTENT.FA-ICON"
					},

					/**
					 * Pagina ID
					 */
					PAGELINK_ID: {
						id: 6053,
						label: "intranet.CMS.B2C.PAGECONTENT.PAGELINK-ID"
					},

					/**
					 * ID della pagina obbligatorio
					 */
					PAGELINK_ID_OBBLIGATORIO: {
						id: 6054,
						label: "intranet.CMS.B2C.PAGECONTENT.PAGELINK-ID-OBBLIGATORIO"
					},

					/**
					 * ID
					 */
					ID: {
						id: 6066,
						label: "intranet.CMS.B2C.PAGECONTENT.ID"
					},

					/**
					 * Tipo obbligatorio
					 */
					ENTITY_TYPE_OGGLIGATORIO: {
						id: 6068,
						label: "intranet.CMS.B2C.PAGECONTENT.ENTITY-TYPE-OGGLIGATORIO"
					},

					/**
					 * Nome entità
					 */
					ENTITY_NAME: {
						id: 6090,
						label: "intranet.CMS.B2C.PAGECONTENT.ENTITY-NAME"
					},

					/**
					 * Vuoi davvero cancellare il contenuto ID {id}?
					 */
					ELIMINAZIONE: {
						id: 6097,
						label: "intranet.CMS.B2C.PAGECONTENT.ELIMINAZIONE"
					},

					/**
					 * Contenuto cancellato
					 */
					ELIMINATO: {
						id: 6098,
						label: "intranet.CMS.B2C.PAGECONTENT.ELIMINATO"
					},

					/**
					 * CMS.B2C.PAGECONTENT.SALVATO
					 */
					SALVATO: {
						id: 6574,
						label: "intranet.CMS.B2C.PAGECONTENT.SALVATO"
					},

					/**
					 * CMS.B2C.PAGECONTENT.RIPRISTINO
					 */
					RIPRISTINO: {
						id: 6576,
						label: "intranet.CMS.B2C.PAGECONTENT.RIPRISTINO"
					},

					/**
					 * CMS.B2C.PAGECONTENT.RIPRISTINATO
					 */
					RIPRISTINATO: {
						id: 6577,
						label: "intranet.CMS.B2C.PAGECONTENT.RIPRISTINATO"
					},

					/**
					 * CMS.B2C.PAGECONTENT.CLONATO
					 */
					CLONATO: {
						id: 6594,
						label: "intranet.CMS.B2C.PAGECONTENT.CLONATO"
					},

				},

				/**
				 * Ordine obbligatorio
				 */
				ORDINE_OBBLIGATORIO: {
					id: 6067,
					label: "intranet.CMS.B2C.ORDINE-OBBLIGATORIO"
				},

			},

		},

		/**
		 * 1° giorno
		 */
		_1__giorno: {
			id: 6347,
			label: "intranet.1\u00B0 giorno"
		},

		/**
		 * 3° giorno
		 */
		_3__giorno: {
			id: 6348,
			label: "intranet.3\u00B0 giorno"
		},

		/**
		 * 4° giorno
		 */
		_4__giorno: {
			id: 6349,
			label: "intranet.4\u00B0 giorno"
		},

		/**
		 * 5° giorno
		 */
		_5__giorno: {
			id: 6350,
			label: "intranet.5\u00B0 giorno"
		},

		/**
		 * ANAGRAFICA.SERVIZIH2H.TITLE
		 */
		ANAGRAFICA: {
			id: 6352,
			label: "intranet.ANAGRAFICA.SERVIZIH2H.TITLE"
		},

		/**
		 * --
		 */
		__: {
			id: 6370,
			label: "intranet.--"
		},

		/**
		 * 2° giorno
		 */
		_2__giorno: {
			id: 6374,
			label: "intranet.2\u00B0 giorno"
		},

		/**
		 * 7° giorno
		 */
		_7__giorno: {
			id: 6375,
			label: "intranet.7\u00B0 giorno"
		},

		/**
		 * 6° giorno
		 */
		_6__giorno: {
			id: 6376,
			label: "intranet.6\u00B0 giorno"
		},

		/**
		 * 8° giorno
		 */
		_8__giorno: {
			id: 6379,
			label: "intranet.8\u00B0 giorno"
		},

		/**
		 * 10° giorno
		 */
		_10__giorno: {
			id: 6380,
			label: "intranet.10\u00B0 giorno"
		},

		/**
		 * 9° giorno
		 */
		_9__giorno: {
			id: 6381,
			label: "intranet.9\u00B0 giorno"
		},

		/**
		 * 12° giorno
		 */
		_12__giorno: {
			id: 6382,
			label: "intranet.12\u00B0 giorno"
		},

		/**
		 * 11° giorno
		 */
		_11__giorno: {
			id: 6383,
			label: "intranet.11\u00B0 giorno"
		},

		/**
		 * 13° giorno
		 */
		_13__giorno: {
			id: 6384,
			label: "intranet.13\u00B0 giorno"
		},

		/**
		 * 1th day
		 */
		_1th_day: {
			id: 6386,
			label: "intranet.1th day"
		},

		/**
		 * 2th day
		 */
		_2th_day: {
			id: 6387,
			label: "intranet.2th day"
		},

		/**
		 * 3th day
		 */
		_3th_day: {
			id: 6388,
			label: "intranet.3th day"
		},

		/**
		 * 5th day
		 */
		_5th_day: {
			id: 6389,
			label: "intranet.5th day"
		},

		/**
		 * 6th day
		 */
		_6th_day: {
			id: 6390,
			label: "intranet.6th day"
		},

		/**
		 * 8th day
		 */
		_8th_day: {
			id: 6391,
			label: "intranet.8th day"
		},

		/**
		 * 7th day
		 */
		_7th_day: {
			id: 6392,
			label: "intranet.7th day"
		},

		/**
		 * 4th day
		 */
		_4th_day: {
			id: 6393,
			label: "intranet.4th day"
		},

		/**
		 * 9th day
		 */
		_9th_day: {
			id: 6394,
			label: "intranet.9th day"
		},

		/**
		 * 10th day
		 */
		_10th_day: {
			id: 6396,
			label: "intranet.10th day"
		},

		/**
		 * 11th day
		 */
		_11th_day: {
			id: 6397,
			label: "intranet.11th day"
		},

		/**
		 * 13th day
		 */
		_13th_day: {
			id: 6398,
			label: "intranet.13th day"
		},

		/**
		 * 14th day
		 */
		_14th_day: {
			id: 6399,
			label: "intranet.14th day"
		},

		/**
		 * 12th day
		 */
		_12th_day: {
			id: 6400,
			label: "intranet.12th day"
		},

		/**
		 * 16th day
		 */
		_16th_day: {
			id: 6411,
			label: "intranet.16th day"
		},

		/**
		 * 15th day
		 */
		_15th_day: {
			id: 6412,
			label: "intranet.15th day"
		},

		/**
		 * 14° giorno
		 */
		_14__giorno: {
			id: 6416,
			label: "intranet.14\u00B0 giorno"
		},

		/**
		 * 15° giorno
		 */
		_15__giorno: {
			id: 6483,
			label: "intranet.15\u00B0 giorno"
		},

		/**
		 * 16° giorno
		 */
		_16__giorno: {
			id: 6484,
			label: "intranet.16\u00B0 giorno"
		},

		/**
		 * 18th day
		 */
		_18th_day: {
			id: 6488,
			label: "intranet.18th day"
		},

		/**
		 * 17th day
		 */
		_17th_day: {
			id: 6489,
			label: "intranet.17th day"
		},

		/**
		 * 20th day
		 */
		_20th_day: {
			id: 6490,
			label: "intranet.20th day"
		},

		/**
		 * 19th day
		 */
		_19th_day: {
			id: 6491,
			label: "intranet.19th day"
		},

		/**
		 * 21th day
		 */
		_21th_day: {
			id: 6492,
			label: "intranet.21th day"
		},

		/**
		 * 22th day
		 */
		_22th_day: {
			id: 6493,
			label: "intranet.22th day"
		},

		/**
		 * 17° giorno
		 */
		_17__giorno: {
			id: 6546,
			label: "intranet.17\u00B0 giorno"
		},

		/**
		 * 18° giorno
		 */
		_18__giorno: {
			id: 6581,
			label: "intranet.18\u00B0 giorno"
		},

		/**
		 * Content of path intranet.USER
		 */
		USER: {
			/**
			 * Content of path intranet.USER.CAMBIO-PASSWORD
			 */
			CAMBIO_PASSWORD: {
				/**
				 * USER.CAMBIO-PASSWORD.NUOVA-PASSWORD
				 */
				NUOVA_PASSWORD: {
					id: 6590,
					label: "intranet.USER.CAMBIO-PASSWORD.NUOVA-PASSWORD"
				},

				/**
				 * USER.CAMBIO-PASSWORD.RIPETI-NUOVA-PASSWORD
				 */
				RIPETI_NUOVA_PASSWORD: {
					id: 6591,
					label: "intranet.USER.CAMBIO-PASSWORD.RIPETI-NUOVA-PASSWORD"
				},

			},

		},

	},

	/**
	 * Content of path b2b
	 */
	b2b: {
		/**
		 * Content of path b2b.ARTICLE-CHOOSER
		 */
		ARTICLE_CHOOSER: {
			/**
			 * Accordo
			 */
			ACCORDO: {
				id: 1807,
				label: "b2b.ARTICLE-CHOOSER.ACCORDO"
			},

			/**
			 * Categoria
			 */
			CATEGORIA: {
				id: 1808,
				label: "b2b.ARTICLE-CHOOSER.CATEGORIA"
			},

			/**
			 * Giorno
			 */
			GIORNO: {
				id: 1809,
				label: "b2b.ARTICLE-CHOOSER.GIORNO"
			},

			/**
			 * ID
			 */
			ID: {
				id: 1810,
				label: "b2b.ARTICLE-CHOOSER.ID"
			},

			/**
			 * Intestazione
			 */
			INTESTAZIONE: {
				id: 1811,
				label: "b2b.ARTICLE-CHOOSER.INTESTAZIONE"
			},

			/**
			 * Luogo
			 */
			LUOGO: {
				id: 1812,
				label: "b2b.ARTICLE-CHOOSER.LUOGO"
			},

			/**
			 * Non associato
			 */
			NON_ASSOCIATO: {
				id: 1813,
				label: "b2b.ARTICLE-CHOOSER.NON-ASSOCIATO"
			},

			/**
			 * Ordine
			 */
			ORDINE: {
				id: 1814,
				label: "b2b.ARTICLE-CHOOSER.ORDINE"
			},

			/**
			 * Ord.glob.
			 */
			ORDINE_GLOBALE: {
				id: 1815,
				label: "b2b.ARTICLE-CHOOSER.ORDINE-GLOBALE"
			},

			/**
			 * Scegli
			 */
			SCEGLI: {
				id: 1816,
				label: "b2b.ARTICLE-CHOOSER.SCEGLI"
			},

			/**
			 * Scegli un articolo
			 */
			SCEGLI_ARTICOLO: {
				id: 1817,
				label: "b2b.ARTICLE-CHOOSER.SCEGLI-ARTICOLO"
			},

			/**
			 * Servizio
			 */
			SERVIZIO: {
				id: 1818,
				label: "b2b.ARTICLE-CHOOSER.SERVIZIO"
			},

			/**
			 * Titolo
			 */
			TITOLO: {
				id: 1819,
				label: "b2b.ARTICLE-CHOOSER.TITOLO"
			},

			/**
			 * Titolo / ID
			 */
			TITOLO_ID: {
				id: 1820,
				label: "b2b.ARTICLE-CHOOSER.TITOLO-ID"
			},

			/**
			 * Titolo originale
			 */
			TITOLO_ORIGINALE: {
				id: 1821,
				label: "b2b.ARTICLE-CHOOSER.TITOLO-ORIGINALE"
			},

			/**
			 * -- Tutte le categorie --
			 */
			TUTTE_LE_CATEGORIE: {
				id: 1822,
				label: "b2b.ARTICLE-CHOOSER.TUTTE-LE-CATEGORIE"
			},

			/**
			 * -- Tutte le intestazioni --
			 */
			TUTTE_LE_INTESTAZIONI: {
				id: 1823,
				label: "b2b.ARTICLE-CHOOSER.TUTTE-LE-INTESTAZIONI"
			},

			/**
			 * Ultimo agg.
			 */
			ULTIMO_AGGIORNAMENTO: {
				id: 1824,
				label: "b2b.ARTICLE-CHOOSER.ULTIMO-AGGIORNAMENTO"
			},

		},

		/**
		 * Content of path b2b.AUTH
		 */
		AUTH: {
			/**
			 * Content of path b2b.AUTH.LOGIN
			 */
			LOGIN: {
				/**
				 * Accedi
				 */
				ACCEDI: {
					id: 1825,
					label: "b2b.AUTH.LOGIN.ACCEDI"
				},

				/**
				 * Accesso non riuscito
				 */
				ACCESSO_NON_RIUSCITO: {
					id: 1826,
					label: "b2b.AUTH.LOGIN.ACCESSO-NON-RIUSCITO"
				},

				/**
				 * {nome} inserisci la tua password per accedere come {target}
				 */
				INSERISCI_LA_TUA_PASSWORD: {
					id: 1827,
					label: "b2b.AUTH.LOGIN.INSERISCI-LA-TUA-PASSWORD"
				},

				/**
				 * Login
				 */
				LOGIN: {
					id: 1828,
					label: "b2b.AUTH.LOGIN.LOGIN"
				},

				/**
				 * Nome utente e/o password errati
				 */
				NOME_O_PASSWORD_ERRATI: {
					id: 1829,
					label: "b2b.AUTH.LOGIN.NOME-O-PASSWORD-ERRATI"
				},

				/**
				 * Password
				 */
				PASSWORD: {
					id: 1830,
					label: "b2b.AUTH.LOGIN.PASSWORD"
				},

				/**
				 * Compilare la password
				 */
				PASSWORD_OBBLIGATORIA: {
					id: 1831,
					label: "b2b.AUTH.LOGIN.PASSWORD-OBBLIGATORIA"
				},

				/**
				 * Ti è stata inviata una mail con le istruzioni per recuperare la password
				 */
				RECUPERO_INVIATO: {
					id: 1832,
					label: "b2b.AUTH.LOGIN.RECUPERO-INVIATO"
				},

				/**
				 * Content of path b2b.AUTH.LOGIN.RECUPERO-PASSWORD
				 */
				RECUPERO_PASSWORD: {
				},

				/**
				 * Richiedi reimposta password
				 */
				RICHIEDI_RECUPERO_PASSWORD: {
					id: 1834,
					label: "b2b.AUTH.LOGIN.RICHIEDI-RECUPERO-PASSWORD"
				},

				/**
				 * Ricordami
				 */
				RICORDAMI: {
					id: 1835,
					label: "b2b.AUTH.LOGIN.RICORDAMI"
				},

				/**
				 * Accesso alla intranet
				 */
				TITLE: {
					id: 1836,
					label: "b2b.AUTH.LOGIN.TITLE"
				},

				/**
				 * Utente
				 */
				UTENTE: {
					id: 1837,
					label: "b2b.AUTH.LOGIN.UTENTE"
				},

				/**
				 * Compilare il campo utente (email)
				 */
				UTENTE_OBBLIGATORIO: {
					id: 1838,
					label: "b2b.AUTH.LOGIN.UTENTE-OBBLIGATORIO"
				},

				/**
				 * Se non riesci a ricordare la password usa il link seguente per reimpostarla.
				 */
				PASSWORD_RECOVER_MSG: {
					id: 6023,
					label: "b2b.AUTH.LOGIN.PASSWORD-RECOVER-MSG"
				},

				/**
				 * Benvenuti in Naar
				 */
				WELCOME_MSG: {
					id: 6024,
					label: "b2b.AUTH.LOGIN.WELCOME-MSG"
				},

				/**
				 * Inserisci la tua email
				 */
				USER_TIP: {
					id: 6025,
					label: "b2b.AUTH.LOGIN.USER-TIP.MSG"
				},

				/**
				 * Inserisci la password
				 */
				PASSWORD_TIP: {
					id: 6026,
					label: "b2b.AUTH.LOGIN.PASSWORD-TIP.MSG"
				},

				/**
				 * Inserisci la mail che usi per loggarti. Sarà l'indirizzo al quale verrà spedito un link che ti permetterà di ridefinire la password.
				 */
				RECUPERO_PASSWORD_MSG: {
					id: 6031,
					label: "b2b.AUTH.LOGIN.RECUPERO-PASSWORD-MSG"
				},

				/**
				 * Formato della mail non valido
				 */
				FORMATO_ERRATO: {
					id: 6032,
					label: "b2b.AUTH.LOGIN.FORMATO-ERRATO"
				},

				/**
				 * Oppure
				 */
				OPPURE: {
					id: 6597,
					label: "b2b.AUTH.LOGIN.OPPURE"
				},

				/**
				 * Pulisci
				 */
				CLEAR: {
					id: 6598,
					label: "b2b.AUTH.LOGIN.CLEAR"
				},

				/**
				 * Accedi senza password
				 */
				ACCEDI_VIA_EMAIL: {
					id: 6599,
					label: "b2b.AUTH.LOGIN.ACCEDI-VIA-EMAIL"
				},

				/**
				 * Accedi con la password
				 */
				ACCEDI_CON_PASSWORD: {
					id: 6600,
					label: "b2b.AUTH.LOGIN.ACCEDI-CON-PASSWORD"
				},

				/**
				 * Verifica la tua mail per poter effettuare il login
				 */
				LOGIN_BY_EMAIL_SENT: {
					id: 6601,
					label: "b2b.AUTH.LOGIN.LOGIN-BY-EMAIL-SENT"
				},

				/**
				 * Qualcosa è andato storto. Riprova più tardi
				 */
				LOGIN_BY_EMAIL_ERROR: {
					id: 6602,
					label: "b2b.AUTH.LOGIN.LOGIN-BY-EMAIL-ERROR"
				},

				/**
				 * Richiedi
				 */
				RICHIEDI: {
					id: 6606,
					label: "b2b.AUTH.LOGIN.RICHIEDI"
				},

			},

			/**
			 * Content of path b2b.AUTH.LOGOUT
			 */
			LOGOUT: {
				/**
				 * Disconnessione in corso
				 */
				DISCONNESSIONE_IN_CORSO: {
					id: 1839,
					label: "b2b.AUTH.LOGOUT.DISCONNESSIONE-IN-CORSO"
				},

				/**
				 * Logout
				 */
				LOGOUT: {
					id: 1840,
					label: "b2b.AUTH.LOGOUT.LOGOUT"
				},

			},

		},

		/**
		 * Content of path b2b.CALENDARIO
		 */
		CALENDARIO: {
			/**
			 * Content of path b2b.CALENDARIO.GIORNI_ABBREVIATI
			 */
			GIORNI_ABBREVIATI: {
				/**
				 * Do
				 */
				DOMENICA: {
					id: 1841,
					label: "b2b.CALENDARIO.GIORNI_ABBREVIATI.DOMENICA"
				},

				/**
				 * Gi
				 */
				GIOVEDI: {
					id: 1842,
					label: "b2b.CALENDARIO.GIORNI_ABBREVIATI.GIOVEDI"
				},

				/**
				 * Lu
				 */
				LUNEDI: {
					id: 1843,
					label: "b2b.CALENDARIO.GIORNI_ABBREVIATI.LUNEDI"
				},

				/**
				 * Ma
				 */
				MARTEDI: {
					id: 1844,
					label: "b2b.CALENDARIO.GIORNI_ABBREVIATI.MARTEDI"
				},

				/**
				 * Me
				 */
				MERCOLEDI: {
					id: 1845,
					label: "b2b.CALENDARIO.GIORNI_ABBREVIATI.MERCOLEDI"
				},

				/**
				 * Sa
				 */
				SABATO: {
					id: 1846,
					label: "b2b.CALENDARIO.GIORNI_ABBREVIATI.SABATO"
				},

				/**
				 * Ve
				 */
				VENERDI: {
					id: 1847,
					label: "b2b.CALENDARIO.GIORNI_ABBREVIATI.VENERDI"
				},

			},

			/**
			 * Content of path b2b.CALENDARIO.GIORNI_COMPLETI
			 */
			GIORNI_COMPLETI: {
				/**
				 * Domenica
				 */
				DOMENICA: {
					id: 1848,
					label: "b2b.CALENDARIO.GIORNI_COMPLETI.DOMENICA"
				},

				/**
				 * Giovedì
				 */
				GIOVEDI: {
					id: 1849,
					label: "b2b.CALENDARIO.GIORNI_COMPLETI.GIOVEDI"
				},

				/**
				 * Lunedì
				 */
				LUNEDI: {
					id: 1850,
					label: "b2b.CALENDARIO.GIORNI_COMPLETI.LUNEDI"
				},

				/**
				 * Martedì
				 */
				MARTEDI: {
					id: 1851,
					label: "b2b.CALENDARIO.GIORNI_COMPLETI.MARTEDI"
				},

				/**
				 * Mercoledì
				 */
				MERCOLEDI: {
					id: 1852,
					label: "b2b.CALENDARIO.GIORNI_COMPLETI.MERCOLEDI"
				},

				/**
				 * Sabato
				 */
				SABATO: {
					id: 1853,
					label: "b2b.CALENDARIO.GIORNI_COMPLETI.SABATO"
				},

				/**
				 * Venerdì
				 */
				VENERDI: {
					id: 1854,
					label: "b2b.CALENDARIO.GIORNI_COMPLETI.VENERDI"
				},

			},

			/**
			 * Content of path b2b.CALENDARIO.GIORNI_CORTI
			 */
			GIORNI_CORTI: {
				/**
				 * Dom
				 */
				DOMENICA: {
					id: 1855,
					label: "b2b.CALENDARIO.GIORNI_CORTI.DOMENICA"
				},

				/**
				 * Gio
				 */
				GIOVEDI: {
					id: 1856,
					label: "b2b.CALENDARIO.GIORNI_CORTI.GIOVEDI"
				},

				/**
				 * Lun
				 */
				LUNEDI: {
					id: 1857,
					label: "b2b.CALENDARIO.GIORNI_CORTI.LUNEDI"
				},

				/**
				 * Mar
				 */
				MARTEDI: {
					id: 1858,
					label: "b2b.CALENDARIO.GIORNI_CORTI.MARTEDI"
				},

				/**
				 * Mer
				 */
				MERCOLEDI: {
					id: 1859,
					label: "b2b.CALENDARIO.GIORNI_CORTI.MERCOLEDI"
				},

				/**
				 * Sab
				 */
				SABATO: {
					id: 1860,
					label: "b2b.CALENDARIO.GIORNI_CORTI.SABATO"
				},

				/**
				 * Ven
				 */
				VENERDI: {
					id: 1861,
					label: "b2b.CALENDARIO.GIORNI_CORTI.VENERDI"
				},

			},

			/**
			 * Content of path b2b.CALENDARIO.GIORNI_INIZIALE
			 */
			GIORNI_INIZIALE: {
				/**
				 * D
				 */
				DOMENICA: {
					id: 1862,
					label: "b2b.CALENDARIO.GIORNI_INIZIALE.DOMENICA"
				},

				/**
				 * G
				 */
				GIOVEDI: {
					id: 1863,
					label: "b2b.CALENDARIO.GIORNI_INIZIALE.GIOVEDI"
				},

				/**
				 * L
				 */
				LUNEDI: {
					id: 1864,
					label: "b2b.CALENDARIO.GIORNI_INIZIALE.LUNEDI"
				},

				/**
				 * M
				 */
				MARTEDI: {
					id: 1865,
					label: "b2b.CALENDARIO.GIORNI_INIZIALE.MARTEDI"
				},

				/**
				 * m
				 */
				MERCOLEDI: {
					id: 1866,
					label: "b2b.CALENDARIO.GIORNI_INIZIALE.MERCOLEDI"
				},

				/**
				 * S
				 */
				SABATO: {
					id: 1867,
					label: "b2b.CALENDARIO.GIORNI_INIZIALE.SABATO"
				},

				/**
				 * V
				 */
				VENERDI: {
					id: 1868,
					label: "b2b.CALENDARIO.GIORNI_INIZIALE.VENERDI"
				},

			},

			/**
			 * Content of path b2b.CALENDARIO.MESI_ABBREVIATI
			 */
			MESI_ABBREVIATI: {
				/**
				 * Ago
				 */
				AGOSTO: {
					id: 1869,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI.AGOSTO"
				},

				/**
				 * Apr
				 */
				APRILE: {
					id: 1870,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI.APRILE"
				},

				/**
				 * Dic
				 */
				DICEMBRE: {
					id: 1871,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI.DICEMBRE"
				},

				/**
				 * Feb
				 */
				FEBBRAIO: {
					id: 1872,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI.FEBBRAIO"
				},

				/**
				 * Gen
				 */
				GENNAIO: {
					id: 1873,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI.GENNAIO"
				},

				/**
				 * Giu
				 */
				GIUGNO: {
					id: 1874,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI.GIUGNO"
				},

				/**
				 * Lug
				 */
				LUGLIO: {
					id: 1875,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI.LUGLIO"
				},

				/**
				 * Mag
				 */
				MAGGIO: {
					id: 1876,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI.MAGGIO"
				},

				/**
				 * Mar
				 */
				MARZO: {
					id: 1877,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI.MARZO"
				},

				/**
				 * Nov
				 */
				NOVEMBRE: {
					id: 1878,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI.NOVEMBRE"
				},

				/**
				 * Ott
				 */
				OTTOBRE: {
					id: 1879,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI.OTTOBRE"
				},

				/**
				 * Set
				 */
				SETTEMBRE: {
					id: 1880,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI.SETTEMBRE"
				},

			},

			/**
			 * Content of path b2b.CALENDARIO.MESI_ABBREVIATI_ID
			 */
			MESI_ABBREVIATI_ID: {
				/**
				 * Gen
				 */
				_1: {
					id: 1881,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI_ID.1"
				},

				/**
				 * Feb
				 */
				_2: {
					id: 1882,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI_ID.2"
				},

				/**
				 * Mar
				 */
				_3: {
					id: 1883,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI_ID.3"
				},

				/**
				 * Apr
				 */
				_4: {
					id: 1884,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI_ID.4"
				},

				/**
				 * Mag
				 */
				_5: {
					id: 1885,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI_ID.5"
				},

				/**
				 * Giu
				 */
				_6: {
					id: 1886,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI_ID.6"
				},

				/**
				 * Lug
				 */
				_7: {
					id: 1887,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI_ID.7"
				},

				/**
				 * Ago
				 */
				_8: {
					id: 1888,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI_ID.8"
				},

				/**
				 * Set
				 */
				_9: {
					id: 1889,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI_ID.9"
				},

				/**
				 * Ott
				 */
				_10: {
					id: 1890,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI_ID.10"
				},

				/**
				 * Nov
				 */
				_11: {
					id: 1891,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI_ID.11"
				},

				/**
				 * Dic
				 */
				_12: {
					id: 1892,
					label: "b2b.CALENDARIO.MESI_ABBREVIATI_ID.12"
				},

			},

			/**
			 * Content of path b2b.CALENDARIO.MESI_COMPLETI
			 */
			MESI_COMPLETI: {
				/**
				 * Agosto
				 */
				AGOSTO: {
					id: 1893,
					label: "b2b.CALENDARIO.MESI_COMPLETI.AGOSTO"
				},

				/**
				 * Aprile
				 */
				APRILE: {
					id: 1894,
					label: "b2b.CALENDARIO.MESI_COMPLETI.APRILE"
				},

				/**
				 * Dicembre
				 */
				DICEMBRE: {
					id: 1895,
					label: "b2b.CALENDARIO.MESI_COMPLETI.DICEMBRE"
				},

				/**
				 * Febbraio
				 */
				FEBBRAIO: {
					id: 1896,
					label: "b2b.CALENDARIO.MESI_COMPLETI.FEBBRAIO"
				},

				/**
				 * Gennaio
				 */
				GENNAIO: {
					id: 1897,
					label: "b2b.CALENDARIO.MESI_COMPLETI.GENNAIO"
				},

				/**
				 * Giugno
				 */
				GIUGNO: {
					id: 1898,
					label: "b2b.CALENDARIO.MESI_COMPLETI.GIUGNO"
				},

				/**
				 * Luglio
				 */
				LUGLIO: {
					id: 1899,
					label: "b2b.CALENDARIO.MESI_COMPLETI.LUGLIO"
				},

				/**
				 * Maggio
				 */
				MAGGIO: {
					id: 1900,
					label: "b2b.CALENDARIO.MESI_COMPLETI.MAGGIO"
				},

				/**
				 * Marzo
				 */
				MARZO: {
					id: 1901,
					label: "b2b.CALENDARIO.MESI_COMPLETI.MARZO"
				},

				/**
				 * Novembre
				 */
				NOVEMBRE: {
					id: 1902,
					label: "b2b.CALENDARIO.MESI_COMPLETI.NOVEMBRE"
				},

				/**
				 * Ottobre
				 */
				OTTOBRE: {
					id: 1903,
					label: "b2b.CALENDARIO.MESI_COMPLETI.OTTOBRE"
				},

				/**
				 * Settembre
				 */
				SETTEMBRE: {
					id: 1904,
					label: "b2b.CALENDARIO.MESI_COMPLETI.SETTEMBRE"
				},

			},

			/**
			 * Content of path b2b.CALENDARIO.MESI_COMPLETI_ID
			 */
			MESI_COMPLETI_ID: {
				/**
				 * Gennaio
				 */
				_01: {
					id: 1905,
					label: "b2b.CALENDARIO.MESI_COMPLETI_ID.01"
				},

				/**
				 * Febbraio
				 */
				_02: {
					id: 1906,
					label: "b2b.CALENDARIO.MESI_COMPLETI_ID.02"
				},

				/**
				 * Marzo
				 */
				_03: {
					id: 1907,
					label: "b2b.CALENDARIO.MESI_COMPLETI_ID.03"
				},

				/**
				 * Aprile
				 */
				_04: {
					id: 1908,
					label: "b2b.CALENDARIO.MESI_COMPLETI_ID.04"
				},

				/**
				 * Maggio
				 */
				_05: {
					id: 1909,
					label: "b2b.CALENDARIO.MESI_COMPLETI_ID.05"
				},

				/**
				 * Giugno
				 */
				_06: {
					id: 1910,
					label: "b2b.CALENDARIO.MESI_COMPLETI_ID.06"
				},

				/**
				 * Luglio
				 */
				_07: {
					id: 1911,
					label: "b2b.CALENDARIO.MESI_COMPLETI_ID.07"
				},

				/**
				 * Agosto
				 */
				_08: {
					id: 1912,
					label: "b2b.CALENDARIO.MESI_COMPLETI_ID.08"
				},

				/**
				 * Settembre
				 */
				_09: {
					id: 1913,
					label: "b2b.CALENDARIO.MESI_COMPLETI_ID.09"
				},

				/**
				 * Ottobre
				 */
				_10: {
					id: 1914,
					label: "b2b.CALENDARIO.MESI_COMPLETI_ID.10"
				},

				/**
				 * Novembre
				 */
				_11: {
					id: 1915,
					label: "b2b.CALENDARIO.MESI_COMPLETI_ID.11"
				},

				/**
				 * Dicembre
				 */
				_12: {
					id: 1916,
					label: "b2b.CALENDARIO.MESI_COMPLETI_ID.12"
				},

			},

		},

		/**
		 * Content of path b2b.CART
		 */
		CART: {
			/**
			 * Content of path b2b.CART.ACCESSORIES
			 */
			ACCESSORIES: {
				/**
				 * Assicurazione
				 */
				AGGIUNGI_ASSICURAZIONE: {
					id: 1917,
					label: "b2b.CART.ACCESSORIES.AGGIUNGI-ASSICURAZIONE"
				},

				/**
				 * Servizio automatico
				 */
				AGGIUNGI_SERVIZIO_AUTOMATICO: {
					id: 1918,
					label: "b2b.CART.ACCESSORIES.AGGIUNGI_SERVIZIO_AUTOMATICO"
				},

				/**
				 * Servizio manuale
				 */
				AGGIUNGI_SERVIZIO_MANUALE: {
					id: 1919,
					label: "b2b.CART.ACCESSORIES.AGGIUNGI_SERVIZIO_MANUALE"
				},

				/**
				 * Commissioni agenzia su biglietteria
				 */
				COMMISSIONI_BIGLIETTERIA: {
					id: 1920,
					label: "b2b.CART.ACCESSORIES.COMMISSIONI-BIGLIETTERIA"
				},

				/**
				 * Importo massimo: il 15% delle tariffe aeree al netto delle tasse
				 */
				COMMISSIONI_BIGLIETTERIA_HELP: {
					id: 1921,
					label: "b2b.CART.ACCESSORIES.COMMISSIONI-BIGLIETTERIA-HELP"
				},

				/**
				 * Percentuale diritti agenzia sul totale lordo
				 */
				DIRITTI_AGENZIA_HELP: {
					id: 1922,
					label: "b2b.CART.ACCESSORIES.DIRITTI-AGENZIA-HELP"
				},

				/**
				 * Diritti agenzia
				 */
				DIRITTI_AGENZIA_TITLE: {
					id: 1923,
					label: "b2b.CART.ACCESSORIES.DIRITTI-AGENZIA-TITLE"
				},

				/**
				 * Gestisci i servizi accessori; Aggiungi, modifica ed elimina.
				 */
				INFO: {
					id: 1924,
					label: "b2b.CART.ACCESSORIES.INFO"
				},

				/**
				 * Quote di apertura pratica aggiornate
				 */
				QUOTE_DI_APERTURA_PRATICA_AGGIORNATE: {
					id: 1925,
					label: "b2b.CART.ACCESSORIES.QUOTE-DI-APERTURA-PRATICA-AGGIORNATE"
				},

				/**
				 * Servizio aggiunto
				 */
				SERVIZIO_AGGIUNTO: {
					id: 1926,
					label: "b2b.CART.ACCESSORIES.SERVIZIO-AGGIUNTO"
				},

				/**
				 * Servizi accessori
				 */
				TITLE: {
					id: 1927,
					label: "b2b.CART.ACCESSORIES.TITLE"
				},

			},

			/**
			 * Content of path b2b.CART.AGENCY
			 */
			AGENCY: {
				/**
				 * Acconto
				 */
				ACCONTO: {
					id: 1928,
					label: "b2b.CART.AGENCY.ACCONTO"
				},

				/**
				 * Acconto viaggi di nozze
				 */
				ACCONTO_NOZZE: {
					id: 1929,
					label: "b2b.CART.AGENCY.ACCONTO-NOZZE"
				},

				/**
				 * Addetto
				 */
				ADDETTO: {
					id: 1930,
					label: "b2b.CART.AGENCY.ADDETTO"
				},

				/**
				 * Agenzia
				 */
				AGENZIA: {
					id: 1931,
					label: "b2b.CART.AGENCY.AGENZIA"
				},

				/**
				 * Apri anagrafica contatto agenzia ID={id}
				 */
				APRI_AGENZIA_ID: {
					id: 1932,
					label: "b2b.CART.AGENCY.APRI-AGENZIA-ID"
				},

				/**
				 * Biglietteria
				 */
				BIGLIETTERIA: {
					id: 1933,
					label: "b2b.CART.AGENCY.BIGLIETTERIA"
				},

				/**
				 * Cancellata ADV
				 */
				CANCELLATA_ADV: {
					id: 1934,
					label: "b2b.CART.AGENCY.CANCELLATA-ADV"
				},

				/**
				 * Cancellata il
				 */
				CANCELLATA_IL: {
					id: 1935,
					label: "b2b.CART.AGENCY.CANCELLATA-IL"
				},

				/**
				 * Cancellata TO
				 */
				CANCELLATA_TO: {
					id: 1936,
					label: "b2b.CART.AGENCY.CANCELLATA-TO"
				},

				/**
				 * Categoria
				 */
				CATEGORIA: {
					id: 1937,
					label: "b2b.CART.AGENCY.CATEGORIA"
				},

				/**
				 * Commissione
				 */
				COMMISSIONE: {
					id: 1938,
					label: "b2b.CART.AGENCY.COMMISSIONE"
				},

				/**
				 * Non standard. Commissione accordo: {perc}%
				 */
				COMMISSIONE_NON_STANDARD: {
					id: 1939,
					label: "b2b.CART.AGENCY.COMMISSIONE-NON-STANDARD"
				},

				/**
				 * La commissione è obbligatoria
				 */
				COMMISSIONE_OBBLIGATORIA: {
					id: 1940,
					label: "b2b.CART.AGENCY.COMMISSIONE-OBBLIGATORIA"
				},

				/**
				 * Preventivo completato! Invia ad agenzia
				 */
				COMPLETATO_INVIA: {
					id: 1941,
					label: "b2b.CART.AGENCY.COMPLETATO-INVIA"
				},

				/**
				 * Conferma
				 */
				CONFERMA: {
					id: 1942,
					label: "b2b.CART.AGENCY.CONFERMA"
				},

				/**
				 * Contatti
				 */
				CONTATTI: {
					id: 1943,
					label: "b2b.CART.AGENCY.CONTATTI"
				},

				/**
				 * Data appuntamento agenzia
				 */
				DATA_APPUNTAMENTO_AGENZIA: {
					id: 1944,
					label: "b2b.CART.AGENCY.DATA-APPUNTAMENTO-AGENZIA"
				},

				/**
				 * Data conferma
				 */
				DATA_CONFERMA: {
					id: 1945,
					label: "b2b.CART.AGENCY.DATA-CONFERMA"
				},

				/**
				 * Data conferma TO
				 */
				DATA_CONFERMA_TO: {
					id: 1946,
					label: "b2b.CART.AGENCY.DATA-CONFERMA-TO"
				},

				/**
				 * Data evasione prevista
				 */
				DATA_EVASIONE_PREVISTA: {
					id: 1947,
					label: "b2b.CART.AGENCY.DATA-EVASIONE-PREVISTA"
				},

				/**
				 * Documenti
				 */
				DOCUMENTI: {
					id: 1948,
					label: "b2b.CART.AGENCY.DOCUMENTI"
				},

				/**
				 * Email
				 */
				EMAIL: {
					id: 1949,
					label: "b2b.CART.AGENCY.EMAIL"
				},

				/**
				 * Fax
				 */
				FAX: {
					id: 1950,
					label: "b2b.CART.AGENCY.FAX"
				},

				/**
				 * ID testata
				 */
				ID_TESTATA: {
					id: 1951,
					label: "b2b.CART.AGENCY.ID-TESTATA"
				},

				/**
				 * Imposta status preventivo a COMPLETATO
				 */
				IMPOSTA_COMPLETATO: {
					id: 1952,
					label: "b2b.CART.AGENCY.IMPOSTA-COMPLETATO"
				},

				/**
				 * Imposta status preventivo a DA MODIFICARE
				 */
				IMPOSTA_DA_MODIFICARE: {
					id: 1953,
					label: "b2b.CART.AGENCY.IMPOSTA-DA-MODIFICARE"
				},

				/**
				 * Cambia stato pratica
				 */
				IMPOSTA_STATO: {
					id: 1954,
					label: "b2b.CART.AGENCY.IMPOSTA-STATO"
				},

				/**
				 * Kanban
				 */
				KANBAN: {
					id: 1955,
					label: "b2b.CART.AGENCY.KANBAN"
				},

				/**
				 * Lingua
				 */
				LINGUA: {
					id: 1956,
					label: "b2b.CART.AGENCY.LINGUA"
				},

				/**
				 * Modifica dati agenzia
				 */
				MODIFICA_DATI_AGENZIA: {
					id: 1957,
					label: "b2b.CART.AGENCY.MODIFICA-DATI-AGENZIA"
				},

				/**
				 * Network
				 */
				NETWORK: {
					id: 1958,
					label: "b2b.CART.AGENCY.NETWORK"
				},

				/**
				 * Operatore
				 */
				OPERATORE: {
					id: 1959,
					label: "b2b.CART.AGENCY.OPERATORE"
				},

				/**
				 * Pagamento
				 */
				PAGAMENTO: {
					id: 1960,
					label: "b2b.CART.AGENCY.PAGAMENTO"
				},

				/**
				 * Preventivo
				 */
				PREVENTIVO: {
					id: 1961,
					label: "b2b.CART.AGENCY.PREVENTIVO"
				},

				/**
				 * Promoter
				 */
				PROMOTER: {
					id: 1962,
					label: "b2b.CART.AGENCY.PROMOTER"
				},

				/**
				 * Aggiornare i cambi!
				 */
				RICHIESTO_AGGIORNAMENTO_CAMBI: {
					id: 1963,
					label: "b2b.CART.AGENCY.RICHIESTO-AGGIORNAMENTO-CAMBI"
				},

				/**
				 * Riferimento cliente
				 */
				RIFERIMENTO_CLIENTE: {
					id: 1964,
					label: "b2b.CART.AGENCY.RIFERIMENTO-CLIENTE"
				},

				/**
				 * Tariffa aerea
				 */
				TARIFFA_AEREA: {
					id: 1965,
					label: "b2b.CART.AGENCY.TARIFFA-AEREA"
				},

				/**
				 * Tel
				 */
				TEL: {
					id: 1966,
					label: "b2b.CART.AGENCY.TEL"
				},

				/**
				 * User name
				 */
				USER_NAME: {
					id: 1967,
					label: "b2b.CART.AGENCY.USER-NAME"
				},

			},

			/**
			 * Content of path b2b.CART.AGENCY-DOCUMENTS
			 */
			AGENCY_DOCUMENTS: {
				/**
				 * Allegati
				 */
				ALLEGATI: {
					id: 1968,
					label: "b2b.CART.AGENCY-DOCUMENTS.ALLEGATI"
				},

				/**
				 * CC
				 */
				CCS: {
					id: 1969,
					label: "b2b.CART.AGENCY-DOCUMENTS.CCS"
				},

				/**
				 * Elenco indirizzi separati da ;
				 */
				CCS_HELP: {
					id: 1970,
					label: "b2b.CART.AGENCY-DOCUMENTS.CCS-HELP"
				},

				/**
				 * Il documento di Conferma Cliente
				 */
				CONFERMA_CLIENTE: {
					id: 1971,
					label: "b2b.CART.AGENCY-DOCUMENTS.CONFERMA-CLIENTE"
				},

				/**
				 * Il documento di Conferma Provvisoria Agenzia
				 */
				CONFERMA_PROVVISORIA_AGENZIA: {
					id: 1972,
					label: "b2b.CART.AGENCY-DOCUMENTS.CONFERMA-PROVVISORIA-AGENZIA"
				},

				/**
				 * Stampa preventivo agenzia
				 */
				CONTR_PREV_ADV: {
					id: 1973,
					label: "b2b.CART.AGENCY-DOCUMENTS.CONTR-PREV-ADV"
				},

				/**
				 * Stampa preventivo cliente
				 */
				CONTR_PREV_CLI: {
					id: 1974,
					label: "b2b.CART.AGENCY-DOCUMENTS.CONTR-PREV-CLI"
				},

				/**
				 * Stampa preventivo cliente sintetico
				 */
				CONTR_PREV_CLI_SIN: {
					id: 1975,
					label: "b2b.CART.AGENCY-DOCUMENTS.CONTR-PREV-CLI-SIN"
				},

				/**
				 * Documenti online
				 */
				DOCUMENTI_ONLINE: {
					id: 1976,
					label: "b2b.CART.AGENCY-DOCUMENTS.DOCUMENTI-ONLINE"
				},

				/**
				 * Il documento di Estratto Conto e Conferma Servizi
				 */
				ESTRATTO_CONTO: {
					id: 1977,
					label: "b2b.CART.AGENCY-DOCUMENTS.ESTRATTO-CONTO"
				},

				/**
				 * Invia per email
				 */
				INVIA_PER_MAIL: {
					id: 1978,
					label: "b2b.CART.AGENCY-DOCUMENTS.INVIA-PER-MAIL"
				},

				/**
				 * Preventivo Agenzia: dettaglio dei servizi, prezzi, commissioni e calendario pagamenti
				 */
				OFFERTA: {
					id: 1979,
					label: "b2b.CART.AGENCY-DOCUMENTS.OFFERTA"
				},

				/**
				 * Prezzi e disponibilità non aggiornati. Prima di procedere alla stampa del documento, si vuole effettuare una verifica?
				 */
				PER_STAMPARE_AGGIORNARE_PREZZI: {
					id: 1980,
					label: "b2b.CART.AGENCY-DOCUMENTS.PER-STAMPARE-AGGIORNARE-PREZZI"
				},

				/**
				 * Preventivo Cliente “sintesi”: dettaglio dei servizi con prezzo totale del viaggio
				 */
				PREVENTIVO: {
					id: 1981,
					label: "b2b.CART.AGENCY-DOCUMENTS.PREVENTIVO"
				},

				/**
				 * Preventivo Cliente: con programma giornaliero e dettaglio dei servizi arricchito di foto gallery e video
				 */
				PREVENTIVO_CLIENTE_EMO: {
					id: 1982,
					label: "b2b.CART.AGENCY-DOCUMENTS.PREVENTIVO-CLIENTE-EMO"
				},

				/**
				 * Il programma di viaggio
				 */
				PROGRAMMA_DI_VIAGGIO: {
					id: 1983,
					label: "b2b.CART.AGENCY-DOCUMENTS.PROGRAMMA-DI-VIAGGIO"
				},

				/**
				 * Scarica in formato RTF
				 */
				SCARICA_RFT: {
					id: 1984,
					label: "b2b.CART.AGENCY-DOCUMENTS.SCARICA-RFT"
				},

				/**
				 * Stampa di tutti i voucher disponibili
				 */
				STAMPA_VOUCHER: {
					id: 1985,
					label: "b2b.CART.AGENCY-DOCUMENTS.STAMPA-VOUCHER"
				},

				/**
				 * Testo di accompagnamento (opzionale)
				 */
				TESTO_OPZIONALE: {
					id: 1986,
					label: "b2b.CART.AGENCY-DOCUMENTS.TESTO-OPZIONALE"
				},

				/**
				 * Documenti agenzia
				 */
				TITLE: {
					id: 1987,
					label: "b2b.CART.AGENCY-DOCUMENTS.TITLE"
				},

				/**
				 * Visualizza
				 */
				VISUALIZZA: {
					id: 1988,
					label: "b2b.CART.AGENCY-DOCUMENTS.VISUALIZZA"
				},

				/**
				 * Voucher
				 */
				VOUCHER: {
					id: 1989,
					label: "b2b.CART.AGENCY-DOCUMENTS.VOUCHER"
				},

			},

			/**
			 * Content of path b2b.CART.AGENCY-EDIT
			 */
			AGENCY_EDIT: {
				/**
				 * Addetto
				 */
				ADDETTO: {
					id: 1990,
					label: "b2b.CART.AGENCY-EDIT.ADDETTO"
				},

				/**
				 * Agenzia
				 */
				AGENZIA: {
					id: 1991,
					label: "b2b.CART.AGENCY-EDIT.AGENZIA"
				},

				/**
				 * L'agenzia è obbligatoria
				 */
				AGENZIA_OBBLIGATORIA: {
					id: 1992,
					label: "b2b.CART.AGENCY-EDIT.AGENZIA-OBBLIGATORIA"
				},

				/**
				 * Aggiorna commissioni
				 */
				AGGIORNA_COMMISSIONI: {
					id: 1993,
					label: "b2b.CART.AGENCY-EDIT.AGGIORNA-COMMISSIONI"
				},

				/**
				 * Aggiorna cambi
				 */
				AGGIORNA_VALUTE: {
					id: 1994,
					label: "b2b.CART.AGENCY-EDIT.AGGIORNA-VALUTE"
				},

				/**
				 * Annullamento ADV
				 */
				ANNULLAMENTO_ADV: {
					id: 1995,
					label: "b2b.CART.AGENCY-EDIT.ANNULLAMENTO-ADV"
				},

				/**
				 * Annullamento TO
				 */
				ANNULLAMENTO_TO: {
					id: 1996,
					label: "b2b.CART.AGENCY-EDIT.ANNULLAMENTO-TO"
				},

				/**
				 * Offerta annullata ADV
				 */
				ANNULLATA_ADV: {
					id: 1997,
					label: "b2b.CART.AGENCY-EDIT.ANNULLATA-ADV"
				},

				/**
				 * Offerta annullata TO
				 */
				ANNULLATA_TO: {
					id: 1998,
					label: "b2b.CART.AGENCY-EDIT.ANNULLATA-TO"
				},

				/**
				 * Biglietti aerei
				 */
				BIGLIETTI_AEREI: {
					id: 1999,
					label: "b2b.CART.AGENCY-EDIT.BIGLIETTI-AEREI"
				},

				/**
				 * Cambi
				 */
				CAMBI: {
					id: 2000,
					label: "b2b.CART.AGENCY-EDIT.CAMBI"
				},

				/**
				 * Canc. preventivo il
				 */
				CANC_PREVENTIVO_IL: {
					id: 2001,
					label: "b2b.CART.AGENCY-EDIT.CANC-PREVENTIVO-IL"
				},

				/**
				 * Cancellata il
				 */
				CANCELLATA_IL: {
					id: 2002,
					label: "b2b.CART.AGENCY-EDIT.CANCELLATA-IL"
				},

				/**
				 * Chiudi
				 */
				CHIUDI: {
					id: 2003,
					label: "b2b.CART.AGENCY-EDIT.CHIUDI"
				},

				/**
				 * Chiudi dopo il salvataggio
				 */
				CHIUDI_DOPO_SALVATAGGIO: {
					id: 2004,
					label: "b2b.CART.AGENCY-EDIT.CHIUDI-DOPO-SALVATAGGIO"
				},

				/**
				 * Cliente diretto
				 */
				CLIENTE_DIRETTO: {
					id: 2005,
					label: "b2b.CART.AGENCY-EDIT.CLIENTE-DIRETTO"
				},

				/**
				 * Commissione non standard
				 */
				COMMISSIONE_NON_STANDARD: {
					id: 2006,
					label: "b2b.CART.AGENCY-EDIT.COMMISSIONE-NON-STANDARD"
				},

				/**
				 * Commissioni
				 */
				COMMISSIONI: {
					id: 2007,
					label: "b2b.CART.AGENCY-EDIT.COMMISSIONI"
				},

				/**
				 * Conferma
				 */
				CONFERMA: {
					id: 2008,
					label: "b2b.CART.AGENCY-EDIT.CONFERMA"
				},

				/**
				 * Confermi l'annullamento da parte dell'agenzia?
				 */
				CONFERMA_ANNULLAMENTO_ADV: {
					id: 2009,
					label: "b2b.CART.AGENCY-EDIT.CONFERMA-ANNULLAMENTO-ADV"
				},

				/**
				 * Confermi l'annullamento da parte del tour operator?
				 */
				CONFERMA_ANNULLAMENTO_TO: {
					id: 2010,
					label: "b2b.CART.AGENCY-EDIT.CONFERMA-ANNULLAMENTO-TO"
				},

				/**
				 * Confermi di voler aggiornare i cambi delle valute?
				 */
				CONFERMI_AGGIORNAMENTO_VALUTE: {
					id: 2011,
					label: "b2b.CART.AGENCY-EDIT.CONFERMI-AGGIORNAMENTO-VALUTE"
				},

				/**
				 * Confermi il ripristino di questa offerta?
				 */
				CONFERMI_RIPRISTINO: {
					id: 2012,
					label: "b2b.CART.AGENCY-EDIT.CONFERMI-RIPRISTINO"
				},

				/**
				 * Data appuntamento agenzia
				 */
				DATA_APPUNTAMENTO_AGENZIA: {
					id: 2013,
					label: "b2b.CART.AGENCY-EDIT.DATA-APPUNTAMENTO-AGENZIA"
				},

				/**
				 * Data conferma
				 */
				DATA_CONFERMA: {
					id: 2014,
					label: "b2b.CART.AGENCY-EDIT.DATA-CONFERMA"
				},

				/**
				 * Data conferma TO
				 */
				DATA_CONFERMA_TO: {
					id: 2015,
					label: "b2b.CART.AGENCY-EDIT.DATA-CONFERMA-TO"
				},

				/**
				 * Data evasione prevista
				 */
				DATA_EVASIONE_PREVISTA: {
					id: 2016,
					label: "b2b.CART.AGENCY-EDIT.DATA-EVASIONE-PREVISTA"
				},

				/**
				 * Documenti
				 */
				DOCUMENTI: {
					id: 2017,
					label: "b2b.CART.AGENCY-EDIT.DOCUMENTI"
				},

				/**
				 * Email
				 */
				EMAIL: {
					id: 2018,
					label: "b2b.CART.AGENCY-EDIT.EMAIL"
				},

				/**
				 * Email non valida
				 */
				EMAIL_NON_VALIDA: {
					id: 2019,
					label: "b2b.CART.AGENCY-EDIT.EMAIL-NON-VALIDA"
				},

				/**
				 * Fattura al netto
				 */
				FATTURA_AL_NETTO: {
					id: 2020,
					label: "b2b.CART.AGENCY-EDIT.FATTURA-AL-NETTO"
				},

				/**
				 * Imposta commissione accordo ({perc}%) e salva
				 */
				IMPOSTA_COMMISSIONE_ACCORDO: {
					id: 2021,
					label: "b2b.CART.AGENCY-EDIT.IMPOSTA-COMMISSIONE-ACCORDO"
				},

				/**
				 * Lingua
				 */
				LINGUA: {
					id: 2022,
					label: "b2b.CART.AGENCY-EDIT.LINGUA"
				},

				/**
				 * Modifiche salvate
				 */
				MODIFICHE_SALVATE: {
					id: 2023,
					label: "b2b.CART.AGENCY-EDIT.MODIFICHE-SALVATE"
				},

				/**
				 * il tuo preventivo non viene aggiornato da tempo. Effettua un aggiornamento dei cambi per attualizzare i valori corretti della prenotazione.
				 */
				MSG_AGGIORNA_CAMBI: {
					id: 2024,
					label: "b2b.CART.AGENCY-EDIT.MSG-AGGIORNA-CAMBI"
				},

				/**
				 * Network
				 */
				NETWORK: {
					id: 2025,
					label: "b2b.CART.AGENCY-EDIT.NETWORK"
				},

				/**
				 * -- Non indicato --
				 */
				NO_UTENTE: {
					id: 2026,
					label: "b2b.CART.AGENCY-EDIT.NO-UTENTE"
				},

				/**
				 * Offerta ripristinata
				 */
				OFFERTA_RIPRISTINATA: {
					id: 2027,
					label: "b2b.CART.AGENCY-EDIT.OFFERTA-RIPRISTINATA"
				},

				/**
				 * Preventivo
				 */
				PREVENTIVO: {
					id: 2028,
					label: "b2b.CART.AGENCY-EDIT.PREVENTIVO"
				},

				/**
				 * Promoter
				 */
				PROMOTER: {
					id: 2029,
					label: "b2b.CART.AGENCY-EDIT.PROMOTER"
				},

				/**
				 * Rif. Cliente
				 */
				RIF_CLIENTE: {
					id: 2030,
					label: "b2b.CART.AGENCY-EDIT.RIF-CLIENTE"
				},

				/**
				 * Ripristina offerta
				 */
				RIPRISTINA_OFFERTA: {
					id: 2031,
					label: "b2b.CART.AGENCY-EDIT.RIPRISTINA-OFFERTA"
				},

				/**
				 * Salva modifiche
				 */
				SALVA_MODIFICHE: {
					id: 2032,
					label: "b2b.CART.AGENCY-EDIT.SALVA-MODIFICHE"
				},

				/**
				 * Tags
				 */
				TAGS: {
					id: 2033,
					label: "b2b.CART.AGENCY-EDIT.TAGS"
				},

				/**
				 * Tariffa
				 */
				TARIFFA: {
					id: 2034,
					label: "b2b.CART.AGENCY-EDIT.TARIFFA"
				},

				/**
				 * Utente
				 */
				UTENTE: {
					id: 2035,
					label: "b2b.CART.AGENCY-EDIT.UTENTE"
				},

				/**
				 * Valute aggiornate
				 */
				VALUTE_AGGIORNATE: {
					id: 2036,
					label: "b2b.CART.AGENCY-EDIT.VALUTE-AGGIORNATE"
				},

			},

			/**
			 * Note agenzia
			 */
			AGENCY_NOTES: {
				id: 2037,
				label: "b2b.CART.AGENCY-NOTES.TITOLO"
			},

			/**
			 * Amadeus Cryptic
			 */
			AMADEUS_CRYPTIC: {
				id: 2038,
				label: "b2b.CART.AMADEUS-CRYPTIC"
			},

			/**
			 * Content of path b2b.CART.AUTOCOMPLETE-RESULT
			 */
			AUTOCOMPLETE_RESULT: {
				/**
				 * Alternativa inserita
				 */
				ALTERNATIVA_INSERITA: {
					id: 2039,
					label: "b2b.CART.AUTOCOMPLETE-RESULT.ALTERNATIVA-INSERITA"
				},

				/**
				 * Esito completamento automatico
				 */
				ESITO: {
					id: 2040,
					label: "b2b.CART.AUTOCOMPLETE-RESULT.ESITO"
				},

			},

			/**
			 * Content of path b2b.CART.BOOKING-SUPPORT
			 */
			BOOKING_SUPPORT: {
				/**
				 * Richiesta di supporto per viaggio {id}
				 */
				HELP: {
					id: 2041,
					label: "b2b.CART.BOOKING-SUPPORT.HELP"
				},

				/**
				 * Scrivi qui la tua richiesta
				 */
				HELP_MESSAGGIO: {
					id: 2042,
					label: "b2b.CART.BOOKING-SUPPORT.HELP-MESSAGGIO"
				},

				/**
				 * Invia
				 */
				INVIA: {
					id: 2043,
					label: "b2b.CART.BOOKING-SUPPORT.INVIA"
				},

				/**
				 * Messaggio
				 */
				MESSAGGIO: {
					id: 2044,
					label: "b2b.CART.BOOKING-SUPPORT.MESSAGGIO"
				},

				/**
				 * Richiesta inviata
				 */
				RICHIESTA_INVIATA: {
					id: 2045,
					label: "b2b.CART.BOOKING-SUPPORT.RICHIESTA-INVIATA"
				},

			},

			/**
			 * Content of path b2b.CART.CREATE
			 */
			CREATE: {
				/**
				 * a partire da
				 */
				A_PARTIRE_DA: {
					id: 2046,
					label: "b2b.CART.CREATE.A-PARTIRE-DA"
				},

				/**
				 * Nessuna soluzione valida trovata
				 */
				NESSUNA_SOLUZIONE_TROVATA: {
					id: 2047,
					label: "b2b.CART.CREATE.NESSUNA-SOLUZIONE-TROVATA"
				},

				/**
				 * per persona
				 */
				PER_PERSONA: {
					id: 2048,
					label: "b2b.CART.CREATE.PER-PERSONA"
				},

				/**
				 * Procedi
				 */
				PROCEDI: {
					id: 2049,
					label: "b2b.CART.CREATE.PROCEDI"
				},

				/**
				 * Prosegui con la quotazione personalizzata del servizio che hai scelto
				 */
				PROCEDI_HELP: {
					id: 2050,
					label: "b2b.CART.CREATE.PROCEDI-HELP"
				},

				/**
				 * Quotazione in corso
				 */
				QUOTAZIONE_IN_CORSO: {
					id: 2051,
					label: "b2b.CART.CREATE.QUOTAZIONE-IN-CORSO"
				},

				/**
				 * Tipologie disponibili
				 */
				TIPOLOGIE_DISPONIBILI: {
					id: 2052,
					label: "b2b.CART.CREATE.TIPOLOGIE-DISPONIBILI"
				},

				/**
				 * Nessuna tipologia disponibile.
				 */
				TIPOLOGIA_MANCANTE: {
					id: 5995,
					label: "b2b.CART.CREATE.TIPOLOGIA-MANCANTE"
				},

				/**
				 * Modifica dati viaggio
				 */
				MODIFICA_DATI_VIAGGIO: {
					id: 6089,
					label: "b2b.CART.CREATE.MODIFICA-DATI-VIAGGIO"
				},

			},

			/**
			 * Content of path b2b.CART.DOCUMENTS
			 */
			DOCUMENTS: {
				/**
				 * {n} document{n, plural, =0{i} one{o} other{i}} inserit{n, plural, =0{i} one{o} other{i}}.
				 */
				ALLEGATI_INSERITI: {
					id: 2053,
					label: "b2b.CART.DOCUMENTS.ALLEGATI-INSERITI"
				},

				/**
				 * Altri documenti ({n})
				 */
				ALTRI_DOCUMENTI: {
					id: 2054,
					label: "b2b.CART.DOCUMENTS.ALTRI-DOCUMENTI"
				},

				/**
				 * Modifica documento
				 */
				APRI_EDITOR_FRASI: {
					id: 2055,
					label: "b2b.CART.DOCUMENTS.APRI-EDITOR-FRASI"
				},

				/**
				 * Apri editor miniguide
				 */
				APRI_EDITOR_MINIGUIDE: {
					id: 2056,
					label: "b2b.CART.DOCUMENTS.APRI-EDITOR-MINIGUIDE"
				},

				/**
				 * C
				 */
				C_CREAZIONE: {
					id: 2057,
					label: "b2b.CART.DOCUMENTS.C-CREAZIONE"
				},

				/**
				 * Carica
				 */
				CARICA: {
					id: 2058,
					label: "b2b.CART.DOCUMENTS.CARICA"
				},

				/**
				 * Carica corrispondenza / documentistica
				 */
				CARICA_DOCUMENTO: {
					id: 2059,
					label: "b2b.CART.DOCUMENTS.CARICA-DOCUMENTO"
				},

				/**
				 * Caricamento delle miniguide in corso ...
				 */
				CARICAMENTO_MINIGUIDE: {
					id: 2060,
					label: "b2b.CART.DOCUMENTS.CARICAMENTO-MINIGUIDE"
				},

				/**
				 * Chiudi dopo il caricamento
				 */
				CHIUDI_DOPO_IL_CARICAMENTO: {
					id: 2061,
					label: "b2b.CART.DOCUMENTS.CHIUDI-DOPO-IL-CARICAMENTO"
				},

				/**
				 * Sta per essere inviata una mail con il preventivo all'agenzia. Vuoi confermare?
				 */
				CONFERMA_INVIO_PREVENTIVO: {
					id: 2062,
					label: "b2b.CART.DOCUMENTS.CONFERMA-INVIO-PREVENTIVO"
				},

				/**
				 * Conferma invio preventivo
				 */
				CONFERMA_INVIO_PREVENTIVO_TITOLO: {
					id: 2063,
					label: "b2b.CART.DOCUMENTS.CONFERMA-INVIO-PREVENTIVO-TITOLO"
				},

				/**
				 * Converti in non traducibile
				 */
				CONVERTI_IN_NON_TRADUCIBILE: {
					id: 2064,
					label: "b2b.CART.DOCUMENTS.CONVERTI-IN-NON-TRADUCIBILE"
				},

				/**
				 * Convertire il documento traducibile ID={id} nel suo equivalente non traducibile?
				 */
				CONVERTI_TRADUCIBILE: {
					id: 2065,
					label: "b2b.CART.DOCUMENTS.CONVERTI-TRADUCIBILE"
				},

				/**
				 * Conversione documento traducibile
				 */
				CONVERTI_TRADUCIBILE_TITOLO: {
					id: 2066,
					label: "b2b.CART.DOCUMENTS.CONVERTI-TRADUCIBILE-TITOLO"
				},

				/**
				 * Corrispondenza agenzia ({n})
				 */
				CORRISPONDENZA_AGENZIA: {
					id: 2067,
					label: "b2b.CART.DOCUMENTS.CORRISPONDENZA-AGENZIA"
				},

				/**
				 * Corrispondenza fornitori ({n})
				 */
				CORRISPONDENZA_FORNITORI: {
					id: 2068,
					label: "b2b.CART.DOCUMENTS.CORRISPONDENZA-FORNITORI"
				},

				/**
				 * Crea documento
				 */
				CREA_DOCUMENTO: {
					id: 2069,
					label: "b2b.CART.DOCUMENTS.CREA-DOCUMENTO"
				},

				/**
				 * Destinatario
				 */
				DESTINATARIO: {
					id: 2070,
					label: "b2b.CART.DOCUMENTS.DESTINATARIO"
				},

				/**
				 * Inserire un destinatario valido
				 */
				DESTINATARIO_NON_VALIDO: {
					id: 2071,
					label: "b2b.CART.DOCUMENTS.DESTINATARIO-NON-VALIDO"
				},

				/**
				 * Allegati
				 */
				DOCUMENTI_ALLEGATI: {
					id: 2072,
					label: "b2b.CART.DOCUMENTS.DOCUMENTI-ALLEGATI"
				},

				/**
				 * Eliminat{n, plural, =0{i} one{o} other{i}} {n} document{n, plural, =0{i} one{o} other{i}}
				 */
				DOCUMENTI_ELIMINATI: {
					id: 2073,
					label: "b2b.CART.DOCUMENTS.DOCUMENTI-ELIMINATI"
				},

				/**
				 * {n} document{n, plural, =0{i} one{o} other{i}} selezionat{n, plural, =0{i} one{o} other{i}}
				 */
				DOCUMENTI_SELEZIONATI: {
					id: 2074,
					label: "b2b.CART.DOCUMENTS.DOCUMENTI-SELEZIONATI"
				},

				/**
				 * Documenti di viaggio ({n})
				 */
				DOCUMENTI_VIAGGIO: {
					id: 2075,
					label: "b2b.CART.DOCUMENTS.DOCUMENTI-VIAGGIO"
				},

				/**
				 * Documento caricato
				 */
				DOCUMENTO_CARICATO: {
					id: 2076,
					label: "b2b.CART.DOCUMENTS.DOCUMENTO-CARICATO"
				},

				/**
				 * Documento duplicato
				 */
				DOCUMENTO_DUPLICATO: {
					id: 2077,
					label: "b2b.CART.DOCUMENTS.DOCUMENTO-DUPLICATO"
				},

				/**
				 * Documento eliminato
				 */
				DOCUMENTO_ELIMINATO: {
					id: 2078,
					label: "b2b.CART.DOCUMENTS.DOCUMENTO-ELIMINATO"
				},

				/**
				 * Documento salvato
				 */
				DOCUMENTO_SALVATO: {
					id: 2079,
					label: "b2b.CART.DOCUMENTS.DOCUMENTO-SALVATO"
				},

				/**
				 * Confermi di voler duplicare il documento {tipo} ID={id}?
				 */
				DUPLICARE_DOCUMENTO: {
					id: 2080,
					label: "b2b.CART.DOCUMENTS.DUPLICARE-DOCUMENTO"
				},

				/**
				 * Duplicazione documento
				 */
				DUPLICARE_DOCUMENTO_TITOLO: {
					id: 2081,
					label: "b2b.CART.DOCUMENTS.DUPLICARE-DOCUMENTO-TITOLO"
				},

				/**
				 * Modifica documento
				 */
				EDITOR_DOCUMENTO: {
					id: 2082,
					label: "b2b.CART.DOCUMENTS.EDITOR-DOCUMENTO"
				},

				/**
				 * Content of path b2b.CART.DOCUMENTS.ELENCO-MINIGUIDE
				 */
				ELENCO_MINIGUIDE: {
					/**
					 * Elenco delle miniguide
					 */
					MSG: {
						id: 2083,
						label: "b2b.CART.DOCUMENTS.ELENCO-MINIGUIDE.MSG"
					},

					/**
					 * Presenti: {p}, Selezionate: {s}
					 */
					MSG_STATS: {
						id: 2084,
						label: "b2b.CART.DOCUMENTS.ELENCO-MINIGUIDE.MSG-STATS"
					},

					/**
					 * Elenco delle Miniguide
					 */
					TITLE: {
						id: 2085,
						label: "b2b.CART.DOCUMENTS.ELENCO-MINIGUIDE.TITLE"
					},

				},

				/**
				 * Confermi l'eliminazione di {n} document{n, plural, =0{i} one{o} other{i}}?
				 */
				ELIMINARE_DOCUMENTI: {
					id: 2086,
					label: "b2b.CART.DOCUMENTS.ELIMINARE-DOCUMENTI"
				},

				/**
				 * Eliminazione documenti
				 */
				ELIMINARE_DOCUMENTI_TITOLO: {
					id: 2087,
					label: "b2b.CART.DOCUMENTS.ELIMINARE-DOCUMENTI-TITOLO"
				},

				/**
				 * Confermi l'eliminazione del documento {tipo} ID={id}?
				 */
				ELIMINARE_DOCUMENTO: {
					id: 2088,
					label: "b2b.CART.DOCUMENTS.ELIMINARE-DOCUMENTO"
				},

				/**
				 * Eliminazione documento
				 */
				ELIMINARE_DOCUMENTO_TITOLO: {
					id: 2089,
					label: "b2b.CART.DOCUMENTS.ELIMINARE-DOCUMENTO-TITOLO"
				},

				/**
				 * Email inviata
				 */
				EMAIL_INVIATA: {
					id: 2090,
					label: "b2b.CART.DOCUMENTS.EMAIL-INVIATA"
				},

				/**
				 * Esporta come PDF
				 */
				ESPORTA_PDF: {
					id: 2091,
					label: "b2b.CART.DOCUMENTS.ESPORTA-PDF"
				},

				/**
				 * Esporta come PDF formato A5
				 */
				ESPORTA_PDF_A5: {
					id: 2092,
					label: "b2b.CART.DOCUMENTS.ESPORTA-PDF-A5"
				},

				/**
				 * Esporta come RTF
				 */
				ESPORTA_RTF: {
					id: 2093,
					label: "b2b.CART.DOCUMENTS.ESPORTA-RTF"
				},

				/**
				 * Selezionati in testa
				 */
				FORZA_ORDINE: {
					id: 2094,
					label: "b2b.CART.DOCUMENTS.FORZA-ORDINE"
				},

				/**
				 * Inserisci {n} document{n, plural, =0{i} one{o} other{i}} allegat{n, plural, =0{i} one{o} other{i}}
				 */
				INSERISCI_ALLEGATI: {
					id: 2095,
					label: "b2b.CART.DOCUMENTS.INSERISCI-ALLEGATI"
				},

				/**
				 * Invia
				 */
				INVIA: {
					id: 2096,
					label: "b2b.CART.DOCUMENTS.INVIA"
				},

				/**
				 * Invio documenti
				 */
				INVIO_DOCUMENTI: {
					id: 2097,
					label: "b2b.CART.DOCUMENTS.INVIO-DOCUMENTI"
				},

				/**
				 * Sono stati selezionati documenti non inviabili per mail
				 */
				INVIO_NON_AMMESSO: {
					id: 2098,
					label: "b2b.CART.DOCUMENTS.INVIO-NON-AMMESSO"
				},

				/**
				 * Email inviata correttamente. L'agenzia è stata informata
				 */
				INVIO_PREVENTIVO_OK: {
					id: 2099,
					label: "b2b.CART.DOCUMENTS.INVIO-PREVENTIVO-OK"
				},

				/**
				 * Lingua
				 */
				LINGUA: {
					id: 2100,
					label: "b2b.CART.DOCUMENTS.LINGUA"
				},

				/**
				 * Content of path b2b.CART.DOCUMENTS.MOSTRA-ID
				 */
				MOSTRA_ID: {
					/**
					 * Id
					 */
					CAPTION: {
						id: 2101,
						label: "b2b.CART.DOCUMENTS.MOSTRA-ID.CAPTION"
					},

					/**
					 * Content of path b2b.CART.DOCUMENTS.MOSTRA-ID.TOGGLE
					 */
					TOGGLE: {
						/**
						 * Mostra colonna Id
						 */
						FALSE: {
							id: 2102,
							label: "b2b.CART.DOCUMENTS.MOSTRA-ID.TOGGLE.FALSE"
						},

						/**
						 * Nascondi colonna Id
						 */
						TRUE: {
							id: 2103,
							label: "b2b.CART.DOCUMENTS.MOSTRA-ID.TOGGLE.TRUE"
						},

					},

				},

				/**
				 * Content of path b2b.CART.DOCUMENTS.MOSTRA-NODI
				 */
				MOSTRA_NODI: {
					/**
					 * Mostra paesi
					 */
					CAPTION: {
						id: 2104,
						label: "b2b.CART.DOCUMENTS.MOSTRA-NODI.CAPTION"
					},

					/**
					 * Content of path b2b.CART.DOCUMENTS.MOSTRA-NODI.TOGGLE
					 */
					TOGGLE: {
						/**
						 * Espandi tutto
						 */
						FALSE: {
							id: 2105,
							label: "b2b.CART.DOCUMENTS.MOSTRA-NODI.TOGGLE.FALSE"
						},

						/**
						 * Comprimi tutto
						 */
						TRUE: {
							id: 2106,
							label: "b2b.CART.DOCUMENTS.MOSTRA-NODI.TOGGLE.TRUE"
						},

					},

				},

				/**
				 * Content of path b2b.CART.DOCUMENTS.MOSTRA-ORDINE
				 */
				MOSTRA_ORDINE: {
					/**
					 * Ordine
					 */
					CAPTION: {
						id: 2107,
						label: "b2b.CART.DOCUMENTS.MOSTRA-ORDINE.CAPTION"
					},

					/**
					 * Content of path b2b.CART.DOCUMENTS.MOSTRA-ORDINE.TOGGLE
					 */
					TOGGLE: {
						/**
						 * Mostra colonna ordine
						 */
						FALSE: {
							id: 2108,
							label: "b2b.CART.DOCUMENTS.MOSTRA-ORDINE.TOGGLE.FALSE"
						},

						/**
						 * Nascondi colonna ordine
						 */
						TRUE: {
							id: 2109,
							label: "b2b.CART.DOCUMENTS.MOSTRA-ORDINE.TOGGLE.TRUE"
						},

					},

				},

				/**
				 * Content of path b2b.CART.DOCUMENTS.MOSTRA-SOLO-SELEZIONATI
				 */
				MOSTRA_SOLO_SELEZIONATI: {
					/**
					 * Solo selezionati
					 */
					CAPTION: {
						id: 2110,
						label: "b2b.CART.DOCUMENTS.MOSTRA-SOLO-SELEZIONATI.CAPTION"
					},

					/**
					 * Content of path b2b.CART.DOCUMENTS.MOSTRA-SOLO-SELEZIONATI.TOGGLE
					 */
					TOGGLE: {
						/**
						 * Mostra solo i documenti selezionati
						 */
						FALSE: {
							id: 2111,
							label: "b2b.CART.DOCUMENTS.MOSTRA-SOLO-SELEZIONATI.TOGGLE.FALSE"
						},

						/**
						 * Mostra tutti i documenti
						 */
						TRUE: {
							id: 2112,
							label: "b2b.CART.DOCUMENTS.MOSTRA-SOLO-SELEZIONATI.TOGGLE.TRUE"
						},

					},

				},

				/**
				 * Altri ---------------------
				 */
				OPT_GROUP_ALTRI: {
					id: 2113,
					label: "b2b.CART.DOCUMENTS.OPT-GROUP-ALTRI"
				},

				/**
				 * Conferme ------------------
				 */
				OPT_GROUP_CONFERME: {
					id: 2114,
					label: "b2b.CART.DOCUMENTS.OPT-GROUP-CONFERME"
				},

				/**
				 * Programmi di viaggio ------
				 */
				OPT_GROUP_PDV: {
					id: 2115,
					label: "b2b.CART.DOCUMENTS.OPT-GROUP-PDV"
				},

				/**
				 * Preventivi ----------------
				 */
				OPT_GROUP_PREVENTIVI: {
					id: 2116,
					label: "b2b.CART.DOCUMENTS.OPT-GROUP-PREVENTIVI"
				},

				/**
				 * Content of path b2b.CART.DOCUMENTS.ORDINA-PER-NOME
				 */
				ORDINA_PER_NOME: {
					/**
					 * Ordina per Nome
					 */
					CAPTION: {
						id: 2117,
						label: "b2b.CART.DOCUMENTS.ORDINA-PER-NOME.CAPTION"
					},

					/**
					 * Content of path b2b.CART.DOCUMENTS.ORDINA-PER-NOME.TOGGLE
					 */
					TOGGLE: {
						/**
						 * Dalla A alla Z
						 */
						FALSE: {
							id: 2118,
							label: "b2b.CART.DOCUMENTS.ORDINA-PER-NOME.TOGGLE.FALSE"
						},

						/**
						 * Dalla Z alla A
						 */
						TRUE: {
							id: 2119,
							label: "b2b.CART.DOCUMENTS.ORDINA-PER-NOME.TOGGLE.TRUE"
						},

					},

				},

				/**
				 * Sono stati selezionati documenti che non consentono la creazione di un unico PDF
				 */
				PDF_MULTI_NON_AMMESSA: {
					id: 2120,
					label: "b2b.CART.DOCUMENTS.PDF-MULTI-NON-AMMESSA"
				},

				/**
				 * Preview
				 */
				PREVIEW: {
					id: 2121,
					label: "b2b.CART.DOCUMENTS.PREVIEW"
				},

				/**
				 * Content of path b2b.CART.DOCUMENTS.RESET
				 */
				RESET: {
					/**
					 * Reset
					 */
					CAPTION: {
						id: 2122,
						label: "b2b.CART.DOCUMENTS.RESET.CAPTION"
					},

					/**
					 * Ripristina i valori originali
					 */
					TOOLTIP: {
						id: 2123,
						label: "b2b.CART.DOCUMENTS.RESET.TOOLTIP"
					},

				},

				/**
				 * Rinumera
				 */
				RINUMERA_SELEZIONATI: {
					id: 2124,
					label: "b2b.CART.DOCUMENTS.RINUMERA-SELEZIONATI"
				},

				/**
				 * Riordina
				 */
				RIORDINA_SELEZIONATI: {
					id: 2125,
					label: "b2b.CART.DOCUMENTS.RIORDINA-SELEZIONATI"
				},

				/**
				 * S
				 */
				S_SPEDIZIONE: {
					id: 2126,
					label: "b2b.CART.DOCUMENTS.S-SPEDIZIONE"
				},

				/**
				 * Scarica allegato
				 */
				SCARICA_ALLEGATO: {
					id: 2127,
					label: "b2b.CART.DOCUMENTS.SCARICA-ALLEGATO"
				},

				/**
				 * Seleziona tutti
				 */
				SELEZIONA_TUTTI: {
					id: 2128,
					label: "b2b.CART.DOCUMENTS.SELEZIONA-TUTTI"
				},

				/**
				 * Stampa
				 */
				STAMPA: {
					id: 2129,
					label: "b2b.CART.DOCUMENTS.STAMPA"
				},

				/**
				 * Sono stati selezionati documenti che non consentono la stampa multipla
				 */
				STAMPA_MULTIPLA_NON_AMMESSA: {
					id: 2130,
					label: "b2b.CART.DOCUMENTS.STAMPA-MULTIPLA-NON-AMMESSA"
				},

				/**
				 * Testo
				 */
				TESTO: {
					id: 2131,
					label: "b2b.CART.DOCUMENTS.TESTO"
				},

				/**
				 * Tipo
				 */
				TIPO_DOCUMENTO: {
					id: 2132,
					label: "b2b.CART.DOCUMENTS.TIPO-DOCUMENTO"
				},

				/**
				 * Conversione documento completata
				 */
				TRADUCIBILE_CONVERTITO: {
					id: 2133,
					label: "b2b.CART.DOCUMENTS.TRADUCIBILE-CONVERTITO"
				},

				/**
				 * Usa nuovo
				 */
				USA_NUOVO: {
					id: 2134,
					label: "b2b.CART.DOCUMENTS.USA-NUOVO"
				},

				/**
				 * Vedi solo selezionati
				 */
				VEDI_SOLO_SELEZIONATI: {
					id: 2135,
					label: "b2b.CART.DOCUMENTS.VEDI-SOLO-SELEZIONATI"
				},

				/**
				 * Visualizza allegato
				 */
				VISUALIZZA_ALLEGATO: {
					id: 2136,
					label: "b2b.CART.DOCUMENTS.VISUALIZZA-ALLEGATO"
				},

				/**
				 * Stampato
				 */
				STAMPATO: {
					id: 6621,
					label: "b2b.CART.DOCUMENTS.STAMPATO"
				},

				/**
				 * Disponibilità e prezzi aggiornati al 
				 */
				DISPONIBILITA_PREZZI_AGGIORNATI: {
					id: 6622,
					label: "b2b.CART.DOCUMENTS.DISPONIBILITA-PREZZI-AGGIORNATI"
				},

				/**
				 * alle ore 
				 */
				ALLE_ORE: {
					id: 6623,
					label: "b2b.CART.DOCUMENTS.ALLE-ORE"
				},

				/**
				 * Prezzi per persona
				 */
				PREZZI_PER_PERSONA: {
					id: 6624,
					label: "b2b.CART.DOCUMENTS.PREZZI-PER-PERSONA"
				},

				/**
				 * Prenotazione
				 */
				PRENOTAZIONE: {
					id: 6625,
					label: "b2b.CART.DOCUMENTS.PRENOTAZIONE"
				},

				/**
				 * Da - A
				 */
				DA_A: {
					id: 6626,
					label: "b2b.CART.DOCUMENTS.DA-A"
				},

				/**
				 * Ora
				 */
				ORA: {
					id: 6627,
					label: "b2b.CART.DOCUMENTS.ORA"
				},

				/**
				 * dall'aeroporto di
				 */
				DALL_AEROPORTO_DI: {
					id: 6628,
					label: "b2b.CART.DOCUMENTS.DALL-AEROPORTO-DI"
				},

				/**
				 * Si raccomanda di presentarsi in aeroporto almeno quattro ore prima della partenza del volo.
				 */
				SI_RACCOMANDA_DI_PRESENTARSI_3_ORE_PRIMA: {
					id: 6629,
					label: "b2b.CART.DOCUMENTS.SI-RACCOMANDA-DI-PRESENTARSI-3-ORE-PRIMA"
				},

				/**
				 * Cambi applicati
				 */
				CAMBI_APPLICATI: {
					id: 6630,
					label: "b2b.CART.DOCUMENTS.CAMBI-APPLICATI"
				},

				/**
				 * Informazioni aggiuntive
				 */
				INFORMAZIONI_AGGIUNTIVE: {
					id: 6632,
					label: "b2b.CART.DOCUMENTS.INFORMAZIONI-AGGIUNTIVE"
				},

			},

			/**
			 * Content of path b2b.CART.EMAILS
			 */
			EMAILS: {
				/**
				 * Allegati
				 */
				ALLEGATI: {
					id: 2137,
					label: "b2b.CART.EMAILS.ALLEGATI"
				},

				/**
				 * Bcc
				 */
				BCC: {
					id: 2138,
					label: "b2b.CART.EMAILS.BCC"
				},

				/**
				 * Cc
				 */
				CC: {
					id: 2139,
					label: "b2b.CART.EMAILS.CC"
				},

				/**
				 * Da inviare il
				 */
				DA_INVIARE_IL: {
					id: 2140,
					label: "b2b.CART.EMAILS.DA-INVIARE-IL"
				},

				/**
				 * Errore di invio
				 */
				ERRORE: {
					id: 2141,
					label: "b2b.CART.EMAILS.ERRORE"
				},

				/**
				 * Da
				 */
				FROM: {
					id: 2142,
					label: "b2b.CART.EMAILS.FROM"
				},

				/**
				 * Inviata il
				 */
				INVIATA_IL: {
					id: 2143,
					label: "b2b.CART.EMAILS.INVIATA-IL"
				},

				/**
				 * Invio annullato
				 */
				INVIO_ANNULLATO: {
					id: 2144,
					label: "b2b.CART.EMAILS.INVIO-ANNULLATO"
				},

				/**
				 * Oggetto
				 */
				OGGETTO: {
					id: 2145,
					label: "b2b.CART.EMAILS.OGGETTO"
				},

				/**
				 * Content of path b2b.CART.EMAILS.TABLE-HEADER
				 */
				TABLE_HEADER: {
					/**
					 * Allegati
					 */
					Allegati: {
						id: 2146,
						label: "b2b.CART.EMAILS.TABLE-HEADER.Allegati"
					},

					/**
					 * Destinatari
					 */
					Destinatari: {
						id: 2147,
						label: "b2b.CART.EMAILS.TABLE-HEADER.Destinatari"
					},

					/**
					 * ID
					 */
					ID: {
						id: 2148,
						label: "b2b.CART.EMAILS.TABLE-HEADER.ID"
					},

					/**
					 * Oggetto
					 */
					Oggetto: {
						id: 2149,
						label: "b2b.CART.EMAILS.TABLE-HEADER.Oggetto"
					},

					/**
					 * Stato
					 */
					Stato: {
						id: 2150,
						label: "b2b.CART.EMAILS.TABLE-HEADER.Stato"
					},

				},

				/**
				 * Corrispondenza
				 */
				TITOLO: {
					id: 2151,
					label: "b2b.CART.EMAILS.TITOLO"
				},

				/**
				 * A
				 */
				TO: {
					id: 2152,
					label: "b2b.CART.EMAILS.TO"
				},

				/**
				 * Vedi email
				 */
				VEDI_EMAIL: {
					id: 2153,
					label: "b2b.CART.EMAILS.VEDI-EMAIL"
				},

				/**
				 * Vedi email in una nuova finestra
				 */
				VEDI_EMAIL_NEW_WINDOW: {
					id: 2154,
					label: "b2b.CART.EMAILS.VEDI-EMAIL-NEW-WINDOW"
				},

				/**
				 * Data
				 */
				DATE: {
					id: 6137,
					label: "b2b.CART.EMAILS.DATE"
				},

				/**
				 * CART.EMAILS.DATA
				 */
				DATA: {
					id: 6362,
					label: "b2b.CART.EMAILS.DATA"
				},

			},

			/**
			 * Content of path b2b.CART.FLIGHTS
			 */
			FLIGHTS: {
				/**
				 * Abbinamento
				 */
				ABBINAMENTO: {
					id: 2155,
					label: "b2b.CART.FLIGHTS.ABBINAMENTO"
				},

				/**
				 * Aggiungi supplemento
				 */
				AGGIUNGI_SUPPLEMENTO: {
					id: 2156,
					label: "b2b.CART.FLIGHTS.AGGIUNGI-SUPPLEMENTO"
				},

				/**
				 * Contabilizzazione
				 */
				CONTABILIZZAZIONE: {
					id: 2157,
					label: "b2b.CART.FLIGHTS.CONTABILIZZAZIONE"
				},

				/**
				 * Costo
				 */
				COSTO: {
					id: 2158,
					label: "b2b.CART.FLIGHTS.COSTO"
				},

				/**
				 * Elimina questo volo
				 */
				ELIMINA_VOLO: {
					id: 2159,
					label: "b2b.CART.FLIGHTS.ELIMINA-VOLO"
				},

				/**
				 * Confermi di voler eliminare il PNR/Preventivo: {pnr}?
				 */
				ELIMINA_VOLO_TESTO: {
					id: 2160,
					label: "b2b.CART.FLIGHTS.ELIMINA-VOLO-TESTO"
				},

				/**
				 * Emesso biglietteria
				 */
				EMESSO_BIGLIETTERIA: {
					id: 2161,
					label: "b2b.CART.FLIGHTS.EMESSO-BIGLIETTERIA"
				},

				/**
				 * ID
				 */
				ID: {
					id: 2162,
					label: "b2b.CART.FLIGHTS.ID"
				},

				/**
				 * Margine
				 */
				MARGINE: {
					id: 2163,
					label: "b2b.CART.FLIGHTS.MARGINE"
				},

				/**
				 * Modifica
				 */
				MODIFICA: {
					id: 2164,
					label: "b2b.CART.FLIGHTS.MODIFICA"
				},

				/**
				 * Ok contabilità 
				 */
				OK_CONTABILITA: {
					id: 2165,
					label: "b2b.CART.FLIGHTS.OK-CONTABILITA"
				},

				/**
				 * Ordinato biglietteria
				 */
				ORDINATO_BIGLIETTERIA: {
					id: 2166,
					label: "b2b.CART.FLIGHTS.ORDINATO-BIGLIETTERIA"
				},

				/**
				 * Piano voli
				 */
				PIANO_VOLI: {
					id: 2167,
					label: "b2b.CART.FLIGHTS.PIANO-VOLI"
				},

				/**
				 * Pnr
				 */
				PNR: {
					id: 2168,
					label: "b2b.CART.FLIGHTS.PNR"
				},

				/**
				 * Pnr nr.
				 */
				PNR_NR: {
					id: 2169,
					label: "b2b.CART.FLIGHTS.PNR-NR"
				},

				/**
				 * Preso in carico biglietteria
				 */
				PRESO_IN_CARICO_BIGLIETTERIA: {
					id: 2170,
					label: "b2b.CART.FLIGHTS.PRESO-IN-CARICO-BIGLIETTERIA"
				},

				/**
				 * Preventivo voli nr.
				 */
				PREVENTIVO_VOLI_NR: {
					id: 2171,
					label: "b2b.CART.FLIGHTS.PREVENTIVO-VOLI-NR"
				},

				/**
				 * Soluzione
				 */
				SOLUZIONE: {
					id: 2172,
					label: "b2b.CART.FLIGHTS.SOLUZIONE"
				},

				/**
				 * Stato
				 */
				STATO: {
					id: 2173,
					label: "b2b.CART.FLIGHTS.STATO"
				},

				/**
				 * SUPPLEMENTO
				 */
				SUPPLEMENTO: {
					id: 2174,
					label: "b2b.CART.FLIGHTS.SUPPLEMENTO"
				},

				/**
				 * Tariffe
				 */
				TARIFFE: {
					id: 2175,
					label: "b2b.CART.FLIGHTS.TARIFFE"
				},

				/**
				 * Voli
				 */
				TITLE: {
					id: 2176,
					label: "b2b.CART.FLIGHTS.TITLE"
				},

				/**
				 * Dati passeggeri cambiati. Aggiornare PNR
				 */
				VERIFICA_DATI_PAX: {
					id: 2177,
					label: "b2b.CART.FLIGHTS.VERIFICA-DATI-PAX"
				},

				/**
				 * Fatto
				 */
				VERIFICA_DATI_PAX_FATTO: {
					id: 2178,
					label: "b2b.CART.FLIGHTS.VERIFICA-DATI-PAX-FATTO"
				},

				/**
				 * Volo aggiunto al viaggio
				 */
				VOLO_AGGIUNTO: {
					id: 2179,
					label: "b2b.CART.FLIGHTS.VOLO-AGGIUNTO"
				},

				/**
				 * Volo Eliminato
				 */
				VOLO_ELIMINATO: {
					id: 2180,
					label: "b2b.CART.FLIGHTS.VOLO-ELIMINATO"
				},

			},

			/**
			 * Content of path b2b.CART.HEADER
			 */
			HEADER: {
				/**
				 * Aggiorna anticipi fornitore
				 */
				AGGIORNA_ANTICIPI_FORNITORE: {
					id: 2181,
					label: "b2b.CART.HEADER.AGGIORNA-ANTICIPI-FORNITORE"
				},

				/**
				 * Aggiorna totali
				 */
				AGGIORNA_TOTALI: {
					id: 2182,
					label: "b2b.CART.HEADER.AGGIORNA-TOTALI"
				},

				/**
				 * Annulla
				 */
				ANNULLA: {
					id: 2183,
					label: "b2b.CART.HEADER.ANNULLA"
				},

				/**
				 * Anticipi fornitore aggiornati
				 */
				ANTICIPI_FORNITORE_AGGIORNATI: {
					id: 2184,
					label: "b2b.CART.HEADER.ANTICIPI-FORNITORE-AGGIORNATI"
				},

				/**
				 * Carica offerta
				 */
				CARICA_OFFERTA: {
					id: 2185,
					label: "b2b.CART.HEADER.CARICA-OFFERTA"
				},

				/**
				 * Copia dell'offerta
				 */
				COPIA_DELL_OFFERTA: {
					id: 2186,
					label: "b2b.CART.HEADER.COPIA-DELL-OFFERTA"
				},

				/**
				 * Creata da
				 */
				CREATA_DA: {
					id: 2187,
					label: "b2b.CART.HEADER.CREATA-DA"
				},

				/**
				 * Creata il
				 */
				CREATA_IL: {
					id: 2188,
					label: "b2b.CART.HEADER.CREATA-IL"
				},

				/**
				 * Il tuo viaggio
				 */
				DATI_VIAGGIO: {
					id: 2189,
					label: "b2b.CART.HEADER.DATI-VIAGGIO"
				},

				/**
				 * Destinazione
				 */
				DESTINAZIONE: {
					id: 2190,
					label: "b2b.CART.HEADER.DESTINAZIONE"
				},

				/**
				 * Duplica
				 */
				DUPLICA: {
					id: 2191,
					label: "b2b.CART.HEADER.DUPLICA"
				},

				/**
				 * Confermi di voler creare un nuovo preventivo come copia di questa offerta?
				 */
				DUPLICA_CONFERMA_TESTO: {
					id: 2192,
					label: "b2b.CART.HEADER.DUPLICA-CONFERMA-TESTO"
				},

				/**
				 * Duplicazione offerta {off}
				 */
				DUPLICA_CONFERMA_TITOLO: {
					id: 2193,
					label: "b2b.CART.HEADER.DUPLICA-CONFERMA-TITOLO"
				},

				/**
				 * {giorni} giorn{giorni, plural, =0{i} one{o} other{i}}
				 */
				GIORNI: {
					id: 2194,
					label: "b2b.CART.HEADER.GIORNI"
				},

				/**
				 * Testata
				 */
				HEADER_TESTATA: {
					id: 2195,
					label: "b2b.CART.HEADER.HEADER-TESTATA"
				},

				/**
				 * Itinerario sbloccato.
				 */
				ITINERARY_UNLOCKED: {
					id: 2196,
					label: "b2b.CART.HEADER.ITINERARY_UNLOCKED"
				},

				/**
				 * Log attività 
				 */
				LOG: {
					id: 2197,
					label: "b2b.CART.HEADER.LOG"
				},

				/**
				 * Content of path b2b.CART.HEADER.LOG-ATTIVITA
				 */
				LOG_ATTIVITA: {
					/**
					 * Data
					 */
					DATA: {
						id: 2198,
						label: "b2b.CART.HEADER.LOG-ATTIVITA.DATA"
					},

					/**
					 * Dati
					 */
					DATI: {
						id: 2199,
						label: "b2b.CART.HEADER.LOG-ATTIVITA.DATI"
					},

					/**
					 * Master
					 */
					MASTER: {
						id: 2200,
						label: "b2b.CART.HEADER.LOG-ATTIVITA.MASTER"
					},

					/**
					 * Messaggio
					 */
					MESSAGGIO: {
						id: 2201,
						label: "b2b.CART.HEADER.LOG-ATTIVITA.MESSAGGIO"
					},

					/**
					 * Metodo
					 */
					METODO: {
						id: 2202,
						label: "b2b.CART.HEADER.LOG-ATTIVITA.METODO"
					},

					/**
					 * Operazione
					 */
					OPERAZIONE: {
						id: 2203,
						label: "b2b.CART.HEADER.LOG-ATTIVITA.OPERAZIONE"
					},

					/**
					 * Record
					 */
					RECORD: {
						id: 2204,
						label: "b2b.CART.HEADER.LOG-ATTIVITA.RECORD"
					},

					/**
					 * Log attività
					 */
					TITLE: {
						id: 2205,
						label: "b2b.CART.HEADER.LOG-ATTIVITA.TITLE"
					},

					/**
					 * Utente
					 */
					UTENTE: {
						id: 2206,
						label: "b2b.CART.HEADER.LOG-ATTIVITA.UTENTE"
					},

				},

				/**
				 * Modifica testata
				 */
				MODIFICA: {
					id: 2207,
					label: "b2b.CART.HEADER.MODIFICA"
				},

				/**
				 * Nome del viaggio
				 */
				NOME_DEL_VIAGGIO: {
					id: 2208,
					label: "b2b.CART.HEADER.NOME-DEL-VIAGGIO"
				},

				/**
				 * Il nome del viaggio è obbligatorio
				 */
				NOME_DEL_VIAGGIO_OBBLIGATORIO: {
					id: 2209,
					label: "b2b.CART.HEADER.NOME-DEL-VIAGGIO-OBBLIGATORIO"
				},

				/**
				 * Nome viaggio salvato
				 */
				NOME_VIAGGIO_SALVATO: {
					id: 2210,
					label: "b2b.CART.HEADER.NOME-VIAGGIO-SALVATO"
				},

				/**
				 * Descrizione
				 */
				NOTE: {
					id: 2211,
					label: "b2b.CART.HEADER.NOTE"
				},

				/**
				 * {notti} nott{notti, plural, =0{i} one{e} other{i}}
				 */
				NOTTI: {
					id: 2212,
					label: "b2b.CART.HEADER.NOTTI"
				},

				/**
				 * ID offerta
				 */
				NUMERO_OFFERTA_IMPORT: {
					id: 2213,
					label: "b2b.CART.HEADER.NUMERO-OFFERTA-IMPORT"
				},

				/**
				 * Usa i dati di un'offerta esistente
				 */
				NUMERO_OFFERTA_IMPORT_HELP: {
					id: 2214,
					label: "b2b.CART.HEADER.NUMERO-OFFERTA-IMPORT-HELP"
				},

				/**
				 * Offerta duplicata
				 */
				OFFERTA_DUPLICATA: {
					id: 2215,
					label: "b2b.CART.HEADER.OFFERTA-DUPLICATA"
				},

				/**
				 * Offerta master
				 */
				OFFERTA_MASTER: {
					id: 2216,
					label: "b2b.CART.HEADER.OFFERTA-MASTER"
				},

				/**
				 * Partenza il
				 */
				PARTENZA: {
					id: 2217,
					label: "b2b.CART.HEADER.PARTENZA"
				},

				/**
				 * Pax totali
				 */
				PAX_TOTALI: {
					id: 2218,
					label: "b2b.CART.HEADER.PAX-TOTALI"
				},

				/**
				 * Riepilogo
				 */
				RIEPILOGO_DEL_VIAGGIO: {
					id: 2219,
					label: "b2b.CART.HEADER.RIEPILOGO-DEL-VIAGGIO"
				},

				/**
				 * Salva
				 */
				SALVA: {
					id: 2220,
					label: "b2b.CART.HEADER.SALVA"
				},

				/**
				 * Sblocca itinerario
				 */
				SBLOCCAITINERARIO: {
					id: 2221,
					label: "b2b.CART.HEADER.SBLOCCAITINERARIO"
				},

				/**
				 * Snapshot
				 */
				SNAPSHOT: {
					id: 2222,
					label: "b2b.CART.HEADER.SNAPSHOT"
				},

				/**
				 * Offerta {idOfferta}: Snapshot del {timeStampDate}
				 */
				SNAPSHOT_TIMESTAMP: {
					id: 2223,
					label: "b2b.CART.HEADER.SNAPSHOT-TIMESTAMP"
				},

				/**
				 * {tappe} tapp{tappe, plural, =0{e} one{a} other{e}}
				 */
				TAPPE: {
					id: 2224,
					label: "b2b.CART.HEADER.TAPPE"
				},

				/**
				 * Tipo viaggio
				 */
				TIPO_VIAGGIO: {
					id: 2225,
					label: "b2b.CART.HEADER.TIPO-VIAGGIO"
				},

				/**
				 * Titolo del viaggio
				 */
				TITOLO_DEL_VIAGGIO: {
					id: 2226,
					label: "b2b.CART.HEADER.TITOLO-DEL-VIAGGIO"
				},

				/**
				 * Totali aggiornati
				 */
				TOTALE_AGGIORNATO: {
					id: 2227,
					label: "b2b.CART.HEADER.TOTALE-AGGIORNATO"
				},

				/**
				 * Totale commissioni
				 */
				TOTALE_COMMISSIONI: {
					id: 2228,
					label: "b2b.CART.HEADER.TOTALE-COMMISSIONI"
				},

				/**
				 * Totale netto
				 */
				TOTALE_NETTO: {
					id: 2229,
					label: "b2b.CART.HEADER.TOTALE-NETTO"
				},

				/**
				 * Totale viaggio
				 */
				TOTALE_VIAGGIO: {
					id: 2230,
					label: "b2b.CART.HEADER.TOTALE-VIAGGIO"
				},

				/**
				 * Sblocco itinerario {off}
				 */
				UNLOCK_ITINERARY_CONFERMA_TESTO: {
					id: 2231,
					label: "b2b.CART.HEADER.UNLOCK-ITINERARY-CONFERMA-TESTO"
				},

				/**
				 * Confermi di voler sbloccare l'itinerario per questa offerta?
				 */
				UNLOCK_ITINERARY_CONFERMA_TITOLO: {
					id: 2232,
					label: "b2b.CART.HEADER.UNLOCK-ITINERARY-CONFERMA-TITOLO"
				},

				/**
				 * {pax} viaggiator{pax, plural, =0{i} one{e} other{i}}
				 */
				VIAGGIATORI: {
					id: 2233,
					label: "b2b.CART.HEADER.VIAGGIATORI"
				},

				/**
				 * Viaggio di
				 */
				VIAGGIO_DI: {
					id: 2234,
					label: "b2b.CART.HEADER.VIAGGIO-DI"
				},

				/**
				 * Viaggio di nozze
				 */
				VIAGGIO_DI_NOZZE: {
					id: 2235,
					label: "b2b.CART.HEADER.VIAGGIO-DI-NOZZE"
				},

				/**
				 * Specificare l'agenzia
				 */
				AGENZIA_OBBLIGATORIO: {
					id: 6302,
					label: "b2b.CART.HEADER.AGENZIA-OBBLIGATORIO"
				},

			},

			/**
			 * Content of path b2b.CART.HEADER-EDIT
			 */
			HEADER_EDIT: {
				/**
				 * Chiudi
				 */
				CHIUDI: {
					id: 2236,
					label: "b2b.CART.HEADER-EDIT.CHIUDI"
				},

				/**
				 * Chiudi dopo il salvataggio
				 */
				CHIUDI_DOPO_SALVATAGGIO: {
					id: 2237,
					label: "b2b.CART.HEADER-EDIT.CHIUDI-DOPO-SALVATAGGIO"
				},

				/**
				 * Creazione
				 */
				CREAZIONE: {
					id: 2238,
					label: "b2b.CART.HEADER-EDIT.CREAZIONE"
				},

				/**
				 * La data di creazione è obbligatoria
				 */
				CREAZIONE_OBBLIGATORIA: {
					id: 2239,
					label: "b2b.CART.HEADER-EDIT.CREAZIONE-OBBLIGATORIA"
				},

				/**
				 * Descrizione
				 */
				DESCRIZIONE: {
					id: 2240,
					label: "b2b.CART.HEADER-EDIT.DESCRIZIONE"
				},

				/**
				 * La descrizione è obbligatoria
				 */
				DESCRIZIONE_OBBLIGATORIA: {
					id: 2241,
					label: "b2b.CART.HEADER-EDIT.DESCRIZIONE-OBBLIGATORIA"
				},

				/**
				 * Destinazione
				 */
				DESTINAZIONE: {
					id: 2242,
					label: "b2b.CART.HEADER-EDIT.DESTINAZIONE"
				},

				/**
				 * Modifiche salvate
				 */
				MODIFICHE_SALVATE: {
					id: 2243,
					label: "b2b.CART.HEADER-EDIT.MODIFICHE-SALVATE"
				},

				/**
				 * I campi contrassegnati dall'asterisco (*) sono obbligatori.
				 */
				MSG_MANDATORY_FIELDS: {
					id: 2244,
					label: "b2b.CART.HEADER-EDIT.MSG-MANDATORY-FIELDS"
				},

				/**
				 * Note
				 */
				NOTE: {
					id: 2245,
					label: "b2b.CART.HEADER-EDIT.NOTE"
				},

				/**
				 * Num pax
				 */
				NUM_PAX: {
					id: 2246,
					label: "b2b.CART.HEADER-EDIT.NUM-PAX"
				},

				/**
				 * Inserire il numero di passeggeri, minimo 1
				 */
				NUM_PAX_NON_VALIDO: {
					id: 2247,
					label: "b2b.CART.HEADER-EDIT.NUM-PAX-NON-VALIDO"
				},

				/**
				 * Operatore
				 */
				OPERATORE: {
					id: 2248,
					label: "b2b.CART.HEADER-EDIT.OPERATORE"
				},

				/**
				 * Partenza
				 */
				PARTENZA: {
					id: 2249,
					label: "b2b.CART.HEADER-EDIT.PARTENZA"
				},

				/**
				 * Riferimento
				 */
				RIFERIMENTO: {
					id: 2250,
					label: "b2b.CART.HEADER-EDIT.RIFERIMENTO"
				},

				/**
				 * Il numero di offerta indicato non esiste
				 */
				RIFERIMENTO_NON_ESISTE: {
					id: 2251,
					label: "b2b.CART.HEADER-EDIT.RIFERIMENTO-NON-ESISTE"
				},

				/**
				 * Inserire il numero di offerta di riferimento
				 */
				RIFERIMENTO_NON_VALIDO: {
					id: 2252,
					label: "b2b.CART.HEADER-EDIT.RIFERIMENTO-NON-VALIDO"
				},

				/**
				 * Salva modifiche
				 */
				SALVA_MODIFICHE: {
					id: 2253,
					label: "b2b.CART.HEADER-EDIT.SALVA-MODIFICHE"
				},

				/**
				 * Tipo di viaggio
				 */
				TIPO_DI_VIAGGIO: {
					id: 2254,
					label: "b2b.CART.HEADER-EDIT.TIPO-DI-VIAGGIO"
				},

			},

			/**
			 * Il tuo viaggio
			 */
			IL_TUO_VIAGGIO: {
				id: 2255,
				label: "b2b.CART.IL-TUO-VIAGGIO"
			},

			/**
			 * Content of path b2b.CART.INDEX
			 */
			INDEX: {
				/**
				 * Content of path b2b.CART.INDEX.COLLAPSE
				 */
				COLLAPSE: {
					/**
					 * Espandi il blocco dei comandi
					 */
					FALSE: {
						id: 2256,
						label: "b2b.CART.INDEX.COLLAPSE.FALSE"
					},

					/**
					 * Comprimi il blocco dei comandi
					 */
					TRUE: {
						id: 2257,
						label: "b2b.CART.INDEX.COLLAPSE.TRUE"
					},

				},

				/**
				 * Indice
				 */
				TITLE: {
					id: 2258,
					label: "b2b.CART.INDEX.TITLE"
				},

			},

			/**
			 * Content of path b2b.CART.INDICE-PREVENTIVO
			 */
			INDICE_PREVENTIVO: {
				/**
				 * Assicurazioni
				 */
				ASSICURAZIONI: {
					id: 2259,
					label: "b2b.CART.INDICE-PREVENTIVO.ASSICURAZIONI"
				},

				/**
				 * Calendario pagamenti
				 */
				CALENDARIO_PAGAMENTI: {
					id: 2260,
					label: "b2b.CART.INDICE-PREVENTIVO.CALENDARIO-PAGAMENTI"
				},

				/**
				 * Documenti agenzia
				 */
				DOCUMENTI_VIAGGIO: {
					id: 2261,
					label: "b2b.CART.INDICE-PREVENTIVO.DOCUMENTI-VIAGGIO"
				},

				/**
				 * Note offerta
				 */
				NOTE_OFFERTA: {
					id: 2262,
					label: "b2b.CART.INDICE-PREVENTIVO.NOTE-OFFERTA"
				},

				/**
				 * Il tuo viaggio
				 */
				RIEPILOGO_VIAGGIO: {
					id: 2263,
					label: "b2b.CART.INDICE-PREVENTIVO.RIEPILOGO-VIAGGIO"
				},

				/**
				 * Quote e servizi accessori
				 */
				SERVIZI_ACCESSORI: {
					id: 2264,
					label: "b2b.CART.INDICE-PREVENTIVO.SERVIZI-ACCESSORI"
				},

				/**
				 * Tappe e servizi
				 */
				TAPPE: {
					id: 2265,
					label: "b2b.CART.INDICE-PREVENTIVO.TAPPE"
				},

				/**
				 * Viaggiatori
				 */
				VIAGGIATORI: {
					id: 2266,
					label: "b2b.CART.INDICE-PREVENTIVO.VIAGGIATORI"
				},

				/**
				 * Voli e tariffe
				 */
				VOLI: {
					id: 2267,
					label: "b2b.CART.INDICE-PREVENTIVO.VOLI"
				},

				/**
				 * Corrispondenza
				 */
				CORRISPONDENZA: {
					id: 6250,
					label: "b2b.CART.INDICE-PREVENTIVO.CORRISPONDENZA"
				},

			},

			/**
			 * Content of path b2b.CART.INSURANCES
			 */
			INSURANCES: {
				/**
				 * Assicurazione aggiunta al viaggio
				 */
				ASSICURAZIONE_AGGIUNTA: {
					id: 2268,
					label: "b2b.CART.INSURANCES.ASSICURAZIONE-AGGIUNTA"
				},

				/**
				 * Questa assicurazione è già presente nel tuo viaggio
				 */
				GIA_PRESENTE: {
					id: 2269,
					label: "b2b.CART.INSURANCES.GIA-PRESENTE"
				},

				/**
				 * Aggiungi assicurazioni al viaggio
				 */
				TITLE: {
					id: 2270,
					label: "b2b.CART.INSURANCES.TITLE"
				},

			},

			/**
			 * Content of path b2b.CART.NAVBAR
			 */
			NAVBAR: {
				/**
				 * Criptico
				 */
				AMADEUS_CRYPTIC: {
					id: 2271,
					label: "b2b.CART.NAVBAR.AMADEUS-CRYPTIC"
				},

				/**
				 * Preventivo
				 */
				CARRELLO: {
					id: 2272,
					label: "b2b.CART.NAVBAR.CARRELLO"
				},

				/**
				 * Itinerario
				 */
				PLANNER: {
					id: 2273,
					label: "b2b.CART.NAVBAR.PLANNER"
				},

			},

			/**
			 * Content of path b2b.CART.NOTE-EDIT
			 */
			NOTE_EDIT: {
				/**
				 * Visualizzazione agenzia
				 */
				COMUNICAZIONI_AGENZIA: {
					id: 2274,
					label: "b2b.CART.NOTE-EDIT.COMUNICAZIONI-AGENZIA"
				},

				/**
				 * Crea nuova nota
				 */
				CREA_NUOVA_NOTA: {
					id: 2275,
					label: "b2b.CART.NOTE-EDIT.CREA-NUOVA-NOTA"
				},

				/**
				 * Data
				 */
				DATA: {
					id: 2276,
					label: "b2b.CART.NOTE-EDIT.DATA"
				},

				/**
				 * La data è obbligatoria
				 */
				DATA_OBBLIGATORIA: {
					id: 2277,
					label: "b2b.CART.NOTE-EDIT.DATA-OBBLIGATORIA"
				},

				/**
				 * Confermi l'eliminazione della nota ID={id}?
				 */
				ELIMINARE_NOTA: {
					id: 2278,
					label: "b2b.CART.NOTE-EDIT.ELIMINARE-NOTA"
				},

				/**
				 * Eliminazione nota
				 */
				ELIMINARE_NOTA_TITOLO: {
					id: 2279,
					label: "b2b.CART.NOTE-EDIT.ELIMINARE-NOTA-TITOLO"
				},

				/**
				 * Confermi l'eliminazione di {n} not{n, plural, =0{e} one{a} other{e}}?
				 */
				ELIMINARE_NOTE: {
					id: 2280,
					label: "b2b.CART.NOTE-EDIT.ELIMINARE-NOTE"
				},

				/**
				 * Eliminazione note
				 */
				ELIMINARE_NOTE_TITOLO: {
					id: 2281,
					label: "b2b.CART.NOTE-EDIT.ELIMINARE-NOTE-TITOLO"
				},

				/**
				 * Inserita da
				 */
				IMPIEGATO: {
					id: 2282,
					label: "b2b.CART.NOTE-EDIT.IMPIEGATO"
				},

				/**
				 * L'impiegato è obbligatorio
				 */
				IMPIEGATO_OBBLIGATORIO: {
					id: 2283,
					label: "b2b.CART.NOTE-EDIT.IMPIEGATO-OBBLIGATORIO"
				},

				/**
				 * Kanban
				 */
				KANBAN: {
					id: 2284,
					label: "b2b.CART.NOTE-EDIT.KANBAN"
				},

				/**
				 * Mostra su
				 */
				MOSTRA_SU: {
					id: 2285,
					label: "b2b.CART.NOTE-EDIT.MOSTRA-SU"
				},

				/**
				 * Nelle comunicazioni a
				 */
				NELLE_COMUNICAZIONI_A: {
					id: 2286,
					label: "b2b.CART.NOTE-EDIT.NELLE-COMUNICAZIONI-A"
				},

				/**
				 * Nota eliminata
				 */
				NOTA_ELIMINATA: {
					id: 2287,
					label: "b2b.CART.NOTE-EDIT.NOTA-ELIMINATA"
				},

				/**
				 * Nota ID={id}
				 */
				NOTA_ID: {
					id: 2288,
					label: "b2b.CART.NOTE-EDIT.NOTA-ID"
				},

				/**
				 * Eliminat{n, plural, =0{e} one{a} other{e}} {n} not{n, plural, =0{e} one{a} other{e}}
				 */
				NOTE_ELIMINATE: {
					id: 2289,
					label: "b2b.CART.NOTE-EDIT.NOTE-ELIMINATE"
				},

				/**
				 * {n} not{n, plural, =0{e} one{a} other{e}} selezionat{n, plural, =0{e} one{a} other{e}}
				 */
				NOTE_SELEZIONATE: {
					id: 2290,
					label: "b2b.CART.NOTE-EDIT.NOTE-SELEZIONATE"
				},

				/**
				 * Nuova nota
				 */
				NUOVA_NOTA: {
					id: 2291,
					label: "b2b.CART.NOTE-EDIT.NUOVA-NOTA"
				},

				/**
				 * Presa in carico
				 */
				PRESAINCARICO: {
					id: 2292,
					label: "b2b.CART.NOTE-EDIT.PRESAINCARICO"
				},

				/**
				 * Task fatto
				 */
				TASK_FATTO: {
					id: 2293,
					label: "b2b.CART.NOTE-EDIT.TASK-FATTO"
				},

				/**
				 * Il testo è obbligatorio
				 */
				TESTO_OBBLIGATORIO: {
					id: 2294,
					label: "b2b.CART.NOTE-EDIT.TESTO-OBBLIGATORIO"
				},

				/**
				 * Tipo di nota
				 */
				TIPO: {
					id: 2295,
					label: "b2b.CART.NOTE-EDIT.TIPO"
				},

				/**
				 * Voucher
				 */
				VOUCHER: {
					id: 2296,
					label: "b2b.CART.NOTE-EDIT.VOUCHER"
				},

			},

			/**
			 * Content of path b2b.CART.NOTES
			 */
			NOTES: {
				/**
				 * Crea nota
				 */
				CREA_NOTA: {
					id: 2297,
					label: "b2b.CART.NOTES.CREA-NOTA"
				},

				/**
				 * Mostra {n} not{n, plural, =0{e} one{a} other{e}} Host-2-host
				 */
				MOSTRA_H2H: {
					id: 2298,
					label: "b2b.CART.NOTES.MOSTRA-H2H"
				},

				/**
				 * Nascondi {n} not{n, plural, =0{e} one{a} other{e}} Host-2-host
				 */
				NASCONDI_H2H: {
					id: 2299,
					label: "b2b.CART.NOTES.NASCONDI-H2H"
				},

				/**
				 * host-2-host
				 */
				NOTA_H2H: {
					id: 2300,
					label: "b2b.CART.NOTES.NOTA-H2H"
				},

				/**
				 * Nota visibile agenzia
				 */
				NOTA_VISIBLE_ADV: {
					id: 2301,
					label: "b2b.CART.NOTES.NOTA-VISIBLE-ADV"
				},

				/**
				 * {n} not{n, plural, =0{e} one{a} other{e}} host-2-host nascost{n, plural, =0{e} one{a} other{e}}
				 */
				NOTE_NASCOSTE: {
					id: 2302,
					label: "b2b.CART.NOTES.NOTE-NASCOSTE"
				},

				/**
				 * Seleziona tutti
				 */
				SELEZIONA_TUTTI: {
					id: 2303,
					label: "b2b.CART.NOTES.SELEZIONA-TUTTI"
				},

				/**
				 * Note Offerta
				 */
				TITLE: {
					id: 2304,
					label: "b2b.CART.NOTES.TITLE"
				},

			},

			/**
			 * Content of path b2b.CART.NUOVO-VIAGGIO
			 */
			NUOVO_VIAGGIO: {
				/**
				 * COGNOME {n}
				 */
				ADULTO_FORMAT: {
					id: 2305,
					label: "b2b.CART.NUOVO-VIAGGIO.ADULTO_FORMAT"
				},

				/**
				 * BAMBINO {n}
				 */
				BAMBINO_FORMAT: {
					id: 2306,
					label: "b2b.CART.NUOVO-VIAGGIO.BAMBINO_FORMAT"
				},

				/**
				 * Camera {n}
				 */
				CAMERA_FORMAT: {
					id: 2307,
					label: "b2b.CART.NUOVO-VIAGGIO.CAMERA_FORMAT"
				},

				/**
				 * Creazione di un nuovo viaggio
				 */
				CREAZIONE_NUOVO_VIAGGIO: {
					id: 2308,
					label: "b2b.CART.NUOVO-VIAGGIO.CREAZIONE-NUOVO-VIAGGIO"
				},

				/**
				 * Nuovo viaggio
				 */
				NOME_VIAGGIO: {
					id: 2309,
					label: "b2b.CART.NUOVO-VIAGGIO.NOME-VIAGGIO"
				},

				/**
				 * Viaggio a {dest}
				 */
				NOME_VIAGGIO_DESTINAZIONE: {
					id: 2310,
					label: "b2b.CART.NUOVO-VIAGGIO.NOME-VIAGGIO-DESTINAZIONE"
				},

				/**
				 * Creato viaggio rif. {id}
				 */
				VIAGGIO_CREATO: {
					id: 2311,
					label: "b2b.CART.NUOVO-VIAGGIO.VIAGGIO-CREATO"
				},

			},

			/**
			 * Content of path b2b.CART.PAXES
			 */
			PAXES: {
				/**
				 * Abbinamenti
				 */
				ABBINAMENTI: {
					id: 2312,
					label: "b2b.CART.PAXES.ABBINAMENTI"
				},

				/**
				 * COGNOME {n}
				 */
				ADULTO_N: {
					id: 2313,
					label: "b2b.CART.PAXES.ADULTO-N"
				},

				/**
				 * un adulto
				 */
				ADULTO_WARNING: {
					id: 2314,
					label: "b2b.CART.PAXES.ADULTO-WARNING"
				},

				/**
				 * Aggiungi passeggero
				 */
				AGGIUNGI_PASSEGGERO: {
					id: 2315,
					label: "b2b.CART.PAXES.AGGIUNGI-PASSEGGERO"
				},

				/**
				 * Annulla
				 */
				ANNULLA: {
					id: 2316,
					label: "b2b.CART.PAXES.ANNULLA"
				},

				/**
				 * BAMBINO {n}
				 */
				BAMBINO_N: {
					id: 2317,
					label: "b2b.CART.PAXES.BAMBINO-N"
				},

				/**
				 * un bambino
				 */
				BAMBINO_WARNING: {
					id: 2318,
					label: "b2b.CART.PAXES.BAMBINO-WARNING"
				},

				/**
				 * Camera {n}
				 */
				CAMERA_N: {
					id: 2319,
					label: "b2b.CART.PAXES.CAMERA-N"
				},

				/**
				 * Camere esistenti
				 */
				CAMERE_ESISTENTI: {
					id: 2320,
					label: "b2b.CART.PAXES.CAMERE-ESISTENTI"
				},

				/**
				 * Cellulare
				 */
				CELLULARE: {
					id: 2321,
					label: "b2b.CART.PAXES.CELLULARE"
				},

				/**
				 * Annulla
				 */
				CHIUDI: {
					id: 2322,
					label: "b2b.CART.PAXES.CHIUDI"
				},

				/**
				 * Codice fiscale
				 */
				CODICE_FISCALE: {
					id: 2323,
					label: "b2b.CART.PAXES.CODICE-FISCALE"
				},

				/**
				 * Cognome
				 */
				COGNOME: {
					id: 2324,
					label: "b2b.CART.PAXES.COGNOME"
				},

				/**
				 * Le modifiche apportate imposteranno i seguenti servizi come 'Prezzi da verificare'. Confermi di voler continuare?
				 */
				CONFIRM_TEXT: {
					id: 2325,
					label: "b2b.CART.PAXES.CONFIRM-TEXT"
				},

				/**
				 * Possibili variazioni prezzi
				 */
				CONFIRM_TITLE: {
					id: 2326,
					label: "b2b.CART.PAXES.CONFIRM-TITLE"
				},

				/**
				 * Dati non validi. Verifica i valori inseriti
				 */
				CONTROLLA_I_DATI: {
					id: 2327,
					label: "b2b.CART.PAXES.CONTROLLA-I-DATI"
				},

				/**
				 * Camera da aggiungere
				 */
				CREA_CAMERA: {
					id: 2328,
					label: "b2b.CART.PAXES.CREA-CAMERA"
				},

				/**
				 * Da eliminare
				 */
				DA_ELIMINARE: {
					id: 2329,
					label: "b2b.CART.PAXES.DA-ELIMINARE"
				},

				/**
				 * La data di nascita è stata confermata. Ricordarsi di salvare i dati al termine delle modifiche
				 */
				DATA_CONFERMATA: {
					id: 2330,
					label: "b2b.CART.PAXES.DATA-CONFERMATA"
				},

				/**
				 * Data di nascita
				 */
				DATA_DI_NASCITA: {
					id: 2331,
					label: "b2b.CART.PAXES.DATA-DI-NASCITA"
				},

				/**
				 * Confermi che {nome} {cognome} è effettivamente nato il {data}?
				 */
				DATA_DI_NASCITA_CORRETTA_CONFIRM_MESSAGE: {
					id: 2332,
					label: "b2b.CART.PAXES.DATA-DI-NASCITA-CORRETTA-CONFIRM-MESSAGE"
				},

				/**
				 * Conferma data di nascita
				 */
				DATA_DI_NASCITA_CORRETTA_CONFIRM_TITLE: {
					id: 2333,
					label: "b2b.CART.PAXES.DATA-DI-NASCITA-CORRETTA-CONFIRM-TITLE"
				},

				/**
				 * La data di nascita è quella inserita dal sistema per identificare {tipo}. Cliccare qui per accettare.
				 */
				DATA_DI_NASCITA_DEFAULT_WARNING: {
					id: 2334,
					label: "b2b.CART.PAXES.DATA-DI-NASCITA-DEFAULT-WARNING"
				},

				/**
				 * gg/mm/aaaa
				 */
				DATA_DI_NASCITA_PLACEHOLDER: {
					id: 2335,
					label: "b2b.CART.PAXES.DATA-DI-NASCITA-PLACEHOLDER"
				},

				/**
				 * Confermare le modifiche?
				 */
				EDIT_CONFIRM: {
					id: 2336,
					label: "b2b.CART.PAXES.EDIT_CONFIRM"
				},

				/**
				 * Segna come da eliminare
				 */
				ELIMINA: {
					id: 2337,
					label: "b2b.CART.PAXES.ELIMINA"
				},

				/**
				 * Email
				 */
				EMAIL: {
					id: 2338,
					label: "b2b.CART.PAXES.EMAIL"
				},

				/**
				 * I seguenti servizi sono confermati o in corso di prenotazione: impossibile apportare le modifiche
				 */
				HALT_TEXT: {
					id: 2339,
					label: "b2b.CART.PAXES.HALT-TEXT"
				},

				/**
				 * Operazione annullata
				 */
				HALT_TITLE: {
					id: 2340,
					label: "b2b.CART.PAXES.HALT-TITLE"
				},

				/**
				 * Ricorda di salvare dopo ogni inserimento/modifica.
				 */
				I_DATI_SONO_STATI_MODIFICATI: {
					id: 2341,
					label: "b2b.CART.PAXES.I-DATI-SONO-STATI-MODIFICATI"
				},

				/**
				 * Id
				 */
				ID: {
					id: 2342,
					label: "b2b.CART.PAXES.ID"
				},

				/**
				 * Il cognome è obbligatorio
				 */
				IL_COGNOME_E_OBBLIGATORIO: {
					id: 2343,
					label: "b2b.CART.PAXES.IL-COGNOME-E-OBBLIGATORIO"
				},

				/**
				 * La data di nascita è obbligatoria
				 */
				LA_DATA_DI_NASCITA_E_OBBLIGATORIA: {
					id: 2344,
					label: "b2b.CART.PAXES.LA-DATA-DI-NASCITA-E-OBBLIGATORIA"
				},

				/**
				 * Luogo di nascita
				 */
				LUOGO_DI_NASCITA: {
					id: 2345,
					label: "b2b.CART.PAXES.LUOGO-DI-NASCITA"
				},

				/**
				 * Camere/Abbinamenti viaggiatori
				 */
				MODIFICA_CAMERE: {
					id: 2346,
					label: "b2b.CART.PAXES.MODIFICA-CAMERE"
				},

				/**
				 * Modifiche salvate
				 */
				MODIFICHE_SALVATE: {
					id: 2347,
					label: "b2b.CART.PAXES.MODIFICHE-SALVATE"
				},

				/**
				 * Nazionalità 
				 */
				NAZIONALITA: {
					id: 2348,
					label: "b2b.CART.PAXES.NAZIONALITA"
				},

				/**
				 * [Nuova] Camera {n}
				 */
				NEW_CAMERA_N: {
					id: 2349,
					label: "b2b.CART.PAXES.NEW-CAMERA-N"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 2350,
					label: "b2b.CART.PAXES.NOME"
				},

				/**
				 * Viaggiatori
				 */
				NOMI_PASSEGGERI: {
					id: 2351,
					label: "b2b.CART.PAXES.NOMI-PASSEGGERI"
				},

				/**
				 * -- Non assegnata --
				 */
				NON_ASSEGNATA: {
					id: 2352,
					label: "b2b.CART.PAXES.NON-ASSEGNATA"
				},

				/**
				 * Numero passaporto
				 */
				NUMERO_PASSAPORTO: {
					id: 2353,
					label: "b2b.CART.PAXES.NUMERO-PASSAPORTO"
				},

				/**
				 * Nuovo
				 */
				NUOVO: {
					id: 2354,
					label: "b2b.CART.PAXES.NUOVO"
				},

				/**
				 * Paese emissione passaporto
				 */
				PAESE_EMISSIONE_PASSAPORTO: {
					id: 2355,
					label: "b2b.CART.PAXES.PAESE-EMISSIONE-PASSAPORTO"
				},

				/**
				 * Procedi comunque
				 */
				PROCEDI: {
					id: 2356,
					label: "b2b.CART.PAXES.PROCEDI"
				},

				/**
				 * Ripristina
				 */
				RIPRISTINA: {
					id: 2357,
					label: "b2b.CART.PAXES.RIPRISTINA"
				},

				/**
				 * Salva le modifiche
				 */
				SALVA_LE_MODIFICHE: {
					id: 2358,
					label: "b2b.CART.PAXES.SALVA-LE-MODIFICHE"
				},

				/**
				 * Scad. passaporto
				 */
				SCADENZA_PASSAPORTO: {
					id: 2359,
					label: "b2b.CART.PAXES.SCADENZA-PASSAPORTO"
				},

				/**
				 * Titolo
				 */
				TITOLO: {
					id: 2360,
					label: "b2b.CART.PAXES.TITOLO"
				},

				/**
				 * Verifica i dati inseriti. Sono presenti voci mancanti i non valide
				 */
				VERIFICA_I_DATI: {
					id: 2361,
					label: "b2b.CART.PAXES.VERIFICA-I-DATI"
				},

				/**
				 * ABB
				 */
				ABBINAMENTI_SHORT: {
					id: 6251,
					label: "b2b.CART.PAXES.ABBINAMENTI-SHORT"
				},

			},

			/**
			 * Content of path b2b.CART.PAXES-CHOOSER
			 */
			PAXES_CHOOSER: {
				/**
				 * Seleziona tutti i viaggiatori di questa camera
				 */
				AGGIUNGI_CAMERA: {
					id: 2362,
					label: "b2b.CART.PAXES-CHOOSER.AGGIUNGI-CAMERA"
				},

				/**
				 * Abbinamenti
				 */
				AGGIUNGI_RIMVUOVI: {
					id: 2363,
					label: "b2b.CART.PAXES-CHOOSER.AGGIUNGI-RIMVUOVI"
				},

				/**
				 * Selezionare almeno un viaggiatore
				 */
				ALMENO_UN_PAX: {
					id: 2364,
					label: "b2b.CART.PAXES-CHOOSER.ALMENO-UN-PAX"
				},

				/**
				 * Seleziona i viaggiatori per {serviceName}
				 */
				HELP_TEXT: {
					id: 2365,
					label: "b2b.CART.PAXES-CHOOSER.HELP-TEXT"
				},

				/**
				 * Viaggiatori
				 */
				PASSEGGERI: {
					id: 2366,
					label: "b2b.CART.PAXES-CHOOSER.PASSEGGERI"
				},

				/**
				 * Aggiungi assicurazione
				 */
				TITLE: {
					id: 2367,
					label: "b2b.CART.PAXES-CHOOSER.TITLE"
				},

				/**
				 * Deseleziona tutti i viaggiatori di questa camera
				 */
				TOGLI_CAMERA: {
					id: 2368,
					label: "b2b.CART.PAXES-CHOOSER.TOGLI-CAMERA"
				},

			},

			/**
			 * Penali di cancellazione
			 */
			PENALI_DI_CANCELLAZIONE: {
				id: 2369,
				label: "b2b.CART.PENALI-DI-CANCELLAZIONE"
			},

			/**
			 * Dettaglio per servizi del massimo rischio penale
			 */
			PENALTIES: {
				id: 2370,
				label: "b2b.CART.PENALTIES.TITLE"
			},

			/**
			 * Content of path b2b.CART.PLANNER
			 */
			PLANNER: {
				/**
				 * Adulti
				 */
				ADULTI: {
					id: 2371,
					label: "b2b.CART.PLANNER.ADULTI"
				},

				/**
				 * Aeroporto di arrivo
				 */
				AEROPORTO_DI_ARRIVO: {
					id: 2372,
					label: "b2b.CART.PLANNER.AEROPORTO-DI-ARRIVO"
				},

				/**
				 * Aeroporto di partenza
				 */
				AEROPORTO_DI_PARTENZA: {
					id: 2373,
					label: "b2b.CART.PLANNER.AEROPORTO-DI-PARTENZA"
				},

				/**
				 * Aeroporto di riferimento
				 */
				AEROPORTO_DI_RIFERIMENTO: {
					id: 2374,
					label: "b2b.CART.PLANNER.AEROPORTO-DI-RIFERIMENTO"
				},

				/**
				 * -- Qualsiasi --
				 */
				AEROPORTO_NON_SPECIFICATO: {
					id: 2375,
					label: "b2b.CART.PLANNER.AEROPORTO-NON-SPECIFICATO"
				},

				/**
				 * Aggiungi camera
				 */
				AGGIUNGI_CAMERA: {
					id: 2376,
					label: "b2b.CART.PLANNER.AGGIUNGI-CAMERA"
				},

				/**
				 * arrivo il giorno
				 */
				ARRIVA_IL_GIORNO: {
					id: 2377,
					label: "b2b.CART.PLANNER.ARRIVA-IL-GIORNO"
				},

				/**
				 * Arrivo in {tt}
				 */
				ARRIVO_IN: {
					id: 2378,
					label: "b2b.CART.PLANNER.ARRIVO-IN"
				},

				/**
				 * Arrivo in aereo. La località fa parte dell'itinerario aereo per la ricerca voli
				 */
				ARRIVO_IN_AEREO: {
					id: 2379,
					label: "b2b.CART.PLANNER.ARRIVO-IN-AEREO"
				},

				/**
				 * Arrivo in aereo. La località non fa parte dell'itinerario aereo per la ricerca voli
				 */
				ARRIVO_IN_AEREO_NO_RICERCA: {
					id: 2380,
					label: "b2b.CART.PLANNER.ARRIVO-IN-AEREO-NO-RICERCA"
				},

				/**
				 * Il percorso tra la località precedente e questa avviene in auto
				 */
				ARRIVO_IN_AUTO: {
					id: 2381,
					label: "b2b.CART.PLANNER.ARRIVO-IN-AUTO"
				},

				/**
				 * Arrivo con modalità indefinita
				 */
				ARRIVO_INDEFINITO: {
					id: 2382,
					label: "b2b.CART.PLANNER.ARRIVO-INDEFINITO"
				},

				/**
				 * Bambini
				 */
				BAMBINI: {
					id: 2383,
					label: "b2b.CART.PLANNER.BAMBINI"
				},

				/**
				 * Budget a persona
				 */
				BUDGET_PERSONA: {
					id: 2384,
					label: "b2b.CART.PLANNER.BUDGET-PERSONA"
				},

				/**
				 * Camere
				 */
				CAMERE: {
					id: 2385,
					label: "b2b.CART.PLANNER.CAMERE"
				},

				/**
				 * Cancellazione della tappa {nomeTappa}
				 */
				CANCELLA_TAPPA: {
					id: 2386,
					label: "b2b.CART.PLANNER.CANCELLA-TAPPA"
				},

				/**
				 * Sei sicuro di voler cancellare la tappa n.{numeroTappa} {nomeTappa}?
				 */
				CANCELLA_TAPPA_SICURO: {
					id: 2387,
					label: "b2b.CART.PLANNER.CANCELLA-TAPPA-SICURO"
				},

				/**
				 * Categoria hotel
				 */
				CATEGORIA_HOTEL: {
					id: 2388,
					label: "b2b.CART.PLANNER.CATEGORIA-HOTEL"
				},

				/**
				 * Cerca in base a
				 */
				CERCA_IN_BASE_A: {
					id: 2389,
					label: "b2b.CART.PLANNER.CERCA-IN-BASE-A"
				},

				/**
				 * Località vicine
				 */
				CERCA_LOCALITA_VICINE: {
					id: 2390,
					label: "b2b.CART.PLANNER.CERCA-LOCALITA-VICINE"
				},

				/**
				 * Autocompletamento
				 */
				CERCA_SERVIZI: {
					id: 2391,
					label: "b2b.CART.PLANNER.CERCA-SERVIZI"
				},

				/**
				 * Impostazione dei criteri usati durante la compilazione automatica del viaggio dove verranno cercati hotel e altri servizi per completare la tappa.
				 */
				CERCA_SERVIZI_HELP: {
					id: 2392,
					label: "b2b.CART.PLANNER.CERCA-SERVIZI-HELP"
				},

				/**
				 * chiudi le finestre info
				 */
				CHIUDI_INFO_WIN: {
					id: 2393,
					label: "b2b.CART.PLANNER.CHIUDI-INFO-WIN"
				},

				/**
				 * Data di arrivo
				 */
				DATA_ARRIVO: {
					id: 2394,
					label: "b2b.CART.PLANNER.DATA-ARRIVO"
				},

				/**
				 * Data di partenza obbligatoria
				 */
				DATA_PARTENZA_OBBLIGATORIA: {
					id: 2395,
					label: "b2b.CART.PLANNER.DATA-PARTENZA-OBBLIGATORIA"
				},

				/**
				 * gg/mm/aaaa
				 */
				DATA_PARTENZA_PLACEHOLDER: {
					id: 2396,
					label: "b2b.CART.PLANNER.DATA-PARTENZA-PLACEHOLDER"
				},

				/**
				 * Le date di questa tappa sono diverse da quelle precedenti
				 */
				DATE_MODIFICATE: {
					id: 2397,
					label: "b2b.CART.PLANNER.DATE-MODIFICATE"
				},

				/**
				 * Le date di questa tappa sono diverse da quelle precedenti. Le date assolute di queste tappe non sono modificabili in quanto dipendono dalla partenza di un tour
				 */
				DATE_MODIFICATE_ERRORE: {
					id: 2398,
					label: "b2b.CART.PLANNER.DATE-MODIFICATE-ERRORE"
				},

				/**
				 * Le date assolute di queste tappe non sono modificabili in quanto dipendono dalla partenza di un tour
				 */
				DATE_NON_MODIFICABILI: {
					id: 2399,
					label: "b2b.CART.PLANNER.DATE-NON-MODIFICABILI"
				},

				/**
				 * Durata in notti
				 */
				DURATA_NOTTI: {
					id: 2400,
					label: "b2b.CART.PLANNER.DURATA-NOTTI"
				},

				/**
				 * Elimina camera
				 */
				ELIMINA_CAMERA: {
					id: 2401,
					label: "b2b.CART.PLANNER.ELIMINA-CAMERA"
				},

				/**
				 * Elimina tappa
				 */
				ELIMINA_TAPPA: {
					id: 2402,
					label: "b2b.CART.PLANNER.ELIMINA-TAPPA"
				},

				/**
				 * Escludi dall'itinerario per la ricerca voli
				 */
				ESCLUDI_RICERCA_VOLI: {
					id: 2403,
					label: "b2b.CART.PLANNER.ESCLUDI-RICERCA-VOLI"
				},

				/**
				 * Content of path b2b.CART.PLANNER.GIORNO-OFFSET
				 */
				GIORNO_OFFSET: {
					/**
					 * il giorno dopo (volo verso est)
					 */
					IL_GIORNO_DOPO: {
						id: 2404,
						label: "b2b.CART.PLANNER.GIORNO-OFFSET.IL-GIORNO-DOPO"
					},

					/**
					 * due giorni dopo (volo verso est)
					 */
					IL_GIORNO_DOPO_DUE: {
						id: 2405,
						label: "b2b.CART.PLANNER.GIORNO-OFFSET.IL-GIORNO-DOPO-DUE"
					},

					/**
					 * il giorno esatto
					 */
					IL_GIORNO_ESATTO: {
						id: 2406,
						label: "b2b.CART.PLANNER.GIORNO-OFFSET.IL-GIORNO-ESATTO"
					},

					/**
					 * il giorno prima (volo verso ovest)
					 */
					IL_GIORNO_PRIMA: {
						id: 2407,
						label: "b2b.CART.PLANNER.GIORNO-OFFSET.IL-GIORNO-PRIMA"
					},

				},

				/**
				 * Trascina la tappa nella posizione desiderata
				 */
				HANDLE_TITLE: {
					id: 2408,
					label: "b2b.CART.PLANNER.HANDLE-TITLE"
				},

				/**
				 * il giorno
				 */
				IL_GIORNO: {
					id: 2409,
					label: "b2b.CART.PLANNER.IL-GIORNO"
				},

				/**
				 * Imposta
				 */
				IMPOSTA: {
					id: 2410,
					label: "b2b.CART.PLANNER.IMPOSTA"
				},

				/**
				 * Imposta in blocco le opzioni di itinerario
				 */
				IMPOSTA_IN_BLOCCO_OPZIONI: {
					id: 2411,
					label: "b2b.CART.PLANNER.IMPOSTA-IN-BLOCCO-OPZIONI"
				},

				/**
				 * In volo
				 */
				IN_VOLO: {
					id: 2412,
					label: "b2b.CART.PLANNER.IN-VOLO"
				},

				/**
				 * Includi gli hotel nei pressi degli aeroporti
				 */
				INCLUDI_HOTEL_AEROPORTO: {
					id: 2413,
					label: "b2b.CART.PLANNER.INCLUDI-HOTEL-AEROPORTO"
				},

				/**
				 * Includi servizi non rimborsabili
				 */
				INCLUDI_NON_RIMBORSABILI: {
					id: 2414,
					label: "b2b.CART.PLANNER.INCLUDI-NON-RIMBORSABILI"
				},

				/**
				 * Includi servizi con pagamento immediato
				 */
				INCLUDI_PAGAMENTO_IMMEDIATO: {
					id: 2415,
					label: "b2b.CART.PLANNER.INCLUDI-PAGAMENTO-IMMEDIATO"
				},

				/**
				 * Includi servizi su richiesta
				 */
				INCLUDI_SU_RICHIESTA: {
					id: 2416,
					label: "b2b.CART.PLANNER.INCLUDI-SU-RICHIESTA"
				},

				/**
				 * Itinerario salvato
				 */
				ITINERARIO_SALVATO: {
					id: 2417,
					label: "b2b.CART.PLANNER.ITINERARIO-SALVATO"
				},

				/**
				 * Content of path b2b.CART.PLANNER.LBL-ARRIVO-GIORNO
				 */
				LBL_ARRIVO_GIORNO: {
					/**
					 * il giorno dopo
					 */
					DOPO: {
						id: 2418,
						label: "b2b.CART.PLANNER.LBL-ARRIVO-GIORNO.DOPO"
					},

					/**
					 * due giorni dopo
					 */
					DUE_DOPO: {
						id: 2419,
						label: "b2b.CART.PLANNER.LBL-ARRIVO-GIORNO.DUE-DOPO"
					},

					/**
					 * lo stesso giorno
					 */
					ESATTO: {
						id: 2420,
						label: "b2b.CART.PLANNER.LBL-ARRIVO-GIORNO.ESATTO"
					},

					/**
					 * il giorno prima
					 */
					PRIMA: {
						id: 2421,
						label: "b2b.CART.PLANNER.LBL-ARRIVO-GIORNO.PRIMA"
					},

				},

				/**
				 * Luogo partenza
				 */
				LUOGO_PARTENZA: {
					id: 2422,
					label: "b2b.CART.PLANNER.LUOGO-PARTENZA"
				},

				/**
				 * Luogo termine
				 */
				LUOGO_TERMINE: {
					id: 2423,
					label: "b2b.CART.PLANNER.LUOGO-TERMINE"
				},

				/**
				 * Content of path b2b.CART.PLANNER.MAPPA
				 */
				MAPPA: {
					/**
					 * a
					 */
					A_LUOGO: {
						id: 2424,
						label: "b2b.CART.PLANNER.MAPPA.A-LUOGO"
					},

					/**
					 * Aggiungi tappa di
					 */
					AGGIUNGI_TAPPA_DI_X_NOTTI: {
						id: 2425,
						label: "b2b.CART.PLANNER.MAPPA.AGGIUNGI-TAPPA-DI-X-NOTTI"
					},

					/**
					 * da
					 */
					DAL_GIORNO: {
						id: 2426,
						label: "b2b.CART.PLANNER.MAPPA.DAL-GIORNO"
					},

					/**
					 * dopo la tappa n.{n} - {luogo}
					 */
					DOPO_LA_TAPPA_A: {
						id: 2427,
						label: "b2b.CART.PLANNER.MAPPA.DOPO-LA-TAPPA-A"
					},

					/**
					 * In Aereo
					 */
					IN_AEREO: {
						id: 2428,
						label: "b2b.CART.PLANNER.MAPPA.IN-AEREO"
					},

					/**
					 * Aggiungi la tappa con arrivo in aereo
					 */
					IN_AEREO_HELP: {
						id: 2429,
						label: "b2b.CART.PLANNER.MAPPA.IN-AEREO-HELP"
					},

					/**
					 * In auto
					 */
					IN_AUTO: {
						id: 2430,
						label: "b2b.CART.PLANNER.MAPPA.IN-AUTO"
					},

					/**
					 * Aggiungi la tappa con arrivo in auto
					 */
					IN_AUTO_HELP: {
						id: 2431,
						label: "b2b.CART.PLANNER.MAPPA.IN-AUTO-HELP"
					},

					/**
					 * In questa località non sono disponibili servizi per pernottare
					 */
					NO_PERNOTTAMENTO: {
						id: 2432,
						label: "b2b.CART.PLANNER.MAPPA.NO-PERNOTTAMENTO"
					},

					/**
					 * nott{notti, plural, =0{i} one{e} other{i}}
					 */
					NOTTI: {
						id: 2433,
						label: "b2b.CART.PLANNER.MAPPA.NOTTI"
					},

				},

				/**
				 * Mostra località vicine
				 */
				MOSTRA_LOCALITA_VICINE: {
					id: 2434,
					label: "b2b.CART.PLANNER.MOSTRA-LOCALITA-VICINE"
				},

				/**
				 * Nascondi località vicine
				 */
				NASCONDI_LOCALITA_VICINE: {
					id: 2435,
					label: "b2b.CART.PLANNER.NASCONDI-LOCALITA-VICINE"
				},

				/**
				 * Nulla da impostare
				 */
				NULLA_DA_IMPOSTARE: {
					id: 2436,
					label: "b2b.CART.PLANNER.NULLA-DA-IMPOSTARE"
				},

				/**
				 * Nuova tappa aggiunta all'itinerario
				 */
				NUOVA_TAPPA: {
					id: 2437,
					label: "b2b.CART.PLANNER.NUOVA-TAPPA"
				},

				/**
				 * Content of path b2b.CART.PLANNER.OPZIONI-ARRIVO-IN
				 */
				OPZIONI_ARRIVO_IN: {
					/**
					 * dopo
					 */
					GIORNO_DOPO: {
						id: 2438,
						label: "b2b.CART.PLANNER.OPZIONI-ARRIVO-IN.GIORNO-DOPO"
					},

					/**
					 * due dopo
					 */
					GIORNO_DUE_DOPO: {
						id: 2439,
						label: "b2b.CART.PLANNER.OPZIONI-ARRIVO-IN.GIORNO-DUE-DOPO"
					},

					/**
					 * prima
					 */
					GIORNO_PRIMA: {
						id: 2440,
						label: "b2b.CART.PLANNER.OPZIONI-ARRIVO-IN.GIORNO-PRIMA"
					},

					/**
					 * stesso
					 */
					GIORNO_STESSO: {
						id: 2441,
						label: "b2b.CART.PLANNER.OPZIONI-ARRIVO-IN.GIORNO-STESSO"
					},

					/**
					 * Arrivo in
					 */
					label: {
						id: 2442,
						label: "b2b.CART.PLANNER.OPZIONI-ARRIVO-IN.label"
					},

				},

				/**
				 * Nessuna opzione di autocompletamento
				 */
				OPZIONI_AUTOCOMPLETAMENTO_OFF: {
					id: 2443,
					label: "b2b.CART.PLANNER.OPZIONI-AUTOCOMPLETAMENTO-OFF"
				},

				/**
				 * Opzioni data
				 */
				OPZIONI_DATA: {
					id: 2444,
					label: "b2b.CART.PLANNER.OPZIONI-DATA"
				},

				/**
				 * Opzioni data arrivo
				 */
				OPZIONI_DATA_TITOLO: {
					id: 2445,
					label: "b2b.CART.PLANNER.OPZIONI-DATA-TITOLO"
				},

				/**
				 * Per voli che passano la linea di cambio data
				 */
				OPZIONI_DATA_TITOLO_HELP: {
					id: 2446,
					label: "b2b.CART.PLANNER.OPZIONI-DATA-TITOLO-HELP"
				},

				/**
				 * Opzioni impostate. Ricordati di salvare le modifiche!
				 */
				OPZIONI_IMPOSATATE: {
					id: 2447,
					label: "b2b.CART.PLANNER.OPZIONI-IMPOSATATE"
				},

				/**
				 * Opzioni itinerario
				 */
				OPZIONI_ITINERARIO: {
					id: 2448,
					label: "b2b.CART.PLANNER.OPZIONI-ITINERARIO"
				},

				/**
				 * Opzioni di Autocompletamento
				 */
				OPZIONI_SERVIZI: {
					id: 2449,
					label: "b2b.CART.PLANNER.OPZIONI-SERVIZI"
				},

				/**
				 * Opzioni voli
				 */
				OPZIONI_VOLI: {
					id: 2450,
					label: "b2b.CART.PLANNER.OPZIONI-VOLI"
				},

				/**
				 * Pacchetto
				 */
				PACCHETTO: {
					id: 2451,
					label: "b2b.CART.PLANNER.PACCHETTO"
				},

				/**
				 * Partenza da
				 */
				PARTENZA_DA: {
					id: 2452,
					label: "b2b.CART.PLANNER.PARTENZA-DA"
				},

				/**
				 * Content of path b2b.CART.PLANNER.PARTENZA-ERROR
				 */
				PARTENZA_ERROR: {
					/**
					 * Non posso spostare la partenza oltre l'inizio di un tour con tappe bloccate. {extra}.
					 */
					MESSAGE: {
						id: 2453,
						label: "b2b.CART.PLANNER.PARTENZA-ERROR.MESSAGE"
					},

					/**
					 * Attenzione! Data selezionata errata
					 */
					TITLE: {
						id: 2454,
						label: "b2b.CART.PLANNER.PARTENZA-ERROR.TITLE"
					},

				},

				/**
				 * Partenza in {tt}
				 */
				PARTENZA_IN: {
					id: 2455,
					label: "b2b.CART.PLANNER.PARTENZA-IN"
				},

				/**
				 * Selfdrive
				 */
				SELFDRIVE: {
					id: 2456,
					label: "b2b.CART.PLANNER.SELFDRIVE"
				},

				/**
				 * Servizi da cercare
				 */
				SERVIZI_DA_CERCARE: {
					id: 2457,
					label: "b2b.CART.PLANNER.SERVIZI-DA-CERCARE"
				},

				/**
				 * al {dTo}
				 */
				SOGGIORNO_AL: {
					id: 2458,
					label: "b2b.CART.PLANNER.SOGGIORNO-AL"
				},

				/**
				 * Dal {dFrom}
				 */
				SOGGIORNO_DAL: {
					id: 2459,
					label: "b2b.CART.PLANNER.SOGGIORNO-DAL"
				},

				/**
				 * Soggiorno libero
				 */
				SOGGIORNO_LIBERO: {
					id: 2460,
					label: "b2b.CART.PLANNER.SOGGIORNO-LIBERO"
				},

				/**
				 * Numero minimo di stelle dell'hotel per la compilazione automatica del viaggio
				 */
				STELLE_MINIME: {
					id: 2461,
					label: "b2b.CART.PLANNER.STELLE-MINIME"
				},

				/**
				 * Queste tappe devono essere consecutive tra loro, non è possibile inserirne un'altra
				 */
				TAPPE_CONSECUTIVE: {
					id: 2462,
					label: "b2b.CART.PLANNER.TAPPE-CONSECUTIVE"
				},

				/**
				 * Fine del viaggio
				 */
				TERMINE: {
					id: 2463,
					label: "b2b.CART.PLANNER.TERMINE"
				},

				/**
				 * Tour
				 */
				TOUR: {
					id: 2464,
					label: "b2b.CART.PLANNER.TOUR"
				},

				/**
				 * Tipo di trasporto
				 */
				TRASPORTO: {
					id: 2465,
					label: "b2b.CART.PLANNER.TRASPORTO"
				},

				/**
				 * Content of path b2b.CART.PLANNER.WIDGET
				 */
				WIDGET: {
					/**
					 * Aggiungi
					 */
					AGGIUNGI: {
						id: 2466,
						label: "b2b.CART.PLANNER.WIDGET.AGGIUNGI"
					},

					/**
					 * Aggiungi notte in volo
					 */
					AGGIUNGI_NOTTE_IN_VOLO: {
						id: 2467,
						label: "b2b.CART.PLANNER.WIDGET.AGGIUNGI-NOTTE-IN-VOLO"
					},

					/**
					 * Aggiungi tappa di {notti} nott{notti, plural, =0{i} one{e} other{i}}:
					 */
					AGGIUNGI_NOTTI: {
						id: 2468,
						label: "b2b.CART.PLANNER.WIDGET.AGGIUNGI-NOTTI"
					},

					/**
					 * Aggiungi tappa
					 */
					AGGIUNGI_TAPPA: {
						id: 2469,
						label: "b2b.CART.PLANNER.WIDGET.AGGIUNGI-TAPPA"
					},

					/**
					 * Annulla
					 */
					ANNULLA: {
						id: 2470,
						label: "b2b.CART.PLANNER.WIDGET.ANNULLA"
					},

					/**
					 * Cambia la data di partenza
					 */
					CAMBIA_DATA_PARTENZA: {
						id: 2471,
						label: "b2b.CART.PLANNER.WIDGET.CAMBIA-DATA-PARTENZA"
					},

					/**
					 * Completa con voli e servizi
					 */
					COMPLETA: {
						id: 2472,
						label: "b2b.CART.PLANNER.WIDGET.COMPLETA"
					},

					/**
					 * Completare il viaggio con servizi a terra
					 */
					COMPLETA_HOTEL: {
						id: 2473,
						label: "b2b.CART.PLANNER.WIDGET.COMPLETA-HOTEL"
					},

					/**
					 * Completare il viaggio con i voli
					 */
					COMPLETA_VOLI: {
						id: 2474,
						label: "b2b.CART.PLANNER.WIDGET.COMPLETA-VOLI"
					},

					/**
					 * Conferma creazione viaggio
					 */
					CONFERMA_CREAZIONE: {
						id: 2475,
						label: "b2b.CART.PLANNER.WIDGET.CONFERMA-CREAZIONE"
					},

					/**
					 * Proseguire con la creazione del viaggio?
					 */
					CONFERMA_MESSAGGIO: {
						id: 2476,
						label: "b2b.CART.PLANNER.WIDGET.CONFERMA-MESSAGGIO"
					},

					/**
					 * Crea il viaggio
					 */
					CREA_IL_VIAGGIO: {
						id: 2477,
						label: "b2b.CART.PLANNER.WIDGET.CREA-IL-VIAGGIO"
					},

					/**
					 * Crea il viaggio
					 */
					CREA_VIAGGIO: {
						id: 2478,
						label: "b2b.CART.PLANNER.WIDGET.CREA-VIAGGIO"
					},

					/**
					 * Salva il viaggio ed eventualmente completalo automaticamente
					 */
					CREA_VIAGGIO_HELP: {
						id: 2479,
						label: "b2b.CART.PLANNER.WIDGET.CREA-VIAGGIO-HELP"
					},

					/**
					 * Estendi il viaggio
					 */
					ESTENDI_IL_VIAGGIO: {
						id: 2480,
						label: "b2b.CART.PLANNER.WIDGET.ESTENDI-IL-VIAGGIO"
					},

					/**
					 * Content of path b2b.CART.PLANNER.WIDGET.HELP
					 */
					HELP: {
						/**
						 * Annulla le modifiche in sospseso
						 */
						ANNULLA: {
							id: 2481,
							label: "b2b.CART.PLANNER.WIDGET.HELP.ANNULLA"
						},

						/**
						 * Completa il viaggio
						 */
						COMPLETA: {
							id: 2482,
							label: "b2b.CART.PLANNER.WIDGET.HELP.COMPLETA"
						},

						/**
						 * Aggiungi una notte in volo all'itinerario
						 */
						IN_VOLO: {
							id: 2483,
							label: "b2b.CART.PLANNER.WIDGET.HELP.IN-VOLO"
						},

						/**
						 * Salva l'itinerario
						 */
						SALVA: {
							id: 2484,
							label: "b2b.CART.PLANNER.WIDGET.HELP.SALVA"
						},

						/**
						 * Aggiungi una tappa all'itinerario
						 */
						TAPPA: {
							id: 2485,
							label: "b2b.CART.PLANNER.WIDGET.HELP.TAPPA"
						},

						/**
						 * Aggiungi una tappa aerea all'itinerario
						 */
						TAPPA_AEREA: {
							id: 2486,
							label: "b2b.CART.PLANNER.WIDGET.HELP.TAPPA-AEREA"
						},

					},

					/**
					 * Imposta come data di partenza
					 */
					IMPOSTA_PARTENZA: {
						id: 2487,
						label: "b2b.CART.PLANNER.WIDGET.IMPOSTA-PARTENZA"
					},

					/**
					 * Notte in volo
					 */
					IN_VOLO: {
						id: 2488,
						label: "b2b.CART.PLANNER.WIDGET.IN-VOLO"
					},

					/**
					 * Modifica Itinerario
					 */
					MODIFICA: {
						id: 2489,
						label: "b2b.CART.PLANNER.WIDGET.MODIFICA"
					},

					/**
					 * Luoghi vicini in caricamento ...
					 */
					NEARBY_LOADING: {
						id: 2490,
						label: "b2b.CART.PLANNER.WIDGET.NEARBY-LOADING"
					},

					/**
					 * Nel luogo:
					 */
					NEL_LUOGO: {
						id: 2491,
						label: "b2b.CART.PLANNER.WIDGET.NEL-LUOGO"
					},

					/**
					 * Salva
					 */
					SALVA: {
						id: 2492,
						label: "b2b.CART.PLANNER.WIDGET.SALVA"
					},

					/**
					 * -- Scegli un luogo --
					 */
					SCEGLI_UN_LUOGO: {
						id: 2493,
						label: "b2b.CART.PLANNER.WIDGET.SCEGLI-UN-LUOGO"
					},

					/**
					 * Soggiorno libero
					 */
					SOGGIORNO_LIBERO: {
						id: 2494,
						label: "b2b.CART.PLANNER.WIDGET.SOGGIORNO-LIBERO"
					},

					/**
					 * Tappa terrestre
					 */
					TAPPA: {
						id: 2495,
						label: "b2b.CART.PLANNER.WIDGET.TAPPA"
					},

					/**
					 * Tappa aerea
					 */
					TAPPA_AEREA: {
						id: 2496,
						label: "b2b.CART.PLANNER.WIDGET.TAPPA-AEREA"
					},

					/**
					 * Tappa via auto
					 */
					TAPPA_AUTO: {
						id: 2497,
						label: "b2b.CART.PLANNER.WIDGET.TAPPA-AUTO"
					},

					/**
					 * Opzioni di completamento viaggio
					 */
					OPT_COMPLETAMENTO_VIAGGIO: {
						id: 6245,
						label: "b2b.CART.PLANNER.WIDGET.OPT-COMPLETAMENTO-VIAGGIO"
					},

					/**
					 * Tipo servizio
					 */
					CREA_TIPO_SERVIZIO: {
						id: 6246,
						label: "b2b.CART.PLANNER.WIDGET.CREA-TIPO-SERVIZIO"
					},

					/**
					 * Categoria hotel
					 */
					CREA_CATEGORIA_HOTEL: {
						id: 6247,
						label: "b2b.CART.PLANNER.WIDGET.CREA-CATEGORIA-HOTEL"
					},

					/**
					 * Cerca tra
					 */
					CREA_CATEGORIA_HOTEL_CERCA_TRA: {
						id: 6248,
						label: "b2b.CART.PLANNER.WIDGET.CREA-CATEGORIA-HOTEL-CERCA-TRA"
					},

					/**
					 * Crea
					 */
					CREA_AUTO_COMPLETE: {
						id: 6249,
						label: "b2b.CART.PLANNER.WIDGET.CREA-AUTO-COMPLETE"
					},

					/**
					 * CART.PLANNER.WIDGET.CREA-NO-AUTO-COMPLETE
					 */
					CREA_NO_AUTO_COMPLETE: {
						id: 6535,
						label: "b2b.CART.PLANNER.WIDGET.CREA-NO-AUTO-COMPLETE"
					},

				},

				/**
				 * Inverti Tappe
				 */
				INVERTI_TAPPE: {
					id: 5985,
					label: "b2b.CART.PLANNER.INVERTI-TAPPE"
				},

				/**
				 * Non è possibile invertire le tappe se ci sono servizi o hotel associati.
				 */
				INVERTI_TAPPE_DISABILITATO_TOOLTIP: {
					id: 5986,
					label: "b2b.CART.PLANNER.INVERTI-TAPPE-DISABILITATO-TOOLTIP"
				},

				/**
				 * Luoghi suggeriti...
				 */
				LUOGHI_SUGGERITI: {
					id: 6084,
					label: "b2b.CART.PLANNER.LUOGHI-SUGGERITI"
				},

				/**
				 * dal {dateDal} al {dateAl}
				 */
				TAPPA_DAL_AL: {
					id: 6085,
					label: "b2b.CART.PLANNER.TAPPA-DAL-AL"
				},

				/**
				 * Seleziona itinerario consigliato
				 */
				SELEZIONA_ITINERARIO_CONSIGLIATO: {
					id: 6255,
					label: "b2b.CART.PLANNER.SELEZIONA-ITINERARIO-CONSIGLIATO"
				},

				/**
				 * Aggiungi dopo tappa
				 */
				AGGIUNGI_DOPO_TAPPA: {
					id: 6256,
					label: "b2b.CART.PLANNER.AGGIUNGI-DOPO-TAPPA"
				},

				/**
				 * Sostituisci itinerario
				 */
				SOSTITUISCI_ITINERARIO: {
					id: 6257,
					label: "b2b.CART.PLANNER.SOSTITUISCI-ITINERARIO"
				},

				/**
				 * Autocompletamento
				 */
				OPZIONI_COMPLETAMENTO: {
					id: 6317,
					label: "b2b.CART.PLANNER.OPZIONI-COMPLETAMENTO"
				},

				/**
				 * Mostra o nasconde opzioni autocompletamento
				 */
				OPZIONI_COMPLETAMENTO_LBL: {
					id: 6318,
					label: "b2b.CART.PLANNER.OPZIONI-COMPLETAMENTO-LBL"
				},

			},

			/**
			 * Content of path b2b.CART.PNR-EDIT
			 */
			PNR_EDIT: {
				/**
				 * A
				 */
				A: {
					id: 2498,
					label: "b2b.CART.PNR-EDIT.A"
				},

				/**
				 * Abbinamento
				 */
				ABBINAMENTO: {
					id: 2499,
					label: "b2b.CART.PNR-EDIT.ABBINAMENTO"
				},

				/**
				 * Aggiungi segmento
				 */
				AGGIUNGI_SEGMENTO: {
					id: 2500,
					label: "b2b.CART.PNR-EDIT.AGGIUNGI-SEGMENTO"
				},

				/**
				 * Arrivo
				 */
				ARRIVO: {
					id: 2501,
					label: "b2b.CART.PNR-EDIT.ARRIVO"
				},

				/**
				 * Bagagli inclusi
				 */
				BAGAGLI_INCLUSI: {
					id: 2502,
					label: "b2b.CART.PNR-EDIT.BAGAGLI-INCLUSI"
				},

				/**
				 * Chiudi
				 */
				CHIUDI: {
					id: 2503,
					label: "b2b.CART.PNR-EDIT.CHIUDI"
				},

				/**
				 * Chiudi dopo il salvataggio
				 */
				CHIUDI_DOPO_SALVATAGGIO: {
					id: 2504,
					label: "b2b.CART.PNR-EDIT.CHIUDI-DOPO-SALVATAGGIO"
				},

				/**
				 * Classe
				 */
				CLASSE: {
					id: 2505,
					label: "b2b.CART.PNR-EDIT.CLASSE"
				},

				/**
				 * Costo tariffa
				 */
				COSTO_TARIFFA: {
					id: 2506,
					label: "b2b.CART.PNR-EDIT.COSTO-TARIFFA"
				},

				/**
				 * Costo tasse
				 */
				COSTO_TASSE: {
					id: 2507,
					label: "b2b.CART.PNR-EDIT.COSTO-TASSE"
				},

				/**
				 * Da
				 */
				DA: {
					id: 2508,
					label: "b2b.CART.PNR-EDIT.DA"
				},

				/**
				 * Data
				 */
				DATA: {
					id: 2509,
					label: "b2b.CART.PNR-EDIT.DATA"
				},

				/**
				 * Data autocancellazione
				 */
				DATA_AUTOCANCELLAZIONE: {
					id: 2510,
					label: "b2b.CART.PNR-EDIT.DATA-AUTOCANCELLAZIONE"
				},

				/**
				 * Fare basis
				 */
				FARE_BASIS: {
					id: 2511,
					label: "b2b.CART.PNR-EDIT.FARE-BASIS"
				},

				/**
				 * Fornitore
				 */
				FORNTITORE: {
					id: 2512,
					label: "b2b.CART.PNR-EDIT.FORNTITORE"
				},

				/**
				 * FXD
				 */
				FXD: {
					id: 2513,
					label: "b2b.CART.PNR-EDIT.FXD"
				},

				/**
				 * ID
				 */
				ID: {
					id: 2514,
					label: "b2b.CART.PNR-EDIT.ID"
				},

				/**
				 * Importa
				 */
				IMPORT: {
					id: 2515,
					label: "b2b.CART.PNR-EDIT.IMPORT"
				},

				/**
				 * Importa nuovo itinerario aereo
				 */
				IMPORT_TITLE: {
					id: 2516,
					label: "b2b.CART.PNR-EDIT.IMPORT-TITLE"
				},

				/**
				 * Itinerario
				 */
				ITINERARIO: {
					id: 2517,
					label: "b2b.CART.PNR-EDIT.ITINERARIO"
				},

				/**
				 * Last Ticket Date
				 */
				LAST_TICKET_DATE: {
					id: 2518,
					label: "b2b.CART.PNR-EDIT.LAST-TICKET-DATE"
				},

				/**
				 * -- Nessuno --
				 */
				NESSUNO: {
					id: 2519,
					label: "b2b.CART.PNR-EDIT.NESSUNO"
				},

				/**
				 * Nessun PNR in pratica
				 */
				NESSUNO_IN_PRATICA: {
					id: 2520,
					label: "b2b.CART.PNR-EDIT.NESSUNO-IN-PRATICA"
				},

				/**
				 * Note
				 */
				NOTE: {
					id: 2521,
					label: "b2b.CART.PNR-EDIT.NOTE"
				},

				/**
				 * Nr
				 */
				NR: {
					id: 2522,
					label: "b2b.CART.PNR-EDIT.NR"
				},

				/**
				 * Nuovo segmento
				 */
				NUOVO_SEGMENTO: {
					id: 2523,
					label: "b2b.CART.PNR-EDIT.NUOVO-SEGMENTO"
				},

				/**
				 * Operato
				 */
				OPERATO: {
					id: 2524,
					label: "b2b.CART.PNR-EDIT.OPERATO"
				},

				/**
				 * Ore
				 */
				ORE: {
					id: 2525,
					label: "b2b.CART.PNR-EDIT.ORE"
				},

				/**
				 * Partenza
				 */
				PARTENZA: {
					id: 2526,
					label: "b2b.CART.PNR-EDIT.PARTENZA"
				},

				/**
				 * Pax nr.
				 */
				PAX_NR: {
					id: 2527,
					label: "b2b.CART.PNR-EDIT.PAX-NR"
				},

				/**
				 * Pnr
				 */
				PNR: {
					id: 2528,
					label: "b2b.CART.PNR-EDIT.PNR"
				},

				/**
				 * Posti
				 */
				POSTI: {
					id: 2529,
					label: "b2b.CART.PNR-EDIT.POSTI"
				},

				/**
				 * Stato
				 */
				STATO: {
					id: 2530,
					label: "b2b.CART.PNR-EDIT.STATO"
				},

				/**
				 * Tariffa
				 */
				TARIFFA: {
					id: 2531,
					label: "b2b.CART.PNR-EDIT.TARIFFA"
				},

				/**
				 * Tariffa TO
				 */
				TARIFFA_TO: {
					id: 2532,
					label: "b2b.CART.PNR-EDIT.TARIFFA-TO"
				},

				/**
				 * Tariffe
				 */
				TARIFFE: {
					id: 2533,
					label: "b2b.CART.PNR-EDIT.TARIFFE"
				},

				/**
				 * Tasse
				 */
				TASSE: {
					id: 2534,
					label: "b2b.CART.PNR-EDIT.TASSE"
				},

				/**
				 * Testo
				 */
				TESTO: {
					id: 2535,
					label: "b2b.CART.PNR-EDIT.TESTO"
				},

				/**
				 * Tipo Memo
				 */
				TIPO_MEMO: {
					id: 2536,
					label: "b2b.CART.PNR-EDIT.TIPO-MEMO"
				},

				/**
				 * Tipo Pax
				 */
				TIPO_PAX: {
					id: 2537,
					label: "b2b.CART.PNR-EDIT.TIPO-PAX"
				},

				/**
				 * Pnr
				 */
				TITLE: {
					id: 2538,
					label: "b2b.CART.PNR-EDIT.TITLE"
				},

				/**
				 * Voli
				 */
				VOLI: {
					id: 2539,
					label: "b2b.CART.PNR-EDIT.VOLI"
				},

				/**
				 * Volo
				 */
				VOLO: {
					id: 2540,
					label: "b2b.CART.PNR-EDIT.VOLO"
				},

				/**
				 * Volo {id}
				 */
				VOLO_ID: {
					id: 2541,
					label: "b2b.CART.PNR-EDIT.VOLO-ID"
				},

				/**
				 * Volo salvato
				 */
				VOLO_SALVATO: {
					id: 2542,
					label: "b2b.CART.PNR-EDIT.VOLO-SALVATO"
				},

			},

			/**
			 * Content of path b2b.CART.PRICE-VERIFY-RESULTS
			 */
			PRICE_VERIFY_RESULTS: {
				/**
				 * Camera
				 */
				CAMERA: {
					id: 2543,
					label: "b2b.CART.PRICE-VERIFY-RESULTS.CAMERA"
				},

				/**
				 * Conferma comunque
				 */
				CONFERMA_CMQ: {
					id: 2544,
					label: "b2b.CART.PRICE-VERIFY-RESULTS.CONFERMA-CMQ"
				},

				/**
				 * Data
				 */
				DATA: {
					id: 2545,
					label: "b2b.CART.PRICE-VERIFY-RESULTS.DATA"
				},

				/**
				 * Disponibiltà
				 */
				DISPONIBILITA: {
					id: 2546,
					label: "b2b.CART.PRICE-VERIFY-RESULTS.DISPONIBILITA"
				},

				/**
				 * Esito verifica prezzi
				 */
				ESITO: {
					id: 2547,
					label: "b2b.CART.PRICE-VERIFY-RESULTS.ESITO"
				},

				/**
				 * Impossibile verificare
				 */
				IMPOSSIBILE_VERIFICARE: {
					id: 2548,
					label: "b2b.CART.PRICE-VERIFY-RESULTS.IMPOSSIBILE-VERIFICARE"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 2549,
					label: "b2b.CART.PRICE-VERIFY-RESULTS.LUOGO"
				},

				/**
				 * Prezzo
				 */
				PREZZO: {
					id: 2550,
					label: "b2b.CART.PRICE-VERIFY-RESULTS.PREZZO"
				},

				/**
				 * Servizi non disponibili
				 */
				SERVIZI_NON_DISPONIBILI: {
					id: 2551,
					label: "b2b.CART.PRICE-VERIFY-RESULTS.SERVIZI-NON-DISPONIBILI"
				},

				/**
				 * Servizi non validi
				 */
				SERVIZI_NON_VALIDI: {
					id: 2552,
					label: "b2b.CART.PRICE-VERIFY-RESULTS.SERVIZI-NON-VALIDI"
				},

				/**
				 * Servizio
				 */
				SERVIZIO: {
					id: 2553,
					label: "b2b.CART.PRICE-VERIFY-RESULTS.SERVIZIO"
				},

				/**
				 * Stanza
				 */
				STANZA: {
					id: 2554,
					label: "b2b.CART.PRICE-VERIFY-RESULTS.STANZA"
				},

			},

			/**
			 * Content of path b2b.CART.QUOTATION
			 */
			QUOTATION: {
				/**
				 * Content of path b2b.CART.QUOTATION.BUDGET
				 */
				BUDGET: {
					/**
					 * Oltre {from}
					 */
					FROM: {
						id: 2555,
						label: "b2b.CART.QUOTATION.BUDGET.FROM"
					},

					/**
					 * {from} - {to}
					 */
					FROM_TO: {
						id: 2556,
						label: "b2b.CART.QUOTATION.BUDGET.FROM-TO"
					},

					/**
					 * Indefinito
					 */
					INDEFINITO: {
						id: 2557,
						label: "b2b.CART.QUOTATION.BUDGET.INDEFINITO"
					},

				},

				/**
				 * Richiesta preventivo
				 */
				RICHIEDI_PREVENTIVO: {
					id: 2558,
					label: "b2b.CART.QUOTATION.RICHIEDI-PREVENTIVO"
				},

			},

			/**
			 * Content of path b2b.CART.ROOM-CHOOSER
			 */
			ROOM_CHOOSER: {
				/**
				 * Scegli l'abbinamento per il servizio {nome}
				 */
				HELP_TEXT: {
					id: 2559,
					label: "b2b.CART.ROOM-CHOOSER.HELP-TEXT"
				},

				/**
				 * Abbinamento
				 */
				TITLE: {
					id: 2560,
					label: "b2b.CART.ROOM-CHOOSER.TITLE"
				},

			},

			/**
			 * Content of path b2b.CART.ROOMS-EDIT
			 */
			ROOMS_EDIT: {
				/**
				 * Aggiungi camera
				 */
				AGGIUNGI_CAMERA: {
					id: 2561,
					label: "b2b.CART.ROOMS-EDIT.AGGIUNGI-CAMERA"
				},

				/**
				 * Annulla modifiche
				 */
				ANNULLA: {
					id: 2562,
					label: "b2b.CART.ROOMS-EDIT.ANNULLA"
				},

				/**
				 * -- Non impostato --
				 */
				BEDDING_NON_INDICATO: {
					id: 2563,
					label: "b2b.CART.ROOMS-EDIT.BEDDING-NON-INDICATO"
				},

				/**
				 * Matrimoniale
				 */
				CAMERA_MATRIMONIALE: {
					id: 2564,
					label: "b2b.CART.ROOMS-EDIT.CAMERA-MATRIMONIALE"
				},

				/**
				 * Camera {n}
				 */
				CAMERA_N: {
					id: 2565,
					label: "b2b.CART.ROOMS-EDIT.CAMERA-N"
				},

				/**
				 * -- Camera vuota --
				 */
				CAMERA_VUOTA: {
					id: 2566,
					label: "b2b.CART.ROOMS-EDIT.CAMERA-VUOTA"
				},

				/**
				 * Camere salvate
				 */
				CAMERE_SALVATE: {
					id: 2567,
					label: "b2b.CART.ROOMS-EDIT.CAMERE-SALVATE"
				},

				/**
				 * Chiudi
				 */
				CHIUDI: {
					id: 2568,
					label: "b2b.CART.ROOMS-EDIT.CHIUDI"
				},

				/**
				 * Le modifiche apportate imposteranno i seguenti servizi come 'Prezzi da verificare'. Confermi di voler continuare?
				 */
				CONFIRM_TEXT: {
					id: 2569,
					label: "b2b.CART.ROOMS-EDIT.CONFIRM-TEXT"
				},

				/**
				 * Possibili variazioni prezzi
				 */
				CONFIRM_TITLE: {
					id: 2570,
					label: "b2b.CART.ROOMS-EDIT.CONFIRM-TITLE"
				},

				/**
				 * I seguenti servizi sono confermati o in corso di prenotazione: impossibile apportare le modifiche
				 */
				HALT_TEXT: {
					id: 2571,
					label: "b2b.CART.ROOMS-EDIT.HALT-TEXT"
				},

				/**
				 * Operazione annullata
				 */
				HALT_TITLE: {
					id: 2572,
					label: "b2b.CART.ROOMS-EDIT.HALT-TITLE"
				},

				/**
				 * Inserire un nome per la camera
				 */
				NOME_CAMERA_OBBLIGATORIO: {
					id: 2573,
					label: "b2b.CART.ROOMS-EDIT.NOME-CAMERA-OBBLIGATORIO"
				},

				/**
				 * Procedi comunque
				 */
				PROCEDI: {
					id: 2574,
					label: "b2b.CART.ROOMS-EDIT.PROCEDI"
				},

				/**
				 * Salva
				 */
				SALVA: {
					id: 2575,
					label: "b2b.CART.ROOMS-EDIT.SALVA"
				},

				/**
				 * Dati modificati. Ricordati di salvare.
				 */
				SALVA_HELP: {
					id: 2576,
					label: "b2b.CART.ROOMS-EDIT.SALVA-HELP"
				},

				/**
				 * Trascinare i viaggiatori tra camere per spostarli. Trascinare i viaggiatori dalla camera '{PRIMO}' per copiarli.
				 */
				TESTO_HELP: {
					id: 2577,
					label: "b2b.CART.ROOMS-EDIT.TESTO-HELP"
				},

			},

			/**
			 * Content of path b2b.CART.SEARCH
			 */
			SEARCH: {
				/**
				 * Content of path b2b.CART.SEARCH.FILTERS-WIDGET
				 */
				FILTERS_WIDGET: {
					/**
					 * Partenza al
					 */
					AL: {
						id: 2578,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.AL"
					},

					/**
					 * Annulla tutti i filtri
					 */
					ANNULLA_FILTRI: {
						id: 2579,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.ANNULLA-FILTRI"
					},

					/**
					 * Cerca per nome
					 */
					CERCA_PER_NOME: {
						id: 2580,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.CERCA-PER-NOME"
					},

					/**
					 * Comprimi elenco
					 */
					COMPRIMI_ELENCO: {
						id: 2581,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.COMPRIMI-ELENCO"
					},

					/**
					 * Da {min} a {max} €
					 */
					DA_A_PREZZO: {
						id: 2582,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.DA-A-PREZZO"
					},

					/**
					 * Partenza dal
					 */
					DAL: {
						id: 2583,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.DAL"
					},

					/**
					 * Destinazione
					 */
					DESTINAZIONE: {
						id: 2584,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.DESTINAZIONE"
					},

					/**
					 * Durata
					 */
					DURATA: {
						id: 2585,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.DURATA"
					},

					/**
					 * Espandi elenco
					 */
					ESPANDI_ELENCO: {
						id: 2586,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.ESPANDI-ELENCO"
					},

					/**
					 * Fino a {prezzo} €
					 */
					FINO_A: {
						id: 2587,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.FINO-A"
					},

					/**
					 * Fuori catalogo
					 */
					FUORI_CATALOGO: {
						id: 2588,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.FUORI-CATALOGO"
					},

					/**
					 * giorni
					 */
					GIORNI: {
						id: 2589,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.GIORNI"
					},

					/**
					 * Luogo visitato
					 */
					LUOGO_VISITATO: {
						id: 2590,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.LUOGO-VISITATO"
					},

					/**
					 * Mostra i fuori catalogo
					 */
					MOSTRA_FUORI_CATALOGO: {
						id: 2591,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.MOSTRA-FUORI-CATALOGO"
					},

					/**
					 * Nascondi i fuori catalogo
					 */
					NASCONDI_FUORI_CATALOGO: {
						id: 2592,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.NASCONDI-FUORI-CATALOGO"
					},

					/**
					 * Nascondi non rimborsabili
					 */
					NASCONDI_NON_RIMBORSABILI: {
						id: 2593,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.NASCONDI-NON-RIMBORSABILI"
					},

					/**
					 * o più
					 */
					O_PIU: {
						id: 2594,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.O-PIU"
					},

					/**
					 * oltre {prezzo} €
					 */
					OLTRE: {
						id: 2595,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.OLTRE"
					},

					/**
					 * Prezzo per persona
					 */
					PREZZO: {
						id: 2596,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.PREZZO"
					},

					/**
					 * Rimuovi i filtri di tipo {categoria}
					 */
					RIMUOVI_CATEGORIA: {
						id: 2597,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.RIMUOVI-CATEGORIA"
					},

					/**
					 * Rimuovi filtro per nome
					 */
					RIMUOVI_FILTRO_NOME: {
						id: 2598,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.RIMUOVI-FILTRO-NOME"
					},

					/**
					 * Luogo di partenza
					 */
					LUOGO_PARTENZA: {
						id: 6258,
						label: "b2b.CART.SEARCH.FILTERS-WIDGET.LUOGO-PARTENZA"
					},

				},

				/**
				 * Content of path b2b.CART.SEARCH.HOTELS-SIDEBAR
				 */
				HOTELS_SIDEBAR: {
					/**
					 * Al
					 */
					AL: {
						id: 2599,
						label: "b2b.CART.SEARCH.HOTELS-SIDEBAR.AL"
					},

					/**
					 * Dal
					 */
					DAL: {
						id: 2600,
						label: "b2b.CART.SEARCH.HOTELS-SIDEBAR.DAL"
					},

					/**
					 * Hotel a
					 */
					HOTEL_A: {
						id: 2601,
						label: "b2b.CART.SEARCH.HOTELS-SIDEBAR.HOTEL-A"
					},

					/**
					 * Ricerca per ...
					 */
					RICERCA_PER: {
						id: 2602,
						label: "b2b.CART.SEARCH.HOTELS-SIDEBAR.RICERCA-PER"
					},

				},

				/**
				 * Content of path b2b.CART.SEARCH.LIST-SUMMARY
				 */
				LIST_SUMMARY: {
					/**
					 * Nome
					 */
					FILTRO_NOME: {
						id: 2603,
						label: "b2b.CART.SEARCH.LIST-SUMMARY.FILTRO-NOME"
					},

					/**
					 * Rimuovi filtro
					 */
					RIMUOVI_FILTRO: {
						id: 2604,
						label: "b2b.CART.SEARCH.LIST-SUMMARY.RIMUOVI-FILTRO"
					},

					/**
					 * {servizi} servizi{servizi, plural, =0{} one{o} other{}} trovat{servizi, plural, =0{i} one{o} other{i}}.
					 */
					VISUALIZZATI: {
						id: 2605,
						label: "b2b.CART.SEARCH.LIST-SUMMARY.VISUALIZZATI"
					},

				},

				/**
				 * Dati in caricamento ...
				 */
				LOADING_DATA: {
					id: 2606,
					label: "b2b.CART.SEARCH.LOADING-DATA"
				},

				/**
				 * Nessun risultato trovato
				 */
				NO_RESULT_FOUND: {
					id: 2607,
					label: "b2b.CART.SEARCH.NO-RESULT-FOUND"
				},

				/**
				 * Content of path b2b.CART.SEARCH.RENTALS-SIDEBAR
				 */
				RENTALS_SIDEBAR: {
					/**
					 * Rilascio
					 */
					DROPOFF: {
						id: 2608,
						label: "b2b.CART.SEARCH.RENTALS-SIDEBAR.DROPOFF"
					},

					/**
					 * Seleziona sul calendario la data e la località per determinare il ritiro e rilascio
					 */
					HELP_TEXT: {
						id: 2609,
						label: "b2b.CART.SEARCH.RENTALS-SIDEBAR.HELP-TEXT"
					},

					/**
					 * Noleggi
					 */
					NOLEGGI: {
						id: 2610,
						label: "b2b.CART.SEARCH.RENTALS-SIDEBAR.NOLEGGI"
					},

					/**
					 * Prelievo
					 */
					PICKUP: {
						id: 2611,
						label: "b2b.CART.SEARCH.RENTALS-SIDEBAR.PICKUP"
					},

					/**
					 * Scegliere la data di rilascio sul calendario
					 */
					SCEGLI_DROPOFF: {
						id: 2612,
						label: "b2b.CART.SEARCH.RENTALS-SIDEBAR.SCEGLI-DROPOFF"
					},

					/**
					 * Scegliere la data di prelievo sul calendario
					 */
					SCEGLI_PICKUP: {
						id: 2613,
						label: "b2b.CART.SEARCH.RENTALS-SIDEBAR.SCEGLI-PICKUP"
					},

				},

				/**
				 * Content of path b2b.CART.SEARCH.SEARCH-PANEL
				 */
				SEARCH_PANEL: {
					/**
					 * Ricerca prezzi
					 */
					AVVIA_RICERCA: {
						id: 2614,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.AVVIA-RICERCA"
					},

					/**
					 * Avvio ricerca...
					 */
					AVVIO_RICERCA: {
						id: 2615,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.AVVIO-RICERCA"
					},

					/**
					 * Azzera date
					 */
					CLEAR_CALENDARIO: {
						id: 2616,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.CLEAR-CALENDARIO"
					},

					/**
					 * Confermi l'expire (cancellazione della cache) per {fornitore}?
					 */
					CONFIRM_EXPIRE: {
						id: 2617,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.CONFIRM-EXPIRE"
					},

					/**
					 * Riavvia ricerca veloce
					 */
					DESC_RIAVVIA_RICERCA_CON_CACHE: {
						id: 2618,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.DESC:RIAVVIA-RICERCA-CON-CACHE"
					},

					/**
					 * Riavvia ricerca
					 */
					DESC_RIAVVIA_RICERCA_NO_CACHE: {
						id: 2619,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.DESC:RIAVVIA-RICERCA-NO-CACHE"
					},

					/**
					 * La verifica dei prezzi è terminata, i prezzi attuali verranno sostituiti da quelli più recenti
					 */
					NUOVI_PREZZI_MESSAGE: {
						id: 2620,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.NUOVI-PREZZI-MESSAGE"
					},

					/**
					 * Aggiornamento prezzi terminato
					 */
					NUOVI_PREZZI_TITLE: {
						id: 2621,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.NUOVI-PREZZI-TITLE"
					},

					/**
					 * Ulteriori risultati potrebbero essere disponibili a breve
					 */
					POSSIBILI_ULTERIORI_RISULTATI: {
						id: 2622,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.POSSIBILI-ULTERIORI-RISULTATI"
					},

					/**
					 * Riavvia ricerca
					 */
					RIAVVIA_RICERCA: {
						id: 2623,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.RIAVVIA-RICERCA"
					},

					/**
					 * Riavvia ricerca
					 */
					RIAVVIA_RICERCA_CON_CACHE: {
						id: 2624,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.RIAVVIA-RICERCA-CON-CACHE"
					},

					/**
					 * Ricerca avanzata
					 */
					RIAVVIA_RICERCA_NO_CACHE: {
						id: 2625,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.RIAVVIA-RICERCA-NO-CACHE"
					},

					/**
					 * Ricalcolo prezzi in corso
					 */
					RICALCOLO_PREZZI: {
						id: 2626,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.RICALCOLO-PREZZI"
					},

					/**
					 * Alcuni prezzi visualizzati non sono più validi ed è in corso la verifica.
					 */
					RICALCOLO_PREZZI_HELP: {
						id: 2627,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.RICALCOLO-PREZZI-HELP"
					},

					/**
					 * Ricerca completata
					 */
					RICERCA_COMPLETATA: {
						id: 2628,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.RICERCA-COMPLETATA"
					},

					/**
					 * Ricerca in corso
					 */
					RICERCA_IN_CORSO: {
						id: 2629,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.RICERCA-IN-CORSO"
					},

					/**
					 * Statistiche ricerca
					 */
					STATISTICHE_RICERCA: {
						id: 2630,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.STATISTICHE-RICERCA"
					},

					/**
					 * Timeout
					 */
					TIMEOUT: {
						id: 2631,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.TIMEOUT"
					},

					/**
					 * Sono disponibili ulteriori risultati. Vuoi aggiungerli?
					 */
					ULTERIORI_PREZZI_MESSAGE: {
						id: 2632,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.ULTERIORI-PREZZI-MESSAGE"
					},

					/**
					 * Ricerca completata
					 */
					ULTERIORI_PREZZI_TITLE: {
						id: 2633,
						label: "b2b.CART.SEARCH.SEARCH-PANEL.ULTERIORI-PREZZI-TITLE"
					},

				},

				/**
				 * Ricerca in corso ...
				 */
				SEARCH_RUNNING: {
					id: 2634,
					label: "b2b.CART.SEARCH.SEARCH-RUNNING"
				},

				/**
				 * Content of path b2b.CART.SEARCH.SERVICE-BOX
				 */
				SERVICE_BOX: {
					/**
					 * Aggiungi al viaggio
					 */
					AGGIUNTA_IMMEDIATA: {
						id: 2635,
						label: "b2b.CART.SEARCH.SERVICE-BOX.AGGIUNTA-IMMEDIATA"
					},

					/**
					 * Categoria
					 */
					CATEGORIA: {
						id: 2636,
						label: "b2b.CART.SEARCH.SERVICE-BOX.CATEGORIA"
					},

					/**
					 * Chiudi la mappa
					 */
					CHIUDI_DETTAGLIO: {
						id: 2637,
						label: "b2b.CART.SEARCH.SERVICE-BOX.CHIUDI-DETTAGLIO"
					},

					/**
					 * Consigliato
					 */
					CONSIGLIATO: {
						id: 2638,
						label: "b2b.CART.SEARCH.SERVICE-BOX.CONSIGLIATO"
					},

					/**
					 * Dettaglio prezzi
					 */
					DETTAGLIO_PREZZI: {
						id: 2639,
						label: "b2b.CART.SEARCH.SERVICE-BOX.DETTAGLIO-PREZZI"
					},

					/**
					 * Hotel extra catalogo
					 */
					HOTEL_EXTRA_CATALOGO: {
						id: 2640,
						label: "b2b.CART.SEARCH.SERVICE-BOX.HOTEL-EXTRA-CATALOGO"
					},

					/**
					 * In ristrutturazione
					 */
					IN_RISTRUTTURAZIONE: {
						id: 2641,
						label: "b2b.CART.SEARCH.SERVICE-BOX.IN-RISTRUTTURAZIONE"
					},

					/**
					 * Mappa
					 */
					MAPPA: {
						id: 2642,
						label: "b2b.CART.SEARCH.SERVICE-BOX.MAPPA"
					},

					/**
					 * Mostra sulla mappa
					 */
					MOSTRA_SU_MAPPA: {
						id: 2643,
						label: "b2b.CART.SEARCH.SERVICE-BOX.MOSTRA-SU-MAPPA"
					},

					/**
					 * Non disponibile
					 */
					NON_DISPONIBILE: {
						id: 2644,
						label: "b2b.CART.SEARCH.SERVICE-BOX.NON-DISPONIBILE"
					},

					/**
					 * Nuovo
					 */
					NUOVO: {
						id: 2645,
						label: "b2b.CART.SEARCH.SERVICE-BOX.NUOVO"
					},

					/**
					 *  
					 */
					PREZZI_DA: {
						id: 2646,
						label: "b2b.CART.SEARCH.SERVICE-BOX.PREZZI-DA"
					},

					/**
					 * Prezzo da verificare
					 */
					PREZZO_DA_VERIFICARE: {
						id: 2647,
						label: "b2b.CART.SEARCH.SERVICE-BOX.PREZZO-DA-VERIFICARE"
					},

					/**
					 * Prossime partenze
					 */
					PROSSIME_PARTENZE: {
						id: 2648,
						label: "b2b.CART.SEARCH.SERVICE-BOX.PROSSIME-PARTENZE"
					},

					/**
					 * Su richiesta
					 */
					SU_RICHIESTA: {
						id: 2649,
						label: "b2b.CART.SEARCH.SERVICE-BOX.SU-RICHIESTA"
					},

					/**
					 * Tappe
					 */
					TAPPE: {
						id: 2650,
						label: "b2b.CART.SEARCH.SERVICE-BOX.TAPPE"
					},

					/**
					 * Vantaggi
					 */
					VANTAGGI: {
						id: 2651,
						label: "b2b.CART.SEARCH.SERVICE-BOX.VANTAGGI"
					},

				},

				/**
				 * Content of path b2b.CART.SEARCH.SORT-BUTTON
				 */
				SORT_BUTTON: {
					/**
					 * Inverti ordinamento
					 */
					INVERTI_ORDINAMENTO: {
						id: 2652,
						label: "b2b.CART.SEARCH.SORT-BUTTON.INVERTI-ORDINAMENTO"
					},

					/**
					 * Nome
					 */
					NOME: {
						id: 2653,
						label: "b2b.CART.SEARCH.SORT-BUTTON.NOME"
					},

					/**
					 * Ordina in base al nome
					 */
					NOME_HELP: {
						id: 2654,
						label: "b2b.CART.SEARCH.SORT-BUTTON.NOME-HELP"
					},

					/**
					 * Ordina per
					 */
					ORDINA_PER: {
						id: 2655,
						label: "b2b.CART.SEARCH.SORT-BUTTON.ORDINA-PER"
					},

					/**
					 * Popolarità
					 */
					POPLARITA: {
						id: 2656,
						label: "b2b.CART.SEARCH.SORT-BUTTON.POPLARITA"
					},

					/**
					 * Ordina in base alla poplarità
					 */
					POPLARITA_HELP: {
						id: 2657,
						label: "b2b.CART.SEARCH.SORT-BUTTON.POPLARITA-HELP"
					},

					/**
					 * Prezzo
					 */
					PREZZO: {
						id: 2658,
						label: "b2b.CART.SEARCH.SORT-BUTTON.PREZZO"
					},

					/**
					 * Ordina in base al prezzo
					 */
					PREZZO_HELP: {
						id: 2659,
						label: "b2b.CART.SEARCH.SORT-BUTTON.PREZZO-HELP"
					},

				},

				/**
				 * Content of path b2b.CART.SEARCH.TOURS-SIDEBAR
				 */
				TOURS_SIDEBAR: {
					/**
					 * Partenze dal {dataDal} al {dataAl}
					 */
					PARTENZE_DAL_AL: {
						id: 2660,
						label: "b2b.CART.SEARCH.TOURS-SIDEBAR.PARTENZE-DAL-AL"
					},

					/**
					 * Partenze del {data}
					 */
					PARTENZE_IL_GIORNO: {
						id: 2661,
						label: "b2b.CART.SEARCH.TOURS-SIDEBAR.PARTENZE-IL-GIORNO"
					},

					/**
					 * Selfdrive a
					 */
					SELFDRIVE_A: {
						id: 2662,
						label: "b2b.CART.SEARCH.TOURS-SIDEBAR.SELFDRIVE-A"
					},

					/**
					 * Tour e crociere a
					 */
					TOUR_E_CROCIERE_A: {
						id: 2663,
						label: "b2b.CART.SEARCH.TOURS-SIDEBAR.TOUR-E-CROCIERE-A"
					},

				},

				/**
				 * Content of path b2b.CART.SEARCH.TRANSFERT-SIDEBAR
				 */
				TRANSFERT_SIDEBAR: {
					/**
					 * a
					 */
					A_DATA: {
						id: 2664,
						label: "b2b.CART.SEARCH.TRANSFERT-SIDEBAR.A-DATA"
					},

					/**
					 * da
					 */
					DA_DATA: {
						id: 2665,
						label: "b2b.CART.SEARCH.TRANSFERT-SIDEBAR.DA-DATA"
					},

					/**
					 * Trasferimenti e visite a
					 */
					TRASFERIMENTI_E_VISITE_A: {
						id: 2666,
						label: "b2b.CART.SEARCH.TRANSFERT-SIDEBAR.TRASFERIMENTI-E-VISITE-A"
					},

				},

				/**
				 * Visualizza come lista
				 */
				VISUALIZZA_COME_LISTA: {
					id: 2667,
					label: "b2b.CART.SEARCH.VISUALIZZA-COME-LISTA"
				},

				/**
				 * Visualizza su mappa
				 */
				VISUALIZZA_SU_MAPPA: {
					id: 2668,
					label: "b2b.CART.SEARCH.VISUALIZZA-SU-MAPPA"
				},

			},

			/**
			 * Content of path b2b.CART.SERVICE-EDIT
			 */
			SERVICE_EDIT: {
				/**
				 * Abbinamento
				 */
				ABBINAMENTO: {
					id: 2669,
					label: "b2b.CART.SERVICE-EDIT.ABBINAMENTO"
				},

				/**
				 * Acconto
				 */
				ACCONTO: {
					id: 2670,
					label: "b2b.CART.SERVICE-EDIT.ACCONTO"
				},

				/**
				 * Accordo
				 */
				ACCORDO: {
					id: 2671,
					label: "b2b.CART.SERVICE-EDIT.ACCORDO"
				},

				/**
				 * Adulti
				 */
				ADULTI: {
					id: 2672,
					label: "b2b.CART.SERVICE-EDIT.ADULTI"
				},

				/**
				 * Agg. manuale
				 */
				AGGIORNAMENTO_MANUALE: {
					id: 2673,
					label: "b2b.CART.SERVICE-EDIT.AGGIORNAMENTO-MANUALE"
				},

				/**
				 * Anticipo al fornitore
				 */
				ANTICIPO_AL_FORNITORE: {
					id: 2674,
					label: "b2b.CART.SERVICE-EDIT.ANTICIPO-AL-FORNITORE"
				},

				/**
				 * Apri articolo in intranet
				 */
				APRI_ARTICOLO: {
					id: 2675,
					label: "b2b.CART.SERVICE-EDIT.APRI-ARTICOLO"
				},

				/**
				 * Articolo ID={id}
				 */
				ARTICOLO_ID: {
					id: 2676,
					label: "b2b.CART.SERVICE-EDIT.ARTICOLO-ID"
				},

				/**
				 * Bambini
				 */
				BAMBINI: {
					id: 2677,
					label: "b2b.CART.SERVICE-EDIT.BAMBINI"
				},

				/**
				 * Bedding
				 */
				BEDDING: {
					id: 2678,
					label: "b2b.CART.SERVICE-EDIT.BEDDING"
				},

				/**
				 * Biglietteria
				 */
				BIGLIETTERIA: {
					id: 2679,
					label: "b2b.CART.SERVICE-EDIT.BIGLIETTERIA"
				},

				/**
				 * Booking reference fornitore
				 */
				BOOKING_REFERENCE_FORNITORE: {
					id: 2680,
					label: "b2b.CART.SERVICE-EDIT.BOOKING-REFERENCE-FORNITORE"
				},

				/**
				 * Booking reference Naar
				 */
				BOOKING_REFERENCE_NAAR: {
					id: 2681,
					label: "b2b.CART.SERVICE-EDIT.BOOKING-REFERENCE-NAAR"
				},

				/**
				 * Calcola
				 */
				CALCOLA: {
					id: 2682,
					label: "b2b.CART.SERVICE-EDIT.CALCOLA"
				},

				/**
				 * Cambio applicato
				 */
				CAMBIO_APPLICATO: {
					id: 2683,
					label: "b2b.CART.SERVICE-EDIT.CAMBIO-APPLICATO"
				},

				/**
				 * Cambio articolo penali di cancellazione
				 */
				CAMBIO_ARTICOLO_PENALI_DI_CANCELLAZIONE: {
					id: 2684,
					label: "b2b.CART.SERVICE-EDIT.CAMBIO-ARTICOLO-PENALI-DI-CANCELLAZIONE"
				},

				/**
				 * L'accordo scelto {accordo} è in valuta {nuovaValuta}.I costi attuali sono di {costi} {vecchiaValuta}.I prezzi verranno ricalcolati come {costi} {nuovaValuta} con un cambio di {cambio}. Vuoi procedere?
				 */
				CAMBIO_VALUTA_MESSAGE: {
					id: 2685,
					label: "b2b.CART.SERVICE-EDIT.CAMBIO-VALUTA-MESSAGE"
				},

				/**
				 * Accordo con valuta diversa dal precedente
				 */
				CAMBIO_VALUTA_TITLE: {
					id: 2686,
					label: "b2b.CART.SERVICE-EDIT.CAMBIO-VALUTA-TITLE"
				},

				/**
				 * Camera
				 */
				CAMERA: {
					id: 2687,
					label: "b2b.CART.SERVICE-EDIT.CAMERA"
				},

				/**
				 * Cancellato
				 */
				CANCELLATO: {
					id: 2688,
					label: "b2b.CART.SERVICE-EDIT.CANCELLATO"
				},

				/**
				 * Caratteristica
				 */
				CARATTERISTICA: {
					id: 2689,
					label: "b2b.CART.SERVICE-EDIT.CARATTERISTICA"
				},

				/**
				 * Classe
				 */
				CLASSE: {
					id: 2690,
					label: "b2b.CART.SERVICE-EDIT.CLASSE"
				},

				/**
				 * Cliente di riferimento / guidatore
				 */
				CLIENTE_RIFERIMENTO_GUIDATORE: {
					id: 2691,
					label: "b2b.CART.SERVICE-EDIT.CLIENTE-RIFERIMENTO-GUIDATORE"
				},

				/**
				 * Commissioni
				 */
				COMMISSIONI: {
					id: 2692,
					label: "b2b.CART.SERVICE-EDIT.COMMISSIONI"
				},

				/**
				 * Compilare il servizio
				 */
				COMPILARE_IL_SERVIZIO: {
					id: 2693,
					label: "b2b.CART.SERVICE-EDIT.COMPILARE-IL-SERVIZIO"
				},

				/**
				 * Indefinita
				 */
				CONTABILIZZAZIONE_INDEFINITA: {
					id: 2694,
					label: "b2b.CART.SERVICE-EDIT.CONTABILIZZAZIONE-INDEFINITA"
				},

				/**
				 * Copia testo in nota custom
				 */
				COPIA_IN_NOTA_CUSTOM: {
					id: 2695,
					label: "b2b.CART.SERVICE-EDIT.COPIA-IN-NOTA-CUSTOM"
				},

				/**
				 * Costo
				 */
				COSTO: {
					id: 2696,
					label: "b2b.CART.SERVICE-EDIT.COSTO"
				},

				/**
				 * Costo adulti precedente
				 */
				COSTO_ADULTI_PRECEDENTE: {
					id: 2697,
					label: "b2b.CART.SERVICE-EDIT.COSTO-ADULTI-PRECEDENTE"
				},

				/**
				 * Costo bambini precedente
				 */
				COSTO_BAMBINI_PRECEDENTE: {
					id: 2698,
					label: "b2b.CART.SERVICE-EDIT.COSTO-BAMBINI-PRECEDENTE"
				},

				/**
				 * Criterio anticipo fornitore
				 */
				CRITERIO_ANTICIPO_FORNITORE: {
					id: 2699,
					label: "b2b.CART.SERVICE-EDIT.CRITERIO-ANTICIPO-FORNITORE"
				},

				/**
				 * data
				 */
				DATA_ACCONTO: {
					id: 2700,
					label: "b2b.CART.SERVICE-EDIT.DATA-ACCONTO"
				},

				/**
				 * Data limite anticipo
				 */
				DATA_ANTICIPO_FORNITORE: {
					id: 2701,
					label: "b2b.CART.SERVICE-EDIT.DATA-ANTICIPO-FORNITORE"
				},

				/**
				 * Data cancellazione
				 */
				DATA_CANCELLAZIONE: {
					id: 2702,
					label: "b2b.CART.SERVICE-EDIT.DATA-CANCELLAZIONE"
				},

				/**
				 * Data conferma
				 */
				DATA_CONFERMA: {
					id: 2703,
					label: "b2b.CART.SERVICE-EDIT.DATA-CONFERMA"
				},

				/**
				 * Data fine
				 */
				DATA_FINE: {
					id: 2704,
					label: "b2b.CART.SERVICE-EDIT.DATA-FINE"
				},

				/**
				 * Data inizio
				 */
				DATA_INIZIO: {
					id: 2705,
					label: "b2b.CART.SERVICE-EDIT.DATA-INIZIO"
				},

				/**
				 * Data limite
				 */
				DATA_LIMITE: {
					id: 2706,
					label: "b2b.CART.SERVICE-EDIT.DATA-LIMITE"
				},

				/**
				 * Data prenotazione
				 */
				DATA_PRENOTAZIONE: {
					id: 2707,
					label: "b2b.CART.SERVICE-EDIT.DATA-PRENOTAZIONE"
				},

				/**
				 * Data prenotazione automatica
				 */
				DATA_PRENOTAZIONE_AUTOMATICA: {
					id: 2708,
					label: "b2b.CART.SERVICE-EDIT.DATA-PRENOTAZIONE-AUTOMATICA"
				},

				/**
				 * Data risposta cancellazione
				 */
				DATA_RISPOSTA_CANCELLAZIONE: {
					id: 2709,
					label: "b2b.CART.SERVICE-EDIT.DATA-RISPOSTA-CANCELLAZIONE"
				},

				/**
				 * Dati
				 */
				DATI: {
					id: 2710,
					label: "b2b.CART.SERVICE-EDIT.DATI"
				},

				/**
				 * Dati anticipo ricalcolati. Ricordarsi di salvare!
				 */
				DATI_ANTICIPO_RICALCOLATI: {
					id: 2711,
					label: "b2b.CART.SERVICE-EDIT.DATI-ANTICIPO-RICALCOLATI"
				},

				/**
				 * Dati Host-2-Host
				 */
				DATI_HOST_TO_HOST: {
					id: 2712,
					label: "b2b.CART.SERVICE-EDIT.DATI-HOST-TO-HOST"
				},

				/**
				 * Deregolamenta
				 */
				DEREGOLAMENTA_COMMISSIONI: {
					id: 2713,
					label: "b2b.CART.SERVICE-EDIT.DEREGOLAMENTA-COMMISSIONI"
				},

				/**
				 * Descrizione
				 */
				DESCRIZIONE: {
					id: 2714,
					label: "b2b.CART.SERVICE-EDIT.DESCRIZIONE"
				},

				/**
				 * Descrizione obbligatoria
				 */
				DESCRIZIONE_OBBLIGATORIA: {
					id: 2715,
					label: "b2b.CART.SERVICE-EDIT.DESCRIZIONE-OBBLIGATORIA"
				},

				/**
				 * Descrizione programma di viaggio
				 */
				DESCRIZIONE_PROGRAMMA: {
					id: 2716,
					label: "b2b.CART.SERVICE-EDIT.DESCRIZIONE-PROGRAMMA"
				},

				/**
				 * Disponibilità
				 */
				DISPONIBILITA: {
					id: 2717,
					label: "b2b.CART.SERVICE-EDIT.DISPONIBILITA"
				},

				/**
				 * Disponibilità precedente
				 */
				DISPONIBILTA_PRECEDENTE: {
					id: 2718,
					label: "b2b.CART.SERVICE-EDIT.DISPONIBILTA-PRECEDENTE"
				},

				/**
				 * Confermare le modifiche?
				 */
				EDIT_CONFIRM: {
					id: 2719,
					label: "b2b.CART.SERVICE-EDIT.EDIT_CONFIRM"
				},

				/**
				 * Emesso
				 */
				EMESSO: {
					id: 2720,
					label: "b2b.CART.SERVICE-EDIT.EMESSO"
				},

				/**
				 * Emettere entro il
				 */
				EMETTERE_ENTRO_IL: {
					id: 2721,
					label: "b2b.CART.SERVICE-EDIT.EMETTERE-ENTRO-IL"
				},

				/**
				 * Fine / Rilascio
				 */
				FINE_RILASCIO: {
					id: 2722,
					label: "b2b.CART.SERVICE-EDIT.FINE-RILASCIO"
				},

				/**
				 * Giorno
				 */
				GIORNO: {
					id: 2723,
					label: "b2b.CART.SERVICE-EDIT.GIORNO"
				},

				/**
				 * Il giorno non rientra nella tappa. Valori validi: {inzio}..{fine}
				 */
				GIORNO_FUORI_TAPPA: {
					id: 2724,
					label: "b2b.CART.SERVICE-EDIT.GIORNO-FUORI-TAPPA"
				},

				/**
				 * Giorno non valido
				 */
				GIORNO_NON_VALIDO: {
					id: 2725,
					label: "b2b.CART.SERVICE-EDIT.GIORNO-NON-VALIDO"
				},

				/**
				 * H2H
				 */
				H2H: {
					id: 2726,
					label: "b2b.CART.SERVICE-EDIT.H2H"
				},

				/**
				 * Hashcode
				 */
				HASHCODE: {
					id: 2727,
					label: "b2b.CART.SERVICE-EDIT.HASHCODE"
				},

				/**
				 * Hashcode calcolato
				 */
				HASHCODE_CALCOLATO: {
					id: 2728,
					label: "b2b.CART.SERVICE-EDIT.HASHCODE-CALCOLATO"
				},

				/**
				 * HH:MM
				 */
				HH_MM: {
					id: 2729,
					label: "b2b.CART.SERVICE-EDIT.HH-MM"
				},

				/**
				 * Importo
				 */
				IMPORTO: {
					id: 2730,
					label: "b2b.CART.SERVICE-EDIT.IMPORTO"
				},

				/**
				 * Inserire un numero valido
				 */
				IMPORTO_ANTICIPO_HELP: {
					id: 2731,
					label: "b2b.CART.SERVICE-EDIT.IMPORTO-ANTICIPO-HELP"
				},

				/**
				 * Inizio / Prelievo
				 */
				INIZIO_PRELIEVO: {
					id: 2732,
					label: "b2b.CART.SERVICE-EDIT.INIZIO-PRELIEVO"
				},

				/**
				 * Iva
				 */
				IVA: {
					id: 2733,
					label: "b2b.CART.SERVICE-EDIT.IVA"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 2734,
					label: "b2b.CART.SERVICE-EDIT.LUOGO"
				},

				/**
				 * Mapping Naar
				 */
				MAPPING_NAAR: {
					id: 2735,
					label: "b2b.CART.SERVICE-EDIT.MAPPING-NAAR"
				},

				/**
				 * Margine
				 */
				MARGINE: {
					id: 2736,
					label: "b2b.CART.SERVICE-EDIT.MARGINE"
				},

				/**
				 * Molt. per pax
				 */
				MOLTIPLICA_PER_NR_PAX: {
					id: 2737,
					label: "b2b.CART.SERVICE-EDIT.MOLTIPLICA-PER-NR-PAX"
				},

				/**
				 * Nessuno
				 */
				NESSUN_ACCONTO: {
					id: 2738,
					label: "b2b.CART.SERVICE-EDIT.NESSUN-ACCONTO"
				},

				/**
				 * Nessun articolo di penali di cancellazione associato.
				 */
				NESSUN_ARTICOLO_PENALI_ASSOCIATO: {
					id: 2739,
					label: "b2b.CART.SERVICE-EDIT.NESSUN-ARTICOLO-PENALI-ASSOCIATO"
				},

				/**
				 * -- Nessuno --
				 */
				NESSUN_BEDDING: {
					id: 2740,
					label: "b2b.CART.SERVICE-EDIT.NESSUN-BEDDING"
				},

				/**
				 * -- Nessuno --
				 */
				NESSUN_TRATTAMENTO: {
					id: 2741,
					label: "b2b.CART.SERVICE-EDIT.NESSUN-TRATTAMENTO"
				},

				/**
				 * Non ci sono note personalizzate per questa riga di servizio.
				 */
				NESSUNA_NOTA_CUSTOM: {
					id: 2742,
					label: "b2b.CART.SERVICE-EDIT.NESSUNA-NOTA-CUSTOM"
				},

				/**
				 * -- Nessuna --
				 */
				NESSUNA_PENALE: {
					id: 2743,
					label: "b2b.CART.SERVICE-EDIT.NESSUNA-PENALE"
				},

				/**
				 * -- Nessuna --
				 */
				NESSUNA_TABELLA_COSTI: {
					id: 2744,
					label: "b2b.CART.SERVICE-EDIT.NESSUNA-TABELLA-COSTI"
				},

				/**
				 * -- Nessuna --
				 */
				NESSUNA_TIPOLOGIA: {
					id: 2745,
					label: "b2b.CART.SERVICE-EDIT.NESSUNA-TIPOLOGIA"
				},

				/**
				 * Non puoi assegnare direttamente un accordo Host-2-Host
				 */
				NO_ACCORDI_H2H: {
					id: 2746,
					label: "b2b.CART.SERVICE-EDIT.NO-ACCORDI-H2H"
				},

				/**
				 * Non modif. periodo
				 */
				NON_MODIFICARE_PERIODO: {
					id: 2747,
					label: "b2b.CART.SERVICE-EDIT.NON-MODIFICARE-PERIODO"
				},

				/**
				 * Note cliente
				 */
				NOTE_CLIENTE: {
					id: 2748,
					label: "b2b.CART.SERVICE-EDIT.NOTE-CLIENTE"
				},

				/**
				 * Note fornitore
				 */
				NOTE_FORNITORE: {
					id: 2749,
					label: "b2b.CART.SERVICE-EDIT.NOTE-FORNITORE"
				},

				/**
				 * Num. adulti non valido
				 */
				NUMERO_ADULTI_NON_VALIDO: {
					id: 2750,
					label: "b2b.CART.SERVICE-EDIT.NUMERO-ADULTI-NON-VALIDO"
				},

				/**
				 * Num. bambini non valido
				 */
				NUMERO_BAMBINI_NON_VALIDO: {
					id: 2751,
					label: "b2b.CART.SERVICE-EDIT.NUMERO-BAMBINI-NON-VALIDO"
				},

				/**
				 * Il numero totale di pax deve essere {npax}
				 */
				NUMERO_TOTALE_PAX_DEVE_ESSERE: {
					id: 2752,
					label: "b2b.CART.SERVICE-EDIT.NUMERO-TOTALE-PAX-DEVE-ESSERE"
				},

				/**
				 * Nuovo servizio
				 */
				NUOVO_SERVIZIO: {
					id: 2753,
					label: "b2b.CART.SERVICE-EDIT.NUOVO-SERVIZIO"
				},

				/**
				 * Ok contab.
				 */
				OK_CONTABILITA_BIGLIETTERIA: {
					id: 2754,
					label: "b2b.CART.SERVICE-EDIT.OK-CONTABILITA-BIGLIETTERIA"
				},

				/**
				 * Ora fine
				 */
				ORA_FINE: {
					id: 2755,
					label: "b2b.CART.SERVICE-EDIT.ORA-FINE"
				},

				/**
				 * Ora inizio
				 */
				ORA_INIZIO: {
					id: 2756,
					label: "b2b.CART.SERVICE-EDIT.ORA-INIZIO"
				},

				/**
				 * Ordina
				 */
				ORDINA_BIGLIETTERIA: {
					id: 2757,
					label: "b2b.CART.SERVICE-EDIT.ORDINA-BIGLIETTERIA"
				},

				/**
				 * Ordine
				 */
				ORDINE: {
					id: 2758,
					label: "b2b.CART.SERVICE-EDIT.ORDINE"
				},

				/**
				 * Ordine non valido
				 */
				ORDINE_NON_VALIDO: {
					id: 2759,
					label: "b2b.CART.SERVICE-EDIT.ORDINE-NON-VALIDO"
				},

				/**
				 * Pag. anticipato
				 */
				PAGAMENTO_IMMEDIATO: {
					id: 2760,
					label: "b2b.CART.SERVICE-EDIT.PAGAMENTO-IMMEDIATO"
				},

				/**
				 * Pasti
				 */
				PASTI: {
					id: 2761,
					label: "b2b.CART.SERVICE-EDIT.PASTI"
				},

				/**
				 * Pax
				 */
				PAX: {
					id: 2762,
					label: "b2b.CART.SERVICE-EDIT.PAX"
				},

				/**
				 * Penale
				 */
				PENALE: {
					id: 2763,
					label: "b2b.CART.SERVICE-EDIT.PENALE"
				},

				/**
				 * Penali di default da dati aggiuntivi
				 */
				PENALI_DEFAULT_DA_DATI_AGGIUNTIVI: {
					id: 2764,
					label: "b2b.CART.SERVICE-EDIT.PENALI-DEFAULT-DA-DATI-AGGIUNTIVI"
				},

				/**
				 * Penali di cancellazione
				 */
				PENALI_DI_CANCELLAZIONE: {
					id: 2765,
					label: "b2b.CART.SERVICE-EDIT.PENALI-DI-CANCELLAZIONE"
				},

				/**
				 * Preso in carico
				 */
				PRESO_IN_CARICO_BIGLIETTERIA: {
					id: 2766,
					label: "b2b.CART.SERVICE-EDIT.PRESO-IN-CARICO-BIGLIETTERIA"
				},

				/**
				 * Prezzo
				 */
				PREZZO: {
					id: 2767,
					label: "b2b.CART.SERVICE-EDIT.PREZZO"
				},

				/**
				 * Prezzo adulti precedente
				 */
				PREZZO_ADULTI_PRECEDENTE: {
					id: 2768,
					label: "b2b.CART.SERVICE-EDIT.PREZZO-ADULTI-PRECEDENTE"
				},

				/**
				 * Prezzo bambini precedente
				 */
				PREZZO_BAMBINI_PRECEDENTE: {
					id: 2769,
					label: "b2b.CART.SERVICE-EDIT.PREZZO-BAMBINI-PRECEDENTE"
				},

				/**
				 * Punto di fine
				 */
				PUNTO_FINALE: {
					id: 2770,
					label: "b2b.CART.SERVICE-EDIT.PUNTO-FINALE"
				},

				/**
				 * Punto d'inizio
				 */
				PUNTO_INIZIALE: {
					id: 2771,
					label: "b2b.CART.SERVICE-EDIT.PUNTO-INIZIALE"
				},

				/**
				 * Quantità
				 */
				QUANTITA: {
					id: 2772,
					label: "b2b.CART.SERVICE-EDIT.QUANTITA"
				},

				/**
				 * Quantità non valida
				 */
				QUANTITA_NON_VALIDA: {
					id: 2773,
					label: "b2b.CART.SERVICE-EDIT.QUANTITA-NON-VALIDA"
				},

				/**
				 * Ricalcola dati anticipo fornitore
				 */
				RICALCOLA_ANTICIPO: {
					id: 2774,
					label: "b2b.CART.SERVICE-EDIT.RICALCOLA-ANTICIPO"
				},

				/**
				 * Richiesto rimborso
				 */
				RICHIESTO_RIMBORSO: {
					id: 2775,
					label: "b2b.CART.SERVICE-EDIT.RICHIESTO-RIMBORSO"
				},

				/**
				 * Servizio
				 */
				SERVIZIO: {
					id: 2776,
					label: "b2b.CART.SERVICE-EDIT.SERVIZIO"
				},

				/**
				 * Servizio alternativo
				 */
				SERVIZIO_ALTERNATIVO: {
					id: 2777,
					label: "b2b.CART.SERVICE-EDIT.SERVIZIO-ALTERNATIVO"
				},

				/**
				 * Servizio fornitore
				 */
				SERVIZIO_FORNITORE: {
					id: 2778,
					label: "b2b.CART.SERVICE-EDIT.SERVIZIO-FORNITORE"
				},

				/**
				 * Host-2-host
				 */
				SERVIZIO_HOST_2_HOST: {
					id: 2779,
					label: "b2b.CART.SERVICE-EDIT.SERVIZIO-HOST-2-HOST"
				},

				/**
				 * Sistemazione
				 */
				SISTEMAZIONE: {
					id: 2780,
					label: "b2b.CART.SERVICE-EDIT.SISTEMAZIONE"
				},

				/**
				 * Specificare l'accordo
				 */
				SPECIFICARE_ACCORDO: {
					id: 2781,
					label: "b2b.CART.SERVICE-EDIT.SPECIFICARE-ACCORDO"
				},

				/**
				 * Status
				 */
				STATUS: {
					id: 2782,
					label: "b2b.CART.SERVICE-EDIT.STATUS"
				},

				/**
				 * Tabella costi/commissioni
				 */
				TABELLA_COSTI_COMMISSIONI: {
					id: 2783,
					label: "b2b.CART.SERVICE-EDIT.TABELLA-COSTI-COMMISSIONI"
				},

				/**
				 * Tappa
				 */
				TAPPA: {
					id: 2784,
					label: "b2b.CART.SERVICE-EDIT.TAPPA"
				},

				/**
				 * Tappa aerea
				 */
				TAPPA_AEREA: {
					id: 2785,
					label: "b2b.CART.SERVICE-EDIT.TAPPA-AEREA"
				},

				/**
				 * Penali di cancellazione visualizzate dall'agenzia
				 */
				TIPO_PENALE_AGENZIA: {
					id: 2786,
					label: "b2b.CART.SERVICE-EDIT.TIPO-PENALE-AGENZIA"
				},

				/**
				 * Tipologia
				 */
				TIPOLOGIA: {
					id: 2787,
					label: "b2b.CART.SERVICE-EDIT.TIPOLOGIA"
				},

				/**
				 * Tipologia non mappata
				 */
				TIPOLOGIA_NON_MAPPATA: {
					id: 2788,
					label: "b2b.CART.SERVICE-EDIT.TIPOLOGIA-NON-MAPPATA"
				},

				/**
				 * Trattamento
				 */
				TRATTAMENTO: {
					id: 2789,
					label: "b2b.CART.SERVICE-EDIT.TRATTAMENTO"
				},

				/**
				 * Valuta
				 */
				VALUTA: {
					id: 2790,
					label: "b2b.CART.SERVICE-EDIT.VALUTA"
				},

				/**
				 * Indicare la valuta
				 */
				VALUTA_ANTICIPO_HELP: {
					id: 2791,
					label: "b2b.CART.SERVICE-EDIT.VALUTA-ANTICIPO-HELP"
				},

				/**
				 * Vista
				 */
				VISTA: {
					id: 2792,
					label: "b2b.CART.SERVICE-EDIT.VISTA"
				},

				/**
				 * Richiesto pagamento immediato al fornitore
				 */
				WARNING_PAGAMENTO_IMMEDIATO: {
					id: 2793,
					label: "b2b.CART.SERVICE-EDIT.WARNING-PAGAMENTO-IMMEDIATO"
				},

				/**
				 * Costi e prezzi calcolati automaticamente. Attivare "Agg.Manuale" per cambiarli
				 */
				WARNING_SERVIZIO_AUTOMATICO: {
					id: 2794,
					label: "b2b.CART.SERVICE-EDIT.WARNING-SERVIZIO-AUTOMATICO"
				},

				/**
				 * Servizio Host-to-host. Per modificare i campi bloccati impostare un accordo non H2H.
				 */
				WARNING_SERVIZIO_H2H: {
					id: 2795,
					label: "b2b.CART.SERVICE-EDIT.WARNING-SERVIZIO-H2H"
				},

			},

			/**
			 * Content of path b2b.CART.SERVICE-ROWS-EDIT
			 */
			SERVICE_ROWS_EDIT: {
				/**
				 * Contatto fine/dropoff
				 */
				CONTATTO_FINE: {
					id: 2796,
					label: "b2b.CART.SERVICE-ROWS-EDIT.CONTATTO-FINE"
				},

				/**
				 * Contatto inizio/pickup
				 */
				CONTATTO_INIZIO: {
					id: 2797,
					label: "b2b.CART.SERVICE-ROWS-EDIT.CONTATTO-INIZIO"
				},

				/**
				 * Errore Criptico
				 */
				CRYPTIC_ERROR: {
					id: 2798,
					label: "b2b.CART.SERVICE-ROWS-EDIT.CRYPTIC-ERROR"
				},

				/**
				 * Data/ora fine
				 */
				DATA_ORA_FINE: {
					id: 2799,
					label: "b2b.CART.SERVICE-ROWS-EDIT.DATA-ORA-FINE"
				},

				/**
				 * Data/ora inizio
				 */
				DATA_ORA_INIZIO: {
					id: 2800,
					label: "b2b.CART.SERVICE-ROWS-EDIT.DATA-ORA-INIZIO"
				},

				/**
				 * Descrizione programma
				 */
				DESCRIZIONE_PROGRAMMA: {
					id: 2801,
					label: "b2b.CART.SERVICE-ROWS-EDIT.DESCRIZIONE-PROGRAMMA"
				},

				/**
				 * Fine
				 */
				FINE: {
					id: 2802,
					label: "b2b.CART.SERVICE-ROWS-EDIT.FINE"
				},

				/**
				 * Trascina per riordinare
				 */
				INFO_DATI_DRAGTOMOVE: {
					id: 2803,
					label: "b2b.CART.SERVICE-ROWS-EDIT.INFO-DATI_DRAGTOMOVE"
				},

				/**
				 * Qui vengono elencati i PNR dell'offerta con le rispettive informazioni dell'itinerario di volo (biglietti). Premere 'Salva' per modificarli e renderli disponibili nel documento di viaggio.
				 */
				INFO_DATI_TICKETS: {
					id: 2804,
					label: "b2b.CART.SERVICE-ROWS-EDIT.INFO-DATI_TICKETS"
				},

				/**
				 * I dati sono stati ottenuti automaticamente dal Criptico.
				 */
				INFO_DATI_TICKETS_AUTO: {
					id: 2805,
					label: "b2b.CART.SERVICE-ROWS-EDIT.INFO-DATI_TICKETS-AUTO"
				},

				/**
				 * Tutti i dati sono necessari
				 */
				INFO_DATI_TICKETS_FORM_NOTVALID: {
					id: 2806,
					label: "b2b.CART.SERVICE-ROWS-EDIT.INFO-DATI_TICKETS-FORM-NOTVALID"
				},

				/**
				 * I dati sono stati ottenuti automaticamente dal Criptico.
				 */
				INFO_DATI_TICKETS_LOADINGDB: {
					id: 2807,
					label: "b2b.CART.SERVICE-ROWS-EDIT.INFO-DATI_TICKETS-LOADINGDB"
				},

				/**
				 * Interrogazione Criptico. Attendere...
				 */
				INFO_DATI_TICKETS_LOADINGDBCRYPTIC: {
					id: 2808,
					label: "b2b.CART.SERVICE-ROWS-EDIT.INFO-DATI_TICKETS-LOADINGDBCRYPTIC"
				},

				/**
				 * Inizio
				 */
				INIZIO: {
					id: 2809,
					label: "b2b.CART.SERVICE-ROWS-EDIT.INIZIO"
				},

				/**
				 * Interroga Criptico
				 */
				INTERROGA_CRIPTICO: {
					id: 2810,
					label: "b2b.CART.SERVICE-ROWS-EDIT.INTERROGA-CRIPTICO"
				},

				/**
				 * Luogo fine
				 */
				LUOGO_FINE: {
					id: 2811,
					label: "b2b.CART.SERVICE-ROWS-EDIT.LUOGO-FINE"
				},

				/**
				 * Luogo inizio
				 */
				LUOGO_INIZIO: {
					id: 2812,
					label: "b2b.CART.SERVICE-ROWS-EDIT.LUOGO-INIZIO"
				},

				/**
				 * Modifica dati conferme
				 */
				MODIFICA_DATI_CONFERME: {
					id: 2813,
					label: "b2b.CART.SERVICE-ROWS-EDIT.MODIFICA-DATI_CONFERME"
				},

				/**
				 * Modifica dati biglietti aerei
				 */
				MODIFICA_DATI_TICKETS: {
					id: 2814,
					label: "b2b.CART.SERVICE-ROWS-EDIT.MODIFICA-DATI_TICKETS"
				},

				/**
				 * Numero conferma
				 */
				NUMERO_CONFERMA: {
					id: 2815,
					label: "b2b.CART.SERVICE-ROWS-EDIT.NUMERO-CONFERMA"
				},

				/**
				 * Punto finale
				 */
				PUNTO_FINALE: {
					id: 2816,
					label: "b2b.CART.SERVICE-ROWS-EDIT.PUNTO-FINALE"
				},

				/**
				 * Punto iniziale
				 */
				PUNTO_INIZIALE: {
					id: 2817,
					label: "b2b.CART.SERVICE-ROWS-EDIT.PUNTO-INIZIALE"
				},

				/**
				 * Status
				 */
				STATUS: {
					id: 2818,
					label: "b2b.CART.SERVICE-ROWS-EDIT.STATUS"
				},

				/**
				 * Stampa biglietti
				 */
				STAMPA_CRIPTICO: {
					id: 6001,
					label: "b2b.CART.SERVICE-ROWS-EDIT.STAMPA-CRIPTICO"
				},

			},

			/**
			 * Content of path b2b.CART.SHOPPER
			 */
			SHOPPER: {
				/**
				 * Aggiungi
				 */
				AGGIUNGI: {
					id: 2819,
					label: "b2b.CART.SHOPPER.AGGIUNGI"
				},

				/**
				 * Aggiunta al viaggio in corso...
				 */
				AGGIUNTA_AL_VIAGGIO: {
					id: 2820,
					label: "b2b.CART.SHOPPER.AGGIUNTA-AL-VIAGGIO"
				},

				/**
				 * Crea un viaggio con quest{n, plural, =0{i} one{o} other{i}} servizi{n, plural, =0{} one{o} other{}}
				 */
				CREA_E_AGGIUNGI: {
					id: 2821,
					label: "b2b.CART.SHOPPER.CREA-E-AGGIUNGI"
				},

				/**
				 * Creazione del viaggio in corso...
				 */
				CREAZIONE_DEL_VIAGGIO: {
					id: 2822,
					label: "b2b.CART.SHOPPER.CREAZIONE-DEL-VIAGGIO"
				},

				/**
				 * (già incluse nel prezzo)
				 */
				GIA_INCLUSE_NEL_PREZZO: {
					id: 2823,
					label: "b2b.CART.SHOPPER.GIA-INCLUSE-NEL-PREZZO"
				},

				/**
				 * Intestatario noleggio
				 */
				GUIDATORE: {
					id: 2824,
					label: "b2b.CART.SHOPPER.GUIDATORE"
				},

				/**
				 * Obbligatorio
				 */
				OBBLIGATORIO: {
					id: 2825,
					label: "b2b.CART.SHOPPER.OBBLIGATORIO"
				},

				/**
				 * Offerte applicate
				 */
				OFFERTE_APPLICATE: {
					id: 2826,
					label: "b2b.CART.SHOPPER.OFFERTE-APPLICATE"
				},

				/**
				 * Penale annullamento dal
				 */
				PENALE_ANNULLAMENTO_DAL: {
					id: 2827,
					label: "b2b.CART.SHOPPER.PENALE-ANNULLAMENTO-DAL"
				},

				/**
				 * Prezzo da verificare
				 */
				PREZZO_DA_VERIFICARE: {
					id: 2828,
					label: "b2b.CART.SHOPPER.PREZZO-DA-VERIFICARE"
				},

				/**
				 * {n} servizi{n, plural, =0{} one{o} other{}} aggiunt{n, plural, =0{i} one{o} other{i}} al viaggio
				 */
				SERVIZI_AGGIUNTI_AL_VIAGGIO: {
					id: 2829,
					label: "b2b.CART.SHOPPER.SERVIZI-AGGIUNTI-AL-VIAGGIO"
				},

				/**
				 * Supplementi disponibili
				 */
				SUPPLEMENTI_DISPONIBILI: {
					id: 2830,
					label: "b2b.CART.SHOPPER.SUPPLEMENTI-DISPONIBILI"
				},

				/**
				 * Totale a persona da aggiungere al viaggio
				 */
				TOTALE_A_PERSONA_DA_AGGIUNGERE_AL_VIAGGIO: {
					id: 2831,
					label: "b2b.CART.SHOPPER.TOTALE-A-PERSONA-DA-AGGIUNGERE-AL-VIAGGIO"
				},

				/**
				 * Totale da aggiungere al viaggio
				 */
				TOTALE_DA_AGGIUNGERE_AL_VIAGGIO: {
					id: 2832,
					label: "b2b.CART.SHOPPER.TOTALE-DA-AGGIUNGERE-AL-VIAGGIO"
				},

				/**
				 * Verifica condizioni di vendita...
				 */
				VERIFICA_CONDIZIONI_DI_VENDITA: {
					id: 2833,
					label: "b2b.CART.SHOPPER.VERIFICA-CONDIZIONI-DI-VENDITA"
				},

				/**
				 * Creato viaggio numero {id} con {n} servizi{n, plural, =0{} one{o} other{}}
				 */
				VIAGGIO_CREATO_E_SERVIZI_AGGIUNTI_AL_VIAGGIO: {
					id: 2834,
					label: "b2b.CART.SHOPPER.VIAGGIO-CREATO-E-SERVIZI-AGGIUNTI-AL-VIAGGIO"
				},

			},

			/**
			 * Content of path b2b.CART.STATUS
			 */
			STATUS: {
				/**
				 * Abbinamento
				 */
				ABBINAMENTO: {
					id: 2836,
					label: "b2b.CART.STATUS.ABBINAMENTO"
				},

				/**
				 * Acconto
				 */
				ACCONTO: {
					id: 2837,
					label: "b2b.CART.STATUS.ACCONTO"
				},

				/**
				 * Acconto versato
				 */
				ACCONTO_VERSATO: {
					id: 2838,
					label: "b2b.CART.STATUS.ACCONTO-VERSATO"
				},

				/**
				 * Accredito commissioni
				 */
				ACCREDITO_COMMISSIONI: {
					id: 2839,
					label: "b2b.CART.STATUS.ACCREDITO-COMMISSIONI"
				},

				/**
				 * Agenzia
				 */
				AGENZIA: {
					id: 2840,
					label: "b2b.CART.STATUS.AGENZIA"
				},

				/**
				 * Acconto alla prenotazione
				 */
				ALLA_CONFERMA: {
					id: 2841,
					label: "b2b.CART.STATUS.ALLA-CONFERMA"
				},

				/**
				 * Anticipi richiesti
				 */
				ANTICIPI_RICHIESTI: {
					id: 2842,
					label: "b2b.CART.STATUS.ANTICIPI-RICHIESTI"
				},

				/**
				 * Assicurazioni
				 */
				ANTICIPO_ASSICURAZIONI: {
					id: 2843,
					label: "b2b.CART.STATUS.ANTICIPO-ASSICURAZIONI"
				},

				/**
				 * Anticipo fisso
				 */
				ANTICIPO_FISSO: {
					id: 2844,
					label: "b2b.CART.STATUS.ANTICIPO-FISSO"
				},

				/**
				 * QAP/BP
				 */
				ANTICIPO_QUOTE: {
					id: 2845,
					label: "b2b.CART.STATUS.ANTICIPO-QUOTE"
				},

				/**
				 * Avere
				 */
				AVERE: {
					id: 2846,
					label: "b2b.CART.STATUS.AVERE"
				},

				/**
				 * Residuo da incassare scaduto
				 */
				AVERE_SCADUTO: {
					id: 2847,
					label: "b2b.CART.STATUS.AVERE-SCADUTO"
				},

				/**
				 * Base
				 */
				BASE: {
					id: 2848,
					label: "b2b.CART.STATUS.BASE"
				},

				/**
				 * Biglietteria
				 */
				BIGLIETTERIA: {
					id: 2849,
					label: "b2b.CART.STATUS.BIGLIETTERIA"
				},

				/**
				 * Biglietti emessi
				 */
				BIGLIETTI_EMESSI: {
					id: 2850,
					label: "b2b.CART.STATUS.BIGLIETTI-EMESSI"
				},

				/**
				 * Commissioni
				 */
				COMMISSIONI: {
					id: 2851,
					label: "b2b.CART.STATUS.COMMISSIONI"
				},

				/**
				 * Compensazione
				 */
				COMPENSAZIONE: {
					id: 2852,
					label: "b2b.CART.STATUS.COMPENSAZIONE"
				},

				/**
				 * Conferme
				 */
				CONFERME: {
					id: 2853,
					label: "b2b.CART.STATUS.CONFERME"
				},

				/**
				 * Importo saldato tutto o in parte dall'acconto del {n}%
				 */
				COPERTO_DA_ANTICIPO_CONTRATTUALE: {
					id: 2854,
					label: "b2b.CART.STATUS.COPERTO-DA-ANTICIPO-CONTRATTUALE"
				},

				/**
				 * Corriere
				 */
				CORRIERE: {
					id: 2855,
					label: "b2b.CART.STATUS.CORRIERE"
				},

				/**
				 * Dare
				 */
				DARE: {
					id: 2856,
					label: "b2b.CART.STATUS.DARE"
				},

				/**
				 * Data
				 */
				DATA: {
					id: 2857,
					label: "b2b.CART.STATUS.DATA"
				},

				/**
				 * Data acconto
				 */
				DATA_ACCONTO: {
					id: 2858,
					label: "b2b.CART.STATUS.DATA-ACCONTO"
				},

				/**
				 * Data incasso
				 */
				DATA_INCASSO: {
					id: 2859,
					label: "b2b.CART.STATUS.DATA-INCASSO"
				},

				/**
				 * Descrizione
				 */
				DESCRIZIONE: {
					id: 2860,
					label: "b2b.CART.STATUS.DESCRIZIONE"
				},

				/**
				 * Documenti
				 */
				DOCUMENTI: {
					id: 2861,
					label: "b2b.CART.STATUS.DOCUMENTI"
				},

				/**
				 * Documenti online
				 */
				DOCUMENTI_ONLINE: {
					id: 2862,
					label: "b2b.CART.STATUS.DOCUMENTI-ONLINE"
				},

				/**
				 * Fattura stampata
				 */
				FATTURA_STAMPATA: {
					id: 2863,
					label: "b2b.CART.STATUS.FATTURA-STAMPATA"
				},

				/**
				 * Fornitori
				 */
				FORNITORI: {
					id: 2864,
					label: "b2b.CART.STATUS.FORNITORI"
				},

				/**
				 * Importo
				 */
				IMPORTO: {
					id: 2865,
					label: "b2b.CART.STATUS.IMPORTO"
				},

				/**
				 * Totale netto agenzia
				 */
				IMPORTO_NETTO_DOVUTO_DALL_AGENZIA: {
					id: 2866,
					label: "b2b.CART.STATUS.IMPORTO-NETTO-DOVUTO-DALL-AGENZIA"
				},

				/**
				 * Incassi
				 */
				INCASSI: {
					id: 2867,
					label: "b2b.CART.STATUS.INCASSI"
				},

				/**
				 * ID
				 */
				INCASSO_ID: {
					id: 2868,
					label: "b2b.CART.STATUS.INCASSO-ID"
				},

				/**
				 * IP
				 */
				IP: {
					id: 2869,
					label: "b2b.CART.STATUS.IP"
				},

				/**
				 * l.v.
				 */
				L_V: {
					id: 2870,
					label: "b2b.CART.STATUS.L-V"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 2871,
					label: "b2b.CART.STATUS.LUOGO"
				},

				/**
				 * Modifica status
				 */
				MODIFICA_STATUS: {
					id: 2872,
					label: "b2b.CART.STATUS.MODIFICA-STATUS"
				},

				/**
				 * Mostra dettagli
				 */
				MOSTRA_DETTAGLI: {
					id: 2873,
					label: "b2b.CART.STATUS.MOSTRA-DETTAGLI"
				},

				/**
				 * Movimenti
				 */
				MOVIMENTI: {
					id: 2874,
					label: "b2b.CART.STATUS.MOVIMENTI"
				},

				/**
				 * -ND-
				 */
				ND: {
					id: 2875,
					label: "b2b.CART.STATUS.ND"
				},

				/**
				 * Note
				 */
				NOTE: {
					id: 2876,
					label: "b2b.CART.STATUS.NOTE"
				},

				/**
				 * Ok contabilità
				 */
				OK_CONTABILITA: {
					id: 2877,
					label: "b2b.CART.STATUS.OK-CONTABILITA"
				},

				/**
				 * Ok per documenti
				 */
				OK_DOCUMENTI: {
					id: 2878,
					label: "b2b.CART.STATUS.OK-DOCUMENTI"
				},

				/**
				 * Ok passaporto
				 */
				OK_PASSAPORTO: {
					id: 2879,
					label: "b2b.CART.STATUS.OK-PASSAPORTO"
				},

				/**
				 * Ordina biglietteria
				 */
				ORDINA_BIGLIETTERIA: {
					id: 2880,
					label: "b2b.CART.STATUS.ORDINA-BIGLIETTERIA"
				},

				/**
				 * Pagamento
				 */
				PAGAMENTO: {
					id: 2881,
					label: "b2b.CART.STATUS.PAGAMENTO"
				},

				/**
				 * Integrazione acconto
				 */
				PAGAMENTO_ANTICIPATO: {
					id: 2882,
					label: "b2b.CART.STATUS.PAGAMENTO-ANTICIPATO"
				},

				/**
				 * Pagato
				 */
				PAGATO: {
					id: 2883,
					label: "b2b.CART.STATUS.PAGATO"
				},

				/**
				 * % Acc
				 */
				PERC_ACCONTO: {
					id: 2884,
					label: "b2b.CART.STATUS.PERC-ACCONTO"
				},

				/**
				 * Presa in carico
				 */
				PRESA_IN_CARICO: {
					id: 2885,
					label: "b2b.CART.STATUS.PRESA-IN-CARICO"
				},

				/**
				 * Programma stampato
				 */
				PROGRAMMA_STAMPATO: {
					id: 2886,
					label: "b2b.CART.STATUS.PROGRAMMA-STAMPATO"
				},

				/**
				 * Residuo
				 */
				RESIDUO: {
					id: 2887,
					label: "b2b.CART.STATUS.RESIDUO"
				},

				/**
				 * Residuo da incassare
				 */
				RESIDUO_DA_INCASSARE: {
					id: 2888,
					label: "b2b.CART.STATUS.RESIDUO-DA-INCASSARE"
				},

				/**
				 * Residuo da incassare scaduto
				 */
				RESIDUO_DA_INCASSARE_SCADUTO: {
					id: 2889,
					label: "b2b.CART.STATUS.RESIDUO-DA-INCASSARE-SCADUTO"
				},

				/**
				 * Saldo
				 */
				SALDO: {
					id: 2890,
					label: "b2b.CART.STATUS.SALDO"
				},

				/**
				 * Residuo da incassare
				 */
				SBILANCIO: {
					id: 2891,
					label: "b2b.CART.STATUS.SBILANCIO"
				},

				/**
				 * Scadenza
				 */
				SCADENZA: {
					id: 2892,
					label: "b2b.CART.STATUS.SCADENZA"
				},

				/**
				 * Calendario pagamenti
				 */
				SCALETTA_PAGAMENTI: {
					id: 2893,
					label: "b2b.CART.STATUS.SCALETTA-PAGAMENTI"
				},

				/**
				 * Status
				 */
				STATUS: {
					id: 2894,
					label: "b2b.CART.STATUS.STATUS"
				},

				/**
				 * Tipo
				 */
				TIPO: {
					id: 2895,
					label: "b2b.CART.STATUS.TIPO"
				},

				/**
				 * Status
				 */
				TITOLO: {
					id: 2896,
					label: "b2b.CART.STATUS.TITOLO"
				},

				/**
				 * TO
				 */
				TO: {
					id: 2897,
					label: "b2b.CART.STATUS.TO"
				},

				/**
				 * Totale
				 */
				TOTALE: {
					id: 2898,
					label: "b2b.CART.STATUS.TOTALE"
				},

				/**
				 * Acconto {perc}%
				 */
				TOTALE_ANTICIPO_CONTRATTURALE_PERC: {
					id: 2899,
					label: "b2b.CART.STATUS.TOTALE-ANTICIPO-CONTRATTURALE-PERC"
				},

				/**
				 * Totale netto
				 */
				TOTALE_AVERE: {
					id: 2900,
					label: "b2b.CART.STATUS.TOTALE-AVERE"
				},

				/**
				 * Totale compensazioni
				 */
				TOTALE_COMPENSAZIONI: {
					id: 2901,
					label: "b2b.CART.STATUS.TOTALE-COMPENSAZIONI"
				},

				/**
				 * Totale Incassi
				 */
				TOTALE_DARE: {
					id: 2902,
					label: "b2b.CART.STATUS.TOTALE-DARE"
				},

				/**
				 * Anticipo alla prenotazione
				 */
				TOTALE_SERVIZI_ALLA_CONFERMA: {
					id: 2903,
					label: "b2b.CART.STATUS.TOTALE-SERVIZI-ALLA-CONFERMA"
				},

				/**
				 * TUTTO
				 */
				TUTTO: {
					id: 2904,
					label: "b2b.CART.STATUS.TUTTO"
				},

				/**
				 * Voucher emessi
				 */
				VOUCHER_EMESSI: {
					id: 2905,
					label: "b2b.CART.STATUS.VOUCHER-EMESSI"
				},

				/**
				 * Calcolo scadenze pagamenti bloccato il {data}
				 */
				BLOCCO_RICALCOLO: {
					id: 6221,
					label: "b2b.CART.STATUS.BLOCCO-RICALCOLO"
				},

			},

			/**
			 * Content of path b2b.CART.STATUS-EDIT
			 */
			STATUS_EDIT: {
				/**
				 * Acconto versato
				 */
				ACCONTO_VERSATO: {
					id: 2906,
					label: "b2b.CART.STATUS-EDIT.ACCONTO-VERSATO"
				},

				/**
				 * Agenzia
				 */
				AGENZIA: {
					id: 2907,
					label: "b2b.CART.STATUS-EDIT.AGENZIA"
				},

				/**
				 * Aggiornamento app
				 */
				AGGIORNAMENTO_APP: {
					id: 2908,
					label: "b2b.CART.STATUS-EDIT.AGGIORNAMENTO-APP"
				},

				/**
				 * Autofattura emessa
				 */
				AUTOFATTURA_EMESSA: {
					id: 2909,
					label: "b2b.CART.STATUS-EDIT.AUTOFATTURA-EMESSA"
				},

				/**
				 * Biglietteria
				 */
				BIGLIETTERIA: {
					id: 2910,
					label: "b2b.CART.STATUS-EDIT.BIGLIETTERIA"
				},

				/**
				 * Biglietti emessi
				 */
				BIGLIETTI_EMESSI: {
					id: 2911,
					label: "b2b.CART.STATUS-EDIT.BIGLIETTI-EMESSI"
				},

				/**
				 * Chiudi
				 */
				CHIUDI: {
					id: 2912,
					label: "b2b.CART.STATUS-EDIT.CHIUDI"
				},

				/**
				 * Conferme
				 */
				CONFERME: {
					id: 2913,
					label: "b2b.CART.STATUS-EDIT.CONFERME"
				},

				/**
				 * Corriere
				 */
				CORRIERE: {
					id: 2914,
					label: "b2b.CART.STATUS-EDIT.CORRIERE"
				},

				/**
				 * Content of path b2b.CART.STATUS-EDIT.CORRIERI
				 */
				CORRIERI: {
					/**
					 * Online
					 */
					_1: {
						id: 2915,
						label: "b2b.CART.STATUS-EDIT.CORRIERI.1"
					},

					/**
					 * Manuale
					 */
					_2: {
						id: 2916,
						label: "b2b.CART.STATUS-EDIT.CORRIERI.2"
					},

					/**
					 * Documenti OK
					 */
					_3: {
						id: 2917,
						label: "b2b.CART.STATUS-EDIT.CORRIERI.3"
					},

					/**
					 * Gestione Milano
					 */
					_4: {
						id: 2918,
						label: "b2b.CART.STATUS-EDIT.CORRIERI.4"
					},

					/**
					 * Attesa dati
					 */
					_5: {
						id: 2919,
						label: "b2b.CART.STATUS-EDIT.CORRIERI.5"
					},

					/**
					 * SDA
					 */
					_6196: {
						id: 2920,
						label: "b2b.CART.STATUS-EDIT.CORRIERI.6196"
					},

					/**
					 * Traco
					 */
					_18211: {
						id: 2921,
						label: "b2b.CART.STATUS-EDIT.CORRIERI.18211"
					},

					/**
					 * Nessuno
					 */
					_null: {
						id: 2922,
						label: "b2b.CART.STATUS-EDIT.CORRIERI.null"
					},

				},

				/**
				 * Documenti
				 */
				DOCUMENTI: {
					id: 2923,
					label: "b2b.CART.STATUS-EDIT.DOCUMENTI"
				},

				/**
				 * Documenti app
				 */
				DOCUMENTI_APP: {
					id: 2924,
					label: "b2b.CART.STATUS-EDIT.DOCUMENTI-APP"
				},

				/**
				 * Documenti Step1 (Miniguida)
				 */
				DOCUMENTI_APP_STEP_1: {
					id: 2925,
					label: "b2b.CART.STATUS-EDIT.DOCUMENTI-APP-STEP-1"
				},

				/**
				 * Documenti Step2 (Voucher)
				 */
				DOCUMENTI_APP_STEP_2: {
					id: 2926,
					label: "b2b.CART.STATUS-EDIT.DOCUMENTI-APP-STEP-2"
				},

				/**
				 * Documenti online
				 */
				DOCUMENTI_ONLINE: {
					id: 2927,
					label: "b2b.CART.STATUS-EDIT.DOCUMENTI-ONLINE"
				},

				/**
				 * Fattura emessa
				 */
				FATTURA_EMESSA: {
					id: 2928,
					label: "b2b.CART.STATUS-EDIT.FATTURA-EMESSA"
				},

				/**
				 * Fornitori
				 */
				FORNITORI: {
					id: 2929,
					label: "b2b.CART.STATUS-EDIT.FORNITORI"
				},

				/**
				 * Invio mail agenzia
				 */
				INVIO_MAIL_AGENZIA: {
					id: 2930,
					label: "b2b.CART.STATUS-EDIT.INVIO-MAIL-AGENZIA"
				},

				/**
				 * Lettera vettura
				 */
				LETTERA_VETTURA: {
					id: 2931,
					label: "b2b.CART.STATUS-EDIT.LETTERA-VETTURA"
				},

				/**
				 * Modalità di pagamento
				 */
				MODALITA_DI_PAGAMENTO: {
					id: 2932,
					label: "b2b.CART.STATUS-EDIT.MODALITA-DI-PAGAMENTO"
				},

				/**
				 * Modifiche salvate
				 */
				MODIFICHE_SALVATE: {
					id: 2933,
					label: "b2b.CART.STATUS-EDIT.MODIFICHE-SALVATE"
				},

				/**
				 * Note
				 */
				NOTE: {
					id: 2934,
					label: "b2b.CART.STATUS-EDIT.NOTE"
				},

				/**
				 * OK contabilità 
				 */
				OK_CONTABILITA: {
					id: 2935,
					label: "b2b.CART.STATUS-EDIT.OK-CONTABILITA"
				},

				/**
				 * OK per documenti
				 */
				OK_DOCUMENTI: {
					id: 2936,
					label: "b2b.CART.STATUS-EDIT.OK-DOCUMENTI"
				},

				/**
				 * OK passaporto
				 */
				OK_PASSAPORTO: {
					id: 2937,
					label: "b2b.CART.STATUS-EDIT.OK-PASSAPORTO"
				},

				/**
				 * Ordina biglietteria
				 */
				ORDINA_BIGLIETTERIA: {
					id: 2938,
					label: "b2b.CART.STATUS-EDIT.ORDINA-BIGLIETTERIA"
				},

				/**
				 * Pagamento
				 */
				PAGAMENTO: {
					id: 2939,
					label: "b2b.CART.STATUS-EDIT.PAGAMENTO"
				},

				/**
				 * Presi in carico
				 */
				PRESI_IN_CARICO: {
					id: 2940,
					label: "b2b.CART.STATUS-EDIT.PRESI-IN-CARICO"
				},

				/**
				 * Programma stampato
				 */
				PROGRAMMA_STAMPATO: {
					id: 2941,
					label: "b2b.CART.STATUS-EDIT.PROGRAMMA-STAMPATO"
				},

				/**
				 * Pubblica su App
				 */
				PUBBLICA_APP: {
					id: 2942,
					label: "b2b.CART.STATUS-EDIT.PUBBLICA-APP"
				},

				/**
				 * Saldo
				 */
				SALDO: {
					id: 2943,
					label: "b2b.CART.STATUS-EDIT.SALDO"
				},

				/**
				 * Salva modifiche
				 */
				SALVA_MODIFICHE: {
					id: 2944,
					label: "b2b.CART.STATUS-EDIT.SALVA-MODIFICHE"
				},

				/**
				 * Spedizione documenti
				 */
				SPEDIZIONE_DOCUMENTI: {
					id: 2945,
					label: "b2b.CART.STATUS-EDIT.SPEDIZIONE-DOCUMENTI"
				},

				/**
				 * Stato
				 */
				STATO: {
					id: 2946,
					label: "b2b.CART.STATUS-EDIT.STATO"
				},

				/**
				 * Tipo emissione
				 */
				TIPO_EMISSIONE: {
					id: 2947,
					label: "b2b.CART.STATUS-EDIT.TIPO-EMISSIONE"
				},

				/**
				 * Titolo viaggio
				 */
				TITOLO_VIAGGIO: {
					id: 2948,
					label: "b2b.CART.STATUS-EDIT.TITOLO-VIAGGIO"
				},

				/**
				 * Viaggio pubblicato
				 */
				VIAGGIO_PUBBLICATO: {
					id: 2949,
					label: "b2b.CART.STATUS-EDIT.VIAGGIO-PUBBLICATO"
				},

				/**
				 * Voucher emessi
				 */
				VOUCHER_EMESSI: {
					id: 2950,
					label: "b2b.CART.STATUS-EDIT.VOUCHER-EMESSI"
				},

				/**
				 * Blocca ricalcolo scadenze
				 */
				BLOCCA_RICALCOLO: {
					id: 6222,
					label: "b2b.CART.STATUS-EDIT.BLOCCA-RICALCOLO"
				},

				/**
				 * Confermi di voler bloccare il ricalcolo scadenze?
				 */
				BLOCCA_RICALCOLO_CONFERMA: {
					id: 6223,
					label: "b2b.CART.STATUS-EDIT.BLOCCA-RICALCOLO-CONFERMA"
				},

				/**
				 * Ricalcolo scadenze bloccato.
				 */
				RICALCOLO_BLOCCATO: {
					id: 6224,
					label: "b2b.CART.STATUS-EDIT.RICALCOLO-BLOCCATO"
				},

			},

			/**
			 * Content of path b2b.CART.STATUS-NOTIFY
			 */
			STATUS_NOTIFY: {
				/**
				 * al
				 */
				AL: {
					id: 2951,
					label: "b2b.CART.STATUS-NOTIFY.AL"
				},

				/**
				 * Apri criptico in nuova finestra
				 */
				APRI_CRIPTICO: {
					id: 2952,
					label: "b2b.CART.STATUS-NOTIFY.APRI-CRIPTICO"
				},

				/**
				 * Completamento terminato
				 */
				COMPLETAMENTO_TERMINATO: {
					id: 2953,
					label: "b2b.CART.STATUS-NOTIFY.COMPLETAMENTO-TERMINATO"
				},

				/**
				 * dal
				 */
				DAL: {
					id: 2954,
					label: "b2b.CART.STATUS-NOTIFY.DAL"
				},

				/**
				 * il
				 */
				IL: {
					id: 2955,
					label: "b2b.CART.STATUS-NOTIFY.IL"
				},

				/**
				 * in corso
				 */
				IN_CORSO: {
					id: 2956,
					label: "b2b.CART.STATUS-NOTIFY.IN-CORSO"
				},

				/**
				 * a
				 */
				SERVIZIO_A_LUOGO: {
					id: 2957,
					label: "b2b.CART.STATUS-NOTIFY.SERVIZIO-A-LUOGO"
				},

			},

			/**
			 * Content of path b2b.CART.STEP-EDIT
			 */
			STEP_EDIT: {
				/**
				 * Aeroporto
				 */
				AEROPORTO: {
					id: 2958,
					label: "b2b.CART.STEP-EDIT.AEROPORTO"
				},

				/**
				 * Aggiungi tappa
				 */
				AGGIUNGI_TAPPA: {
					id: 2959,
					label: "b2b.CART.STEP-EDIT.AGGIUNGI-TAPPA"
				},

				/**
				 * Aggiusta date e durate dei servizi
				 */
				AGGIUSTA_DURATE: {
					id: 2960,
					label: "b2b.CART.STEP-EDIT.AGGIUSTA-DURATE"
				},

				/**
				 * Altri servizi
				 */
				ALTRI_SERVIZI: {
					id: 2961,
					label: "b2b.CART.STEP-EDIT.ALTRI-SERVIZI"
				},

				/**
				 * Si cercherà di conservare la data
				 */
				ALTRI_SERVIZI_HELP: {
					id: 2962,
					label: "b2b.CART.STEP-EDIT.ALTRI-SERVIZI-HELP"
				},

				/**
				 * Categoria hotel
				 */
				CATEGORIA_HOTEL: {
					id: 2963,
					label: "b2b.CART.STEP-EDIT.CATEGORIA-HOTEL"
				},

				/**
				 * Indicare la categoria di hotel
				 */
				CATEGORIA_HOTEL_RICHIESTA: {
					id: 2964,
					label: "b2b.CART.STEP-EDIT.CATEGORIA-HOTEL-RICHIESTA"
				},

				/**
				 * Data
				 */
				DATA: {
					id: 2965,
					label: "b2b.CART.STEP-EDIT.DATA"
				},

				/**
				 * Correzioni
				 */
				DATA_CORRETTA: {
					id: 2966,
					label: "b2b.CART.STEP-EDIT.DATA-CORRETTA"
				},

				/**
				 * Date modificate
				 */
				DATE_MODIFICATE: {
					id: 2967,
					label: "b2b.CART.STEP-EDIT.DATE-MODIFICATE"
				},

				/**
				 * Date precedenti
				 */
				DATE_PRECEDENTI: {
					id: 2968,
					label: "b2b.CART.STEP-EDIT.DATE-PRECEDENTI"
				},

				/**
				 * Elimina tutti i servizi della tappa
				 */
				ELIMINARE_SERVIZI: {
					id: 2969,
					label: "b2b.CART.STEP-EDIT.ELIMINARE-SERVIZI"
				},

				/**
				 * Escludi dalla ricerca voli
				 */
				ESCLUDI_RICERCA_VOLI: {
					id: 2970,
					label: "b2b.CART.STEP-EDIT.ESCLUDI-RICERCA-VOLI"
				},

				/**
				 * Valori validi: 1 - 255
				 */
				GIORNI_VALIDI: {
					id: 2971,
					label: "b2b.CART.STEP-EDIT.GIORNI-VALIDI"
				},

				/**
				 * Giorno
				 */
				GIORNO: {
					id: 2972,
					label: "b2b.CART.STEP-EDIT.GIORNO"
				},

				/**
				 * Impostazioni per la ricerca automatica
				 */
				IMPOSTAZIONI_RICERCA_AUTOMATICA: {
					id: 2973,
					label: "b2b.CART.STEP-EDIT.IMPOSTAZIONI-RICERCA-AUTOMATICA"
				},

				/**
				 * Includi hotel vicino all'aeroporto
				 */
				INCLUDI_HOTEL_AEROPORTO: {
					id: 2974,
					label: "b2b.CART.STEP-EDIT.INCLUDI-HOTEL-AEROPORTO"
				},

				/**
				 * Includi servizi non rimborsabili
				 */
				INCLUDI_NON_RIMBORSABILI: {
					id: 2975,
					label: "b2b.CART.STEP-EDIT.INCLUDI-NON-RIMBORSABILI"
				},

				/**
				 * Includi servizi con pagamento immediato
				 */
				INCLUDI_PAGAMENTO_IMMEDIATO: {
					id: 2976,
					label: "b2b.CART.STEP-EDIT.INCLUDI-PAGAMENTO-IMMEDIATO"
				},

				/**
				 * Includi servizi su richiesta
				 */
				INCLUDI_SU_RICHIESTA: {
					id: 2977,
					label: "b2b.CART.STEP-EDIT.INCLUDI-SU-RICHIESTA"
				},

				/**
				 * Itinerario
				 */
				ITINERARIO: {
					id: 2978,
					label: "b2b.CART.STEP-EDIT.ITINERARIO"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 2979,
					label: "b2b.CART.STEP-EDIT.LUOGO"
				},

				/**
				 * Luogo modificato
				 */
				LUOGO_MODIFICATO: {
					id: 2980,
					label: "b2b.CART.STEP-EDIT.LUOGO-MODIFICATO"
				},

				/**
				 * Il luogo della tappa è stato modificato. I servizi potrebbero non corrispondere più al luogo scelto
				 */
				LUOGO_MODIFICATO_HELP: {
					id: 2981,
					label: "b2b.CART.STEP-EDIT.LUOGO-MODIFICATO-HELP"
				},

				/**
				 * Luogo precedente
				 */
				LUOGO_PRECEDENTE: {
					id: 2982,
					label: "b2b.CART.STEP-EDIT.LUOGO-PRECEDENTE"
				},

				/**
				 * Inserire un luogo
				 */
				LUOGO_RICHIESTO: {
					id: 2983,
					label: "b2b.CART.STEP-EDIT.LUOGO-RICHIESTO"
				},

				/**
				 * Modifica tappa con servizi
				 */
				MODIFICA_TAPPA_CON_SERVIZI: {
					id: 2984,
					label: "b2b.CART.STEP-EDIT.MODIFICA-TAPPA-CON-SERVIZI"
				},

				/**
				 * La tappa che stai modificando contiene dei servizi le cui date di inizio e/o durate potrebbero non essere più all'interno della tappa stessa
				 */
				MODIFICA_TAPPA_CON_SERVIZI_HELP: {
					id: 2985,
					label: "b2b.CART.STEP-EDIT.MODIFICA-TAPPA-CON-SERVIZI-HELP"
				},

				/**
				 * -- Non specificato --
				 */
				NON_SPECIFICATO: {
					id: 2986,
					label: "b2b.CART.STEP-EDIT.NON-SPECIFICATO"
				},

				/**
				 * Notti
				 */
				NOTTI: {
					id: 2987,
					label: "b2b.CART.STEP-EDIT.NOTTI"
				},

				/**
				 * Valori validi: 0 - 255
				 */
				NOTTI_VALIDE: {
					id: 2988,
					label: "b2b.CART.STEP-EDIT.NOTTI-VALIDE"
				},

				/**
				 * Nuova tappa
				 */
				NUOVA_TAPPA: {
					id: 2989,
					label: "b2b.CART.STEP-EDIT.NUOVA-TAPPA"
				},

				/**
				 * Nuovo luogo
				 */
				NUOVO_PRECEDENTE: {
					id: 2990,
					label: "b2b.CART.STEP-EDIT.NUOVO-PRECEDENTE"
				},

				/**
				 * Offset
				 */
				OFFSET: {
					id: 2991,
					label: "b2b.CART.STEP-EDIT.OFFSET"
				},

				/**
				 * Valori validi: da -10 a 10
				 */
				OFFSET_VALIDI: {
					id: 2992,
					label: "b2b.CART.STEP-EDIT.OFFSET-VALIDI"
				},

				/**
				 * Sarà necessaria una verifica prezzi
				 */
				PREZZI_DA_VERIFICARE: {
					id: 2993,
					label: "b2b.CART.STEP-EDIT.PREZZI-DA-VERIFICARE"
				},

				/**
				 * Salva
				 */
				SALVA: {
					id: 2994,
					label: "b2b.CART.STEP-EDIT.SALVA"
				},

				/**
				 * Salva modifiche
				 */
				SALVA_MODIFICHE: {
					id: 2995,
					label: "b2b.CART.STEP-EDIT.SALVA-MODIFICHE"
				},

				/**
				 * Servizi da cercare
				 */
				SERVIZI_DA_CERCARE: {
					id: 2996,
					label: "b2b.CART.STEP-EDIT.SERVIZI-DA-CERCARE"
				},

				/**
				 * Indicare che tipo di servizi cercare
				 */
				SERVIZI_DA_CERCARE_RICHIESTI: {
					id: 2997,
					label: "b2b.CART.STEP-EDIT.SERVIZI-DA-CERCARE-RICHIESTI"
				},

				/**
				 * Servizi che sono al termine della tappa
				 */
				SERVIZI_FINE_TAPPA: {
					id: 2998,
					label: "b2b.CART.STEP-EDIT.SERVIZI-FINE-TAPPA"
				},

				/**
				 * Resteranno al termine della tappa modificata
				 */
				SERVIZI_FINE_TAPPA_HELP: {
					id: 2999,
					label: "b2b.CART.STEP-EDIT.SERVIZI-FINE-TAPPA-HELP"
				},

				/**
				 * Servizi che sono all'inizio della tappa
				 */
				SERVIZI_INIZIO_TAPPA: {
					id: 3000,
					label: "b2b.CART.STEP-EDIT.SERVIZI-INIZIO-TAPPA"
				},

				/**
				 * Resteranno all'inizio della tappa modificata
				 */
				SERVIZI_INIZIO_TAPPA_HELP: {
					id: 3001,
					label: "b2b.CART.STEP-EDIT.SERVIZI-INIZIO-TAPPA-HELP"
				},

				/**
				 * Servizi che durano come l'intera tappa
				 */
				SERVIZI_INTERA_TAPPA: {
					id: 3002,
					label: "b2b.CART.STEP-EDIT.SERVIZI-INTERA-TAPPA"
				},

				/**
				 * Verranno adeguati in modo da coprire tutta la tappa modificata
				 */
				SERVIZI_INTERA_TAPPA_HELP: {
					id: 3003,
					label: "b2b.CART.STEP-EDIT.SERVIZI-INTERA-TAPPA-HELP"
				},

				/**
				 * Servizio
				 */
				SERVIZIO: {
					id: 3004,
					label: "b2b.CART.STEP-EDIT.SERVIZIO"
				},

				/**
				 * Servizio hotel
				 */
				SERVIZIO_HOTEL: {
					id: 3005,
					label: "b2b.CART.STEP-EDIT.SERVIZIO-HOTEL"
				},

				/**
				 * Strategia
				 */
				STRATEGIA: {
					id: 3006,
					label: "b2b.CART.STEP-EDIT.STRATEGIA"
				},

				/**
				 * Indicare la strategia
				 */
				STRATEGIA_RICHIESTA: {
					id: 3007,
					label: "b2b.CART.STEP-EDIT.STRATEGIA-RICHIESTA"
				},

				/**
				 * Tappa ID={id}
				 */
				TAPPA_ID: {
					id: 3008,
					label: "b2b.CART.STEP-EDIT.TAPPA-ID"
				},

				/**
				 * Tipo tappa
				 */
				TIPO_TAPPA: {
					id: 3009,
					label: "b2b.CART.STEP-EDIT.TIPO-TAPPA"
				},

				/**
				 * Indicare il tipo di tappa
				 */
				TIPO_TAPPA_RICHIESTO: {
					id: 3010,
					label: "b2b.CART.STEP-EDIT.TIPO-TAPPA-RICHIESTO"
				},

				/**
				 * Trasporto
				 */
				TRASPORTO: {
					id: 3011,
					label: "b2b.CART.STEP-EDIT.TRASPORTO"
				},

				/**
				 * Indicare le modalità di trasporto
				 */
				TRASPORTO_RICHIESTO: {
					id: 3012,
					label: "b2b.CART.STEP-EDIT.TRASPORTO-RICHIESTO"
				},

				/**
				 * Abbandona modifiche
				 */
				ABBANDONA_MODIFICHE: {
					id: 5994,
					label: "b2b.CART.STEP-EDIT.ABBANDONA-MODIFICHE"
				},

			},

			/**
			 * Content of path b2b.CART.STEPS
			 */
			STEPS: {
				/**
				 * Abbinamento
				 */
				ABBINAMENTO: {
					id: 3013,
					label: "b2b.CART.STEPS.ABBINAMENTO"
				},

				/**
				 * Accordo
				 */
				ACCORDO: {
					id: 3014,
					label: "b2b.CART.STEPS.ACCORDO"
				},

				/**
				 * ACC
				 */
				ACCORDO_ABBREVIATO: {
					id: 3015,
					label: "b2b.CART.STEPS.ACCORDO-ABBREVIATO"
				},

				/**
				 * al
				 */
				AL: {
					id: 3016,
					label: "b2b.CART.STEPS.AL"
				},

				/**
				 * Alternativa accettata dall'agenzia
				 */
				ALTERNATIVA_ACCETTATA: {
					id: 3017,
					label: "b2b.CART.STEPS.ALTERNATIVA-ACCETTATA"
				},

				/**
				 * ALT.A
				 */
				ALTERNATIVA_ACCETTATA_BADGE: {
					id: 3018,
					label: "b2b.CART.STEPS.ALTERNATIVA-ACCETTATA-BADGE"
				},

				/**
				 * Alternativa proposta da noi
				 */
				ALTERNATIVA_PROPOSTA: {
					id: 3019,
					label: "b2b.CART.STEPS.ALTERNATIVA-PROPOSTA"
				},

				/**
				 * ALT.P
				 */
				ALTERNATIVA_PROPOSTA_BADGE: {
					id: 3020,
					label: "b2b.CART.STEPS.ALTERNATIVA-PROPOSTA-BADGE"
				},

				/**
				 * Anticipato
				 */
				ANTICIPATO: {
					id: 3021,
					label: "b2b.CART.STEPS.ANTICIPATO"
				},

				/**
				 * ANT
				 */
				ANTICIPO_AL_FORNITORE: {
					id: 3022,
					label: "b2b.CART.STEPS.ANTICIPO-AL-FORNITORE"
				},

				/**
				 * Apri anagrafica accordo
				 */
				APRI_ANAGRAFICA_ACCORDO: {
					id: 3023,
					label: "b2b.CART.STEPS.APRI-ANAGRAFICA-ACCORDO"
				},

				/**
				 * Apri anagrafica servizio
				 */
				APRI_ANAGRAFICA_SERVIZIO: {
					id: 3024,
					label: "b2b.CART.STEPS.APRI-ANAGRAFICA-SERVIZIO"
				},

				/**
				 * Arrivo in {mezzo}
				 */
				ARRIVO_IN: {
					id: 3025,
					label: "b2b.CART.STEPS.ARRIVO-IN"
				},

				/**
				 * Tappa non modificabile collegata al servizio {svc}
				 */
				COLLEGATA_A: {
					id: 3026,
					label: "b2b.CART.STEPS.COLLEGATA-A"
				},

				/**
				 * Comprimi tutto
				 */
				COLLPASE_ALL: {
					id: 3027,
					label: "b2b.CART.STEPS.COLLPASE-ALL"
				},

				/**
				 * Conferma eliminazione
				 */
				CONFERMA_ELIMINAZIONE: {
					id: 3028,
					label: "b2b.CART.STEPS.CONFERMA-ELIMINAZIONE"
				},

				/**
				 * Confermi l'eliminazione di {svc}?
				 */
				CONFERMA_ELIMINAZIONE_SINGOLO: {
					id: 3029,
					label: "b2b.CART.STEPS.CONFERMA-ELIMINAZIONE-SINGOLO"
				},

				/**
				 * Confermi l'eliminazione di {count} servizi{count, plural, =0{} one{o} other{}}?
				 */
				CONFERMA_ELIMINAZIONE_TEXT: {
					id: 3030,
					label: "b2b.CART.STEPS.CONFERMA-ELIMINAZIONE-TEXT"
				},

				/**
				 * Confermi l'eliminazione di tutti i servizi?
				 */
				CONFERMA_ELIMINAZIONE_TUTTI: {
					id: 3031,
					label: "b2b.CART.STEPS.CONFERMA-ELIMINAZIONE-TUTTI"
				},

				/**
				 * Confermato
				 */
				CONFERMATO: {
					id: 3032,
					label: "b2b.CART.STEPS.CONFERMATO"
				},

				/**
				 * Costo
				 */
				COSTO: {
					id: 3033,
					label: "b2b.CART.STEPS.COSTO"
				},

				/**
				 * Da emettere entro
				 */
				DA_EMETTERE_ENTRO: {
					id: 3034,
					label: "b2b.CART.STEPS.DA-EMETTERE-ENTRO"
				},

				/**
				 * Da pagare entro
				 */
				DA_PAGARE_ENTRO: {
					id: 3035,
					label: "b2b.CART.STEPS.DA-PAGARE-ENTRO"
				},

				/**
				 * da
				 */
				DAL: {
					id: 3036,
					label: "b2b.CART.STEPS.DAL"
				},

				/**
				 * Data penale
				 */
				DATA_PENALE: {
					id: 3037,
					label: "b2b.CART.STEPS.DATA-PENALE"
				},

				/**
				 * Dettaglio anticipi
				 */
				DETTAGLIO_ANTICIPI: {
					id: 3038,
					label: "b2b.CART.STEPS.DETTAGLIO-ANTICIPI"
				},

				/**
				 * Dettaglio costi
				 */
				DETTAGLIO_COSTI: {
					id: 3039,
					label: "b2b.CART.STEPS.DETTAGLIO-COSTI"
				},

				/**
				 * Confermi di voler duplicare il servizio {svc}?
				 */
				DUPLICARE_RIGA: {
					id: 3040,
					label: "b2b.CART.STEPS.DUPLICARE-RIGA"
				},

				/**
				 * Duplicazione servizio
				 */
				DUPLICARE_RIGA_TITOLO: {
					id: 3041,
					label: "b2b.CART.STEPS.DUPLICARE-RIGA-TITOLO"
				},

				/**
				 * Elimina {svc} servizi{svc, plural, =0{} one{o} other{}}
				 */
				ELIMINA_N: {
					id: 3042,
					label: "b2b.CART.STEPS.ELIMINA-N"
				},

				/**
				 * Espandi tutto
				 */
				EXPANDE_ALL: {
					id: 3043,
					label: "b2b.CART.STEPS.EXPANDE-ALL"
				},

				/**
				 * Gap di {giorni} giorn{giorni, plural, =0{i} one{o} other{i}}
				 */
				GAP_GIORNI: {
					id: 3044,
					label: "b2b.CART.STEPS.GAP-GIORNI"
				},

				/**
				 * arrivo {offset} giorni dopo
				 */
				GIORNI_DOPO: {
					id: 3045,
					label: "b2b.CART.STEPS.GIORNI-DOPO"
				},

				/**
				 * arrivo {offset} giorni prima
				 */
				GIORNI_PRIMA: {
					id: 3046,
					label: "b2b.CART.STEPS.GIORNI-PRIMA"
				},

				/**
				 * arrivo il giorno dopo
				 */
				GIORNO_DOPO: {
					id: 3047,
					label: "b2b.CART.STEPS.GIORNO-DOPO"
				},

				/**
				 * arrivo il giorno prima
				 */
				GIORNO_PRIMA: {
					id: 3048,
					label: "b2b.CART.STEPS.GIORNO-PRIMA"
				},

				/**
				 * il
				 */
				IL: {
					id: 3049,
					label: "b2b.CART.STEPS.IL"
				},

				/**
				 * Inserisci i biglietti aerei per i documenti di viaggio
				 */
				IMPORT_TICKET_VOLI: {
					id: 3050,
					label: "b2b.CART.STEPS.IMPORT-TICKET-VOLI"
				},

				/**
				 * Incluso nel prezzo
				 */
				INCLUSO_NEL_PREZZO: {
					id: 3051,
					label: "b2b.CART.STEPS.INCLUSO-NEL-PREZZO"
				},

				/**
				 * Invii assicurazioni
				 */
				INVII_ASSICURAZIONI: {
					id: 3052,
					label: "b2b.CART.STEPS.INVII-ASSICURAZIONI"
				},

				/**
				 * Notti servizio e tappa differenti
				 */
				ITINERARIO_NOTTI_SERVIZIO: {
					id: 3053,
					label: "b2b.CART.STEPS.ITINERARIO-NOTTI-SERVIZIO"
				},

				/**
				 * Itinerario sovrapposto ad altri
				 */
				ITINERARIO_SOVRAPPOSTO: {
					id: 3054,
					label: "b2b.CART.STEPS.ITINERARIO-SOVRAPPOSTO"
				},

				/**
				 * Modifica dati conferme
				 */
				MODIFICA_DATI_CONFERME: {
					id: 3055,
					label: "b2b.CART.STEPS.MODIFICA-DATI-CONFERME"
				},

				/**
				 * Nome usato dal fornitore
				 */
				NOME_FORNITORE: {
					id: 3056,
					label: "b2b.CART.STEPS.NOME-FORNITORE"
				},

				/**
				 * Nome del servizio Naar
				 */
				NOME_NAAR: {
					id: 3057,
					label: "b2b.CART.STEPS.NOME-NAAR"
				},

				/**
				 * NS
				 */
				NOME_SERVIZIO: {
					id: 3058,
					label: "b2b.CART.STEPS.NOME-SERVIZIO"
				},

				/**
				 * NF
				 */
				NOME_SERVIZIO_FORNITORE: {
					id: 3059,
					label: "b2b.CART.STEPS.NOME-SERVIZIO-FORNITORE"
				},

				/**
				 * Nome del servizio Host-2-Host
				 */
				NOME_SERVIZIO_H2H: {
					id: 3060,
					label: "b2b.CART.STEPS.NOME-SERVIZIO-H2H"
				},

				/**
				 * Nome della tipologia Host-2-Host
				 */
				NOME_TIPOLOGIA_H2H: {
					id: 3061,
					label: "b2b.CART.STEPS.NOME-TIPOLOGIA-H2H"
				},

				/**
				 * Non rimborsabile
				 */
				NON_RIMBORSABILE: {
					id: 3062,
					label: "b2b.CART.STEPS.NON-RIMBORSABILE"
				},

				/**
				 * Note fornitore
				 */
				NOTE_FORNITORE: {
					id: 3063,
					label: "b2b.CART.STEPS.NOTE-FORNITORE"
				},

				/**
				 * {notti} nott{notti, plural, =0{i} one{e} other{i}}
				 */
				NOTTI: {
					id: 3064,
					label: "b2b.CART.STEPS.NOTTI"
				},

				/**
				 * {note} not{note, plural, =0{e} one{a} other{e}}
				 */
				NR_NOTE: {
					id: 3065,
					label: "b2b.CART.STEPS.NR-NOTE"
				},

				/**
				 * Pagamento sbloccato
				 */
				PAGAMENTO_SBLOCCATO: {
					id: 3066,
					label: "b2b.CART.STEPS.PAGAMENTO-SBLOCCATO"
				},

				/**
				 * Penale
				 */
				PENALE: {
					id: 3067,
					label: "b2b.CART.STEPS.PENALE"
				},

				/**
				 * Comprimi tutte le penali
				 */
				PENALI_COLLPASE_ALL: {
					id: 3068,
					label: "b2b.CART.STEPS.PENALI-COLLPASE-ALL"
				},

				/**
				 * Penali di cancellazione
				 */
				PENALI_DI_CANCELLAZIONE: {
					id: 3069,
					label: "b2b.CART.STEPS.PENALI-DI-CANCELLAZIONE"
				},

				/**
				 * Espandi tutte le penali
				 */
				PENALI_EXPANDE_ALL: {
					id: 3070,
					label: "b2b.CART.STEPS.PENALI-EXPANDE-ALL"
				},

				/**
				 * Prenotazione in corso
				 */
				PRENOTAZIONE_IN_CORSO: {
					id: 3071,
					label: "b2b.CART.STEPS.PRENOTAZIONE-IN-CORSO"
				},

				/**
				 * Prezzo EUR
				 */
				PREZZO: {
					id: 3072,
					label: "b2b.CART.STEPS.PREZZO"
				},

				/**
				 * Intestatario noleggio
				 */
				RENT_DRIVER: {
					id: 3073,
					label: "b2b.CART.STEPS.RENT-DRIVER"
				},

				/**
				 * Richiesto rimborso
				 */
				RICHIESTO_RIMBORSO: {
					id: 3074,
					label: "b2b.CART.STEPS.RICHIESTO-RIMBORSO"
				},

				/**
				 * Servizio duplicato
				 */
				RIGA_DUPLICATA: {
					id: 3075,
					label: "b2b.CART.STEPS.RIGA-DUPLICATA"
				},

				/**
				 * Sblocca pagamento fornitore
				 */
				SBLOCCA_PAGAMENTO: {
					id: 3076,
					label: "b2b.CART.STEPS.SBLOCCA-PAGAMENTO"
				},

				/**
				 * Sbloccare pagamento fornitore?
				 */
				SBLOCCA_PAGAMENTO_CONFERMA: {
					id: 3077,
					label: "b2b.CART.STEPS.SBLOCCA-PAGAMENTO-CONFERMA"
				},

				/**
				 * Verrà creata una riga nei servizi accessori per conservare i dati contabili del pagamento. La riga originale sarà poi svincolata
				 */
				SBLOCCA_PAGAMENTO_HELP: {
					id: 3078,
					label: "b2b.CART.STEPS.SBLOCCA-PAGAMENTO-HELP"
				},

				/**
				 * Eliminat{count, plural, =0{i} one{o} other{i}} {count} servizi{count, plural, =0{} one{o} other{}}.
				 */
				SERVIZI_ELIMINTI: {
					id: 3079,
					label: "b2b.CART.STEPS.SERVIZI-ELIMINTI"
				},

				/**
				 * Eliminati tutti i servizi.
				 */
				SERVIZI_TUTTI_ELIMINATI: {
					id: 3080,
					label: "b2b.CART.STEPS.SERVIZI-TUTTI-ELIMINATI"
				},

				/**
				 * Stampa itinerario
				 */
				STAMPA_ITINERARIO: {
					id: 3081,
					label: "b2b.CART.STEPS.STAMPA-ITINERARIO"
				},

				/**
				 * Svuota carrello
				 */
				SUOTA_CARRELLO: {
					id: 3082,
					label: "b2b.CART.STEPS.SUOTA-CARRELLO"
				},

				/**
				 * Tappe
				 */
				TITLE: {
					id: 3083,
					label: "b2b.CART.STEPS.TITLE"
				},

				/**
				 * Ultimo aggiornamento
				 */
				ULTIMO_AGGIORNAMENTO: {
					id: 3084,
					label: "b2b.CART.STEPS.ULTIMO-AGGIORNAMENTO"
				},

				/**
				 * Valuta cambiata. Costi ricalcolati con il nuovo cambio.
				 */
				VALUTA_CAMBIATA: {
					id: 3085,
					label: "b2b.CART.STEPS.VALUTA-CAMBIATA"
				},

				/**
				 * Vedi dettagli
				 */
				VEDI_DETTAGLI: {
					id: 3086,
					label: "b2b.CART.STEPS.VEDI-DETTAGLI"
				},

				/**
				 * Verifica prezzi di {svc} servizi{svc, plural, =0{} one{o} other{}}
				 */
				VERIFICA_N: {
					id: 3087,
					label: "b2b.CART.STEPS.VERIFICA-N"
				},

			},

			/**
			 * {id} - {nome}
			 */
			TITLE: {
				id: 3088,
				label: "b2b.CART.TITLE"
			},

			/**
			 * Content of path b2b.CART.VOUCHER
			 */
			VOUCHER: {
				/**
				 * Destinatario
				 */
				DESTINATARIO: {
					id: 3089,
					label: "b2b.CART.VOUCHER.DESTINATARIO"
				},

				/**
				 * Inserire un destinatario valido
				 */
				DESTINATARIO_NON_VALIDO: {
					id: 3090,
					label: "b2b.CART.VOUCHER.DESTINATARIO-NON-VALIDO"
				},

				/**
				 * Voucher duplicato
				 */
				DOCUMENTO_VOUCHER: {
					id: 3091,
					label: "b2b.CART.VOUCHER.DOCUMENTO-VOUCHER"
				},

				/**
				 * Confermi di voler duplicare il voucher ID={id}?
				 */
				DUPLICARE_VOUCHER: {
					id: 3092,
					label: "b2b.CART.VOUCHER.DUPLICARE-VOUCHER"
				},

				/**
				 * Duplicazione voucher
				 */
				DUPLICARE_VOUCHER_TITOLO: {
					id: 3093,
					label: "b2b.CART.VOUCHER.DUPLICARE-VOUCHER-TITOLO"
				},

				/**
				 * Confermi di voler eliminare il voucher ID={id}?
				 */
				ELIMINA_CONFERMA: {
					id: 3094,
					label: "b2b.CART.VOUCHER.ELIMINA-CONFERMA"
				},

				/**
				 * Confermi l'eliminazione del voucher ID={id}?
				 */
				ELIMINARE_VOUCHER: {
					id: 3095,
					label: "b2b.CART.VOUCHER.ELIMINARE-VOUCHER"
				},

				/**
				 * Eliminazione voucher
				 */
				ELIMINARE_VOUCHER_TITOLO: {
					id: 3096,
					label: "b2b.CART.VOUCHER.ELIMINARE-VOUCHER-TITOLO"
				},

				/**
				 * Confermi l'eliminazione di {n} voucher{n, plural, =0{} one{} other{}}?
				 */
				ELIMINARE_VOUCHERS: {
					id: 3097,
					label: "b2b.CART.VOUCHER.ELIMINARE-VOUCHERS"
				},

				/**
				 * Eliminazione voucher
				 */
				ELIMINARE_VOUCHERS_TITOLO: {
					id: 3098,
					label: "b2b.CART.VOUCHER.ELIMINARE-VOUCHERS-TITOLO"
				},

				/**
				 * Email inviata
				 */
				EMAIL_INVIATA: {
					id: 3099,
					label: "b2b.CART.VOUCHER.EMAIL-INVIATA"
				},

				/**
				 * Invia
				 */
				INVIA: {
					id: 3100,
					label: "b2b.CART.VOUCHER.INVIA"
				},

				/**
				 * Invio voucher
				 */
				INVIO_VOUCHER: {
					id: 3101,
					label: "b2b.CART.VOUCHER.INVIO-VOUCHER"
				},

				/**
				 * Lingua
				 */
				LINGUA: {
					id: 3102,
					label: "b2b.CART.VOUCHER.LINGUA"
				},

				/**
				 * Testo
				 */
				TESTO: {
					id: 3103,
					label: "b2b.CART.VOUCHER.TESTO"
				},

				/**
				 * Voucher
				 */
				VOUCHER: {
					id: 3104,
					label: "b2b.CART.VOUCHER.VOUCHER"
				},

				/**
				 * Voucher eliminato
				 */
				VOUCHER_ELIMINATO: {
					id: 3105,
					label: "b2b.CART.VOUCHER.VOUCHER-ELIMINATO"
				},

				/**
				 * Eliminat{n, plural, =0{i} one{o} other{i}} {n} voucher{n, plural, =0{} one{} other{}}
				 */
				VOUCHERS_ELIMINATI: {
					id: 3106,
					label: "b2b.CART.VOUCHER.VOUCHERS-ELIMINATI"
				},

				/**
				 * Abbinamento
				 */
				ABBINAMENTO: {
					id: 5890,
					label: "b2b.CART.VOUCHER.ABBINAMENTO"
				},

				/**
				 * Servizio
				 */
				SERVIZIO: {
					id: 5891,
					label: "b2b.CART.VOUCHER.SERVIZIO"
				},

				/**
				 * Genera
				 */
				BTN_GENERA_CAPTION: {
					id: 5901,
					label: "b2b.CART.VOUCHER.BTN-GENERA-CAPTION"
				},

				/**
				 * Content of path b2b.CART.VOUCHER.CB-SELEZIONA
				 */
				CB_SELEZIONA: {
					/**
					 * Seleziona
					 */
					CAPTION: {
						id: 5902,
						label: "b2b.CART.VOUCHER.CB-SELEZIONA.CAPTION"
					},

					/**
					 * Content of path b2b.CART.VOUCHER.CB-SELEZIONA.TOGGLE
					 */
					TOGGLE: {
						/**
						 * tutti
						 */
						FALSE: {
							id: 5903,
							label: "b2b.CART.VOUCHER.CB-SELEZIONA.TOGGLE.FALSE"
						},

						/**
						 * nessuno
						 */
						TRUE: {
							id: 5904,
							label: "b2b.CART.VOUCHER.CB-SELEZIONA.TOGGLE.TRUE"
						},

					},

				},

				/**
				 * Content of path b2b.CART.VOUCHER.CB-USE-NEW-PRINT-SERVER
				 */
				CB_USE_NEW_PRINT_SERVER: {
					/**
					 * Usa stampe
					 */
					CAPTION: {
						id: 5905,
						label: "b2b.CART.VOUCHER.CB-USE-NEW-PRINT-SERVER.CAPTION"
					},

					/**
					 * Content of path b2b.CART.VOUCHER.CB-USE-NEW-PRINT-SERVER.TOGGLE
					 */
					TOGGLE: {
						/**
						 * vecchie (NPS-Server)
						 */
						FALSE: {
							id: 5906,
							label: "b2b.CART.VOUCHER.CB-USE-NEW-PRINT-SERVER.TOGGLE.FALSE"
						},

						/**
						 * nuove (PRINT-Server)
						 */
						TRUE: {
							id: 5907,
							label: "b2b.CART.VOUCHER.CB-USE-NEW-PRINT-SERVER.TOGGLE.TRUE"
						},

					},

				},

				/**
				 * Data
				 */
				DATA: {
					id: 5908,
					label: "b2b.CART.VOUCHER.DATA"
				},

				/**
				 * Fornitore
				 */
				FORNITORE: {
					id: 5920,
					label: "b2b.CART.VOUCHER.FORNITORE"
				},

				/**
				 * Genera voucher per servizi manuali. (max {n})
				 */
				GENERA_EMPTY: {
					id: 5921,
					label: "b2b.CART.VOUCHER.GENERA-EMPTY"
				},

				/**
				 * ID
				 */
				ID: {
					id: 5922,
					label: "b2b.CART.VOUCHER.ID"
				},

				/**
				 * Luogo
				 */
				LUOGO: {
					id: 5926,
					label: "b2b.CART.VOUCHER.LUOGO"
				},

				/**
				 * Tipo emissione
				 */
				TIPO_EMISSIONE: {
					id: 5929,
					label: "b2b.CART.VOUCHER.TIPO-EMISSIONE"
				},

			},

			/**
			 * Content of path b2b.CART.VOUCHER-EDIT
			 */
			VOUCHER_EDIT: {
				/**
				 * Booking ref
				 */
				BOOKING_REF: {
					id: 3107,
					label: "b2b.CART.VOUCHER-EDIT.BOOKING-REF"
				},

				/**
				 * Booking ref non valido
				 */
				BOOKING_REF_NON_VALIDO: {
					id: 3108,
					label: "b2b.CART.VOUCHER-EDIT.BOOKING-REF-NON-VALIDO"
				},

				/**
				 * Clienti
				 */
				CLIENTI: {
					id: 3109,
					label: "b2b.CART.VOUCHER-EDIT.CLIENTI"
				},

				/**
				 * Clienti non validi
				 */
				CLIENTI_NON_VALIDI: {
					id: 3110,
					label: "b2b.CART.VOUCHER-EDIT.CLIENTI-NON-VALIDI"
				},

				/**
				 * Dettaglio
				 */
				DETTAGLIO: {
					id: 3111,
					label: "b2b.CART.VOUCHER-EDIT.DETTAGLIO"
				},

				/**
				 * Dettaglio non valido
				 */
				DETTAGLIO_NON_VALIDO: {
					id: 3112,
					label: "b2b.CART.VOUCHER-EDIT.DETTAGLIO-NON-VALIDO"
				},

				/**
				 * Travel instructions
				 */
				ISTRUZIONI: {
					id: 3113,
					label: "b2b.CART.VOUCHER-EDIT.ISTRUZIONI"
				},

				/**
				 * Nuovo voucher
				 */
				NUOVO_VOUCHER: {
					id: 3114,
					label: "b2b.CART.VOUCHER-EDIT.NUOVO-VOUCHER"
				},

				/**
				 * Ordine
				 */
				ORDINE: {
					id: 3115,
					label: "b2b.CART.VOUCHER-EDIT.ORDINE"
				},

				/**
				 * Ordine non valido
				 */
				ORDINE_NON_VALIDO: {
					id: 3116,
					label: "b2b.CART.VOUCHER-EDIT.ORDINE-NON-VALIDO"
				},

				/**
				 * Periodo
				 */
				PERIODO: {
					id: 3117,
					label: "b2b.CART.VOUCHER-EDIT.PERIODO"
				},

				/**
				 * Periodo non valido
				 */
				PERIODO_NON_VALIDO: {
					id: 3118,
					label: "b2b.CART.VOUCHER-EDIT.PERIODO-NON-VALIDO"
				},

				/**
				 * Servizio
				 */
				SERVIZIO: {
					id: 3119,
					label: "b2b.CART.VOUCHER-EDIT.SERVIZIO"
				},

				/**
				 * Il servizio è obbligatorio
				 */
				SERVIZIO_OBBLIGATORIO: {
					id: 3120,
					label: "b2b.CART.VOUCHER-EDIT.SERVIZIO-OBBLIGATORIO"
				},

				/**
				 * Testata
				 */
				TESTATA: {
					id: 3121,
					label: "b2b.CART.VOUCHER-EDIT.TESTATA"
				},

				/**
				 * Testata non valida
				 */
				TESTATA_NON_VALIDA: {
					id: 3122,
					label: "b2b.CART.VOUCHER-EDIT.TESTATA-NON-VALIDA"
				},

				/**
				 * Voucher ID={id}
				 */
				VOUCHER_ID: {
					id: 3123,
					label: "b2b.CART.VOUCHER-EDIT.VOUCHER-ID"
				},

				/**
				 * Voucher salvato
				 */
				VOUCHER_SALVATO: {
					id: 3124,
					label: "b2b.CART.VOUCHER-EDIT.VOUCHER-SALVATO"
				},

			},

			/**
			 * Content of path b2b.CART.CODE-VOLI
			 */
			CODE_VOLI: {
				/**
				 * Code voli
				 */
				TITOLO: {
					id: 6283,
					label: "b2b.CART.CODE-VOLI.TITOLO"
				},

				/**
				 * Nessun messaggio di coda presente
				 */
				ATTIVI_NON_PRESENTI: {
					id: 6544,
					label: "b2b.CART.CODE-VOLI.ATTIVI-NON-PRESENTI"
				},

				/**
				 * Nessun messaggio di coda presente
				 */
				CANCELLATI_NON_PRESENTI: {
					id: 6548,
					label: "b2b.CART.CODE-VOLI.CANCELLATI-NON-PRESENTI"
				},

			},

		},

		/**
		 * Content of path b2b.CATALOG
		 */
		CATALOG: {
			/**
			 * da
			 */
			A_PARTIRE_DA: {
				id: 3125,
				label: "b2b.CATALOG.A-PARTIRE-DA"
			},

			/**
			 * Calendario lavorazione preventivi e prenotazioni partenze 2022 e 2023
			 */
			CALENDARIO_LAVORAZIONE: {
				id: 3126,
				label: "b2b.CATALOG.CALENDARIO-LAVORAZIONE"
			},

			/**
			 * Consigliato
			 */
			CONSIGLIATO: {
				id: 3127,
				label: "b2b.CATALOG.CONSIGLIATO"
			},

			/**
			 * Consigliato da NAAR
			 */
			CONSIGLIATO_DA_NAAR: {
				id: 3128,
				label: "b2b.CATALOG.CONSIGLIATO-DA-NAAR"
			},

			/**
			 * Crea un viaggio in libertà
			 */
			CREA_IN_LIBERTA: {
				id: 3129,
				label: "b2b.CATALOG.CREA-IN-LIBERTA"
			},

			/**
			 * Crea un viaggio su misura da questo itinerario
			 */
			CREA_UN_VIAGGIO_DA_ITINERARIO: {
				id: 3130,
				label: "b2b.CATALOG.CREA-UN-VIAGGIO-DA-ITINERARIO"
			},

			/**
			 * Content of path b2b.CATALOG.DESCR
			 */
			DESCR: {
				/**
				 * Stima, per destinazione e area geografica, delle date di partenza da cui riprenderà l'attività di preventivazione e prenotazione.
				 */
				CALENDARIO_LAVORAZIONE: {
					id: 3131,
					label: "b2b.CATALOG.DESCR.CALENDARIO-LAVORAZIONE"
				},

				/**
				 * Una tappa, più tappe, volo + hotel, solo hotel, noleggio auto, moto, camper, escursioni: il massimo della flessibilità .
				 */
				CREA_IN_LIBERTA: {
					id: 3132,
					label: "b2b.CATALOG.DESCR.CREA-IN-LIBERTA"
				},

				/**
				 * Consulta e crea un viaggio scegliendo uno dei nostri itinerari consigliati; puoi aggiungere tappe, servizi, noleggio auto, personalizzare il numero notti.
				 */
				ITN_CONS: {
					id: 3133,
					label: "b2b.CATALOG.DESCR.ITN-CONS"
				},

				/**
				 * Una collezione dei migliori itinerari, flessibili e personalizzabili, per garantire un viaggio in totale tranquillità nel rispetto dei protocolli sanitari.
				 */
				ITN_CONS_BRAND_USA: {
					id: 3134,
					label: "b2b.CATALOG.DESCR.ITN-CONS-BRAND-USA"
				},

				/**
				 * Consulta, scegli e crea un viaggio da una proposta speciale con "prezzo finito". Commissione riconosciuta: 10% calcolata su servizi a terra e tariffa aerea (tasse escluse).
				 */
				OFFERTE: {
					id: 3135,
					label: "b2b.CATALOG.DESCR.OFFERTE"
				},

				/**
				 * Consulta, scegli e crea un viaggio da una proposta speciale con possibilità di adattarlo alle tue esigenze.
				 */
				PACCHETTI_VIAGGIO: {
					id: 3136,
					label: "b2b.CATALOG.DESCR.PACCHETTI-VIAGGIO"
				},

				/**
				 * Un metodo semplice che permette di velocizzare l’evasione del preventivo, ottimizzare al meglio le esigenze dei Clienti, fornire proposte sempre aggiornate e competitive che rispondano alle aspettative, tutto sempre con qualità e attenzione alla nostra co
				 */
				RICHIEDI_PREVENTIVO: {
					id: 3137,
					label: "b2b.CATALOG.DESCR.RICHIEDI-PREVENTIVO"
				},

				/**
				 * Scegli uno dei nostri itinerari predefiniti con hotel già inclusi, da completare con noleggio auto o moto; puoi aggiungere altre tappe, voli e servizi.
				 */
				SELF_DRIVE: {
					id: 3138,
					label: "b2b.CATALOG.DESCR.SELF-DRIVE"
				},

				/**
				 * Scegli un tour guidato, in pullman o in moto e crociere; puoi aggiungere voli, tappe post, estensioni mare, tutto quel che desideri.
				 */
				TOUR_GUIDATI: {
					id: 3139,
					label: "b2b.CATALOG.DESCR.TOUR-GUIDATI"
				},

				/**
				 * Tutti i tuoi viaggi, in un unico posto
				 */
				RICERCA_VIAGGI: {
					id: 6423,
					label: "b2b.CATALOG.DESCR.RICERCA-VIAGGI"
				},

				/**
				 * Content of path b2b.CATALOG.DESCR.FLY-BEACH
				 */
				FLY_BEACH: {
				},

				/**
				 * Content of path b2b.CATALOG.DESCR.FLY-CRUISE
				 */
				FLY_CRUISE: {
				},

				/**
				 * Content of path b2b.CATALOG.DESCR.FLY-CITY
				 */
				FLY_CITY: {
				},

				/**
				 * Content of path b2b.CATALOG.DESCR.FLY-TOUR
				 */
				FLY_TOUR: {
				},

			},

			/**
			 * Itinerari consigliati
			 */
			ITN_CONS: {
				id: 3140,
				label: "b2b.CATALOG.ITN-CONS"
			},

			/**
			 * Progetti di Viaggio 2022 e 2023
			 */
			ITN_CONS_BRAND_USA: {
				id: 3141,
				label: "b2b.CATALOG.ITN-CONS-BRAND-USA"
			},

			/**
			 * Offerte viaggio
			 */
			OFFERTE: {
				id: 3142,
				label: "b2b.CATALOG.OFFERTE"
			},

			/**
			 * Pacchetti viaggio
			 */
			PACCHETTI_VIAGGIO: {
				id: 3143,
				label: "b2b.CATALOG.PACCHETTI-VIAGGIO"
			},

			/**
			 * Partenza del 
			 */
			PARTENZA_DEL: {
				id: 3144,
				label: "b2b.CATALOG.PARTENZA-DEL"
			},

			/**
			 * Partenza individuale
			 */
			PARTENZA_INDIVIDUALE: {
				id: 3145,
				label: "b2b.CATALOG.PARTENZA-INDIVIDUALE"
			},

			/**
			 * Partenze disponibili
			 */
			PARTENZE_DISPONIBILI: {
				id: 3146,
				label: "b2b.CATALOG.PARTENZE-DISPONIBILI"
			},

			/**
			 * Modulo di richiesta preventivo - Viaggi su misura
			 */
			RICHIEDI_PREVENTIVO: {
				id: 3147,
				label: "b2b.CATALOG.RICHIEDI-PREVENTIVO"
			},

			/**
			 * Scheda tecnica
			 */
			SCHEDA_TECNICA: {
				id: 3148,
				label: "b2b.CATALOG.SCHEDA_TECNICA"
			},

			/**
			 * Seleziona una partenza di gruppo
			 */
			SELEZIONA_PARTENZA: {
				id: 3149,
				label: "b2b.CATALOG.SELEZIONA-PARTENZA"
			},

			/**
			 * Consulta i nostri self-drive e crea un viaggio
			 */
			SELF_DRIVE: {
				id: 3150,
				label: "b2b.CATALOG.SELF-DRIVE"
			},

			/**
			 * Content of path b2b.CATALOG.SERVICE-DETAIL
			 */
			SERVICE_DETAIL: {
				/**
				 * Info
				 */
				DATI_AGGIUNTIVI: {
					id: 3151,
					label: "b2b.CATALOG.SERVICE-DETAIL.DATI-AGGIUNTIVI"
				},

				/**
				 * Gallery
				 */
				GALLERY: {
					id: 3152,
					label: "b2b.CATALOG.SERVICE-DETAIL.GALLERY"
				},

				/**
				 * Mappa
				 */
				MAPPA: {
					id: 3153,
					label: "b2b.CATALOG.SERVICE-DETAIL.MAPPA"
				},

				/**
				 * Tappe
				 */
				TAPPE: {
					id: 3154,
					label: "b2b.CATALOG.SERVICE-DETAIL.TAPPE"
				},

				/**
				 * Itinerario
				 */
				TESTI: {
					id: 3155,
					label: "b2b.CATALOG.SERVICE-DETAIL.TESTI"
				},

				/**
				 * Apri articolo
				 */
				VEDI_ARTICOLO: {
					id: 6313,
					label: "b2b.CATALOG.SERVICE-DETAIL.VEDI-ARTICOLO"
				},

				/**
				 * Generazione della descrizione tramite AI...
				 */
				GENERAZIONE_AI: {
					id: 6592,
					label: "b2b.CATALOG.SERVICE-DETAIL.GENERAZIONE-AI"
				},

			},

			/**
			 * Scopri il tipo di viaggio da creare
			 */
			SUBTITLE: {
				id: 3156,
				label: "b2b.CATALOG.SUBTITLE"
			},

			/**
			 * Content of path b2b.CATALOG.SUMMARY-BOX
			 */
			SUMMARY_BOX: {
				/**
				 * Torna in cima
				 */
				BACK_TO_TOP: {
					id: 3157,
					label: "b2b.CATALOG.SUMMARY-BOX.BACK-TO-TOP"
				},

				/**
				 * Scheda tecnica
				 */
				DATI: {
					id: 3158,
					label: "b2b.CATALOG.SUMMARY-BOX.DATI"
				},

				/**
				 * Maggiori informazioni
				 */
				DETTAGLI: {
					id: 3159,
					label: "b2b.CATALOG.SUMMARY-BOX.DETTAGLI"
				},

				/**
				 * Galleria
				 */
				ELENCO_IMMAGINI: {
					id: 3160,
					label: "b2b.CATALOG.SUMMARY-BOX.ELENCO-IMMAGINI"
				},

				/**
				 * Informazioni principali
				 */
				INFO_BOX: {
					id: 3161,
					label: "b2b.CATALOG.SUMMARY-BOX.INFO-BOX"
				},

				/**
				 * Partenze
				 */
				PARTENZE: {
					id: 3162,
					label: "b2b.CATALOG.SUMMARY-BOX.PARTENZE"
				},

				/**
				 * Tappe
				 */
				TAPPE: {
					id: 3163,
					label: "b2b.CATALOG.SUMMARY-BOX.TAPPE"
				},

				/**
				 * Stampa
				 */
				STAMPA: {
					id: 6311,
					label: "b2b.CATALOG.SUMMARY-BOX.STAMPA"
				},

			},

			/**
			 * Tappe
			 */
			TAPPE_PRINCIPALI: {
				id: 3164,
				label: "b2b.CATALOG.TAPPE-PRINCIPALI"
			},

			/**
			 * {numOfStages} Tapp{numOfStages, plural, =0{e} one{a} other{e}}
			 */
			TAPPE_PRINCIPALI_N: {
				id: 3165,
				label: "b2b.CATALOG.TAPPE-PRINCIPALI-N"
			},

			/**
			 * Crea un viaggio
			 */
			TITLE: {
				id: 3166,
				label: "b2b.CATALOG.TITLE"
			},

			/**
			 * Consulta i nostri tour guidati - crociere e crea un viaggio
			 */
			TOUR_GUIDATI: {
				id: 3167,
				label: "b2b.CATALOG.TOUR-GUIDATI"
			},

			/**
			 * I tuoi viaggi
			 */
			RICERCA_VIAGGI: {
				id: 6421,
				label: "b2b.CATALOG.RICERCA-VIAGGI"
			},

			/**
			 * Content of path b2b.CATALOG.FLY-BEACH
			 */
			FLY_BEACH: {
			},

			/**
			 * Content of path b2b.CATALOG.FLY-CRUISE
			 */
			FLY_CRUISE: {
			},

			/**
			 * Content of path b2b.CATALOG.FLY-CITY
			 */
			FLY_CITY: {
			},

			/**
			 * Content of path b2b.CATALOG.FLY-TOUR
			 */
			FLY_TOUR: {
			},

		},

		/**
		 * Content of path b2b.COMMON
		 */
		COMMON: {
			/**
			 * A
			 */
			A: {
				id: 3168,
				label: "b2b.COMMON.A"
			},

			/**
			 * Adulti
			 */
			ADULTI: {
				id: 3169,
				label: "b2b.COMMON.ADULTI"
			},

			/**
			 * Aggiungi
			 */
			AGGIUNGI: {
				id: 3170,
				label: "b2b.COMMON.AGGIUNGI"
			},

			/**
			 * Al
			 */
			AL: {
				id: 3171,
				label: "b2b.COMMON.AL"
			},

			/**
			 * Annulla
			 */
			ANNULLA: {
				id: 3172,
				label: "b2b.COMMON.ANNULLA"
			},

			/**
			 * Bambini
			 */
			BAMBINI: {
				id: 3173,
				label: "b2b.COMMON.BAMBINI"
			},

			/**
			 * Cambia
			 */
			CAMBIA: {
				id: 3174,
				label: "b2b.COMMON.CAMBIA"
			},

			/**
			 * Cancella
			 */
			CANCELLA: {
				id: 3175,
				label: "b2b.COMMON.CANCELLA"
			},

			/**
			 * Caricamento in corso...
			 */
			CARICAMENTO_IN_CORSO: {
				id: 3176,
				label: "b2b.COMMON.CARICAMENTO-IN-CORSO"
			},

			/**
			 * Cerca
			 */
			CERCA: {
				id: 3177,
				label: "b2b.COMMON.CERCA"
			},

			/**
			 * Chiudi
			 */
			CHIUDI: {
				id: 3178,
				label: "b2b.COMMON.CHIUDI"
			},

			/**
			 * Chiudi dopo il salvataggio
			 */
			CHIUDI_DOPO_SALVATAGGIO: {
				id: 3179,
				label: "b2b.COMMON.CHIUDI-DOPO-SALVATAGGIO"
			},

			/**
			 * Conferma
			 */
			CONFERMA: {
				id: 3180,
				label: "b2b.COMMON.CONFERMA"
			},

			/**
			 * Crea
			 */
			CREA: {
				id: 3181,
				label: "b2b.COMMON.CREA"
			},

			/**
			 * Da
			 */
			DA: {
				id: 3182,
				label: "b2b.COMMON.DA"
			},

			/**
			 * Dal
			 */
			DAL: {
				id: 3183,
				label: "b2b.COMMON.DAL"
			},

			/**
			 * Dashboard
			 */
			DASHBOARD: {
				id: 3184,
				label: "b2b.COMMON.DASHBOARD"
			},

			/**
			 * Dati salvati
			 */
			DATI_SALVATI: {
				id: 3185,
				label: "b2b.COMMON.DATI-SALVATI"
			},

			/**
			 * ritiro [0] il [1:dd/MM/yyyy] ore [1:HH] - rilascio [2] il [3:dd/MM/yyyy] ore [3:HH]
			 */
			DETTAGLIO_NOLEGGIO_FORMAT: {
				id: 3186,
				label: "b2b.COMMON.DETTAGLIO-NOLEGGIO-FORMAT"
			},

			/**
			 * Duplica
			 */
			DUPLICA: {
				id: 3187,
				label: "b2b.COMMON.DUPLICA"
			},

			/**
			 * Elimina
			 */
			ELIMINA: {
				id: 3188,
				label: "b2b.COMMON.ELIMINA"
			},

			/**
			 * Elimina tutti
			 */
			ELIMINA_TUTTI: {
				id: 3189,
				label: "b2b.COMMON.ELIMINA-TUTTI"
			},

			/**
			 * EMISSIONE ENTRO IL
			 */
			EMISSIONE_ENTRO_IL: {
				id: 3190,
				label: "b2b.COMMON.EMISSIONE-ENTRO-IL"
			},

			/**
			 * Espandi
			 */
			ESPANDI: {
				id: 3191,
				label: "b2b.COMMON.ESPANDI"
			},

			/**
			 * Esporta
			 */
			ESPORTA: {
				id: 3192,
				label: "b2b.COMMON.ESPORTA"
			},

			/**
			 * Prezzo pieno
			 */
			FULL_PRICE: {
				id: 3193,
				label: "b2b.COMMON.FULL-PRICE"
			},

			/**
			 * gg/mm/aaaa
			 */
			GG_MM_AAAA: {
				id: 3194,
				label: "b2b.COMMON.GG-MM-AAAA"
			},

			/**
			 * {giorni} giorn{giorni, plural, =0{i} one{o} other{i}}
			 */
			GIORNI: {
				id: 3195,
				label: "b2b.COMMON.GIORNI"
			},

			/**
			 * giorni
			 */
			GIORNI_GENERICO: {
				id: 3196,
				label: "b2b.COMMON.GIORNI-GENERICO"
			},

			/**
			 * hh:mm
			 */
			HH_MM: {
				id: 3197,
				label: "b2b.COMMON.HH:MM"
			},

			/**
			 * Modifica
			 */
			MODIFICA: {
				id: 3198,
				label: "b2b.COMMON.MODIFICA"
			},

			/**
			 * Mostra
			 */
			MOSTRA: {
				id: 3199,
				label: "b2b.COMMON.MOSTRA"
			},

			/**
			 * Nascondi
			 */
			NASCONDI: {
				id: 3200,
				label: "b2b.COMMON.NASCONDI"
			},

			/**
			 * Nessun elemento trovato
			 */
			NESSUN_ELEMENTO_TROVATO: {
				id: 3201,
				label: "b2b.COMMON.NESSUN-ELEMENTO-TROVATO"
			},

			/**
			 * Nessuno
			 */
			NESSUNO: {
				id: 3202,
				label: "b2b.COMMON.NESSUNO"
			},

			/**
			 * News
			 */
			NEWS: {
				id: 3203,
				label: "b2b.COMMON.NEWS"
			},

			/**
			 * No
			 */
			NO: {
				id: 3204,
				label: "b2b.COMMON.NO"
			},

			/**
			 * Tutti
			 */
			NOME_GRUPPO_TUTTI: {
				id: 3205,
				label: "b2b.COMMON.NOME-GRUPPO-TUTTI"
			},

			/**
			 * non disponibile
			 */
			NON_DISPONIBILE: {
				id: 3206,
				label: "b2b.COMMON.NON-DISPONIBILE"
			},

			/**
			 * non rimborsabile
			 */
			NON_RIMBORSABILE: {
				id: 3207,
				label: "b2b.COMMON.NON-RIMBORSABILE"
			},

			/**
			 * {notti} nott{notti, plural, =0{i} one{e} other{i}}
			 */
			NOTTI: {
				id: 3208,
				label: "b2b.COMMON.NOTTI"
			},

			/**
			 * notti
			 */
			NOTTI_GENERICO: {
				id: 3209,
				label: "b2b.COMMON.NOTTI-GENERICO"
			},

			/**
			 * Ok
			 */
			OK: {
				id: 3210,
				label: "b2b.COMMON.OK"
			},

			/**
			 * Operazione annullata
			 */
			OPERAZIONE_ANNULLATA: {
				id: 3211,
				label: "b2b.COMMON.OPERAZIONE-ANNULLATA"
			},

			/**
			 * Operazione eseguita
			 */
			OPERAZIONE_ESEGUITA: {
				id: 3212,
				label: "b2b.COMMON.OPERAZIONE-ESEGUITA"
			},

			/**
			 * pagamento immediato
			 */
			PAGAMENTO_IMMEDIATO: {
				id: 3213,
				label: "b2b.COMMON.PAGAMENTO-IMMEDIATO"
			},

			/**
			 * part.
			 */
			PARTENZA_TRATTA_VOLO: {
				id: 3214,
				label: "b2b.COMMON.PARTENZA-TRATTA-VOLO"
			},

			/**
			 * Penale di cancellazione € [0] se annullata dopo il [1]
			 */
			PENALE_SE_ANNULLATA_ENTRO: {
				id: 3215,
				label: "b2b.COMMON.PENALE-SE-ANNULLATA-ENTRO"
			},

			/**
			 * {qty} per pagina
			 */
			PER_PAGINA: {
				id: 3216,
				label: "b2b.COMMON.PER-PAGINA"
			},

			/**
			 * persone
			 */
			PERSONE_GENERICO: {
				id: 3217,
				label: "b2b.COMMON.PERSONE-GENERICO"
			},

			/**
			 * prezzo indicativo
			 */
			PREZZO_INDICATIVO: {
				id: 3218,
				label: "b2b.COMMON.PREZZO-INDICATIVO"
			},

			/**
			 * Ricarica
			 */
			RICARICA: {
				id: 3219,
				label: "b2b.COMMON.RICARICA"
			},

			/**
			 * Ricorda di salvare le modifiche!
			 */
			RICORDA_DI_SALVARE: {
				id: 3220,
				label: "b2b.COMMON.RICORDA-DI-SALVARE"
			},

			/**
			 * Rimuovi
			 */
			RIMUOVI: {
				id: 3221,
				label: "b2b.COMMON.RIMUOVI"
			},

			/**
			 * Salva
			 */
			SALVA: {
				id: 3222,
				label: "b2b.COMMON.SALVA"
			},

			/**
			 * Scarica Markdown
			 */
			SCARICA_MARKDOWN: {
				id: 3223,
				label: "b2b.COMMON.SCARICA-MARKDOWN"
			},

			/**
			 * Scarica PDF
			 */
			SCARICA_PDF: {
				id: 3224,
				label: "b2b.COMMON.SCARICA-PDF"
			},

			/**
			 * Scegli
			 */
			SCEGLI: {
				id: 3225,
				label: "b2b.COMMON.SCEGLI"
			},

			/**
			 * {selected} selezionat{selected, plural, =0{e} one{a} other{e}}
			 */
			SELEZIONATE: {
				id: 3226,
				label: "b2b.COMMON.SELEZIONATE"
			},

			/**
			 * {selected} selezionat{selected, plural, =0{i} one{o} other{i}}
			 */
			SELEZIONATI: {
				id: 3227,
				label: "b2b.COMMON.SELEZIONATI"
			},

			/**
			 * Sessione
			 */
			SESSIONE: {
				id: 3228,
				label: "b2b.COMMON.SESSIONE"
			},

			/**
			 * Sì
			 */
			SI: {
				id: 3229,
				label: "b2b.COMMON.SI"
			},

			/**
			 * Soluzione [0] [1] [2] Tariffa aerea itinerario [3] (NON PRENOTATO) [4] - Soggetto a modifica fino a conferma
			 */
			TARIFFA_AEREA: {
				id: 3230,
				label: "b2b.COMMON.TARIFFA-AEREA"
			},

			/**
			 * TARIFFA TO
			 */
			TARIFFA_TO: {
				id: 3231,
				label: "b2b.COMMON.TARIFFA-TO"
			},

			/**
			 * Soluzione [0] [1] [2] Tasse itinerario [3] (NON PRENOTATO) - Soggette a modifica fino ad emissione biglietti
			 */
			TASSE_TARIFFA_AEREA: {
				id: 3232,
				label: "b2b.COMMON.TASSE-TARIFFA-AEREA"
			},

			/**
			 * Content of path b2b.COMMON.TEMPO
			 */
			TEMPO: {
				/**
				 * minuti
				 */
				MINUTI: {
					id: 3233,
					label: "b2b.COMMON.TEMPO.MINUTI"
				},

				/**
				 * ore
				 */
				ORE: {
					id: 3234,
					label: "b2b.COMMON.TEMPO.ORE"
				},

				/**
				 * secondi
				 */
				SECONDI: {
					id: 3235,
					label: "b2b.COMMON.TEMPO.SECONDI"
				},

			},

			/**
			 * Tutti
			 */
			TUTTI: {
				id: 3236,
				label: "b2b.COMMON.TUTTI"
			},

			/**
			 * Verificare i dati immessi
			 */
			VERIFICARE_I_DATI_IMMESSI: {
				id: 3237,
				label: "b2b.COMMON.VERIFICARE-I-DATI-IMMESSI"
			},

			/**
			 * Visualizza
			 */
			VISUALIZZA: {
				id: 3238,
				label: "b2b.COMMON.VISUALIZZA"
			},

			/**
			 * Visualizzat{shown, plural, =0{i} one{o} other{i}} {shown} di {total}
			 */
			VISUALIZZATI: {
				id: 3239,
				label: "b2b.COMMON.VISUALIZZATI"
			},

			/**
			 * Visualizzat{shown, plural, =0{i} one{o} other{i}} {start}-{end} di {total}
			 */
			VISUALIZZATI_RANGE: {
				id: 3240,
				label: "b2b.COMMON.VISUALIZZATI-RANGE"
			},

			/**
			 * BAGAGLIO NON INCLUSO
			 */
			VOLI_BAGAGLIO_NON_INCLUSO: {
				id: 3241,
				label: "b2b.COMMON.VOLI-BAGAGLIO-NON-INCLUSO"
			},

			/**
			 * classe business
			 */
			VOLI_BUSINESS: {
				id: 3242,
				label: "b2b.COMMON.VOLI-BUSINESS"
			},

			/**
			 * classe economica
			 */
			VOLI_ECONOMY: {
				id: 3243,
				label: "b2b.COMMON.VOLI-ECONOMY"
			},

			/**
			 * EMISSIONE ENTRO IL
			 */
			VOLI_EMISSIONE: {
				id: 3244,
				label: "b2b.COMMON.VOLI-EMISSIONE"
			},

			/**
			 * classe first
			 */
			VOLI_FIRST: {
				id: 3245,
				label: "b2b.COMMON.VOLI-FIRST"
			},

			/**
			 * itinerario
			 */
			VOLI_ITINERARIO: {
				id: 3246,
				label: "b2b.COMMON.VOLI-ITINERARIO"
			},

			/**
			 * La tariffa è indicativa e soggetta a variazione fino alla data di emissione
			 */
			VOLI_NOPNR_IP_TARIFFA_MODIFICA: {
				id: 3247,
				label: "b2b.COMMON.VOLI-NOPNR-IP-TARIFFA-MODIFICA"
			},

			/**
			 * La tariffa è indicativa e soggetta a variazione fino alla data di emissione
			 */
			VOLI_NOPNR_TO_TARIFFA_MODIFICA: {
				id: 3248,
				label: "b2b.COMMON.VOLI-NOPNR-TO-TARIFFA-MODIFICA"
			},

			/**
			 * NON RIMBORSABILE
			 */
			VOLI_NOT_REF: {
				id: 3249,
				label: "b2b.COMMON.VOLI-NOT-REF"
			},

			/**
			 * Opzione/autocancellazione
			 */
			VOLI_OPZIONE_AUTOCANCELLAZIONE: {
				id: 3250,
				label: "b2b.COMMON.VOLI-OPZIONE-AUTOCANCELLAZIONE"
			},

			/**
			 * Pagamento Immediato NON RIMBORSABILE
			 */
			VOLI_PAGAMENTO_IMMEDIATO: {
				id: 3251,
				label: "b2b.COMMON.VOLI-PAGAMENTO-IMMEDIATO"
			},

			/**
			 * part.
			 */
			VOLI_SEGMENTO_PARTENZA: {
				id: 3252,
				label: "b2b.COMMON.VOLI-SEGMENTO-PARTENZA"
			},

			/**
			 * Soluzione
			 */
			VOLI_SOLUZIONE: {
				id: 3253,
				label: "b2b.COMMON.VOLI-SOLUZIONE"
			},

			/**
			 * Tariffa aerea instant purchase (IP)
			 */
			VOLI_TARIFFA_IP: {
				id: 3254,
				label: "b2b.COMMON.VOLI-TARIFFA-IP"
			},

			/**
			 * Soggetta a modifica fino ad emissione biglietti
			 */
			VOLI_TARIFFA_MODIFICA: {
				id: 3255,
				label: "b2b.COMMON.VOLI-TARIFFA-MODIFICA"
			},

			/**
			 * Tariffa aerea tour operator (IT)
			 */
			VOLI_TARIFFA_TO: {
				id: 3256,
				label: "b2b.COMMON.VOLI-TARIFFA-TO"
			},

			/**
			 * Tasse (IP)
			 */
			VOLI_TASSE_IP: {
				id: 3257,
				label: "b2b.COMMON.VOLI-TASSE-IP"
			},

			/**
			 * Soggette a modifica fino ad emissione biglietti
			 */
			VOLI_TASSE_MODIFICA: {
				id: 3258,
				label: "b2b.COMMON.VOLI-TASSE-MODIFICA"
			},

			/**
			 * Tasse (IT)
			 */
			VOLI_TASSE_TO: {
				id: 3259,
				label: "b2b.COMMON.VOLI-TASSE-TO"
			},

			/**
			 * Caricamento dati...
			 */
			WAITING_FOR_DATA: {
				id: 5958,
				label: "b2b.COMMON.WAITING-FOR-DATA"
			},

			/**
			 * Tariffa aerea NDC
			 */
			VOLI_TARIFFA_NDC: {
				id: 5990,
				label: "b2b.COMMON.VOLI-TARIFFA-NDC"
			},

			/**
			 * Tasse (NDC)
			 */
			VOLI_TASSE_NDC: {
				id: 5991,
				label: "b2b.COMMON.VOLI-TASSE-NDC"
			},

			/**
			 * Invio email
			 */
			INVIO_MAIL: {
				id: 6244,
				label: "b2b.COMMON.INVIO-MAIL"
			},

			/**
			 * Sostituisci
			 */
			SOSTITUISCI: {
				id: 6259,
				label: "b2b.COMMON.SOSTITUISCI"
			},

			/**
			 * Mostra eliminati
			 */
			MOSTRA_ELIMINATI: {
				id: 6285,
				label: "b2b.COMMON.MOSTRA-ELIMINATI"
			},

			/**
			 * Testo tradotto automaticamente
			 */
			TESTO_TRADOTTO_AUTOMATICAMENTE: {
				id: 6306,
				label: "b2b.COMMON.TESTO-TRADOTTO-AUTOMATICAMENTE"
			},

			/**
			 * Ignora
			 */
			IGNORA: {
				id: 6440,
				label: "b2b.COMMON.IGNORA"
			},

			/**
			 * Esegui
			 */
			ESEGUI: {
				id: 6518,
				label: "b2b.COMMON.ESEGUI"
			},

			/**
			 * Testo generato da AI
			 */
			TESTO_GENERATO_AI: {
				id: 6553,
				label: "b2b.COMMON.TESTO-GENERATO-AI"
			},

			/**
			 * notte
			 */
			NOTTE: {
				id: 6631,
				label: "b2b.COMMON.NOTTE"
			},

		},

		/**
		 * Content of path b2b.DASHBOARD
		 */
		DASHBOARD: {
			/**
			 * Destinazioni In Evidenza
			 */
			DESTINAZIONI_IN_EVIDENZA: {
				id: 3260,
				label: "b2b.DASHBOARD.DESTINAZIONI-IN-EVIDENZA"
			},

			/**
			 * Ho capito, chiudi
			 */
			LBL_PRESA_VISIONE: {
				id: 3261,
				label: "b2b.DASHBOARD.LBL-PRESA-VISIONE"
			},

			/**
			 * Presa visione
			 */
			LBL_PRESA_VISONE: {
				id: 3262,
				label: "b2b.DASHBOARD.LBL-PRESA-VISONE"
			},

			/**
			 * Scarica l'informativa sulla privacy
			 */
			LBL_SCARICA_INFORMATIVA: {
				id: 3263,
				label: "b2b.DASHBOARD.LBL-SCARICA-INFORMATIVA"
			},

			/**
			 * Scorrere fino alla fine
			 */
			PRIVACY_HOWTO: {
				id: 3264,
				label: "b2b.DASHBOARD.PRIVACY-HOWTO"
			},

			/**
			 * Trattamento dei dati personali per la richiesta e fruizione dei servizi di Naar Tour Operator s.p.a.
			 */
			PRIVACY_LABEL: {
				id: 3265,
				label: "b2b.DASHBOARD.PRIVACY-LABEL"
			},

			/**
			 * Promozioni In Corso
			 */
			PROMOZIONI_IN_CORSO: {
				id: 3266,
				label: "b2b.DASHBOARD.PROMOZIONI-IN-CORSO"
			},

			/**
			 * Seguici su
			 */
			SOCIAL_BOX_TITLE: {
				id: 3267,
				label: "b2b.DASHBOARD.SOCIAL-BOX-TITLE"
			},

			/**
			 * Dashboard
			 */
			TITLE: {
				id: 3268,
				label: "b2b.DASHBOARD.TITLE"
			},

			/**
			 * Nessuna news
			 */
			NO_NEWS: {
				id: 6120,
				label: "b2b.DASHBOARD.NO-NEWS"
			},

			/**
			 * Video help
			 */
			HELP: {
				id: 6329,
				label: "b2b.DASHBOARD.HELP"
			},

		},

		/**
		 * Content of path b2b.ENUMS
		 */
		ENUMS: {
			/**
			 * Content of path b2b.ENUMS.EAutofillMinStars
			 */
			EAutofillMinStars: {
				/**
				 * Nessuna
				 */
				None: {
					id: 3269,
					label: "b2b.ENUMS.EAutofillMinStars.None"
				},

				/**
				 * 1 stella o superiore
				 */
				Stars1: {
					id: 3270,
					label: "b2b.ENUMS.EAutofillMinStars.Stars1"
				},

				/**
				 * 2 stelle o superiore
				 */
				Stars2: {
					id: 3271,
					label: "b2b.ENUMS.EAutofillMinStars.Stars2"
				},

				/**
				 * 3 stelle o superiore
				 */
				Stars3: {
					id: 3272,
					label: "b2b.ENUMS.EAutofillMinStars.Stars3"
				},

				/**
				 * 4 stelle o superiore
				 */
				Stars4: {
					id: 3273,
					label: "b2b.ENUMS.EAutofillMinStars.Stars4"
				},

				/**
				 * 5 stelle
				 */
				Stars5: {
					id: 3274,
					label: "b2b.ENUMS.EAutofillMinStars.Stars5"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EAutofillOperation
			 */
			EAutofillOperation: {
				/**
				 * Hotel
				 */
				Hotel: {
					id: 3275,
					label: "b2b.ENUMS.EAutofillOperation.Hotel"
				},

				/**
				 * Hotel + Trasferimento collettivo con visita città 
				 */
				HotelAndGroupCityViewTransfert: {
					id: 3276,
					label: "b2b.ENUMS.EAutofillOperation.HotelAndGroupCityViewTransfert"
				},

				/**
				 * Hotel + Trasferimento collettivo
				 */
				HotelAndGroupTransfert: {
					id: 3277,
					label: "b2b.ENUMS.EAutofillOperation.HotelAndGroupTransfert"
				},

				/**
				 * Hotel + Trasferimento privato con visita città 
				 */
				HotelAndPrivateCityViewTransfert: {
					id: 3278,
					label: "b2b.ENUMS.EAutofillOperation.HotelAndPrivateCityViewTransfert"
				},

				/**
				 * Hotel + Trasferimento privato
				 */
				HotelAndPrivateTransfert: {
					id: 3279,
					label: "b2b.ENUMS.EAutofillOperation.HotelAndPrivateTransfert"
				},

				/**
				 * Nessuno
				 */
				None: {
					id: 3280,
					label: "b2b.ENUMS.EAutofillOperation.None"
				},

				/**
				 * Content of path b2b.ENUMS.EAutofillOperation.CityView
				 */
				CityView: {
				},

			},

			/**
			 * Content of path b2b.ENUMS.EAutofillStrategy
			 */
			EAutofillStrategy: {
				/**
				 * Hotel più venduti
				 */
				HightestRating: {
					id: 3281,
					label: "b2b.ENUMS.EAutofillStrategy.HightestRating"
				},

				/**
				 * Hotel più economici
				 */
				MostEconomic: {
					id: 3282,
					label: "b2b.ENUMS.EAutofillStrategy.MostEconomic"
				},

				/**
				 * ENUMS.EAutofillStrategy.Private
				 */
				Private: {
					id: 6369,
					label: "b2b.ENUMS.EAutofillStrategy.Private"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EAutofillWarningType
			 */
			EAutofillWarningType: {
				/**
				 * L'hotel trovato non corrisponde ai criteri richiesti
				 */
				HotelNonCorrispondenteAiCriteri: {
					id: 3283,
					label: "b2b.ENUMS.EAutofillWarningType.HotelNonCorrispondenteAiCriteri"
				},

				/**
				 * Nessun hotel trovato
				 */
				HotelNonTrovato: {
					id: 3284,
					label: "b2b.ENUMS.EAutofillWarningType.HotelNonTrovato"
				},

				/**
				 * Tappe modificate per adeguamento al piano voli
				 */
				TappeModificatePerInserimentoVoli: {
					id: 3285,
					label: "b2b.ENUMS.EAutofillWarningType.TappeModificatePerInserimentoVoli"
				},

				/**
				 * Nessun trasferimento trovato
				 */
				TrasferimentoNonTrovato: {
					id: 3286,
					label: "b2b.ENUMS.EAutofillWarningType.TrasferimentoNonTrovato"
				},

				/**
				 * Nessun volo trovato
				 */
				VoliNonTrovati: {
					id: 3287,
					label: "b2b.ENUMS.EAutofillWarningType.VoliNonTrovati"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EBatchType
			 */
			EBatchType: {
				/**
				 * Completamento automatico
				 */
				Autofill: {
					id: 3288,
					label: "b2b.ENUMS.EBatchType.Autofill"
				},

				/**
				 * Verifica Prezzi
				 */
				VerificaPrezzi: {
					id: 3289,
					label: "b2b.ENUMS.EBatchType.VerificaPrezzi"
				},

				/**
				 * ENUMS.EBatchType.undefined
				 */
				undefined: {
					id: 6344,
					label: "b2b.ENUMS.EBatchType.undefined"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ECategoriaArticolo
			 */
			ECategoriaArticolo: {
				/**
				 * Elementi Articoli
				 */
				ElementiArticoli: {
					id: 3290,
					label: "b2b.ENUMS.ECategoriaArticolo.ElementiArticoli"
				},

				/**
				 * Miniguida
				 */
				Miniguida: {
					id: 3291,
					label: "b2b.ENUMS.ECategoriaArticolo.Miniguida"
				},

				/**
				 * Obsoleto
				 */
				Obsoleto: {
					id: 3292,
					label: "b2b.ENUMS.ECategoriaArticolo.Obsoleto"
				},

				/**
				 * Offerte
				 */
				Offerte: {
					id: 3293,
					label: "b2b.ENUMS.ECategoriaArticolo.Offerte"
				},

				/**
				 * Pre Produzione
				 */
				PreProduzione: {
					id: 3294,
					label: "b2b.ENUMS.ECategoriaArticolo.PreProduzione"
				},

				/**
				 * Produzione
				 */
				Produzione: {
					id: 3295,
					label: "b2b.ENUMS.ECategoriaArticolo.Produzione"
				},

				/**
				 * Testi Catalogo
				 */
				TestiCatalogo: {
					id: 3296,
					label: "b2b.ENUMS.ECategoriaArticolo.TestiCatalogo"
				},

				/**
				 * Testi Stampe
				 */
				TestiStampe: {
					id: 3297,
					label: "b2b.ENUMS.ECategoriaArticolo.TestiStampe"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EContabilizzazione
			 */
			EContabilizzazione: {
				/**
				 * Cee
				 */
				Cee: {
					id: 3298,
					label: "b2b.ENUMS.EContabilizzazione.Cee"
				},

				/**
				 * ExtraCee
				 */
				ExtraCee: {
					id: 3299,
					label: "b2b.ENUMS.EContabilizzazione.ExtraCee"
				},

				/**
				 * Italia
				 */
				Italia: {
					id: 3300,
					label: "b2b.ENUMS.EContabilizzazione.Italia"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EContabilizzazione-SHORT
			 */
			EContabilizzazione_SHORT: {
				/**
				 * CEE
				 */
				Cee: {
					id: 3301,
					label: "b2b.ENUMS.EContabilizzazione-SHORT.Cee"
				},

				/**
				 * XCE
				 */
				ExtraCee: {
					id: 3302,
					label: "b2b.ENUMS.EContabilizzazione-SHORT.ExtraCee"
				},

				/**
				 * ITA
				 */
				Italia: {
					id: 3303,
					label: "b2b.ENUMS.EContabilizzazione-SHORT.Italia"
				},

				/**
				 * N.D.
				 */
				undefined: {
					id: 3304,
					label: "b2b.ENUMS.EContabilizzazione-SHORT.undefined"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EDareAvere
			 */
			EDareAvere: {
				/**
				 * A
				 */
				Avere: {
					id: 3305,
					label: "b2b.ENUMS.EDareAvere.Avere"
				},

				/**
				 * D
				 */
				Dare: {
					id: 3306,
					label: "b2b.ENUMS.EDareAvere.Dare"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EEmailStatus
			 */
			EEmailStatus: {
				/**
				 * Annullata
				 */
				Canceled: {
					id: 3307,
					label: "b2b.ENUMS.EEmailStatus.Canceled"
				},

				/**
				 * Errore
				 */
				Error: {
					id: 3308,
					label: "b2b.ENUMS.EEmailStatus.Error"
				},

				/**
				 * In coda
				 */
				Queued: {
					id: 3309,
					label: "b2b.ENUMS.EEmailStatus.Queued"
				},

				/**
				 * Inviata
				 */
				Sent: {
					id: 3310,
					label: "b2b.ENUMS.EEmailStatus.Sent"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ELingua
			 */
			ELingua: {
				/**
				 * Deutsch
				 */
				Deutsch: {
					id: 3311,
					label: "b2b.ENUMS.ELingua.Deutsch"
				},

				/**
				 * Olandese
				 */
				Dutch: {
					id: 3312,
					label: "b2b.ENUMS.ELingua.Dutch"
				},

				/**
				 * English
				 */
				English: {
					id: 3313,
					label: "b2b.ENUMS.ELingua.English"
				},

				/**
				 * Francais
				 */
				Francais: {
					id: 3314,
					label: "b2b.ENUMS.ELingua.Francais"
				},

				/**
				 * Italiano
				 */
				Italiano: {
					id: 3315,
					label: "b2b.ENUMS.ELingua.Italiano"
				},

				/**
				 * Non definita
				 */
				undefined: {
					id: 3316,
					label: "b2b.ENUMS.ELingua.undefined"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EModalitaAggiornamentoValute
			 */
			EModalitaAggiornamentoValute: {
				/**
				 * Aggiornare Cambi
				 */
				AggiornaCambi: {
					id: 3317,
					label: "b2b.ENUMS.EModalitaAggiornamentoValute.AggiornaCambi"
				},

				/**
				 * Cambi Aggiornati
				 */
				CambiAggiornati: {
					id: 3318,
					label: "b2b.ENUMS.EModalitaAggiornamentoValute.CambiAggiornati"
				},

				/**
				 * Nessun Adeguamento
				 */
				NessunAdeguamento: {
					id: 3319,
					label: "b2b.ENUMS.EModalitaAggiornamentoValute.NessunAdeguamento"
				},

				/**
				 * Verificare Offerta
				 */
				VerificaOfferta: {
					id: 3320,
					label: "b2b.ENUMS.EModalitaAggiornamentoValute.VerificaOfferta"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EOperatore
			 */
			EOperatore: {
				/**
				 * Indefinito
				 */
				Indefinito: {
					id: 3321,
					label: "b2b.ENUMS.EOperatore.Indefinito"
				},

				/**
				 * Naar Tour Operator
				 */
				Naar: {
					id: 3322,
					label: "b2b.ENUMS.EOperatore.Naar"
				},

				/**
				 * Naar Benelux
				 */
				NaarBenelux: {
					id: 3323,
					label: "b2b.ENUMS.EOperatore.NaarBenelux"
				},

				/**
				 * Naar France
				 */
				NaarFrance: {
					id: 3324,
					label: "b2b.ENUMS.EOperatore.NaarFrance"
				},

				/**
				 * Naar Germany
				 */
				NaarGermany: {
					id: 3325,
					label: "b2b.ENUMS.EOperatore.NaarGermany"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EPriceVerifyRequestResult
			 */
			EPriceVerifyRequestResult: {
				/**
				 * Verifica prezzi già in corso 
				 */
				ALREADY_RUNNING: {
					id: 3326,
					label: "b2b.ENUMS.EPriceVerifyRequestResult.ALREADY_RUNNING"
				},

				/**
				 * Autompletamento in corso
				 */
				AUTOCOMPLETE_RUNNING: {
					id: 3327,
					label: "b2b.ENUMS.EPriceVerifyRequestResult.AUTOCOMPLETE_RUNNING"
				},

				/**
				 * Errore generico
				 */
				ERROR: {
					id: 3328,
					label: "b2b.ENUMS.EPriceVerifyRequestResult.ERROR"
				},

				/**
				 * OK
				 */
				OK: {
					id: 3329,
					label: "b2b.ENUMS.EPriceVerifyRequestResult.OK"
				},

				/**
				 * Tempo per l'operazione scaduto
				 */
				TIMEOUT: {
					id: 3330,
					label: "b2b.ENUMS.EPriceVerifyRequestResult.TIMEOUT"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EPriceVerifyResult
			 */
			EPriceVerifyResult: {
				/**
				 * Errore nella verifica prezzi
				 */
				ERROR: {
					id: 3331,
					label: "b2b.ENUMS.EPriceVerifyResult.ERROR"
				},

				/**
				 * Verifica prezzi non riuscita
				 */
				INVALID: {
					id: 3332,
					label: "b2b.ENUMS.EPriceVerifyResult.INVALID"
				},

				/**
				 * In corso
				 */
				NONE: {
					id: 3333,
					label: "b2b.ENUMS.EPriceVerifyResult.NONE"
				},

				/**
				 * Prezzi e disponibilità verificati
				 */
				OK: {
					id: 3334,
					label: "b2b.ENUMS.EPriceVerifyResult.OK"
				},

				/**
				 * Prezzi o disponibilità variati
				 */
				PRICE_AVAIL_CHANGED: {
					id: 3335,
					label: "b2b.ENUMS.EPriceVerifyResult.PRICE_AVAIL_CHANGED"
				},

			},

			/**
			 * Content of path b2b.ENUMS.eServicesMacroFilterMode
			 */
			eServicesMacroFilterMode: {
				/**
				 * tutti
				 */
				All: {
					id: 3336,
					label: "b2b.ENUMS.eServicesMacroFilterMode.All"
				},

				/**
				 * Solo catalogo Naar
				 */
				Catalog: {
					id: 3337,
					label: "b2b.ENUMS.eServicesMacroFilterMode.Catalog"
				},

				/**
				 * Consigliati da Naar
				 */
				Suggested: {
					id: 3338,
					label: "b2b.ENUMS.eServicesMacroFilterMode.Suggested"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EServizioAutomatico
			 */
			EServizioAutomatico: {
				/**
				 * Coccole In Partenza
				 */
				CoccoleInPartenza: {
					id: 3339,
					label: "b2b.ENUMS.EServizioAutomatico.CoccoleInPartenza"
				},

				/**
				 * Coccole In Partenza VIP
				 */
				CoccoleInPartenzaVip: {
					id: 3340,
					label: "b2b.ENUMS.EServizioAutomatico.CoccoleInPartenzaVip"
				},

				/**
				 * Commissioni agenzia su biglietteria
				 */
				DirittiAgenziaSuBiglietteria: {
					id: 3341,
					label: "b2b.ENUMS.EServizioAutomatico.DirittiAgenziaSuBiglietteria"
				},

				/**
				 * Polizza medico bagaglio facoltativa
				 */
				MedicoBagaglioBase: {
					id: 3342,
					label: "b2b.ENUMS.EServizioAutomatico.MedicoBagaglioBase"
				},

				/**
				 * Annulation cas imprévu, bagages, interruption du séjour
				 */
				PolizzaFr1: {
					id: 3343,
					label: "b2b.ENUMS.EServizioAutomatico.PolizzaFr1"
				},

				/**
				 * Annulation cas imprévu, bagages, interruption du séjour, Responsabilité vie civile privé, fermeture d'aéroport, stabilité des prix
				 */
				PolizzaFr2: {
					id: 3344,
					label: "b2b.ENUMS.EServizioAutomatico.PolizzaFr2"
				},

				/**
				 * Assistance rapatriement
				 */
				PolizzaFr3: {
					id: 3345,
					label: "b2b.ENUMS.EServizioAutomatico.PolizzaFr3"
				},

				/**
				 * Quote di apertura pratica Naar
				 */
				QuoteNaar: {
					id: 3346,
					label: "b2b.ENUMS.EServizioAutomatico.QuoteNaar"
				},

				/**
				 * Quote di apertura pratica Naar
				 */
				QuoteNaarFrance: {
					id: 3347,
					label: "b2b.ENUMS.EServizioAutomatico.QuoteNaarFrance"
				},

				/**
				 * Quote di apertura pratica Online
				 */
				QuoteOnline: {
					id: 3348,
					label: "b2b.ENUMS.EServizioAutomatico.QuoteOnline"
				},

				/**
				 * Quote di apertura pratica Online
				 */
				QuoteOnlineFrance: {
					id: 3349,
					label: "b2b.ENUMS.EServizioAutomatico.QuoteOnlineFrance"
				},

				/**
				 * Rinuncia blocca prezzo
				 */
				RinunciaBloccaPrezzo: {
					id: 3350,
					label: "b2b.ENUMS.EServizioAutomatico.RinunciaBloccaPrezzo"
				},

				/**
				 * Rinuncia a We Care You Smile
				 */
				RinunciaWeCareYouSmile: {
					id: 3351,
					label: "b2b.ENUMS.EServizioAutomatico.RinunciaWeCareYouSmile"
				},

				/**
				 * NOBIS FILODIRETTO - Integrazione spese mediche illimitate
				 */
				SpeseMediche1M: {
					id: 3352,
					label: "b2b.ENUMS.EServizioAutomatico.SpeseMediche1M"
				},

				/**
				 * NOBIS FILODIRETTO - Integrazione spese mediche fino a 100.000 €
				 */
				SpeseMediche100K: {
					id: 3353,
					label: "b2b.ENUMS.EServizioAutomatico.SpeseMediche100K"
				},

				/**
				 * NOBIS FILODIRETTO - Integrazione spese mediche fino a 300.000 €
				 */
				SpeseMediche300K: {
					id: 3354,
					label: "b2b.ENUMS.EServizioAutomatico.SpeseMediche300K"
				},

				/**
				 * NOBIS FILODIRETTO - Integrazione spese mediche fino a 500.000 €
				 */
				SpeseMediche500K: {
					id: 3355,
					label: "b2b.ENUMS.EServizioAutomatico.SpeseMediche500K"
				},

				/**
				 * Integrazione da quote di apertura Online a quote Naar
				 */
				UpgradeQuoteOnline: {
					id: 3356,
					label: "b2b.ENUMS.EServizioAutomatico.UpgradeQuoteOnline"
				},

				/**
				 * NOBIS FILODIRETTO - Integrativa We Care You Smile PLUS
				 */
				WeCareYouSmilePlus: {
					id: 3357,
					label: "b2b.ENUMS.EServizioAutomatico.WeCareYouSmilePlus"
				},

				/**
				 * Quote di apertura pratica Online
				 */
				QuoteOnlineBenelux: {
					id: 6615,
					label: "b2b.ENUMS.EServizioAutomatico.QuoteOnlineBenelux"
				},

				/**
				 * Quote di apertura pratica Online
				 */
				QuoteOnlineGermany: {
					id: 6616,
					label: "b2b.ENUMS.EServizioAutomatico.QuoteOnlineGermany"
				},

				/**
				 * Quote di apertura pratica Naar
				 */
				QuoteNaarBenelux: {
					id: 6617,
					label: "b2b.ENUMS.EServizioAutomatico.QuoteNaarBenelux"
				},

				/**
				 * Quote di apertura pratica Naar
				 */
				QuoteNaarGermany: {
					id: 6618,
					label: "b2b.ENUMS.EServizioAutomatico.QuoteNaarGermany"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EServizioManuale
			 */
			EServizioManuale: {
				/**
				 * Diritti Agenzia
				 */
				DirittiAgenzia: {
					id: 3358,
					label: "b2b.ENUMS.EServizioManuale.DirittiAgenzia"
				},

				/**
				 * Intervento Commerciale
				 */
				InterventoCommerciale: {
					id: 3359,
					label: "b2b.ENUMS.EServizioManuale.InterventoCommerciale"
				},

				/**
				 * Intervento Operativo
				 */
				InterventoOperativo: {
					id: 3360,
					label: "b2b.ENUMS.EServizioManuale.InterventoOperativo"
				},

				/**
				 * Rettifica Prezzi
				 */
				RettificaPrezzi: {
					id: 3361,
					label: "b2b.ENUMS.EServizioManuale.RettificaPrezzi"
				},

				/**
				 * Servizio Manuale
				 */
				ServizioManuale: {
					id: 3362,
					label: "b2b.ENUMS.EServizioManuale.ServizioManuale"
				},

				/**
				 * Tariffa Aerea
				 */
				TariffaAerea: {
					id: 3363,
					label: "b2b.ENUMS.EServizioManuale.TariffaAerea"
				},

				/**
				 * Tariffa Aerea Instant Purchase
				 */
				TariffaAereaInstantPurchase: {
					id: 3364,
					label: "b2b.ENUMS.EServizioManuale.TariffaAereaInstantPurchase"
				},

				/**
				 * Tasse Aeroportuali
				 */
				TasseAeroportuali: {
					id: 3365,
					label: "b2b.ENUMS.EServizioManuale.TasseAeroportuali"
				},

				/**
				 * Traghetti
				 */
				Traghetti: {
					id: 3366,
					label: "b2b.ENUMS.EServizioManuale.Traghetti"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EStatoDisponibilita
			 */
			EStatoDisponibilita: {
				/**
				 * Prezzi da verificare
				 */
				DaVerificare: {
					id: 3367,
					label: "b2b.ENUMS.EStatoDisponibilita.DaVerificare"
				},

				/**
				 * Disponibile
				 */
				Disponibile: {
					id: 3368,
					label: "b2b.ENUMS.EStatoDisponibilita.Disponibile"
				},

				/**
				 * Disponibile in freesale
				 */
				DisponibileFreesale: {
					id: 3369,
					label: "b2b.ENUMS.EStatoDisponibilita.DisponibileFreesale"
				},

				/**
				 * Indeterminato
				 */
				Indeterminato: {
					id: 3370,
					label: "b2b.ENUMS.EStatoDisponibilita.Indeterminato"
				},

				/**
				 * Non disponibile
				 */
				NonDisponibile: {
					id: 3371,
					label: "b2b.ENUMS.EStatoDisponibilita.NonDisponibile"
				},

				/**
				 * Non disponibile per mancato rispetto vincoli
				 */
				NonDisponibileNoMatchRegola: {
					id: 3372,
					label: "b2b.ENUMS.EStatoDisponibilita.NonDisponibileNoMatchRegola"
				},

				/**
				 * Su richiesta
				 */
				SuRichiesta: {
					id: 3373,
					label: "b2b.ENUMS.EStatoDisponibilita.SuRichiesta"
				},

				/**
				 * ENUMS.EStatoDisponibilita.undefined
				 */
				undefined: {
					id: 6342,
					label: "b2b.ENUMS.EStatoDisponibilita.undefined"
				},

				/**
				 * ENUMS.EStatoDisponibilita.255
				 */
				_255: {
					id: 6405,
					label: "b2b.ENUMS.EStatoDisponibilita.255"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EStatoDisponibilitaConferma
			 */
			EStatoDisponibilitaConferma: {
				/**
				 * Cancellato
				 */
				Cancellata: {
					id: 3374,
					label: "b2b.ENUMS.EStatoDisponibilitaConferma.Cancellata"
				},

				/**
				 * Non Disponibile
				 */
				NonDisponibile: {
					id: 3375,
					label: "b2b.ENUMS.EStatoDisponibilitaConferma.NonDisponibile"
				},

				/**
				 * Confermato
				 */
				Ok: {
					id: 3376,
					label: "b2b.ENUMS.EStatoDisponibilitaConferma.Ok"
				},

				/**
				 * Confermato allot.
				 */
				OkAllotment: {
					id: 3377,
					label: "b2b.ENUMS.EStatoDisponibilitaConferma.OkAllotment"
				},

				/**
				 * Su richiesta
				 */
				OnRequest: {
					id: 3378,
					label: "b2b.ENUMS.EStatoDisponibilitaConferma.OnRequest"
				},

				/**
				 * Su richiesta per regola
				 */
				OnRequestReg: {
					id: 3379,
					label: "b2b.ENUMS.EStatoDisponibilitaConferma.OnRequestReg"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EStatoOfferta
			 */
			EStatoOfferta: {
				/**
				 * Attesa pagamento
				 */
				AttesaPagamento: {
					id: 3380,
					label: "b2b.ENUMS.EStatoOfferta.AttesaPagamento"
				},

				/**
				 * Cancellato
				 */
				Cancellato: {
					id: 3381,
					label: "b2b.ENUMS.EStatoOfferta.Cancellato"
				},

				/**
				 * In cancellazione
				 */
				InCancellazione: {
					id: 3382,
					label: "b2b.ENUMS.EStatoOfferta.InCancellazione"
				},

				/**
				 * In emissione documenti
				 */
				InEmissioneDoc: {
					id: 3383,
					label: "b2b.ENUMS.EStatoOfferta.InEmissioneDoc"
				},

				/**
				 * In prenotazione
				 */
				InPrenotazione: {
					id: 3384,
					label: "b2b.ENUMS.EStatoOfferta.InPrenotazione"
				},

				/**
				 * Manuale
				 */
				Manuale: {
					id: 3385,
					label: "b2b.ENUMS.EStatoOfferta.Manuale"
				},

				/**
				 * Viaggio chiuso
				 */
				ViaggioChiuso: {
					id: 3386,
					label: "b2b.ENUMS.EStatoOfferta.ViaggioChiuso"
				},

				/**
				 * Viaggio in contenzioso
				 */
				ViaggioInContenzioso: {
					id: 3387,
					label: "b2b.ENUMS.EStatoOfferta.ViaggioInContenzioso"
				},

				/**
				 * Viaggio Pronto
				 */
				ViaggioPronto: {
					id: 3388,
					label: "b2b.ENUMS.EStatoOfferta.ViaggioPronto"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EStatoOffertaUtente
			 */
			EStatoOffertaUtente: {
				/**
				 * Annullata
				 */
				Annullata: {
					id: 3389,
					label: "b2b.ENUMS.EStatoOffertaUtente.Annullata"
				},

				/**
				 * Confermata
				 */
				Confermata: {
					id: 3390,
					label: "b2b.ENUMS.EStatoOffertaUtente.Confermata"
				},

				/**
				 * Prenotazione in corso
				 */
				PrenotazioneInCorso: {
					id: 3391,
					label: "b2b.ENUMS.EStatoOffertaUtente.PrenotazioneInCorso"
				},

				/**
				 * Preventivo
				 */
				Preventivo: {
					id: 3392,
					label: "b2b.ENUMS.EStatoOffertaUtente.Preventivo"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EStatoServizioAlternativo
			 */
			EStatoServizioAlternativo: {
				/**
				 * Alternativa accettata dall'agenzia
				 */
				AlternativaAccettata: {
					id: 3393,
					label: "b2b.ENUMS.EStatoServizioAlternativo.AlternativaAccettata"
				},

				/**
				 * Alternativa da noi proposta
				 */
				AlternativaProposta: {
					id: 3394,
					label: "b2b.ENUMS.EStatoServizioAlternativo.AlternativaProposta"
				},

				/**
				 * Nessuno
				 */
				Nessuno: {
					id: 3395,
					label: "b2b.ENUMS.EStatoServizioAlternativo.Nessuno"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EStatoServizioUtente
			 */
			EStatoServizioUtente: {
				/**
				 * Annullato
				 */
				Annullato: {
					id: 3396,
					label: "b2b.ENUMS.EStatoServizioUtente.Annullato"
				},

				/**
				 * Confermato
				 */
				Confermato: {
					id: 3397,
					label: "b2b.ENUMS.EStatoServizioUtente.Confermato"
				},

				/**
				 * In prenotazione
				 */
				InPrenotazione: {
					id: 3398,
					label: "b2b.ENUMS.EStatoServizioUtente.InPrenotazione"
				},

				/**
				 * Nessuno
				 */
				Nessuno: {
					id: 3399,
					label: "b2b.ENUMS.EStatoServizioUtente.Nessuno"
				},

				/**
				 * Prezzi o disponibilità variati
				 */
				PrezziDispoVariati: {
					id: 3400,
					label: "b2b.ENUMS.EStatoServizioUtente.PrezziDispoVariati"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EStatoVolo
			 */
			EStatoVolo: {
				/**
				 * Attivo
				 */
				Attivo: {
					id: 3401,
					label: "b2b.ENUMS.EStatoVolo.Attivo"
				},

				/**
				 * Cancellato
				 */
				Cancellato: {
					id: 3402,
					label: "b2b.ENUMS.EStatoVolo.Cancellato"
				},

				/**
				 * Emesso
				 */
				Emesso: {
					id: 3403,
					label: "b2b.ENUMS.EStatoVolo.Emesso"
				},

				/**
				 * InEmissione
				 */
				InEmissione: {
					id: 3404,
					label: "b2b.ENUMS.EStatoVolo.InEmissione"
				},

				/**
				 * Monitorato
				 */
				Monitorato: {
					id: 3405,
					label: "b2b.ENUMS.EStatoVolo.Monitorato"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EStatusAlternativa
			 */
			EStatusAlternativa: {
				/**
				 * Annullato
				 */
				Annullato: {
					id: 3406,
					label: "b2b.ENUMS.EStatusAlternativa.Annullato"
				},

				/**
				 * Errore
				 */
				Errore: {
					id: 3407,
					label: "b2b.ENUMS.EStatusAlternativa.Errore"
				},

				/**
				 * Eseguito senza risultati
				 */
				EseguitoNoRisultati: {
					id: 3408,
					label: "b2b.ENUMS.EStatusAlternativa.EseguitoNoRisultati"
				},

				/**
				 * Eseguito con Successo
				 */
				EseguitoSuccesso: {
					id: 3409,
					label: "b2b.ENUMS.EStatusAlternativa.EseguitoSuccesso"
				},

				/**
				 * In Corso
				 */
				InCorso: {
					id: 3410,
					label: "b2b.ENUMS.EStatusAlternativa.InCorso"
				},

				/**
				 * Pronto
				 */
				Pronto: {
					id: 3411,
					label: "b2b.ENUMS.EStatusAlternativa.Pronto"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EStatusCostiFornitore
			 */
			EStatusCostiFornitore: {
				/**
				 * Completato
				 */
				Done: {
					id: 3412,
					label: "b2b.ENUMS.EStatusCostiFornitore.Done"
				},

				/**
				 * In errore
				 */
				Error: {
					id: 3413,
					label: "b2b.ENUMS.EStatusCostiFornitore.Error"
				},

				/**
				 * Scaduto
				 */
				Expired: {
					id: 3414,
					label: "b2b.ENUMS.EStatusCostiFornitore.Expired"
				},

				/**
				 * Pronto all'esecuzione
				 */
				Ready: {
					id: 3415,
					label: "b2b.ENUMS.EStatusCostiFornitore.Ready"
				},

				/**
				 * In corso
				 */
				Running: {
					id: 3416,
					label: "b2b.ENUMS.EStatusCostiFornitore.Running"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EStatusPreventivo
			 */
			EStatusPreventivo: {
				/**
				 * Evaso attesa agenzia
				 */
				AttesaADV: {
					id: 3417,
					label: "b2b.ENUMS.EStatusPreventivo.AttesaADV"
				},

				/**
				 * Completato
				 */
				Completato: {
					id: 3418,
					label: "b2b.ENUMS.EStatusPreventivo.Completato"
				},

				/**
				 * Conferma da modificare
				 */
				ConfermaDaModificare: {
					id: 3419,
					label: "b2b.ENUMS.EStatusPreventivo.ConfermaDaModificare"
				},

				/**
				 * Da fare
				 */
				DaFare: {
					id: 3420,
					label: "b2b.ENUMS.EStatusPreventivo.DaFare"
				},

				/**
				 * Da modificare
				 */
				DaModificare: {
					id: 3421,
					label: "b2b.ENUMS.EStatusPreventivo.DaModificare"
				},

				/**
				 * Da verificare
				 */
				DaVerificare: {
					id: 3422,
					label: "b2b.ENUMS.EStatusPreventivo.DaVerificare"
				},

				/**
				 * Non confermato
				 */
				NonConcretizzato: {
					id: 3423,
					label: "b2b.ENUMS.EStatusPreventivo.NonConcretizzato"
				},

				/**
				 * Non evaso
				 */
				NonEvaso: {
					id: 3424,
					label: "b2b.ENUMS.EStatusPreventivo.NonEvaso"
				},

				/**
				 * Non parte
				 */
				NonParte: {
					id: 3425,
					label: "b2b.ENUMS.EStatusPreventivo.NonParte"
				},

				/**
				 * Non richiamare
				 */
				NonRichiamare: {
					id: 3426,
					label: "b2b.ENUMS.EStatusPreventivo.NonRichiamare"
				},

				/**
				 * Venduto altro TO
				 */
				VendutoAltroTO: {
					id: 3427,
					label: "b2b.ENUMS.EStatusPreventivo.VendutoAltroTO"
				},

				/**
				 * Supporto post vendita
				 */
				SupportPostVendita: {
					id: 6619,
					label: "b2b.ENUMS.EStatusPreventivo.SupportPostVendita"
				},

			},

			/**
			 * Content of path b2b.ENUMS.EStatusServizio
			 */
			EStatusServizio: {
				/**
				 * Cancellato
				 */
				Cancellato: {
					id: 3428,
					label: "b2b.ENUMS.EStatusServizio.Cancellato"
				},

				/**
				 * Confermato
				 */
				Confermato: {
					id: 3429,
					label: "b2b.ENUMS.EStatusServizio.Confermato"
				},

				/**
				 * Da Cancellare
				 */
				DaCancellare: {
					id: 3430,
					label: "b2b.ENUMS.EStatusServizio.DaCancellare"
				},

				/**
				 * Da Prenotare
				 */
				DaPrenotare: {
					id: 3431,
					label: "b2b.ENUMS.EStatusServizio.DaPrenotare"
				},

				/**
				 * In Cancellazione
				 */
				InCancellazione: {
					id: 3432,
					label: "b2b.ENUMS.EStatusServizio.InCancellazione"
				},

				/**
				 * In Prenotazione
				 */
				InPrenotazione: {
					id: 3433,
					label: "b2b.ENUMS.EStatusServizio.InPrenotazione"
				},

				/**
				 * Manuale
				 */
				Manuale: {
					id: 3434,
					label: "b2b.ENUMS.EStatusServizio.Manuale"
				},

				/**
				 * Non Confermato
				 */
				NonConfermato: {
					id: 3435,
					label: "b2b.ENUMS.EStatusServizio.NonConfermato"
				},

				/**
				 * Parzialmente confermato
				 */
				ParzialmenteConfermato: {
					id: 3436,
					label: "b2b.ENUMS.EStatusServizio.ParzialmenteConfermato"
				},

				/**
				 * Prezzi Variati
				 */
				PrezziVariati: {
					id: 3437,
					label: "b2b.ENUMS.EStatusServizio.PrezziVariati"
				},

				/**
				 * ENUMS.EStatusServizio.undefined
				 */
				undefined: {
					id: 6378,
					label: "b2b.ENUMS.EStatusServizio.undefined"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETestoPenaleAgenzia
			 */
			ETestoPenaleAgenzia: {
				/**
				 * Articolo specifico
				 */
				Articolo: {
					id: 3438,
					label: "b2b.ENUMS.ETestoPenaleAgenzia.Articolo"
				},

				/**
				 * Dati aggiuntivi penale default
				 */
				DatiAggiuntivi: {
					id: 3439,
					label: "b2b.ENUMS.ETestoPenaleAgenzia.DatiAggiuntivi"
				},

				/**
				 * Nessun testo presente per le penali di cancellazione
				 */
				Nessuno: {
					id: 3440,
					label: "b2b.ENUMS.ETestoPenaleAgenzia.Nessuno"
				},

				/**
				 * Nota personalizzata
				 */
				NotaCustom: {
					id: 3441,
					label: "b2b.ENUMS.ETestoPenaleAgenzia.NotaCustom"
				},

				/**
				 * Nota del fornitore
				 */
				NotaFornitore: {
					id: 3442,
					label: "b2b.ENUMS.ETestoPenaleAgenzia.NotaFornitore"
				},

				/**
				 * Penale H2H
				 */
				PenaleH2H: {
					id: 3443,
					label: "b2b.ENUMS.ETestoPenaleAgenzia.PenaleH2H"
				},

				/**
				 * ENUMS.ETestoPenaleAgenzia.null
				 */
				_null: {
					id: 6345,
					label: "b2b.ENUMS.ETestoPenaleAgenzia.null"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoAcconto
			 */
			ETipoAcconto: {
				/**
				 * Acconto
				 */
				Acconto: {
					id: 3444,
					label: "b2b.ENUMS.ETipoAcconto.Acconto"
				},

				/**
				 * Altri Voli
				 */
				AltriVoli: {
					id: 3445,
					label: "b2b.ENUMS.ETipoAcconto.AltriVoli"
				},

				/**
				 * Hotel
				 */
				Hotel: {
					id: 3446,
					label: "b2b.ENUMS.ETipoAcconto.Hotel"
				},

				/**
				 * Tariffa IP
				 */
				TariffaIP: {
					id: 3447,
					label: "b2b.ENUMS.ETipoAcconto.TariffaIP"
				},

				/**
				 * Tariffa TO
				 */
				TariffaTO: {
					id: 3448,
					label: "b2b.ENUMS.ETipoAcconto.TariffaTO"
				},

				/**
				 * Tour
				 */
				Tour: {
					id: 3449,
					label: "b2b.ENUMS.ETipoAcconto.Tour"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoAnticipo
			 */
			ETipoAnticipo: {
				/**
				 * Acconto
				 */
				AnticipoContrattuale: {
					id: 3450,
					label: "b2b.ENUMS.ETipoAnticipo.AnticipoContrattuale"
				},

				/**
				 * Commissioni
				 */
				Commissioni: {
					id: 3451,
					label: "b2b.ENUMS.ETipoAnticipo.Commissioni"
				},

				/**
				 * QAP, BP e Assicurazioni
				 */
				QuoteEAssicurazioni: {
					id: 3452,
					label: "b2b.ENUMS.ETipoAnticipo.QuoteEAssicurazioni"
				},

				/**
				 * Servizi
				 */
				ServiziTerra: {
					id: 3453,
					label: "b2b.ENUMS.ETipoAnticipo.ServiziTerra"
				},

				/**
				 * Biglietterie aeree
				 */
				ServiziVolo: {
					id: 3454,
					label: "b2b.ENUMS.ETipoAnticipo.ServiziVolo"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoContatto
			 */
			ETipoContatto: {
				/**
				 * Addetto
				 */
				Addetto: {
					id: 3455,
					label: "b2b.ENUMS.ETipoContatto.Addetto"
				},

				/**
				 * Agenzia
				 */
				Agenzia: {
					id: 3456,
					label: "b2b.ENUMS.ETipoContatto.Agenzia"
				},

				/**
				 * AgenzieChiuse
				 */
				AgenzieChiuse: {
					id: 3457,
					label: "b2b.ENUMS.ETipoContatto.AgenzieChiuse"
				},

				/**
				 * Appartamenti
				 */
				Appartamenti: {
					id: 3458,
					label: "b2b.ENUMS.ETipoContatto.Appartamenti"
				},

				/**
				 * Cliente contabilità
				 */
				ClienteContabilita: {
					id: 3459,
					label: "b2b.ENUMS.ETipoContatto.ClienteContabilita"
				},

				/**
				 * ClienteDiretto
				 */
				ClienteDiretto: {
					id: 3460,
					label: "b2b.ENUMS.ETipoContatto.ClienteDiretto"
				},

				/**
				 * CompagnieAeree
				 */
				CompagnieAeree: {
					id: 3461,
					label: "b2b.ENUMS.ETipoContatto.CompagnieAeree"
				},

				/**
				 * ContrattiAgenti
				 */
				ContrattiAgenti: {
					id: 3462,
					label: "b2b.ENUMS.ETipoContatto.ContrattiAgenti"
				},

				/**
				 * Corrieri
				 */
				Corrieri: {
					id: 3463,
					label: "b2b.ENUMS.ETipoContatto.Corrieri"
				},

				/**
				 * Fornitore
				 */
				Fornitore: {
					id: 3464,
					label: "b2b.ENUMS.ETipoContatto.Fornitore"
				},

				/**
				 * FornitoreContabilita
				 */
				FornitoreContabilita: {
					id: 3465,
					label: "b2b.ENUMS.ETipoContatto.FornitoreContabilita"
				},

				/**
				 * FornitoreIndiretto
				 */
				FornitoreIndiretto: {
					id: 3466,
					label: "b2b.ENUMS.ETipoContatto.FornitoreIndiretto"
				},

				/**
				 * Giornali
				 */
				Giornali: {
					id: 3467,
					label: "b2b.ENUMS.ETipoContatto.Giornali"
				},

				/**
				 * GruppiAgenzie
				 */
				GruppiAgenzie: {
					id: 3468,
					label: "b2b.ENUMS.ETipoContatto.GruppiAgenzie"
				},

				/**
				 * Hotel
				 */
				Hotel: {
					id: 3469,
					label: "b2b.ENUMS.ETipoContatto.Hotel"
				},

				/**
				 * Produttore
				 */
				Produttore: {
					id: 3470,
					label: "b2b.ENUMS.ETipoContatto.Produttore"
				},

				/**
				 * Punto pickup
				 */
				PuntoPickup: {
					id: 3471,
					label: "b2b.ENUMS.ETipoContatto.PuntoPickup"
				},

				/**
				 * SottogruppiAgenzie
				 */
				SottogruppiAgenzie: {
					id: 3472,
					label: "b2b.ENUMS.ETipoContatto.SottogruppiAgenzie"
				},

				/**
				 * TourOperator
				 */
				TourOperator: {
					id: 3473,
					label: "b2b.ENUMS.ETipoContatto.TourOperator"
				},

				/**
				 * UfficioFornitore
				 */
				UfficioFornitore: {
					id: 3474,
					label: "b2b.ENUMS.ETipoContatto.UfficioFornitore"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoDeltaAlternativa
			 */
			ETipoDeltaAlternativa: {
				/**
				 * Costo Assoluto
				 */
				CostoAssoluto: {
					id: 3475,
					label: "b2b.ENUMS.ETipoDeltaAlternativa.CostoAssoluto"
				},

				/**
				 * Percentuale Costo
				 */
				PercentualeCosto: {
					id: 3476,
					label: "b2b.ENUMS.ETipoDeltaAlternativa.PercentualeCosto"
				},

				/**
				 * Percentuale Prezzo
				 */
				PercentualePrezzo: {
					id: 3477,
					label: "b2b.ENUMS.ETipoDeltaAlternativa.PercentualePrezzo"
				},

				/**
				 * Prezzo Assoluto
				 */
				PrezzoAssoluto: {
					id: 3478,
					label: "b2b.ENUMS.ETipoDeltaAlternativa.PrezzoAssoluto"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoDocumento
			 */
			ETipoDocumento: {
				/**
				 * Assicurazioni
				 */
				Assicurazioni: {
					id: 3479,
					label: "b2b.ENUMS.ETipoDocumento.Assicurazioni"
				},

				/**
				 * Autofattura
				 */
				Autofattura: {
					id: 3480,
					label: "b2b.ENUMS.ETipoDocumento.Autofattura"
				},

				/**
				 * Autofattura Bis
				 */
				AutofatturaBis: {
					id: 3481,
					label: "b2b.ENUMS.ETipoDocumento.AutofatturaBis"
				},

				/**
				 * Biglietteria
				 */
				Biglietteria: {
					id: 3482,
					label: "b2b.ENUMS.ETipoDocumento.Biglietteria"
				},

				/**
				 * Conferma
				 */
				Conferma: {
					id: 3483,
					label: "b2b.ENUMS.ETipoDocumento.Conferma"
				},

				/**
				 * Conferma cliente
				 */
				ConfermaCliente: {
					id: 3484,
					label: "b2b.ENUMS.ETipoDocumento.ConfermaCliente"
				},

				/**
				 * Conferma provvisoria agenzia
				 */
				ConfermaProvvisoriaAgenzia: {
					id: 3485,
					label: "b2b.ENUMS.ETipoDocumento.ConfermaProvvisoriaAgenzia"
				},

				/**
				 * Corrispondenza
				 */
				Corrispondenza: {
					id: 3486,
					label: "b2b.ENUMS.ETipoDocumento.Corrispondenza"
				},

				/**
				 * Documenti di viaggio
				 */
				DocumentiDiViaggio: {
					id: 3487,
					label: "b2b.ENUMS.ETipoDocumento.DocumentiDiViaggio"
				},

				/**
				 * Documento Allegato
				 */
				DocumentoAllegato: {
					id: 3488,
					label: "b2b.ENUMS.ETipoDocumento.DocumentoAllegato"
				},

				/**
				 * Documento condiviso
				 */
				DocumentoCondiviso: {
					id: 3489,
					label: "b2b.ENUMS.ETipoDocumento.DocumentoCondiviso"
				},

				/**
				 * Documento Online
				 */
				DocumentoContabile: {
					id: 3490,
					label: "b2b.ENUMS.ETipoDocumento.DocumentoContabile"
				},

				/**
				 * Estratto Conto
				 */
				EstrattoConto: {
					id: 3491,
					label: "b2b.ENUMS.ETipoDocumento.EstrattoConto"
				},

				/**
				 * Status Conferma Servizi
				 */
				EstrattoContoConfermaServizi: {
					id: 3492,
					label: "b2b.ENUMS.ETipoDocumento.EstrattoContoConfermaServizi"
				},

				/**
				 * Estratto Conto penale
				 */
				EstrattoContoPenale: {
					id: 3493,
					label: "b2b.ENUMS.ETipoDocumento.EstrattoContoPenale"
				},

				/**
				 * Fattura
				 */
				Fattura: {
					id: 3494,
					label: "b2b.ENUMS.ETipoDocumento.Fattura"
				},

				/**
				 * Fattura acquisto
				 */
				FatturaAcquisto: {
					id: 3495,
					label: "b2b.ENUMS.ETipoDocumento.FatturaAcquisto"
				},

				/**
				 * Fattura di acquisto ordinaria
				 */
				FatturaAcquistoOrdinaria: {
					id: 3496,
					label: "b2b.ENUMS.ETipoDocumento.FatturaAcquistoOrdinaria"
				},

				/**
				 * Fattura ordinaria
				 */
				FatturaOrdinaria: {
					id: 3497,
					label: "b2b.ENUMS.ETipoDocumento.FatturaOrdinaria"
				},

				/**
				 * Generico
				 */
				Generico: {
					id: 3498,
					label: "b2b.ENUMS.ETipoDocumento.Generico"
				},

				/**
				 * Mail conferma cliente finale
				 */
				MailConfermaClienteFinale: {
					id: 3499,
					label: "b2b.ENUMS.ETipoDocumento.MailConfermaClienteFinale"
				},

				/**
				 * Mail prenotazione cliente finale
				 */
				MailPrenotazioneClienteFinale: {
					id: 3500,
					label: "b2b.ENUMS.ETipoDocumento.MailPrenotazioneClienteFinale"
				},

				/**
				 * Miniguida
				 */
				Miniguida: {
					id: 3501,
					label: "b2b.ENUMS.ETipoDocumento.Miniguida"
				},

				/**
				 * Multi documento
				 */
				MultiDocumento: {
					id: 3502,
					label: "b2b.ENUMS.ETipoDocumento.MultiDocumento"
				},

				/**
				 * Nota Credito
				 */
				NotaCredito: {
					id: 3503,
					label: "b2b.ENUMS.ETipoDocumento.NotaCredito"
				},

				/**
				 * Nota di credito acquisto
				 */
				NotaCreditoAcquisto: {
					id: 3504,
					label: "b2b.ENUMS.ETipoDocumento.NotaCreditoAcquisto"
				},

				/**
				 * Nota di credito acquisto ordinaria
				 */
				NotaCreditoAcquistoOrdinaria: {
					id: 3505,
					label: "b2b.ENUMS.ETipoDocumento.NotaCreditoAcquistoOrdinaria"
				},

				/**
				 * Nota di credito ordinaria
				 */
				NotaCreditoOrdinaria: {
					id: 3506,
					label: "b2b.ENUMS.ETipoDocumento.NotaCreditoOrdinaria"
				},

				/**
				 * Nota di debito
				 */
				NotaDiDebito: {
					id: 3507,
					label: "b2b.ENUMS.ETipoDocumento.NotaDiDebito"
				},

				/**
				 * Nota di debito ordinaria
				 */
				NotaDiDebitoOrdinaria: {
					id: 3508,
					label: "b2b.ENUMS.ETipoDocumento.NotaDiDebitoOrdinaria"
				},

				/**
				 * Preventivo agenzia
				 */
				Offerta: {
					id: 3509,
					label: "b2b.ENUMS.ETipoDocumento.Offerta"
				},

				/**
				 * Prenotazione
				 */
				Prenotazione: {
					id: 3510,
					label: "b2b.ENUMS.ETipoDocumento.Prenotazione"
				},

				/**
				 * Prenotazione host-to-host
				 */
				PrenotazioneH2H: {
					id: 3511,
					label: "b2b.ENUMS.ETipoDocumento.PrenotazioneH2H"
				},

				/**
				 * Preventivo cliente sintesi
				 */
				PreventivoCliente: {
					id: 3512,
					label: "b2b.ENUMS.ETipoDocumento.PreventivoCliente"
				},

				/**
				 * Preventivo cliente
				 */
				PreventivoClientePlus: {
					id: 3513,
					label: "b2b.ENUMS.ETipoDocumento.PreventivoClientePlus"
				},

				/**
				 * Preventivo cliente traducibile
				 */
				PreventivoClienteTraducibile: {
					id: 3514,
					label: "b2b.ENUMS.ETipoDocumento.PreventivoClienteTraducibile"
				},

				/**
				 * Programma di viaggio
				 */
				ProgrammaViaggio: {
					id: 3515,
					label: "b2b.ENUMS.ETipoDocumento.ProgrammaViaggio"
				},

				/**
				 * Programma di Viaggio 2022
				 */
				ProgrammaViaggio2022: {
					id: 3516,
					label: "b2b.ENUMS.ETipoDocumento.ProgrammaViaggio2022"
				},

				/**
				 * Programma di Viaggio 2023
				 */
				ProgrammaViaggio2023: {
					id: 3517,
					label: "b2b.ENUMS.ETipoDocumento.ProgrammaViaggio2023"
				},

				/**
				 * Programma di viaggio traducibile
				 */
				ProgrammaViaggioTraducibile: {
					id: 3518,
					label: "b2b.ENUMS.ETipoDocumento.ProgrammaViaggioTraducibile"
				},

				/**
				 * Rapporto Contabile
				 */
				RapportoContabile: {
					id: 3519,
					label: "b2b.ENUMS.ETipoDocumento.RapportoContabile"
				},

				/**
				 * Riepilogo prenotazioni
				 */
				RiepilogoPrenotazioni: {
					id: 3520,
					label: "b2b.ENUMS.ETipoDocumento.RiepilogoPrenotazioni"
				},

				/**
				 * Riepilogo prenotazioni accordo
				 */
				RiepilogoPrenotazioniAccordo: {
					id: 3521,
					label: "b2b.ENUMS.ETipoDocumento.RiepilogoPrenotazioniAccordo"
				},

				/**
				 * Riepilogo prenotazioni NU
				 */
				RiepilogoPrenotazioniNU: {
					id: 3522,
					label: "b2b.ENUMS.ETipoDocumento.RiepilogoPrenotazioniNU"
				},

				/**
				 * Risposta per adv
				 */
				RispostaPerAdv: {
					id: 3523,
					label: "b2b.ENUMS.ETipoDocumento.RispostaPerAdv"
				},

				/**
				 * Risposta prenotazione
				 */
				RispostaPrenotazione: {
					id: 3524,
					label: "b2b.ENUMS.ETipoDocumento.RispostaPrenotazione"
				},

				/**
				 * Storno autofattura
				 */
				StornoAutofattura: {
					id: 3525,
					label: "b2b.ENUMS.ETipoDocumento.StornoAutofattura"
				},

				/**
				 * Voucher
				 */
				Voucher: {
					id: 3526,
					label: "b2b.ENUMS.ETipoDocumento.Voucher"
				},

				/**
				 * Voucher turistico
				 */
				VoucherTuristico: {
					id: 3527,
					label: "b2b.ENUMS.ETipoDocumento.VoucherTuristico"
				},

				/**
				 * ENUMS.ETipoDocumento.Kanban
				 */
				Kanban: {
					id: 6536,
					label: "b2b.ENUMS.ETipoDocumento.Kanban"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoImportoAcconto
			 */
			ETipoImportoAcconto: {
				/**
				 * Percentuale
				 */
				Percentuale: {
					id: 3528,
					label: "b2b.ENUMS.ETipoImportoAcconto.Percentuale"
				},

				/**
				 * Importo costo
				 */
				ValoreAssolutoCosto: {
					id: 3529,
					label: "b2b.ENUMS.ETipoImportoAcconto.ValoreAssolutoCosto"
				},

				/**
				 * Valore assoluto
				 */
				ValoreAssolutoPrezzo: {
					id: 3530,
					label: "b2b.ENUMS.ETipoImportoAcconto.ValoreAssolutoPrezzo"
				},

				/**
				 * Importo costo + markup
				 */
				ValoreCostoConMarkup: {
					id: 3531,
					label: "b2b.ENUMS.ETipoImportoAcconto.ValoreCostoConMarkup"
				},

			},

			/**
			 * Content of path b2b.ENUMS.eTipoNota
			 */
			eTipoNota: {
				/**
				 * Nota agenzia
				 */
				Agenzia: {
					id: 3532,
					label: "b2b.ENUMS.eTipoNota.Agenzia"
				},

				/**
				 * Nota assistenza
				 */
				Assistenza: {
					id: 3533,
					label: "b2b.ENUMS.eTipoNota.Assistenza"
				},

				/**
				 * Nota interna
				 */
				Interna: {
					id: 3534,
					label: "b2b.ENUMS.eTipoNota.Interna"
				},

				/**
				 * Nota operatore
				 */
				Operatore: {
					id: 3535,
					label: "b2b.ENUMS.eTipoNota.Operatore"
				},

				/**
				 * Nota passeggero
				 */
				Passeggero: {
					id: 3536,
					label: "b2b.ENUMS.eTipoNota.Passeggero"
				},

				/**
				 * Nota preventivo multipla
				 */
				PreventivoMultipla: {
					id: 3537,
					label: "b2b.ENUMS.eTipoNota.PreventivoMultipla"
				},

				/**
				 * Nota preventivo singola
				 */
				PreventivoSingola: {
					id: 3538,
					label: "b2b.ENUMS.eTipoNota.PreventivoSingola"
				},

				/**
				 * Nota risposta agenzia
				 */
				RispostaAgenzia: {
					id: 3539,
					label: "b2b.ENUMS.eTipoNota.RispostaAgenzia"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoOperazioneIncasso
			 */
			ETipoOperazioneIncasso: {
				/**
				 * Acconto
				 */
				Acconto: {
					id: 3540,
					label: "b2b.ENUMS.ETipoOperazioneIncasso.Acconto"
				},

				/**
				 * Cessione
				 */
				Cessione: {
					id: 3541,
					label: "b2b.ENUMS.ETipoOperazioneIncasso.Cessione"
				},

				/**
				 * Saldo
				 */
				Saldo: {
					id: 3542,
					label: "b2b.ENUMS.ETipoOperazioneIncasso.Saldo"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoPagamentoIncasso
			 */
			ETipoPagamentoIncasso: {
				/**
				 * Bonifico
				 */
				Bonifico: {
					id: 3543,
					label: "b2b.ENUMS.ETipoPagamentoIncasso.Bonifico"
				},

				/**
				 * Factoring
				 */
				Factoring: {
					id: 3544,
					label: "b2b.ENUMS.ETipoPagamentoIncasso.Factoring"
				},

				/**
				 * RID
				 */
				RID: {
					id: 3545,
					label: "b2b.ENUMS.ETipoPagamentoIncasso.RID"
				},

				/**
				 * TravelFactoring
				 */
				TravelFactoring: {
					id: 3546,
					label: "b2b.ENUMS.ETipoPagamentoIncasso.TravelFactoring"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoPax
			 */
			ETipoPax: {
				/**
				 * Adulto
				 */
				Adulto: {
					id: 3547,
					label: "b2b.ENUMS.ETipoPax.Adulto"
				},

				/**
				 * Child
				 */
				Child: {
					id: 3548,
					label: "b2b.ENUMS.ETipoPax.Child"
				},

				/**
				 * Infant
				 */
				Infant: {
					id: 3549,
					label: "b2b.ENUMS.ETipoPax.Infant"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoServizio
			 */
			ETipoServizio: {
				/**
				 * Hotel
				 */
				Hotel: {
					id: 3550,
					label: "b2b.ENUMS.ETipoServizio.Hotel"
				},

				/**
				 * Itinerari consigliati
				 */
				ItinerariConsigliati: {
					id: 3551,
					label: "b2b.ENUMS.ETipoServizio.ItinerariConsigliati"
				},

				/**
				 * Kit viaggio
				 */
				KitViaggio: {
					id: 3552,
					label: "b2b.ENUMS.ETipoServizio.KitViaggio"
				},

				/**
				 * Kit viaggio OLD
				 */
				KitViaggioOLD: {
					id: 3553,
					label: "b2b.ENUMS.ETipoServizio.KitViaggioOLD"
				},

				/**
				 * Noleggi auto
				 */
				NoleggiAuto: {
					id: 3554,
					label: "b2b.ENUMS.ETipoServizio.NoleggiAuto"
				},

				/**
				 * Pacchetti
				 */
				Pacchetti: {
					id: 3555,
					label: "b2b.ENUMS.ETipoServizio.Pacchetti"
				},

				/**
				 * Pacchetti catalogo
				 */
				PacchettiCatalogo: {
					id: 3556,
					label: "b2b.ENUMS.ETipoServizio.PacchettiCatalogo"
				},

				/**
				 * Pacchetti dinamici
				 */
				PacchettiDinamici: {
					id: 3557,
					label: "b2b.ENUMS.ETipoServizio.PacchettiDinamici"
				},

				/**
				 * Selfdrive
				 */
				SelfDrive: {
					id: 3558,
					label: "b2b.ENUMS.ETipoServizio.SelfDrive"
				},

				/**
				 * Tour e crociere
				 */
				TourECrociere: {
					id: 3559,
					label: "b2b.ENUMS.ETipoServizio.TourECrociere"
				},

				/**
				 * Trasferimenti
				 */
				Trasferimenti: {
					id: 3560,
					label: "b2b.ENUMS.ETipoServizio.Trasferimenti"
				},

				/**
				 * Trasferimenti e visite
				 */
				TrasferimentiEVisite: {
					id: 3561,
					label: "b2b.ENUMS.ETipoServizio.TrasferimentiEVisite"
				},

				/**
				 * -- ND --
				 */
				undefined: {
					id: 3562,
					label: "b2b.ENUMS.ETipoServizio.undefined"
				},

				/**
				 * Visite
				 */
				Visite: {
					id: 3563,
					label: "b2b.ENUMS.ETipoServizio.Visite"
				},

				/**
				 * Voli
				 */
				Voli: {
					id: 3564,
					label: "b2b.ENUMS.ETipoServizio.Voli"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoTappa
			 */
			ETipoTappa: {
				/**
				 * Accessori
				 */
				Accessori: {
					id: 3565,
					label: "b2b.ENUMS.ETipoTappa.Accessori"
				},

				/**
				 * Inizio Viaggio
				 */
				InizioViaggio: {
					id: 3566,
					label: "b2b.ENUMS.ETipoTappa.InizioViaggio"
				},

				/**
				 * In Volo
				 */
				InVolo: {
					id: 3567,
					label: "b2b.ENUMS.ETipoTappa.InVolo"
				},

				/**
				 * Itinerario Selfdrive
				 */
				ItinerarioSelfdrive: {
					id: 3568,
					label: "b2b.ENUMS.ETipoTappa.ItinerarioSelfdrive"
				},

				/**
				 * Itinerario Tour
				 */
				ItinerarioTour: {
					id: 3569,
					label: "b2b.ENUMS.ETipoTappa.ItinerarioTour"
				},

				/**
				 * Normale
				 */
				Normale: {
					id: 3570,
					label: "b2b.ENUMS.ETipoTappa.Normale"
				},

				/**
				 * Soggiorno Libero
				 */
				SoggiornoLibero: {
					id: 3571,
					label: "b2b.ENUMS.ETipoTappa.SoggiornoLibero"
				},

				/**
				 * Termine Viaggio
				 */
				TermineViaggio: {
					id: 3572,
					label: "b2b.ENUMS.ETipoTappa.TermineViaggio"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoTicketBiglietteria
			 */
			ETipoTicketBiglietteria: {
				/**
				 * IP
				 */
				IP: {
					id: 3573,
					label: "b2b.ENUMS.ETipoTicketBiglietteria.IP"
				},

				/**
				 * -- Nessuna --
				 */
				_null: {
					id: 3574,
					label: "b2b.ENUMS.ETipoTicketBiglietteria.null"
				},

				/**
				 * TO
				 */
				TO: {
					id: 3575,
					label: "b2b.ENUMS.ETipoTicketBiglietteria.TO"
				},

				/**
				 * Tutto
				 */
				Tutto: {
					id: 3576,
					label: "b2b.ENUMS.ETipoTicketBiglietteria.Tutto"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoTrasporto
			 */
			ETipoTrasporto: {
				/**
				 * Aereo
				 */
				Aereo: {
					id: 3577,
					label: "b2b.ENUMS.ETipoTrasporto.Aereo"
				},

				/**
				 * Auto
				 */
				Auto: {
					id: 3578,
					label: "b2b.ENUMS.ETipoTrasporto.Auto"
				},

				/**
				 * Indefinito
				 */
				Indefinito: {
					id: 3579,
					label: "b2b.ENUMS.ETipoTrasporto.Indefinito"
				},

				/**
				 * Traghetto
				 */
				Traghetto: {
					id: 3580,
					label: "b2b.ENUMS.ETipoTrasporto.Traghetto"
				},

				/**
				 * Treno
				 */
				Treno: {
					id: 3581,
					label: "b2b.ENUMS.ETipoTrasporto.Treno"
				},

				/**
				 * ENUMS.ETipoTrasporto.undefined
				 */
				undefined: {
					id: 6611,
					label: "b2b.ENUMS.ETipoTrasporto.undefined"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoUtente
			 */
			ETipoUtente: {
				/**
				 * Agenzia
				 */
				Agenzia: {
					id: 3582,
					label: "b2b.ENUMS.ETipoUtente.Agenzia"
				},

				/**
				 * Intranet
				 */
				Intranet: {
					id: 3583,
					label: "b2b.ENUMS.ETipoUtente.Intranet"
				},

				/**
				 * Privato
				 */
				Privato: {
					id: 3584,
					label: "b2b.ENUMS.ETipoUtente.Privato"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoViaggio
			 */
			ETipoViaggio: {
				/**
				 * Adv
				 */
				Adv: {
					id: 3585,
					label: "b2b.ENUMS.ETipoViaggio.Adv"
				},

				/**
				 * EasyTop
				 */
				EasyTop: {
					id: 3586,
					label: "b2b.ENUMS.ETipoViaggio.EasyTop"
				},

				/**
				 * Gruppo
				 */
				Gruppo: {
					id: 3587,
					label: "b2b.ENUMS.ETipoViaggio.Gruppo"
				},

				/**
				 * Indefinito
				 */
				Indefinito: {
					id: 3588,
					label: "b2b.ENUMS.ETipoViaggio.Indefinito"
				},

				/**
				 * Nozze
				 */
				Nozze: {
					id: 3589,
					label: "b2b.ENUMS.ETipoViaggio.Nozze"
				},

				/**
				 * Pacchetto
				 */
				Pacchetto: {
					id: 3590,
					label: "b2b.ENUMS.ETipoViaggio.Pacchetto"
				},

				/**
				 * Attività Naar
				 */
				Tci: {
					id: 3591,
					label: "b2b.ENUMS.ETipoViaggio.Tci"
				},

				/**
				 * Vip
				 */
				Vip: {
					id: 3592,
					label: "b2b.ENUMS.ETipoViaggio.Vip"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoViaggioPreventivo
			 */
			ETipoViaggioPreventivo: {
				/**
				 * Viaggio di gruppo
				 */
				Gruppo: {
					id: 3593,
					label: "b2b.ENUMS.ETipoViaggioPreventivo.Gruppo"
				},

				/**
				 * Indefinito
				 */
				Indefinito: {
					id: 3594,
					label: "b2b.ENUMS.ETipoViaggioPreventivo.Indefinito"
				},

				/**
				 * Viaggio di nozze
				 */
				Nozze: {
					id: 3595,
					label: "b2b.ENUMS.ETipoViaggioPreventivo.Nozze"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoWarningOfferta
			 */
			ETipoWarningOfferta: {
				/**
				 * Almeno un cliente deve avere il codice fiscale
				 */
				CodiceFiscaleMancante: {
					id: 3596,
					label: "b2b.ENUMS.ETipoWarningOfferta.CodiceFiscaleMancante"
				},

				/**
				 * Il cliente {oggetto} ha un codice fiscale non valido
				 */
				CodiceFiscaleNonValido: {
					id: 3597,
					label: "b2b.ENUMS.ETipoWarningOfferta.CodiceFiscaleNonValido"
				},

				/**
				 * Il cliente {oggetto} è indicato come bambino ma avrà più di 18 anni alla partenza del viaggio
				 */
				DataNascitaNonCoerente: {
					id: 3598,
					label: "b2b.ENUMS.ETipoWarningOfferta.DataNascitaNonCoerente"
				},

				/**
				 * La data di nascita del cliente {oggetto} è quella generica per un adulto. Se è corretta va confermata.
				 */
				DataNascitaNonConfermata: {
					id: 3599,
					label: "b2b.ENUMS.ETipoWarningOfferta.DataNascitaNonConfermata"
				},

				/**
				 * Il cliente {oggetto} non ha tutti i dati necessari. Richiesti nome, cognome, data di nascita
				 */
				DatiClienteIncompleti: {
					id: 3600,
					label: "b2b.ENUMS.ETipoWarningOfferta.DatiClienteIncompleti"
				},

				/**
				 * Alcuni itinerari non sono consecutivi
				 */
				ItinerariNonConsecutivi: {
					id: 3601,
					label: "b2b.ENUMS.ETipoWarningOfferta.ItinerariNonConsecutivi"
				},

				/**
				 * Alcuni itinerari sono sovrapposti
				 */
				ItinerariSovrapposti: {
					id: 3602,
					label: "b2b.ENUMS.ETipoWarningOfferta.ItinerariSovrapposti"
				},

				/**
				 * L'offerta non contiene abbinamenti
				 */
				NessunAbbinamento: {
					id: 3603,
					label: "b2b.ENUMS.ETipoWarningOfferta.NessunAbbinamento"
				},

				/**
				 * L'offerta non ha nessun cliente
				 */
				NessunCliente: {
					id: 3604,
					label: "b2b.ENUMS.ETipoWarningOfferta.NessunCliente"
				},

				/**
				 * L'offerta non ha nessun itinerario
				 */
				NessunItinerario: {
					id: 3605,
					label: "b2b.ENUMS.ETipoWarningOfferta.NessunItinerario"
				},

				/**
				 * L'offerta non contiene servizi
				 */
				NessunServizio: {
					id: 3606,
					label: "b2b.ENUMS.ETipoWarningOfferta.NessunServizio"
				},

				/**
				 * Sono presenti preventivi di volo non confermabili
				 */
				VoloSenzaPNR: {
					id: 3607,
					label: "b2b.ENUMS.ETipoWarningOfferta.VoloSenzaPNR"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETitolo
			 */
			ETitolo: {
				/**
				 * Bambina
				 */
				Bambina: {
					id: 3608,
					label: "b2b.ENUMS.ETitolo.Bambina"
				},

				/**
				 * Bambino
				 */
				Bambino: {
					id: 3609,
					label: "b2b.ENUMS.ETitolo.Bambino"
				},

				/**
				 * Dottor
				 */
				Dottor: {
					id: 3610,
					label: "b2b.ENUMS.ETitolo.Dottor"
				},

				/**
				 * Dottssa
				 */
				Dottssa: {
					id: 3611,
					label: "b2b.ENUMS.ETitolo.Dottssa"
				},

				/**
				 * Sas
				 */
				Sas: {
					id: 3612,
					label: "b2b.ENUMS.ETitolo.Sas"
				},

				/**
				 * Sconosciuto
				 */
				Sconosciuto: {
					id: 3613,
					label: "b2b.ENUMS.ETitolo.Sconosciuto"
				},

				/**
				 * Signor
				 */
				Signor: {
					id: 3614,
					label: "b2b.ENUMS.ETitolo.Signor"
				},

				/**
				 * Signora
				 */
				Signora: {
					id: 3615,
					label: "b2b.ENUMS.ETitolo.Signora"
				},

				/**
				 * Snc
				 */
				Snc: {
					id: 3616,
					label: "b2b.ENUMS.ETitolo.Snc"
				},

				/**
				 * SpA
				 */
				SpA: {
					id: 3617,
					label: "b2b.ENUMS.ETitolo.SpA"
				},

				/**
				 * Srl
				 */
				Srl: {
					id: 3618,
					label: "b2b.ENUMS.ETitolo.Srl"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETripStepHotelStatus
			 */
			ETripStepHotelStatus: {
				/**
				 * Hotel mancante
				 */
				Mancante: {
					id: 3619,
					label: "b2b.ENUMS.ETripStepHotelStatus.Mancante"
				},

				/**
				 * Ok
				 */
				Ok: {
					id: 3620,
					label: "b2b.ENUMS.ETripStepHotelStatus.Ok"
				},

				/**
				 * I servizi hotel non coprono tutti i giorni della tappa
				 */
				Parziale: {
					id: 3621,
					label: "b2b.ENUMS.ETripStepHotelStatus.Parziale"
				},

			},

			/**
			 * Content of path b2b.ENUMS.StatoVolo
			 */
			StatoVolo: {
				/**
				 * Confermato
				 */
				GK: {
					id: 3622,
					label: "b2b.ENUMS.StatoVolo.GK"
				},

				/**
				 * Confermato
				 */
				HK: {
					id: 3623,
					label: "b2b.ENUMS.StatoVolo.HK"
				},

				/**
				 * Lista di attesa
				 */
				HL: {
					id: 3624,
					label: "b2b.ENUMS.StatoVolo.HL"
				},

				/**
				 * Su richiesta
				 */
				HN: {
					id: 3625,
					label: "b2b.ENUMS.StatoVolo.HN"
				},

				/**
				 * Cancellato da compagnia
				 */
				HX: {
					id: 3626,
					label: "b2b.ENUMS.StatoVolo.HX"
				},

				/**
				 * Confermato da richiesta
				 */
				KK: {
					id: 3627,
					label: "b2b.ENUMS.StatoVolo.KK"
				},

				/**
				 * Confermato da lista
				 */
				KL: {
					id: 3628,
					label: "b2b.ENUMS.StatoVolo.KL"
				},

				/**
				 * Lista di attesa
				 */
				LL: {
					id: 3629,
					label: "b2b.ENUMS.StatoVolo.LL"
				},

				/**
				 * In attesa conferma
				 */
				NN: {
					id: 3630,
					label: "b2b.ENUMS.StatoVolo.NN"
				},

				/**
				 * Nessuna conferma
				 */
				NO: {
					id: 3631,
					label: "b2b.ENUMS.StatoVolo.NO"
				},

				/**
				 * Non prenotato
				 */
				SS: {
					id: 3632,
					label: "b2b.ENUMS.StatoVolo.SS"
				},

				/**
				 * Confermato cambio operativo
				 */
				TK: {
					id: 3633,
					label: "b2b.ENUMS.StatoVolo.TK"
				},

				/**
				 * Lista - cambio operativo
				 */
				TL: {
					id: 3634,
					label: "b2b.ENUMS.StatoVolo.TL"
				},

				/**
				 * Su richiesta - cambio operativo
				 */
				TN: {
					id: 3635,
					label: "b2b.ENUMS.StatoVolo.TN"
				},

				/**
				 * Impossibile confermare
				 */
				UC: {
					id: 3636,
					label: "b2b.ENUMS.StatoVolo.UC"
				},

				/**
				 * Impossibile confermare
				 */
				UN: {
					id: 3637,
					label: "b2b.ENUMS.StatoVolo.UN"
				},

				/**
				 * Lista di attesa (US)
				 */
				US: {
					id: 3638,
					label: "b2b.ENUMS.StatoVolo.US"
				},

				/**
				 * Lista di attesa (UU)
				 */
				UU: {
					id: 3639,
					label: "b2b.ENUMS.StatoVolo.UU"
				},

				/**
				 * Lista di attesa
				 */
				WL: {
					id: 3640,
					label: "b2b.ENUMS.StatoVolo.WL"
				},

			},

			/**
			 * Content of path b2b.ENUMS.ETipoEmissioneVoucher
			 */
			ETipoEmissioneVoucher: {
				/**
				 * No
				 */
				Nessuna: {
					id: 5951,
					label: "b2b.ENUMS.ETipoEmissioneVoucher.Nessuna"
				},

				/**
				 * Un voucher per offerta
				 */
				UnVoucherAOfferta: {
					id: 5952,
					label: "b2b.ENUMS.ETipoEmissioneVoucher.UnVoucherAOfferta"
				},

				/**
				 * Un voucher a servizio
				 */
				UnVoucherAServizio: {
					id: 5953,
					label: "b2b.ENUMS.ETipoEmissioneVoucher.UnVoucherAServizio"
				},

				/**
				 * Voucher del fornitore
				 */
				VoucherFornitore: {
					id: 5954,
					label: "b2b.ENUMS.ETipoEmissioneVoucher.VoucherFornitore"
				},

			},

			/**
			 * Servizio
			 */
			ETipoServizioFlag: {
				id: 5989,
				label: "b2b.ENUMS.ETipoServizioFlag.undefined"
			},

		},

		/**
		 * Content of path b2b.ERRORS
		 */
		ERRORS: {
			/**
			 * Impossibile cancellare l'Abbinamento ID={abbinamentoID} {abbinamento}: Riga ID={rigaID} - {descrizione} ha un servizio prenotato/confermato
			 */
			ABBINAMENTO_NON_ELIMINABILE_PER_CONFERME: {
				id: 3641,
				label: "b2b.ERRORS.ABBINAMENTO_NON_ELIMINABILE_PER_CONFERME"
			},

			/**
			 * Impossibile modificare il gruppo '{abbinamento}' ID={abbinamentoID}. Servizio RigaID={rigaID} {descrizione} è confermato o in prenotazione.
			 */
			ABBINAMENTO_NON_MODIFICABILE: {
				id: 3642,
				label: "b2b.ERRORS.ABBINAMENTO_NON_MODIFICABILE"
			},

			/**
			 * Camera / abbinamento ID={abbinamentoID} non trovato nell'offerta ID={offertaID}
			 */
			ABBINAMENTO_NON_TROVATO: {
				id: 3643,
				label: "b2b.ERRORS.ABBINAMENTO_NON_TROVATO"
			},

			/**
			 * Accordo ID={accordoID} {nome} è Host2Host. Non è possibile assegnare direttamente questo accordo, selezionare un altro accordo o eseguire una nuova ricerca e aggiungere il servizio.
			 */
			ACCORDO_H2H_NON_ASSEGNABILE_DIRETTAMENTE: {
				id: 3644,
				label: "b2b.ERRORS.ACCORDO_H2H_NON_ASSEGNABILE_DIRETTAMENTE"
			},

			/**
			 * Accordo ID={accordoID} non trovato
			 */
			ACCORDO_NON_TROVATO: {
				id: 3645,
				label: "b2b.ERRORS.ACCORDO_NON_TROVATO"
			},

			/**
			 * Accordo ID={accordoID} non ha tabelle costi commissioni valide ora
			 */
			ACCORDO_SENZA_TABELLE_COSTO_VALIDE: {
				id: 3646,
				label: "b2b.ERRORS.ACCORDO_SENZA_TABELLE_COSTO_VALIDE"
			},

			/**
			 * Agenzia mancante per offerta ID={offertaID}
			 */
			AGENZIA_MANCANTE: {
				id: 3647,
				label: "b2b.ERRORS.AGENZIA_MANCANTE"
			},

			/**
			 * Offerta ID={offertaID}, agenzia Contatto ID={contattoID} ({nome}) priva di accordo commerciale con operatore {operatore}.
			 */
			AGENZIA_PRIVA_DI_ACCORDO_COMMERCIALE: {
				id: 3648,
				label: "b2b.ERRORS.AGENZIA_PRIVA_DI_ACCORDO_COMMERCIALE"
			},

			/**
			 * Allegato ID={attachmentID} non esiste nell'articolo ID={articoloID}
			 */
			ALLEGATO_NON_TROVATO: {
				id: 3649,
				label: "b2b.ERRORS.ALLEGATO_NON_TROVATO"
			},

			/**
			 * L'articolo ID={articoloID} contiene un riferimento circolare agli articoli padre. Sequenza degli ID padre: {sequenzaid}
			 */
			ARTICOLO_CON_RIFERIMENTI_CIRCOLARI: {
				id: 3650,
				label: "b2b.ERRORS.ARTICOLO_CON_RIFERIMENTI_CIRCOLARI"
			},

			/**
			 * Articolo ID={articoloID} non esiste
			 */
			ARTICOLO_NON_TROVATO: {
				id: 3651,
				label: "b2b.ERRORS.ARTICOLO_NON_TROVATO"
			},

			/**
			 * Impossibile cancellare Cliente ID={clienteID} {nome} {cognome} associato all'Abbinamento ID={abbinamentoID} {abbinamento}: Riga ID={rigaID} - {descrizione} ha un servizio prenotato/confermato
			 */
			CLIENTE_NON_ELIMINABILE_PER_CONFERME: {
				id: 3652,
				label: "b2b.ERRORS.CLIENTE_NON_ELIMINABILE_PER_CONFERME"
			},

			/**
			 * Impossibile cancellare Cliente ID={clienteID} {nome} {cognome}: è il cliente di riferimento per Riga ID={rigaID} - {descrizione}
			 */
			CLIENTE_NON_ELIMINABILE_PER_RIFERIMENTO: {
				id: 3653,
				label: "b2b.ERRORS.CLIENTE_NON_ELIMINABILE_PER_RIFERIMENTO"
			},

			/**
			 * Impossibile modificare Cliente ID={clienteID} {nome} {cognome} associato all'Abbinamento ID={abbinamentoID} {abbinamento}: Riga ID={rigaID} - {descrizione} ha un servizio prenotato/confermato
			 */
			CLIENTE_NON_MODIFICABILE: {
				id: 3654,
				label: "b2b.ERRORS.CLIENTE_NON_MODIFICABILE"
			},

			/**
			 * Cliente ID={clienteID} non esiste associato all'Offerta ID={offertaID} / OffertaAgenzia ID={offertaAgenziaID}
			 */
			CLIENTE_NON_TROVATO: {
				id: 3655,
				label: "b2b.ERRORS.CLIENTE_NON_TROVATO"
			},

			/**
			 * Il contatto ID={contattoID} non esiste
			 */
			CONTATTO_ID_INESISTENTE: {
				id: 3656,
				label: "b2b.ERRORS.CONTATTO_ID_INESISTENTE"
			},

			/**
			 * Costo ID={costoID} non trovato
			 */
			COSTO_NON_TROVATO: {
				id: 3657,
				label: "b2b.ERRORS.COSTO_NON_TROVATO"
			},

			/**
			 * Data di fine periodo ricerca prezzi non determinabile. Indicare una data specifica o una tappa di un'offerta
			 */
			DATA_FINE_PERIODO_RICERCA_INDETERMINATA: {
				id: 3658,
				label: "b2b.ERRORS.DATA_FINE_PERIODO_RICERCA_INDETERMINATA"
			},

			/**
			 * Data di inizio periodo ricerca prezzi non determinabile. Indicare una data specifica o una tappa di un'offerta
			 */
			DATA_INIZIO_PERIODO_RICERCA_INDETERMINATA: {
				id: 3659,
				label: "b2b.ERRORS.DATA_INIZIO_PERIODO_RICERCA_INDETERMINATA"
			},

			/**
			 * Date di inizio ({dal}) e fine ({al}) incompatibili.
			 */
			DATE_RICERCA_INCOMPATIBILI: {
				id: 3660,
				label: "b2b.ERRORS.DATE_RICERCA_INCOMPATIBILI"
			},

			/**
			 * Impossibile cancellare: Documento ID={documentoID} è associato alla riga servizio ID={rigaID} {descrizione} che è confermata.
			 */
			DOCUMENTO_NON_ELIMINABILE_ASSOCIATO_A_RIGA_CONFERMATA: {
				id: 3661,
				label: "b2b.ERRORS.DOCUMENTO_NON_ELIMINABILE_ASSOCIATO_A_RIGA_CONFERMATA"
			},

			/**
			 * Documento ID={documentoID} non esiste
			 */
			DOCUMENTO_NON_TROVATO: {
				id: 3662,
				label: "b2b.ERRORS.DOCUMENTO_NON_TROVATO"
			},

			/**
			 * Documento ID={documentoID} non esiste nell'offerta ID={offertaID}
			 */
			DOCUMENTO_OFFERTA_NON_TROVATO: {
				id: 3663,
				label: "b2b.ERRORS.DOCUMENTO_OFFERTA_NON_TROVATO"
			},

			/**
			 * Nessun itinerario abbinato o creato
			 */
			ERRORE_CREAZIONE_ITINERARIO: {
				id: 3664,
				label: "b2b.ERRORS.ERRORE_CREAZIONE_ITINERARIO"
			},

			/**
			 * Fascia prezzo {importo} non trovata per servizio codice={codice}
			 */
			FASCIA_PREZZO_SERVIZIO_NON_TROVATA: {
				id: 3665,
				label: "b2b.ERRORS.FASCIA_PREZZO_SERVIZIO_NON_TROVATA"
			},

			/**
			 * Servizio codice={codice} {nome} privo di fascia prezzo
			 */
			FASCIA_SERVIZIO_NON_TROVATA: {
				id: 3666,
				label: "b2b.ERRORS.FASCIA_SERVIZIO_NON_TROVATA"
			},

			/**
			 * Il giorno della tappa deve essere un numero e deve essere compreso tra 1 e 255
			 */
			GIORNO_MANCANTE: {
				id: 3667,
				label: "b2b.ERRORS.GIORNO_MANCANTE"
			},

			/**
			 * Il giorno {data} non ricade nell'itinerario ID={itinerarioID} dal {dal} al {al}
			 */
			GIORNO_NON_APPARTENENTE_AD_ITINERARIO: {
				id: 3668,
				label: "b2b.ERRORS.GIORNO_NON_APPARTENENTE_AD_ITINERARIO"
			},

			/**
			 * Il valore {giorno} non è valido per il giorno della tappa: richiesto un numero tra 1 e 255
			 */
			GIORNO_NON_VALIDO: {
				id: 3669,
				label: "b2b.ERRORS.GIORNO_NON_VALIDO"
			},

			/**
			 * Impossibile eliminare le quote di apertura pratica
			 */
			IMPOSSIBILE_ELIMINARE_QUOTE_APERTURA: {
				id: 3670,
				label: "b2b.ERRORS.IMPOSSIBILE_ELIMINARE_QUOTE_APERTURA"
			},

			/**
			 * Impossibile eliminare la polizza medico bagaglio base. E' necessaria per {nome}
			 */
			IMPOSSIBILE_ELIMINARE_RIGA_ASSICURAZIONE_BASE: {
				id: 3671,
				label: "b2b.ERRORS.IMPOSSIBILE_ELIMINARE_RIGA_ASSICURAZIONE_BASE"
			},

			/**
			 * Impossibile eliminare l'assicurazione {nome}: servizio confermato o in corso di conferma
			 */
			IMPOSSIBILE_ELIMINARE_RIGA_ASSICURAZIONE_INVIATA: {
				id: 3672,
				label: "b2b.ERRORS.IMPOSSIBILE_ELIMINARE_RIGA_ASSICURAZIONE_INVIATA"
			},

			/**
			 * Riga ID={rigaID} dell'offerta ID={offertaID} è associata ad un pagamento a fornitore. Contattare l'amministrazione per procedere
			 */
			IMPOSSIBILE_ELIMINARE_RIGA_CON_PAGAMENTO_FORNITORE: {
				id: 3673,
				label: "b2b.ERRORS.IMPOSSIBILE_ELIMINARE_RIGA_CON_PAGAMENTO_FORNITORE"
			},

			/**
			 * Impossibile eliminare la riga ID={rigaID} dell'offerta ID={offertaID}: servizio confermato o in corso di conferma
			 */
			IMPOSSIBILE_ELIMINARE_RIGA_CONFERMATA_O_IN_CONFERMA: {
				id: 3674,
				label: "b2b.ERRORS.IMPOSSIBILE_ELIMINARE_RIGA_CONFERMATA_O_IN_CONFERMA"
			},

			/**
			 * Servizio non eliminabile
			 */
			IMPOSSIBILE_ELIMINARE_RIGA_SERVIZIO_NON_ELIMINABILE: {
				id: 3675,
				label: "b2b.ERRORS.IMPOSSIBILE_ELIMINARE_RIGA_SERVIZIO_NON_ELIMINABILE"
			},

			/**
			 * Impossibile eliminare servizi su una conferma
			 */
			IMPOSSIBILE_ELIMINARE_SERVIZI_SU_UNA_CONFERMA: {
				id: 3676,
				label: "b2b.ERRORS.IMPOSSIBILE_ELIMINARE_SERVIZI_SU_UNA_CONFERMA"
			},

			/**
			 * Impossibile inserire We Care You Smile Plus se si è rinunciato a We Care You Smile
			 */
			IMPOSSIBILE_INSERIRE_AMTP_SENZA_AMTW: {
				id: 3677,
				label: "b2b.ERRORS.IMPOSSIBILE_INSERIRE_AMTP_SENZA_AMTW"
			},

			/**
			 * Impossibile rinunciare a {servizio}: i dati sono già stati inviati all'assicurazione in data {data}
			 */
			IMPOSSIBILE_RINUNCIARE_A_SERVIZIO: {
				id: 3678,
				label: "b2b.ERRORS.IMPOSSIBILE_RINUNCIARE_A_SERVIZIO"
			},

			/**
			 * Offerta ID={offertaID} ha impostata data conferma o data cancellazione conferma. Impossibile ripristinare.
			 */
			IMPOSSIBILE_RIPRISTINARE: {
				id: 3679,
				label: "b2b.ERRORS.IMPOSSIBILE_RIPRISTINARE"
			},

			/**
			 * Impossibile sostituire {descrizione}: Servizio confermato o in prenotazione.
			 */
			IMPOSSIBILE_SOSTITUIRE_SERVIZIO: {
				id: 3680,
				label: "b2b.ERRORS.IMPOSSIBILE_SOSTITUIRE_SERVIZIO"
			},

			/**
			 * Impostare almeno una coppia di date di filtro: Partenza dal/al o Conferma dal/al
			 */
			IMPOSTARE_ALMENO_UNA_COPPIA_DI_DATE_FILTRO: {
				id: 3681,
				label: "b2b.ERRORS.IMPOSTARE_ALMENO_UNA_COPPIA_DI_DATE_FILTRO"
			},

			/**
			 * Tappa ID={itinerarioID} non esiste nell'offerta ID={offertaID} / OffertaAgenzia ID={offertaAgenziaID}
			 */
			ITINERARIO_NON_TROVATO: {
				id: 3682,
				label: "b2b.ERRORS.ITINERARIO_NON_TROVATO"
			},

			/**
			 * L'itinerario ID={itinerarioID} non ha tipologie associate nel servizio ID={servizioID} {servizio}
			 */
			ITINERARIO_SERVIZIO_NON_ASSOCIATO_A_TIPOLOGIE: {
				id: 3683,
				label: "b2b.ERRORS.ITINERARIO_SERVIZIO_NON_ASSOCIATO_A_TIPOLOGIE"
			},

			/**
			 * Itinerario ID={itinerarioID} della tipologia ID={tipologiaID} {tipologia} non esiste nel servizio ID={servizioID} {servizio}
			 */
			ITINERARIO_TIPOLOGIA_NON_TROVATO: {
				id: 3684,
				label: "b2b.ERRORS.ITINERARIO_TIPOLOGIA_NON_TROVATO"
			},

			/**
			 * Itinerario tour ID={itinerarioID} abbinato alla tipologia ID={tipologiaID} {tipologia} del servizio ID={servizioID} {servizio} non trovato
			 */
			ITINERARIO_TOUR_ASSOCIATO_TIPOLOGIA_NON_TROVATO: {
				id: 3685,
				label: "b2b.ERRORS.ITINERARIO_TOUR_ASSOCIATO_TIPOLOGIA_NON_TROVATO"
			},

			/**
			 * Itinerario ID={itinerarioID} non trovato nel servizio ID={servizioID} {nome}
			 */
			ITINERARIO_TOUR_NON_TROVATO: {
				id: 3686,
				label: "b2b.ERRORS.ITINERARIO_TOUR_NON_TROVATO"
			},

			/**
			 * Luogo ID={luogoID} non trovato
			 */
			LUOGO_NON_TROVATO: {
				id: 3687,
				label: "b2b.ERRORS.LUOGO_NON_TROVATO"
			},

			/**
			 * Luogo per ricerca prezzi servizi non determinabile. Indicare un luogo o una tappa di un'offerta
			 */
			LUOGO_RICERCA_PREZZI_SERVIZI_INDETERMINATO: {
				id: 3688,
				label: "b2b.ERRORS.LUOGO_RICERCA_PREZZI_SERVIZI_INDETERMINATO"
			},

			/**
			 * Nota ID={notaID} è di sistema e non si puà² modificare.
			 */
			NOTA_DI_SISTEMA_NON_MODIFICABILE: {
				id: 3689,
				label: "b2b.ERRORS.NOTA_DI_SISTEMA_NON_MODIFICABILE"
			},

			/**
			 * Nota ID={notaID} è associata all'accordo ID={accordoID}. Eliminare i servizi dell'offerta con l'accordo per eliminare la nota.
			 */
			NOTA_NON_ELIMINABILE_LEGATA_AD_ACCORDO: {
				id: 3690,
				label: "b2b.ERRORS.NOTA_NON_ELIMINABILE_LEGATA_AD_ACCORDO"
			},

			/**
			 * Nota ID={notaID} è di sistema e non si puà² modificare.
			 */
			NOTA_NON_TROVATA: {
				id: 3691,
				label: "b2b.ERRORS.NOTA_NON_TROVATA"
			},

			/**
			 * Le notti della tappa devono essere un numero e devono essere comprese tra 0 e 255
			 */
			NOTTI_MANCANTI: {
				id: 3692,
				label: "b2b.ERRORS.NOTTI_MANCANTI"
			},

			/**
			 * Il valore {notti} non è valido per le notti della tappa: richiesto un numero tra 0 e 255
			 */
			NOTTI_NON_VALIDE: {
				id: 3693,
				label: "b2b.ERRORS.NOTTI_NON_VALIDE"
			},

			/**
			 * L'operazione richiede che l'offerta ID={offertaID} abbia la conferma Tour Operator
			 */
			OFFERTA_NON_CONFERMATA_TO: {
				id: 3694,
				label: "b2b.ERRORS.OFFERTA_NON_CONFERMATA_TO"
			},

			/**
			 * Offerta {offertaID} non trovata
			 */
			OFFERTA_NON_TROVATA: {
				id: 3695,
				label: "b2b.ERRORS.OFFERTA_NON_TROVATA"
			},

			/**
			 * Passeggeri e/o abbinamenti non determinabili. Specificare l'elenco passeggeri o indicare gli ID cliente di un'offerta o gli ID abbinamento di un'offerta
			 */
			PASSEGGERI_INDETERMINATI: {
				id: 3696,
				label: "b2b.ERRORS.PASSEGGERI_INDETERMINATI"
			},

			/**
			 * Polizza non cumulabile con We Care You Smile
			 */
			POLIZZA_NON_CUMULABILE_CON_AMTW: {
				id: 3697,
				label: "b2b.ERRORS.POLIZZA_NON_CUMULABILE_CON_AMTW"
			},

			/**
			 * Costo ID={costoID} è associato alla regola vendita ID={regolaVenditaID} che non esiste
			 */
			REGOLA_VENDITA_ASSOCIATA_A_COSTO_NON_TROVATA: {
				id: 3698,
				label: "b2b.ERRORS.REGOLA_VENDITA_ASSOCIATA_A_COSTO_NON_TROVATA"
			},

			/**
			 * La ricera hotel deve essere per almeno una notte. Date richieste: {dal} - {al}
			 */
			RICERCA_HOTEL_ZERO_NOTTI: {
				id: 3699,
				label: "b2b.ERRORS.RICERCA_HOTEL_ZERO_NOTTI"
			},

			/**
			 * Ricerca ID={jobID} non trovata
			 */
			RICERCA_NON_TROVATA: {
				id: 3700,
				label: "b2b.ERRORS.RICERCA_NON_TROVATA"
			},

			/**
			 * Impossibile eseguire ricerche prezzo nel passato. Date richieste: {dal} - {al}
			 */
			RICERCA_PREZZI_NEL_PASSATO: {
				id: 3701,
				label: "b2b.ERRORS.RICERCA_PREZZI_NEL_PASSATO"
			},

			/**
			 * Riga ID={rigaID} non trovata all'interno dell'offerta ID={offertaID}
			 */
			RIGA_OFFERTA_NON_TROVATA: {
				id: 3702,
				label: "b2b.ERRORS.RIGA_OFFERTA_NON_TROVATA"
			},

			/**
			 * Riga ID={rigaID} {descrizione} è un servizio di tipo hotel. Il pernottamento richiede almeno una notte nella tappa
			 */
			RIGA_SERVIZIO_HOTEL_RICHIEDE_NOTTI: {
				id: 3703,
				label: "b2b.ERRORS.RIGA_SERVIZIO_HOTEL_RICHIEDE_NOTTI"
			},

			/**
			 * Riga ID={rigaID} {descrizione} è confermata o in prenotazione. Impossibile eliminare
			 */
			RIGA_SERVIZIO_NON_ELIMINABILE: {
				id: 3704,
				label: "b2b.ERRORS.RIGA_SERVIZIO_NON_ELIMINABILE"
			},

			/**
			 * Riga ID={rigaID} {descrizione} è confermata o in prenotazione. Impossibile modificare data o quantità 
			 */
			RIGA_SERVIZIO_NON_MODIFICABILE: {
				id: 3705,
				label: "b2b.ERRORS.RIGA_SERVIZIO_NON_MODIFICABILE"
			},

			/**
			 * Servizo Codice={codice} non trovato
			 */
			SERVIZIO_CODICE_NON_TROVATO: {
				id: 3706,
				label: "b2b.ERRORS.SERVIZIO_CODICE_NON_TROVATO"
			},

			/**
			 * Servizio {servizio} non inseribile in una pratica confermata
			 */
			SERVIZIO_NON_INSERIBILE_SU_PRATICA_CONFERMATA: {
				id: 3707,
				label: "b2b.ERRORS.SERVIZIO_NON_INSERIBILE_SU_PRATICA_CONFERMATA"
			},

			/**
			 * Servizio non modificabile
			 */
			SERVIZIO_NON_MODIFICABILE: {
				id: 3708,
				label: "b2b.ERRORS.SERVIZIO_NON_MODIFICABILE"
			},

			/**
			 * Servizio ID={servizioID} non trovato
			 */
			SERVIZIO_NON_TROVATO: {
				id: 3709,
				label: "b2b.ERRORS.SERVIZIO_NON_TROVATO"
			},

			/**
			 * Il servizio ID={servizioID} {nome} non ha itinerari associati
			 */
			SERVIZIO_PRIVO_DI_ITINERARI: {
				id: 3710,
				label: "b2b.ERRORS.SERVIZIO_PRIVO_DI_ITINERARI"
			},

			/**
			 * Servizio {servizio} è utilizzabile solo da {min} a {max} viaggiatori. Abbinamento {abbinamento} ne ha {count}
			 */
			SERVIZIO_UTILIZZABILE_SOLO_DA_MIN_MAX: {
				id: 3711,
				label: "b2b.ERRORS.SERVIZIO_UTILIZZABILE_SOLO_DA_MIN_MAX"
			},

			/**
			 * Tabella costi commissioni ID={tabellaCostoID} non trovata nell'accordo ID={accordoID}
			 */
			TABELLA_COSTI_COMMISSIONI_NON_TROVATA: {
				id: 3712,
				label: "b2b.ERRORS.TABELLA_COSTI_COMMISSIONI_NON_TROVATA"
			},

			/**
			 * Tappa ID={itinerarioID} non eliminabile in quanto associata alla tipologia ID={tipologiaID}
			 */
			TAPPA_NON_ELIMINABILE_ASSOCIATA_A_SERVIZIO: {
				id: 3713,
				label: "b2b.ERRORS.TAPPA_NON_ELIMINABILE_ASSOCIATA_A_SERVIZIO"
			},

			/**
			 * Tappa ID={itinerarioID} non eliminabile in quanto contiene servizi confermati o in prenotazione
			 */
			TAPPA_NON_ELIMINABILE_PER_SERVIZIO_CONFERMATO: {
				id: 3714,
				label: "b2b.ERRORS.TAPPA_NON_ELIMINABILE_PER_SERVIZIO_CONFERMATO"
			},

			/**
			 * Tappa ID={itinerarioID} contiene la riga ID={rigaID} {descrizione} che è confermata o in prenotazione con date dal {dal} al {al}. Nuovo periodo richiesto {nuovoDal} - {nuovoAl} non è compatibile.
			 */
			TAPPA_NON_MODIFICABILE_PER_INCOMPATIBILITA_SERVIZIO_CONFERMATO: {
				id: 3715,
				label: "b2b.ERRORS.TAPPA_NON_MODIFICABILE_PER_INCOMPATIBILITA_SERVIZIO_CONFERMATO"
			},

			/**
			 * La tappa è legata ad un tour e non può essere spostata né modificata nel tipo di trasporto
			 */
			TAPPA_TOUR_NON_MODIFICABILE: {
				id: 3716,
				label: "b2b.ERRORS.TAPPA_TOUR_NON_MODIFICABILE"
			},

			/**
			 * Tariffa non più disponibile
			 */
			TARIFFA_NON_PIU_DISPONIBILE: {
				id: 3717,
				label: "b2b.ERRORS.TARIFFA_NON_PIU_DISPONIBILE"
			},

			/**
			 * Tipologia ID={tipologiaID} non trovata nel servizio ID={servizioID} {nome}
			 */
			TIPOLOGIA_NON_TROVATA: {
				id: 3718,
				label: "b2b.ERRORS.TIPOLOGIA_NON_TROVATA"
			},

			/**
			 * Tipologia ID={tipologiaID} {tipologia} del servizio ID={servizioID} {servizio} non abbinata ad itinerari
			 */
			TIPOLOGIA_SENZA_ITINERARIO: {
				id: 3719,
				label: "b2b.ERRORS.TIPOLOGIA_SENZA_ITINERARIO"
			},

			/**
			 * L'utente {utenteID} non esiste
			 */
			UTENTE_ID_INESISTENTE: {
				id: 3720,
				label: "b2b.ERRORS.UTENTE_ID_INESISTENTE"
			},

			/**
			 * Valuta ID={valutaID} dell'accordo ID={accordoID} non trovata
			 */
			VALUTA_ACCORDO_NON_TROVATA: {
				id: 3721,
				label: "b2b.ERRORS.VALUTA_ACCORDO_NON_TROVATA"
			},

			/**
			 * Volo ID={voloID} non trovato nell'offerta ID={offertaID}
			 */
			VOLO_NON_TROVATO: {
				id: 3722,
				label: "b2b.ERRORS.VOLO_NON_TROVATO"
			},

			/**
			 * Voucher ID={voucherID} non esiste nell'offerta ID={offertaID}
			 */
			VOUCHER_NON_TROVATO: {
				id: 3723,
				label: "b2b.ERRORS.VOUCHER_NON_TROVATO"
			},

			/**
			 * Operazione annullata: impossibile modificare i valori per We Care You Smile, i dati sono già stati inviati in data {data}
			 */
			WCYS_GIA_INVIATA: {
				id: 3724,
				label: "b2b.ERRORS.WCYS_GIA_INVIATA"
			},

			/**
			 * Content of path b2b.ERRORS.undefined
			 */
			undefined: {
			},

			/**
			 * ERRORS.SERVIZIO_NON_TROVATO_NEI_RISULTATI_DI_RICERCA
			 */
			SERVIZIO_NON_TROVATO_NEI_RISULTATI_DI_RICERCA: {
				id: 6361,
				label: "b2b.ERRORS.SERVIZIO_NON_TROVATO_NEI_RISULTATI_DI_RICERCA"
			},

			/**
			 * ERRORS.AGENZIA_PRIVA_DI_ACCORDO_COMMERCIALE_CON_OPERATORE
			 */
			AGENZIA_PRIVA_DI_ACCORDO_COMMERCIALE_CON_OPERATORE: {
				id: 6368,
				label: "b2b.ERRORS.AGENZIA_PRIVA_DI_ACCORDO_COMMERCIALE_CON_OPERATORE"
			},

			/**
			 * ERRORS.CODICE_RECUPERO_PASSWORD_NON_VALIDO
			 */
			CODICE_RECUPERO_PASSWORD_NON_VALIDO: {
				id: 6417,
				label: "b2b.ERRORS.CODICE_RECUPERO_PASSWORD_NON_VALIDO"
			},

			/**
			 * ERRORS.PASSWORD_ERRATA
			 */
			PASSWORD_ERRATA: {
				id: 6589,
				label: "b2b.ERRORS.PASSWORD_ERRATA"
			},

		},

		/**
		 * Content of path b2b.FLIGHT
		 */
		FLIGHT: {
			/**
			 * Seleziona
			 */
			ADD_SELECTED: {
				id: 3725,
				label: "b2b.FLIGHT.ADD_SELECTED"
			},

			/**
			 * Aggiungi
			 */
			ADD_TO_TRIP: {
				id: 3726,
				label: "b2b.FLIGHT.ADD_TO_TRIP"
			},

			/**
			 * Adulti
			 */
			ADULTS: {
				id: 3727,
				label: "b2b.FLIGHT.ADULTS"
			},

			/**
			 * Da 
			 */
			APT_FROM: {
				id: 3728,
				label: "b2b.FLIGHT.APT-FROM"
			},

			/**
			 * A 
			 */
			APT_TO: {
				id: 3729,
				label: "b2b.FLIGHT.APT-TO"
			},

			/**
			 * Torna ai risultati della ricerca
			 */
			BACK_TO_SELECTED_RESULTS: {
				id: 3730,
				label: "b2b.FLIGHT.BACK-TO-SELECTED_RESULTS"
			},

			/**
			 * Content of path b2b.FLIGHT.BADGE-LABELS
			 */
			BADGE_LABELS: {
				/**
				 * {num} Adult{num, plural, =0{i} one{o} other{i}}
				 */
				Adulto: {
					id: 3731,
					label: "b2b.FLIGHT.BADGE-LABELS.Adulto"
				},

				/**
				 * {num} Child{num, plural, =0{ren} one{} other{ren}}
				 */
				Child: {
					id: 3732,
					label: "b2b.FLIGHT.BADGE-LABELS.Child"
				},

				/**
				 * {num} Infant{num, plural, =0{s} one{} other{s}}
				 */
				Infant: {
					id: 3733,
					label: "b2b.FLIGHT.BADGE-LABELS.Infant"
				},

				/**
				 * FLIGHT.BADGE-LABELS.Adult
				 */
				Adult: {
					id: 6332,
					label: "b2b.FLIGHT.BADGE-LABELS.Adult"
				},

				/**
				 * FLIGHT.BADGE-LABELS.Volwassene
				 */
				Volwassene: {
					id: 6337,
					label: "b2b.FLIGHT.BADGE-LABELS.Volwassene"
				},

				/**
				 * FLIGHT.BADGE-LABELS.Adulte
				 */
				Adulte: {
					id: 6343,
					label: "b2b.FLIGHT.BADGE-LABELS.Adulte"
				},

				/**
				 * FLIGHT.BADGE-LABELS.Erwachsene
				 */
				Erwachsene: {
					id: 6346,
					label: "b2b.FLIGHT.BADGE-LABELS.Erwachsene"
				},

				/**
				 * FLIGHT.BADGE-LABELS.Enfant
				 */
				Enfant: {
					id: 6363,
					label: "b2b.FLIGHT.BADGE-LABELS.Enfant"
				},

				/**
				 * FLIGHT.BADGE-LABELS.Bébé
				 */
				B_b_: {
					id: 6495,
					label: "b2b.FLIGHT.BADGE-LABELS.B\u00E9b\u00E9"
				},

				/**
				 * FLIGHT.BADGE-LABELS.Kind
				 */
				Kind: {
					id: 6512,
					label: "b2b.FLIGHT.BADGE-LABELS.Kind"
				},

			},

			/**
			 * {num, plural, =0{Nessun bagaglio incluso} one{Un bagaglio incluso per viaggiatore} other{{num} bagagli inclusi per viaggiatore}}
			 */
			BAGGAGE: {
				id: 3734,
				label: "b2b.FLIGHT.BAGGAGE"
			},

			/**
			 * Consigliata
			 */
			BEST_ABSOLUTE_RESULT: {
				id: 3735,
				label: "b2b.FLIGHT.BEST_ABSOLUTE_RESULT"
			},

			/**
			 * Più economica
			 */
			BEST_PRICE_RESULT: {
				id: 3736,
				label: "b2b.FLIGHT.BEST_PRICE_RESULT"
			},

			/**
			 * Più veloce
			 */
			BEST_TIME_RESULT: {
				id: 3737,
				label: "b2b.FLIGHT.BEST_TIME_RESULT"
			},

			/**
			 * Classe
			 */
			CABIN: {
				id: 3738,
				label: "b2b.FLIGHT.CABIN"
			},

			/**
			 * Compagnia aereaCarrier Inclusi
			 */
			CARRIERS_INCLUDED: {
				id: 3739,
				label: "b2b.FLIGHT.CARRIERS-INCLUDED"
			},

			/**
			 * Bambini
			 */
			CHILDS: {
				id: 3740,
				label: "b2b.FLIGHT.CHILDS"
			},

			/**
			 * Crea Viaggio da questi voli
			 */
			CREATE_TRIP: {
				id: 3741,
				label: "b2b.FLIGHT.CREATE_TRIP"
			},

			/**
			 * Elimina soluzione
			 */
			DELETE_SELECTED: {
				id: 3742,
				label: "b2b.FLIGHT.DELETE_SELECTED"
			},

			/**
			 * Durata
			 */
			DURATA: {
				id: 3743,
				label: "b2b.FLIGHT.DURATA"
			},

			/**
			 * Infant
			 */
			INFANTS: {
				id: 3744,
				label: "b2b.FLIGHT.INFANTS"
			},

			/**
			 * Bagaglio incluso
			 */
			LBL_BAGGAGE: {
				id: 3745,
				label: "b2b.FLIGHT.LBL-BAGGAGE"
			},

			/**
			 * Ricerca solovoli diretti
			 */
			LBL_DIRECT: {
				id: 3746,
				label: "b2b.FLIGHT.LBL-DIRECT"
			},

			/**
			 * Nessun Itinerario volo per il viaggio corrente
			 */
			MISSING_ITINERARY: {
				id: 3747,
				label: "b2b.FLIGHT.MISSING-ITINERARY"
			},

			/**
			 * Nessun risultato per l'itinerario cercato
			 */
			NO_RESULTS: {
				id: 3748,
				label: "b2b.FLIGHT.NO-RESULTS"
			},

			/**
			 * {numVoli} vol{numVoli, plural, =0{i} one{o} other{i}}
			 */
			NUMERO_VOLI: {
				id: 3749,
				label: "b2b.FLIGHT.NUMERO-VOLI"
			},

			/**
			 * Operated by
			 */
			OPERATED_BY: {
				id: 3750,
				label: "b2b.FLIGHT.OPERATED-BY"
			},

			/**
			 * PNR IN OFFERTA
			 */
			PNR_IN_OFFERTA: {
				id: 3751,
				label: "b2b.FLIGHT.PNR-IN-OFFERTA"
			},

			/**
			 * da
			 */
			PRICE_FORM: {
				id: 3752,
				label: "b2b.FLIGHT.PRICE_FORM"
			},

			/**
			 * Seleziona per tratta
			 */
			RESULTS: {
				id: 3753,
				label: "b2b.FLIGHT.RESULTS"
			},

			/**
			 * {scali} scal{scali, plural, =0{i} one{o} other{i}}
			 */
			SCALI: {
				id: 3754,
				label: "b2b.FLIGHT.SCALI"
			},

			/**
			 * Cerca
			 */
			SEARCH: {
				id: 3755,
				label: "b2b.FLIGHT.SEARCH"
			},

			/**
			 * Ricerca Voli
			 */
			SEARCH_TITLE: {
				id: 3756,
				label: "b2b.FLIGHT.SEARCH-TITLE"
			},

			/**
			 * Ricerca in corso
			 */
			SEARCHING: {
				id: 3757,
				label: "b2b.FLIGHT.SEARCHING"
			},

			/**
			 * Voli Selezionati
			 */
			SELECTED_RESULTS: {
				id: 3758,
				label: "b2b.FLIGHT.SELECTED_RESULTS"
			},

			/**
			 * Le migliori soluzioni-tariffe a persona tasse incluse
			 */
			SOLUZIONI_CONSIGLIATE: {
				id: 3759,
				label: "b2b.FLIGHT.SOLUZIONI-CONSIGLIATE"
			},

			/**
			 * Data
			 */
			STEP_DATE: {
				id: 3760,
				label: "b2b.FLIGHT.STEP-DATE"
			},

			/**
			 * Content of path b2b.FLIGHT.DELETE_STEP
			 */
			DELETE_STEP: {
			},

			/**
			 * Content of path b2b.FLIGHT.ADD_STEP
			 */
			ADD_STEP: {
			},

		},

		/**
		 * Content of path b2b.FLIGHT_CRYPTIC
		 */
		FLIGHT_CRYPTIC: {
			/**
			 * Chiudi PNR
			 */
			CLOSE_PNR_BTN: {
				id: 3761,
				label: "b2b.FLIGHT_CRYPTIC.CLOSE_PNR_BTN"
			},

			/**
			 * Ufficio
			 */
			COMPANY_TITLE: {
				id: 3762,
				label: "b2b.FLIGHT_CRYPTIC.COMPANY_TITLE"
			},

			/**
			 * Funzioni
			 */
			FUNCTIONS_TITLE: {
				id: 3763,
				label: "b2b.FLIGHT_CRYPTIC.FUNCTIONS_TITLE"
			},

			/**
			 * Importa nomi
			 */
			IMPORT_NAMES_BTN: {
				id: 3764,
				label: "b2b.FLIGHT_CRYPTIC.IMPORT_NAMES_BTN"
			},

			/**
			 * Ins. OSI/SSR
			 */
			IMPORT_OSI_BTN: {
				id: 3765,
				label: "b2b.FLIGHT_CRYPTIC.IMPORT_OSI_BTN"
			},

			/**
			 * Errore durante l'importazione del PNR da Sessione
			 */
			IMPORT_PNR_ERROR: {
				id: 3766,
				label: "b2b.FLIGHT_CRYPTIC.IMPORT_PNR_ERROR"
			},

			/**
			 * PNR e Tariffe
			 */
			IMPORT_PNR_FARES: {
				id: 3767,
				label: "b2b.FLIGHT_CRYPTIC.IMPORT_PNR_FARES"
			},

			/**
			 * Sovrascrivi PNR e Tariffe
			 */
			IMPORT_PNR_REPLACE: {
				id: 3768,
				label: "b2b.FLIGHT_CRYPTIC.IMPORT_PNR_REPLACE"
			},

			/**
			 * PNR Importato correttamente dalla Sessione
			 */
			IMPORT_PNR_SUCCESS: {
				id: 3769,
				label: "b2b.FLIGHT_CRYPTIC.IMPORT_PNR_SUCCESS"
			},

			/**
			 * Importa PNR
			 */
			IMPORT_PNR_TITLE: {
				id: 3770,
				label: "b2b.FLIGHT_CRYPTIC.IMPORT_PNR_TITLE"
			},

			/**
			 * PNR e Tariffe a 0
			 */
			IMPORT_PNR_ZERO_FARES: {
				id: 3771,
				label: "b2b.FLIGHT_CRYPTIC.IMPORT_PNR_ZERO_FARES"
			},

			/**
			 * Forza Dati:
			 */
			OVERRIDE_PENALTY: {
				id: 3772,
				label: "b2b.FLIGHT_CRYPTIC.OVERRIDE_PENALTY"
			},

			/**
			 * Penale Prezzo Pax
			 */
			OVERRIDE_PENALTY_AMOUNT: {
				id: 3773,
				label: "b2b.FLIGHT_CRYPTIC.OVERRIDE_PENALTY_AMOUNT"
			},

			/**
			 * Data Emissione
			 */
			OVERRIDE_PENALTY_DATE: {
				id: 3774,
				label: "b2b.FLIGHT_CRYPTIC.OVERRIDE_PENALTY_DATE"
			},

			/**
			 * Coda
			 */
			QUEUE_TITLE: {
				id: 3775,
				label: "b2b.FLIGHT_CRYPTIC.QUEUE_TITLE"
			},

			/**
			 * Invia
			 */
			SEND_BTN: {
				id: 3776,
				label: "b2b.FLIGHT_CRYPTIC.SEND_BTN"
			},

			/**
			 * SSR data di nascita
			 */
			SSR_BIRTHDATE_BTN: {
				id: 3777,
				label: "b2b.FLIGHT_CRYPTIC.SSR_BIRTHDATE_BTN"
			},

			/**
			 * SSR Passaporto
			 */
			SSR_PASSPORT_BTN: {
				id: 3778,
				label: "b2b.FLIGHT_CRYPTIC.SSR_PASSPORT_BTN"
			},

			/**
			 * SSR Telefono
			 */
			SSR_PHONE_BTN: {
				id: 3779,
				label: "b2b.FLIGHT_CRYPTIC.SSR_PHONE_BTN"
			},

			/**
			 * Conferma
			 */
			STATUS_CONFIRM: {
				id: 3780,
				label: "b2b.FLIGHT_CRYPTIC.STATUS_CONFIRM"
			},

			/**
			 * Preventivo
			 */
			STATUS_QUOTE: {
				id: 3781,
				label: "b2b.FLIGHT_CRYPTIC.STATUS_QUOTE"
			},

			/**
			 * Status
			 */
			STATUS_TITLE: {
				id: 3782,
				label: "b2b.FLIGHT_CRYPTIC.STATUS_TITLE"
			},

			/**
			 * Time Limit
			 */
			TIMELIMIT_TITLE: {
				id: 3783,
				label: "b2b.FLIGHT_CRYPTIC.TIMELIMIT_TITLE"
			},

			/**
			 * RRN Duplica PNR
			 */
			RRN_DUPLICAET_PNR_BTN: {
				id: 5992,
				label: "b2b.FLIGHT_CRYPTIC.RRN_DUPLICAET_PNR_BTN"
			},

			/**
			 * FLIGHT_CRYPTIC.SSR_EMAIL_BTN
			 */
			SSR_EMAIL_BTN: {
				id: 6330,
				label: "b2b.FLIGHT_CRYPTIC.SSR_EMAIL_BTN"
			},

			/**
			 * Emissione biglietto
			 */
			TICKET_EMISSION: {
				id: 6427,
				label: "b2b.FLIGHT_CRYPTIC.TICKET_EMISSION"
			},

			/**
			 * Accordo
			 */
			AGREEMENT: {
				id: 6428,
				label: "b2b.FLIGHT_CRYPTIC.AGREEMENT"
			},

			/**
			 * Metodo di pagamento
			 */
			FORM_PAYMENT: {
				id: 6429,
				label: "b2b.FLIGHT_CRYPTIC.FORM_PAYMENT"
			},

			/**
			 * Dettagli della carta
			 */
			CARD_DETAILS: {
				id: 6430,
				label: "b2b.FLIGHT_CRYPTIC.CARD_DETAILS"
			},

			/**
			 * Invia biglietto
			 */
			MAIL_TICKET: {
				id: 6431,
				label: "b2b.FLIGHT_CRYPTIC.MAIL_TICKET"
			},

			/**
			 * Invia biglietto via email
			 */
			SEND_TICKET_TO: {
				id: 6432,
				label: "b2b.FLIGHT_CRYPTIC.SEND_TICKET_TO"
			},

			/**
			 * Apri emissione biglietti
			 */
			TICKET_EMISSION_BTN: {
				id: 6433,
				label: "b2b.FLIGHT_CRYPTIC.TICKET_EMISSION_BTN"
			},

			/**
			 * Invia biglietto via email
			 */
			EMAIL_TICKET_BTN: {
				id: 6434,
				label: "b2b.FLIGHT_CRYPTIC.EMAIL_TICKET_BTN"
			},

			/**
			 * Apri PNR
			 */
			RETRIEVE_PNR_BTN: {
				id: 6435,
				label: "b2b.FLIGHT_CRYPTIC.RETRIEVE_PNR_BTN"
			},

			/**
			 * Itinerario inviato via mail a {email}
			 */
			ITINERARY_EMAIL_SENT_TO: {
				id: 6436,
				label: "b2b.FLIGHT_CRYPTIC.ITINERARY_EMAIL_SENT_TO"
			},

			/**
			 * Ticket emesso
			 */
			TICKET_EMITTED: {
				id: 6437,
				label: "b2b.FLIGHT_CRYPTIC.TICKET_EMITTED"
			},

			/**
			 * FLIGHT_CRYPTIC.SEND_TO
			 */
			SEND_TO: {
				id: 6539,
				label: "b2b.FLIGHT_CRYPTIC.SEND_TO"
			},

			/**
			 * Email
			 */
			EMAIL: {
				id: 6540,
				label: "b2b.FLIGHT_CRYPTIC.EMAIL"
			},

			/**
			 * Data di nascita
			 */
			DATE_BIRTH: {
				id: 6541,
				label: "b2b.FLIGHT_CRYPTIC.DATE_BIRTH"
			},

			/**
			 * Telefono
			 */
			PHONE: {
				id: 6542,
				label: "b2b.FLIGHT_CRYPTIC.PHONE"
			},

			/**
			 * Passeggeri
			 */
			PASSENGERS: {
				id: 6543,
				label: "b2b.FLIGHT_CRYPTIC.PASSENGERS"
			},

			/**
			 * Campi mancanti
			 */
			INVALID_FORM: {
				id: 6559,
				label: "b2b.FLIGHT_CRYPTIC.INVALID_FORM"
			},

			/**
			 * Passaporto da inserire
			 */
			PASSPORT_NOT_CORRECTLY_INSERTED: {
				id: 6562,
				label: "b2b.FLIGHT_CRYPTIC.PASSPORT_NOT_CORRECTLY_INSERTED"
			},

			/**
			 * Passaporto pronto
			 */
			PASSPORT_CORRECTLY_INSERTED: {
				id: 6563,
				label: "b2b.FLIGHT_CRYPTIC.PASSPORT_CORRECTLY_INSERTED"
			},

		},

		/**
		 * Content of path b2b.LANGUAGES
		 */
		LANGUAGES: {
			/**
			 * Tedesco
			 */
			DE: {
				id: 3784,
				label: "b2b.LANGUAGES.DE"
			},

			/**
			 * Inglese
			 */
			EN: {
				id: 3785,
				label: "b2b.LANGUAGES.EN"
			},

			/**
			 * Spagnolo
			 */
			ES: {
				id: 3786,
				label: "b2b.LANGUAGES.ES"
			},

			/**
			 * Francese
			 */
			FR: {
				id: 3787,
				label: "b2b.LANGUAGES.FR"
			},

			/**
			 * Italiano
			 */
			IT: {
				id: 3788,
				label: "b2b.LANGUAGES.IT"
			},

			/**
			 * Olandese
			 */
			NL: {
				id: 3789,
				label: "b2b.LANGUAGES.NL"
			},

			/**
			 * Debug language
			 */
			ZU: {
				id: 3790,
				label: "b2b.LANGUAGES.ZU"
			},

		},

		/**
		 * Content of path b2b.NAVBAR
		 */
		NAVBAR: {
			/**
			 * Aggiungi una camera
			 */
			AGGIUNGI_UNA_CAMERA: {
				id: 3791,
				label: "b2b.NAVBAR.AGGIUNGI-UNA-CAMERA"
			},

			/**
			 * Apri la scheda descrittiva del servizio
			 */
			APRI_SCHEDA_SERVIZIO: {
				id: 3792,
				label: "b2b.NAVBAR.APRI-SCHEDA-SERVIZIO"
			},

			/**
			 * Apri questo viaggio
			 */
			APRI_VIAGGIO: {
				id: 3793,
				label: "b2b.NAVBAR.APRI-VIAGGIO"
			},

			/**
			 * Area agenzie
			 */
			AREA_AGENZIE: {
				id: 3794,
				label: "b2b.NAVBAR.AREA-AGENZIE"
			},

			/**
			 * Cambia lingua
			 */
			CAMBIA_LINGUA: {
				id: 3795,
				label: "b2b.NAVBAR.CAMBIA-LINGUA"
			},

			/**
			 * {n} camer{n, plural, =0{e} one{a} other{e}}
			 */
			CAMERE: {
				id: 3796,
				label: "b2b.NAVBAR.CAMERE"
			},

			/**
			 * Cerca {tipoServizio} a {luogo} dal {dataDal}, {notti} per {camere}
			 */
			CERCA_HOTEL_IN_LUOGO: {
				id: 3797,
				label: "b2b.NAVBAR.CERCA-HOTEL-IN-LUOGO"
			},

			/**
			 * Cerca {tipoServizio} a {luogo}
			 */
			CERCA_HOTEL_IN_LUOGO_SHORT: {
				id: 3798,
				label: "b2b.NAVBAR.CERCA-HOTEL-IN-LUOGO-SHORT"
			},

			/**
			 * Cerca tutto
			 */
			CERCA_LUOGHI_SERVIZI_VIAGGI: {
				id: 3799,
				label: "b2b.NAVBAR.CERCA-LUOGHI-SERVIZI-VIAGGI"
			},

			/**
			 * Cerca disponibilità e prezzi per {nomeHotel} a {luogo} dal {dataDal}, {notti} per {n}
			 */
			CERCA_PREZZI_HOTEL: {
				id: 3800,
				label: "b2b.NAVBAR.CERCA-PREZZI-HOTEL"
			},

			/**
			 * Cerca disponibilità e prezzi per {nomeHotel} a {luogo}
			 */
			CERCA_PREZZI_HOTEL_SHORT: {
				id: 3801,
				label: "b2b.NAVBAR.CERCA-PREZZI-HOTEL-SHORT"
			},

			/**
			 * Cerca disponibilità e prezzi per {nomeServizio} a {luogo} dal {dataDal} al {dataAl} per {n}
			 */
			CERCA_PREZZI_SERVIZIO: {
				id: 3802,
				label: "b2b.NAVBAR.CERCA-PREZZI-SERVIZIO"
			},

			/**
			 * Cerca disponibilità e prezzi per {nomeServizio} a {luogo}
			 */
			CERCA_PREZZI_SERVIZIO_SHORT: {
				id: 3803,
				label: "b2b.NAVBAR.CERCA-PREZZI-SERVIZIO-SHORT"
			},

			/**
			 * Sono stati trovati {luoghi} e {servizi}.
			 */
			CERCA_PREZZI_STATS: {
				id: 3804,
				label: "b2b.NAVBAR.CERCA-PREZZI-STATS"
			},

			/**
			 * {luoghi} {luoghi, plural, =0{nessun luogo} one{luogo} other{luoghi}}
			 */
			CERCA_PREZZI_STATS_LUOGHI: {
				id: 3805,
				label: "b2b.NAVBAR.CERCA-PREZZI-STATS-LUOGHI"
			},

			/**
			 * {servizi} {servizi, plural, =0{nessun servizio} one{servizio} other{servizi}}
			 */
			CERCA_PREZZI_STATS_SERVIZI: {
				id: 3806,
				label: "b2b.NAVBAR.CERCA-PREZZI-STATS-SERVIZI"
			},

			/**
			 * Cerca {tipoServizio} a {luogo} dal {dataDal} al {dataAl} per {camere}
			 */
			CERCA_TIPO_SERVIZIO_IN_LUOGO: {
				id: 3807,
				label: "b2b.NAVBAR.CERCA-TIPO-SERVIZIO-IN-LUOGO"
			},

			/**
			 * Cerca {tipoServizio} a {luogo}
			 */
			CERCA_TIPO_SERVIZIO_IN_LUOGO_SHORT: {
				id: 3808,
				label: "b2b.NAVBAR.CERCA-TIPO-SERVIZIO-IN-LUOGO-SHORT"
			},

			/**
			 * Cancella
			 */
			CLEAR_SEARCH: {
				id: 3809,
				label: "b2b.NAVBAR.CLEAR-SEARCH"
			},

			/**
			 * Crea un viaggio
			 */
			CREA_UN_VIAGGIO: {
				id: 3810,
				label: "b2b.NAVBAR.CREA-UN-VIAGGIO"
			},

			/**
			 * Crea un nuovo viaggio con l'itinerario {nomeItinerario}, partenza il {dataPartenza}, {n}
			 */
			CREA_VIAGGIO_ITINERARIO: {
				id: 3811,
				label: "b2b.NAVBAR.CREA-VIAGGIO-ITINERARIO"
			},

			/**
			 * Crea un nuovo viaggio con l'itinerario {nomeItinerario}, partenza il {dataPartenza}, {n}
			 */
			CREA_VIAGGIO_ITINERARIO_SHORT: {
				id: 3812,
				label: "b2b.NAVBAR.CREA-VIAGGIO-ITINERARIO-SHORT"
			},

			/**
			 * Crea un nuovo viaggio con tappa {nomeLuogo}, arrivo il {dataPartenza}, {notti}
			 */
			CREA_VIAGGIO_LUOGO: {
				id: 3813,
				label: "b2b.NAVBAR.CREA-VIAGGIO-LUOGO"
			},

			/**
			 * Crea un nuovo viaggio con {nomeTour} per la partenza del {dataPartenza}
			 */
			CREA_VIAGGIO_TOUR: {
				id: 3814,
				label: "b2b.NAVBAR.CREA-VIAGGIO-TOUR"
			},

			/**
			 * La partenza è successiva al periodo richiesto. Crea un nuovo viaggio con {nomeTour} per la partenza del {dataPartenza}
			 */
			CREA_VIAGGIO_TOUR_POST: {
				id: 3815,
				label: "b2b.NAVBAR.CREA-VIAGGIO-TOUR-POST"
			},

			/**
			 * La partenza è precedente al periodo richiesto. Crea un nuovo viaggio con {nomeTour} per la partenza del {dataPartenza}
			 */
			CREA_VIAGGIO_TOUR_PRE: {
				id: 3816,
				label: "b2b.NAVBAR.CREA-VIAGGIO-TOUR-PRE"
			},

			/**
			 * Elimina camera
			 */
			ELIMINA_CAMERA: {
				id: 3817,
				label: "b2b.NAVBAR.ELIMINA-CAMERA"
			},

			/**
			 * Home
			 */
			HOME: {
				id: 3818,
				label: "b2b.NAVBAR.HOME"
			},

			/**
			 * I tuoi viaggi
			 */
			I_TUOI_VIAGGI: {
				id: 3819,
				label: "b2b.NAVBAR.I-TUOI-VIAGGI"
			},

			/**
			 * Il mio utente
			 */
			IL_MIO_UTENTE: {
				id: 3820,
				label: "b2b.NAVBAR.IL-MIO-UTENTE"
			},

			/**
			 * Intranet
			 */
			INTRANET: {
				id: 3821,
				label: "b2b.NAVBAR.INTRANET"
			},

			/**
			 * La mia agenzia
			 */
			LA_MIA_AGENZIA: {
				id: 3822,
				label: "b2b.NAVBAR.LA-MIA-AGENZIA"
			},

			/**
			 * Lingua di default salvata
			 */
			LINGUA_DEFAULT_SALVATA: {
				id: 3823,
				label: "b2b.NAVBAR.LINGUA_DEFAULT_SALVATA"
			},

			/**
			 * Luoghi
			 */
			LUOGHI: {
				id: 3824,
				label: "b2b.NAVBAR.LUOGHI"
			},

			/**
			 * Luoghi e servizi
			 */
			LUOGHI_E_SERVIZI: {
				id: 3825,
				label: "b2b.NAVBAR.LUOGHI-E-SERVIZI"
			},

			/**
			 * Menu utente
			 */
			MENU_UTENTE: {
				id: 3826,
				label: "b2b.NAVBAR.MENU-UTENTE"
			},

			/**
			 * Nessun luogo o servizio trovato per
			 */
			NESSUN_LUOGO_O_SERVIZIO_TROVATO: {
				id: 3827,
				label: "b2b.NAVBAR.NESSUN-LUOGO-O-SERVIZIO-TROVATO"
			},

			/**
			 * Nessun luogo trovato per
			 */
			NESSUN_LUOGO_TROVATO: {
				id: 3828,
				label: "b2b.NAVBAR.NESSUN-LUOGO-TROVATO"
			},

			/**
			 * Selezionare una ricerca
			 */
			NESSUN_PARAMETRO_DI_RICERCA: {
				id: 3829,
				label: "b2b.NAVBAR.NESSUN-PARAMETRO-DI-RICERCA"
			},

			/**
			 * Nessun servizio trovato per
			 */
			NESSUN_SERVIZIO_TROVATO: {
				id: 3830,
				label: "b2b.NAVBAR.NESSUN-SERVIZIO-TROVATO"
			},

			/**
			 * Non sono previste partenze nelle date scelte
			 */
			NO_PARTENZE: {
				id: 3831,
				label: "b2b.NAVBAR.NO-PARTENZE"
			},

			/**
			 * Partenze giornaliere
			 */
			PARTENZE_GIORNALIERE: {
				id: 3832,
				label: "b2b.NAVBAR.PARTENZE-GIORNALIERE"
			},

			/**
			 * Al
			 */
			RICERCA_A_DATA: {
				id: 3833,
				label: "b2b.NAVBAR.RICERCA-A-DATA"
			},

			/**
			 * Ricerca dal
			 */
			RICERCA_DA_DATA: {
				id: 3834,
				label: "b2b.NAVBAR.RICERCA-DA-DATA"
			},

			/**
			 * Inserisci la destinazione, il periodo d'interesse, il numero dei viaggiatori/camere ed avvia la ricerca.Puoi attivare la ricerca anche digitando il nome del singolo servizio.Inserisci il numero del preventivo/prenotazione o il cognome di un viag
			 */
			RICERCA_HELP: {
				id: 3835,
				label: "b2b.NAVBAR.RICERCA-HELP"
			},

			/**
			 * ricerca viaggi
			 */
			RICERCA_VIAGGI: {
				id: 3836,
				label: "b2b.NAVBAR.RICERCA-VIAGGI"
			},

			/**
			 * Vuoi salvare nel tuo profilo la lingua di default {lingua}?
			 */
			SALVARE_LINGUA_DEFAULT: {
				id: 3837,
				label: "b2b.NAVBAR.SALVARE_LINGUA_DEFAULT"
			},

			/**
			 * Servizi
			 */
			SERVIZI: {
				id: 3838,
				label: "b2b.NAVBAR.SERVIZI"
			},

			/**
			 * Viaggi
			 */
			VIAGGI: {
				id: 3839,
				label: "b2b.NAVBAR.VIAGGI"
			},

			/**
			 * Vedi come agenzia
			 */
			VISUALIZZAZIONE_AGENZIA: {
				id: 3840,
				label: "b2b.NAVBAR.VISUALIZZAZIONE-AGENZIA"
			},

		},

		/**
		 * Content of path b2b.NEWS
		 */
		NEWS: {
			/**
			 * aggiornato al 
			 */
			AGGIORNATOA: {
				id: 3841,
				label: "b2b.NEWS.AGGIORNATOA"
			},

			/**
			 * Approfondimenti
			 */
			APPROFONDIMENTI: {
				id: 3842,
				label: "b2b.NEWS.APPROFONDIMENTI"
			},

			/**
			 * In Primo Piano
			 */
			INPRIMOPIANO: {
				id: 3843,
				label: "b2b.NEWS.INPRIMOPIANO"
			},

			/**
			 * Ultime Notizie
			 */
			ULTIMENOTIZIE: {
				id: 3844,
				label: "b2b.NEWS.ULTIMENOTIZIE"
			},

		},

		/**
		 * Content of path b2b.NR-DROPDOWN
		 */
		NR_DROPDOWN: {
			/**
			 * Aggiungi elemento
			 */
			ADD_TAG: {
				id: 3845,
				label: "b2b.NR-DROPDOWN.ADD-TAG"
			},

			/**
			 * Cancella
			 */
			CLEAR_ALL: {
				id: 3846,
				label: "b2b.NR-DROPDOWN.CLEAR-ALL"
			},

			/**
			 * Ricerca...
			 */
			LOADING: {
				id: 3847,
				label: "b2b.NR-DROPDOWN.LOADING"
			},

			/**
			 * Nessun elemento trovato
			 */
			NOT_FOUND: {
				id: 3848,
				label: "b2b.NR-DROPDOWN.NOT-FOUND"
			},

			/**
			 * Scrivi per cercare
			 */
			TYPE_TO_SEARCH: {
				id: 3849,
				label: "b2b.NR-DROPDOWN.TYPE-TO-SEARCH"
			},

		},

		/**
		 * Content of path b2b.ORDERS
		 */
		ORDERS: {
			/**
			 * Al
			 */
			A_DATA: {
				id: 3850,
				label: "b2b.ORDERS.A-DATA"
			},

			/**
			 * Adv
			 */
			ADV: {
				id: 3851,
				label: "b2b.ORDERS.ADV"
			},

			/**
			 * Agenzia
			 */
			AGENZIA: {
				id: 3852,
				label: "b2b.ORDERS.AGENZIA"
			},

			/**
			 * Ricerca
			 */
			AGGIORNA: {
				id: 3853,
				label: "b2b.ORDERS.AGGIORNA"
			},

			/**
			 * Aggiornare
			 */
			AGGIORNARE: {
				id: 3854,
				label: "b2b.ORDERS.AGGIORNARE"
			},

			/**
			 * Almeno uno dei selezionati
			 */
			ALMENO_UN_TAG: {
				id: 3855,
				label: "b2b.ORDERS.ALMENO-UN-TAG"
			},

			/**
			 * Alternativa
			 */
			ALTERNATIVA: {
				id: 3856,
				label: "b2b.ORDERS.ALTERNATIVA"
			},

			/**
			 * Appuntamento agenzia al
			 */
			APPUNTAMENTO_AGENZIA_AL: {
				id: 3857,
				label: "b2b.ORDERS.APPUNTAMENTO-AGENZIA-AL"
			},

			/**
			 * Appuntamento agenzia dal
			 */
			APPUNTAMENTO_AGENZIA_DAL: {
				id: 3858,
				label: "b2b.ORDERS.APPUNTAMENTO-AGENZIA-DAL"
			},

			/**
			 * Apri viaggio in una nuova finestra
			 */
			APRI_IN_NUOVA_FINESTRA: {
				id: 3859,
				label: "b2b.ORDERS.APRI-IN-NUOVA-FINESTRA"
			},

			/**
			 * Cat. ADV
			 */
			CATEGORIA_ADV: {
				id: 3860,
				label: "b2b.ORDERS.CATEGORIA-ADV"
			},

			/**
			 * Clienti
			 */
			CLIENTI: {
				id: 3861,
				label: "b2b.ORDERS.CLIENTI"
			},

			/**
			 * Content of path b2b.ORDERS.COMPENSATO
			 */
			COMPENSATO: {
				/**
				 * C
				 */
				INIZIALE: {
					id: 6128,
					label: "b2b.ORDERS.COMPENSATO.INIZIALE"
				},

			},

			/**
			 * Cnf
			 */
			CONFERMA_ABBREVIATA: {
				id: 3863,
				label: "b2b.ORDERS.CONFERMA-ABBREVIATA"
			},

			/**
			 * Conferma al
			 */
			CONFERMA_AL: {
				id: 3864,
				label: "b2b.ORDERS.CONFERMA-AL"
			},

			/**
			 * Conferma / Completamento
			 */
			CONFERMA_COMPLETAMENTO: {
				id: 3865,
				label: "b2b.ORDERS.CONFERMA-COMPLETAMENTO"
			},

			/**
			 * Conferma dal
			 */
			CONFERMA_DAL: {
				id: 3866,
				label: "b2b.ORDERS.CONFERMA-DAL"
			},

			/**
			 * Eliminare {n} alternativ{n, plural, =0{e} one{a} other{e}}?
			 */
			CONFERMA_ELIMINA: {
				id: 3867,
				label: "b2b.ORDERS.CONFERMA-ELIMINA"
			},

			/**
			 * Confermi di voler ottimizzare {n} rig{n, plural, =0{he} one{a} other{he}}?
			 */
			CONFERMA_OTTIMIZZA: {
				id: 3868,
				label: "b2b.ORDERS.CONFERMA-OTTIMIZZA"
			},

			/**
			 * CnfTO
			 */
			CONFERMA_TO_ABBREVIATA: {
				id: 3869,
				label: "b2b.ORDERS.CONFERMA-TO-ABBREVIATA"
			},

			/**
			 * Costo
			 */
			COSTO: {
				id: 3870,
				label: "b2b.ORDERS.COSTO"
			},

			/**
			 * Costo alt
			 */
			COSTO_ALTERNATIVA: {
				id: 3871,
				label: "b2b.ORDERS.COSTO-ALTERNATIVA"
			},

			/**
			 * Cerca alternative
			 */
			CREA_OTTIMIZZAZIONI: {
				id: 3872,
				label: "b2b.ORDERS.CREA-OTTIMIZZAZIONI"
			},

			/**
			 * Crea un viaggio
			 */
			CREA_UN_VIAGGIO: {
				id: 3873,
				label: "b2b.ORDERS.CREA-UN-VIAGGIO"
			},

			/**
			 * Crea app
			 */
			CREAAPP: {
				id: 3874,
				label: "b2b.ORDERS.CREAAPP"
			},

			/**
			 * Content of path b2b.ORDERS.CREAZIONE-ABBREVIATA
			 */
			CREAZIONE_ABBREVIATA: {
				/**
				 * C
				 */
				INIZIALE: {
					id: 6124,
					label: "b2b.ORDERS.CREAZIONE-ABBREVIATA.INIZIALE"
				},

			},

			/**
			 * Creazione al
			 */
			CREAZIONE_AL: {
				id: 3876,
				label: "b2b.ORDERS.CREAZIONE-AL"
			},

			/**
			 * Creazione dal
			 */
			CREAZIONE_DAL: {
				id: 3877,
				label: "b2b.ORDERS.CREAZIONE-DAL"
			},

			/**
			 * Dal
			 */
			DA_DATA: {
				id: 3878,
				label: "b2b.ORDERS.DA-DATA"
			},

			/**
			 * Data appuntamento ADV
			 */
			DATA_APPUNTAMENTO_AGENZIA: {
				id: 3879,
				label: "b2b.ORDERS.DATA-APPUNTAMENTO-AGENZIA"
			},

			/**
			 * Content of path b2b.ORDERS.DATA-CONFERMA
			 */
			DATA_CONFERMA: {
				/**
				 * C
				 */
				INIZIALE: {
					id: 6125,
					label: "b2b.ORDERS.DATA-CONFERMA.INIZIALE"
				},

			},

			/**
			 * Data conferma TO
			 */
			DATA_CONFERMA_TO: {
				id: 3881,
				label: "b2b.ORDERS.DATA-CONFERMA-TO"
			},

			/**
			 * Data creazione
			 */
			DATA_CREAZIONE: {
				id: 3882,
				label: "b2b.ORDERS.DATA-CREAZIONE"
			},

			/**
			 * Data evasione
			 */
			DATA_EVASIONE: {
				id: 3883,
				label: "b2b.ORDERS.DATA-EVASIONE"
			},

			/**
			 * Data evasione al
			 */
			DATA_EVASIONE_AL: {
				id: 3884,
				label: "b2b.ORDERS.DATA-EVASIONE-AL"
			},

			/**
			 * Data evasione dal
			 */
			DATA_EVASIONE_DAL: {
				id: 3885,
				label: "b2b.ORDERS.DATA-EVASIONE-DAL"
			},

			/**
			 * Content of path b2b.ORDERS.DATA-PARTENZA
			 */
			DATA_PARTENZA: {
				/**
				 * P
				 */
				INIZIALE: {
					id: 6126,
					label: "b2b.ORDERS.DATA-PARTENZA.INIZIALE"
				},

			},

			/**
			 * Data prenotazione
			 */
			DATA_PRENOTAZIONE: {
				id: 3887,
				label: "b2b.ORDERS.DATA-PRENOTAZIONE"
			},

			/**
			 * Rientro
			 */
			DATA_RIENTRO: {
				id: 3888,
				label: "b2b.ORDERS.DATA-RIENTRO"
			},

			/**
			 * Rilascio doc
			 */
			DATA_RILASCIODOC: {
				id: 3889,
				label: "b2b.ORDERS.DATA-RILASCIODOC"
			},

			/**
			 * Data acconto
			 */
			DATAACCONTO: {
				id: 3890,
				label: "b2b.ORDERS.DATAACCONTO"
			},

			/**
			 * Data saldo
			 */
			DATASALDO: {
				id: 3891,
				label: "b2b.ORDERS.DATASALDO"
			},

			/**
			 * Date
			 */
			DATE: {
				id: 3892,
				label: "b2b.ORDERS.DATE"
			},

			/**
			 * Destinazione
			 */
			DESTINAZIONE: {
				id: 3893,
				label: "b2b.ORDERS.DESTINAZIONE"
			},

			/**
			 * Disponibilità
			 */
			DISPONIBILITA: {
				id: 3894,
				label: "b2b.ORDERS.DISPONIBILITA"
			},

			/**
			 * Documenti in partenza
			 */
			DOC_IN_PARTENZA: {
				id: 3895,
				label: "b2b.ORDERS.DOC_IN_PARTENZA"
			},

			/**
			 * Duplicare il preventivo {id} {name}?
			 */
			DUPLICARE_IL_PREVENTIVO: {
				id: 3896,
				label: "b2b.ORDERS.DUPLICARE-IL-PREVENTIVO"
			},

			/**
			 * Eliminare il preventivo {id} {name}?
			 */
			ELIMINARE_IL_PREVENTIVO: {
				id: 3897,
				label: "b2b.ORDERS.ELIMINARE-IL-PREVENTIVO"
			},

			/**
			 * Content of path b2b.ORDERS.FILTRI-LUOGHI
			 */
			FILTRI_LUOGHI: {
				/**
				 * Africa
				 */
				Africa: {
					id: 3898,
					label: "b2b.ORDERS.FILTRI-LUOGHI.Africa"
				},

				/**
				 * Americhe
				 */
				Americhe: {
					id: 3899,
					label: "b2b.ORDERS.FILTRI-LUOGHI.Americhe"
				},

				/**
				 * Australia/Nuova zelanda
				 */
				Australia: {
					id: 3900,
					label: "b2b.ORDERS.FILTRI-LUOGHI.Australia"
				},

				/**
				 * Caraibi
				 */
				Caraibi: {
					id: 3901,
					label: "b2b.ORDERS.FILTRI-LUOGHI.Caraibi"
				},

				/**
				 * Oceano Indiano
				 */
				OcIndiano: {
					id: 3902,
					label: "b2b.ORDERS.FILTRI-LUOGHI.OcIndiano"
				},

				/**
				 * Oriente
				 */
				Oriente: {
					id: 3903,
					label: "b2b.ORDERS.FILTRI-LUOGHI.Oriente"
				},

				/**
				 * Sud-est asiatico
				 */
				SEAsia: {
					id: 3904,
					label: "b2b.ORDERS.FILTRI-LUOGHI.SEAsia"
				},

				/**
				 * Sud Pacifico
				 */
				SudPacifico: {
					id: 3905,
					label: "b2b.ORDERS.FILTRI-LUOGHI.SudPacifico"
				},

			},

			/**
			 * Filtra per categoria
			 */
			FILTRO_PER_CATEGORIA: {
				id: 3906,
				label: "b2b.ORDERS.FILTRO-PER-CATEGORIA"
			},

			/**
			 * Filtra per status
			 */
			FILTRO_PER_STATUS: {
				id: 3907,
				label: "b2b.ORDERS.FILTRO-PER-STATUS"
			},

			/**
			 * Filtra per team
			 */
			FILTRO_PER_TEAM: {
				id: 3908,
				label: "b2b.ORDERS.FILTRO-PER-TEAM"
			},

			/**
			 * Fornitore
			 */
			FORNITORE: {
				id: 3909,
				label: "b2b.ORDERS.FORNITORE"
			},

			/**
			 * +/- gg
			 */
			GG_PM: {
				id: 3910,
				label: "b2b.ORDERS.GG-PM"
			},

			/**
			 * ID
			 */
			ID: {
				id: 3911,
				label: "b2b.ORDERS.ID"
			},

			/**
			 * ID riga
			 */
			ID_RIGA: {
				id: 3912,
				label: "b2b.ORDERS.ID-RIGA"
			},

			/**
			 * ID servizio
			 */
			ID_SERVIZIO: {
				id: 3913,
				label: "b2b.ORDERS.ID-SERVIZIO"
			},

			/**
			 * Content of path b2b.ORDERS.IMPIEGATO
			 */
			IMPIEGATO: {
				/**
				 * U
				 */
				INIZIALE: {
					id: 6121,
					label: "b2b.ORDERS.IMPIEGATO.INIZIALE"
				},

			},

			/**
			 * Inserita al
			 */
			INSERIMENTO_AL: {
				id: 3915,
				label: "b2b.ORDERS.INSERIMENTO-AL"
			},

			/**
			 * Inserita dal
			 */
			INSERIMENTO_DAL: {
				id: 3916,
				label: "b2b.ORDERS.INSERIMENTO-DAL"
			},

			/**
			 * Luogo
			 */
			LUOGO: {
				id: 3917,
				label: "b2b.ORDERS.LUOGO"
			},

			/**
			 * Modifica status di {n} preventiv{n, plural, =0{i} one{o} other{i}}
			 */
			MODIFICA_STATUS_PREVENTIVI: {
				id: 3918,
				label: "b2b.ORDERS.MODIFICA-STATUS-PREVENTIVI"
			},

			/**
			 * Modificare lo status di {n} preventiv{n, plural, =0{i} one{o} other{i}}?
			 */
			MODIFICA_STATUS_PREVENTIVI_CONFERMA: {
				id: 3919,
				label: "b2b.ORDERS.MODIFICA-STATUS-PREVENTIVI-CONFERMA"
			},

			/**
			 * Nascondi servizi accessori
			 */
			NASCONDI_ACCESSORI: {
				id: 3920,
				label: "b2b.ORDERS.NASCONDI-ACCESSORI"
			},

			/**
			 * Nessuna riga corrispondente ai criteri
			 */
			NESSUNA_RIGA_DA_OTTIMIZZARE: {
				id: 3921,
				label: "b2b.ORDERS.NESSUNA-RIGA-DA-OTTIMIZZARE"
			},

			/**
			 * Cognome, Nome PAX
			 */
			NOME_PAX: {
				id: 3922,
				label: "b2b.ORDERS.NOME-PAX"
			},

			/**
			 * Viaggio
			 */
			NOME_VIAGGIO: {
				id: 3923,
				label: "b2b.ORDERS.NOME-VIAGGIO"
			},

			/**
			 * Ns.Rif
			 */
			NOSTRO_RIFERIMENTO_ABBREVIATO: {
				id: 3924,
				label: "b2b.ORDERS.NOSTRO-RIFERIMENTO-ABBREVIATO"
			},

			/**
			 * Nr.Conferma
			 */
			NUMERO_CONFERMA: {
				id: 3925,
				label: "b2b.ORDERS.NUMERO-CONFERMA"
			},

			/**
			 * Nr. Conf. Fornitore
			 */
			NUMERO_CONFERMA_FORNITORE: {
				id: 3926,
				label: "b2b.ORDERS.NUMERO-CONFERMA-FORNITORE"
			},

			/**
			 * Rif. Conf. Naar
			 */
			NUMERO_CONFERMA_NAAR: {
				id: 3927,
				label: "b2b.ORDERS.NUMERO-CONFERMA-NAAR"
			},

			/**
			 * Offerta
			 */
			OFFERTA: {
				id: 3928,
				label: "b2b.ORDERS.OFFERTA"
			},

			/**
			 * Operatore
			 */
			OPERATORE: {
				id: 3929,
				label: "b2b.ORDERS.OPERATORE"
			},

			/**
			 * Ottimizzatore tariffe
			 */
			OTTIMIZZATORE_TARIFFE: {
				id: 3930,
				label: "b2b.ORDERS.OTTIMIZZATORE-TARIFFE"
			},

			/**
			 * Pagamento
			 */
			PAGAMENTO: {
				id: 3931,
				label: "b2b.ORDERS.PAGAMENTO"
			},

			/**
			 * Part
			 */
			PARTENZA_ABBREVIATA: {
				id: 3932,
				label: "b2b.ORDERS.PARTENZA-ABBREVIATA"
			},

			/**
			 * Partenza al
			 */
			PARTENZA_AL: {
				id: 3933,
				label: "b2b.ORDERS.PARTENZA-AL"
			},

			/**
			 * Partenza dal
			 */
			PARTENZA_DAL: {
				id: 3934,
				label: "b2b.ORDERS.PARTENZA-DAL"
			},

			/**
			 * PartitaIVA /#RefNumber
			 */
			PARTITA_IVA: {
				id: 3935,
				label: "b2b.ORDERS.PARTITA-IVA"
			},

			/**
			 * Pax
			 */
			PAX: {
				id: 3936,
				label: "b2b.ORDERS.PAX"
			},

			/**
			 * Pren
			 */
			PRENOTAZIONE_ABBREVIATA: {
				id: 3937,
				label: "b2b.ORDERS.PRENOTAZIONE-ABBREVIATA"
			},

			/**
			 * Preventivo {id} duplicato. Nuovo preventivo: {idclone}
			 */
			PREVENTIVO_DUPLICATO: {
				id: 3938,
				label: "b2b.ORDERS.PREVENTIVO-DUPLICATO"
			},

			/**
			 * Preventivo {id} eliminato
			 */
			PREVENTIVO_ELIMINATO: {
				id: 3939,
				label: "b2b.ORDERS.PREVENTIVO-ELIMINATO"
			},

			/**
			 * Prezzo
			 */
			PREZZO: {
				id: 3940,
				label: "b2b.ORDERS.PREZZO"
			},

			/**
			 * Prezzo alt
			 */
			PREZZO_ALTERNATIVA: {
				id: 3941,
				label: "b2b.ORDERS.PREZZO-ALTERNATIVA"
			},

			/**
			 * Promotore
			 */
			PROMOTORE: {
				id: 3942,
				label: "b2b.ORDERS.PROMOTORE"
			},

			/**
			 * Ricerca offerta
			 */
			RICERCA_AVANZATA: {
				id: 3943,
				label: "b2b.ORDERS.RICERCA-AVANZATA"
			},

			/**
			 * Ricerca conferme
			 */
			RICERCA_CONFERME: {
				id: 3944,
				label: "b2b.ORDERS.RICERCA-CONFERME"
			},

			/**
			 * Elenco conferme
			 */
			RICERCA_CONFERME_EXPORT: {
				id: 3945,
				label: "b2b.ORDERS.RICERCA-CONFERME-EXPORT"
			},

			/**
			 * Ricerca mail
			 */
			RICERCA_MAIL: {
				id: 3946,
				label: "b2b.ORDERS.RICERCA-MAIL"
			},

			/**
			 * Ricerca note
			 */
			RICERCA_NOTE: {
				id: 3947,
				label: "b2b.ORDERS.RICERCA-NOTE"
			},

			/**
			 * Ricerca preventivi
			 */
			RICERCA_PREVENTIVI: {
				id: 3948,
				label: "b2b.ORDERS.RICERCA-PREVENTIVI"
			},

			/**
			 * Riferimento offerta
			 */
			RIFERIMENTO_OFFERTA: {
				id: 3949,
				label: "b2b.ORDERS.RIFERIMENTO-OFFERTA"
			},

			/**
			 * Content of path b2b.ORDERS.SALDARE
			 */
			SALDARE: {
				/**
				 * S
				 */
				INIZIALE: {
					id: 6129,
					label: "b2b.ORDERS.SALDARE.INIZIALE"
				},

			},

			/**
			 * Content of path b2b.ORDERS.SALDATO
			 */
			SALDATO: {
				/**
				 * P
				 */
				INIZIALE: {
					id: 6127,
					label: "b2b.ORDERS.SALDATO.INIZIALE"
				},

			},

			/**
			 * Saldo
			 */
			SALDO: {
				id: 3952,
				label: "b2b.ORDERS.SALDO"
			},

			/**
			 * Sales
			 */
			SALES: {
				id: 3953,
				label: "b2b.ORDERS.SALES"
			},

			/**
			 * Servizio
			 */
			SERVIZIO: {
				id: 3954,
				label: "b2b.ORDERS.SERVIZIO"
			},

			/**
			 * Solo annullate
			 */
			SOLO_ANNULLATE: {
				id: 3955,
				label: "b2b.ORDERS.SOLO-ANNULLATE"
			},

			/**
			 * Solo confermate
			 */
			SOLO_CONFERMATE: {
				id: 3956,
				label: "b2b.ORDERS.SOLO-CONFERMATE"
			},

			/**
			 * Solo conferme
			 */
			SOLO_CONFERME: {
				id: 3957,
				label: "b2b.ORDERS.SOLO-CONFERME"
			},

			/**
			 * Solo incomplete
			 */
			SOLO_INCOMPLETE: {
				id: 3958,
				label: "b2b.ORDERS.SOLO-INCOMPLETE"
			},

			/**
			 * Specificare il team
			 */
			SPECIFICARE_IL_TEAM: {
				id: 3959,
				label: "b2b.ORDERS.SPECIFICARE-IL-TEAM"
			},

			/**
			 * Specificare lo status
			 */
			SPECIFICARE_LO_STATUS: {
				id: 3960,
				label: "b2b.ORDERS.SPECIFICARE-LO-STATUS"
			},

			/**
			 * Spedizione
			 */
			SPEDIZIONE: {
				id: 3961,
				label: "b2b.ORDERS.SPEDIZIONE"
			},

			/**
			 * Sposta data evasione
			 */
			SPOSTA_DATA_EVASIONE: {
				id: 3962,
				label: "b2b.ORDERS.SPOSTA-DATA-EVASIONE"
			},

			/**
			 * Status
			 */
			STATUS: {
				id: 3963,
				label: "b2b.ORDERS.STATUS"
			},

			/**
			 * Content of path b2b.ORDERS.STATUS-GROUP
			 */
			STATUS_GROUP: {
				/**
				 * Commerciale
				 */
				COMMERCIALE: {
					id: 3964,
					label: "b2b.ORDERS.STATUS-GROUP.COMMERCIALE"
				},

				/**
				 * Operativo
				 */
				OPERATIVO: {
					id: 3965,
					label: "b2b.ORDERS.STATUS-GROUP.OPERATIVO"
				},

			},

			/**
			 * Status Doc
			 */
			STATUSDOC: {
				id: 3966,
				label: "b2b.ORDERS.STATUSDOC"
			},

			/**
			 * Tag dell'offerta
			 */
			TAGS: {
				id: 3967,
				label: "b2b.ORDERS.TAGS"
			},

			/**
			 * Team
			 */
			TEAM: {
				id: 3968,
				label: "b2b.ORDERS.TEAM"
			},

			/**
			 * Tipo servizio
			 */
			TIPO_SERVIZIO: {
				id: 3969,
				label: "b2b.ORDERS.TIPO-SERVIZIO"
			},

			/**
			 * Tipologia
			 */
			TIPOLOGIANOTE: {
				id: 3970,
				label: "b2b.ORDERS.TIPOLOGIANOTE"
			},

			/**
			 * Tipo QI
			 */
			TIPOQI: {
				id: 3971,
				label: "b2b.ORDERS.TIPOQI"
			},

			/**
			 * Ricerca offerte
			 */
			TITOLO_INTRANET: {
				id: 3972,
				label: "b2b.ORDERS.TITOLO-INTRANET"
			},

			/**
			 * I tuoi viaggi
			 */
			TITOLO_UTENTE: {
				id: 3973,
				label: "b2b.ORDERS.TITOLO-UTENTE"
			},

			/**
			 * Totale
			 */
			TOTALE: {
				id: 3974,
				label: "b2b.ORDERS.TOTALE"
			},

			/**
			 * Tot netto
			 */
			TOTNETTO: {
				id: 3975,
				label: "b2b.ORDERS.TOTNETTO"
			},

			/**
			 * Tour operator
			 */
			TOUR_OPERATOR: {
				id: 3976,
				label: "b2b.ORDERS.TOUR-OPERATOR"
			},

			/**
			 * -- Tutte le agenzie --
			 */
			TUTTE_LE_AGENZIE: {
				id: 3977,
				label: "b2b.ORDERS.TUTTE-LE-AGENZIE"
			},

			/**
			 * -- Tutti gli impiegati --
			 */
			TUTTI_GLI_IMPIEGATI: {
				id: 3978,
				label: "b2b.ORDERS.TUTTI-GLI-IMPIEGATI"
			},

			/**
			 * -- Tutti i fornitori --
			 */
			TUTTI_I_FORNITORI: {
				id: 3979,
				label: "b2b.ORDERS.TUTTI-I-FORNITORI"
			},

			/**
			 * -- Tutti i servizi --
			 */
			TUTTI_I_SERVIZI: {
				id: 3980,
				label: "b2b.ORDERS.TUTTI-I-SERVIZI"
			},

			/**
			 * Tutti quelli selezionati
			 */
			TUTTI_I_TAG: {
				id: 3981,
				label: "b2b.ORDERS.TUTTI-I-TAG"
			},

			/**
			 * -- Qualsiasi tipo --
			 */
			TUTTI_I_TIPI_SERVIZIO: {
				id: 3982,
				label: "b2b.ORDERS.TUTTI-I-TIPI-SERVIZIO"
			},

			/**
			 * -- Tutti --
			 */
			TUTTI_I_TOUR_OPERATOR: {
				id: 3983,
				label: "b2b.ORDERS.TUTTI-I-TOUR-OPERATOR"
			},

			/**
			 * Utente preventivo
			 */
			USER_PREVENTIVO: {
				id: 3984,
				label: "b2b.ORDERS.USER-PREVENTIVO"
			},

			/**
			 * Content of path b2b.ORDERS.UTENTE-CONFERMA
			 */
			UTENTE_CONFERMA: {
				/**
				 * C
				 */
				INIZIALE: {
					id: 6123,
					label: "b2b.ORDERS.UTENTE-CONFERMA.INIZIALE"
				},

			},

			/**
			 * Content of path b2b.ORDERS.UTENTE-PREVENTIVO
			 */
			UTENTE_PREVENTIVO: {
				/**
				 * P
				 */
				INIZIALE: {
					id: 6122,
					label: "b2b.ORDERS.UTENTE-PREVENTIVO.INIZIALE"
				},

			},

			/**
			 * Conferma
			 */
			UTENTE_CONF: {
				id: 3987,
				label: "b2b.ORDERS.UTENTE_CONF"
			},

			/**
			 * Documenti
			 */
			UTENTE_DOC: {
				id: 3988,
				label: "b2b.ORDERS.UTENTE_DOC"
			},

			/**
			 * Preventivo
			 */
			UTENTE_PREV: {
				id: 3989,
				label: "b2b.ORDERS.UTENTE_PREV"
			},

			/**
			 * Valuta
			 */
			VALUTA: {
				id: 3990,
				label: "b2b.ORDERS.VALUTA"
			},

			/**
			 * Vedi solo i tuoi viaggi
			 */
			VEDI_SOLO_I_TUOI_VIAGGI: {
				id: 3991,
				label: "b2b.ORDERS.VEDI-SOLO-I-TUOI-VIAGGI"
			},

			/**
			 * Content of path b2b.ORDERS.WORK-LOAD
			 */
			WORK_LOAD: {
				/**
				 * Apri questo viaggio
				 */
				APRI_VIAGGIO: {
					id: 3992,
					label: "b2b.ORDERS.WORK-LOAD.APRI-VIAGGIO"
				},

				/**
				 * Dettaglio preventivi
				 */
				DETTAGLIO_PREVENTIVI: {
					id: 3993,
					label: "b2b.ORDERS.WORK-LOAD.DETTAGLIO-PREVENTIVI"
				},

				/**
				 * Carico di lavoro
				 */
				TITLE: {
					id: 3994,
					label: "b2b.ORDERS.WORK-LOAD.TITLE"
				},

				/**
				 * Totale preventivi
				 */
				TOTALE_PREVENTIVI: {
					id: 3995,
					label: "b2b.ORDERS.WORK-LOAD.TOTALE-PREVENTIVI"
				},

			},

			/**
			 * Content of path b2b.ORDERS.CODE-VOLI
			 */
			CODE_VOLI: {
				/**
				 * Vuoi ripristinare PNR {id}?
				 */
				RIPRISTINO_CODA_VOLO: {
					id: 6270,
					label: "b2b.ORDERS.CODE-VOLI.RIPRISTINO-CODA-VOLO"
				},

				/**
				 * Id Coda
				 */
				ID_CODA: {
					id: 6271,
					label: "b2b.ORDERS.CODE-VOLI.ID-CODA"
				},

				/**
				 * Offerta Id
				 */
				ID_OFFERTA: {
					id: 6272,
					label: "b2b.ORDERS.CODE-VOLI.ID-OFFERTA"
				},

				/**
				 * PNR
				 */
				PNR: {
					id: 6273,
					label: "b2b.ORDERS.CODE-VOLI.PNR"
				},

				/**
				 * Nome fornitore
				 */
				FORNITORE: {
					id: 6274,
					label: "b2b.ORDERS.CODE-VOLI.FORNITORE"
				},

				/**
				 * Data
				 */
				DATA: {
					id: 6275,
					label: "b2b.ORDERS.CODE-VOLI.DATA"
				},

				/**
				 * Testo
				 */
				TESTO: {
					id: 6276,
					label: "b2b.ORDERS.CODE-VOLI.TESTO"
				},

				/**
				 * Code voli
				 */
				RICERCA: {
					id: 6284,
					label: "b2b.ORDERS.CODE-VOLI.RICERCA"
				},

				/**
				 * Termine
				 */
				TERMINE: {
					id: 6286,
					label: "b2b.ORDERS.CODE-VOLI.TERMINE"
				},

				/**
				 * Dal
				 */
				CREATO_DAL: {
					id: 6287,
					label: "b2b.ORDERS.CODE-VOLI.CREATO-DAL"
				},

				/**
				 * Al
				 */
				CREATO_AL: {
					id: 6288,
					label: "b2b.ORDERS.CODE-VOLI.CREATO-AL"
				},

				/**
				 * Vuoi eliminare PNR {id}?
				 */
				ELIMINAZIONE_CODA_VOLO: {
					id: 6289,
					label: "b2b.ORDERS.CODE-VOLI.ELIMINAZIONE-CODA-VOLO"
				},

				/**
				 * Nome utente
				 */
				UTENTE: {
					id: 6290,
					label: "b2b.ORDERS.CODE-VOLI.UTENTE"
				},

				/**
				 * Date partenza
				 */
				DATA_PARTENZA: {
					id: 6291,
					label: "b2b.ORDERS.CODE-VOLI.DATA-PARTENZA"
				},

				/**
				 * Dettaglio coda
				 */
				DETAIL_TITLE: {
					id: 6531,
					label: "b2b.ORDERS.CODE-VOLI.DETAIL-TITLE"
				},

				/**
				 * Messaggio di coda archiviato
				 */
				CODA_VOLO_ELIMINATO: {
					id: 6532,
					label: "b2b.ORDERS.CODE-VOLI.CODA-VOLO-ELIMINATO"
				},

				/**
				 * Archivia
				 */
				CODA_VOLO_ELIMINA: {
					id: 6533,
					label: "b2b.ORDERS.CODE-VOLI.CODA-VOLO-ELIMINA"
				},

				/**
				 * Messaggio di coda ripristinato
				 */
				CODA_VOLO_RIPRISTINATO: {
					id: 6534,
					label: "b2b.ORDERS.CODE-VOLI.CODA-VOLO-RIPRISTINATO"
				},

				/**
				 * Mostra messaggi archiviati
				 */
				MOSTRA_ELIMINATI: {
					id: 6545,
					label: "b2b.ORDERS.CODE-VOLI.MOSTRA-ELIMINATI"
				},

				/**
				 * Mostra dettagli
				 */
				APRI: {
					id: 6547,
					label: "b2b.ORDERS.CODE-VOLI.APRI"
				},

			},

			/**
			 * Carico di lavoro
			 */
			CARICO_LAVORO: {
				id: 6587,
				label: "b2b.ORDERS.CARICO-LAVORO"
			},

		},

		/**
		 * Content of path b2b.RICHIESTA-PREVENTIVO
		 */
		RICHIESTA_PREVENTIVO: {
			/**
			 * Aggiornare
			 */
			AGGIORNARE: {
				id: 3996,
				label: "b2b.RICHIESTA-PREVENTIVO.AGGIORNARE"
			},

			/**
			 * Reimposta dati
			 */
			BTN_CLEAR: {
				id: 3997,
				label: "b2b.RICHIESTA-PREVENTIVO.BTN-CLEAR"
			},

			/**
			 * Invia richiesta
			 */
			BTN_SUBMIT: {
				id: 3998,
				label: "b2b.RICHIESTA-PREVENTIVO.BTN-SUBMIT"
			},

			/**
			 * Crea un altro viaggio
			 */
			CREA_ALTRO_VIAGGIO: {
				id: 3999,
				label: "b2b.RICHIESTA-PREVENTIVO.CREA-ALTRO-VIAGGIO"
			},

			/**
			 * Data evasione
			 */
			DATA_EVASIONE: {
				id: 4000,
				label: "b2b.RICHIESTA-PREVENTIVO.DATA-EVASIONE"
			},

			/**
			 * Data evasione al
			 */
			DATA_EVASIONE_AL: {
				id: 4001,
				label: "b2b.RICHIESTA-PREVENTIVO.DATA-EVASIONE-AL"
			},

			/**
			 * Data evasione dal
			 */
			DATA_EVASIONE_DAL: {
				id: 4002,
				label: "b2b.RICHIESTA-PREVENTIVO.DATA-EVASIONE-DAL"
			},

			/**
			 * Inserire la descrizione e dettagli del viaggio
			 */
			ERR_DESCRIZIONE: {
				id: 4003,
				label: "b2b.RICHIESTA-PREVENTIVO.ERR-DESCRIZIONE"
			},

			/**
			 * Scegliere una destinazione
			 */
			ERR_DESTINAZIONI: {
				id: 4004,
				label: "b2b.RICHIESTA-PREVENTIVO.ERR-DESTINAZIONI"
			},

			/**
			 * Inserire il nome del viaggio
			 */
			ERR_NOME_DEL_VIAGGIO: {
				id: 4005,
				label: "b2b.RICHIESTA-PREVENTIVO.ERR-NOME-DEL-VIAGGIO"
			},

			/**
			 * Inserire la data di partenza
			 */
			ERR_PARTENZA_RITORNO: {
				id: 4006,
				label: "b2b.RICHIESTA-PREVENTIVO.ERR-PARTENZA-RITORNO"
			},

			/**
			 * Selezionare un utente
			 */
			ERR_UTENTE_NO_SEL: {
				id: 4007,
				label: "b2b.RICHIESTA-PREVENTIVO.ERR-UTENTE-NO-SEL"
			},

			/**
			 * Content of path b2b.RICHIESTA-PREVENTIVO.ERROR-MSGS
			 */
			ERROR_MSGS: {
				/**
				 * Budget a persona
				 */
				BUDGET: {
					id: 4008,
					label: "b2b.RICHIESTA-PREVENTIVO.ERROR-MSGS.BUDGET"
				},

				/**
				 * Descrizione
				 */
				DESCRIZIONE: {
					id: 4009,
					label: "b2b.RICHIESTA-PREVENTIVO.ERROR-MSGS.DESCRIZIONE"
				},

				/**
				 * Destinazione
				 */
				DESTINAZIONE: {
					id: 4010,
					label: "b2b.RICHIESTA-PREVENTIVO.ERROR-MSGS.DESTINAZIONE"
				},

				/**
				 * Specificare il nome del viaggio
				 */
				NOME_DEL_VIAGGIO: {
					id: 4011,
					label: "b2b.RICHIESTA-PREVENTIVO.ERROR-MSGS.NOME-DEL-VIAGGIO"
				},

				/**
				 * Opzioni voli
				 */
				OPZIONI_VOLI: {
					id: 4012,
					label: "b2b.RICHIESTA-PREVENTIVO.ERROR-MSGS.OPZIONI-VOLI"
				},

				/**
				 * Inserire la data di partenza
				 */
				PARTENZA_IL: {
					id: 4013,
					label: "b2b.RICHIESTA-PREVENTIVO.ERROR-MSGS.PARTENZA-IL"
				},

				/**
				 * Inserire la data di partenza
				 */
				RITORNO_IL: {
					id: 4014,
					label: "b2b.RICHIESTA-PREVENTIVO.ERROR-MSGS.RITORNO-IL"
				},

				/**
				 * Impostare il tipo viaggio
				 */
				TIPO_VIAGGIO: {
					id: 4015,
					label: "b2b.RICHIESTA-PREVENTIVO.ERROR-MSGS.TIPO-VIAGGIO"
				},

			},

			/**
			 * Filtro per categoria agenzia
			 */
			FILTRO_PER_CATEGORIA: {
				id: 4016,
				label: "b2b.RICHIESTA-PREVENTIVO.FILTRO-PER-CATEGORIA"
			},

			/**
			 * Filtro per status
			 */
			FILTRO_PER_STATUS: {
				id: 4017,
				label: "b2b.RICHIESTA-PREVENTIVO.FILTRO-PER-STATUS"
			},

			/**
			 * Filtro per team
			 */
			FILTRO_PER_TEAM: {
				id: 4018,
				label: "b2b.RICHIESTA-PREVENTIVO.FILTRO-PER-TEAM"
			},

			/**
			 * gg
			 */
			GG: {
				id: 4019,
				label: "b2b.RICHIESTA-PREVENTIVO.GG"
			},

			/**
			 * +/- gg
			 */
			GG_PM: {
				id: 4020,
				label: "b2b.RICHIESTA-PREVENTIVO.GG-PM"
			},

			/**
			 * Data appuntamento clienti
			 */
			LBL_APPUNTAMENTO_CLIENTI: {
				id: 4021,
				label: "b2b.RICHIESTA-PREVENTIVO.LBL-APPUNTAMENTO-CLIENTI"
			},

			/**
			 * Budget Totale
			 */
			LBL_BUDGET: {
				id: 4022,
				label: "b2b.RICHIESTA-PREVENTIVO.LBL-BUDGET"
			},

			/**
			 * Categoria Hotel
			 */
			LBL_CAT_HOTEL: {
				id: 4023,
				label: "b2b.RICHIESTA-PREVENTIVO.LBL-CAT-HOTEL"
			},

			/**
			 * Descrizione e dettagli del viaggio
			 */
			LBL_DESCRIZIONE: {
				id: 4024,
				label: "b2b.RICHIESTA-PREVENTIVO.LBL-DESCRIZIONE"
			},

			/**
			 * Destinazione
			 */
			LBL_DESTINAZIONE: {
				id: 4025,
				label: "b2b.RICHIESTA-PREVENTIVO.LBL-DESTINAZIONE"
			},

			/**
			 * Noleggio auto
			 */
			LBL_NOLEGGIO: {
				id: 4026,
				label: "b2b.RICHIESTA-PREVENTIVO.LBL-NOLEGGIO"
			},

			/**
			 * Nome del viaggio
			 */
			LBL_NOME_DEL_VIAGGIO: {
				id: 4027,
				label: "b2b.RICHIESTA-PREVENTIVO.LBL-NOME-DEL-VIAGGIO"
			},

			/**
			 * Note
			 */
			LBL_NOTE: {
				id: 4028,
				label: "b2b.RICHIESTA-PREVENTIVO.LBL-NOTE"
			},

			/**
			 * Opzioni Voli
			 */
			LBL_OPZIONI_VOLI: {
				id: 4029,
				label: "b2b.RICHIESTA-PREVENTIVO.LBL-OPZIONI-VOLI"
			},

			/**
			 * Partenza il
			 */
			LBL_PARTENZA: {
				id: 4030,
				label: "b2b.RICHIESTA-PREVENTIVO.LBL-PARTENZA"
			},

			/**
			 * Partenza da (indicare aeroporto)
			 */
			LBL_PARTENZA_DA: {
				id: 4031,
				label: "b2b.RICHIESTA-PREVENTIVO.LBL-PARTENZA-DA"
			},

			/**
			 * Partenza
			 */
			LBL_PARTENZA_RITORNO: {
				id: 4032,
				label: "b2b.RICHIESTA-PREVENTIVO.LBL-PARTENZA-RITORNO"
			},

			/**
			 * Ritorno il
			 */
			LBL_RITORNO: {
				id: 4033,
				label: "b2b.RICHIESTA-PREVENTIVO.LBL-RITORNO"
			},

			/**
			 * Tipo viaggio
			 */
			LBL_TIPO_VIAGGIO: {
				id: 4034,
				label: "b2b.RICHIESTA-PREVENTIVO.LBL-TIPO-VIAGGIO"
			},

			/**
			 * Trasferimenti
			 */
			LBL_TRASFERIMENTI: {
				id: 4035,
				label: "b2b.RICHIESTA-PREVENTIVO.LBL-TRASFERIMENTI"
			},

			/**
			 * Content of path b2b.RICHIESTA-PREVENTIVO.LIST-BUDGET
			 */
			LIST_BUDGET: {
				/**
				 * Fino a € 5.000
				 */
				_0: {
					id: 4036,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-BUDGET.0"
				},

				/**
				 * Fino a € 8.000
				 */
				_1: {
					id: 4037,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-BUDGET.1"
				},

				/**
				 * Fino a € 10.000
				 */
				_2: {
					id: 4038,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-BUDGET.2"
				},

				/**
				 * Oltre € 10.000
				 */
				_3: {
					id: 4039,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-BUDGET.3"
				},

				/**
				 * RICHIESTA-PREVENTIVO.LIST-BUDGET.4
				 */
				_4: {
					id: 6525,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-BUDGET.4"
				},

			},

			/**
			 * Content of path b2b.RICHIESTA-PREVENTIVO.LIST-CAT-HOTEL
			 */
			LIST_CAT_HOTEL: {
				/**
				 * 2 stelle o superiore
				 */
				_0: {
					id: 4040,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-CAT-HOTEL.0"
				},

				/**
				 * 3 stelle o superiore
				 */
				_1: {
					id: 4041,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-CAT-HOTEL.1"
				},

				/**
				 * 4 stelle o superiore
				 */
				_2: {
					id: 4042,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-CAT-HOTEL.2"
				},

				/**
				 * 5 stelle
				 */
				_3: {
					id: 4043,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-CAT-HOTEL.3"
				},

				/**
				 * RICHIESTA-PREVENTIVO.LIST-CAT-HOTEL.4
				 */
				_4: {
					id: 6523,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-CAT-HOTEL.4"
				},

			},

			/**
			 * Content of path b2b.RICHIESTA-PREVENTIVO.LIST-DESTINAZIONI
			 */
			LIST_DESTINAZIONI: {
				/**
				 * Africa, Emirati e Oceano Indiano
				 */
				_0: {
					id: 4044,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-DESTINAZIONI.0"
				},

				/**
				 * Europa
				 */
				_1: {
					id: 4045,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-DESTINAZIONI.1"
				},

				/**
				 * Nord e Centro America, Caraibi
				 */
				_2: {
					id: 4046,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-DESTINAZIONI.2"
				},

				/**
				 * Oceania e Pacifico
				 */
				_3: {
					id: 4047,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-DESTINAZIONI.3"
				},

				/**
				 * Oriente
				 */
				_4: {
					id: 4048,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-DESTINAZIONI.4"
				},

				/**
				 * Sudamerica
				 */
				_5: {
					id: 4049,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-DESTINAZIONI.5"
				},

			},

			/**
			 * Content of path b2b.RICHIESTA-PREVENTIVO.LIST-NOLEGGIO
			 */
			LIST_NOLEGGIO: {
				/**
				 * Nessuna
				 */
				_0: {
					id: 4050,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-NOLEGGIO.0"
				},

				/**
				 * Piccola
				 */
				_1: {
					id: 4051,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-NOLEGGIO.1"
				},

				/**
				 * Media
				 */
				_2: {
					id: 4052,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-NOLEGGIO.2"
				},

				/**
				 * Grande
				 */
				_3: {
					id: 4053,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-NOLEGGIO.3"
				},

				/**
				 * RICHIESTA-PREVENTIVO.LIST-NOLEGGIO.4
				 */
				_4: {
					id: 6524,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-NOLEGGIO.4"
				},

			},

			/**
			 * Content of path b2b.RICHIESTA-PREVENTIVO.LIST-TRASFERIMENTI
			 */
			LIST_TRASFERIMENTI: {
				/**
				 * Privati
				 */
				_0: {
					id: 4054,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-TRASFERIMENTI.0"
				},

				/**
				 * Collettivi
				 */
				_1: {
					id: 4055,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-TRASFERIMENTI.1"
				},

				/**
				 * RICHIESTA-PREVENTIVO.LIST-TRASFERIMENTI.2
				 */
				_2: {
					id: 6521,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-TRASFERIMENTI.2"
				},

			},

			/**
			 * Content of path b2b.RICHIESTA-PREVENTIVO.LIST-VOLI
			 */
			LIST_VOLI: {
				/**
				 * Nessun volo
				 */
				_0: {
					id: 4056,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-VOLI.0"
				},

				/**
				 * Economy
				 */
				_1: {
					id: 4057,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-VOLI.1"
				},

				/**
				 * Economy Premium
				 */
				_2: {
					id: 4058,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-VOLI.2"
				},

				/**
				 * Business Class
				 */
				_3: {
					id: 4059,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-VOLI.3"
				},

				/**
				 * First Class
				 */
				_4: {
					id: 4060,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-VOLI.4"
				},

				/**
				 * RICHIESTA-PREVENTIVO.LIST-VOLI.5
				 */
				_5: {
					id: 6522,
					label: "b2b.RICHIESTA-PREVENTIVO.LIST-VOLI.5"
				},

			},

			/**
			 * Modifica status di {n} preventiv{n, plural, =0{i} one{o} other{i}}
			 */
			MODIFICA_STATUS_PREVENTIVI: {
				id: 4061,
				label: "b2b.RICHIESTA-PREVENTIVO.MODIFICA-STATUS-PREVENTIVI"
			},

			/**
			 * Modificare lo status di {n} preventiv{n, plural, =0{i} one{o} other{i}}?
			 */
			MODIFICA_STATUS_PREVENTIVI_CONFERMA: {
				id: 4062,
				label: "b2b.RICHIESTA-PREVENTIVO.MODIFICA-STATUS-PREVENTIVI-CONFERMA"
			},

			/**
			 * È consigliabile indicare la data di appuntamento con i clienti in modo da poter fornire una proposta aggiornata in termini di disponibilità e costi.
			 */
			MSG_APPUNTAMENTO_CLIENTI: {
				id: 4063,
				label: "b2b.RICHIESTA-PREVENTIVO.MSG-APPUNTAMENTO-CLIENTI"
			},

			/**
			 * Per procedere con l'elaborazione completa del preventivo, richiediamo nomi e cognomi dei passeggeri (come da passaporto) e le date di nascita di bambini/ragazzi fino a 18 anni non compiuti. Importante: in presenza di nomi fittizi la richiesta non verrà elaborata (eccetto Europa)
			 */
			MSG_CAMERE_INFO: {
				id: 4064,
				label: "b2b.RICHIESTA-PREVENTIVO.MSG-CAMERE-INFO"
			},

			/**
			 * In caso di viaggiatori di tipologia Child e/o Infant (0/11 anni) vi preghiamo voler completare le informazioni con le date di nascita, obbligatorie per la quotazione del volato.
			 */
			MSG_CAMERE_INFO_CHILD: {
				id: 4065,
				label: "b2b.RICHIESTA-PREVENTIVO.MSG-CAMERE-INFO-CHILD"
			},

			/**
			 * Nel caso di combinazione destinazioni preghiamo selezionare la prima area dell'itinerario (esempio: Giappone + Polinesia indicare Oriente)
			 */
			MSG_DESTINAZIONE_INFO: {
				id: 4066,
				label: "b2b.RICHIESTA-PREVENTIVO.MSG-DESTINAZIONE-INFO"
			},

			/**
			 * Se si desidera registrare il preventivo con utente diverso da quello utilizzato per il login, è necessario fare una nuova registrazione utente. Clicca sul "+" e segui le indicazioni.
			 */
			MSG_UTENTESECONDARIO_INFO: {
				id: 4067,
				label: "b2b.RICHIESTA-PREVENTIVO.MSG-UTENTESECONDARIO-INFO"
			},

			/**
			 * Il viaggio è stato creato correttamente!
			 * Una volta lavorato il preventivo riceverai il link per verificare nel dettaglio la proposta per i clienti.
			 */
			MSG_VIAGGIO_CREATO_CORRETTAMENTE: {
				id: 4068,
				label: "b2b.RICHIESTA-PREVENTIVO.MSG-VIAGGIO-CREATO-CORRETTAMENTE"
			},

			/**
			 * Content of path b2b.RICHIESTA-PREVENTIVO.NUOVO-UTENTE
			 */
			NUOVO_UTENTE: {
				/**
				 * Cognome
				 */
				COGNOME: {
					id: 4069,
					label: "b2b.RICHIESTA-PREVENTIVO.NUOVO-UTENTE.COGNOME"
				},

				/**
				 * Email
				 */
				EMAIL: {
					id: 4070,
					label: "b2b.RICHIESTA-PREVENTIVO.NUOVO-UTENTE.EMAIL"
				},

				/**
				 * Aggiunta utente secondario
				 */
				MODAL_TITLE: {
					id: 4071,
					label: "b2b.RICHIESTA-PREVENTIVO.NUOVO-UTENTE.MODAL-TITLE"
				},

				/**
				 * Modifica utente secondario
				 */
				MODAL_TITLE_EDIT: {
					id: 4072,
					label: "b2b.RICHIESTA-PREVENTIVO.NUOVO-UTENTE.MODAL-TITLE-EDIT"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 4073,
					label: "b2b.RICHIESTA-PREVENTIVO.NUOVO-UTENTE.NOME"
				},

				/**
				 * Telefono
				 */
				TELEFONO: {
					id: 4074,
					label: "b2b.RICHIESTA-PREVENTIVO.NUOVO-UTENTE.TELEFONO"
				},

			},

			/**
			 * Richiesta inviata
			 */
			RICHIESTA_INVIATA: {
				id: 4075,
				label: "b2b.RICHIESTA-PREVENTIVO.RICHIESTA-INVIATA"
			},

			/**
			 * Specificare il team
			 */
			SPECIFICARE_IL_TEAM: {
				id: 4076,
				label: "b2b.RICHIESTA-PREVENTIVO.SPECIFICARE-IL-TEAM"
			},

			/**
			 * Specificare lo status
			 */
			SPECIFICARE_LO_STATUS: {
				id: 4077,
				label: "b2b.RICHIESTA-PREVENTIVO.SPECIFICARE-LO-STATUS"
			},

			/**
			 * Sposta data evasione
			 */
			SPOSTA_DATA_EVASIONE: {
				id: 4078,
				label: "b2b.RICHIESTA-PREVENTIVO.SPOSTA-DATA-EVASIONE"
			},

			/**
			 * Content of path b2b.RICHIESTA-PREVENTIVO.TEAM
			 */
			TEAM: {
				/**
				 * Ufficio Milano - Africa, Emirati e Oceano Indiano
				 */
				AFEMOCEI: {
					id: 4079,
					label: "b2b.RICHIESTA-PREVENTIVO.TEAM.AFEMOCEI"
				},

				/**
				 * Ufficio Milano - Americhe
				 */
				AMERICHE: {
					id: 4080,
					label: "b2b.RICHIESTA-PREVENTIVO.TEAM.AMERICHE"
				},

				/**
				 * Naar Benelux
				 */
				BENELUX: {
					id: 4081,
					label: "b2b.RICHIESTA-PREVENTIVO.TEAM.BENELUX"
				},

				/**
				 * Ufficio Milano - Europa
				 */
				EUROPA: {
					id: 4082,
					label: "b2b.RICHIESTA-PREVENTIVO.TEAM.EUROPA"
				},

				/**
				 * Naar Germany
				 */
				GERMANY: {
					id: 4083,
					label: "b2b.RICHIESTA-PREVENTIVO.TEAM.GERMANY"
				},

				/**
				 * Ufficio Milano - Oceania e Pacifico
				 */
				OCEANIA: {
					id: 4084,
					label: "b2b.RICHIESTA-PREVENTIVO.TEAM.OCEANIA"
				},

				/**
				 * Ufficio Milano - Oriente
				 */
				ORIENTE: {
					id: 4085,
					label: "b2b.RICHIESTA-PREVENTIVO.TEAM.ORIENTE"
				},

				/**
				 * Ufficio Milano - Sudamerica
				 */
				SUDAMERICA: {
					id: 4086,
					label: "b2b.RICHIESTA-PREVENTIVO.TEAM.SUDAMERICA"
				},

				/**
				 * Ufficio Napoli - Tutte le destinazioni
				 */
				NAPOLI: {
					id: 6252,
					label: "b2b.RICHIESTA-PREVENTIVO.TEAM.NAPOLI"
				},

				/**
				 * Ufficio Roma - Tutte le destinazioni
				 */
				ROMA: {
					id: 6253,
					label: "b2b.RICHIESTA-PREVENTIVO.TEAM.ROMA"
				},

				/**
				 * Ufficio Palermo - Tutte le destinazioni
				 */
				PALERMO: {
					id: 6254,
					label: "b2b.RICHIESTA-PREVENTIVO.TEAM.PALERMO"
				},

			},

			/**
			 * Modulo di richiesta preventivo - Viaggi su misura
			 */
			TITLE: {
				id: 4087,
				label: "b2b.RICHIESTA-PREVENTIVO.TITLE"
			},

			/**
			 * Flessibilità partenza in giorni
			 */
			TITLE_PARTENZA_RANGE: {
				id: 4088,
				label: "b2b.RICHIESTA-PREVENTIVO.TITLE-PARTENZA-RANGE"
			},

			/**
			 * Utente preventivo
			 */
			USER_PREVENTIVO: {
				id: 4089,
				label: "b2b.RICHIESTA-PREVENTIVO.USER-PREVENTIVO"
			},

			/**
			 * Vai al viaggio
			 */
			VAI_AL_VIAGGIO: {
				id: 4090,
				label: "b2b.RICHIESTA-PREVENTIVO.VAI-AL-VIAGGIO"
			},

			/**
			 * Vedi solo i tuoi viaggi
			 */
			VEDI_SOLO_I_TUOI_VIAGGI: {
				id: 4091,
				label: "b2b.RICHIESTA-PREVENTIVO.VEDI-SOLO-I-TUOI-VIAGGI"
			},

			/**
			 * Viaggio
			 */
			VIAGGIO: {
				id: 4092,
				label: "b2b.RICHIESTA-PREVENTIVO.VIAGGIO"
			},

		},

		/**
		 * Content of path b2b.SIDEBAR
		 */
		SIDEBAR: {
			/**
			 * Content of path b2b.SIDEBAR.BUTTONS
			 */
			BUTTONS: {
				/**
				 * Clicca per vedere i dati del viaggio
				 */
				LBL_CART_SUMMARY: {
					id: 4093,
					label: "b2b.SIDEBAR.BUTTONS.LBL-CART-SUMMARY"
				},

				/**
				 * Clicca per vedere i filtri per i servizi
				 */
				LBL_SERVICE_FILTERS: {
					id: 4094,
					label: "b2b.SIDEBAR.BUTTONS.LBL-SERVICE-FILTERS"
				},

			},

			/**
			 * Content of path b2b.SIDEBAR.CART-AUTOCOMPLETE
			 */
			CART_AUTOCOMPLETE: {
				/**
				 * Aggiorna e completa
				 */
				AGGIORNA_E_COMPLETA: {
					id: 4095,
					label: "b2b.SIDEBAR.CART-AUTOCOMPLETE.AGGIORNA-E-COMPLETA"
				},

				/**
				 * Completa
				 */
				COMPLETA: {
					id: 4096,
					label: "b2b.SIDEBAR.CART-AUTOCOMPLETE.COMPLETA"
				},

				/**
				 * Aggiorna e completa con voli e servizi
				 */
				COMPLETA_CON_VOLI_E_SERVIZI: {
					id: 4097,
					label: "b2b.SIDEBAR.CART-AUTOCOMPLETE.COMPLETA-CON-VOLI-E-SERVIZI"
				},

				/**
				 * Completa servizi a terra
				 */
				COMPLETA_HOTEL: {
					id: 4098,
					label: "b2b.SIDEBAR.CART-AUTOCOMPLETE.COMPLETA-HOTEL"
				},

				/**
				 * Completa voli
				 */
				COMPLETA_VOLI: {
					id: 4099,
					label: "b2b.SIDEBAR.CART-AUTOCOMPLETE.COMPLETA-VOLI"
				},

				/**
				 * Completamento avviato
				 */
				COMPLETAMENTO_AVVIATO: {
					id: 4100,
					label: "b2b.SIDEBAR.CART-AUTOCOMPLETE.COMPLETAMENTO-AVVIATO"
				},

				/**
				 * Verifica di prezzi e servizi
				 */
				VERIFICA_PREZZI_E_SERVIZI: {
					id: 4101,
					label: "b2b.SIDEBAR.CART-AUTOCOMPLETE.VERIFICA-PREZZI-E-SERVIZI"
				},

			},

			/**
			 * Content of path b2b.SIDEBAR.CART-BUTTONS
			 */
			CART_BUTTONS: {
				/**
				 * Amadeus Cryptic
				 */
				AMADEUS_CRYPTIC: {
					id: 4102,
					label: "b2b.SIDEBAR.CART-BUTTONS.AMADEUS-CRYPTIC"
				},

				/**
				 * Procedere con la conferma della pratica? Dopo la conferma inizierà la prenotazione automatica di tutti i servizi.
				 */
				CONFERMA_AUTOMATICA_TESTO: {
					id: 4103,
					label: "b2b.SIDEBAR.CART-BUTTONS.CONFERMA-AUTOMATICA-TESTO"
				},

				/**
				 * Conferma in manuale
				 */
				CONFERMA_MANUALE: {
					id: 4104,
					label: "b2b.SIDEBAR.CART-BUTTONS.CONFERMA-MANUALE"
				},

				/**
				 * Confermare la pratica in modo manuale? Tutti i servizi verranno impostati con prenotazione manuale.
				 */
				CONFERMA_MANUALE_TESTO: {
					id: 4105,
					label: "b2b.SIDEBAR.CART-BUTTONS.CONFERMA-MANUALE-TESTO"
				},

				/**
				 * Conferma manuale offerta {off}
				 */
				CONFERMA_MANUALE_TITOLO: {
					id: 4106,
					label: "b2b.SIDEBAR.CART-BUTTONS.CONFERMA-MANUALE-TITOLO"
				},

				/**
				 * Content of path b2b.SIDEBAR.CART-BUTTONS.CONFIRM-MODAL
				 */
				CONFIRM_MODAL: {
					/**
					 * Conferma
					 */
					CONFERMA: {
						id: 4107,
						label: "b2b.SIDEBAR.CART-BUTTONS.CONFIRM-MODAL.CONFERMA"
					},

					/**
					 * Conferma comunque
					 */
					CONFERMA_COMUNQUE: {
						id: 4108,
						label: "b2b.SIDEBAR.CART-BUTTONS.CONFIRM-MODAL.CONFERMA-COMUNQUE"
					},

					/**
					 * Sei sicuro di voler confermare il viaggio nonostante siano presenti dei problemi? Confermando verrà eseguita una verifica di disponibilità e prezzi, se non ci sono state variazioni l'offerta verrà confermata.
					 */
					CONFERMA_COMUNQUE_TEXT: {
						id: 4109,
						label: "b2b.SIDEBAR.CART-BUTTONS.CONFIRM-MODAL.CONFERMA-COMUNQUE-TEXT"
					},

					/**
					 * Conferma offerta {off}
					 */
					CONFERMA_OFFERTA: {
						id: 4110,
						label: "b2b.SIDEBAR.CART-BUTTONS.CONFIRM-MODAL.CONFERMA-OFFERTA"
					},

					/**
					 * Procedere con la conferma dell'offerta?
					 */
					CONFIRM_SIMPLE_TEXT: {
						id: 4111,
						label: "b2b.SIDEBAR.CART-BUTTONS.CONFIRM-MODAL.CONFIRM-SIMPLE-TEXT"
					},

					/**
					 * L'offerta presenta i seguenti problemi. Verificare attentamente prima di procedere:
					 */
					CONFIRM_TEXT: {
						id: 4112,
						label: "b2b.SIDEBAR.CART-BUTTONS.CONFIRM-MODAL.CONFIRM-TEXT"
					},

					/**
					 * Destinatario
					 */
					DESTINATARIO: {
						id: 4113,
						label: "b2b.SIDEBAR.CART-BUTTONS.CONFIRM-MODAL.DESTINATARIO"
					},

					/**
					 * I problemi evidenziati impediscono la prenotazione automatica e vanno corretti prima di avviare manualmente le conferme dei singoli servizi.
					 */
					DISCLAIMER: {
						id: 4114,
						label: "b2b.SIDEBAR.CART-BUTTONS.CONFIRM-MODAL.DISCLAIMER"
					},

					/**
					 * Invia email di conferma
					 */
					INVIA_EMAIL_CONFERMA: {
						id: 4115,
						label: "b2b.SIDEBAR.CART-BUTTONS.CONFIRM-MODAL.INVIA-EMAIL-CONFERMA"
					},

				},

				/**
				 * Duplica questo viaggio
				 */
				DUPLICA_VIAGGIO: {
					id: 4116,
					label: "b2b.SIDEBAR.CART-BUTTONS.DUPLICA-VIAGGIO"
				},

				/**
				 * Genera conferme
				 */
				GENERA_CONFERME: {
					id: 4117,
					label: "b2b.SIDEBAR.CART-BUTTONS.GENERA-CONFERME"
				},

				/**
				 * Offerta confermata.
				 */
				OFFERTA_CONFERMATA: {
					id: 4118,
					label: "b2b.SIDEBAR.CART-BUTTONS.OFFERTA-CONFERMATA"
				},

				/**
				 * Offerta confermata in manuale
				 */
				OFFERTA_CONFERMATA_MANUALE: {
					id: 4119,
					label: "b2b.SIDEBAR.CART-BUTTONS.OFFERTA-CONFERMATA-MANUALE"
				},

				/**
				 * Prenota viaggio
				 */
				PRENOTA: {
					id: 4120,
					label: "b2b.SIDEBAR.CART-BUTTONS.PRENOTA"
				},

				/**
				 * Prenota Voli
				 */
				PRENOTA_VOLI: {
					id: 4121,
					label: "b2b.SIDEBAR.CART-BUTTONS.PRENOTA-VOLI"
				},

				/**
				 * Sblocca offerta
				 */
				SBLOCCA: {
					id: 4122,
					label: "b2b.SIDEBAR.CART-BUTTONS.SBLOCCA"
				},

				/**
				 * Sbloccare l'offerta confermata {id}?
				 */
				SBLOCCA_CONFERMA: {
					id: 4123,
					label: "b2b.SIDEBAR.CART-BUTTONS.SBLOCCA-CONFERMA"
				},

				/**
				 * Scegli eventuali servizi confermati da portare allo status manuale
				 */
				SBLOCCA_HELP: {
					id: 4124,
					label: "b2b.SIDEBAR.CART-BUTTONS.SBLOCCA-HELP"
				},

				/**
				 * Offerta sbloccata
				 */
				SBLOCCATA: {
					id: 4125,
					label: "b2b.SIDEBAR.CART-BUTTONS.SBLOCCATA"
				},

				/**
				 * Stampa Preventivo cliente
				 */
				STAMPA_PREV_CLI: {
					id: 4126,
					label: "b2b.SIDEBAR.CART-BUTTONS.STAMPA-PREV-CLI"
				},

				/**
				 * Stampa Preventivo
				 */
				STAMPA_PREVENTIVO: {
					id: 4127,
					label: "b2b.SIDEBAR.CART-BUTTONS.STAMPA-PREVENTIVO"
				},

				/**
				 * Supporto booking
				 */
				SUPPORTO_BOOKING: {
					id: 4128,
					label: "b2b.SIDEBAR.CART-BUTTONS.SUPPORTO-BOOKING"
				},

				/**
				 * Verifica disponibilità e prezzi
				 */
				VERIFICA_PREZZI: {
					id: 4129,
					label: "b2b.SIDEBAR.CART-BUTTONS.VERIFICA-PREZZI"
				},

				/**
				 * Prezzi e disponibilità potrebbero essere variati. Eseguire una verifica.
				 */
				VERIFICA_PREZZI_NECESSARIA: {
					id: 4130,
					label: "b2b.SIDEBAR.CART-BUTTONS.VERIFICA-PREZZI-NECESSARIA"
				},

			},

			/**
			 * Content of path b2b.SIDEBAR.CART-SUMMARY
			 */
			CART_SUMMARY: {
				/**
				 * Commissioni
				 */
				COMMISSIONI: {
					id: 4131,
					label: "b2b.SIDEBAR.CART-SUMMARY.COMMISSIONI"
				},

				/**
				 * Clicca per i dati del viaggio.
				 */
				DISPLAY_INFO: {
					id: 4132,
					label: "b2b.SIDEBAR.CART-SUMMARY.DISPLAY-INFO"
				},

				/**
				 * {giorni} giorn{giorni, plural, =0{i} one{o} other{i}}
				 */
				GIORNI: {
					id: 4133,
					label: "b2b.SIDEBAR.CART-SUMMARY.GIORNI"
				},

				/**
				 * il
				 */
				IL: {
					id: 4134,
					label: "b2b.SIDEBAR.CART-SUMMARY.IL"
				},

				/**
				 * Il tuo viaggio {tripId}
				 */
				IL_TUO_VIAGGIO: {
					id: 4135,
					label: "b2b.SIDEBAR.CART-SUMMARY.IL-TUO-VIAGGIO"
				},

				/**
				 * lordo
				 */
				LORDO: {
					id: 4136,
					label: "b2b.SIDEBAR.CART-SUMMARY.LORDO"
				},

				/**
				 * Margine
				 */
				MARGINE: {
					id: 4137,
					label: "b2b.SIDEBAR.CART-SUMMARY.MARGINE"
				},

				/**
				 * netto
				 */
				NETTO: {
					id: 4138,
					label: "b2b.SIDEBAR.CART-SUMMARY.NETTO"
				},

				/**
				 * {notti} nott{notti, plural, =0{i} one{e} other{i}}
				 */
				NOTTI: {
					id: 4139,
					label: "b2b.SIDEBAR.CART-SUMMARY.NOTTI"
				},

				/**
				 * Totale viaggio
				 */
				PREZZO_TOTALE: {
					id: 4140,
					label: "b2b.SIDEBAR.CART-SUMMARY.PREZZO-TOTALE"
				},

				/**
				 * Stato
				 */
				STATO: {
					id: 4141,
					label: "b2b.SIDEBAR.CART-SUMMARY.STATO"
				},

				/**
				 * {tappe} tapp{tappe, plural, =0{e} one{a} other{e}}
				 */
				TAPPE: {
					id: 4142,
					label: "b2b.SIDEBAR.CART-SUMMARY.TAPPE"
				},

				/**
				 * Totale da anticipare
				 */
				TOTALE_DA_ANTICIPARE: {
					id: 4143,
					label: "b2b.SIDEBAR.CART-SUMMARY.TOTALE-DA-ANTICIPARE"
				},

				/**
				 * {pax} viaggiator{pax, plural, =0{i} one{e} other{i}}
				 */
				VIAGGIATORI: {
					id: 4144,
					label: "b2b.SIDEBAR.CART-SUMMARY.VIAGGIATORI"
				},

			},

			/**
			 * Content of path b2b.SIDEBAR.CART-WARNINGS
			 */
			CART_WARNINGS: {
				/**
				 * E' possibile comunque confermare l'offerta ma occorre verificare l'esattezza dei dati
				 */
				CONSENTE_CONFERMA: {
					id: 4145,
					label: "b2b.SIDEBAR.CART-WARNINGS.CONSENTE-CONFERMA"
				},

				/**
				 * Dettagli
				 */
				DETTAGLI: {
					id: 4146,
					label: "b2b.SIDEBAR.CART-WARNINGS.DETTAGLI"
				},

				/**
				 * Il viaggio non è confermabile
				 */
				NON_CONFERMABILE: {
					id: 4147,
					label: "b2b.SIDEBAR.CART-WARNINGS.NON-CONFERMABILE"
				},

				/**
				 * Questo dettaglio non consente la conferma dell'offerta
				 */
				NON_CONSENTE_CONFERMA: {
					id: 4148,
					label: "b2b.SIDEBAR.CART-WARNINGS.NON-CONSENTE-CONFERMA"
				},

				/**
				 * Per la conferma dell'intera offerta occorre verificare quando segue:
				 */
				PER_LA_CONFERMA: {
					id: 4149,
					label: "b2b.SIDEBAR.CART-WARNINGS.PER-LA-CONFERMA"
				},

				/**
				 * Per la conferma dei soli voli occorre verificare quando segue:
				 */
				PER_LA_CONFERMA_VOLI: {
					id: 4150,
					label: "b2b.SIDEBAR.CART-WARNINGS.PER-LA-CONFERMA-VOLI"
				},

				/**
				 * {nr, plural, =0{Ci sono} one{C'è} other{Ci sono}} {nr} dettagl{nr, plural, =0{i} one{io} other{i}} da verificare
				 */
				PROBLEMI_CONFERMA: {
					id: 4151,
					label: "b2b.SIDEBAR.CART-WARNINGS.PROBLEMI-CONFERMA"
				},

				/**
				 * Dettagli da verificare per l'offerta {off}
				 */
				PROBLEMI_PER_L_OFFERTA: {
					id: 4152,
					label: "b2b.SIDEBAR.CART-WARNINGS.PROBLEMI-PER-L-OFFERTA"
				},

				/**
				 * Non è possibile prenotare i voli
				 */
				VOLI_NON_PRENOTABILI: {
					id: 4153,
					label: "b2b.SIDEBAR.CART-WARNINGS.VOLI-NON-PRENOTABILI"
				},

			},

			/**
			 * Content of path b2b.SIDEBAR.MENU
			 */
			MENU: {
				/**
				 * Assicurazioni
				 */
				MNU_03: {
					id: 4154,
					label: "b2b.SIDEBAR.MENU.MNU_03"
				},

				/**
				 * Assistenza
				 */
				MNU_04: {
					id: 4155,
					label: "b2b.SIDEBAR.MENU.MNU_04"
				},

				/**
				 * Parcheggio
				 */
				MNU_06: {
					id: 4156,
					label: "b2b.SIDEBAR.MENU.MNU_06"
				},

				/**
				 * Area Commerciale
				 */
				MNU_AREA_COMMERCIALE: {
					id: 4157,
					label: "b2b.SIDEBAR.MENU.MNU_AREA_COMMERCIALE"
				},

				/**
				 * Calendario eventi
				 */
				MNU_CALENDARIO: {
					id: 4158,
					label: "b2b.SIDEBAR.MENU.MNU_CALENDARIO"
				},

				/**
				 * Contatti
				 */
				MNU_CONTATTI: {
					id: 4159,
					label: "b2b.SIDEBAR.MENU.MNU_CONTATTI"
				},

				/**
				 * Crea un viaggio
				 */
				MNU_CREA_VIAGGIO: {
					id: 4160,
					label: "b2b.SIDEBAR.MENU.MNU_CREA_VIAGGIO"
				},

				/**
				 * Documenti di viaggio - App
				 */
				MNU_DOCUMENTI: {
					id: 4161,
					label: "b2b.SIDEBAR.MENU.MNU_DOCUMENTI"
				},

				/**
				 * Home
				 */
				MNU_HOME: {
					id: 4162,
					label: "b2b.SIDEBAR.MENU.MNU_HOME"
				},

				/**
				 * Info Voli
				 */
				MNU_INFO_VOLI: {
					id: 4163,
					label: "b2b.SIDEBAR.MENU.MNU_INFO_VOLI"
				},

				/**
				 * Logout
				 */
				MNU_LOGOUT: {
					id: 4164,
					label: "b2b.SIDEBAR.MENU.MNU_LOGOUT"
				},

				/**
				 * Profilo
				 */
				MNU_PROFILO: {
					id: 4165,
					label: "b2b.SIDEBAR.MENU.MNU_PROFILO"
				},

				/**
				 * Promozioni in corso
				 */
				MNU_PROMO_IN_CORSO: {
					id: 4166,
					label: "b2b.SIDEBAR.MENU.MNU_PROMO_IN_CORSO"
				},

				/**
				 * Condizioni generali di contratto di vendita di pacchetti turistici
				 */
				MNU_PROPO: {
					id: 4167,
					label: "b2b.SIDEBAR.MENU.MNU_PROPO"
				},

				/**
				 * Richiedi Preventivo
				 */
				MNU_RICHIEDI_PREVENTIVO: {
					id: 4168,
					label: "b2b.SIDEBAR.MENU.MNU_RICHIEDI_PREVENTIVO"
				},

				/**
				 * Scrivi a
				 */
				MNU_SCRIVI_A: {
					id: 4169,
					label: "b2b.SIDEBAR.MENU.MNU_SCRIVI_A"
				},

				/**
				 * Servizi in aeroporto
				 */
				MNU_SERVIZI_AEROPORTO: {
					id: 4170,
					label: "b2b.SIDEBAR.MENU.MNU_SERVIZI_AEROPORTO"
				},

				/**
				 * I tuoi Viaggi
				 */
				MNU_TUOI_VIAGGI: {
					id: 4171,
					label: "b2b.SIDEBAR.MENU.MNU_TUOI_VIAGGI"
				},

				/**
				 * Utenti
				 */
				MNU_UTENTI: {
					id: 4172,
					label: "b2b.SIDEBAR.MENU.MNU_UTENTI"
				},

			},

		},

		/**
		 * Content of path b2b.SOCIAL
		 */
		SOCIAL: {
			/**
			 * Facebook
			 */
			FACEBOOK: {
				id: 4173,
				label: "b2b.SOCIAL.FACEBOOK"
			},

			/**
			 * Instagram
			 */
			INSTAGRAM: {
				id: 4174,
				label: "b2b.SOCIAL.INSTAGRAM"
			},

			/**
			 * Twitter
			 */
			TWITTER: {
				id: 4175,
				label: "b2b.SOCIAL.TWITTER"
			},

			/**
			 * Youtube
			 */
			YOUTUBE: {
				id: 4176,
				label: "b2b.SOCIAL.YOUTUBE"
			},

		},

		/**
		 * Content of path b2b.TRIP-NAV-MENU
		 */
		TRIP_NAV_MENU: {
			/**
			 * Hotel
			 */
			HOTEL: {
				id: 4177,
				label: "b2b.TRIP-NAV-MENU.HOTEL"
			},

			/**
			 * Nessun servizio a catalogo in questo luogo
			 */
			NESSUN_SERVIZIO: {
				id: 4178,
				label: "b2b.TRIP-NAV-MENU.NESSUN-SERVIZIO"
			},

			/**
			 * Nessun servizio previsto
			 */
			NESSUN_SERVIZIO_NOTTI: {
				id: 4179,
				label: "b2b.TRIP-NAV-MENU.NESSUN-SERVIZIO-NOTTI"
			},

			/**
			 * Noleggi
			 */
			NOLEGGI: {
				id: 4180,
				label: "b2b.TRIP-NAV-MENU.NOLEGGI"
			},

			/**
			 * Ricerca immediata
			 */
			RIC_DIRETTA: {
				id: 4181,
				label: "b2b.TRIP-NAV-MENU.RIC-DIRETTA"
			},

			/**
			 * Selfdrive
			 */
			SELFDRIVE: {
				id: 4182,
				label: "b2b.TRIP-NAV-MENU.SELFDRIVE"
			},

			/**
			 * Tour e Crociere
			 */
			TOUR_E_CROCIERE: {
				id: 4183,
				label: "b2b.TRIP-NAV-MENU.TOUR-E-CROCIERE"
			},

			/**
			 * Trasferimenti
			 */
			TRASFERIMENTI: {
				id: 4184,
				label: "b2b.TRIP-NAV-MENU.TRASFERIMENTI"
			},

			/**
			 * Visite e Trasferimenti
			 */
			TRASFERIMENTI_E_VISITE: {
				id: 4185,
				label: "b2b.TRIP-NAV-MENU.TRASFERIMENTI-E-VISITE"
			},

			/**
			 * Visite
			 */
			VISITE: {
				id: 4186,
				label: "b2b.TRIP-NAV-MENU.VISITE"
			},

			/**
			 * Voli
			 */
			VOLI: {
				id: 4187,
				label: "b2b.TRIP-NAV-MENU.VOLI"
			},

		},

		/**
		 * Content of path b2b.USER
		 */
		USER: {
			/**
			 * Content of path b2b.USER.CAMBIO-PASSWORD
			 */
			CAMBIO_PASSWORD: {
				/**
				 * Compilare il campo
				 */
				COMPILARE_I_CAMPI: {
					id: 4188,
					label: "b2b.USER.CAMBIO-PASSWORD.COMPILARE-I-CAMPI"
				},

				/**
				 * Le due nuove password non coincidono
				 */
				LE_DUE_NUOVE_PASSWORD_NON_COINCIDONO: {
					id: 4189,
					label: "b2b.USER.CAMBIO-PASSWORD.LE-DUE-NUOVE-PASSWORD-NON-COINCIDONO"
				},

				/**
				 * Nuova password
				 */
				NUOVA_PASSWORD: {
					id: 4190,
					label: "b2b.USER.CAMBIO-PASSWORD.NUOVA-PASSWORD"
				},

				/**
				 * Password attuale
				 */
				PASSWORD_ATTUALE: {
					id: 4191,
					label: "b2b.USER.CAMBIO-PASSWORD.PASSWORD-ATTUALE"
				},

				/**
				 * Password modificata
				 */
				PASSWORD_MODIFICATA: {
					id: 4192,
					label: "b2b.USER.CAMBIO-PASSWORD.PASSWORD-MODIFICATA"
				},

				/**
				 * Ripeti la nuova password
				 */
				RIPETI_NUOVA_PASSWORD: {
					id: 4193,
					label: "b2b.USER.CAMBIO-PASSWORD.RIPETI-NUOVA-PASSWORD"
				},

			},

			/**
			 * Content of path b2b.USER.LOGO
			 */
			LOGO: {
				/**
				 * Carica nuovo logo
				 */
				CARICA_NUOVO_LOGO: {
					id: 4194,
					label: "b2b.USER.LOGO.CARICA-NUOVO-LOGO"
				},

				/**
				 * Errore nella lettura del file
				 */
				ERRORE_LETTURA: {
					id: 4195,
					label: "b2b.USER.LOGO.ERRORE-LETTURA"
				},

				/**
				 * Formati supportati: JPG, PNG, GIF
				 */
				FORMATI_SUPPORTATI: {
					id: 4196,
					label: "b2b.USER.LOGO.FORMATI-SUPPORTATI"
				},

				/**
				 * L'immagine deve essere larga max 400px
				 */
				IMMAGINE_MAX_PIX: {
					id: 4197,
					label: "b2b.USER.LOGO.IMMAGINE-MAX-PIX"
				},

				/**
				 * Immagine danneggiata o non supportata
				 */
				IMMAGINE_NON_SUPPORTATA: {
					id: 4198,
					label: "b2b.USER.LOGO.IMMAGINE-NON-SUPPORTATA"
				},

				/**
				 * Logo modificato
				 */
				LOGO_MODIFICATO: {
					id: 4199,
					label: "b2b.USER.LOGO.LOGO-MODIFICATO"
				},

				/**
				 * Nessun logo
				 */
				NESSUN_LOGO: {
					id: 4200,
					label: "b2b.USER.LOGO.NESSUN-LOGO"
				},

				/**
				 * Preview
				 */
				PREVIEW: {
					id: 4201,
					label: "b2b.USER.LOGO.PREVIEW"
				},

				/**
				 * Logo eliminato correttamente
				 */
				LOGO_ELIMINATO: {
					id: 6441,
					label: "b2b.USER.LOGO.LOGO-ELIMINATO"
				},

			},

			/**
			 * Content of path b2b.USER.OPTIONS
			 */
			OPTIONS: {
				/**
				 * Abilita preview documenti
				 */
				ABILITA_PREVIEW_DOCUMENTI: {
					id: 4202,
					label: "b2b.USER.OPTIONS.ABILITA-PREVIEW-DOCUMENTI"
				},

				/**
				 * Attiva la possibilità di visualizzare la preview dei documenti prima di generarli. Valido solo per i documenti creati come utente intranet
				 */
				ABILITA_PREVIEW_DOCUMENTI_HELP: {
					id: 4203,
					label: "b2b.USER.OPTIONS.ABILITA-PREVIEW-DOCUMENTI-HELP"
				},

				/**
				 * Queste impostazioni sono specifiche per questo browser su questo pc. E' possibile avere impostazioni diverse su pc diversi e su browser diversi dello stesso pc. Cliccare "Salva" per rendere effettive le modifiche
				 */
				GLOBAL_HELP: {
					id: 4204,
					label: "b2b.USER.OPTIONS.GLOBAL-HELP"
				},

				/**
				 * Non richiedere conferma su eliminazione tappe
				 */
				NO_CONFERMA_ELIMINA_TAPPE: {
					id: 4205,
					label: "b2b.USER.OPTIONS.NO-CONFERMA-ELIMINA-TAPPE"
				},

				/**
				 * Nelle modifiche dell'itinerario non chiedere conferma per ogni tappa che viene eliminata. In ogni caso nessuna modifica viene apportata finché non si salva l'itinerario, quindi è sempre possibile annullare le modifiche
				 */
				NO_CONFERMA_ELIMINA_TAPPE_HELP: {
					id: 4206,
					label: "b2b.USER.OPTIONS.NO-CONFERMA-ELIMINA-TAPPE-HELP"
				},

				/**
				 * Abilita ricerca globale viaggi
				 */
				RICERCA_GLOBALE_VIAGGI: {
					id: 4207,
					label: "b2b.USER.OPTIONS.RICERCA-GLOBALE-VIAGGI"
				},

				/**
				 * Quando questa opzione è attiva la funzione "Cerca tutto" della barra di navigazione in alto cerca anche nei tuoi viaggi per nome e per numero di pratica
				 */
				RICERCA_GLOBALE_VIAGGI_HELP: {
					id: 4208,
					label: "b2b.USER.OPTIONS.RICERCA-GLOBALE-VIAGGI-HELP"
				},

				/**
				 * Impostazioni locali
				 */
				TITLE: {
					id: 4209,
					label: "b2b.USER.OPTIONS.TITLE"
				},

				/**
				 * Apri sempre i viaggi della homepage agenzie nella stessa pagina
				 */
				VIAGGI_HOME_SELF: {
					id: 4210,
					label: "b2b.USER.OPTIONS.VIAGGI-HOME-SELF"
				},

				/**
				 * I viaggi della homepage agenzie di norma si aprono in un nuovo tab / finestra. Attivando questa opzione il viaggio si apre nella stessa pagina sostituendo la home. E' comunque possibile aprire in un tab usando il menù contestuale del browser
				 */
				VIAGGI_HOME_SELF_HELP: {
					id: 4211,
					label: "b2b.USER.OPTIONS.VIAGGI-HOME-SELF-HELP"
				},

			},

			/**
			 * Content of path b2b.USER.PROFILE
			 */
			PROFILE: {
				/**
				 * Cambio password
				 */
				CAMBIO_PASSWORD: {
					id: 4212,
					label: "b2b.USER.PROFILE.CAMBIO-PASSWORD"
				},

				/**
				 * CAP
				 */
				CAP: {
					id: 4213,
					label: "b2b.USER.PROFILE.CAP"
				},

				/**
				 * Inserire il CAP
				 */
				CAP_INVALID: {
					id: 4214,
					label: "b2b.USER.PROFILE.CAP-INVALID"
				},

				/**
				 * Città
				 */
				CITTA: {
					id: 4215,
					label: "b2b.USER.PROFILE.CITTA"
				},

				/**
				 * Indicare la città
				 */
				CITTA_INVALID: {
					id: 4216,
					label: "b2b.USER.PROFILE.CITTA-INVALID"
				},

				/**
				 * Civico
				 */
				CIVICO: {
					id: 4217,
					label: "b2b.USER.PROFILE.CIVICO"
				},

				/**
				 * Inserire il numero civico dell'agenzia
				 */
				CIVICO_INVALID: {
					id: 4218,
					label: "b2b.USER.PROFILE.CIVICO-INVALID"
				},

				/**
				 * Codice univoco F.E.
				 */
				CODICE_UNIVOCO_FE: {
					id: 4219,
					label: "b2b.USER.PROFILE.CODICE-UNIVOCO-FE"
				},

				/**
				 * Dati
				 */
				DATI: {
					id: 4220,
					label: "b2b.USER.PROFILE.DATI"
				},

				/**
				 * Dati di fatturazione
				 */
				DATI_FATTURAZIONE: {
					id: 4221,
					label: "b2b.USER.PROFILE.DATI-FATTURAZIONE"
				},

				/**
				 * Email
				 */
				EMAIL: {
					id: 4222,
					label: "b2b.USER.PROFILE.EMAIL"
				},

				/**
				 * Inserire l'indirizzo email
				 */
				EMAIL_INVALID: {
					id: 4223,
					label: "b2b.USER.PROFILE.EMAIL-INVALID"
				},

				/**
				 * Fax
				 */
				FAX: {
					id: 4224,
					label: "b2b.USER.PROFILE.FAX"
				},

				/**
				 * Indirizzo
				 */
				INDIRIZZO: {
					id: 4225,
					label: "b2b.USER.PROFILE.INDIRIZZO"
				},

				/**
				 * Inserire l'indirizzo dell'agenzia
				 */
				INDIRIZZO_INVALID: {
					id: 4226,
					label: "b2b.USER.PROFILE.INDIRIZZO-INVALID"
				},

				/**
				 * I dati contrassegnati con (*) sono obbligatori.
				 */
				LBL_MANDATORY: {
					id: 4227,
					label: "b2b.USER.PROFILE.LBL-MANDATORY"
				},

				/**
				 * Logo
				 */
				LOGO: {
					id: 4228,
					label: "b2b.USER.PROFILE.LOGO"
				},

				/**
				 * E' stata inviata una richiesta di modifica dei dati.
				 */
				MAIL_RICHIESTA_INVIATA: {
					id: 4229,
					label: "b2b.USER.PROFILE.MAIL-RICHIESTA-INVIATA"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 4230,
					label: "b2b.USER.PROFILE.NOME"
				},

				/**
				 * Nome colloquiale dell'agenzia. La ragione sociale completa va indicata nel tab 'Dati di fatturazione'
				 */
				NOME_HELP: {
					id: 4231,
					label: "b2b.USER.PROFILE.NOME-HELP"
				},

				/**
				 * Inserire il nome dell'agenzia
				 */
				NOME_INVALID: {
					id: 4232,
					label: "b2b.USER.PROFILE.NOME-INVALID"
				},

				/**
				 * Partita IVA
				 */
				PARTITA_IVA: {
					id: 4233,
					label: "b2b.USER.PROFILE.PARTITA-IVA"
				},

				/**
				 * Inserire la partita IVA
				 */
				PARTITA_IVA_INVALID: {
					id: 4234,
					label: "b2b.USER.PROFILE.PARTITA-IVA-INVALID"
				},

				/**
				 * PEC fatturazione elettronica (alternatica al codice univoco)
				 */
				PEC_FE: {
					id: 4235,
					label: "b2b.USER.PROFILE.PEC-FE"
				},

				/**
				 * Ragione sociale
				 */
				RAGIONE_SOCIALE: {
					id: 4236,
					label: "b2b.USER.PROFILE.RAGIONE-SOCIALE"
				},

				/**
				 * Inserire la ragione sociale
				 */
				RAGIONE_SOCIALE_INVALID: {
					id: 4237,
					label: "b2b.USER.PROFILE.RAGIONE-SOCIALE-INVALID"
				},

				/**
				 * Richiedi modifica
				 */
				RICHIEDI_MODIFICA: {
					id: 4238,
					label: "b2b.USER.PROFILE.RICHIEDI-MODIFICA"
				},

				/**
				 * Telefono
				 */
				TELEFONO: {
					id: 4239,
					label: "b2b.USER.PROFILE.TELEFONO"
				},

				/**
				 * Inserire il numero di telefono
				 */
				TELEFONO_INVALID: {
					id: 4240,
					label: "b2b.USER.PROFILE.TELEFONO-INVALID"
				},

				/**
				 * La mia agenzia
				 */
				TITLE_AGENZIA: {
					id: 4241,
					label: "b2b.USER.PROFILE.TITLE-AGENZIA"
				},

				/**
				 * Il mio utente
				 */
				TITLE_USER: {
					id: 4242,
					label: "b2b.USER.PROFILE.TITLE-USER"
				},

			},

		},

		/**
		 * Content of path b2b.PRENOTAZIONI-SELECTOR
		 */
		PRENOTAZIONI_SELECTOR: {
			/**
			 * Elenco prenotazioni fornitore divise per contatto e valuta
			 */
			MSG_1: {
				id: 5964,
				label: "b2b.PRENOTAZIONI-SELECTOR.MSG-1"
			},

			/**
			 * Contatto
			 */
			CONTATTO: {
				id: 5966,
				label: "b2b.PRENOTAZIONI-SELECTOR.CONTATTO"
			},

			/**
			 * Valuta
			 */
			VALUTA: {
				id: 5967,
				label: "b2b.PRENOTAZIONI-SELECTOR.VALUTA"
			},

			/**
			 * Non ci sono documenti da stampare
			 */
			MSG_2: {
				id: 5993,
				label: "b2b.PRENOTAZIONI-SELECTOR.MSG-2"
			},

		},

		/**
		 * Content of path b2b.DOCUMENTS
		 */
		DOCUMENTS: {
			/**
			 * Content of path b2b.DOCUMENTS.RESERVATIONS
			 */
			RESERVATIONS: {
				/**
				 * Confermato
				 */
				CONFERMATO: {
					id: 5975,
					label: "b2b.DOCUMENTS.RESERVATIONS.CONFERMATO"
				},

				/**
				 * Confermato da voi
				 */
				CONFERMATODAVOI: {
					id: 5976,
					label: "b2b.DOCUMENTS.RESERVATIONS.CONFERMATODAVOI"
				},

				/**
				 * Confermato da voi
				 */
				CONFERMATODAALLOTMENT: {
					id: 5977,
					label: "b2b.DOCUMENTS.RESERVATIONS.CONFERMATODAALLOTMENT"
				},

				/**
				 * Da Confermare
				 */
				DACONFERMARE: {
					id: 5978,
					label: "b2b.DOCUMENTS.RESERVATIONS.DACONFERMARE"
				},

			},

		},

		/**
		 * lo stesso giorno
		 */
		lo_stesso_giorno: {
			id: 6328,
			label: "b2b.lo stesso giorno"
		},

		/**
		 * Aggiornamento tipo trasporto
		 */
		Aggiornamento_tipo_trasporto: {
			id: 6331,
			label: "b2b.Aggiornamento tipo trasporto"
		},

		/**
		 * Content of path b2b.PROGRAMMAZIONE
		 */
		PROGRAMMAZIONE: {
			/**
			 * Content of path b2b.PROGRAMMAZIONE.SERVIZI
			 */
			SERVIZI: {
				/**
				 * Content of path b2b.PROGRAMMAZIONE.SERVIZI.PARTENZE
				 */
				PARTENZE: {
					/**
					 * PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-ITALIANO
					 */
					IN_ITALIANO: {
						id: 6335,
						label: "b2b.PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-ITALIANO"
					},

					/**
					 * PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-INGLESE
					 */
					IN_INGLESE: {
						id: 6336,
						label: "b2b.PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-INGLESE"
					},

					/**
					 * PROGRAMMAZIONE.SERVIZI.PARTENZE.SU-RICHIESTA
					 */
					SU_RICHIESTA: {
						id: 6341,
						label: "b2b.PROGRAMMAZIONE.SERVIZI.PARTENZE.SU-RICHIESTA"
					},

					/**
					 * PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-SPAGNOLO
					 */
					IN_SPAGNOLO: {
						id: 6351,
						label: "b2b.PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-SPAGNOLO"
					},

					/**
					 * PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-FRANCESE
					 */
					IN_FRANCESE: {
						id: 6364,
						label: "b2b.PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-FRANCESE"
					},

					/**
					 * PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-TEDESCO
					 */
					IN_TEDESCO: {
						id: 6365,
						label: "b2b.PROGRAMMAZIONE.SERVIZI.PARTENZE.IN-TEDESCO"
					},

				},

			},

		},

		/**
		 * Treno
		 */
		Treno: {
			id: 6354,
			label: "b2b.Treno"
		},

		/**
		 * Traghetto
		 */
		Traghetto: {
			id: 6355,
			label: "b2b.Traghetto"
		},

		/**
		 * Auto
		 */
		Auto: {
			id: 6356,
			label: "b2b.Auto"
		},

		/**
		 * Aereo
		 */
		Aereo: {
			id: 6357,
			label: "b2b.Aereo"
		},

		/**
		 * Indefinito
		 */
		Indefinito: {
			id: 6358,
			label: "b2b.Indefinito"
		},

		/**
		 * il giorno dopo
		 */
		il_giorno_dopo: {
			id: 6442,
			label: "b2b.il giorno dopo"
		},

		/**
		 * due giorni dopo
		 */
		due_giorni_dopo: {
			id: 6443,
			label: "b2b.due giorni dopo"
		},

		/**
		 * il giorno prima
		 */
		il_giorno_prima: {
			id: 6444,
			label: "b2b.il giorno prima"
		},

		/**
		 * am gleichen Tag
		 */
		am_gleichen_Tag: {
			id: 6550,
			label: "b2b.am gleichen Tag"
		},

		/**
		 * Aggiornamento tipo trasporto OK
		 */
		Aggiornamento_tipo_trasporto_OK: {
			id: 6551,
			label: "b2b.Aggiornamento tipo trasporto OK"
		},

		/**
		 * MENU.INTRANET-OFFERTE.I-TUOI-VIAGGI
		 */
		MENU: {
			id: 6556,
			label: "b2b.MENU.INTRANET-OFFERTE.I-TUOI-VIAGGI"
		},

		/**
		 * 	NAVBAR.I-TUOI-VIAGGI
		 */
		_NAVBAR: {
			id: 6557,
			label: "b2b.\tNAVBAR.I-TUOI-VIAGGI"
		},

		/**
		 * Content of path b2b.NEWS-PANEL
		 */
		NEWS_PANEL: {
			/**
			 * Content of path b2b.NEWS-PANEL.TITLE
			 */
			TITLE: {
			},

		},

		/**
		 * Le même jour
		 */
		Le_m_me_jour: {
			id: 6593,
			label: "b2b.Le m\u00EAme jour"
		},

		/**
		 * Filtri di ricerca
		 */
		SEARCH_FILTERS: {
			id: 6614,
			label: "b2b.SEARCH-FILTERS.TITLE"
		},

	},

	/**
	 * Content of path b2c
	 */
	b2c: {
		/**
		 * Content of path b2c.COMMON
		 */
		COMMON: {
			/**
			 * Accetto
			 */
			ACCETTO: {
				id: 4243,
				label: "b2c.COMMON.ACCETTO"
			},

			/**
			 * Cerca
			 */
			CERCA: {
				id: 4244,
				label: "b2c.COMMON.CERCA"
			},

			/**
			 * Destinazione
			 */
			DESTINATION: {
				id: 4245,
				label: "b2c.COMMON.DESTINATION.TITLE"
			},

			/**
			 * Dettagli
			 */
			DETTAGLI: {
				id: 4246,
				label: "b2c.COMMON.DETTAGLI"
			},

			/**
			 * {qty} per pagina
			 */
			PER_PAGINA: {
				id: 4247,
				label: "b2c.COMMON.PER-PAGINA"
			},

			/**
			 * Content of path b2c.COMMON.TRIPCARD
			 */
			TRIPCARD: {
				/**
				 * Id
				 */
				ID: {
					id: 4248,
					label: "b2c.COMMON.TRIPCARD.ID"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 4249,
					label: "b2c.COMMON.TRIPCARD.NOME"
				},

				/**
				 * Prezzo
				 */
				PREZZO: {
					id: 4250,
					label: "b2c.COMMON.TRIPCARD.PREZZO"
				},

			},

			/**
			 * Visualizzat{shown, plural, =0{i} one{o} other{i}} {shown} di {total}
			 */
			VISUALIZZATI: {
				id: 4251,
				label: "b2c.COMMON.VISUALIZZATI"
			},

		},

		/**
		 * Content of path b2c.COMPONENTS
		 */
		COMPONENTS: {
			/**
			 * Content of path b2c.COMPONENTS.FOOTER
			 */
			FOOTER: {
				/**
				 * Content of path b2c.COMPONENTS.FOOTER.CONTACTS
				 */
				CONTACTS: {
					/**
					 *  (+39) 02 700594129
					 */
					FAX: {
						id: 4252,
						label: "b2c.COMPONENTS.FOOTER.CONTACTS.FAX"
					},

					/**
					 * Naar Tour Operator s.p.a.Via M. Pagano 4020145 MilanoP.I. 11276650154
					 */
					NAME: {
						id: 4253,
						label: "b2c.COMPONENTS.FOOTER.CONTACTS.NAME"
					},

					/**
					 *  (+39) 02 485585 1
					 */
					PHONE: {
						id: 4254,
						label: "b2c.COMPONENTS.FOOTER.CONTACTS.PHONE"
					},

					/**
					 * Contatti
					 */
					TITLE: {
						id: 4255,
						label: "b2c.COMPONENTS.FOOTER.CONTACTS.TITLE"
					},

				},

				/**
				 * Content of path b2c.COMPONENTS.FOOTER.COPYRIGHT
				 */
				COPYRIGHT: {
					/**
					 * All Rights Reserved
					 */
					ALL_RIGHT: {
						id: 4256,
						label: "b2c.COMPONENTS.FOOTER.COPYRIGHT.ALL-RIGHT"
					},

					/**
					 * Naar Tour Operator s.p.a.&reg;
					 */
					NAME: {
						id: 4257,
						label: "b2c.COMPONENTS.FOOTER.COPYRIGHT.NAME"
					},

				},

				/**
				 * Content of path b2c.COMPONENTS.FOOTER.TITLES
				 */
				TITLES: {
					/**
					 * Info utili
					 */
					INFO_LINK: {
						id: 4258,
						label: "b2c.COMPONENTS.FOOTER.TITLES.INFO-LINK"
					},

					/**
					 * Contatti
					 */
					OWNER: {
						id: 4259,
						label: "b2c.COMPONENTS.FOOTER.TITLES.OWNER"
					},

					/**
					 * Partnership
					 */
					PARTNERSHIP: {
						id: 4260,
						label: "b2c.COMPONENTS.FOOTER.TITLES.PARTNERSHIP"
					},

				},

			},

			/**
			 * Content of path b2c.COMPONENTS.RIBBON
			 */
			RIBBON: {
				/**
				 * Allegato {fileType}
				 */
				ATTACHMENT: {
					id: 4261,
					label: "b2c.COMPONENTS.RIBBON.ATTACHMENT"
				},

				/**
				 * Vai a 
				 */
				LINK: {
					id: 4262,
					label: "b2c.COMPONENTS.RIBBON.LINK"
				},

			},

			/**
			 * Content of path b2c.COMPONENTS.TRIPCARD
			 */
			TRIPCARD: {
				/**
				 * Content of path b2c.COMPONENTS.TRIPCARD.DETAILS
				 */
				DETAILS: {
					/**
					 * Cat
					 */
					Category: {
						id: 4263,
						label: "b2c.COMPONENTS.TRIPCARD.DETAILS.Category"
					},

					/**
					 * Extra
					 */
					Extra: {
						id: 4264,
						label: "b2c.COMPONENTS.TRIPCARD.DETAILS.Extra"
					},

					/**
					 * Giorni
					 */
					Giorni: {
						id: 4265,
						label: "b2c.COMPONENTS.TRIPCARD.DETAILS.Giorni"
					},

					/**
					 * Lingua
					 */
					Lingua: {
						id: 4266,
						label: "b2c.COMPONENTS.TRIPCARD.DETAILS.Lingua"
					},

					/**
					 * Notti
					 */
					Notti: {
						id: 4267,
						label: "b2c.COMPONENTS.TRIPCARD.DETAILS.Notti"
					},

				},

				/**
				 * Identificativo
				 */
				ID: {
					id: 4268,
					label: "b2c.COMPONENTS.TRIPCARD.ID"
				},

				/**
				 * Nome
				 */
				NOME: {
					id: 4269,
					label: "b2c.COMPONENTS.TRIPCARD.NOME"
				},

				/**
				 * A partire da
				 */
				PREZZO: {
					id: 4270,
					label: "b2c.COMPONENTS.TRIPCARD.PREZZO"
				},

				/**
				 * Dettagli
				 */
				VIEW_LINK: {
					id: 4271,
					label: "b2c.COMPONENTS.TRIPCARD.VIEW-LINK"
				},

			},

		},

		/**
		 * Naar
		 */
		HOME: {
			id: 4272,
			label: "b2c.HOME.TITLE"
		},

		/**
		 * Content of path b2c.LANGUAGES
		 */
		LANGUAGES: {
			/**
			 * Germania
			 */
			DE: {
				id: 4273,
				label: "b2c.LANGUAGES.DE"
			},

			/**
			 * English
			 */
			EN: {
				id: 4274,
				label: "b2c.LANGUAGES.EN"
			},

			/**
			 * Spagna
			 */
			ES: {
				id: 4275,
				label: "b2c.LANGUAGES.ES"
			},

			/**
			 * Francia
			 */
			FR: {
				id: 4276,
				label: "b2c.LANGUAGES.FR"
			},

			/**
			 * Italia
			 */
			IT: {
				id: 4277,
				label: "b2c.LANGUAGES.IT"
			},

			/**
			 * Benelux
			 */
			NL: {
				id: 4278,
				label: "b2c.LANGUAGES.NL"
			},

		},

		/**
		 * Content of path b2c.LOGIN
		 */
		LOGIN: {
			/**
			 * Email
			 */
			EMAIL: {
				id: 4279,
				label: "b2c.LOGIN.EMAIL"
			},

			/**
			 * Password
			 */
			PASSWORD: {
				id: 4280,
				label: "b2c.LOGIN.PASSWORD"
			},

			/**
			 * Compilare la password
			 */
			PASSWORD_OBBLIGATORIA: {
				id: 4281,
				label: "b2c.LOGIN.PASSWORD-OBBLIGATORIA"
			},

			/**
			 * Compilare l'email utente
			 */
			UTENTE_OBBLIGATORIO: {
				id: 4282,
				label: "b2c.LOGIN.UTENTE-OBBLIGATORIO"
			},

		},

		/**
		 * Content of path b2c.NAV-BAR
		 */
		NAV_BAR: {
			/**
			 * Accedi
			 */
			ACCEDI: {
				id: 4283,
				label: "b2c.NAV-BAR.ACCEDI"
			},

			/**
			 * Login alla tua area agenzia
			 */
			ACCEDI_TITLE: {
				id: 4284,
				label: "b2c.NAV-BAR.ACCEDI-TITLE"
			},

			/**
			 * Area agenzie
			 */
			AREA_AGENZIE: {
				id: 4285,
				label: "b2c.NAV-BAR.AREA-AGENZIE"
			},

			/**
			 * Cambia lingua
			 */
			CAMBIA_LINGUA: {
				id: 4286,
				label: "b2c.NAV-BAR.CAMBIA-LINGUA"
			},

			/**
			 * I tuoi viaggi
			 */
			I_TUOI_VIAGGI: {
				id: 4287,
				label: "b2c.NAV-BAR.I-TUOI-VIAGGI"
			},

			/**
			 * Intranet
			 */
			INTRANET: {
				id: 4288,
				label: "b2c.NAV-BAR.INTRANET"
			},

			/**
			 * Login
			 */
			LOGIN: {
				id: 4289,
				label: "b2c.NAV-BAR.LOGIN"
			},

			/**
			 * Logout
			 */
			LOGOUT: {
				id: 4290,
				label: "b2c.NAV-BAR.LOGOUT"
			},

			/**
			 * Recupera password
			 */
			RECUPERO_PASSWORD: {
				id: 4291,
				label: "b2c.NAV-BAR.RECUPERO-PASSWORD"
			},

			/**
			 * Registrati
			 */
			REGISTRAZIONE_ADV: {
				id: 4292,
				label: "b2c.NAV-BAR.REGISTRAZIONE-ADV"
			},

			/**
			 * Operatore
			 */
			Operatore: {
				id: 6104,
				label: "b2c.NAV-BAR.Operatore"
			},

			/**
			 * Menu utente
			 */
			MENU_UTENTE: {
				id: 6105,
				label: "b2c.NAV-BAR.MENU-UTENTE"
			},

			/**
			 * Menu operatore
			 */
			MENU_OPERATORE: {
				id: 6106,
				label: "b2c.NAV-BAR.MENU-OPERATORE"
			},

		},

		/**
		 * Content of path b2c.PAGES
		 */
		PAGES: {
			/**
			 * Content of path b2c.PAGES.ABOUT
			 */
			ABOUT: {
				/**
				 * 
				 */
				ABSTRACT: {
					id: 4293,
					label: "b2c.PAGES.ABOUT.ABSTRACT"
				},

				/**
				 * Chi siamo
				 */
				TITLE: {
					id: 4294,
					label: "b2c.PAGES.ABOUT.TITLE"
				},

			},

			/**
			 * Content of path b2c.PAGES.CHI-SIAMO
			 */
			CHI_SIAMO: {
				/**
				 * 
				 */
				ABSTRACT: {
					id: 4295,
					label: "b2c.PAGES.CHI-SIAMO.ABSTRACT"
				},

				/**
				 * 
				 */
				TITLE: {
					id: 4296,
					label: "b2c.PAGES.CHI-SIAMO.TITLE"
				},

			},

			/**
			 * Content of path b2c.PAGES.CONTACTS
			 */
			CONTACTS: {
				/**
				 * Abstract
				 */
				ABSTRACT: {
					id: 4297,
					label: "b2c.PAGES.CONTACTS.ABSTRACT"
				},

				/**
				 * Content of path b2c.PAGES.CONTACTS.FORM
				 */
				FORM: {
					/**
					 * Budget a persona
					 */
					BUDGET: {
						id: 4298,
						label: "b2c.PAGES.CONTACTS.FORM.BUDGET"
					},

					/**
					 * Destinazioni
					 */
					DESTINATIONS: {
						id: 4299,
						label: "b2c.PAGES.CONTACTS.FORM.DESTINATIONS"
					},

					/**
					 * email
					 */
					EMAIL: {
						id: 4300,
						label: "b2c.PAGES.CONTACTS.FORM.EMAIL"
					},

					/**
					 * Content of path b2c.PAGES.CONTACTS.FORM.ERROR
					 */
					ERROR: {
						/**
						 * L'email è richiesta.
						 */
						EMAIL_REQUEST: {
							id: 4301,
							label: "b2c.PAGES.CONTACTS.FORM.ERROR.EMAIL-REQUEST"
						},

						/**
						 * È necessario accettare per proseguire
						 */
						GO_TO_PROCEEDE: {
							id: 4302,
							label: "b2c.PAGES.CONTACTS.FORM.ERROR.GO-TO-PROCEEDE"
						},

						/**
						 * Il nome è richiesto.
						 */
						NAME_REQUEST: {
							id: 4303,
							label: "b2c.PAGES.CONTACTS.FORM.ERROR.NAME-REQUEST"
						},

						/**
						 * Il cognome è richiesto.
						 */
						SURNAME_REQUEST: {
							id: 4304,
							label: "b2c.PAGES.CONTACTS.FORM.ERROR.SURNAME-REQUEST"
						},

					},

					/**
					 * Informazioni contatto
					 */
					INFO_CONTATTI: {
						id: 4305,
						label: "b2c.PAGES.CONTACTS.FORM.INFO-CONTATTI"
					},

					/**
					 * nome
					 */
					NAME: {
						id: 4306,
						label: "b2c.PAGES.CONTACTS.FORM.NAME"
					},

					/**
					 * Richiedi informazioni
					 */
					RICHIEDI_INFO: {
						id: 4307,
						label: "b2c.PAGES.CONTACTS.FORM.RICHIEDI-INFO"
					},

					/**
					 * cognome
					 */
					SURNAME: {
						id: 4308,
						label: "b2c.PAGES.CONTACTS.FORM.SURNAME"
					},

					/**
					 * Informativa privacy - Trattamento dei dati personali
					 */
					TRATTAMENTO_DATI: {
						id: 4309,
						label: "b2c.PAGES.CONTACTS.FORM.TRATTAMENTO-DATI"
					},

					/**
					 * Viaggio di nozze
					 */
					VIAGGIO_DI_NOZZE: {
						id: 4310,
						label: "b2c.PAGES.CONTACTS.FORM.VIAGGIO-DI-NOZZE"
					},

				},

				/**
				 * Ricerca l'agenzia di viaggi
				 */
				LABL_ADV: {
					id: 4311,
					label: "b2c.PAGES.CONTACTS.LABL-ADV"
				},

				/**
				 * Nessun viaggio visitato
				 */
				NESSUN_VIAGGIO: {
					id: 4312,
					label: "b2c.PAGES.CONTACTS.NESSUN-VIAGGIO"
				},

				/**
				 * {gg} giorn{gg, plural, =0{i} one{o} other{i}} / {nn} nott{nn, plural, =0{i} one{e} other{i}}
				 */
				NUM_GIORNI_NOTTI: {
					id: 4313,
					label: "b2c.PAGES.CONTACTS.NUM-GIORNI-NOTTI"
				},

				/**
				 * Qualunque
				 */
				RAGGIO_INF: {
					id: 4314,
					label: "b2c.PAGES.CONTACTS.RAGGIO-INF"
				},

				/**
				 * Per Cap
				 */
				RIC_CAP: {
					id: 4315,
					label: "b2c.PAGES.CONTACTS.RIC-CAP"
				},

				/**
				 * Per Città
				 */
				RIC_CITTA: {
					id: 4316,
					label: "b2c.PAGES.CONTACTS.RIC-CITTA"
				},

				/**
				 * Per Provincia
				 */
				RIC_PROV: {
					id: 4317,
					label: "b2c.PAGES.CONTACTS.RIC-PROV"
				},

				/**
				 * Dalla tua posizione in un raggio di
				 */
				RIC_RAGGIO: {
					id: 4318,
					label: "b2c.PAGES.CONTACTS.RIC-RAGGIO"
				},

				/**
				 * Title
				 */
				TITLE: {
					id: 4319,
					label: "b2c.PAGES.CONTACTS.TITLE"
				},

				/**
				 * Viaggi visitati
				 */
				VIAGGI_VISITATI: {
					id: 4320,
					label: "b2c.PAGES.CONTACTS.VIAGGI-VISITATI"
				},

			},

			/**
			 * Content of path b2c.PAGES.CONTATTI
			 */
			CONTATTI: {
				/**
				 * 
				 */
				ABSTRACT: {
					id: 4321,
					label: "b2c.PAGES.CONTATTI.ABSTRACT"
				},

				/**
				 * 
				 */
				TITLE: {
					id: 4322,
					label: "b2c.PAGES.CONTATTI.TITLE"
				},

			},

			/**
			 * Content of path b2c.PAGES.DESTINATIONS
			 */
			DESTINATIONS: {
				/**
				 * Destinazioni
				 */
				ABSTRACT: {
					id: 4323,
					label: "b2c.PAGES.DESTINATIONS.ABSTRACT"
				},

				/**
				 * Destinazioni
				 */
				TITLE: {
					id: 4324,
					label: "b2c.PAGES.DESTINATIONS.TITLE"
				},

			},

			/**
			 * Content of path b2c.PAGES.DESTINAZIONI
			 */
			DESTINAZIONI: {
				/**
				 * 
				 */
				ABSTRACT: {
					id: 4325,
					label: "b2c.PAGES.DESTINAZIONI.ABSTRACT"
				},

				/**
				 * 
				 */
				TITLE: {
					id: 4326,
					label: "b2c.PAGES.DESTINAZIONI.TITLE"
				},

			},

			/**
			 * Content of path b2c.PAGES.HOME
			 */
			HOME: {
				/**
				 * 
				 */
				ABSTRACT: {
					id: 4327,
					label: "b2c.PAGES.HOME.ABSTRACT"
				},

				/**
				 * Home
				 */
				TITLE: {
					id: 4328,
					label: "b2c.PAGES.HOME.TITLE"
				},

			},

			/**
			 * Content of path b2c.PAGES.IDEAS
			 */
			IDEAS: {
				/**
				 * Idee
				 */
				ABSTRACT: {
					id: 4329,
					label: "b2c.PAGES.IDEAS.ABSTRACT"
				},

				/**
				 * Idee
				 */
				TITLE: {
					id: 4330,
					label: "b2c.PAGES.IDEAS.TITLE"
				},

			},

			/**
			 * Content of path b2c.PAGES.IDEE
			 */
			IDEE: {
				/**
				 * 
				 */
				ABSTRACT: {
					id: 4331,
					label: "b2c.PAGES.IDEE.ABSTRACT"
				},

				/**
				 * Idee
				 */
				TITLE: {
					id: 4332,
					label: "b2c.PAGES.IDEE.TITLE"
				},

			},

			/**
			 * Content of path b2c.PAGES.OFFERS
			 */
			OFFERS: {
				/**
				 * Offerte
				 */
				ABSTRACT: {
					id: 4333,
					label: "b2c.PAGES.OFFERS.ABSTRACT"
				},

				/**
				 * Offerte
				 */
				TITLE: {
					id: 4334,
					label: "b2c.PAGES.OFFERS.TITLE"
				},

			},

			/**
			 * Content of path b2c.PAGES.OFFERTE
			 */
			OFFERTE: {
				/**
				 * 
				 */
				ABSTRACT: {
					id: 4335,
					label: "b2c.PAGES.OFFERTE.ABSTRACT"
				},

				/**
				 * Offerte
				 */
				TITLE: {
					id: 4336,
					label: "b2c.PAGES.OFFERTE.TITLE"
				},

			},

			/**
			 * Destinazioni aperte
			 */
			OPENDESTPAGE: {
				id: 4337,
				label: "b2c.PAGES.OPENDESTPAGE.TITLE"
			},

			/**
			 * Content of path b2c.PAGES.PARTNERS
			 */
			PARTNERS: {
				/**
				 * Partners
				 */
				ABSTRACT: {
					id: 4338,
					label: "b2c.PAGES.PARTNERS.ABSTRACT"
				},

				/**
				 * Partnership
				 */
				TITLE: {
					id: 4339,
					label: "b2c.PAGES.PARTNERS.TITLE"
				},

			},

			/**
			 * Content of path b2c.PAGES.TRIP
			 */
			TRIP: {
				/**
				 * Lo trovi anche
				 */
				APPARTIENE_ANCHE: {
					id: 4340,
					label: "b2c.PAGES.TRIP.APPARTIENE-ANCHE"
				},

				/**
				 * Tappe: 
				 */
				LBL_TAPPE: {
					id: 4341,
					label: "b2c.PAGES.TRIP.LBL-TAPPE"
				},

				/**
				 * Scarica la scheda per maggiori dettagli
				 */
				MAGGIORI_INFO: {
					id: 4342,
					label: "b2c.PAGES.TRIP.MAGGIORI-INFO"
				},

				/**
				 * giorn{gg, plural, =0{i} one{o} other{i}}
				 */
				NUM_GIORNI: {
					id: 4343,
					label: "b2c.PAGES.TRIP.NUM-GIORNI"
				},

				/**
				 * nott{nn, plural, =0{i} one{e} other{i}}
				 */
				NUM_NOTTI: {
					id: 4344,
					label: "b2c.PAGES.TRIP.NUM-NOTTI"
				},

				/**
				 * Viaggio
				 */
				TITLE: {
					id: 4345,
					label: "b2c.PAGES.TRIP.TITLE"
				},

			},

		},

		/**
		 * Naar Tour Operator
		 */
		SITE_NAME: {
			id: 4346,
			label: "b2c.SITE-NAME"
		},

		/**
		 * Content of path b2c.ENUMS
		 */
		ENUMS: {
			/**
			 * Content of path b2c.ENUMS.EOperatore
			 */
			EOperatore: {
				/**
				 * Naar Italy
				 */
				Naar: {
					id: 6080,
					label: "b2c.ENUMS.EOperatore.Naar"
				},

				/**
				 * Naar Benelux
				 */
				NaarBenelux: {
					id: 6081,
					label: "b2c.ENUMS.EOperatore.NaarBenelux"
				},

				/**
				 * Naar France
				 */
				NaarFrance: {
					id: 6082,
					label: "b2c.ENUMS.EOperatore.NaarFrance"
				},

				/**
				 * Naar Germany
				 */
				NaarGermany: {
					id: 6083,
					label: "b2c.ENUMS.EOperatore.NaarGermany"
				},

			},

			/**
			 * Content of path b2c.ENUMS.ELingua
			 */
			ELingua: {
				/**
				 * Tedesco
				 */
				Deutsch: {
					id: 6092,
					label: "b2c.ENUMS.ELingua.Deutsch"
				},

				/**
				 * Olandese
				 */
				Dutch: {
					id: 6093,
					label: "b2c.ENUMS.ELingua.Dutch"
				},

				/**
				 * Inglese
				 */
				English: {
					id: 6094,
					label: "b2c.ENUMS.ELingua.English"
				},

				/**
				 * Francese
				 */
				Francais: {
					id: 6095,
					label: "b2c.ENUMS.ELingua.Francais"
				},

				/**
				 * Italiano
				 */
				Italiano: {
					id: 6096,
					label: "b2c.ENUMS.ELingua.Italiano"
				},

			},

		},

	},

};
