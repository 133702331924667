import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {UserService} from './user.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (!this.user.isLoggedIn) {

			// OM 13.12.2024: redirect to login page with return url without query params
			const urlTree = this.router.parseUrl(state.url);
			const urlWithoutParams = urlTree.root.children['primary'].segments.map(it => it.path).join('/');

			this.router.navigate(['/auth/login'], {
				queryParams: {
					returnUrl: urlWithoutParams
				}
			});
		}

        // OM 12.11.2024: redirect to reset password if the user has to change it
        if (this.user.mustChangePassword) {
            console.warn('User must change password');
            this.router.navigate(['/auth/reset/' + this.user.resetPasswordCode]);
        }

		return this.user.isLoggedIn;
	}

	constructor(private router: Router, private user: UserService) {
	}
}
