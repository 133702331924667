import { MissingTranslationHandlerParams } from "@ngx-translate/core";
import { BackendService, IMissingLabelRequest } from "nr-common";

export async function HandleMissingLabel(
	website: string, 
	params: MissingTranslationHandlerParams, 
	backend: BackendService
) {
	return;
	//? Non inviare le richieste di traduzione mancanti per la lingua di debug
	if (params.translateService.currentLang == 'zu') {
		return;
	}

	try {
		const req: IMissingLabelRequest = {
			label: params.key,
			website: website,
			langCode: params.translateService.currentLang,
			url: window.location.href,
		};
		await backend.call(`websitelabels/missing`, req).toPromise();
	} catch (error) {
		console.error(`Error while sending missing translation key: ${params.key}`, error);
	}
}